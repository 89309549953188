import React, { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { useHistory, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

import { Breadcrumb, Button, FileUpload } from '../../../components';
import Radio from '../../../components/form-elements/radio';
import { Divider } from '../../../components/menu/styles';
import FileUploadService from '../../../services/FileUploadService';
import { ProjectService } from '../../../services/ProjectService';
import { GroupHeader } from '../new-project-form/styles';

import { Container, Content, Wrapper, PageHeader, InputGroup } from './styles';
import { ProjectStatus, UploadTypes } from '../types';
import Textarea from '../../../components/form-elements/textarea';
import projectsApi from '../../../services/projects';
import { useAuth } from '../../../contexts/auth';
import { ProjectType } from '../../../types/project';

type FormData = {
  compensation: string;
  observations: string;
};

type NewProjectDocumentExtraPageProps = {
  id: string;
};

type Files = {
  id: string;
  name: string;
  url: string;
  type: string;
  originalName: string;
  path: string;
  file_url: string;
};

type ControlledInputsType = {
  compensation: string;
};

const NewProjectDocumentExtraPage: React.FC = () => {
  const history = useHistory();
  const formRef = useRef<FormHandles>(null);
  const { user } = useAuth();
  const { id } = useParams<NewProjectDocumentExtraPageProps>();
  const [files, setFiles] = useState<Files[]>([]);
  const [project, setProject] = useState<ProjectType>();

  const [values, setValues] = useState<ControlledInputsType>({
    compensation: 'Sim',
  });

  useEffect(() => {
    FileUploadService.getFiles(id, '').then((res) => {
      const normalizied = res.data.map((file: Files) => ({
        id: file.id,
        name: file.originalName,
        url: file.path,
        type: file.type,
        file_url: file.file_url,
      }));
      setFiles(normalizied);
    });

    ProjectService.get(id).then((res) => {
      setProject(res.data);

      if (res.data) {
        setValue('compensation', res.data.compensation ? 'Sim' : 'Não');
        setValue('observations', res.data.observations);
      } else {
        setValue('compensation', values.compensation);
        setValue('observations', '');
      }
    });
  }, []);

  const links = [
    {
      id: 1,
      title: 'Projetos',
      link: '/projetos',
      active: false,
    },
    {
      id: 2,
      title: 'Novo projeto',
      link: '/projeto/novo',
      active: false,
    },
    {
      id: 3,
      title: 'Formulário',
      link: '/projeto/novo/formulario',
      active: false,
    },
    {
      id: 4,
      title: 'Documentos Extras',
      link: '/projeto/novo/formulario/documentos',
      active: true,
    },
  ];

  const compensationRadioOptions = [
    {
      label: 'Sim',
      value: 'Sim',
    },
    {
      label: 'Não',
      value: 'Não',
    },
  ];

  const { handleSubmit, setValue } = useForm<FormData>();

  const onSubmit = (data: FormData) => {
    ProjectService.get(id).then((res) => {
      const project = { ...res.data };
      delete project.id;
      delete project.createdAt;
      delete project.updatedAt;
      delete project.powerCompany;
      delete project.franchise;
      delete project.paymentType;
      delete project.paymentDate;
      delete project.documentationAnalysis;
      delete project.powerCompanyFeedback;
      delete project.projectInspections;
      delete project.inspectionRequestObs;

      Object.assign(project, {
        compensation: data.compensation === 'Sim' ? true : false,
        observations: data.observations
          ? data.observations
          : res.data.observations,
        status: ProjectStatus.DOCUMENTACAO_EM_ANALISE,
      });

      ProjectService.update(id, project).then(() => {
        projectsApi.post(`/api/projects/${id}/status-log`, {
          userId: user.id,
          observation: ProjectStatus.DOCUMENTACAO_EM_ANALISE,
          status: ProjectStatus.DOCUMENTACAO_EM_ANALISE,
        });
        toast.success('Projeto salvo com sucesso');
        history.push('/projetos');
      });
    });
  };

  const getFiles = (type: string) => {
    return files.filter((file) => file.type === type);
  };

  return (
    <Container>
      <Breadcrumb links={links} />
      <Wrapper>
        <PageHeader>
          <span>Novo projeto</span>
        </PageHeader>
        <Content>
          <Form
            ref={formRef}
            onSubmit={handleSubmit(onSubmit)}
            initialData={project}
          >
            <GroupHeader>
              <h2>Documentos Extras</h2>
              <span>
                Contas de unidade beneficiárias, ART de execução,
                Contrato/Estatuto social e etc.
              </span>
            </GroupHeader>
            <InputGroup>
              <span>Deseja realizar compensação?</span>
              <Radio
                options={compensationRadioOptions}
                name="compensation"
                onChange={(e) => {
                  setValue('compensation', e.target.value);
                  setValues({
                    ...values,
                    compensation: e.target.value,
                  });
                }}
                value={values.compensation}
              />
            </InputGroup>

            <FileUpload
              loadedFiles={getFiles(UploadTypes.DOCUMENTOS_EXTRAS)}
              projectId={id}
              type={UploadTypes.DOCUMENTOS_EXTRAS}
              helpMessage="Permitido somente arquivos .jpg, .png e .pdf com no máximo 5MB"
            />

            <InputGroup>
              <Textarea
                name="observations"
                label="Observações"
                onChange={(e) => {
                  setValue('observations', e.target.value);
                }}
              />
            </InputGroup>

            <Divider />

            <Button
              type="submit"
              text="Concluir"
              backgroundColor="#000000"
              backgroundHoverColor="#292929"
            />
          </Form>
        </Content>
      </Wrapper>
    </Container>
  );
};

export default NewProjectDocumentExtraPage;
