import React, { Fragment, useEffect } from 'react';
import 'react-indiana-drag-scroll/dist/style.css';
import { SearchInput } from '../../../../components/search';
import { useAuth } from '../../../../contexts/auth';
import { useSalesFunnel } from '../../../../contexts/sales-funnel';
import { FloatMenu } from '../FloatMenu';
import { Board } from './Board';
import { Content, Header, TitleContainer, Wrapper } from './styles';
import { Filters } from './Filters';
import { TagsModal } from './TagsModal';
import { format, parse } from 'date-fns';

export const Kanban: React.FC = () => {
  const { filters, kanban, handleFilters, getLeads } = useSalesFunnel();

  function normalizeDate(date: string) {
    if (!date) return;

    return format(parse(date, 'yyyy/MM/dd', new Date()), 'dd/MM/yyyy');
  }

  const { startDate, endDate } = {
    startDate: normalizeDate(filters.date.startDate),
    endDate: normalizeDate(filters.date.endDate),
  };

  const { selectedFranchise: franchiseId } = useAuth();

  useEffect(() => {
    getLeads();
  }, [franchiseId, filters]);

  return (
    <Fragment>
      <Header>
        <TitleContainer>
          <h1>Volt</h1>
        </TitleContainer>

        <Wrapper>
          <SearchInput
            placeholder="Pesquise por nome do cliente"
            onTextChange={(name) =>
              handleFilters(name, filters.tags, filters.userId, filters.date)
            }
            name="name"
          />

          <Filters
            onChange={(tags, userId, date) =>
              handleFilters(filters.name, tags, userId, date)
            }
          />
        </Wrapper>

        {startDate && (
          <span>
            Mostrando resultados de {startDate} a {endDate}.
          </span>
        )}
      </Header>

      <Content>
        <Board data={kanban} />
        <FloatMenu />
      </Content>

      <TagsModal />
    </Fragment>
  );
};
