import styled from 'styled-components';

export const Container = styled.main`
  display: flex;
  flex-direction: column;
  gap: 2rem;

  background: #ffffff;

  border: 1px solid #c9cbcf;
  border-radius: 8px;

  padding: 1.5rem 1rem;

  & > h1 {
    font-family: 'DunbarTallBold';
    font-weight: 500;
    font-size: 1.125rem;

    letter-spacing: 0.03em;

    color: #000000;
  }

  & > div {
    display: flex;
    align-items: center;

    flex: 1;

    overflow-x: auto;

    cursor: grab;

    &::-webkit-scrollbar {
      display: none;
    }
  }
`;

export const SellingSupplier = styled.div`
  display: flex;
  align-items: center;
  gap: 1.5rem;

  flex: 1 0 15.625rem;
`;

export const SellingSupplierDetails = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;

  & > strong {
    font-family: 'DunbarTallBold';
    font-weight: 700;
    font-size: 1.5rem;

    letter-spacing: 0.03em;

    color: #000000;
  }

  & > p {
    font-family: 'DunbarTextRegular';
    font-weight: 400;
    font-size: 1rem;

    letter-spacing: 0.02em;

    color: #000000;
  }
`;

export const SellingSupplierLogo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 3rem;
  height: 3rem;

  & > img {
    width: 100%;

    object-fit: contain;
  }
`;
