import React from 'react';
import ReactModal, { Props } from 'react-modal';
import { Button } from '../../../../../components';
import { Buttons, ModalContainer } from './styles';

interface ConfirmUpdatesModalProps extends Props {
  isOpen: boolean;
  onConfirm: () => void;
}

const ConfirmUpdatesModal: React.FC<ConfirmUpdatesModalProps> = (props) => {
  const { isOpen, onConfirm, children, ...rest } = props;

  return (
    <ReactModal
      isOpen={isOpen}
      style={{
        content: {
          borderRadius: '16px',
          maxWidth: '34.5rem',
          width: '100%',
          height: 'fit-content',
          padding: '1.5rem',
          top: '50%',
          left: '50%',
          transform: 'translate(-55%, -55%)',
          margin: '5%',
          overflow: 'auto',
        },
        overlay: {
          backgroundColor: 'rgba(19, 20, 21, 0.6)',
        },
      }}
      {...rest}
    >
      <ModalContainer>
        {children}

        <Buttons>
          <Button
            type="button"
            text="Cancelar"
            typeStyle="default"
            backgroundHoverColor="#C9CBCF"
            style={{ width: '15rem' }}
            onClick={rest.onRequestClose}
          />
          <Button
            type="button"
            text="Confirmar alteração"
            backgroundColor="#ED5A5A"
            backgroundHoverColor="#ed5a5abc"
            style={{ width: '15rem' }}
            onClick={onConfirm}
          />
        </Buttons>
      </ModalContainer>
    </ReactModal>
  );
};

export default ConfirmUpdatesModal;
