import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  max-width: 27.5rem;
  width: 100%;
  height: 3rem;

  @media (max-width: 520px) {
    max-width: unset;
  }
`;

export const ButtonBackground = styled.div`
  background: #dfe0e2;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 1rem;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
`;

type InputTypes = {
  error: boolean;
};

export const InputContainer = styled.div`
  flex: 1;

  height: 100%;

  label {
    display: inline-block;
    margin-bottom: 0.375rem;
  }
`;
export const Error = styled.div`
  color: red;
  font-weight: 500;
  margin-top: 0.375rem;
`;

export const InputText = styled.input<InputTypes>`
  background: #ffffff;

  border: 1px solid #e0e0e0;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;

  padding: 0 1rem;

  width: 100%;
  height: 100%;

  ${(props) =>
    props.error &&
    css`
      border: 2px solid red;
    `}
`;
