import { UserRoles } from '../types/users';

export const BREADCRUMB_PAGES = {
  PROJETOS: 'projetos',
};

export const getBreadCrumbObject = (
  role: string,
  page: string,
  id?: string
) => {
  if (role === UserRoles.CENTRAL_ATENDIMENTO || role === UserRoles.ADMIN) {
    return [
      {
        id: 1,
        title: 'Projetos',
        link: '/projetos',
        active: false,
      },
      {
        id: 2,
        title: 'Lista de Projetos',
        link: '/projetos',
        active: true,
      },
    ];
  } else if (role === UserRoles.CONSULTOR_SUCESSO) {
    return [
      {
        id: 1,
        title: 'Projetos',
        link: '/projetos',
        active: false,
      },
      {
        id: 2,
        title: 'Lista de Projetos',
        link: '/projetos',
        active: true,
      },
    ];
  }

  return [];
};
