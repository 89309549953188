import styled from 'styled-components';

export const Container = styled.div``;

export const Header = styled.header`
  display: flex;
  flex-direction: column;
  gap: 2.63rem;

  margin-bottom: 1rem;

  & > h1 {
    color: #27282b;

    font-family: 'DunbarTallBold', sans-serif;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 500;
    letter-spacing: 0.03375rem;
  }

  & > p {
    color: #27282b;

    font-family: 'Montserrat', sans-serif;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0.0225rem;
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  border: 1px solid #e0e0e0;
  border-radius: 1rem;
  background-color: #fafafa;
`;

export const Table = styled.table`
  width: 100%;
  table-layout: auto;
  border-collapse: collapse;

  & th {
    padding: 1.25rem 1rem;
    border-bottom: 1px solid #e0e0e0;

    text-align: left;

    & > strong {
      color: #27282b;
      font-family: 'Montserrat', sans-serif;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 600;
    }
  }

  & tr:not(:last-child) td {
    border-bottom: 1px solid #e0e0e0;
  }

  & tr:last-child {
    & > td:first-child {
      border-bottom-left-radius: 1rem;
    }
    & > td:last-child {
      border-bottom-right-radius: 1rem;
    }
  }

  & td {
    background-color: #fff;
    padding: 1.25rem 1rem;

    color: #61646b;

    & > div {
      display: flex;
      flex-direction: column;
      gap: 0.25rem;

      & > strong {
        color: #27282b;
        font-family: 'Montserrat', sans-serif;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 600;
      }

      & > p {
        color: #61646b;
        font-family: 'Montserrat', sans-serif;
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 500;
      }

      & > span {
        color: #e01919;
        font-family: 'Montserrat', sans-serif;
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 500;
      }
    }

    & > strong {
      color: #292929;

      font-family: 'DunbarTextRegular', sans-serif;
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      letter-spacing: 0.02rem;
    }
  }

  & th:last-child,
  td:last-child {
    text-align: center;
  }
`;
