import styled, { css } from 'styled-components';

type InputProps = {
  text?: string;
};

export const Container = styled.div<InputProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  background-color: #e6e6e6;

  padding: 0.5rem 1.5rem;

  ${(props) =>
    props.text === 'Ativo' &&
    css`
      background-color: #00e1ff;
    `}

  ${(props) =>
    props.text === 'Concluído' &&
    css`
      background-color: #f5f8dd;
    `}
`;
