import styled from 'styled-components';

export const Container = styled.div`
  h1 {
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    color: #fff;
    font-family: 'DunbarTallBold';
    letter-spacing: 0.03em;
  }

  span {
    display: inline-block;
    margin-top: 15px;
    color: #fff;
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    font-family: 'DunbarTextRegular';
    letter-spacing: 0.02em;
  }

  a {
    color: #fff;
    font-size: 16px;
  }
`;

export const Group = styled.div`
  margin: 26px 0;

  label {
    color: #fff;
  }
`;

export const ForgotPassword = styled.div`
  display: flex;
  margin-bottom: 16px;
`;
