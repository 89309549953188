import React, { memo, useLayoutEffect, useRef } from 'react';
import { Droppable } from 'react-beautiful-dnd';
import { FixedSizeList as List } from 'react-window';
import { Item } from '../Item';
import { Row } from '../Row';
import AutoSizer from 'react-virtualized-auto-sizer';
import './styles';
import { Wrapper } from './styles';
import { KanbanData } from '../../../../../../contexts/sales-funnel/types';

interface Props {
  column: KanbanData;
  index: number;
}

export const ItemList = memo(function ItemList(props: Props) {
  const { column, index } = props;

  const listRef = useRef<List>(null);

  useLayoutEffect(() => {
    const list = listRef.current;

    if (list) {
      list.scrollTo(0);
    }
  }, [index]);

  return (
    <AutoSizer>
      {({ width, height }: { width: number; height: number }) => {
        return (
          <Droppable
            isDropDisabled={column.id === 'agendado'}
            droppableId={column.id}
            mode="virtual"
            renderClone={(provided, snapshot, rubric) => (
              <Item
                provided={provided}
                isDragging={snapshot.isDragging}
                item={column.leads[rubric.source.index]}
              />
            )}
          >
            {(provided, snapshot) => {
              const itemCount = snapshot.isUsingPlaceholder
                ? column.leads.length + 1
                : column.leads.length;

              return (
                <Wrapper>
                  <List
                    className="item-list"
                    width={width}
                    height={height}
                    itemCount={itemCount}
                    itemSize={145}
                    outerRef={provided.innerRef}
                    itemData={column.leads}
                    ref={listRef}
                  >
                    {Row}
                  </List>
                </Wrapper>
              );
            }}
          </Droppable>
        );
      }}
    </AutoSizer>
  );
});
