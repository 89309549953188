import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { CollapsibleOptionsStyle, ListOptions, Option } from './styles';

interface CollapsibleOptionsProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  icon: React.ReactElement;
  title: string;
  titleLink?: string;
  options: { title: string; route: string }[];
  toggleOpen: (arg0: string) => void;
  opened: boolean;
}

export const CollapsibleOptions: React.FC<CollapsibleOptionsProps> = ({
  icon,
  title,
  titleLink,
  options,
  toggleOpen,
  opened,
}) => {
  const [forceOpen, setForceOpened] = useState<boolean>(false);
  const [lastRoute, setLastRoute] = useState<string>('');
  const isOpActive = (route: string) => {
    if (
      location.pathname === route ||
      location.pathname.indexOf(route || '') !== -1
    ) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (location.pathname !== lastRoute) {
      let change = false;
      if (
        location.pathname === titleLink ||
        location.pathname.indexOf(titleLink || '') !== -1
      )
        change = true;
      if (!change) {
        options.map((option) => {
          if (isOpActive(option.route)) change = true;
        });
      }
      setForceOpened(change);
      setLastRoute(location.pathname);
    }
  }, [location.pathname]);

  return (
    <CollapsibleOptionsStyle
      opened={opened || forceOpen}
      onClick={() => !forceOpen && toggleOpen(title)}
    >
      <a href={titleLink || '#'} className={forceOpen === true ? 'active' : ''}>
        {icon}
        <span>{title}</span>
      </a>
      <ListOptions opened={opened || forceOpen}>
        <ul>
          {options.map((option, index: number) => (
            <Option
              key={index}
              className={isOpActive(option.route) ? 'active' : ''}
            >
              <Link to={option.route}>
                <span>{option.title}</span>
              </Link>
            </Option>
          ))}
        </ul>
      </ListOptions>
    </CollapsibleOptionsStyle>
  );
};
