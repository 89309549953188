import React, { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import usersApi from '../../services/users';
import { UsersListType } from './types';
import {
  Breadcrumb,
  SearchInput,
  Status,
  Button,
  ActionButton,
  FranchisersIcon,
} from '../../components/';
import { Container, TableHeader, PageHeader } from './../styles';
import Table, { ColumnStructure, TableData } from '../../components/table';
import InnerActions from '../../components/table/innerActions';
import { FiEdit } from 'react-icons/fi';

type TableItems = {
  id: string;
  name: string;
  email: string;
  role: string;
  enabled: JSX.Element;
  actions: JSX.Element;
};

const UsersPage: React.FC = () => {
  const history = useHistory();
  const [users, setUsers] = useState<UsersListType>({
    content: [],
    pagination: {
      currentPage: 0,
      lastPage: 0,
      limit: 0,
      total: 0,
    },
  });
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [name, setName] = useState<string>('');
  const [loading, setLoading] = useState(true);

  const handlePageChange = (page: number): void => {
    setPage(page);
  };

  const handleLimitChange = (limit: number): void => {
    setLimit(limit);
  };

  const handleSearchChange = (search: string): void => {
    setName(search);
  };

  const links = [
    {
      id: 1,
      title: 'Usuários',
      link: '/usuarios',
      active: false,
    },
    {
      id: 2,
      title: 'Lista de usuários',
      link: '/usuarios',
      active: true,
    },
  ];

  useEffect(() => {
    setLoading(true);

    usersApi
      .get<UsersListType>(`/api/users?page=${page}&limit=${limit}&name=${name}`)
      .then((res) => {
        setUsers(res.data);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [page, limit, name]);

  const tableColumns: ColumnStructure[] = [
    {
      id: 'name',
      label: 'Nome',
      onClick: () => {},
    },
    {
      id: 'email',
      label: 'E-mail',
      onClick: () => {},
    },
    {
      id: 'role',
      label: 'Tipo',
      onClick: () => {},
    },
    {
      id: 'enabled',
      label: 'Status',
      onClick: () => {},
    },
    {
      id: 'actions',
      label: 'Ações',
      type: 'actionCell',
    },
  ];

  const tableItems: TableData<TableItems>[] = useMemo(() => {
    return users.content.map((user) => {
      return {
        ...user,
        enabled: <Status text={user.enabled ? 'Ativo' : 'Desativado'} />,
        actions: (
          <InnerActions>
            <ActionButton
              tooltip="Editar"
              onClick={() => {
                history.push(`/usuario/editar/${user.id}`);
              }}
            >
              <FiEdit />
            </ActionButton>

            <ActionButton
              tooltip="Franquias vinculadas"
              onClick={() => {
                history.push(`/usuarios/${user.id}/franquias`);
              }}
            >
              <FranchisersIcon />
            </ActionButton>
          </InnerActions>
        ),
      };
    });
  }, [users]);

  return (
    <Container>
      <Breadcrumb links={links} />

      <PageHeader>
        <span>Lista de usuários</span>
      </PageHeader>

      <Table
        items={tableItems}
        columns={tableColumns}
        isLoading={loading}
        header={
          <TableHeader>
            <SearchInput
              name="search"
              onTextChange={handleSearchChange}
              placeholder="Digite aqui para pesquisar"
            />
            <Button
              text="Novo usuário"
              icon="add"
              onClick={() => history.push('/usuario/novo')}
            />
          </TableHeader>
        }
        placeholder="Nenhum usuário encontrado"
        pagination={users.pagination}
        onPageChange={handlePageChange}
        onLimitChange={handleLimitChange}
      />
    </Container>
  );
};

export default UsersPage;
