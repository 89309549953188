import styled from 'styled-components';

export const Container = styled.div`
  height: 5rem;

  color: #000;
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  a {
    font-size: 0.875rem;
    font-weight: 600;
    display: flex;
    align-items: center;
    color: #9fa1a8;

    & > svg {
      margin: 0 0.4rem;
    }

    &.active {
      color: #005af9;
    }

    @media (max-width: 992px) {
      & > span.ellipsed {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        max-width: 5rem;
      }
    }
  }

  .last {
    & path {
      fill: #005af9;

      stroke: #005af9;
    }
  }

  @media (max-width: 992px) {
    height: 2rem;

    margin: 2rem 0;
  }
`;
