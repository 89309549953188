import styled from 'styled-components';

export const Content = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  padding: 1.5rem 0;

  height: 100%;

  & > h1 {
    font-weight: 500;
    font-size: 1.25rem;

    color: #27282b;
  }
`;

export const DocumentsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 2.5rem;

  flex: 1;
`;

export const Documents = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  flex: 1;

  @media (max-width: 992px) {
    & > div {
      width: 100%;

      & > div {
        width: 100% !important;
      }
    }
  }
`;
