import React, { CSSProperties, ForwardedRef, InputHTMLAttributes } from 'react';
import { Container, RadioContainer, RadioIndicator } from './styles';

export type Option = {
  id?: string;
  label: string;
  value: string;
};

interface RadioGroupProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  name?: string;
  options: Option[];
  value?: string;
  error?: string;
  style?: CSSProperties;
  rowMode?: boolean;
}

const RadioGroup = React.forwardRef(
  (props: RadioGroupProps, myRef: ForwardedRef<HTMLInputElement>) => {
    const {
      label,
      options,
      value,
      error,
      style,
      name,
      rowMode = false,
      ...rest
    } = props;

    return (
      <Container style={style} rowMode={rowMode}>
        {label && label}

        <div>
          {options.map((option) => {
            return (
              <RadioContainer key={option.id ?? option.label} htmlFor={name}>
                <RadioIndicator isChecked={option.value === value} />
                <input
                  ref={myRef}
                  type="radio"
                  name={name}
                  id={name}
                  value={option.value}
                  checked={option.value === value}
                  {...rest}
                />
                {option.label}
              </RadioContainer>
            );
          })}
        </div>
        {error && <span>{error}</span>}
      </Container>
    );
  }
);

RadioGroup.displayName = 'RadioGroup';

export default RadioGroup;
