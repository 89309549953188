import { Fragment, ReactNode, useEffect } from 'react';
import { useLocation } from 'react-router';

type ScrollToTopProps = {
  children: ReactNode;
};

const ScrollToTop: React.FC<ScrollToTopProps> = (props) => {
  // eslint-disable-next-line react/prop-types
  const { children } = props;

  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  // eslint-disable-next-line react/react-in-jsx-scope
  return <Fragment>{children}</Fragment>;
};

export default ScrollToTop;
