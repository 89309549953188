import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useAuth } from '../../contexts/auth';
import { userURLByRole } from '../../helper/UserURLByRole';
import { UserRoles } from '../../types/users';
import SelectInput from '../select';
import { Container } from './styles';

type OptionsType = {
  label: string;
  value: string;
  enabled: boolean;
};

type FranchiseSelectorProps = {};

export const FranchiseSelector: React.FC<FranchiseSelectorProps> = () => {
  const history = useHistory();

  const { pathname } = history.location;

  const { franchises, selectFranchise, selectedFranchise, user } = useAuth();

  const isAllowedToRedirect = pathname === '/franquia/selecionar';

  const isEditBudgetFlow =
    pathname.split('/').includes('orcamentos') &&
    pathname.split('/').includes('editar');

  const [selected, setSelected] = useState<string>();

  const [franchiseOptions, setFranchiseOptions] = useState<OptionsType[]>([]);

  useEffect(() => {
    setSelected(selectedFranchise ?? '');

    if (isAllowedToRedirect && selectedFranchise) {
      history.push(userURLByRole(user.role as keyof typeof UserRoles).loggedIn);
    }
  }, [selectedFranchise, isAllowedToRedirect]);

  useEffect(() => {
    setFranchiseOptions(
      franchises.map((item) => {
        const franchise = item.franchise;

        return {
          label: !franchise.enabled
            ? franchise.nickname + ' (Inativa)'
            : franchise.nickname,
          value: franchise.id,
          enabled: franchise.enabled,
        };
      })
    );
  }, [franchises]);

  const handleSelectChange = (franchise: string) => {
    selectFranchise(franchise);
  };

  return (
    <Container>
      <SelectInput
        isDisabled={isEditBudgetFlow}
        name="franchise"
        onChange={(option) => {
          handleSelectChange((option?.value as string) ?? '');
        }}
        options={franchiseOptions}
        placeholder="Selecione uma franquia"
        isClearable
        isOptionDisabled={(option) => !(option as OptionsType).enabled}
        value={
          franchiseOptions.find((franchise) => franchise.value === selected) ??
          ''
        }
      />
    </Container>
  );
};
