export const accessoriesType = [
  {
    label: 'Cabo',
    value: 'cable',
  },
  {
    label: 'Haste de cobre',
    value: 'copper-rod',
  },
  {
    label: 'Disjuntor',
    value: 'circuit-breaker',
  },
  {
    label: 'Tubulação (infraestrutura)',
    value: 'pipe',
  },
  {
    label: 'Parafusos',
    value: 'screws',
  },
  {
    label: 'Outro',
    value: 'other',
  },
];

export const units = [
  {
    label: 'Unidades',
    value: 'units',
  },
  {
    label: 'Metros',
    value: 'meters',
  },
  {
    label: 'Centímetros',
    value: 'centimeters',
  },
  {
    label: 'Litros',
    value: 'liters',
  },
  {
    label: 'Metros quadrados',
    value: 'square-meters',
  },
  {
    label: 'Metros cúbicos',
    value: 'cubic-meters',
  },
];
