import styled from 'styled-components';

export const Content = styled.div`
  width: 100%;

  position: relative;
`;

export const Header = styled.header`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 1.5rem;

  width: 100%;

  & > span {
    color: #2d3339;
    font-size: 1rem;
    font-family: 'Nunito Sans', sans-serif;
    font-weight: 500;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  & > h1 {
    font-family: 'DunbarTallBold';
    font-size: 1.5rem;
    font-weight: 500;

    letter-spacing: 0.03em;

    color: #000000;
  }

  & > h2 {
    font-family: 'Montserrat';
    font-size: 1rem;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;

  width: 100%;
`;
