import styled from 'styled-components';

export const Content = styled.main`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  padding: 1.5rem 0;

  height: 100%;
`;

export const CustomKitsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  padding: 1.5rem 3rem;
  margin: 0 -2rem;

  background-color: #ffffff;

  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.15) inset;

  & > header {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 2rem;

    & > div {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;

      & > h1 {
        color: #00299b;
        font-family: 'Montserrat', sans-serif;
        font-size: 1.25rem;
        font-style: normal;
        font-weight: 600;
        letter-spacing: 0.0125rem;
      }

      & > p {
        color: #00299b;

        font-family: 'DunbarTextRegular', sans-serif;
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        letter-spacing: 0.02rem;
      }

      & > span {
        display: inline-block;

        color: #00299b;

        font-family: 'Montserrat', sans-serif;

        font-size: 0.875rem;
        font-style: normal;
        font-weight: 400;
        letter-spacing: 0.0175rem;

        & > strong {
          color: #00299b;
          text-align: center;

          font-family: 'DunbarTextRegular', sans-serif;
          font-size: 1rem;
          font-style: normal;
          font-weight: 400;
          letter-spacing: 0.02rem;

          &.generation-selected {
            color: #25b007;
          }
        }
      }
    }
  }
`;

export const CustomKitsWrapper = styled.div`
  display: flex;

  background-color: #ffffff;

  overflow-x: auto;

  padding: 1.5rem 3rem;
  margin: 0 -3rem;

  &::-webkit-scrollbar {
    width: 8rem;
    height: 0.5rem;

    border-radius: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #ffffff;

    border-radius: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background: #00299b;

    border-radius: 8px;
  }

  & > table {
    width: 100%;

    border-spacing: 0;
    border-collapse: collapse;

    & > thead {
      & > tr {
        & > th {
          text-align: left;

          white-space: nowrap;

          padding: 1rem 1.5rem;

          height: 3.5rem;

          border-bottom: #e0e0e0 solid 1px;

          color: #00299b;

          font-family: 'Montserrat', sans-serif;
          font-size: 0.875rem;
          font-style: normal;
          font-weight: 600;
        }
      }
    }

    & > tbody {
      & > tr {
        & > td {
          padding: 1rem 1.5rem;

          height: 6.25rem;

          position: relative;

          white-space: nowrap;

          color: #00299b;

          font-family: 'Montserrat', sans-serif;
          font-size: 0.875rem;
          font-style: normal;
          font-weight: 400;

          & > strong {
            display: flex;
            flex-direction: column;

            font-weight: 600;
            font-size: 0.875rem;

            color: #00299b;

            white-space: nowrap;

            & > span {
              font-weight: 500;
              font-size: 0.875rem;

              color: #4e6bb9;
            }
          }

          background-color: #fff;
        }
      }
    }
  }
`;

export const Actions = styled.div`
  display: flex;

  align-items: center;
  gap: 0.75rem;

  & > button[data-tip='Visualizar'] {
    border: 1px solid #00299b;

    box-shadow: 0;

    & > svg {
      color: #00299b;
    }
  }
`;

export const TableFooter = styled.tfoot``;
