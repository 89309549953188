import styled, { css } from 'styled-components';

type Props = {
  open: boolean;
};

export const Container = styled.aside<Props>`
  box-shadow: inset -1px 0px 0px #e1e0e7;

  background-color: #00299b;

  padding: 0 0.5rem;

  width: min(18.5rem);

  & > div {
    position: sticky;
    top: 0;
  }

  @media screen and (max-width: 520px) {
    right: -100px;
    position: fixed;
    bottom: 0;
    top: 0;
    transition: -webkit-transform 0.5s ease;
    transition: transform 0.5s ease;
    -webkit-transform: translateX(110%);
    -ms-transform: translateX(110%);
    transform: translateX(110%);
    z-index: 999;

    width: min(18rem);

    box-shadow: unset;

    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;

    padding: 2rem 0.5rem;

    ${(props) =>
      props.open &&
      css`
        right: 0;
        -webkit-transform: translateX(0);
        -ms-transform: translateX(0);
        transform: translateX(0);
      `}

    .menu-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
`;

export const Logo = styled.div`
  height: 5rem;
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 520px) {
    width: 132px;
    height: 32px;

    padding-left: 1rem;

    & > img {
      width: 100%;

      object-fit: cover;
    }
  }
`;

export const CloseButton = styled.button`
  display: none;

  width: 32px;
  height: 32px;

  background: #4e6bb9;
  border-radius: 16px;

  @media screen and (max-width: 520px) {
    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 0;
  }
`;

export const Content = styled.div`
  margin-top: 2rem;

  max-height: calc(90vh - 8rem);
  overflow-y: auto;

  padding: 0 0.5rem 2rem;

  &::-webkit-scrollbar {
    width: 2px;

    border-radius: 8px;
  }

  &::-webkit-scrollbar-track {
    background-color: #00299b;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #f5f5f6;
  }

  & > ul {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    & > li {
      display: block;

      > a {
        font-weight: 500;
        font-size: 0.875rem;

        color: #f5f5f6;

        height: 3rem;
        width: 100%;

        display: flex;
        align-items: center;

        border-radius: 5px;

        & > span {
          margin-left: 0.5rem;
        }

        & > svg {
          margin-left: 1rem;

          width: 1.5rem;
          height: 1.5rem;

          color: #f5f5f5;

          & > path {
            color: #f5f5f5;

            stroke: #f5f5f5;
            stroke-width: 2px;
          }
        }

        &:hover {
          background-color: #c8d2ef;
          color: #00299b;

          font-weight: bold;

          & > svg {
            color: #00299b;

            & > path {
              stroke: #00299b;
            }
          }
        }
      }

      > a.active {
        background-color: #c8d2ef;
        color: #00299b;

        font-weight: bold;

        & > svg {
          color: #00299b;

          & > path {
            stroke: #00299b;
          }
        }
      }

      & > button {
        margin-top: 0.5rem;

        border: 0;

        display: flex;
        align-items: center;

        color: #f5f5f6;
        background-color: #00299b;

        & > span {
          margin-left: 0.625rem;

          font-weight: 500;
        }

        & > svg {
          margin-left: 1.125rem;

          & > path {
            stroke: #fff;
          }
        }

        &:hover {
          color: #c8d2ef;

          & > svg {
            color: #c8d2ef;

            & > path {
              stroke: #c8d2ef;
            }
          }
        }
      }
    }
  }
`;

export const Divider = styled.hr`
  border-top: 1px solid #444;
  margin: 2.5rem 1rem;
`;
