import React from 'react';
import { Breadcrumb, Button, StepProgress } from '../../../../../components';
import { Content, Wrapper, Divisor, Header, Grid } from './styles';
import { useHistory, useParams } from 'react-router-dom';
import { ButtonGroup, Container, PageHeader } from '../../../../styles';
import SelectInput from '../../../../../components/select';
import { Infos } from './components/infos';
import { AddColumn } from './components/add-column';
import { Columns } from './components/columns';

const steps = [
  { key: 1, title: 'Selecionar produto', active: false, complete: true },
  { key: 2, title: 'Cadastro do cliente', active: false, complete: true },
  { key: 3, title: 'Selecionar Kits', active: false, complete: true },
  { key: 4, title: 'Personalizar kit', active: true, complete: false },
  { key: 5, title: 'Serviços', active: false, complete: false },
  { key: 6, title: 'Proposta', active: false, complete: false },
];

const CustomKitBuildKitPage: React.FC = () => {
  const history = useHistory();

  const { pathname } = history.location;

  const hasEditPath = pathname.includes('editar');

  const { id } = useParams<{ id: string }>();

  const links = [
    {
      id: 1,
      title: 'Orçamentos',
      link: '/orcamentos',
      active: false,
    },
    {
      id: 2,
      title: 'Selecionar produto',
      link: '/orcamentos/selecionar-produto',
      active: false,
    },
    {
      id: 3,
      title: 'Cadastro do cliente',
      link: hasEditPath
        ? `/orcamentos/selecionar-produto/cadastro-cliente/${id}/editar`
        : `/orcamentos/selecionar-produto/cadastro-cliente`,
      active: false,
    },
    {
      id: 4,
      title: 'Seleção de kits',
      link: hasEditPath
        ? `/orcamentos/selecionar-produto/cadastro-cliente/${id}/selecao-de-kits/editar`
        : `/orcamentos/selecionar-produto/cadastro-cliente/${id}/selecao-de-kits/`,
      active: false,
    },
    {
      id: 5,
      title: 'Personalizar kit',
      link: hasEditPath
        ? `/orcamentos/selecionar-produto/cadastro-cliente/${id}/selecao-de-kits/personalizar-kit/editar`
        : `/orcamentos/selecionar-produto/cadastro-cliente/${id}/selecao-de-kits/personalizar-kit/`,
      active: true,
    },
  ];

  return (
    <Container>
      <Breadcrumb links={links} />
      <PageHeader>
        <span>{'Personalizar Kit'}</span>
        <div>
          <StepProgress steps={steps} />
        </div>
      </PageHeader>

      <Content>
        <Header>
          <h1>Configuração de layout</h1>
          <div>
            <SelectInput label="Fornecedor" placeholder="Selecione uma opção" />

            <SelectInput label="Categoria" placeholder="Selecione uma opção" />
          </div>
        </Header>

        <Divisor />

        <Grid>
          <Wrapper>
            <h1>Adicionar colunas</h1>

            <AddColumn />
          </Wrapper>

          <Wrapper>
            <h1>Colunas adicionadas</h1>

            <Columns />
          </Wrapper>

          <Wrapper>
            <h1>Material de apoio</h1>

            <Infos />
          </Wrapper>
        </Grid>

        <ButtonGroup>
          <Button
            text="Voltar"
            typeStyle="default"
            backgroundHoverColor="#C9CBCF"
            onClick={() => {
              history.push(
                `/orcamentos/selecionar-produto/cadastro-cliente/${id}/selecao-de-kits/personalizar-kit/${
                  hasEditPath ? 'editar' : ''
                }`
              );
            }}
          />

          <Button
            text="Avançar"
            onClick={() => {
              history.push(
                `/orcamentos/selecionar-produto/cadastro-cliente/${id}/selecao-de-kits/personalizar-kit/montar-kit/selecao-de-produtos/`
              );
            }}
          />
        </ButtonGroup>
      </Content>
    </Container>
  );
};

export default CustomKitBuildKitPage;
