import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  & label {
    cursor: pointer;

    color: #131415;
    font-family: 'Nunito Sans', sans-serif;
  }
`;
