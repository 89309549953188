import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;

  min-height: 60vh;

  & > div:last-child {
    margin-top: auto;
  }
`;

export const Header = styled.header`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  & > h1 {
    font-family: 'DunbarTallBold', sans-serif;
    font-weight: 500;
    font-size: 1.125rem;

    letter-spacing: 0.03em;

    color: #27282b;
  }

  & > p {
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    font-size: 1.125rem;

    letter-spacing: 0.02em;

    color: #27282b;
  }
`;

export const Content = styled.main``;

export const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  & > div {
    padding: 1.5rem 1.5rem 0;
    margin: 0 -1.5rem;

    overflow: auto;

    flex: 1;

    &::-webkit-scrollbar {
      height: 0.5rem;

      border-radius: 8px;
    }

    &::-webkit-scrollbar-track {
      background: #ffffff;

      border-radius: 8px;
    }

    &::-webkit-scrollbar-thumb {
      background: #00299b;

      border-radius: 8px;
    }

    & > table {
      width: 100%;
      border-spacing: 0;
      border-collapse: collapse;

      & > thead {
        & > tr {
          & > th {
            text-align: left;

            white-space: nowrap;

            padding: 1rem 1.5rem;

            border-bottom: #e0e0e0 solid 1px;

            color: #00299b;

            font-family: 'Montserrat', sans-serif;
            font-size: 0.875rem;
            font-style: normal;
            font-weight: 600;
          }
        }
      }

      & > tbody {
        & > tr {
          & > td {
            padding: 1rem 1.5rem;

            white-space: nowrap;

            color: #00299b;

            font-family: 'Montserrat', sans-serif;
            font-size: 0.875rem;
            font-style: normal;
            font-weight: 400;

            & > strong {
              display: flex;
              flex-direction: column;

              font-weight: 600;
              font-size: 0.875rem;

              color: #00299b;

              white-space: nowrap;

              & > span {
                font-weight: 500;
                font-size: 0.875rem;

                color: #4e6bb9;
              }
            }

            background-color: #fff;
          }
        }
      }
    }
  }
`;

export const TableFooter = styled.tfoot``;

export const TableHeader = styled.header`
  & > h1 {
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    font-size: 1.25rem;

    letter-spacing: 0.01em;

    color: #00299b;
  }
`;

export const ValuesData = styled.span`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;

  white-space: nowrap;

  font-weight: 500;
  color: ${(props) => props.theme.mediumBlue};

  padding: 1rem 0 !important;

  & > span {
    font-size: 0.875rem;
    padding: 0 !important;
  }

  & > strong {
    color: ${(props) => props.theme.darkBlue};
  }

  & > span.hr {
    width: 100%;
    height: 1px;
    border-bottom: 1px solid ${(props) => props.theme.mediumBlue};
  }
`;
