import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Breadcrumb, Button, StepProgress } from '../../../../../../components';
import { ButtonGroup, Container, PageHeader } from '../../../../../styles';
import Textarea from '../../../../../../components/textarea';

import { Content, Header } from './styles';

const steps = [
  { key: 1, title: 'Selecionar produto', active: false, complete: true },
  { key: 2, title: 'Cadastro do cliente', active: false, complete: true },
  { key: 3, title: 'Selecionar Kits', active: false, complete: true },
  { key: 4, title: 'Personalizar kit', active: true, complete: false },
  { key: 5, title: 'Serviços', active: false, complete: false },
  { key: 6, title: 'Proposta', active: false, complete: false },
];

const CustomKitAnalysisRequestPage: React.FC = () => {
  const history = useHistory();

  const { pathname } = history.location;

  const hasEditPath = pathname.includes('editar');

  const { id } = useParams<{ id: string }>();

  const links = [
    {
      id: 1,
      title: 'Orçamentos',
      link: '/orcamentos',
      active: false,
    },
    {
      id: 2,
      title: 'Selecionar produto',
      link: '/orcamentos/selecionar-produto',
      active: false,
    },
    {
      id: 3,
      title: 'Cadastro do cliente',
      link: hasEditPath
        ? `/orcamentos/selecionar-produto/cadastro-cliente/${id}/editar`
        : `/orcamentos/selecionar-produto/cadastro-cliente`,
      active: false,
    },
    {
      id: 4,
      title: 'Seleção de kits',
      link: hasEditPath
        ? `/orcamentos/selecionar-produto/cadastro-cliente/${id}/selecao-de-kits/editar`
        : `/orcamentos/selecionar-produto/cadastro-cliente/${id}/selecao-de-kits/`,
      active: false,
    },
    {
      id: 5,
      title: 'Personalizar kit',
      link: hasEditPath
        ? `/orcamentos/selecionar-produto/cadastro-cliente/${id}/selecao-de-kits/personalizar-kit/editar`
        : `/orcamentos/selecionar-produto/cadastro-cliente/${id}/selecao-de-kits/personalizar-kit/`,
      active: true,
    },
  ];

  return (
    <Container>
      <Breadcrumb links={links} />
      <PageHeader>
        <span>Solicitação de análise</span>
        <div>
          <StepProgress steps={steps} />
        </div>
      </PageHeader>

      <Content>
        <Header>
          <h1>Seu pedido precisa ser analisado</h1>

          <p>
            O número de <strong>Proteções</strong> está inferior ao recomendado.
          </p>
        </Header>

        <Textarea
          height="12rem"
          label="Justificativa"
          placeholder="Type here..."
        />

        <ButtonGroup>
          <Button
            text="Voltar"
            typeStyle="default"
            backgroundHoverColor="#C9CBCF"
            onClick={() =>
              history.push(
                `/orcamentos/selecionar-produto/cadastro-cliente/${id}/selecao-de-kits/personalizar-kit/montar-kit/selecao-de-produtos/${
                  hasEditPath ? 'editar' : ''
                }`
              )
            }
          />

          <Button
            type="submit"
            text="Enviar"
            onClick={() =>
              history.push(
                `/orcamentos/selecionar-produto/cadastro-cliente/${id}/selecao-de-kits/personalizar-kit/${
                  hasEditPath ? 'editar' : ''
                }`
              )
            }
          />
        </ButtonGroup>
      </Content>
    </Container>
  );
};

export default CustomKitAnalysisRequestPage;
