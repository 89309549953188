import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { Breadcrumb, Button } from '../../../components';
import DynamicFilter, {
  FilterOption,
  Option,
} from '../../../components/dynamic-filter';
import Table, { ColumnStructure, TableData } from '../../../components/table';
import InnerActions from '../../../components/table/innerActions';
import { useAuth } from '../../../contexts/auth';
import productsApi from '../../../services/products';
import { EMPTY_PAGE, PaginatedResponse } from '../../../types/pagination';
import { Container, PageHeader } from '../../styles';
import ConfirmationModal from './components/Modal';
import PercentageInput from './components/PercentageInput';
import { ModalContent, ModalSummary, TableHeader } from './styles';

enum FiltersLabels {
  supplier = 'Fornecedores',
  category = 'Categoria',
}

enum RestoredFilterLabels {
  Fornecedores = 'supplier',
  Categoria = 'category',
}

type Filters = {
  category: string[];
  supplier: string[];
};

type ProductResponse = {
  supplier: string;
  category: string;
  discount: number;
  rebate: number;
  count: number;
};

type DiscountAndAbatement = {
  [key: string]: number | undefined;
};

type TableItems = {
  id: string;
  supplier: string;
  category: string;
  discount: JSX.Element;
  rebate: JSX.Element;
  actions: JSX.Element;
};

const links = [
  {
    id: 1,
    title: 'Produtos',
    link: '/produtos',
    active: false,
  },
  {
    id: 2,
    title: 'Descontos e Rebates',
    link: '/produtos/descontos-rebates',
    active: true,
  },
];

const ProductsDiscountAbatementPage: React.FC = () => {
  const { selectedFranchise: franchiseId } = useAuth();

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalContent, setModalContent] = useState({} as ProductResponse);

  const [discount, setDiscount] = useState<DiscountAndAbatement | null>(null);
  const [abatement, setAbatement] = useState<DiscountAndAbatement | null>(null);

  const [filterOptions, setFilterOptions] = useState([] as FilterOption[]);
  const [filters, setFilters] = useState({} as Filters);
  const [loading, setLoading] = useState(false);

  const [products, setProducts] =
    useState<PaginatedResponse<ProductResponse>>(EMPTY_PAGE);

  const handleUpdateProduct = async (product: ProductResponse) => {
    setModalIsOpen(false);

    try {
      setLoading(true);

      await productsApi.put(`/products/groups`, {
        supplier: product.supplier,
        category: product.category,
        discount: product.discount,
        rebate: product.rebate,
      });

      getProducts(1).then((products) => {
        setProducts(products);
      });

      return toast.success('Desconto e rebate atualizados.', {
        autoClose: 1200,
      });
    } catch (error) {
      toast.error('Não foi possível atualizar o desconto e rebate.');
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const getProducts = async (
    page: number,
    limit?: number
  ): Promise<PaginatedResponse<ProductResponse>> => {
    setLoading(true);

    try {
      const { data } = await productsApi.get<
        PaginatedResponse<ProductResponse>
      >(`/products/groups`, {
        params: {
          page,
          limit: limit ? limit : products.pagination.limit,
          supplier: filters.supplier,
          category: filters.category,
        },
      });
      return data;
    } catch (error) {
      toast.error('Não foi possível buscar os produtos.');
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const normalizeFilters = (options: Filters) => {
    const formatLabel = {
      category(value: string | number) {
        return `${value}W`;
      },
    };

    return Object.entries(options)
      .map(([field, value]) => {
        const label = FiltersLabels[field as keyof typeof FiltersLabels];

        const options = value
          .map((item) => ({
            label: formatLabel[field as keyof typeof formatLabel]
              ? formatLabel[field as keyof typeof formatLabel](item)
              : item,
            value: item,
          }))
          .sort((a, b) => a.label.localeCompare(b.label));

        return {
          label,
          options,
        };
      })
      .sort((a, b) => a.label.localeCompare(b.label));
  };

  const handleModal = (product: ProductResponse) => {
    setModalContent(product);
    setModalIsOpen(true);
  };

  const handleFilters = (options: Option[]) => {
    const normalizeSelectedFilters = options.reduce(
      (acc, option) => {
        return {
          ...acc,
          [RestoredFilterLabels[
            option.label as keyof typeof RestoredFilterLabels
          ]]: [
            ...acc[
              RestoredFilterLabels[
                option.label as keyof typeof RestoredFilterLabels
              ] as keyof typeof filters
            ],
            option.value,
          ],
        };
      },
      {
        category: [],
        supplier: [],
      } as Filters
    );

    setFilters(normalizeSelectedFilters);
  };

  const handlePageChange = useCallback(
    (page: number) => {
      getProducts(page, products.pagination.limit).then((products) => {
        setProducts(products);
      });
    },
    [products]
  );

  const handleLimitChange = useCallback(
    (limit: number) => {
      getProducts(products.pagination.currentPage, limit).then((products) => {
        setProducts(products);
      });
    },
    [products]
  );

  const getFilters = useCallback(async () => {
    try {
      const { data } = await productsApi.get('/products/filters/', {
        params: {
          fields: 'category,supplier',
          franchiseId: franchiseId || undefined,
        },
      });

      return data;
    } catch (error) {
      toast.error('Não foi possível buscar os filtros.');
    }
  }, []);

  useEffect(() => {
    getFilters().then((filters) => setFilterOptions(normalizeFilters(filters)));
  }, []);

  useEffect(() => {
    getProducts(1).then((products) => {
      setProducts(products);
    });
  }, [filters]);

  useEffect(() => {
    products.content.forEach((product) => {
      setAbatement((state) => ({
        ...state,
        [product.supplier + product.category]: product.rebate,
      }));

      setDiscount((state) => ({
        ...state,
        [product.supplier + product.category]: product.discount,
      }));
    });
  }, [products]);

  const tableColumns: ColumnStructure[] = [
    {
      id: 'supplier',
      label: 'Fornecedor',
      onClick: () => {},
    },
    {
      id: 'category',
      label: 'Categoria',
      onClick: () => {},
    },
    {
      id: 'discount',
      label: 'Desconto',
      onClick: () => {},
    },
    {
      id: 'rebate',
      label: 'Rebate',
      onClick: () => {},
    },
    {
      id: 'actions',
      label: 'Ações',
      type: 'actionCell',
    },
  ];

  const tableItems: TableData<TableItems>[] = useMemo(() => {
    return products.content.map((product) => {
      return {
        id: product.category + product.supplier,
        supplier: product.supplier,
        category: product.category + 'W',
        discount: (
          <PercentageInput
            value={discount ? discount[product.supplier + product.category] : 0}
            type="number"
            onChange={(e) =>
              setDiscount((state) => ({
                ...state,
                [product.supplier + product.category]: Number(e.target.value),
              }))
            }
          />
        ),
        rebate: (
          <PercentageInput
            value={
              abatement ? abatement[product.supplier + product.category] : 0
            }
            onChange={(e) =>
              setAbatement((state) => ({
                ...state,
                [product.supplier + product.category]: Number(e.target.value),
              }))
            }
          />
        ),
        actions: (
          <InnerActions>
            <Button
              text="Cancelar"
              typeStyle="default"
              backgroundHoverColor="#C9CBCF"
              style={{
                height: '2.5rem',
              }}
              disabled={
                abatement && discount
                  ? abatement[product.supplier + product.category] ==
                      product.rebate &&
                    discount[product.supplier + product.category] ==
                      product.discount
                  : false
              }
              onClick={() => {
                setAbatement((state) => ({
                  ...state,
                  [product.supplier + product.category]: product.rebate,
                }));

                setDiscount((state) => ({
                  ...state,
                  [product.supplier + product.category]: product.discount,
                }));
              }}
            />
            <Button
              text="Atualizar"
              disabled={
                abatement && discount
                  ? abatement[product.supplier + product.category] ==
                      product.rebate &&
                    discount[product.supplier + product.category] ==
                      product.discount
                  : false
              }
              style={{ height: '2.5rem' }}
              onClick={() => {
                discount &&
                  abatement &&
                  handleModal({
                    ...product,
                    discount: discount[
                      product.supplier + product.category
                    ] as number,
                    rebate: abatement[
                      product.supplier + product.category
                    ] as number,
                  });
              }}
            />
          </InnerActions>
        ),
      };
    });
  }, [products, discount, abatement]);

  return (
    <Container>
      <Breadcrumb links={links} />

      <PageHeader>
        <span>{'Descontos e Rebates'}</span>
      </PageHeader>

      <Table
        items={tableItems}
        columns={tableColumns}
        isLoading={loading}
        header={
          <TableHeader>
            <DynamicFilter filters={filterOptions} onChange={handleFilters} />
          </TableHeader>
        }
        pagination={products.pagination}
        onPageChange={handlePageChange}
        onLimitChange={handleLimitChange}
      />

      <ConfirmationModal
        title="Alteração de desconto e rebate"
        contentLabel="Modal de Confirmação"
        isOpen={modalIsOpen}
        onConfirm={() => handleUpdateProduct(modalContent)}
        onCancel={() => setModalIsOpen(false)}
        onRequestClose={() => setModalIsOpen(false)}
      >
        <ModalContent>
          <p>
            Tem certeza que essa categoria e fornecedor possuem os valores de
            desconto e rebate configurados?
          </p>

          <ModalSummary>
            <div>
              <span>
                Fornecedor: <strong>{modalContent.supplier}</strong>
              </span>
              <span>
                Categoria: <strong>{modalContent.category}W</strong>
              </span>
            </div>
            <div>
              <span>
                Desconto: <strong>{modalContent.discount}</strong>
              </span>
              <span>
                Rebate: <strong>{modalContent.rebate}</strong>
              </span>
            </div>
          </ModalSummary>
        </ModalContent>
      </ConfirmationModal>
    </Container>
  );
};

export default ProductsDiscountAbatementPage;
