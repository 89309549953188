import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.25rem;

  min-height: 20vh;

  & > h1 {
    font-weight: 900;
    font-size: 1.125rem;

    color: #27282b;
  }

  & > p {
    font-weight: 400;
    font-size: 1.125rem;

    color: #27282b;
  }

  & > div {
    margin: auto 0 0;
  }
`;
