import styled, { css } from 'styled-components';

type ContainerProps = {
  rowMode: boolean;
};

export const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.5rem;

  font-size: 1rem;
  font-weight: 500;
  color: #27282b;

  width: fit-content;

  & > div {
    min-height: 3rem;

    ${({ rowMode }) =>
      rowMode
        ? css`
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 1.25rem;
          `
        : css`
            display: flex;
            flex-direction: column;
            gap: 0.25rem;
          `}
  }

  & > span {
    color: #e01919;

    font-size: 0.875rem;
  }
`;

export const RadioContainer = styled.label`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;

  height: 100%;

  position: relative;

  font-weight: 500;
  font-size: 0.875rem;

  color: #27282b;

  white-space: nowrap;

  & > input[type='radio'] {
    height: 1px;
    position: absolute;
    width: 1px;
  }
`;

type RadioIndicatorProps = {
  isChecked: boolean;
};

export const RadioIndicator = styled.span<RadioIndicatorProps>`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 16px;
  height: 16px;

  background: #ffffff;

  border: 1px solid #005af9;
  border-radius: 200px;

  position: relative;

  ${({ isChecked }) =>
    isChecked &&
    css`
      &::after {
        display: flex;
        justify-content: center;
        align-items: center;

        content: '';

        width: 8px;
        height: 8px;

        border-radius: 200px;

        background-color: #005af9;
      }
    `}
`;
