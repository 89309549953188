import React from 'react';
import { Container, RadioItem } from './styles';

type RadioOption = {
  label: string;
  value: string;
};

type RadioProps = {
  options: RadioOption[];
  name: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange: (e: any) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value?: any;
};

const Radio: React.FC<RadioProps> = ({ options, name, onChange, value }) => {
  return (
    <Container>
      {options.map((option) => (
        <RadioItem
          key={option.value}
          htmlFor={option.value}
          checked={option.value === value}
        >
          <input
            type="radio"
            value={option.value}
            id={option.value}
            name={name}
            onChange={onChange}
            checked={option.value === value}
          />
          {option.label}
        </RadioItem>
      ))}
    </Container>
  );
};

export default Radio;
