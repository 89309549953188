import styled, { css } from 'styled-components';

type groupType = {
  size?: 'small' | 'medium';
};

export const Group = styled.div<groupType>`
  ${(props) =>
    props.size === 'small' &&
    css`
      max-width: 21.875rem;
    `}
  ${(props) =>
    props.size === 'medium' &&
    css`
      max-width: 35.625rem;
    `}

    span {
    display: inline-block;
    margin-bottom: 1rem;
    color: #27282b;
    font-weight: 600;
  }

  div.project-files {
    margin-top: 1rem;

    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
  }

  div.mb-16 {
    margin-bottom: 2rem;
  }
`;

export const Columns = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;

  div {
    margin-left: 1rem;
  }
`;

export const File = styled.a`
  min-height: 3rem;

  border: 0;
  background: #7ed56b;
  border-radius: 4px;

  padding: 1rem;

  font-size: 0.875rem;
  color: #121212;

  @media (max-width: 520px) {
    width: 100%;

    text-align: center;
  }
`;
