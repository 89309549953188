import React from 'react';
import { ShapeModal } from '..';
import { Button } from '../..';
import { Buttons } from '../styles';
import { Content } from './styles';

type Props = {
  onConfirm: () => void;
  onCancel: () => void;
  isOpen: boolean;
  isAdmin: boolean;
};

export const InfoProfitabilityModal: React.FC<Props> = (props) => {
  const { onConfirm, onCancel, isOpen, isAdmin } = props;

  return (
    <ShapeModal
      isOpen={isOpen}
      onRequestClose={onConfirm}
      shouldCloseOnEsc={false}
      shouldCloseOnOverlayClick={false}
    >
      <Content>
        <h1>Atualização no sistema</h1>

        {isAdmin && (
          <article>
            <h1>Olá!</h1>

            <p>
              Atualizamos o sistema no dia 06 de maio. Sendo assim, você
              precisará reconfigurar seus parâmetros de lucratividade.
            </p>

            <div>
              <p>Agora, você poderá editar os seguintes dados:</p>

              <ul>
                <li>Margem de instalação mínima;</li>
                <li>Margem de instalação ideal;</li>
                <li>Comissão do vendedor (valores percentuais);</li>
                <li>Comissão por indicação (valores percentuais);</li>
                <li>Royalties (valores percentuais);</li>
                <li>Nota fiscal (valores percentuais);</li>
              </ul>
            </div>
          </article>
        )}

        {!isAdmin && (
          <article className="seller">
            <h1>Olá!</h1>

            <p>
              Atualizamos o sistema no dia 06 de maio. Sendo assim, sua franquia
              precisará reconfigurar os parâmetros de lucratividade.
            </p>
            <p>
              Fale com o administrador da franquia e peça que ele realize a
              configuração.
            </p>
          </article>
        )}

        <Buttons>
          {isAdmin && (
            <Button
              type="button"
              typeStyle="confirm"
              text="Configurar lucratividade"
              onClick={onConfirm}
            />
          )}

          {!isAdmin && (
            <Button
              type="button"
              text="Cancelar"
              typeStyle="default"
              backgroundHoverColor="#C9CBCF"
              onClick={onCancel}
            />
          )}
        </Buttons>
      </Content>
    </ShapeModal>
  );
};
