import styled from 'styled-components';
import { Level as LevelType, levelColors } from '.';

export const Container = styled.div`
  max-width: 22rem;
  width: 100%;
  height: 29.25rem;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  border-radius: 8px;
  border: 1px solid #c9cbcf;
  padding: 1.25rem 1rem 2.5rem;
  background-color: #fff;

  @media (max-width: 520px) {
    height: fit-content;
    max-width: unset;
  }
`;

export const Title = styled.h3`
  font-family: 'DunbarTallRegular';
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 1.5rem;
  letter-spacing: 0.03em;
  color: #27282b;
`;

type LevelProps = {
  level: LevelType;
  version: 'primary' | 'secondary';
};

export const Level = styled.span<LevelProps>`
  font-family: 'DunbarTallBold';
  font-weight: 750;
  font-size: ${(props) => (props.version == 'primary' ? '6rem' : '4rem')};
  line-height: 100%;

  color: ${(props) => levelColors[props.level]};

  user-select: none;
`;

export const LastClassification = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3rem;
`;

export const CurrentClassification = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
`;

export const Details = styled.div`
  width: 100%;

  display: flex;
  justify-content: space-evenly;
  gap: 1rem;

  & > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;

    & > span {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    & > p {
      font-family: 'DunbarTextRegular';
      font-weight: 400;
      font-size: 1rem;
      line-height: 1.5rem;
      letter-spacing: 0.02em;
      color: #000000;
      white-space: nowrap;
    }
  }
`;

export const ChartContainer = styled.div`
  width: 104px;
  height: 104px;

  position: relative;

  & > p {
    width: 100%;
    text-align: center;

    font-family: 'DunbarTallBold';
    font-weight: 700;
    font-size: 1.25rem;
    line-height: 100%;
    letter-spacing: 0.03em;
    color: #27282b;

    position: absolute;
    top: 40px;
  }
`;
