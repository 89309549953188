import React from 'react';
import ReactTooltip from 'react-tooltip';
import { Container } from './styles';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  tooltip?: string;
}

export const ActionButton: React.FC<ButtonProps> = ({
  tooltip,
  children,
  ...rest
}) => {
  return (
    <>
      <Container type="button" data-tip={tooltip} {...rest}>
        {children}
      </Container>
      <ReactTooltip place="top" effect="solid" globalEventOff="click" />
    </>
  );
};
