import { format } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import React, { useState } from 'react';
import {
  FiCalendar,
  FiEdit,
  FiMinusCircle,
  FiPlusCircle,
  FiX,
} from 'react-icons/fi';
import { Props } from 'react-modal';
import { useHistory } from 'react-router-dom';
import { ShapeModal } from '../..';
import { useSalesFunnel } from '../../../../contexts/sales-funnel';
import { Lead, STATES_UF } from '../../../../contexts/sales-funnel/types';
import { Button } from '../components/button';
import { InnerLostModal } from '../inner-modals/lost';
import { InnerScheduleModal } from '../inner-modals/schedule';
import {
  Aside,
  Buttons,
  Container,
  Content,
  Customer,
  Data,
  Header,
  Schedule,
  Wrapper,
} from './styles';
import { Tags } from '../components/tags';
import { LeadModal } from '../lead-modal';

type ScheduledModalProps = {
  onCancel: () => void;
  content: Lead | null;
} & Props;

export const ScheduledModal: React.FC<ScheduledModalProps> = (props) => {
  const { onCancel, content, ...rest } = props;

  const history = useHistory();

  const { leadModal, handleCRMData, handleLeadModal } = useSalesFunnel();

  const [modals, setModals] = useState({
    schedule: {
      isOpen: false,
    },
    lost: {
      isOpen: false,
    },
  });

  const handleOpenModals = (modal: keyof typeof modals) => {
    setModals((state) => {
      if (modal in state) {
        return {
          ...state,
          [modal]: {
            isOpen: true,
          },
        };
      }

      return state;
    });
  };

  const handleCloseModals = (modal: keyof typeof modals) => {
    setModals((state) => {
      if (modal in state) {
        return {
          ...state,
          [modal]: {
            isOpen: false,
          },
        };
      }

      return state;
    });
  };

  const createBudget = () => {
    handleCRMData(content);
    history.push('/orcamentos/selecionar-produto/cadastro-cliente');
  };

  return (
    <ShapeModal
      padding="0"
      maxWidth="60rem"
      onRequestClose={onCancel}
      {...rest}
    >
      <Container>
        <Header>
          <h1>Agendado</h1>

          <button onClick={onCancel} type="button">
            <FiX />
          </button>
        </Header>

        <Content>
          <Tags lead={content} />
          <Wrapper>
            <Customer>
              <div>
                <header>
                  <h1>Dados do cliente</h1>

                  <Button
                    variant="white"
                    type="button"
                    maxWidth="7.313rem"
                    onClick={() => handleLeadModal(content)}
                  >
                    <FiEdit />
                    Editar
                  </Button>
                </header>

                <div>
                  <strong>{content?.customer.name}</strong>
                  <p>
                    Tipo de instalação:
                    <strong>{content?.customer.sector}</strong>
                  </p>
                  <p>
                    Canal de origem:<strong>{content?.customer.origin}</strong>
                  </p>
                  <p>
                    Vendedor:
                    <strong>{content?.franchiseUser?.user.name}</strong>
                  </p>
                </div>
              </div>
              <div>
                <header>
                  <h1>Endereço do cliente</h1>
                </header>

                {content?.customer.address && (
                  <div>
                    <p>
                      {content?.customer.address},{' '}
                      {content?.customer.addressNumber}
                    </p>

                    <p>
                      {content?.customer.district} - {content?.customer.city} -{' '}
                      {
                        STATES_UF[
                          content?.customer.state as keyof typeof STATES_UF
                        ]
                      }
                    </p>

                    <p>
                      CEP: <strong>{content?.customer.zipcode}</strong>
                    </p>
                  </div>
                )}

                {!content?.customer.address && (
                  <div>
                    <p>Nenhum endereço cadastrado.</p>
                  </div>
                )}
              </div>
            </Customer>

            <Aside>
              <Data>
                <header>
                  <h1>Dados do agendamento</h1>
                </header>
                <Schedule>
                  {content?.schedule && (
                    <div>
                      <FiCalendar />
                      <strong>
                        {format(
                          new Date(content?.schedule?.scheduledTo),
                          "dd 'de' MMMM 'de' yyyy",
                          {
                            locale: ptBR,
                          }
                        )}
                      </strong>
                    </div>
                  )}

                  <p>{content?.schedule?.description}</p>
                </Schedule>
              </Data>
              <Buttons>
                <Button
                  variant="blue"
                  type="button"
                  maxWidth="100%"
                  onClick={createBudget}
                >
                  <FiPlusCircle />
                  Gerar orçamento
                </Button>
                <Button
                  variant="red"
                  type="button"
                  maxWidth="100%"
                  onClick={() => handleOpenModals('lost')}
                >
                  <FiMinusCircle />
                  Orçamento perdido
                </Button>
                <Button
                  variant="white"
                  type="button"
                  maxWidth="100%"
                  onClick={() => handleOpenModals('schedule')}
                >
                  <FiCalendar />
                  Agendar novo contato
                </Button>
              </Buttons>
            </Aside>
          </Wrapper>
        </Content>
      </Container>

      <InnerScheduleModal
        scheduleId={content?.schedule?.id ?? ''}
        onConfirm={onCancel}
        isOpen={modals.schedule.isOpen}
        onCancel={() => {
          handleCloseModals('schedule');
          onCancel();
        }}
      />

      <InnerLostModal
        leadId={content?.id ?? ''}
        isOpen={modals.lost.isOpen}
        onCancel={() => handleCloseModals('lost')}
        onConfirm={() => {
          handleCloseModals('lost');

          onCancel();
        }}
      />

      <LeadModal
        isOpen={leadModal.isOpen}
        lead={leadModal.data}
        onCancel={() => {
          handleLeadModal(null);
          onCancel();
        }}
      />
    </ShapeModal>
  );
};
