import styled from 'styled-components';

export const File = styled.div`
  background: #7ed56b;
  border-radius: 4px;
  min-height: 48px;
  min-width: 150px;
  padding: 16px;

  font-size: 14px;
  a {
    color: #121212;
  }

  &:not(:first-child) {
    margin-left: 16px;
  }
`;

export const FilesContainer = styled.div`
  display: flex;
`;
