export const personTypeRadioOptions = [
  {
    label: 'Física',
    value: 'Física',
  },
  {
    label: 'Jurídica',
    value: 'Jurídica',
  },
];

export const solicitationTypeRadioOptions = [
  {
    label: 'Microgeração igual ou inferior a 10kw',
    value: 'Microgeração igual ou inferior a 10kw',
  },
  {
    label: 'Microgeração igual ou superior a 10kw',
    value: 'Microgeração igual ou superior a 10kw',
  },
  {
    label: 'Minigeração',
    value: 'Minigeração',
  },
];

export const ucClassOptions = [
  {
    label: 'Residencial',
    value: 'Residencial',
  },
  {
    label: 'Comercial',
    value: 'Comercial',
  },
  {
    label: 'Industrial',
    value: 'Industrial',
  },
  {
    label: 'Rural',
    value: 'Rural',
  },
  {
    label: 'Iluminação pública',
    value: 'Iluminação pública',
  },
  {
    label: 'Outros',
    value: 'Outros',
  },
];

export const neutralPhaseVoltageOptions = [
  {
    label: '127/220v',
    value: '127/220v',
  },
  {
    label: '220/380v',
    value: '220/380v',
  },
];

export const connectionTypeOptions = [
  {
    label: 'Monofásica',
    value: 'Monofásica',
  },
  {
    label: 'Bifásica',
    value: 'Bifásica',
  },
  {
    label: 'Trifásica',
    value: 'Trifásica',
  },
];

export const branchOptions = [
  {
    label: 'Aéreo',
    value: 'Aéreo',
  },
  {
    label: 'Subterrâneo',
    value: 'Subterrâneo',
  },
];

export const generationSourceOptions = [
  {
    label: 'Solar',
    value: 'Solar',
  },
];
