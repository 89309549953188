import React, { ButtonHTMLAttributes, ReactNode } from 'react';
import { Container, Variant } from './styles';

type ButtonProps = {
  variant: Variant;
  maxWidth: string;
  children: ReactNode;
} & ButtonHTMLAttributes<HTMLButtonElement>;

export const Button: React.FC<ButtonProps> = (props) => {
  const { maxWidth = '22.625rem', variant, children, ...rest } = props;

  return (
    <Container maxWidth={maxWidth} variant={variant} {...rest}>
      {children}
    </Container>
  );
};
