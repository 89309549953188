import axios from 'axios';
import { axiosConfig } from './config';

const crmApi = axios.create({
  baseURL: process.env.REACT_APP_CRM_MICROSERVICE_URL,
});

axiosConfig(crmApi);

export default crmApi;
