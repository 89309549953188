import React, { useEffect, useState } from 'react';
import {
  ActionButton,
  Breadcrumb,
  Button,
  StepProgress,
} from '../../../../../components';
import { ButtonGroup, Container, PageHeader } from '../../../../styles';
import {
  Actions,
  Content,
  CustomKitsContainer,
  CustomKitsWrapper,
  TableFooter,
} from './styles';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import { FiEye, FiTrash2 } from 'react-icons/fi';
import Count from '../../../../../components/count';
import { KitDescriptionModal } from '../../../../../components/modal/kit-description';
import SelectInput from '../../../../../components/select';
import productsApi from '../../../../../services/products';
import { toast } from 'react-toastify';
import { Budget, CustomItem } from '../../../../../contexts/budgets/types';
import { toMoneyFormat } from '../../../../../utils/toMoneyFormat';
import Loading from '../../../../../components/loading';

const steps = [
  { key: 1, title: 'Selecionar produto', active: false, complete: true },
  { key: 2, title: 'Cadastro do cliente', active: false, complete: true },
  { key: 3, title: 'Selecionar Kits', active: false, complete: true },
  { key: 4, title: 'Personalizar kit', active: true, complete: false },
  { key: 5, title: 'Serviços', active: false, complete: false },
  { key: 6, title: 'Proposta', active: false, complete: false },
];

const orientationOptions = [
  {
    label: 'Norte',
    value: 'NORTE',
  },
  {
    label: 'Sul',
    value: 'SUL',
  },
  {
    label: 'Leste',
    value: 'LESTE',
  },
  {
    label: 'Oeste',
    value: 'OESTE',
  },
];

const CustomKitCompositionPage: React.FC = () => {
  const history = useHistory();

  const [customItem, setCustomItem] = useState<CustomItem | null>(null);

  const hasItems = !!customItem?.kits.length;

  const [loading, setLoading] = useState(true);

  const [kitDescription, setKitDescription] = useState('');

  const hasKitDescription = !!kitDescription;

  const handleKitDescriptionModal = (description: string) =>
    setKitDescription(description);

  const { pathname, search } = history.location;

  const supplier = customItem?.supplier;

  const hasEditPath = pathname.includes('editar');

  const { id, customItemId } = useParams<{
    id: string;
    customItemId: string;
  }>();

  const links = [
    {
      id: 1,
      title: 'Orçamentos',
      link: '/orcamentos',
      active: false,
    },
    {
      id: 2,
      title: 'Selecionar produto',
      link: '/orcamentos/selecionar-produto',
      active: false,
    },
    {
      id: 3,
      title: 'Cadastro do cliente',
      link: hasEditPath
        ? `/orcamentos/selecionar-produto/cadastro-cliente/${id}/editar`
        : `/orcamentos/selecionar-produto/cadastro-cliente`,
      active: false,
    },
    {
      id: 5,
      title: 'Personalizar kit',
      link: `/orcamentos/selecionar-produto/cadastro-cliente/${id}/personalizar-kit/`,
      active: true,
    },
  ];

  const handleSelectionComposition = async () => {
    history.push(
      `/orcamentos/selecionar-produto/cadastro-cliente/${id}/personalizar-kit/`
    );
  };

  const deleteCustomKit = async (kitId: string) => {
    try {
      await productsApi.delete(`/budgets/${id}/delete-custom-kit/${kitId}`);

      setCustomItem((state) => {
        if (state) {
          return {
            ...state,
            kits: state.kits.filter((kit) => kit.id !== kitId),
          };
        }

        return state;
      });
    } catch (error) {
      toast.error('Desculpe, não foi possível remover esse item');
    }
  };

  const getCustomItem = async () => {
    try {
      const { data } = await productsApi.get<CustomItem>(
        `/budgets/${customItemId}/custom-item`
      );

      setCustomItem(data);
    } catch (error) {
      toast.error(
        'Desculpe, não foi possível buscar as informações da composição'
      );
    } finally {
      setLoading(false);
    }
  };

  const updateCustomKit = async (
    kitId: string,
    orientation: string,
    quantity: number
  ) => {
    try {
      const { data } = await productsApi.patch<Budget>(
        `/budgets/${kitId}/update-kits`,
        {
          budgetId: id,
          orientation,
          quantity: quantity > 0 ? quantity : 1,
        }
      );

      const updatedCustomItem = data.items.find(
        (item) => item.budgetCustomItemId === customItem?.id
      );

      if (updatedCustomItem) {
        const { customItem } = updatedCustomItem;

        const updatedKit = customItem?.kits.find((kit) => kit.id === kitId);

        setCustomItem((state) => {
          if (state && updatedKit) {
            return {
              ...state,
              kits: state.kits.map((kit) =>
                kit.id === kitId ? updatedKit : kit
              ),
            };
          }

          return state;
        });
      }
    } catch (error) {
      toast.error('Desculpe, não foi possível atualizar o kit');
    }
  };

  useEffect(() => {
    getCustomItem();
  }, []);

  if (loading) {
    return <Loading />;
  }

  if (!hasItems) {
    return (
      <Redirect
        to={`/orcamentos/selecionar-produto/cadastro-cliente/${id}/personalizar-kit/selecionar-kits${search}`}
      />
    );
  }

  return (
    <Container>
      <Breadcrumb links={links} />
      <PageHeader>
        <span>{'Personalizar Kit'}</span>
        <div>
          <StepProgress steps={steps} />
        </div>
      </PageHeader>

      <Content>
        <CustomKitsContainer>
          <header>
            <div>
              <h1>Composição</h1>
              <p>{supplier}</p>
            </div>

            {/* <div>
              <span>
                Geração calculada: <strong>99.9 KWp</strong>
              </span>
              <span>
                Geração selecionada:{' '}
                <strong className="generation-selected">111 KWp</strong>
              </span>
            </div> */}
          </header>

          <CustomKitsWrapper>
            <table>
              <thead>
                <tr>
                  <th>Marca</th>
                  <th>Categoria</th>
                  <th>Kit</th>
                  <th>Inversor</th>
                  <th>Valores</th>
                  <th>Quantidade</th>
                  <th>Orientação</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {customItem?.kits.map((kit) => {
                  const { product } = kit;

                  const defaultOrientation = orientationOptions.find(
                    (orientation) => orientation.value === kit.orientation
                  );

                  return (
                    <tr key={product.id}>
                      <td>
                        <strong>{product.supplier}</strong>
                      </td>
                      <td>
                        <strong>{product.fabricMaterial}</strong>
                      </td>
                      <td>
                        <strong>
                          {kit.generationBySolarPlate} KWp | {product.voltage}V
                          <span>
                            {kit.solarPlatesNumber} módulos |{' '}
                            {kit.totalGeneration + 'KWh'}
                          </span>
                        </strong>
                      </td>
                      <td>
                        <strong>{product.inverterType}</strong>
                      </td>
                      <td>
                        <strong>Kit: {toMoneyFormat(kit.total)}</strong>
                      </td>
                      <td>
                        <Count
                          name={kit.id}
                          value={kit.quantity}
                          onIncrement={() =>
                            updateCustomKit(
                              kit.id,
                              kit.orientation,
                              kit.quantity + 1
                            )
                          }
                          onDecrement={() =>
                            updateCustomKit(
                              kit.id,
                              kit.orientation,
                              kit.quantity - 1
                            )
                          }
                        />
                      </td>
                      <td>
                        <SelectInput
                          menuPosition="fixed"
                          isSearchable={false}
                          placeholder="Selecione uma opção"
                          options={orientationOptions}
                          style={{
                            width: '7rem',
                            height: '2rem',
                            fontSize: '0.875rem',
                          }}
                          defaultValue={defaultOrientation}
                          onChange={(option) =>
                            updateCustomKit(
                              kit.id,
                              String(option.value),
                              kit.quantity
                            )
                          }
                        />
                      </td>
                      <td>
                        <Actions>
                          <ActionButton
                            tooltip="Remover"
                            onClick={() => deleteCustomKit(kit.id)}
                          >
                            <FiTrash2 className="delete-icon" />
                          </ActionButton>
                          <ActionButton
                            tooltip="Visualizar"
                            onClick={() =>
                              handleKitDescriptionModal(product.description)
                            }
                          >
                            <FiEye />
                          </ActionButton>
                        </Actions>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
              <TableFooter />
            </table>
          </CustomKitsWrapper>
        </CustomKitsContainer>

        <ButtonGroup>
          <Button
            text="Voltar"
            typeStyle="default"
            backgroundHoverColor="#C9CBCF"
            onClick={() => {
              history.push(
                `/orcamentos/selecionar-produto/cadastro-cliente/${id}/personalizar-kit/selecionar-kits/${customItemId}/editar${search}`
              );
            }}
          />

          <Button text="Avançar" onClick={handleSelectionComposition} />
        </ButtonGroup>
      </Content>

      <KitDescriptionModal
        isOpen={hasKitDescription}
        description={kitDescription}
        onCancel={() => handleKitDescriptionModal('')}
      />
    </Container>
  );
};

export default CustomKitCompositionPage;
