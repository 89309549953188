import styled from 'styled-components';

export const Content = styled.main`
  display: flex;
  flex-direction: column;
  gap: 2.5rem;

  padding: 1.5rem 0;
`;

export const TableHeader = styled.header`
  display: flex;
  justify-content: flex-end;

  background-color: #fafafa;
`;

