import React, { useEffect, useRef, useState } from 'react';
import { Breadcrumb, Button, FileUpload } from '../../../components';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { toast } from 'react-toastify';
import { Container, Wrapper, PageHeader } from './../styles';
import { useHistory, useParams } from 'react-router-dom';
import {
  FeedbackType,
  PowerCompanyFeedbackType,
  ProjectStatus,
  UploadTypes,
} from './../types';
import { FormContainer, InputGroup, TableContainer } from '../../styles';
import Textarea from '../../../components/form-elements/textarea';

import { ButtonContainer } from './style';
import projectsApi from '../../../services/projects';
import { useAuth } from '../../../contexts/auth';
import { format } from 'date-fns';
import FileUploadService from '../../../services/FileUploadService';
import { Files } from '../../../types/files';
import Radio from '../../../components/form-elements/radio';
import axios from 'axios';

type ControlledInputTypes = {
  status: string;
};

type PowerCompanyFeedbackPageProps = {
  id: string;
};

const PowerCompanyFeedbackPage: React.FC = () => {
  const history = useHistory();
  const formRef = useRef<FormHandles>(null);
  const [feedbacks, setFeedbacks] = useState<PowerCompanyFeedbackType[]>([]);
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState<Files[]>([]);
  const { id } = useParams<PowerCompanyFeedbackPageProps>();
  const { user } = useAuth();
  const links = [
    {
      id: 1,
      title: 'Projetos',
      link: '/projetos',
      active: false,
    },
    {
      id: 2,
      title: 'Lista de Projetos',
      link: '/projetos',
      active: true,
    },
  ];

  const [values, setValues] = useState<ControlledInputTypes>({
    status: 'Aprovado',
  });

  const feedbackOptions = [
    {
      label: 'Aprovado',
      value: ProjectStatus.PROJETO_APROVADO,
    },
    {
      label: 'Recusado',
      value: ProjectStatus.PROJETO_REPROVADO,
    },
  ];

  const schema = yup.object().shape({
    status: yup.string().required('Concessionária é um campo obrigatório'),
    description: yup.string().required(),
  });

  useEffect(() => {
    loadFiles();
  }, []);

  const loadFiles = () => {
    FileUploadService.getFiles(id, '').then((res) => {
      const normalizied = res.data.map((file: any) => ({
        id: file.id,
        name: file.originalName,
        url: file.path,
        type: file.type,
        file_url: file.file_url,
      }));
      setFiles(normalizied);
    });
  };

  const getFiles = (type: string) => {
    return files.filter((file) => file.type === type);
  };

  useEffect(() => {
    if (!loading) {
      projectsApi
        .get<PowerCompanyFeedbackType[]>(
          `/api/projects/${id}/project-power-company-feedback`
        )
        .then((res) => {
          setFeedbacks(res.data);
        });
    }
  }, [loading]);

  const { handleSubmit, setValue } = useForm<FeedbackType>({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data: FeedbackType) => {
    try {
      setLoading(true);
      await projectsApi.post(
        `/api/projects/${id}/project-power-company-feedback`,
        {
          userId: user.id,
          observation: data.description,
          status: data.status,
        }
      );

      await projectsApi.patch(`/api/projects/${id}/status`, {
        status: data.status,
      });

      toast.success('Parecer criado com sucesso');
      history.push('/projetos/central-atendimento');
      setLoading(false);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        toast.error(error.response?.data.message);
      } else {
        toast.error('Erro desconhecido, tente novamente mais tarde.');
      }
    }
  };

  return (
    <Container>
      <Breadcrumb links={links} />
      <Wrapper>
        <PageHeader>
          <span>Parecer concessionária</span>
        </PageHeader>
        <FormContainer>
          <Form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
            <InputGroup>
              <span>Status do projeto:</span>
              <Radio
                options={feedbackOptions}
                name="status"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setValue('status', e.target.value);
                  setValues({
                    ...values,
                    status: e.target.value,
                  });
                }}
                value={values.status}
              />
            </InputGroup>
            <InputGroup>
              <FileUpload
                title="Parecer da concessionária"
                helpMessage="Permitido somente arquivos .jpg, .png e .pdf com no máximo 5MB"
                loadedFiles={getFiles(UploadTypes.PARECER_CONCESSIONARIA)}
                projectId={id}
                type={UploadTypes.PARECER_CONCESSIONARIA}
                onLoadFiles={() => loadFiles()}
              />
            </InputGroup>
            <InputGroup>
              <Textarea
                name="description"
                label="Observações"
                onChange={(e) => {
                  setValue('description', e.target.value);
                }}
              />
            </InputGroup>

            <ButtonContainer>
              <div>
                <Button
                  text="Voltar"
                  backgroundColor="#DFE0E2"
                  onClick={() => history.push('/projetos/engenharia')}
                />
              </div>
              <div>
                <Button
                  text="Salvar"
                  backgroundColor="#005AF9"
                  backgroundHoverColor="#2772f8"
                />
              </div>
            </ButtonContainer>

            <TableContainer>
              <div>
                <table>
                  <thead>
                    <tr>
                      <th>Status</th>
                      <th>Usuário</th>
                      <th>Data</th>
                      <th>Observação</th>
                    </tr>
                  </thead>
                  <tbody>
                    {feedbacks.map((feedback) => (
                      <tr key={feedback.id}>
                        <td>{feedback.status}</td>
                        <td>{feedback.user.name}</td>
                        <td>
                          {format(
                            new Date(feedback.createdAt),
                            'dd/MM/yyyy hh:mm'
                          )}
                        </td>
                        <td>
                          <p>{feedback.observation}</p>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </TableContainer>
          </Form>
        </FormContainer>
      </Wrapper>
    </Container>
  );
};

export default PowerCompanyFeedbackPage;
