import { yupResolver } from '@hookform/resolvers/yup';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import React, { useRef } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { Breadcrumb, Button } from '../../../components';
import Textarea from '../../../components/form-elements/textarea';
import { FormContainer, InputGroup, TableContainer } from '../../styles';
import { Container, PageHeader, Wrapper } from '../styles';
import { ImpedimentType } from '../types';

import Select from '../../../components/form-elements/select';
import { ButtonContainer } from './style';

type ImpedimentPageProps = {
  id: string;
};

const ImpedimentPage: React.FC = () => {
  const history = useHistory();
  const { id } = useParams<ImpedimentPageProps>();
  const formRef = useRef<FormHandles>(null);
  const links = [
    {
      id: 1,
      title: 'Projetos',
      link: '/projetos',
      active: false,
    },
    {
      id: 2,
      title: 'Lista de Projetos',
      link: '/projetos',
      active: true,
    },
  ];

  const reasonOptions = [
    {
      label: 'Aprovado',
      value: 'Aprovado',
    },
    {
      label: 'Recusado',
      value: 'Recusado',
    },
  ];

  const schema = yup.object().shape({
    status: yup.string().required('Concessionária é um campo obrigatório'),
    description: yup.string().required(),
  });

  const {
    handleSubmit,
    formState: { errors },
    setValue,
    control,
  } = useForm<ImpedimentType>({ resolver: yupResolver(schema) });

  const onSubmit = async (data: ImpedimentType) => {
    try {
      null;
    } catch (error: any) {
      toast.error(error.response.data.message);
    }
  };

  return (
    <Container>
      <Breadcrumb links={links} />
      <Wrapper>
        <PageHeader>
          <span>Impedimento</span>
        </PageHeader>
        <FormContainer>
          <Form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
            <InputGroup>
              <span>Motivo do impedimento</span>
              <Controller
                name="reason"
                control={control}
                render={({ field: { onChange, value } }) => {
                  return (
                    <Select
                      name="reason"
                      onChange={onChange}
                      value={value}
                      control={control}
                      placeholder="Selecione uma opção"
                      options={reasonOptions}
                      error={errors.reason?.message}
                    />
                  );
                }}
              />
            </InputGroup>
            <InputGroup>
              <Textarea
                name="description"
                label="Observações"
                onChange={(e) => {
                  setValue('description', e.target.value);
                }}
              />
            </InputGroup>

            <ButtonContainer>
              <div>
                <Button
                  text="Voltar"
                  backgroundColor="#DFE0E2"
                  onClick={() => history.push('/projetos/engenharia')}
                />
              </div>
              <div>
                <Button
                  text="Salvar"
                  backgroundColor="#005AF9"
                  backgroundHoverColor="#2772f8"
                />
              </div>
            </ButtonContainer>

            <TableContainer>
              <div>
                <table>
                  <thead>
                    <tr>
                      <th>Status</th>
                      <th>Parecer</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Aprovado</td>
                      <td>Teste</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </TableContainer>
          </Form>
        </FormContainer>
      </Wrapper>
    </Container>
  );
};

export default ImpedimentPage;
