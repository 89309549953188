import React, { useState } from 'react';
import { FiXCircle } from 'react-icons/fi';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Breadcrumb } from '../../../../../../components/breadcrumb';
import { Button } from '../../../../../../components/button';
import { CancelOrderModal } from '../../../../../../components/modal/cancel-order';
import { Order } from '../../../../../../contexts/orders/types';
import productsApi from '../../../../../../services/products';
import { ButtonGroup, Container, PageHeader } from '../../../../../styles';
import { ORDER_STATUS } from '../../../components/orders/innerTables/consultant';
import { Content, Links, Voucher, Wrapper } from './styles';

type ConsultantLinkProps = {
  order: Order;
};

export const ConsultantLink: React.FC<ConsultantLinkProps> = (props) => {
  const { order } = props;

  const { orderId, status } = useParams<{
    orderId: string;
    status: ORDER_STATUS;
  }>();

  const history = useHistory();

  const links = [
    {
      id: 1,
      title: 'Orçamentos',
      link: '/orcamentos',
      active: false,
    },
    {
      id: 2,
      title: 'Orçamentos e Pedidos',
      link: '/orcamentos/orcamentos-e-pedidos',
      active: false,
    },
    {
      id: 3,
      title: 'Pedidos enviados',
      link: `/orcamentos/orcamentos-e-pedidos/pedidos-enviados/${orderId}/${status}`,
      active: true,
    },
  ];

  const [cancelModal, setCancelModal] = useState(false);

  const cancelOrder = () => {
    productsApi
      .patch(`/orders/${order.id}/status`, {
        status: 'Pedido cancelado',
        franchiseStatus: 'Pedido cancelado',
      })
      .then(() => {
        toast.success('Status do pedido atualizado');

        history.replace('/orcamentos/orcamentos-e-pedidos/');
      })
      .catch((error) => {
        const errorMessage = error.response.data.message;

        toast.error(errorMessage);
      });
  };

  const handleCancelModal = () => {
    setCancelModal((state) => !state);
  };

  return (
    <Container>
      <Breadcrumb links={links} />

      <PageHeader>
        <span>{'Pedidos enviados'}</span>
      </PageHeader>

      <Content>
        <Wrapper>
          <h1>Faça o pagamento através dos links</h1>

          <Links>
            {order.paymentLinks.map((link, index) => {
              return (
                <div key={link.id}>
                  <h1>{`Link ${index + 1}`}</h1>

                  <a href={link.link} target="_blank" rel="noreferrer">
                    {link.link}
                  </a>
                </div>
              );
            })}
          </Links>
        </Wrapper>

        <Voucher>
          <h1>Comprovante de pagamento</h1>

          <div>
            <p>O franqueado ainda não enviou o comprovante de pagamento.</p>

            <Button
              type="button"
              backgroundColor="#E01919"
              backgroundHoverColor="#e01919c1"
              typeStyle="default"
              text="Cancelar pedido"
              onClick={handleCancelModal}
              icon={<FiXCircle />}
            />
          </div>
        </Voucher>

        <ButtonGroup>
          <Button
            type="button"
            text="Voltar"
            typeStyle="default"
            backgroundHoverColor="#C9CBCF"
            onClick={() =>
              history.replace('/orcamentos/orcamentos-e-pedidos', 'Pedidos')
            }
          />
        </ButtonGroup>
      </Content>

      <CancelOrderModal
        isOpen={cancelModal}
        onConfirm={cancelOrder}
        onCancel={handleCancelModal}
      />
    </Container>
  );
};
