import styled, { css } from 'styled-components';

type ContainerProps = {
  isMultiple: boolean;
};

export const Container = styled.div<ContainerProps>`
  height: ${(props) => (props.isMultiple ? 'auto' : '10rem')};

  background: #ffffff;
  border: ${(props) => (props.isMultiple ? 'none' : '1px solid #c9cbcf')};
  border-radius: 8px;

  padding: ${(props) => (props.isMultiple ? 0 : '1rem 1.25rem 1.25rem')};

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.875rem;

  width: min(16.125rem, 100%);

  white-space: nowrap;

  & > div {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
  }

  @media (max-width: 520px) {
    width: 100%;
  }
`;
export const Title = styled.h2`
  font-weight: 500;
  font-size: 1rem;
  line-height: 1rem;
  text-align: center;
  color: #61646b;
`;

export const ValueContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const CurrentValue = styled.span`
  font-family: 'DunbarTallBold';
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 100%;
  text-align: center;
  letter-spacing: 0.03em;
  color: #00299b;
`;

export const LastValue = styled(CurrentValue)`
  font-size: 1rem;
  color: #0090a3;
`;

type IndicatorProps = {
  version: 'negative' | 'positive';
};

export const Indicator = styled.span<IndicatorProps>`
  display: flex;
  justify-content: center;
  align-items: center;

  font-family: 'Roboto';
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 100%;

  padding: 0.25rem;
  border-radius: 999px;

  & > svg {
    font-size: 1rem;
  }

  ${(props) => {
    switch (props.version) {
      case 'negative':
        return css`
          color: #e01919;
          background-color: #fdeded;

          & > svg {
            rotate: 180deg;
          }
        `;

      case 'positive':
        return css`
          color: #1a7605;
          background-color: #d4f1ce;
        `;
    }
  }}
`;
