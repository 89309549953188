import styled, { css } from 'styled-components';

export const Container = styled.div`
  height: 100%;
`;

export const Wrapper = styled.div`
  margin: 24px 48px;
`;

export const PageHeader = styled.div`
  display: flex;
  justify-content: space-between;

  span {
    font-weight: bold;
    font-size: 32px;
    line-height: 32px;

    color: #292929;
  }
`;

export const Content = styled.div`
  align-items: center;
  color: #292929;
  max-width: 100%;
  margin-top: 32px;
  border: 1px solid #dfe0e2;
  border-radius: 16px;
  background-color: #fff;
  padding: 48px;

  p {
    margin-top: 16px;
  }
`;

export const Divider = styled.hr`
  border: 1px solid #e0e0e0;
  margin: 48px 0;
`;

export const GroupedInputs = styled.div`
  display: flex;
`;

type InputGroup = {
  sideSpace?: 'left' | 'right';
  size?: 'small';
};

export const InputGroup = styled.div<InputGroup>`
  margin-top: 48px;
  display: flex;
  flex-direction: column;
  width: 100%;

  span {
    display: inline-block;
    margin-bottom: 8px;
  }

  ${(props) =>
    props.sideSpace === 'right' &&
    css`
      margin-right: 16px;
    `}

  ${(props) =>
    props.size === 'small' &&
    css`
      width: 25%;
    `}
`;
