import React, { ReactElement, useRef } from 'react';
import { RiCoinsFill, RiErrorWarningFill } from 'react-icons/ri';
import { useOnClickOutside } from '../../../../../hooks/outsideClick';
import { Container, Notification, Overlay } from './styles';

type Columns = 'finalProposal' | 'lost' | 'recovery';

type Data = {
  id: string;
  date: string;
  movedTo: Columns;
  name: string;
};

type Props = {
  isOpen: boolean;
  onClose: () => void;
  data: Data[];
};

type ParagraphByAction = {
  [key in Columns]: (value: string) => ReactElement;
};

const paragraphByAction: ParagraphByAction = {
  recovery(name: string) {
    return (
      <p>
        O lead de <strong className="name">{name}</strong> foi movido para a
        coluna <strong>Recuperação de leads</strong> pois está sem atualização a
        pelo menos <strong>15 dias</strong>.
      </p>
    );
  },
  lost(name: string) {
    return (
      <p>
        O lead de <strong className="name">{name}</strong> foi movido para a
        coluna <strong>Perdido</strong> pois está sem atualização a pelo menos{' '}
        <strong>30 dias</strong>.
      </p>
    );
  },
  finalProposal(name: string) {
    return (
      <p>
        Desconto aprovado!
        <br />O lead de <strong className="name">{name}</strong> foi movido para
        a coluna: <strong>Proposta final</strong>
      </p>
    );
  },
};

export const NotificationsSidebar: React.FC<Props> = (props) => {
  const { isOpen, data, onClose } = props;

  const ref = useRef<HTMLDivElement>(null);
  useOnClickOutside(ref, onClose);

  const isWarning = (action: Columns) => ![action].includes('finalProposal');

  return (
    <>
      {isOpen && <Overlay />}
      <Container open={isOpen} ref={ref}>
        {data.map((item) => (
          <Notification
            key={item.id}
            type={isWarning(item.movedTo) ? 'warning' : 'update'}
          >
            {isWarning(item.movedTo) ? (
              <RiErrorWarningFill color="#E01919" fontSize={24} />
            ) : (
              <RiCoinsFill color="#005AF9" fontSize={24} />
            )}
            <div>
              <span>{item.date}</span>
              {paragraphByAction[item.movedTo](item.name)}
            </div>
          </Notification>
        ))}
      </Container>
    </>
  );
};
