import styled from 'styled-components';

export const ButtonContainer = styled.div`
  margin-top: 16px;
  display: flex;
  justify-content: flex-end;

  div {
    margin-left: 16px;
  }
`;
