import styled from 'styled-components';

type ListOptionsProps = {
  opened?: boolean;
};

export const CollapsibleOptionsStyle = styled.li<ListOptionsProps>`
  border-radius: '4px';
  font-weight: bold;
  background-color: ${(props) => (props.opened ? '#c8d2ef' : '')};
  display: block;
  border-radius: ${(props) => (props.opened ? '4px' : '4px')};

  a.active span {
    font-weight: bold;
  }

  &:hover > a {
    span {
      font-weight: bold;
      color: #00299b;
    }
    svg {
      path {
        stroke: #00299b;
      }
    }
  }

  > a {
    border-radius: ${(props) => (props.opened ? '4px ' : '0px')};
    border-bottom: ${(props) => (props.opened ? '1px solid #bbbbbb' : '')};
  }
  > a span {
    font-weight: 500;
    color: ${(props) => (props.opened ? '#00299b' : '#f5f5f6')};
  }
  > a svg {
    path {
      stroke: ${(props) => (props.opened ? '#00299b' : '#f5f5f6')};
    }
  }
`;

export const ListOptions = styled.div<ListOptionsProps>`
  display: block;
  height: ${(props) => (props.opened ? 'fit-content' : '0px')};
  background-color: ${(props) => (props.opened ? '#c8d2ef' : '')};
  border-radius: 0px 0px 4px 4px;
  width: auto;
  overflow: hidden;

  & > ul {
    display: flex;
    flex-direction: column;

    & > li.active {
      background-color: #fff;
      & > a {
        font-weight: bold;
      }
    }

    & > li:hover {
      background-color: #fff;

      & > a {
        color: #00299b;
        font-weight: bold;
      }
    }

    & > li {
      width: 100%;
      margin: 0px 0px;
      padding: 0.5rem;
      display: flex;
      flex-direction: column;

      white-space: nowrap;

      & > a {
        font-weight: 500;
        font-size: 0.875rem;

        color: #00299b;

        height: 3rem;
        width: 100%;

        display: flex;
        align-items: center;
        border-radius: 0px;

        & > span {
          margin-left: 0.5rem;

          transition: font-weight 50ms ease-in-out;
        }

        &:hover {
          background-color: #fff;
          color: #00299b;

          font-weight: bold;

          & > svg {
            & > path {
              stroke: #00299b;
            }
          }
        }
      }
    }
  }
`;

export const Option = styled.li``;

