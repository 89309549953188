/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect } from 'react';
import { Container, ContainerHeader, File, FileContainer } from './styles';

type FileUploadProps = {
  loadedFiles?: Files[];
  title: string;
};

type Files = {
  id: string;
  name: string;
  url: string;
  file_url: string;
};

export const InspectionFiles: React.FC<FileUploadProps> = ({
  title,
  loadedFiles,
}) => {
  const [files, setFiles] = useState<Files[]>([]);

  useEffect(() => {
    if (loadedFiles) {
      setFiles(loadedFiles);
    }
  }, [loadedFiles]);

  return (
    <Container>
      <ContainerHeader>{title}</ContainerHeader>

      <FileContainer>
        {files.map((file) => (
          <File key={file.id}>
            <a href={file.file_url} target="_blank" rel="noreferrer">
              {file.name}
            </a>
          </File>
        ))}
      </FileContainer>
    </Container>
  );
};
