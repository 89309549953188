import { yupResolver } from '@hookform/resolvers/yup';
import { AxiosError } from 'axios';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import {
  Breadcrumb,
  Button,
  InfoIcon,
  StepProgress,
} from '../../../../components';
import { FileInput, IFile } from '../../../../components/file-input';
import Loading from '../../../../components/loading';
import Textarea from '../../../../components/textarea';
import { useAuth } from '../../../../contexts/auth';
import { useOrders } from '../../../../contexts/orders';
import productsApi from '../../../../services/products';
import { ButtonGroup, Container, PageHeader } from '../../../styles';
import { Content, Documents, DocumentsContainer } from './styles';

const steps = [
  { key: 1, title: 'Selecionar kit', active: false, complete: true },
  { key: 2, title: 'Resumo', active: false, complete: true },
  { key: 3, title: 'Acessórios', active: false, complete: true },
  { key: 4, title: 'Custos', active: false, complete: true },
  { key: 5, title: 'Pagamento', active: false, complete: true },
  { key: 6, title: 'Documentos', active: true, complete: false },
  { key: 7, title: 'Termo de aceite', active: false, complete: false },
];

type DocumentsForm = {
  description: string;
  personType: IFile[];
  houseDocumentProof: IFile[];
  bankDocument: IFile[];
  otherDocuments: IFile[];
};

type OrderFiles = {
  PersonType: IFile[];
  HouseDocumentProof: IFile[];
  BankDocument: IFile[];
  OtherDocuments: IFile[];
};

enum PaymentTypes {
  BANK_SLIP = 'Boleto',
  CREDIT_CARD = 'Cartão de crédito',
  PIX = 'Pix',
  FINANCING = 'Financiamento',
  TRANSFER = 'Transferência bancária',
  ENTRY_CREDIT_CARD = 'Entrada + cartão de crédito',
  ENTRY_FINANCING = 'Entrada + financiamento',
}

const schema = yup.object().shape({
  description: yup.string().optional(),
  personType: yup
    .mixed()
    .test('check if field is empty', (value: IFile[], { createError }) => {
      const hasErrors = value.some((file) => file.error);

      if (value.length > 0) {
        return hasErrors
          ? createError({
              path: 'personType',
              message: 'Remova o arquivo com erro',
              type: 'field-error',
            })
          : true;
      } else {
        return createError({
          path: 'personType',
          message: 'Campo obrigatório',
          type: 'field-empty',
        });
      }
    }),
  houseDocumentProof: yup
    .mixed()
    .test('check if field is empty', (value: IFile[], { createError }) => {
      const hasErrors = value.some((file) => file.error);

      if (value.length > 0) {
        return hasErrors
          ? createError({
              path: 'houseDocumentProof',
              message: 'Remova o arquivo com erro',
              type: 'field-error',
            })
          : true;
      } else {
        return createError({
          path: 'houseDocumentProof',
          message: 'Campo obrigatório',
          type: 'field-empty',
        });
      }
    }),
  bankDocument: yup.mixed().optional(),
  otherDocuments: yup.mixed().optional(),
});

const OrdersDocumentsPage: React.FC = () => {
  const { order, getOrder } = useOrders();

  const { selectedFranchise: franchiseId } = useAuth();

  const { id: orderId } = useParams<{ id: string }>();

  const links = [
    {
      id: 1,
      title: 'Orçamentos',
      link: '/orcamentos',
      active: false,
    },
    {
      id: 2,
      title: 'Orçamentos e pedidos',
      link: '/orcamentos/orcamentos-e-pedidos',
      active: false,
    },
    {
      id: 3,
      title: 'Gerar pedido',
      link: '/orcamentos/orcamentos-e-pedidos/',
      active: false,
    },
    {
      id: 4,
      title: 'Selecionar kit',
      link: '/orcamentos/orcamentos-e-pedidos/gerar-pedido/selecione',
      active: false,
    },
    {
      id: 5,
      title: 'Resumo',
      link: `/orcamentos/orcamentos-e-pedidos/gerar-pedido/${orderId}/resumo`,
      active: false,
    },
    {
      id: 6,
      title: 'Acessórios',
      link: `/orcamentos/orcamentos-e-pedidos/gerar-pedido/${orderId}/acessorios`,
      active: false,
    },
    {
      id: 7,
      title: 'Custos',
      link: `/orcamentos/orcamentos-e-pedidos/gerar-pedido/${orderId}/custos`,
      active: false,
    },
    {
      id: 8,
      title: 'Pagamento',
      link: `/orcamentos/orcamentos-e-pedidos/gerar-pedido/${orderId}/formas-de-pagamento`,
      active: false,
    },
    {
      id: 9,
      title: 'Documentos',
      link: `/orcamentos/orcamentos-e-pedidos/gerar-pedido/${orderId}/documentos`,
      active: true,
    },
  ];

  const files = order?.files?.reduce(
    (acc: OrderFiles, file: File) => {
      if (file.type in acc) {
        return {
          ...acc,
          [file.type]: [
            ...acc[file.type as keyof OrderFiles],
            {
              ...file,
              progress: 100,
            },
          ],
        };
      }

      return acc;
    },
    {
      PersonType: [],
      BankDocument: [],
      HouseDocumentProof: [],
      OtherDocuments: [],
    }
  ) ?? {
    PersonType: [],
    BankDocument: [],
    HouseDocumentProof: [],
    OtherDocuments: [],
  };

  const { register, control, handleSubmit } = useForm<DocumentsForm>({
    resolver: yupResolver(schema),
    defaultValues: {
      description: '',
      personType: files.PersonType,
      houseDocumentProof: files.HouseDocumentProof,
      bankDocument: files.BankDocument,
      otherDocuments: files.OtherDocuments,
    },
  });

  const [loading, setLoading] = useState(false);

  const history = useHistory();

  const onSubmit = async ({ description }: DocumentsForm) => {
    setLoading(true);

    try {
      const { data } = await productsApi.post(
        `/orders/${orderId}/acceptance-term`,
        {
          franchiseId,
          interestRate: order?.creditCardInstalmentNumber || 0,
          paymentMethod:
            PaymentTypes[order?.paymentType as keyof typeof PaymentTypes],
          description: description.length ? description : undefined,
        },
        {
          responseType: 'blob',
        }
      );

      const blob = new Blob([data], { type: 'application/pdf' });

      const pdfURL = URL.createObjectURL(blob);

      history.push(
        `/orcamentos/orcamentos-e-pedidos/gerar-pedido/${orderId}/termo-de-aceite`,
        pdfURL
      );
    } catch (error) {
      const errorMessage = (error as AxiosError).response?.data.message;

      switch (errorMessage) {
        case 'Order not found':
          toast.error('Pedido não encontrado');

          break;

        case 'Franchise not found':
          toast.error('Franquia não encontrada');

          break;

        default:
          toast.error('Desculpe, não foi possível gerar o termo');

          break;
      }

      setLoading(false);
    }
  };

  const fetchData = async () => {
    if (!order) {
      await getOrder(orderId);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (loading) {
    return <Loading label="Gerando termo" />;
  }

  return (
    <Container>
      <Breadcrumb links={links} />

      <PageHeader>
        <span>{'Documentos'}</span>
        <div>
          <StepProgress steps={steps} />
        </div>
      </PageHeader>

      <Content onSubmit={handleSubmit(onSubmit)}>
        <h1>Documentos</h1>

        <DocumentsContainer>
          <Documents>
            <Controller
              name="personType"
              control={control}
              render={({
                field: { ref, value, onChange },
                fieldState: { error },
              }) => {
                return (
                  <FileInput
                    ref={ref}
                    required
                    label="Pessoa física ou jurídica"
                    inputLabel="Anexar arquivo"
                    message={
                      <>
                        <InfoIcon />
                        CPF/CNH/Cartão CNPJ
                      </>
                    }
                    error={error?.message}
                    api={productsApi}
                    url={`/orders/upload-file/${orderId}/type/PersonType`}
                    deleteUrl={'/orders/upload-file/'}
                    onChange={(files) => {
                      onChange(files);
                    }}
                    files={value}
                  />
                );
              }}
            />

            <Controller
              name="houseDocumentProof"
              control={control}
              render={({
                field: { ref, value, onChange },
                fieldState: { error },
              }) => {
                return (
                  <FileInput
                    ref={ref}
                    required
                    label="Comprovante de residência"
                    inputLabel="Anexar arquivo"
                    error={error?.message}
                    api={productsApi}
                    url={`/orders/upload-file/${orderId}/type/HouseDocumentProof`}
                    deleteUrl={'/orders/upload-file/'}
                    onChange={(files) => onChange(files)}
                    files={value}
                  />
                );
              }}
            />

            <Controller
              name="bankDocument"
              control={control}
              render={({
                field: { ref, value, onChange },
                fieldState: { error },
              }) => {
                return (
                  <FileInput
                    ref={ref}
                    label="Documento do Banco"
                    inputLabel="Anexar arquivo"
                    message={
                      <>
                        <InfoIcon />
                        Comprovante de financiamento
                      </>
                    }
                    error={error?.message}
                    api={productsApi}
                    url={`/orders/upload-file/${orderId}/type/BankDocument`}
                    deleteUrl={'/orders/upload-file/'}
                    onChange={(files) => onChange(files)}
                    files={value}
                  />
                );
              }}
            />

            <Controller
              name="otherDocuments"
              control={control}
              render={({
                field: { ref, value, onChange },
                fieldState: { error },
              }) => {
                return (
                  <FileInput
                    ref={ref}
                    label="Outros documentos"
                    inputLabel="Anexar arquivo"
                    error={error?.message}
                    api={productsApi}
                    url={`/orders/upload-file/${orderId}/type/OtherDocuments`}
                    deleteUrl={'/orders/upload-file/'}
                    onChange={(files) => onChange(files)}
                    files={value}
                  />
                );
              }}
            />
          </Documents>
          <Textarea
            label="Descrição"
            placeholder="Informe a descrição dos serviços vendidos"
            height="18.5rem"
            {...register('description')}
          />
        </DocumentsContainer>
        <ButtonGroup>
          <Button
            type="button"
            text="Voltar"
            typeStyle="default"
            backgroundHoverColor="#C9CBCF"
            onClick={() =>
              history.push(
                `/orcamentos/orcamentos-e-pedidos/gerar-pedido/${orderId}/formas-de-pagamento/`
              )
            }
          />
          <Button type="submit" text="Gerar termo" />
        </ButtonGroup>
      </Content>
    </Container>
  );
};

export default OrdersDocumentsPage;
