import styled from 'styled-components';

export const ModalContent = styled.div`
  & > p {
    font-weight: 400;

    font-size: 1.125rem;

    color: #27282b;

    margin: 3rem 0;
  }
`;

export const ModalSummary = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  margin-bottom: 2.5rem;

  & > div {
    display: flex;
    flex-direction: column;
  }
`;

type TableHeaderProps = {
  hasProductSelected: boolean;
};

export const TableHeader = styled.header<TableHeaderProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;

  padding: 0 1rem;

  @media (max-width: 1250px) {
    flex-direction: column;

    padding: 0;
  }

  & > div {
    display: flex;
    align-items: center;
    gap: 1rem;

    &:first-child {
      gap: 2rem;

      min-width: fit-content;

      @media (max-width: 1250px) {
        display: ${({ hasProductSelected }) =>
          !hasProductSelected ? 'flex' : 'none'};

        justify-content: flex-end;
        order: 2;
        width: 100%;

        & > label {
          display: none;
        }
      }

      @media (max-width: 992px) {
        & > button {
          width: 100% !important;
        }
      }
    }

    &:last-child {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      width: 100%;

      @media (max-width: 1250px) {
        & > div:first-child {
          max-width: unset;
          order: 2;
        }
        & > div:last-child {
          width: 100%;

          display: flex;
          justify-content: flex-end;
        }
      }

      @media (max-width: 992px) {
        flex-direction: column;

        & > div:last-child > button {
          width: 100%;
        }
      }
    }
  }
`;
