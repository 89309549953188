import styled from 'styled-components';

type StyledCheckboxProps = {
  checked: boolean;
};

export const StyledCheckbox = styled.label<StyledCheckboxProps>`
  width: 1.4rem;
  height: 1.4rem;

  border-radius: 50%;

  background: #f6f6f6;

  display: flex;
  justify-content: center;
  align-items: center;

  img {
    display: ${(props) => (props.checked ? 'flex' : 'none')};
    filter: invert(75%) sepia(11%) saturate(6042%) hue- rotate(30deg)
      brightness(105%) contrast(68%);
  }
`;

export const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  overflow: hidden;
  white-space: nowrap;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
`;

export const Text = styled.label<StyledCheckboxProps>`
  color: ${(props) => (props.checked ? '#FFF' : '#555')};
`;

export const CheckboxContainer = styled.div<StyledCheckboxProps>`
  width: 7.5rem;
  height: 2.25rem;

  padding: 0.5rem;

  border-radius: 5px;

  margin-top: 0.5rem;

  background-color: ${(props) => (props.checked ? '#005AF9' : '#E5E5E5')};

  display: flex;
  align-items: center;
  gap: 0.5rem;
`;
