import { UserRoles } from '../types/users';

export const userURLByRole = (role: keyof typeof UserRoles) => {
  const usersURL = {
    [UserRoles.ADMIN]: {
      loggedIn: '/dashboard',
      signIn: '/dashboard',
    },
    [UserRoles.CENTRAL_ATENDIMENTO]: {
      loggedIn: '/projetos/central-atendimento',
      signIn: '/dashboard',
    },
    [UserRoles.ENGENHEIRO]: {
      loggedIn: '/projetos/engenharia/listar',
      signIn: '/projetos/engenharia/listar',
    },
    [UserRoles.FRANQUEADO]: {
      loggedIn: '/orcamentos',
      signIn: '/franquia/selecionar',
    },
    [UserRoles.CONSULTOR_SUCESSO]: {
      loggedIn: '/orcamentos',
      signIn: '/franquia/selecionar',
    },
    [UserRoles.GERENCIA]: {
      loggedIn: '/dashboard',
      signIn: '/dashboard',
    },
    [UserRoles.SUPERVISAO]: {
      loggedIn: '/dashboard',
      signIn: '/dashboard',
    },
  };

  return usersURL[role];
};
