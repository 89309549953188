import React from 'react';

import { ShapeModal } from '..';
import { Buttons } from '../styles';

import { Button } from '../../button';

type Props = {
  isOpen: boolean;
  onCancel: () => void;
  onConfirm: () => void;
};

export const DeleteBudgetModal = (props: Props) => {
  const { isOpen, onCancel, onConfirm } = props;

  return (
    <ShapeModal isOpen={isOpen}>
      <h1>Remover orçamento</h1>
      <p>Deseja realmente remover esse orçamento?</p>
      <p>
        Essa ação não poderá ser desfeita e você perderá todos os dados deste
        orçamento.
      </p>

      <Buttons>
        <Button
          type="button"
          text="Cancelar"
          typeStyle="default"
          backgroundHoverColor="#C9CBCF"
          onClick={onCancel}
        />
        <Button
          type="button"
          text="Confirmar"
          backgroundColor="#ED5A5A"
          backgroundHoverColor="#ed5a5abc"
          onClick={onConfirm}
        />
      </Buttons>
    </ShapeModal>
  );
};
