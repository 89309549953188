import React, { InputHTMLAttributes, useEffect, useRef } from 'react';
import { useField } from '@unform/core';

import { InputText, Container, Error } from './styles';

interface InputPros extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  label?: string;
  error?: string;
}

const Input = React.forwardRef(
  ({ name, label, error, ...rest }: InputPros, ref) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const { fieldName, defaultValue, registerField } = useField(name);

    useEffect(() => {
      registerField({
        name: fieldName,
        ref: inputRef.current,
        path: 'value',
      });
    }, [fieldName, registerField]);

    return (
      <Container>
        {label && <label htmlFor={name}>{label}</label>}
        <InputText
          id={name}
          ref={inputRef}
          type="text"
          defaultValue={defaultValue}
          error={error ? true : false}
          {...rest}
        />
        {error && <Error>{error}</Error>}
      </Container>
    );
  }
);
Input.displayName = 'Input';
export default Input;
