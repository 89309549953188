import axios from 'axios';
import { axiosConfig } from './config';

const franchisesApi = axios.create({
  baseURL: process.env.REACT_APP_FRANCHISE_MICROSERVICE_URL,
});

axiosConfig(franchisesApi);

export default franchisesApi;
