import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import React, { useRef, useState } from 'react';
import { Calendar } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { FiCalendar, FiChevronDown } from 'react-icons/fi';
import { CSSProperties } from 'styled-components';
import { useOnClickOutside } from '../../hooks/outsideClick';
import { removeTimeZone } from '../../utils/removeTimeZone';
import { Container } from './styles';

interface DatePickerProps {
  date?: Date | null;
  onChange: (Date: Date) => void;
  months?: number;
  direction?: 'horizontal' | 'vertical';
  minDate?: Date;
  maxDate?: Date;
  label?: string;
  style?: CSSProperties;
  placeholder?: string;
  error?: string;
  iconColor?: string;
  required?: boolean;
}

export const DatePicker = React.forwardRef<Calendar, DatePickerProps>(
  (props, ref) => {
    const {
      date,
      onChange,
      direction = 'horizontal',
      months = 1,
      label,
      style,
      placeholder = 'Data',
      error,
      iconColor,
      required = false,
      ...rest
    } = props;

    const [isOpen, setIsOpen] = useState(false);

    const MyRef = useRef<HTMLDivElement>(null);

    useOnClickOutside(MyRef, () => setIsOpen(false));

    return (
      <Container ref={MyRef} style={style} isOpen={isOpen} error={!!error}>
        <label>{required ? label + '*' : label}</label>

        <button type="button" onClick={() => setIsOpen((state) => !state)}>
          <span>
            <FiCalendar color={iconColor} />
            {date ? format(removeTimeZone(date), 'dd/MM/yyyy') : placeholder}
          </span>
          <FiChevronDown />
        </button>
        {isOpen && (
          <section>
            <Calendar
              ref={ref}
              date={date ? removeTimeZone(date) : new Date()}
              locale={ptBR}
              months={months}
              classNames={{
                selected: 'date-picker-selected',
                dayToday: 'date-picker-today',
                dayNumber: 'date-picker-day-number',
                dayStartPreview: 'date-picker-start-preview',
                dayEndPreview: 'date-picker-end-preview',
                monthsHorizontal: 'date-picker-container',
              }}
              color="#c9cbcf"
              rangeColors={['red']}
              direction={direction}
              weekdayDisplayFormat={'EEEEEE'}
              onChange={(e) => {
                setIsOpen(false);
                onChange(removeTimeZone(e));
              }}
              {...rest}
            />
          </section>
        )}
        {error && !isOpen && <span>{error}</span>}
      </Container>
    );
  }
);

DatePicker.displayName = 'DatePicker';
