import { UserRoles } from '../types/users';

export const getProjectUrl = (role: string) => {
  if (role === UserRoles.CENTRAL_ATENDIMENTO) {
    return '/projetos/central-atendimento';
  } else if (role === UserRoles.FRANQUEADO) {
    return '/projetos';
  } else if (role === UserRoles.ENGENHEIRO) {
    return '/projetos/engenharia/listar';
  }

  return '/projetos';
};
