/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { toast } from 'react-toastify';

import { Breadcrumb, Button } from '../../../components';
import Select from '../../../components/form-elements/select';
import Radio from '../../../components/form-elements/radio';
import Input from '../../../components/form-elements/input';

import {
  Container,
  Wrapper,
  PageHeader,
  FormContainer,
  Group,
  InputGroup,
  Divider,
  GroupHeader,
  GroupedInputs,
} from './styles';

import projectsApi from '../../../services/projects';
import { useHistory, useParams } from 'react-router-dom';
import {
  PowerCompanyOptionsType,
  PowerCompanyType,
} from '../../../types/powerCompany';
import { BlankProjectType, ProjectType } from '../../../types/project';
import { useAuth } from '../../../contexts/auth';
import { ProjectStatus, ZipCodeResponseType } from '../types';
import {
  branchOptions,
  connectionTypeOptions,
  generationSourceOptions,
  neutralPhaseVoltageOptions,
  personTypeRadioOptions,
  solicitationTypeRadioOptions,
  ucClassOptions,
} from './options';
import { projectSchema } from './validation';
import cepApi from '../../../services/cep';
import { FranchiseAll } from '../../../components';
import { UserRoles } from '../../../types/users';

type ControlledInputsType = {
  personType: string;
  accessType: string;
};

type NewProjectFormPageProps = {
  id: string;
};

const NewProjectFormPage: React.FC = () => {
  const { id } = useParams<NewProjectFormPageProps>();
  const [project, setProject] = useState<BlankProjectType>();
  const [cepLoading, setCepLoading] = useState(false);
  const { selectedFranchise, user } = useAuth();
  const history = useHistory();
  const formRef = useRef<FormHandles>(null);

  const links = [
    {
      id: 1,
      title: 'Projetos',
      link: '/projetos',
      active: false,
    },
    {
      id: 2,
      title: 'Novo projeto',
      link: '/projeto/novo',
      active: false,
    },
    {
      id: 3,
      title: 'Formulário',
      link: '/projeto/novo/formulario',
      active: true,
    },
  ];

  const [values, setValues] = useState<ControlledInputsType>({
    personType: 'Física',
    accessType: 'Microgeração igual ou inferior a 10kw',
  });

  const [powerCompanyOptions, setPowerCompanyOptions] = useState<
    PowerCompanyOptionsType[]
  >([]);

  useEffect(() => {
    const loadCompanies = async () => {
      const response = await projectsApi.get<PowerCompanyType[]>(
        '/api/projects/power-company/list'
      );

      const normalizedToOptionsType = response.data.map((option) => ({
        label: option.name,
        value: option.id,
      }));

      setPowerCompanyOptions(normalizedToOptionsType);
    };
    loadCompanies();
    setValue('personType', values.personType);
    setValue('accessType', values.accessType);
  }, []);

  const {
    handleSubmit,
    formState: { errors },
    setValue,
    control,
  } = useForm<BlankProjectType>({ resolver: yupResolver(projectSchema) });

  useEffect(() => {
    if (Object.keys(errors).length) {
      toast.error('Alguns erros foram encontrados no seu formulário');
    }
  }, [errors]);

  useEffect(() => {
    if (id) {
      projectsApi.get<ProjectType>(`/api/projects/${id}`).then((res) => {
        setValue('powerCompanyId', res.data.powerCompanyId);
        setValue('accessType', res.data.accessType);
        setValue('requesterName', res.data.requesterName);
        setValue('requesterPhone', res.data.requesterPhone);
        setValue('requesterEmail', res.data.requesterEmail);
        setValue('ucCode', res.data.ucCode);
        setValue('ucClass', res.data.ucClass);
        setValue('ucOwner', res.data.ucOwner);
        setValue('ucDocument', res.data.ucDocument);
        setValue('ucZipcode', res.data.ucZipcode);
        setValue('ucState', res.data.ucState);
        setValue('ucCity', res.data.ucCity);
        setValue('ucAddress', res.data.ucAddress);
        setValue('ucNumber', res.data.ucNumber);
        setValue('ucPhone', res.data.ucPhone);
        setValue('ucEmail', res.data.ucEmail);
        setValue('ucNeutralPhaseVoltage', res.data.ucNeutralPhaseVoltage);
        setValue('ucConnectionType', res.data.ucConnectionType);
        setValue('ucBranch', res.data.ucBranch);
        setValue(
          'ucInstalledGenerationPower',
          res.data.ucInstalledGenerationPower
        );
        setValue('ucGenerationSource', res.data.ucGenerationSource);
        setValue('ucLatitude', res.data.ucLatitude);
        setValue('ucLongitude', res.data.ucLongitude);
        setValue('franchiseId', res.data.franchiseId);

        setProject(res.data);
      });
    }
  }, []);

  const handleCepBlur = async (cep: string) => {
    setCepLoading(true);
    const response = await cepApi.get<ZipCodeResponseType>(
      `/api/projects/zipcode/list?zipcode=${cep.replace('-', '')}`
    );

    if (response.data) {
      setValue('ucState', response.data.state.name);
      setValue('ucCity', response.data.city.name);
      setValue('ucAddress', response.data.address);

      setProject({
        ...project,
        ucState: response.data.state.name,
        ucCity: response.data.city.name,
        ucAddress: response.data.address,
      });
    } else {
      toast.error('CEP não encontrado');
    }
    setCepLoading(false);
  };

  const onSubmit = async (data: BlankProjectType) => {
    try {
      if (id) {
        await projectsApi.put(`/api/projects/${id}`, {
          ...data,
          compensation: project?.compensation,
          franchiseId: project?.franchiseId,
          observations: project?.observations,
          status: ProjectStatus.DOCUMENTACAO_EM_ANALISE,
        });
        toast.success('Projeto salvo com sucesso');
        history.push(`/projeto/novo/formulario/documentos/${id}`);
      } else {
        const response = await projectsApi.post('/api/projects', {
          ...data,
          compensation: false,
          franchiseId: selectedFranchise ? selectedFranchise : data.franchiseId,
          status: ProjectStatus.PROJETO_RACUNHO,
          observations: '',
        });
        toast.success('Projeto salvo com sucesso');
        history.push(`/projeto/novo/formulario/documentos/${response.data.id}`);
      }
    } catch (error) {
      toast.error('Erro desconhecido, tente novamente mais tarde');
    }
  };

  return (
    <Container>
      <Breadcrumb links={links} />
      <Wrapper>
        <PageHeader>
          <h1>Novo projeto</h1>
        </PageHeader>
        <FormContainer>
          <Form
            ref={formRef}
            initialData={project}
            onSubmit={handleSubmit(onSubmit)}
          >
            <Group>
              <GroupHeader>
                <h2>Dados do Solicitante</h2>
                <span>
                  Informações de contato de quem será responsavel pela
                  fiscalização deste projeto.
                </span>
              </GroupHeader>
              <InputGroup>
                <span>Qual é a sua concessionária?</span>
                <Controller
                  name="powerCompanyId"
                  control={control}
                  render={({ field: { onChange, value } }) => {
                    return (
                      <Select
                        name="powerCompanyId"
                        onChange={onChange}
                        value={value}
                        control={control}
                        placeholder="Selecione uma opção"
                        options={powerCompanyOptions}
                        error={errors.powerCompanyId?.message}
                      />
                    );
                  }}
                />
              </InputGroup>

              <InputGroup>
                <span>Escolha um tipo de conta:</span>
                <Radio
                  options={personTypeRadioOptions}
                  name="personType"
                  onChange={(e: any) => {
                    setValue('personType', e.target.value);
                    setValues({
                      ...values,
                      personType: e.target.value,
                    });
                  }}
                  value={values.personType}
                />
              </InputGroup>

              <InputGroup>
                <span>Selecione o tipo de solicitação de acesso:</span>
                <Radio
                  options={solicitationTypeRadioOptions}
                  name="accessType"
                  onChange={(e: any) => {
                    setValue('accessType', e.target.value);
                    setValues({
                      ...values,
                      accessType: e.target.value,
                    });
                  }}
                  value={values.accessType}
                />
              </InputGroup>
            </Group>
            <Divider />
            <Group>
              <GroupHeader>
                <h2>Dados da Franquia</h2>
                <span>
                  Informações de contato de quem será responsavel pela
                  fiscalização deste projeto.
                </span>
              </GroupHeader>
              <InputGroup>
                <Input
                  placeholder="Digite o nome completo"
                  name="requesterName"
                  onChange={(e: any) =>
                    setValue('requesterName', e.target.value)
                  }
                  error={errors.requesterName?.message}
                  label={
                    values.personType === 'Física'
                      ? 'Nome da pessoa'
                      : 'Nome do procurador legal'
                  }
                />
              </InputGroup>

              <GroupedInputs>
                <InputGroup>
                  <Input
                    placeholder="+55 00 00000-0000"
                    name="requesterPhone"
                    onChange={(e: any) =>
                      setValue('requesterPhone', e.target.value)
                    }
                    label="Número de telefone"
                    error={errors.requesterPhone?.message}
                  />
                </InputGroup>
                <InputGroup>
                  <Input
                    placeholder="Digite o e-mail"
                    name="requesterEmail"
                    onChange={(e: any) =>
                      setValue('requesterEmail', e.target.value)
                    }
                    label="Endereço de email"
                    error={errors.requesterEmail?.message}
                  />
                </InputGroup>
              </GroupedInputs>
            </Group>
            <Divider />
            <Group>
              <GroupHeader>
                <h2>Indentidicação da unidade consumidora (UC)</h2>
                <span>
                  Informações de contato de quem será responsavel pela unidade
                  consumidora
                </span>
              </GroupHeader>

              <GroupedInputs>
                <InputGroup>
                  <Input
                    placeholder="00000000"
                    name="ucCode"
                    onChange={(e: any) => setValue('ucCode', e.target.value)}
                    label="Código da UC"
                    error={errors.ucCode?.message}
                  />
                </InputGroup>
                <InputGroup>
                  <span>Classe</span>
                  <Controller
                    name="ucClass"
                    control={control}
                    render={({ field: { onChange, value } }) => {
                      return (
                        <Select
                          name="ucClass"
                          onChange={onChange}
                          value={value}
                          control={control}
                          placeholder="Selecione uma opção"
                          options={ucClassOptions}
                          error={errors.ucClass?.message}
                        />
                      );
                    }}
                  />
                </InputGroup>
              </GroupedInputs>

              <GroupedInputs>
                <InputGroup>
                  <Input
                    placeholder="Digite o nome"
                    name="ucOwner"
                    onChange={(e: any) => setValue('ucOwner', e.target.value)}
                    label="Títular da UC"
                    error={errors.ucOwner?.message}
                  />
                </InputGroup>
                <InputGroup>
                  <Input
                    placeholder="000.000.000-00"
                    name="ucDocument"
                    onChange={(e: any) =>
                      setValue('ucDocument', e.target.value)
                    }
                    label="CPF / CNPJ"
                    error={errors.ucDocument?.message}
                  />
                </InputGroup>
              </GroupedInputs>

              <GroupedInputs>
                <InputGroup>
                  <Input
                    placeholder="Digite o CEP"
                    name="ucZipcode"
                    onChange={(e: any) => setValue('ucZipcode', e.target.value)}
                    onBlur={(e) => handleCepBlur(e.target.value)}
                    label={cepLoading ? `CEP - buscando` : 'CEP'}
                    error={errors.ucZipcode?.message}
                  />
                </InputGroup>
                <InputGroup>
                  <Input
                    placeholder="Digite o estado"
                    name="ucState"
                    onChange={(e: any) => setValue('ucState', e.target.value)}
                    label="Estado"
                    error={errors.ucState?.message}
                  />
                </InputGroup>
                <InputGroup>
                  <Input
                    placeholder="Digite a cidade"
                    name="ucCity"
                    onChange={(e: any) => setValue('ucCity', e.target.value)}
                    label="Cidade"
                    error={errors.ucCity?.message}
                  />
                </InputGroup>
              </GroupedInputs>

              <GroupedInputs>
                <InputGroup>
                  <Input
                    placeholder="Digite o logradouro"
                    name="ucAddress"
                    onChange={(e: any) => setValue('ucAddress', e.target.value)}
                    label="Logradouro"
                    error={errors.ucAddress?.message}
                  />
                </InputGroup>
                <InputGroup size="small">
                  <Input
                    placeholder="00000"
                    name="ucNumber"
                    onChange={(e: any) => setValue('ucNumber', e.target.value)}
                    label="Número"
                    error={errors.ucNumber?.message}
                  />
                </InputGroup>
              </GroupedInputs>

              <GroupedInputs>
                <InputGroup>
                  <Input
                    placeholder="+ 55 00 00000-0000"
                    name="ucPhone"
                    onChange={(e: any) => setValue('ucPhone', e.target.value)}
                    label="Número de telefone"
                    error={errors.ucPhone?.message}
                  />
                </InputGroup>
                <InputGroup>
                  <Input
                    placeholder="Digite o e-mail"
                    name="ucEmail"
                    onChange={(e: any) => setValue('ucEmail', e.target.value)}
                    label="Endereço de e-mail"
                    error={errors.ucEmail?.message}
                  />
                </InputGroup>
              </GroupedInputs>
            </Group>
            <Divider />
            <Group>
              <GroupHeader>
                <h2>Dados da unidade consumidora</h2>
                <span>
                  Informações tecnicas de onde será feito a instalação.
                </span>
              </GroupHeader>

              <GroupedInputs>
                <InputGroup>
                  <span>Tensão de atendimento</span>
                  <Controller
                    name="ucNeutralPhaseVoltage"
                    control={control}
                    render={({ field: { onChange, value } }) => {
                      return (
                        <Select
                          name="ucNeutralPhaseVoltage"
                          onChange={onChange}
                          value={value}
                          control={control}
                          placeholder="Selecione uma opção"
                          options={neutralPhaseVoltageOptions}
                          error={errors.ucNeutralPhaseVoltage?.message}
                        />
                      );
                    }}
                  />
                </InputGroup>
              </GroupedInputs>

              <GroupedInputs>
                <InputGroup>
                  <span>Tipo de conexão</span>
                  <Controller
                    name="ucConnectionType"
                    control={control}
                    render={({ field: { onChange, value } }) => {
                      return (
                        <Select
                          name="ucConnectionType"
                          onChange={onChange}
                          value={value}
                          control={control}
                          placeholder="Selecione uma opção"
                          options={connectionTypeOptions}
                          error={errors.ucConnectionType?.message}
                        />
                      );
                    }}
                  />
                </InputGroup>
                <InputGroup>
                  <span>Tipo de ramal</span>
                  <Controller
                    name="ucBranch"
                    control={control}
                    render={({ field: { onChange, value } }) => {
                      return (
                        <Select
                          name="ucBranch"
                          onChange={onChange}
                          value={value}
                          control={control}
                          placeholder="Selecione uma opção"
                          options={branchOptions}
                          error={errors.ucBranch?.message}
                        />
                      );
                    }}
                  />
                </InputGroup>
              </GroupedInputs>
            </Group>
            <Divider />
            <Group>
              <GroupHeader>
                <h2>Dados da Geração</h2>
                <span>
                  Informações tecnicas de onde será feito a instalação
                </span>
              </GroupHeader>

              <GroupedInputs>
                <InputGroup>
                  <Input
                    placeholder="00000000000 KW"
                    name="ucInstalledGenerationPower"
                    onChange={(e: any) =>
                      setValue('ucInstalledGenerationPower', e.target.value)
                    }
                    label="Potência instalada de geração (em KW)"
                    error={errors.ucInstalledGenerationPower?.message}
                  />
                </InputGroup>
                <InputGroup>
                  <span>Tipo da fonte de geração</span>
                  <Controller
                    name="ucGenerationSource"
                    control={control}
                    render={({ field: { onChange, value } }) => {
                      return (
                        <Select
                          name="ucGenerationSource"
                          onChange={onChange}
                          value={value}
                          control={control}
                          placeholder="Selecione uma opção"
                          options={generationSourceOptions}
                          error={errors.ucGenerationSource?.message}
                        />
                      );
                    }}
                  />
                </InputGroup>
              </GroupedInputs>
            </Group>
            <Divider />
            <Group>
              <GroupHeader>
                <h2>Latitude e longitude do local</h2>
                <span>
                  Informações sobre a localização da unidade consumidora
                </span>
              </GroupHeader>
              <GroupedInputs>
                <InputGroup>
                  <span>Qual é a Latitude?</span>
                  <Input
                    placeholder="0000000000"
                    name="ucLongitude"
                    onChange={(e: any) =>
                      setValue('ucLongitude', e.target.value)
                    }
                    error={errors.ucLongitude?.message}
                  />
                </InputGroup>
                <InputGroup>
                  <span>Qual é a Longitude do local? </span>
                  <Input
                    placeholder="0000000000"
                    name="ucLatitude"
                    onChange={(e: any) =>
                      setValue('ucLatitude', e.target.value)
                    }
                    error={errors.ucLatitude?.message}
                  />
                </InputGroup>
              </GroupedInputs>
            </Group>
            {user.role === UserRoles.ADMIN && (
              <>
                <Divider />
                <Group>
                  <GroupedInputs>
                    <InputGroup marginTop={false}>
                      <span>Franqueado</span>
                      <FranchiseAll
                        onChange={(text: string) => {
                          setValue('franchiseId', text);
                        }}
                      />
                    </InputGroup>
                  </GroupedInputs>
                </Group>
              </>
            )}
            <Divider />
            <Button
              text="Avançar"
              backgroundColor="#005AF9"
              backgroundHoverColor="#005AF9"
            />
          </Form>
        </FormContainer>
      </Wrapper>
    </Container>
  );
};

export default NewProjectFormPage;
