import styled, { css } from 'styled-components';

type ButtonStyleProps = {
  backgroundColor?: string;
  backgroundHoverColor?: string;
  disabled?: boolean;
  size?: 'large';
};

export const Container = styled.button<ButtonStyleProps>`
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  font-weight: 600;
  font-size: 14px;
  color: #ffffff;
  background-color: ${(props) => props.backgroundColor};
  padding: 0 28px;
  border-radius: 10px;

  &:hover {
    background-color: ${(props) => props.backgroundHoverColor};
  }

  img {
    margin-right: 14px;
  }

  ${(props) =>
    props.disabled &&
    css`
      background-color: #f5f5f5;
      color: #cccccc;
      cursor: default;

      &:hover {
        background-color: #f5f5f5;
      }
    `}

  ${(props) =>
    props.size === 'large' &&
    css`
      width: 100%;
    `}
`;
