import { createGlobalStyle } from 'styled-components';

import DunbarTallRegularEot from '../fonts/dunbar/DunbarTall-Regular.eot';
import DunbarTallRegularTtf from '../fonts/dunbar/DunbarTall-Regular.ttf';
import DunbarTallRegularWoff from '../fonts/dunbar/DunbarTall-Regular.woff';
import DunbarTallRegularWoff2 from '../fonts/dunbar/DunbarTall-Regular.woff2';

import DunbarTallBoldEot from '../fonts/dunbar/DunbarTall-Bold.eot';
import DunbarTallBoldTtf from '../fonts/dunbar/DunbarTall-Bold.ttf';
import DunbarTallBoldWoff from '../fonts/dunbar/DunbarTall-Bold.woff';
import DunbarTallBoldWoff2 from '../fonts/dunbar/DunbarTall-Bold.woff2';

import DunbarTextRegularEot from '../fonts/dunbar/DunbarText-Regular.eot';
import DunbarTextRegularTtf from '../fonts/dunbar/DunbarText-Regular.ttf';
import DunbarTextRegularWoff from '../fonts/dunbar/DunbarText-Regular.woff';
import DunbarTextRegularWoff2 from '../fonts/dunbar/DunbarText-Regular.woff2';

export const GlobalStyles = createGlobalStyle`


  @font-face {
    font-family: "DunbarTallRegular";
    src: local('DunbarTallRegular'),
    url(${DunbarTallRegularEot}) format("eot"),
    url(${DunbarTallRegularTtf}) format("truetype"),
    url(${DunbarTallRegularWoff}) format("woff"),
    url(${DunbarTallRegularWoff2}) format("woff2");
  }

  @font-face {
    font-family: "DunbarTallBold";
    src: local('DunbarTallBold'),
    url(${DunbarTallBoldEot}) format("eot"),
    url(${DunbarTallBoldTtf}) format("truetype"),
    url(${DunbarTallBoldWoff}) format("woff"),
    url(${DunbarTallBoldWoff2}) format("woff2");
  }

  @font-face {
    font-family: "DunbarTextRegular";
    src: local('DunbarTextRegular'),
    url(${DunbarTextRegularEot}) format("eot"),
    url(${DunbarTextRegularTtf}) format("truetype"),
    url(${DunbarTextRegularWoff}) format("woff"),
    url(${DunbarTextRegularWoff2}) format("woff2");
  }

  * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  
  font-family: 'Montserrat', sans-serif;
}

html {
  font-size: 81.25%;
  
  @media screen and (min-width: 640px) {
    font-size: 87.5%;
  }
  
  @media screen and (min-width: 768px) {
    font-size: 93.75%;
  }
  
  @media screen and (min-width: 1024px) {
    font-size: 100%;
  }
}


a {
  color: inherit;
  text-decoration: none;
}

::-webkit-scrollbar {
  width: 4px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background: #C4C4C4;

}

::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background: #5A6272;
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
    transition: background-color 600000s 0s, color 600000s 0s;
}
input[data-autocompleted] {
    background-color: transparent !important;
}

ul, li, ol {
  list-style: none;
}

button {
  background: transparent;
  cursor: pointer;
  &:disabled {
    cursor: not-allowed;
  }
}
  input:focus {
    outline: 0;
  }

  input, button, textarea, fieldset {
    outline: none;
  border: none;
  }

`;
