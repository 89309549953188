import styled, { css } from 'styled-components';

export const PaginationContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 1rem 1.625rem;

  span.filter-title {
    font-size: 0.875rem;

    margin-right: 1rem;
  }

  div.pagination-buttons {
    display: flex;
    align-items: center;
  }

  span.data {
    font-size: 0.875rem;
  }

  select {
    height: 2rem;
    width: 4rem;
    margin-right: 1rem;
  }

  img {
    margin-right: 1.5rem;
  }

  @media (max-width: 800px) {
    justify-content: space-between;

    span.filter-title {
      display: none;
    }

    select {
      display: none;
    }
  }
`;
type PaginationButtonProps = {
  isActive: boolean;
};

export const PaginationButton = styled.button<PaginationButtonProps>`
  border: 0;
  background-color: transparent;

  svg {
    path {
      stroke: #c9cbcf;
    }
  }

  &:hover {
    svg {
      path {
        stroke: #27282b;
      }
    }
  }

  ${(props) =>
    props.isActive &&
    css`
      svg {
        path {
          stroke: #27282b;
        }
      }
    `}
`;
