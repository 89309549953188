import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { Breadcrumb, Button } from '../../../components';

import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import Checkbox from '../../../components/checkbox';
import Input from '../../../components/input';
import Loading from '../../../components/loading';
import productsApi from '../../../services/products';
import { ButtonGroup, Container, PageHeader } from '../../styles';
import {
  Content,
  FieldWrapper,
  Header,
  RegionWrapper,
  Regions,
} from './styles';

type Shipment = {
  id?: string;
  region: string | null;
  state: string | null;
  enabled: boolean;
  freightRate: number;
};

type ShipmentResponse = {
  id: string;
  supplierId: string;
  region: string | null;
  state: string | null;
  enabled: boolean;
  freightRate: string;
  createdAt: string;
  updatedAt: string;
};

type Params = {
  id: string;
};

type AllState = {
  id?: string;
  isChecked: boolean;
  value: string;
};

type State = {
  id?: string;
  state: string;
  stateAcronym: string;
  value: string;
  isChecked: boolean;
};

type RegionsType = {
  norte: State[];
  nordeste: State[];
  centrooeste: State[];
  sudeste: State[];
  sul: State[];
};

type Form = {
  allOfBrazil: AllState;
  allNorte: AllState;
  allNordeste: AllState;
  allCentrooeste: AllState;
  allSudeste: AllState;
  allSul: AllState;
  regions: RegionsType;
};

type Provider = {
  id: string;
  name: string;
  personName: string;
  website: string;
  email: string;
  phone: string;
  zipcode: string;
  state: string;
  city: string;
  address: string;
  district: string;
  addressNumber: string;
  complement: string | null;
  solarGenerationLost: string;
  deleted: boolean;
  createdAt: string;
  updatedAt: string;
};

const schema = yup.object().shape(
  {
    allOfBrazil: yup.object().shape({
      isChecked: yup.boolean(),
      value: yup.string(),
    }),
    allNorte: yup.object().shape({
      isChecked: yup.boolean(),
      value: yup.string(),
    }),
    allNordeste: yup.object().shape({
      isChecked: yup.boolean(),
      value: yup.string(),
    }),
    allCentrooeste: yup.object().shape({
      isChecked: yup.boolean(),
      value: yup.string(),
    }),
    allSudeste: yup.object().shape({
      isChecked: yup.boolean(),
      value: yup.string(),
    }),
    allSul: yup.object().shape({
      isChecked: yup.boolean(),
      value: yup.string(),
    }),
    regions: yup.object().shape({
      norte: yup.array().of(
        yup.object({
          state: yup.string(),
          stateAcronym: yup.string(),
          shipping: yup.string(),
          isChecked: yup.boolean(),
        })
      ),
      nordeste: yup.array().of(
        yup.object({
          state: yup.string(),
          stateAcronym: yup.string(),
          shipping: yup.string(),
          isChecked: yup.boolean(),
        })
      ),
      centrooeste: yup.array().of(
        yup.object({
          state: yup.string(),
          stateAcronym: yup.string(),
          shipping: yup.string(),
          isChecked: yup.boolean(),
        })
      ),
      sudeste: yup.array().of(
        yup.object({
          state: yup.string(),
          stateAcronym: yup.string(),
          shipping: yup.string(),
          isChecked: yup.boolean(),
        })
      ),
      sul: yup.array().of(
        yup.object({
          state: yup.string(),
          stateAcronym: yup.string(),
          shipping: yup.string(),
          isChecked: yup.boolean(),
        })
      ),
    }),
  },
  []
);

const ProvidersShippingPage: React.FC = () => {
  const [loading, setLoading] = useState(true);

  const history = useHistory();

  const { id } = useParams<Params>();

  const [provider, setProvider] = useState<Provider | null>(null);

  const links = [
    {
      id: 1,
      title: 'Fornecedores',
      link: '/fornecedores',
      active: false,
    },
    {
      id: 2,
      title: 'Fretes',
      link: `/fornecedores/${id}/frete`,
      active: true,
    },
  ];

  const formDefaultValues: Form = {
    allOfBrazil: {
      isChecked: false,
      value: '',
    },
    allNorte: {
      isChecked: false,
      value: '',
    },
    allNordeste: {
      isChecked: false,
      value: '',
    },
    allCentrooeste: {
      isChecked: false,
      value: '',
    },
    allSudeste: {
      isChecked: false,
      value: '',
    },
    allSul: {
      isChecked: false,
      value: '',
    },
    regions: {
      norte: [
        {
          state: 'Acre',
          stateAcronym: 'AC',
          value: '',
          isChecked: false,
        },
        {
          state: 'Amapá',
          stateAcronym: 'AP',
          value: '',
          isChecked: false,
        },
        {
          state: 'Amazonas',
          stateAcronym: 'AM',
          value: '',
          isChecked: false,
        },
        {
          state: 'Pará',
          stateAcronym: 'PA',
          value: '',
          isChecked: false,
        },
        {
          state: 'Rondônia',
          stateAcronym: 'RO',
          value: '',
          isChecked: false,
        },
        {
          state: 'Roraima',
          stateAcronym: 'RR',
          value: '',
          isChecked: false,
        },
        {
          state: 'Tocantins',
          stateAcronym: 'TO',
          value: '',
          isChecked: false,
        },
      ],
      nordeste: [
        {
          state: 'Alagoas',
          stateAcronym: 'AL',
          value: '',
          isChecked: false,
        },
        {
          state: 'Bahia',
          stateAcronym: 'BA',
          value: '',
          isChecked: false,
        },
        {
          state: 'Ceará',
          stateAcronym: 'CE',
          value: '',
          isChecked: false,
        },
        {
          state: 'Maranhão',
          stateAcronym: 'MA',
          value: '',
          isChecked: false,
        },
        {
          state: 'Pernambuco',
          stateAcronym: 'PE',
          value: '',
          isChecked: false,
        },
        {
          state: 'Paraíba',
          stateAcronym: 'PB',
          value: '',
          isChecked: false,
        },
        {
          state: 'Piauí',
          stateAcronym: 'PI',
          value: '',
          isChecked: false,
        },
        {
          state: 'Rio Grande do Norte',
          stateAcronym: 'RN',
          value: '',
          isChecked: false,
        },
        {
          state: 'Sergipe',
          stateAcronym: 'SE',
          value: '',
          isChecked: false,
        },
      ],
      centrooeste: [
        {
          state: 'Distrito Federal',
          stateAcronym: 'DF',
          value: '',
          isChecked: false,
        },
        {
          state: 'Goiás',
          stateAcronym: 'GO',
          value: '',
          isChecked: false,
        },
        {
          state: 'Mato Grosso do Sul',
          stateAcronym: 'MS',
          value: '',
          isChecked: false,
        },
        {
          state: 'Mato Grosso',
          stateAcronym: 'MT',
          value: '',
          isChecked: false,
        },
      ],
      sudeste: [
        {
          state: 'Espírito Santo',
          stateAcronym: 'ES',
          value: '',
          isChecked: false,
        },
        {
          state: 'Minas Gerais',
          stateAcronym: 'MG',
          value: '',
          isChecked: false,
        },
        {
          state: 'Rio de Janeiro',
          stateAcronym: 'RJ',
          value: '',
          isChecked: false,
        },
        {
          state: 'São Paulo',
          stateAcronym: 'SP',
          value: '',
          isChecked: false,
        },
      ],
      sul: [
        {
          state: 'Paraná',
          stateAcronym: 'PR',
          value: '',
          isChecked: false,
        },
        {
          state: 'Rio Grande do Sul',
          stateAcronym: 'RS',
          value: '',
          isChecked: false,
        },
        {
          state: 'Santa Catarina',
          stateAcronym: 'SC',
          value: '',
          isChecked: false,
        },
      ],
    },
  };

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    getValues,
    reset,
    formState: { errors },
  } = useForm<Form>({
    resolver: yupResolver(schema),
    defaultValues: formDefaultValues,
  });

  const {
    allOfBrazil,
    allNorte,
    allNordeste,
    allCentrooeste,
    allSudeste,
    allSul,
    regions,
  } = {
    allOfBrazil: watch('allOfBrazil'),
    allNorte: watch('allNorte'),
    allNordeste: watch('allNordeste'),
    allCentrooeste: watch('allCentrooeste'),
    allSudeste: watch('allSudeste'),
    allSul: watch('allSul'),
    regions: watch('regions'),
  };

  const getProvider = async () => {
    try {
      const { data } = await productsApi.get(`/products/suppliers/${id}`);

      setProvider(data);
    } catch (error) {
      toast.error(
        'Desculpe, não foi possível buscar as informações do fornecedor'
      );

      throw error;
    }
  };

  const getProviderShipping = async () => {
    try {
      const { data } = await productsApi.get<ShipmentResponse[]>(
        `/products/suppliers/${id}/shipments`
      );

      enum ALL_REGIONS_KEY {
        'Centro-Oeste' = 'allCentrooeste',
        Nordeste = 'allNordeste',
        Norte = 'allNorte',
        Sudeste = 'allSudeste',
        Sul = 'allSul',
      }

      const {
        allCentrooeste,
        allNordeste,
        allNorte,
        allOfBrazil,
        allSudeste,
        allSul,
        regions,
      } = formDefaultValues;

      const allRegionsShipments = data
        .filter((shipment) => !shipment.state)
        .reduce(
          (acc, shipment) => {
            const allRegionKey =
              ALL_REGIONS_KEY[shipment.region as keyof typeof ALL_REGIONS_KEY];

            const shipmentData = {
              id: shipment.id,
              isChecked: shipment.enabled,
              value: shipment.freightRate,
            };

            return {
              ...acc,
              [allRegionKey || 'allOfBrazil']: shipmentData,
            };
          },
          {
            allOfBrazil,
            allNorte,
            allNordeste,
            allCentrooeste,
            allSudeste,
            allSul,
          }
        );

      enum REGIONS_KEYS {
        'Centro-Oeste' = 'centrooeste',
        Nordeste = 'nordeste',
        Norte = 'norte',
        Sudeste = 'sudeste',
        Sul = 'sul',
      }

      enum STATES_KEYS {
        AC = 'Acre',
        AL = 'Alagoas',
        AM = 'Amazonas',
        AP = 'Amapá',
        BA = 'Bahia',
        CE = 'Ceará',
        DF = 'Distrito Federal',
        ES = 'Espírito Santo',
        GO = 'Goiás',
        MA = 'Maranhão',
        MG = 'Minas Gerais',
        MS = 'Mato Grosso do Sul',
        MT = 'Mato Grosso',
        PA = 'Pará',
        PB = 'Paraíba',
        PE = 'Pernambuco',
        PI = 'Piauí',
        PR = 'Paraná',
        RJ = 'Rio de Janeiro',
        RN = 'Rio Grande do Norte',
        RO = 'Rondônia',
        RR = 'Roraima',
        RS = 'Rio Grande do Sul',
        SC = 'Santa Catarina',
        SE = 'Sergipe',
        SP = 'São Paulo',
        TO = 'Tocantins',
      }

      const statesShipments = data
        .filter((shipment) => shipment.state && shipment.region)
        .reduce(
          (acc, shipment) => {
            const regionKey = REGIONS_KEYS[
              shipment.region as keyof typeof REGIONS_KEYS
            ] as keyof typeof acc.regions;

            const stateKey =
              STATES_KEYS[shipment.state as keyof typeof STATES_KEYS];

            if (regionKey && shipment.state) {
              const regionStates = acc.regions[regionKey];

              const existingStateIndex = regionStates.findIndex(
                (state) => state.state === stateKey
              );

              const stateShipment = {
                id: shipment.id,
                state: stateKey,
                stateAcronym: shipment.state,
                value: shipment.freightRate,
                isChecked: shipment.enabled,
              };

              if (existingStateIndex !== -1) {
                regionStates[existingStateIndex] = stateShipment;
              } else {
                regionStates.push(stateShipment);
              }
            }

            return acc;
          },
          { regions }
        );

      reset({ ...allRegionsShipments, ...statesShipments });
    } catch (error) {
      toast.error('Desculpe, não foi possível buscar as informações de frete');

      throw error;
    }
  };

  const fetchData = async () => {
    try {
      await Promise.all([getProvider(), getProviderShipping()]);
      setLoading(false);
    } catch (error) {
      history.replace('/fornecedores');
    }
  };

  const handleAllCheckBoxes = () => {
    const regions = [
      'allCentrooeste',
      'allNordeste',
      'allNorte',
      'allSudeste',
      'allSul',
    ];

    regions.forEach((region) => {
      const regionName = region.substring(3).toLowerCase() as keyof RegionsType;

      reset({
        ...getValues(),
        [region]: {
          ...getValues(region as keyof Form),
          isChecked: false,
        },
        regions: {
          ...getValues('regions'),
          [regionName]: getValues(`regions.${regionName}`).map((state) => {
            return {
              ...state,
              isChecked: false,
            };
          }),
        },
      });
    });
  };

  const handleAllStatesCheckboxes = (region: keyof RegionsType) => {
    reset({
      ...getValues(),
      regions: {
        ...getValues('regions'),
        [region]: getValues(`regions.${region}`).map((state) => {
          return {
            ...state,
            isChecked: false,
          };
        }),
      },
    });
  };

  const handleStateCheckBox = (region: keyof RegionsType, state: string) => {
    const currentState = regions[region].find(
      (_state) => _state.state === state
    );

    const normalizedRegionName = ('all' +
      region.charAt(0).toUpperCase() +
      region.slice(1)) as keyof Form;

    reset({
      ...getValues(),
      [normalizedRegionName]: {
        ...getValues(normalizedRegionName),
        isChecked: false,
      },
      regions: {
        ...getValues('regions'),
        [region]: regions[region].map((_state) => {
          if (_state.state === currentState?.state) {
            return {
              ...currentState,
              isChecked: !_state.isChecked,
            };
          } else {
            return _state;
          }
        }),
      },
    });
  };

  const handleStateShippingValue = (
    region: keyof RegionsType,
    state: string,
    value: string
  ) => {
    const currentState = regions[region].find(
      (_state) => _state.state === state
    );

    setValue('regions', {
      ...regions,
      [region]: regions[region].map((_state) => {
        if (_state.state === currentState?.state) {
          return {
            ...currentState,
            value: value,
          };
        } else {
          return _state;
        }
      }),
    });
  };

  const normalizeAllRegionsToShipments = (
    allRegions: Omit<Form, 'regions'>
  ) => {
    enum REGIONS_KEYS {
      allCentrooeste = 'Centro-Oeste',
      allNordeste = 'Nordeste',
      allNorte = 'Norte',
      allSudeste = 'Sudeste',
      allSul = 'Sul',
    }

    const regionsShipment = Object.entries(allRegions).map(([key, item]) => {
      const region = REGIONS_KEYS[key as keyof typeof REGIONS_KEYS];

      return {
        ...(item.id && { id: item.id }),
        region: region ?? null,
        state: null,
        enabled: item.isChecked,
        freightRate: Number(item.value),
      };
    });

    return regionsShipment;
  };

  const normalizeRegionsStatesToShipments = (
    regions: RegionsType
  ): Shipment[] => {
    enum REGIONS_KEYS {
      centrooeste = 'Centro-Oeste',
      nordeste = 'Nordeste',
      norte = 'Norte',
      sudeste = 'Sudeste',
      sul = 'Sul',
    }

    const statesShipment: Shipment[] = Object.entries(regions)
      .map(([key, item]) => {
        const region = REGIONS_KEYS[key as keyof typeof REGIONS_KEYS];

        const shipment = item.map((state) => {
          return {
            ...(state.id && { id: state.id }),
            region: region ?? null,
            state: state.stateAcronym,
            enabled: state.isChecked,
            freightRate: Number(state.value),
          };
        });

        return shipment;
      })
      .flat();

    return statesShipment;
  };

  const onSubmit = async (data: Form) => {
    const { regions, ...allRegions } = data;

    const allRegionsShipment = normalizeAllRegionsToShipments(allRegions);

    const regionsStatesShipment = normalizeRegionsStatesToShipments(regions);

    const shipments = [...allRegionsShipment, ...regionsStatesShipment];

    try {
      await productsApi.post(`/products/suppliers/${id}/shipments`, {
        shipments,
      });

      toast.success('Configurações de frete atualizadas com sucesso');

      history.replace('/fornecedores/');
    } catch (error) {
      toast.error(
        'Desculpe, não foi possível atualizar as configurações de frete'
      );
    }
  };

  useEffect(() => {
    fetchData();
  }, [id]);

  if (loading) {
    return <Loading />;
  }

  return (
    <Container>
      <Breadcrumb links={links} />
      <PageHeader>
        <span>Frete</span>
      </PageHeader>

      <Content onSubmit={handleSubmit(onSubmit)}>
        <Header>
          <h1>Nome do fornecedor</h1>
          <strong>{provider?.personName}</strong>
        </Header>

        <FieldWrapper variant="primary" style={{ margin: '2rem 0 4rem' }}>
          <Controller
            control={control}
            name="allOfBrazil"
            render={({ field: { value, onChange, ...rest } }) => {
              return (
                <Checkbox
                  label="Todo o Brasil"
                  onChange={() => {
                    onChange({
                      ...allOfBrazil,
                      isChecked: !value.isChecked,
                    });

                    handleAllCheckBoxes();
                  }}
                  isChecked={value.isChecked}
                  {...rest}
                />
              );
            }}
          />

          <Input
            name="allOfBrazil"
            onChange={(event) => {
              const value = event.target.value;

              setValue('allOfBrazil', {
                ...allOfBrazil,
                value,
              });
            }}
            value={allOfBrazil.value}
            placeholder="0,00"
            disabled={!allOfBrazil.isChecked}
            step="0.01"
            label="Valor do frete"
            type="number"
            iconRight="%"
          />
        </FieldWrapper>
        <RegionWrapper>
          <h1>Por regiões</h1>

          <FieldWrapper variant="primary" style={{ margin: '2rem 0 4rem' }}>
            <Controller
              control={control}
              name="allNorte"
              render={({ field: { value, onChange, ...rest } }) => {
                return (
                  <Checkbox
                    label="Norte"
                    onChange={() => {
                      onChange({
                        ...allNorte,
                        isChecked: !value.isChecked,
                      });

                      handleAllStatesCheckboxes('norte');
                    }}
                    isChecked={value.isChecked}
                    disabled={allOfBrazil.isChecked}
                    {...rest}
                  />
                );
              }}
            />

            <Input
              name="allNorte"
              onChange={(event) => {
                const value = event.target.value;

                setValue('allNorte', {
                  ...allNorte,
                  value,
                });
              }}
              value={allNorte.value}
              placeholder="0,00"
              disabled={!allNorte.isChecked}
              step="0.01"
              label="Valor do frete"
              type="number"
              iconRight="%"
            />
          </FieldWrapper>

          <Regions>
            {regions.norte.map((state) => {
              const currentState = regions.norte.find(
                (_state) => _state.state === state.state
              );

              return (
                <FieldWrapper variant="secondary" key={state.stateAcronym}>
                  <Checkbox
                    name={state.state}
                    label={state.state + ' - ' + state.stateAcronym}
                    onChange={() => {
                      handleStateCheckBox('norte', state.state);
                    }}
                    isChecked={Boolean(currentState?.isChecked)}
                    disabled={allOfBrazil.isChecked || allNorte.isChecked}
                  />

                  <Input
                    name={state.state}
                    onChange={(event) => {
                      const value = event.target.value;

                      handleStateShippingValue('norte', state.state, value);
                    }}
                    value={currentState?.value}
                    placeholder="0,00"
                    disabled={!currentState?.isChecked}
                    step="0.01"
                    label="Valor do frete"
                    type="number"
                    iconRight="%"
                  />
                </FieldWrapper>
              );
            })}
          </Regions>

          <FieldWrapper variant="primary">
            <Controller
              control={control}
              name="allNordeste"
              render={({ field: { value, onChange, ...rest } }) => {
                return (
                  <Checkbox
                    label="Nordeste"
                    onChange={() => {
                      onChange({
                        ...allNordeste,
                        isChecked: !value.isChecked,
                      });

                      handleAllStatesCheckboxes('nordeste');
                    }}
                    isChecked={value.isChecked}
                    disabled={allOfBrazil.isChecked}
                    {...rest}
                  />
                );
              }}
            />

            <Input
              name="allNordeste"
              onChange={(event) => {
                const value = event.target.value;

                setValue('allNordeste', {
                  ...allNordeste,
                  value,
                });
              }}
              value={allNordeste.value}
              placeholder="0,00"
              disabled={!allNordeste.isChecked}
              step="0.01"
              label="Valor do frete"
              type="number"
              iconRight="%"
            />
          </FieldWrapper>

          <Regions>
            {regions.nordeste.map((state) => {
              const currentState = regions.nordeste.find(
                (_state) => _state.state === state.state
              );

              return (
                <FieldWrapper variant="secondary" key={state.stateAcronym}>
                  <Checkbox
                    name={state.state}
                    label={state.state + ' - ' + state.stateAcronym}
                    onChange={() => {
                      handleStateCheckBox('nordeste', state.state);
                    }}
                    disabled={allOfBrazil.isChecked || allNordeste.isChecked}
                    isChecked={Boolean(currentState?.isChecked)}
                  />

                  <Input
                    name={state.state}
                    onChange={(event) => {
                      const value = event.target.value;

                      handleStateShippingValue('nordeste', state.state, value);
                    }}
                    value={currentState?.value}
                    placeholder="0,00"
                    disabled={!currentState?.isChecked}
                    step="0.01"
                    label="Valor do frete"
                    type="number"
                    iconRight="%"
                  />
                </FieldWrapper>
              );
            })}
          </Regions>

          <FieldWrapper variant="primary">
            <Controller
              control={control}
              name="allCentrooeste"
              render={({ field: { value, onChange, ...rest } }) => {
                return (
                  <Checkbox
                    label="Centro-Oeste"
                    onChange={() => {
                      onChange({
                        ...allCentrooeste,
                        isChecked: !value.isChecked,
                      });

                      handleAllStatesCheckboxes('centrooeste');
                    }}
                    isChecked={value.isChecked}
                    disabled={allOfBrazil.isChecked}
                    {...rest}
                  />
                );
              }}
            />

            <Input
              name="allCentrooeste"
              onChange={(event) => {
                const value = event.target.value;

                setValue('allCentrooeste', {
                  ...allCentrooeste,
                  value,
                });
              }}
              value={allCentrooeste.value}
              placeholder="0,00"
              disabled={!allCentrooeste.isChecked}
              step="0.01"
              label="Valor do frete"
              type="number"
              iconRight="%"
            />
          </FieldWrapper>

          <Regions>
            {regions.centrooeste.map((state) => {
              const currentState = regions.centrooeste.find(
                (_state) => _state.state === state.state
              );

              return (
                <FieldWrapper variant="secondary" key={state.stateAcronym}>
                  <Checkbox
                    name={state.state}
                    label={state.state + ' - ' + state.stateAcronym}
                    onChange={() => {
                      handleStateCheckBox('centrooeste', state.state);
                    }}
                    disabled={allOfBrazil.isChecked || allCentrooeste.isChecked}
                    isChecked={Boolean(currentState?.isChecked)}
                  />

                  <Input
                    name={state.state}
                    onChange={(event) => {
                      const value = event.target.value;

                      handleStateShippingValue(
                        'centrooeste',
                        state.state,
                        value
                      );
                    }}
                    value={currentState?.value}
                    placeholder="0,00"
                    disabled={!currentState?.isChecked}
                    step="0.01"
                    label="Valor do frete"
                    type="number"
                    iconRight="%"
                  />
                </FieldWrapper>
              );
            })}
          </Regions>

          <FieldWrapper variant="primary">
            <Controller
              control={control}
              name="allSudeste"
              render={({ field: { value, onChange, ...rest } }) => {
                return (
                  <Checkbox
                    label="Sudeste"
                    onChange={() => {
                      onChange({
                        ...allSudeste,
                        isChecked: !value.isChecked,
                      });

                      handleAllStatesCheckboxes('sudeste');
                    }}
                    disabled={allOfBrazil.isChecked}
                    isChecked={value.isChecked}
                    {...rest}
                  />
                );
              }}
            />

            <Input
              name="allSudeste"
              onChange={(event) => {
                const value = event.target.value;

                setValue('allSudeste', {
                  ...allSudeste,
                  value,
                });
              }}
              value={allSudeste.value}
              placeholder="0,00"
              disabled={!allSudeste.isChecked}
              step="0.01"
              label="Valor do frete"
              type="number"
              iconRight="%"
            />
          </FieldWrapper>

          <Regions>
            {regions.sudeste.map((state) => {
              const currentState = regions.sudeste.find(
                (_state) => _state.state === state.state
              );

              return (
                <FieldWrapper variant="secondary" key={state.stateAcronym}>
                  <Checkbox
                    name={state.state}
                    label={state.state + ' - ' + state.stateAcronym}
                    onChange={() => {
                      handleStateCheckBox('sudeste', state.state);
                    }}
                    disabled={allOfBrazil.isChecked || allSudeste.isChecked}
                    isChecked={Boolean(currentState?.isChecked)}
                  />

                  <Input
                    name={state.state}
                    onChange={(event) => {
                      const value = event.target.value;

                      handleStateShippingValue('sudeste', state.state, value);
                    }}
                    value={currentState?.value}
                    placeholder="0,00"
                    disabled={!currentState?.isChecked}
                    step="0.01"
                    label="Valor do frete"
                    type="number"
                    iconRight="%"
                  />
                </FieldWrapper>
              );
            })}
          </Regions>

          <FieldWrapper variant="primary">
            <Controller
              control={control}
              name="allSul"
              render={({ field: { value, onChange, ...rest } }) => {
                return (
                  <Checkbox
                    label="Sul"
                    onChange={() => {
                      onChange({
                        ...allSul,
                        isChecked: !value.isChecked,
                      });

                      handleAllStatesCheckboxes('sul');
                    }}
                    disabled={allOfBrazil.isChecked}
                    isChecked={value.isChecked}
                    {...rest}
                  />
                );
              }}
            />

            <Input
              name="allSul"
              onChange={(event) => {
                const value = event.target.value;

                setValue('allSul', {
                  ...allSul,
                  value,
                });
              }}
              value={allSul.value}
              placeholder="0,00"
              disabled={!allSul.isChecked}
              step="0.01"
              label="Valor do frete"
              type="number"
              iconRight="%"
            />
          </FieldWrapper>

          <Regions>
            {regions.sul.map((state) => {
              const currentState = regions.sul.find(
                (_state) => _state.state === state.state
              );

              return (
                <FieldWrapper variant="secondary" key={state.stateAcronym}>
                  <Checkbox
                    name={state.state}
                    label={state.state + ' - ' + state.stateAcronym}
                    onChange={() => {
                      handleStateCheckBox('sul', state.state);
                    }}
                    disabled={allOfBrazil.isChecked || allSul.isChecked}
                    isChecked={Boolean(currentState?.isChecked)}
                  />

                  <Input
                    name={state.state}
                    onChange={(event) => {
                      const value = event.target.value;

                      handleStateShippingValue('sul', state.state, value);
                    }}
                    value={currentState?.value}
                    placeholder="0,00"
                    disabled={!currentState?.isChecked}
                    step="0.01"
                    label="Valor do frete"
                    type="number"
                    iconRight="%"
                  />
                </FieldWrapper>
              );
            })}
          </Regions>
        </RegionWrapper>

        <ButtonGroup>
          <Button
            type="button"
            text="Voltar"
            typeStyle="default"
            backgroundHoverColor="#C9CBCF"
            onClick={() => history.replace('/fornecedores')}
          />
          <Button text="Salvar alterações" disabled={false} />
        </ButtonGroup>
      </Content>
    </Container>
  );
};

export default ProvidersShippingPage;
