import wegLogo from '../../../../assets/suppliers/weg-logo.svg';
import elginLogo from '../../../../assets/suppliers/elgin-logo.svg';
import renovigiLogo from '../../../../assets/suppliers/renovigi-logo.svg';
import geLogo from '../../../../assets/suppliers/ge-logo.svg';

export const providersLogo = {
  weg: wegLogo,
  elgin: elginLogo,
  renovigi: renovigiLogo,
  ge: geLogo,
};
