import React, { Fragment, useEffect } from 'react';
import {
  TagEditIcon,
  TagsRefreshIcon,
} from '../../../../../../components/icons';
import { Button, Container, Tag, Wrapper } from './styles';
import { TagsModal } from '../../TagsModal';
import { Tag as TagType } from '../../../../../../contexts/sales-funnel/types';
import Checkbox from '../../../../../../components/checkbox';
import { useSalesFunnel } from '../../../../../../contexts/sales-funnel';
import { useAuth } from '../../../../../../contexts/auth';

interface TagsProps {
  selectedTags: string[];
  onChange: (tagId: string) => void;
  onRefresh: (tags: string[]) => void;
}

export const Tags: React.FC<TagsProps> = (props) => {
  const { selectedTags, onChange, onRefresh } = props;

  const { tags, handleTagModal, getTags } = useSalesFunnel();

  const { selectedFranchise: franchiseId } = useAuth();

  const hasTags = tags.length > 0;

  const handleUpdateSelectedTags = () => {
    const updatedSelectedTags = selectedTags.filter((currentTagId) =>
      tags.some((_tag) => _tag.id === currentTagId)
    );

    onRefresh(updatedSelectedTags);
  };

  useEffect(() => {
    if (selectedTags.length > 0) {
      handleUpdateSelectedTags();
    }
  }, [tags]);

  useEffect(() => {
    if (franchiseId) {
      getTags();
    }
  }, [franchiseId]);

  return (
    <Fragment>
      <Container>
        <Wrapper>
          {!hasTags && <span>Nenhuma etiqueta encontrada.</span>}

          {tags.map((tag) => {
            const isChecked = selectedTags.includes(tag.id);

            return (
              <Tag key={tag.id} bgColor={tag.color}>
                <label htmlFor={tag.id}>
                  <Checkbox
                    id={tag.id}
                    name={tag.id}
                    isChecked={isChecked}
                    onChange={(option: TagType) => onChange(option.id)}
                    option={tag}
                  />

                  <span>{tag.name}</span>
                </label>

                <button
                  type="button"
                  onClick={() => handleTagModal('EDIT_STEP', tag)}
                >
                  <TagEditIcon />
                </button>
              </Tag>
            );
          })}
        </Wrapper>

        <Button onClick={() => handleTagModal('DEFAULT_STEP', null)}>
          <TagsRefreshIcon />
          Etiquetas
        </Button>
      </Container>
    </Fragment>
  );
};
