import { yupResolver } from '@hookform/resolvers/yup';
import { format } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import React, { useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { FiTrash2, FiXCircle } from 'react-icons/fi';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { Breadcrumb } from '../../../../../../components/breadcrumb';
import { Button } from '../../../../../../components/button';
import { FileInput, IFile } from '../../../../../../components/file-input';
import { CloudIcon, InfoIcon } from '../../../../../../components/icons';
import Input from '../../../../../../components/input';
import { CancelOrderModal } from '../../../../../../components/modal/cancel-order';
import RadioGroup from '../../../../../../components/radio-group';
import Textarea from '../../../../../../components/textarea';
import { Order } from '../../../../../../contexts/orders/types';
import productsApi from '../../../../../../services/products';
import { downloadFile } from '../../../../../../utils/downloadFile';
import { ButtonGroup, Container, PageHeader } from '../../../../../styles';
import { ORDER_STATUS } from '../../../components/orders/innerTables/consultant';
import { SentDate } from '../../../styles';
import {
  CancelOrder,
  ConsultantMessage,
  Content,
  DocumentTypes,
  Documents,
  Info,
  Link,
  LinkWrapper,
  Links,
  RadioWrapper,
  Wrapper,
} from './styles';

type ConsultantSentToSupplierProps = {
  order: Order;
};

type OrderFiles = {
  PersonType: IFile[];
  HouseDocumentProof: IFile[];
  BankDocument: IFile[];
  OtherDocuments: IFile[];
  PaymentConfirmation: IFile[];
  AcceptanceTerm: IFile[];
  NFE: IFile[];
  NFF: IFile[];
  BOLETO: IFile[];
};

type Form = {
  supplierError: boolean;
  supplierMessage: string;
  documentType: string;
  nfe: IFile[];
  nff: IFile[];
  boleto: IFile[];
  link: string;
};

type Link = {
  name: string;
  link: string;
};

const urlRegex =
  /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi;

const schema = yup.object().shape(
  {
    supplierError: yup.boolean(),
    supplierMessage: yup
      .string()
      .when('supplierError', (value) =>
        value
          ? yup.string().required('Campo obrigatório')
          : yup.string().optional()
      ),
    documentType: yup.string(),
    nfe: yup
      .mixed()
      .when(['documentType', 'supplierError'], (type, supplierError) => {
        if (type.includes('nfe') && !supplierError) {
          return yup
            .mixed()
            .test(
              'check if field is empty',
              (value: IFile[], { createError }) => {
                const hasErrors = value.some((file) => file.error);

                if (value.length > 0) {
                  return hasErrors
                    ? createError({
                        path: type,
                        message: 'Remova o arquivo com erro',
                        type: 'field-error',
                      })
                    : true;
                } else {
                  return createError({
                    path: type,
                    message: 'Campo obrigatório',
                    type: 'field-empty',
                  });
                }
              }
            );
        } else {
          return yup.mixed().optional();
        }
      }),
    nff: yup
      .mixed()
      .when(['documentType', 'supplierError'], (type, supplierError) => {
        if (type.includes('nff') && !supplierError) {
          return yup
            .mixed()
            .test(
              'check if field is empty',
              (value: IFile[], { createError }) => {
                const hasErrors = value.some((file) => file.error);

                if (value.length > 0) {
                  return hasErrors
                    ? createError({
                        path: type,
                        message: 'Remova o arquivo com erro',
                        type: 'field-error',
                      })
                    : true;
                } else {
                  return createError({
                    path: type,
                    message: 'Campo obrigatório',
                    type: 'field-empty',
                  });
                }
              }
            );
        } else {
          return yup.mixed().optional();
        }
      }),
    boleto: yup
      .mixed()
      .when(['documentType', 'supplierError'], (type, supplierError) => {
        if (type.includes('boleto') && !supplierError) {
          return yup
            .mixed()
            .test(
              'check if field is empty',
              (value: IFile[], { createError }) => {
                const hasErrors = value.some((file) => file.error);

                if (value.length > 0) {
                  return hasErrors
                    ? createError({
                        path: type,
                        message: 'Remova o arquivo com erro',
                        type: 'field-error',
                      })
                    : true;
                } else {
                  return createError({
                    path: type,
                    message: 'Campo obrigatório',
                    type: 'field-empty',
                  });
                }
              }
            );
        } else {
          return yup.mixed().optional();
        }
      }),
    link: yup.string().when(['documentType', 'link'], (type, link) => {
      if (type.includes('link') && link) {
        return yup.string().matches(urlRegex, 'Digite um link válido');
      } else {
        return yup.string().optional();
      }
    }),
  },
  [
    ['supplierError', 'supplierMessage'],
    ['documentType', 'nfe'],
    ['nff', 'boleto'],
    ['link', 'link'],
  ]
);

export const ConsultantSentToSupplier: React.FC<
  ConsultantSentToSupplierProps
> = (props) => {
  const { order } = props;

  const { orderId, status } = useParams<{
    orderId: string;
    status: ORDER_STATUS;
  }>();

  const [links, setLinks] = useState<Link[]>([]);

  const history = useHistory();

  const breadcrumb = [
    {
      id: 1,
      title: 'Orçamentos',
      link: '/orcamentos',
      active: false,
    },
    {
      id: 2,
      title: 'Orçamentos e Pedidos',
      link: '/orcamentos/orcamentos-e-pedidos',
      active: false,
    },
    {
      id: 3,
      title: 'Pedidos enviados',
      link: `/orcamentos/orcamentos-e-pedidos/pedidos-enviados/${orderId}/${status}`,
      active: true,
    },
  ];

  const orderFiles: OrderFiles = useMemo(() => {
    return (
      order.files?.reduce(
        (acc: OrderFiles, file: File) => {
          if (file.type in acc) {
            return {
              ...acc,
              [file.type]: [
                ...acc[file.type as keyof OrderFiles],
                {
                  ...file,
                  progress: 100,
                },
              ],
            };
          }

          return acc;
        },
        {
          PersonType: [],
          HouseDocumentProof: [],
          PaymentConfirmation: [],
          BankDocument: [],
          OtherDocuments: [],
          AcceptanceTerm: [],
          NFE: [],
          BOLETO: [],
          NFF: [],
        }
      ) ?? {
        PersonType: [],
        HouseDocumentProof: [],
        PaymentConfirmation: [],
        BankDocument: [],
        OtherDocuments: [],
        AcceptanceTerm: [],
        NFE: [],
        BOLETO: [],
        NFF: [],
      }
    );
  }, [order]);

  const {
    control,
    handleSubmit,
    watch,
    register,
    clearErrors,
    reset,
    getValues,
    setValue,
    trigger,
    formState: { errors },
    setError,
  } = useForm<Form>({
    resolver: yupResolver(schema),
    defaultValues: {
      supplierError: order.supplierError,
      documentType: 'nfe',
      nfe: orderFiles.NFE,
      nff: orderFiles.NFF,
      boleto: orderFiles.BOLETO,
    },
  });

  const hasFormErrors = !!Object.entries(errors).length;

  const supplierError = watch('supplierError');

  const documentType = watch('documentType');

  const handleAddLink = () => {
    const link = getValues('link');

    setLinks((state) => {
      return [
        ...state,
        {
          name: 'Link ' + (state.length + 1),
          link,
        },
      ];
    });

    setValue('link', '');
  };

  const handleDeleteLink = (linkName: string) => {
    setLinks((state) => {
      return state.filter((link) => link.name !== linkName);
    });
  };

  const updateSupplierMessage = (supplierMessage: string) => {
    productsApi
      .put(`/orders/${order.id}`, {
        supplierMessage,
        documentationMessage: supplierMessage,
        status: 'Pedido incompleto',
        franchiseStatus: 'Pedido incompleto',
        isDocumentationOk: false,
      })
      .then(() => {
        toast.success('Status do pedido atualizado');

        history.replace('/orcamentos/orcamentos-e-pedidos/');
      })
      .catch((error) => {
        const errorMessage = error.response.data.message;

        toast.error(errorMessage);
      });
  };

  const updateToNFEStatus = () => {
    productsApi
      .put(`/orders/${order.id}`, {
        documentationMessage: null,
        isDocumentationOk: true,
        status: 'NFE emitida',
        franchiseStatus: 'NFE emitida',
      })
      .then(() => {
        toast.success('Status do pedido atualizado');

        history.replace('/orcamentos/orcamentos-e-pedidos/');
      })
      .catch((error) => {
        const errorMessage = error.response.data.message;

        toast.error(errorMessage);
      });
  };

  const updateToBoletoStatus = () => {
    productsApi
      .put(`/orders/${order.id}`, {
        documentationMessage: null,
        isDocumentationOk: true,
        status: 'Boleto/Espelho',
        franchiseStatus: 'Aguardando pagamento',
      })
      .then(() => {
        toast.success('Status do pedido atualizado');

        history.replace('/orcamentos/orcamentos-e-pedidos/');
      })
      .catch((error) => {
        const errorMessage = error.response.data.message;

        toast.error(errorMessage);
      });
  };

  const updateToNFFStatus = () => {
    productsApi
      .put(`/orders/${order.id}`, {
        documentationMessage: null,
        isDocumentationOk: true,
        status: 'NFF/Espelho',
        franchiseStatus: 'Aguardando pagamento',
      })
      .then(() => {
        toast.success('Status do pedido atualizado');

        history.replace('/orcamentos/orcamentos-e-pedidos/');
      })
      .catch((error) => {
        const errorMessage = error.response.data.message;

        toast.error(errorMessage);
      });
  };

  const updateToLinkStatus = () => {
    const normalizedLinks = links.map((link) => {
      return {
        link: link.link,
      };
    });

    productsApi
      .put(`/orders/${order.id}`, {
        documentationMessage: null,
        isDocumentationOk: true,
        status: 'Link do cartão',
        franchiseStatus: 'Aguardando pagamento',
        paymentLinks: normalizedLinks,
      })
      .then(() => {
        toast.success('Status do pedido atualizado');

        history.replace('/orcamentos/orcamentos-e-pedidos/');
      })
      .catch((error) => {
        const errorMessage = error.response.data.message;

        toast.error(errorMessage);
      });
  };

  const onSubmit = async (data: Form) => {
    if (supplierError) {
      updateSupplierMessage(data.supplierMessage);

      return;
    }

    switch (documentType) {
      case 'nfe':
        updateToNFEStatus();

        break;

      case 'boleto':
        updateToBoletoStatus();

        break;

      case 'nff':
        updateToNFFStatus();

        break;

      case 'links':
        if (links.length) {
          updateToLinkStatus();
        } else {
          setError('link', {
            type: 'field-empty',
            message: 'Envie ao menos um link',
          });
        }

        break;
    }
  };

  const [cancelModal, setCancelModal] = useState(false);

  const cancelOrder = () => {
    productsApi
      .patch(`/orders/${order.id}/status`, {
        status: 'Pedido cancelado',
        franchiseStatus: 'Pedido cancelado',
      })
      .then(() => {
        toast.success('Status do pedido atualizado');

        history.replace('/orcamentos/orcamentos-e-pedidos/');
      })
      .catch((error) => {
        const errorMessage = error.response.data.message;

        toast.error(errorMessage);
      });
  };

  const handleCancelModal = () => {
    setCancelModal((state) => !state);
  };

  return (
    <Container>
      <Breadcrumb links={breadcrumb} />

      <PageHeader>
        <span>{'Pedidos enviados'}</span>
      </PageHeader>

      <Content onSubmit={handleSubmit(onSubmit)} noValidate>
        <ConsultantMessage>
          <div>
            <h1>Observações do pedido</h1>

            <p>{order.observations}</p>
          </div>

          <CancelOrder>
            <Button
              type="button"
              backgroundColor="#E01919"
              backgroundHoverColor="#e01919c1"
              typeStyle="default"
              text="Cancelar pedido"
              onClick={handleCancelModal}
              icon={<FiXCircle />}
            />
          </CancelOrder>
        </ConsultantMessage>

        <Documents>
          <div>
            <h1>Documentos enviados pelo vendedor</h1>

            <Wrapper>
              <ul>
                <label>Pessoa física ou jurídica</label>

                {orderFiles?.PersonType.map((file) => {
                  return (
                    <li key={file.name}>
                      <Button
                        type="button"
                        backgroundColor="#FFFFFF"
                        typeStyle="default"
                        text="Fazer download"
                        onClick={() => {
                          if (file.path) {
                            downloadFile(file.path);
                          }
                        }}
                        icon={<CloudIcon />}
                      />
                      <SentDate>
                        Enviado em:{' '}
                        {format(
                          new Date(file.updatedAt as string),
                          'dd/MM/yyyy',
                          {
                            locale: ptBR,
                          }
                        )}
                      </SentDate>
                    </li>
                  );
                })}
              </ul>

              <ul>
                <label>Comprovante de residência</label>

                {orderFiles?.HouseDocumentProof.map((file) => {
                  return (
                    <li key={file.name}>
                      <Button
                        type="button"
                        backgroundColor="#FFFFFF"
                        typeStyle="default"
                        text="Fazer download"
                        onClick={() => {
                          if (file.path) {
                            downloadFile(file.path);
                          }
                        }}
                        icon={<CloudIcon />}
                      />
                      <SentDate>
                        Enviado em:{' '}
                        {format(
                          new Date(file.updatedAt as string),
                          'dd/MM/yyyy',
                          {
                            locale: ptBR,
                          }
                        )}
                      </SentDate>
                    </li>
                  );
                })}
              </ul>

              <ul>
                <label>Documento do Banco</label>

                {orderFiles?.BankDocument.map((file) => {
                  return (
                    <li key={file.name}>
                      <Button
                        type="button"
                        backgroundColor="#FFFFFF"
                        typeStyle="default"
                        text="Fazer download"
                        onClick={() => {
                          if (file.path) {
                            downloadFile(file.path);
                          }
                        }}
                        icon={<CloudIcon />}
                      />
                      <SentDate>
                        Enviado em:{' '}
                        {format(
                          new Date(file.updatedAt as string),
                          'dd/MM/yyyy',
                          {
                            locale: ptBR,
                          }
                        )}
                      </SentDate>
                    </li>
                  );
                })}
              </ul>

              <ul>
                <label>Termo de aceite assinado</label>

                {orderFiles?.AcceptanceTerm.map((file) => {
                  return (
                    <li key={file.name}>
                      <Button
                        type="button"
                        backgroundColor="#FFFFFF"
                        typeStyle="default"
                        text="Fazer download"
                        onClick={() => {
                          if (file.path) {
                            downloadFile(file.path);
                          }
                        }}
                        icon={<CloudIcon />}
                      />
                      <SentDate>
                        Enviado em:{' '}
                        {format(
                          new Date(file.updatedAt as string),
                          'dd/MM/yyyy',
                          {
                            locale: ptBR,
                          }
                        )}
                      </SentDate>
                    </li>
                  );
                })}
              </ul>

              <ul>
                <label>Outros documentos</label>

                {orderFiles?.OtherDocuments.map((file) => {
                  return (
                    <li key={file.name}>
                      <Button
                        type="button"
                        backgroundColor="#FFFFFF"
                        typeStyle="default"
                        text="Fazer download"
                        onClick={() => {
                          if (file.path) {
                            downloadFile(file.path);
                          }
                        }}
                        icon={<CloudIcon />}
                      />
                      <SentDate>
                        Enviado em:{' '}
                        {format(
                          new Date(file.updatedAt as string),
                          'dd/MM/yyyy',
                          {
                            locale: ptBR,
                          }
                        )}
                      </SentDate>
                    </li>
                  );
                })}
              </ul>
            </Wrapper>
          </div>
        </Documents>

        <Info>
          <h1>A documentaçao já foi enviada para o fornecedor.</h1>
        </Info>

        <RadioWrapper>
          <Controller
            control={control}
            name="supplierError"
            render={({ field: { ref, onChange, value } }) => {
              return (
                <RadioGroup
                  ref={ref}
                  label="O fornecedor relatou algum erro?"
                  options={[
                    {
                      label: 'Sim',
                      value: 'true',
                    },
                    {
                      label: 'Não',
                      value: 'false',
                    },
                  ]}
                  rowMode
                  onChange={async (event) => {
                    const value = JSON.parse(event.target.value);

                    onChange(value);

                    clearErrors();
                  }}
                  value={JSON.stringify(value)}
                />
              );
            }}
          />

          {supplierError && (
            <Textarea
              label="Descreva o problema com a documentação"
              placeholder="Digite aqui"
              resize="vertical"
              height="4.8rem"
              error={errors.supplierMessage?.message}
              required
              {...register('supplierMessage')}
            />
          )}

          {!supplierError && (
            <DocumentTypes>
              <Controller
                control={control}
                name="documentType"
                render={({ field: { ref, value } }) => {
                  return (
                    <RadioGroup
                      ref={ref}
                      label="Qual o tipo de documento enviado pelo fornecedor?"
                      options={[
                        {
                          label: 'NFE',
                          value: 'nfe',
                        },
                        {
                          label: 'Link para cartão de crédito',
                          value: 'links',
                        },
                        {
                          label: 'Boleto/espelho',
                          value: 'boleto',
                        },
                        {
                          label: 'NFF/espelho',
                          value: 'nff',
                        },
                      ]}
                      rowMode
                      onChange={async (event) => {
                        const value = event.target.value;

                        reset({
                          documentType: value,
                          supplierError: getValues('supplierError'),
                          nfe: getValues('nfe'),
                          nff: getValues('nff'),
                          boleto: getValues('boleto'),
                        });
                      }}
                      value={value}
                    />
                  );
                }}
              />

              <div>
                {documentType.includes('nfe') && (
                  <Controller
                    name="nfe"
                    control={control}
                    render={({
                      field: { ref, value, onChange },
                      fieldState: { error },
                    }) => {
                      return (
                        <FileInput
                          ref={ref}
                          label="Documento enviado pelo fornecedor"
                          inputLabel="Anexar arquivo"
                          error={error?.message}
                          api={productsApi}
                          url={`/orders/upload-file/${order.id}/type/NFE`}
                          deleteUrl={'/orders/upload-file/'}
                          onChange={(files) => onChange(files)}
                          files={value}
                        />
                      );
                    }}
                  />
                )}

                {documentType.includes('nff') && (
                  <Controller
                    name="nff"
                    control={control}
                    render={({
                      field: { ref, value, onChange },
                      fieldState: { error },
                    }) => {
                      return (
                        <FileInput
                          ref={ref}
                          label="Documento enviado pelo fornecedor"
                          inputLabel="Anexar arquivo"
                          error={error?.message}
                          api={productsApi}
                          url={`/orders/upload-file/${order.id}/type/NFF`}
                          deleteUrl={'/orders/upload-file/'}
                          onChange={(files) => onChange(files)}
                          files={value}
                        />
                      );
                    }}
                  />
                )}

                {documentType.includes('boleto') && (
                  <Controller
                    name="boleto"
                    control={control}
                    render={({
                      field: { ref, value, onChange },
                      fieldState: { error },
                    }) => {
                      return (
                        <FileInput
                          ref={ref}
                          label="Documento enviado pelo fornecedor"
                          inputLabel="Anexar arquivo"
                          error={error?.message}
                          api={productsApi}
                          url={`/orders/upload-file/${order.id}/type/BOLETO`}
                          deleteUrl={'/orders/upload-file/'}
                          onChange={(files) => onChange(files)}
                          files={value}
                        />
                      );
                    }}
                  />
                )}

                {documentType.includes('links') && (
                  <Links>
                    <div>
                      <Input
                        label="Insira o link"
                        placeholder="Link"
                        error={errors.link?.message}
                        message={
                          <>
                            <InfoIcon />
                            Somente um link por campo
                          </>
                        }
                        {...register('link')}
                      />

                      <Button
                        type="button"
                        text="Adicionar link"
                        onClick={async () => {
                          await trigger('link').then((isValid) => {
                            if (isValid) {
                              handleAddLink();
                            }
                          });
                        }}
                        icon="add"
                      />
                    </div>

                    <LinkWrapper>
                      <h1>Links adicionados</h1>

                      <div>
                        {links.map((link) => {
                          return (
                            <Link key={link.name}>
                              <button
                                onClick={() => handleDeleteLink(link.name)}
                              >
                                <FiTrash2 />
                              </button>
                              <div>
                                <h1>{link.name}</h1>
                                <a href={link.link}>{link.link}</a>
                              </div>
                            </Link>
                          );
                        })}
                      </div>
                    </LinkWrapper>
                  </Links>
                )}
              </div>
            </DocumentTypes>
          )}
        </RadioWrapper>

        <ButtonGroup>
          <Button
            type="button"
            text="Voltar"
            typeStyle="default"
            backgroundHoverColor="#C9CBCF"
            onClick={() =>
              history.replace('/orcamentos/orcamentos-e-pedidos', 'Pedidos')
            }
          />
          <Button
            type="submit"
            text={
              supplierError ? 'Enviar para franqueado' : 'Enviar para pagamento'
            }
            disabled={hasFormErrors}
          />
        </ButtonGroup>
      </Content>

      <CancelOrderModal
        isOpen={cancelModal}
        onConfirm={cancelOrder}
        onCancel={handleCancelModal}
      />
    </Container>
  );
};
