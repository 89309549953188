import React from 'react';
import { Button } from '../../../../../components';
import { Buttons, ModalContainer } from './styles';
import Modal, { Props } from 'react-modal';

interface ModalProps extends Props {
  title?: string;
  onConfirm: () => void;
  onCancel: () => void;
}

const ConfirmationModal: React.FC<ModalProps> = ({
  onConfirm,
  onCancel,
  title = 'title',
  children,
  ...rest
}) => {
  return (
    <Modal
      style={{
        content: {
          borderRadius: '16px',
          maxWidth: '34.5rem',
          width: '100%',
          height: 'fit-content',
          padding: '1.5rem',
          inset: '50%',
          transform: 'translate(-50%, -50%)',
          overflow: 'auto',
        },
        overlay: {
          backgroundColor: 'rgba(19, 20, 21, 0.6)',
        },
      }}
      {...rest}
    >
      <ModalContainer>
        <h1>{title}</h1>
        {children}
        <Buttons>
          <Button
            type="submit"
            text="Cancelar"
            typeStyle="default"
            backgroundHoverColor="#C9CBCF"
            onClick={() => onCancel && onCancel()}
          />
          <Button
            type="submit"
            text="Confirmar"
            backgroundColor="#ED5A5A"
            backgroundHoverColor="#ed5a5abc"
            onClick={() => onConfirm && onConfirm()}
          />
        </Buttons>
      </ModalContainer>
    </Modal>
  );
};

export default ConfirmationModal;
