import { yupResolver } from '@hookform/resolvers/yup';
import { format } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import React, { useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { Breadcrumb } from '../../../../../../components/breadcrumb';
import { Button } from '../../../../../../components/button';
import { FileInput, IFile } from '../../../../../../components/file-input';
import { CloudIcon } from '../../../../../../components/icons';
import { Order } from '../../../../../../contexts/orders/types';
import productsApi from '../../../../../../services/products';
import { downloadFile } from '../../../../../../utils/downloadFile';
import { ButtonGroup, Container, PageHeader } from '../../../../../styles';
import { FRANCHISE_ORDER_STATUS } from '../../../components/orders/innerTables/franchise';
import { SentDate } from '../../../styles';
import { Content, Documents, Links, Payment, Wrapper } from './styles';

type FranchiseWaitingPaymentProps = {
  order: Order;
};

type Form = {
  paymentConfirmation: IFile[];
};

type OrderFiles = {
  PaymentConfirmation: IFile[];
  BOLETO: IFile[];
  NFE: IFile[];
  NFF: IFile[];
};

const schema = yup.object().shape({
  paymentConfirmation: yup
    .mixed()
    .test('check if field is empty', (value: IFile[], { createError }) => {
      const hasErrors = value.some((file) => file.error);

      if (value.length > 0) {
        return hasErrors
          ? createError({
              path: 'paymentConfirmation',
              message: 'Remova o arquivo com erro',
              type: 'field-error',
            })
          : true;
      } else {
        return createError({
          path: 'paymentConfirmation',
          message: 'Campo obrigatório',
          type: 'field-empty',
        });
      }
    }),
});

export const FranchiseWaitingPayment: React.FC<FranchiseWaitingPaymentProps> = (
  props
) => {
  const { order } = props;

  const history = useHistory();

  const files = useMemo(() => {
    return (
      order.files?.reduce(
        (acc: OrderFiles, file: File) => {
          if (file.type in acc) {
            return {
              ...acc,
              [file.type]: [
                ...acc[file.type as keyof OrderFiles],
                {
                  ...file,
                  progress: 100,
                },
              ],
            };
          }

          return acc;
        },
        {
          PaymentConfirmation: [],
          BOLETO: [],
          NFE: [],
          NFF: [],
        }
      ) ?? {
        PaymentConfirmation: [],
        BOLETO: [],
        NFE: [],
        NFF: [],
      }
    );
  }, [order]);

  const hasBoleto = !!files.BOLETO.length;

  const hasNFE = !!files.NFE.length;

  const hasNFF = !!files.NFF.length;

  const { orderId, status } = useParams<{
    orderId: string;
    status: FRANCHISE_ORDER_STATUS;
  }>();

  const links = [
    {
      id: 1,
      title: 'Orçamentos',
      link: '/orcamentos',
      active: false,
    },
    {
      id: 2,
      title: 'Orçamentos e Pedidos',
      link: '/orcamentos/orcamentos-e-pedidos',
      active: false,
    },
    {
      id: 3,
      title: 'Pedidos enviados',
      link: `/orcamentos/orcamentos-e-pedidos/pedidos-enviados/${orderId}/${status}`,
      active: true,
    },
  ];

  const { control, handleSubmit, watch } = useForm<Form>({
    resolver: yupResolver(schema),
    defaultValues: {
      paymentConfirmation: files.PaymentConfirmation,
    },
  });

  const hasFiles = !!watch('paymentConfirmation').length;

  const hasLinks = !!order.paymentLinks.length;

  const updateOrderStatus = () => {
    productsApi
      .patch(`/orders/${order.id}/status`, {
        status: 'Pagamento Efetuado',
        franchiseStatus: 'Aguardando NFE',
      })
      .then(() => {
        toast.success('Status do pedido atualizado');

        history.replace('/orcamentos/orcamentos-e-pedidos/');
      })
      .catch((error) => {
        const errorMessage = error.response.data.message;

        toast.error(errorMessage);
      });
  };

  const onSubmit = () => {
    updateOrderStatus();
  };

  return (
    <Container>
      <Breadcrumb links={links} />

      <PageHeader>
        <span>{'Pedidos enviados'}</span>
      </PageHeader>

      <Content onSubmit={handleSubmit(onSubmit)}>
        {hasLinks && (
          <Links>
            <h1>Faça o pagamento através dos links</h1>

            <div>
              {order.paymentLinks.map((link, index) => {
                return (
                  <div key={link.id}>
                    <h1>{`Link ${index + 1}`}</h1>

                    <a href={link.link} target="_blank" rel="noreferrer">
                      {link.link}
                    </a>
                  </div>
                );
              })}
            </div>
          </Links>
        )}

        <Payment>
          {!hasLinks && (
            <div>
              <h1>Aguardando pagamento</h1>

              <p>
                Para efetuar o pagamento, faça o download da documentaçao
                enviada pelo fornecedor.
              </p>

              <Documents>
                <div>
                  <h1>Documentos enviados pelo vendedor</h1>

                  <Wrapper>
                    {hasBoleto && (
                      <ul>
                        <label>Boleto/espelho</label>

                        {files.BOLETO.map((file) => {
                          return (
                            <li key={file.id}>
                              <Button
                                type="button"
                                backgroundColor="#FFFFFF"
                                typeStyle="default"
                                text="Fazer download"
                                onClick={() => {
                                  if (file.path) {
                                    downloadFile(file.path);
                                  }
                                }}
                                icon={<CloudIcon />}
                              />
                              <SentDate>
                                Enviado em:{' '}
                                {format(
                                  new Date(file.updatedAt as string),
                                  'dd/MM/yyyy',
                                  {
                                    locale: ptBR,
                                  }
                                )}
                              </SentDate>
                            </li>
                          );
                        })}
                      </ul>
                    )}

                    {hasNFE && (
                      <ul>
                        <label>NFE</label>

                        {files.NFE.map((file) => {
                          return (
                            <li key={file.id}>
                              <Button
                                type="button"
                                backgroundColor="#FFFFFF"
                                typeStyle="default"
                                text="Fazer download"
                                onClick={() => {
                                  if (file.path) {
                                    downloadFile(file.path);
                                  }
                                }}
                                icon={<CloudIcon />}
                              />
                              <SentDate>
                                Enviado em:{' '}
                                {format(
                                  new Date(file.updatedAt as string),
                                  'dd/MM/yyyy',
                                  {
                                    locale: ptBR,
                                  }
                                )}
                              </SentDate>
                            </li>
                          );
                        })}
                      </ul>
                    )}

                    {hasNFF && (
                      <ul>
                        <label>NFF/espelho</label>

                        {files.NFF.map((file) => {
                          return (
                            <li key={file.id}>
                              <Button
                                type="button"
                                backgroundColor="#FFFFFF"
                                typeStyle="default"
                                text="Fazer download"
                                onClick={() => {
                                  if (file.path) {
                                    downloadFile(file.path);
                                  }
                                }}
                                icon={<CloudIcon />}
                              />
                              <SentDate>
                                Enviado em:{' '}
                                {format(
                                  new Date(file.updatedAt as string),
                                  'dd/MM/yyyy',
                                  {
                                    locale: ptBR,
                                  }
                                )}
                              </SentDate>
                            </li>
                          );
                        })}
                      </ul>
                    )}
                  </Wrapper>
                </div>
              </Documents>
            </div>
          )}

          <p>
            Depois, faça upload do comprovante de pagamento. Para que este seja
            encaminhado ao fornecedor.
          </p>

          <Controller
            name="paymentConfirmation"
            control={control}
            render={({
              field: { ref, value, onChange },
              fieldState: { error },
            }) => {
              return (
                <FileInput
                  ref={ref}
                  label="Comprovante de pagamento"
                  inputLabel="Anexar arquivo"
                  error={error?.message}
                  api={productsApi}
                  url={`/orders/upload-file/${order.id}/type/PaymentConfirmation`}
                  deleteUrl={'/orders/upload-file/'}
                  onChange={(files) => onChange(files)}
                  files={value}
                />
              );
            }}
          />
        </Payment>

        <ButtonGroup>
          <Button
            type="button"
            text="Voltar"
            typeStyle="default"
            backgroundHoverColor="#C9CBCF"
            onClick={() =>
              history.replace('/orcamentos/orcamentos-e-pedidos', 'Pedidos')
            }
          />
          <Button
            type="submit"
            text={'Enviar para o fornecedor'}
            disabled={!hasFiles}
          />
        </ButtonGroup>
      </Content>
    </Container>
  );
};

