import React, {
  CSSProperties,
  ForwardedRef,
  InputHTMLAttributes,
  ReactNode,
  useState,
} from 'react';

import { Container, Wrapper } from './styles';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  name?: string;
  type?: string;
  disabled?: boolean;
  required?: boolean;
  iconLeft?: ReactNode;
  iconRight?: ReactNode;
  style?: CSSProperties;
  height?: string;
  error?: string;
  message?: ReactNode;
}

const Input = React.forwardRef(
  (props: InputProps, myRef: ForwardedRef<HTMLInputElement>) => {
    const {
      label,
      iconLeft,
      iconRight,
      style,
      height = '3rem',
      type = 'text',
      error,
      disabled = false,
      required = false,
      name,
      message,
      ...rest
    } = props;

    const [active, setActive] = useState(false);

    return (
      <Container
        height={height}
        active={active}
        error={!!error}
        disabled={disabled}
        style={style}
        onFocus={() => setActive(true)}
        onBlur={() => setActive(false)}
      >
        {label && (required ? label + '*' : label)}
        <div>
          {iconLeft ?? iconLeft}
          <input
            type={type}
            name={name}
            ref={myRef}
            disabled={disabled}
            {...rest}
          />
          {iconRight ?? iconRight}
        </div>

        <Wrapper>
          {error && <span>{error}</span>}

          {message && <span className="message">{message}</span>}
        </Wrapper>
      </Container>
    );
  }
);

Input.displayName = 'Input';

export default Input;
