import styled from 'styled-components';

export const TableHeader = styled.header`
  display: flex;
  justify-content: flex-end;
  gap: 1rem;

  background-color: #fafafa;

  @media (max-width: 992px) {
    flex-direction: column;

    & > div:has(input) {
      max-width: unset;
      order: 2;
    }

    & > div:has(button) {
      order: 1;
    }

    & > div:has(button) > button {
      width: 100%;
    }
  }
`;
