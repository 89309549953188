import React, { useRef } from 'react';
import * as yup from 'yup';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { Link, useHistory } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import usersApi from '../../services/users';
import { Button } from '../../components/';
import Input from '../../components/form-elements/input';
import { Container, ForgotPassword, Group } from './styles';

type LoginType = {
  email: string;
};

const ForgotPasswordPage: React.FC = () => {
  const history = useHistory();
  const formRef = useRef<FormHandles>(null);
  const schema = yup.object().shape({
    email: yup
      .string()
      .email('E-mail inválido')
      .required('E-mail é um campo obrigatório'),
  });

  const {
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<LoginType>({ resolver: yupResolver(schema) });

  const onSubmit = async (data: LoginType) => {
    try {
      await usersApi.post('/api/users/forgot-password', {
        email: data.email,
      });
      toast.success('E-mail de recuperação enviado com sucesso');
      history.push('/');
    } catch (error) {
      if (axios.isAxiosError(error)) {
        toast.error(error.response?.data.message);
      }
    }
  };

  return (
    <Container>
      <ForgotPassword>
        <Link to="/">Voltar</Link>
      </ForgotPassword>
      <Form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
        <h1>Esqueci minha senha</h1>
        <span>Digite o e-mail cadastrado para recuperar a sua senha.</span>
        <Group>
          <Input
            name="email"
            label="E-mail"
            placeholder="Digite o seu e-mail"
            onChange={(e) => setValue('email', e.target.value)}
            error={errors.email?.message}
          />
        </Group>
        <Button
          text="Enviar"
          size="large"
          backgroundColor="#FFC129"
          backgroundHoverColor="#eda800"
          fontColor="#00299B"
          type="submit"
        />
      </Form>
    </Container>
  );
};

export default ForgotPasswordPage;
