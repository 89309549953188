import styled from 'styled-components';

export const Container = styled.div`
  display: flex;

  min-height: 100vh;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  max-width: calc(100vw - 18.5rem);
  width: 100%;

  @media screen and (max-width: 520px) {
    max-width: 100%;
  }
`;

export const Content = styled.div`
  background-color: #f5f5f6;

  padding: 0 2rem;

  height: 100%;

  @media (max-width: 520px) {
    padding: 0 5%;
  }
`;
