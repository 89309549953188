import styled, { css } from 'styled-components';

type groupType = {
  size?: 'small' | 'medium';
};

export const Group = styled.div<groupType>`
  ${(props) =>
    props.size === 'small' &&
    css`
      max-width: 350px;
    `}
  ${(props) =>
    props.size === 'medium' &&
    css`
      max-width: 570px;
    `}

    span {
    display: inline-block;
    margin-bottom: 16px;
    color: #27282b;
    font-weight: 600;
  }
`;

export const Columns = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;

  div {
    margin-left: 16px;
  }
`;

export const File = styled.a`
  border: 0;
  background: #7ed56b;
  border-radius: 4px;
  min-height: 48px;
  display: inline-block;
  margin-top: 8px;
  padding: 16px;

  font-size: 14px;
  color: #121212;

  margin-right: 16px;
`;
