import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Breadcrumb, Button, FileUpload } from '../../../components';
import { Divider } from '../../../components/menu/styles';
import FileUploadService from '../../../services/FileUploadService';
import { Files } from '../../../types/files';
import { UploadTypes } from '../types';

import {
  Container,
  Content,
  Wrapper,
  PageHeader,
  ButtonContainer,
} from './styles';

type NewProjectDocumentPageProps = {
  id: string;
};

const NewProjectDocumentPage: React.FC = () => {
  const history = useHistory();
  const { id } = useParams<NewProjectDocumentPageProps>();
  const [files, setFiles] = useState<Files[]>([]);

  useEffect(() => {
    loadFiles();
  }, []);

  const loadFiles = () => {
    FileUploadService.getFiles(id, '').then((res) => {
      const normalizied = res.data.map((file: any) => ({
        id: file.id,
        name: file.originalName,
        url: file.path,
        type: file.type,
        file_url: file.file_url,
      }));
      setFiles(normalizied);
    });
  };

  const links = [
    {
      id: 1,
      title: 'Projetos',
      link: '/projetos',
      active: false,
    },
    {
      id: 2,
      title: 'Novo projeto',
      link: '/projeto/novo',
      active: false,
    },
    {
      id: 3,
      title: 'Formulário',
      link: '/projeto/novo/formulario',
      active: false,
    },
    {
      id: 4,
      title: 'Documentos',
      link: '/projeto/novo/formulario/documentos',
      active: true,
    },
  ];

  const getFiles = (type: string) => {
    return files.filter((file) => file.type === type);
  };

  const validateHasAllFiles = () => {
    let valid = true;
    if (getFiles(UploadTypes.CONTA_ENEGIA).length === 0) {
      valid = false;
    }
    if (getFiles(UploadTypes.TERMO_ACEITA_CLIENTE).length === 0) {
      valid = false;
    }
    if (getFiles(UploadTypes.DOCUMENTO_TITULAR).length === 0) {
      valid = false;
    }
    if (getFiles(UploadTypes.PADRAO_ENTRADA_QUADRO).length === 0) {
      valid = false;
    }
    if (getFiles(UploadTypes.INTERIOR_QUADRO_MEDICAO).length === 0) {
      valid = false;
    }
    if (getFiles(UploadTypes.DISJUNTOR_GERAL_PADRAO_ENTRADA).length === 0) {
      valid = false;
    }
    if (getFiles(UploadTypes.ESPECIFICACAO_KIT_VENDIDO_MARCA).length === 0) {
      valid = false;
    }
    if (
      getFiles(UploadTypes.PROCURACAO_RECONHECIMENTO_ASSINATURA_CARTORIO)
        .length === 0
    ) {
      valid = false;
    }
    if (getFiles(UploadTypes.NUMERO_POSTE_TRANSFORMADOR_PROXIMO).length === 0) {
      valid = false;
    }

    return valid;
  };

  return (
    <Container>
      <Breadcrumb links={links} />
      <Wrapper>
        <PageHeader>
          <span>Novo Projeto</span>
        </PageHeader>
        <Content>
          <FileUpload
            title="Conta de energia do cliente"
            helpMessage="Permitido somente arquivos .jpg, .png e .pdf com no máximo 5MB"
            loadedFiles={getFiles(UploadTypes.CONTA_ENEGIA)}
            projectId={id}
            type={UploadTypes.CONTA_ENEGIA}
            onLoadFiles={() => loadFiles()}
          />
          <Divider />

          <FileUpload
            title="Termo de aceite com assinatura do cliente"
            helpMessage="Permitido somente arquivos .jpg, .png e .pdf com no máximo 5MB"
            loadedFiles={getFiles(UploadTypes.TERMO_ACEITA_CLIENTE)}
            projectId={id}
            type={UploadTypes.TERMO_ACEITA_CLIENTE}
            onLoadFiles={() => loadFiles()}
          />
          <Divider />

          <FileUpload
            title="Documento do titular da conta de energia (RG / CNH)"
            helpMessage="Permitido somente arquivos .jpg, .png e .pdf com no máximo 5MB"
            loadedFiles={getFiles(UploadTypes.DOCUMENTO_TITULAR)}
            projectId={id}
            type={UploadTypes.DOCUMENTO_TITULAR}
            onLoadFiles={() => loadFiles()}
          />
          <Divider />

          <FileUpload
            title="Foto do padrão de entrada com quadro aberto e poste"
            helpMessage="Permitido somente arquivos .jpg, .png e .pdf com no máximo 5MB"
            loadedFiles={getFiles(UploadTypes.PADRAO_ENTRADA_QUADRO)}
            projectId={id}
            type={UploadTypes.PADRAO_ENTRADA_QUADRO}
            onLoadFiles={() => loadFiles()}
          />
          <Divider />

          <FileUpload
            title="Foto do interior do quadro de medição"
            helpMessage="Permitido somente arquivos .jpg, .png e .pdf com no máximo 5MB"
            loadedFiles={getFiles(UploadTypes.INTERIOR_QUADRO_MEDICAO)}
            projectId={id}
            type={UploadTypes.INTERIOR_QUADRO_MEDICAO}
            onLoadFiles={() => loadFiles()}
          />
          <Divider />

          <FileUpload
            title="Foto do disjuntor geral do padrão de entrada"
            helpMessage="Permitido somente arquivos .jpg, .png e .pdf com no máximo 5MB"
            loadedFiles={getFiles(UploadTypes.DISJUNTOR_GERAL_PADRAO_ENTRADA)}
            projectId={id}
            type={UploadTypes.DISJUNTOR_GERAL_PADRAO_ENTRADA}
            onLoadFiles={() => loadFiles()}
          />
          <Divider />

          <FileUpload
            title="Especificações do kit vendido com marca (Modelo dos Equipamentos)"
            helpMessage="Permitido somente arquivos .jpg, .png e .pdf com no máximo 5MB"
            loadedFiles={getFiles(UploadTypes.ESPECIFICACAO_KIT_VENDIDO_MARCA)}
            projectId={id}
            type={UploadTypes.ESPECIFICACAO_KIT_VENDIDO_MARCA}
            onLoadFiles={() => loadFiles()}
          />
          <Divider />

          <FileUpload
            title="Procuração com reconhecimento de assinatura em cartório"
            helpMessage="Permitido somente arquivos .jpg, .png e .pdf com no máximo 5MB"
            loadedFiles={getFiles(
              UploadTypes.PROCURACAO_RECONHECIMENTO_ASSINATURA_CARTORIO
            )}
            projectId={id}
            type={UploadTypes.PROCURACAO_RECONHECIMENTO_ASSINATURA_CARTORIO}
            onLoadFiles={() => loadFiles()}
          />
          <Divider />

          <FileUpload
            title="Foto do número de poste com transformador mais próximo"
            helpMessage="Permitido somente arquivos .jpg, .png e .pdf com no máximo 5MB"
            loadedFiles={getFiles(
              UploadTypes.NUMERO_POSTE_TRANSFORMADOR_PROXIMO
            )}
            projectId={id}
            type={UploadTypes.NUMERO_POSTE_TRANSFORMADOR_PROXIMO}
            onLoadFiles={() => loadFiles()}
          />

          <Divider />
          <ButtonContainer>
            <Button
              text="Voltar"
              backgroundColor="#b8b8b8"
              backgroundHoverColor="#292929"
              onClick={() => {
                history.push(`/projeto/${id}/editar`);
              }}
            />
            <Button
              text="Avançar"
              backgroundColor="#000000"
              backgroundHoverColor="#292929"
              onClick={() => {
                if (validateHasAllFiles()) {
                  history.push(
                    `/projeto/novo/formulario/documentos/extra/${id}`
                  );
                } else {
                  toast.error(
                    'É necessário preencher todos os documentos antes de continuar'
                  );
                }
              }}
            />
          </ButtonContainer>
        </Content>
      </Wrapper>
    </Container>
  );
};

export default NewProjectDocumentPage;
