import styled from 'styled-components';

export const Content = styled.form`
  height: 100%;

  display: flex;
  flex-direction: column;

  padding: 1.5rem 0;
`;

export const Payment = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.875rem;

  height: 100%;

  & > div:first-child {
    display: flex;
    flex-direction: column;
    gap: 1.875rem;
  }

  & h1 {
    font-family: 'DunbarTextRegular', sans-serif;
    font-weight: 400;
    font-size: 1.25rem;

    letter-spacing: 0.02em;

    color: #27282b;
  }

  & p {
    font-family: 'DunbarTextRegular', sans-serif;

    font-weight: 400;
    font-size: 1rem;

    letter-spacing: 0.02em;

    color: #000000;
  }
`;

export const Links = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2.5rem;

  margin-bottom: 4rem;

  & > h1 {
    font-family: 'DunbarTextRegular', sans-serif;
    font-weight: 400;
    font-size: 1.25rem;

    letter-spacing: 0.02em;

    color: #27282b;
  }

  & > div {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(12.125rem, 15.125rem));
    gap: 3.625rem;

    & > div {
      display: flex;
      flex-direction: column;

      width: fit-content;

      & > h1 {
        font-family: 'DunbarTextRegular', sans-serif;

        font-weight: 400;
        font-size: 1rem;

        letter-spacing: 0.02em;

        color: #000000;
      }

      & > a {
        font-family: 'Montserrat';

        font-weight: 400;
        font-size: 1rem;

        letter-spacing: 0.02em;
        text-decoration-line: underline;

        color: #005af9;
      }
    }
  }
`;

export const Documents = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4.25rem;

  margin-bottom: 2.5rem;

  & > div {
    display: flex;
    flex-direction: column;
    gap: 1.125rem;

    & > h1 {
      font-family: 'DunbarTextRegular', sans-serif;
      font-weight: 400;
      font-size: 1.25rem;

      letter-spacing: 0.02em;

      color: #27282b;
    }
  }
`;

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(16.125rem, 24.125rem));
  gap: 1.5rem;

  & button {
    border: 1px solid #c9cbcf;
  }

  & > ul {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    & > label {
      font-family: 'Montserrat', sans-serif;
      font-weight: 500;
      font-size: 1rem;

      letter-spacing: 0.01em;

      color: #27282b;
    }
  }

  @media (max-width: 992px) {
    grid-template-columns: 1fr;

    & button {
      width: 100%;
    }
  }
`;
