import React, { useState } from 'react';
import { Button } from '../../../../../../components/button';
import DynamicFilter from '../../../../../../components/dynamic-filter';
import { CloudIcon } from '../../../../../../components/icons';
import { SearchInput } from '../../../../../../components/search';
import Table, { ColumnStructure } from '../../../../../../components/table';
import { useDashboard } from '../../../../../../contexts/dashboard';
import { Container, DownloadContainer, TableHeader } from './styles';
import { FranchiseData } from '../../../../../../contexts/dashboard/types';

type FranchiseRankProps = {};

type DashboardPropertiesType = {
  franchiseData: FranchiseData;
};

export const FranchiseRank: React.FC<FranchiseRankProps> = (props) => {
  const { franchiseData } = useDashboard() as DashboardPropertiesType;

  const [tableColumns, setTableColumns] = useState<ColumnStructure[]>([
    {
      id: 'rank',
      label: 'Ranking',
      orderBy: 'DESC',
      onClick: () => handleOrderBy('rank'),
    },
    {
      id: 'unity',
      label: 'Unidade',
      orderBy: 'DESC',
      onClick: () => handleOrderBy('unity'),
    },
    {
      id: 'sales',
      label: 'Vendas',
      orderBy: 'DESC',
      onClick: () => handleOrderBy('sales'),
    },
    {
      id: 'itemsSold',
      label: 'Itens vendidos',
      orderBy: 'DESC',
      onClick: () => handleOrderBy('itemsSold'),
    },
    {
      id: 'averageTicket',
      orderBy: 'DESC',
      label: 'Ticket Médio Produtos',
      onClick: () => handleOrderBy('averageTicket'),
    },
    {
      id: 'averageLabor',
      orderBy: 'DESC',
      label: 'Média de mão de obra',
      onClick: () => handleOrderBy('averageLabor'),
    },
    {
      id: 'ticket',
      orderBy: 'DESC',
      label: 'Ticket médio',
      onClick: () => handleOrderBy('ticket'),
    },
    {
      id: 'share',
      orderBy: 'DESC',
      label: 'Share',
      onClick: () => handleOrderBy('share'),
    },
  ]);

  const [tableItemns, setTableItems] = useState([
    {
      id: '1',
      rank: '1°',
      unity: 'Nome da unidade',
      sales: 'R$ 5.000.000,00',
      itemsSold: '1.000',
      averageTicket: 'R$ 50.000,00',
      averageLabor: 'R$ 20.000,00',
    },
    {
      id: '2',
      rank: '2°',
      unity: 'Nome da unidade',
      sales: 'R$ 5.000.000,00',
      itemsSold: '1.000',
      averageTicket: 'R$ 50.000,00',
      averageLabor: 'R$ 20.000,00',
    },
    {
      id: '3',
      rank: '3°',
      unity: 'Nome da unidade',
      sales: 'R$ 5.000.000,00',
      itemsSold: '1.000',
      averageTicket: 'R$ 50.000,00',
      averageLabor: 'R$ 20.000,00',
    },
    {
      id: '4',
      rank: '4°',
      unity: 'Nome da unidade',
      sales: 'R$ 5.000.000,00',
      itemsSold: '1.000',
      averageTicket: 'R$ 50.000,00',
      averageLabor: 'R$ 20.000,00',
    },
  ]);

  const [orderBy, setOrderBy] = useState('');

  const handleOrderBy = (columnId: string) => {
    setTableColumns((state) => {
      return state.map((column) => {
        const currentColumn = state.find((column) => column.id === columnId);

        setOrderBy(String(currentColumn?.id + ',' + currentColumn?.orderBy));

        return column.id === columnId
          ? column.orderBy === 'ASC'
            ? { ...column, orderBy: 'DESC' }
            : { ...column, orderBy: 'ASC' }
          : { ...column, orderBy: column.orderBy ? 'DESC' : '' };
      });
    });
  };

  return (
    <Container>
      <h1>Ranking de franqueados</h1>

      <DownloadContainer>
        <Button
          type="button"
          style={{ border: '1px solid #C9CBCF' }}
          backgroundColor="#FFFFFF"
          typeStyle="default"
          text="Baixar relatório"
          onClick={() => null}
          icon={<CloudIcon />}
        />
      </DownloadContainer>

      <Table
        header={
          <TableHeader>
            <SearchInput
              placeholder="Digite aqui para pesquisar"
              onTextChange={() => null}
              name="kitsSearch"
            />
            <DynamicFilter filters={[]} onChange={() => null} />
          </TableHeader>
        }
        columns={tableColumns}
        items={tableItemns}
        isLoading={false}
        pagination={{ currentPage: 1, lastPage: 1, limit: 10, total: 1 }}
        onLimitChange={() => null}
        onPageChange={() => null}
      />
    </Container>
  );
};

