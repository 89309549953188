import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  height: 100%;

  & > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 7.5rem;

    max-width: 38rem;
    width: 100%;

    & > svg {
      width: 11.25rem;
      height: 10rem;

      & > path {
        stroke: #dfe0e2;
      }
    }

    & > p {
      font-family: 'DunbarTextRegular';
      font-weight: 400;
      font-size: 1.5rem;

      text-align: center;
      letter-spacing: 0.03em;

      color: #000000;

      & > strong {
        font-family: 'DunbarTallBold';
      }
    }
  }
`;
