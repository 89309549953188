import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  margin-top: 3rem;

  height: 100%;

  & > h1 {
    color: #27282b;
    font-family: 'Montserrat', sans-serif;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 600;
    letter-spacing: 0.0125rem;
  }
`;

export const Wrapper = styled.div``;

export const TableHeader = styled.header`
  display: flex;
  justify-content: flex-start;

  background-color: #fafafa;
`;

