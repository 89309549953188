import * as yup from 'yup';

export const userSchema = yup.object().shape({
  name: yup.string().required('Nome é um campo obrigatório'),
  email: yup.string().required('E-mail é um campo obrigatório'),
  cpf: yup.string().nullable().optional(),
  role: yup.object().nullable().required('Perfil é um campo obrigatório'),
  password: yup
    .string()
    .min(6, 'A senha deve ter no mínimo 6 dígitos')
    .required('Senha é um campo obrigatório'),
  confirmPassword: yup
    .string()
    .oneOf(
      [yup.ref('password'), null],
      'Senha e repetir senha devem ser iguais'
    )
    .required('Repetir senha é um campo obrigatório'),
});
