import React from 'react';
import { useAuth } from '../../../../contexts/auth';
import { UserRoles } from '../../../../types/users';
import { BestSellingsSuppliers } from './components/BestSellingsSuppliers';
import { FranchiseRank } from './components/FranchiseRank';
import { Ranking } from './components/Ranking';
import { Container } from './styles';

export const Rankings: React.FC = () => {
  const { user } = useAuth();

  return (
    <Container>
      <BestSellingsSuppliers />

      <Ranking />

      {UserRoles.ADMIN.includes(user.role) && <FranchiseRank />}
    </Container>
  );
};
