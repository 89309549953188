import { AxiosInstance } from 'axios';

export const getToken = () => {
  try {
    const token = localStorage.getItem(
      `@energy:${process.env.REACT_APP_SITE_VERSION}-token`
    );

    return token;
  } catch (error) {
    return null;
  }
};

export const axiosConfig = (axiosInstance: AxiosInstance) => {
  axiosInstance.interceptors.request.use((config) => {
    const token = getToken();

    if (token && config.headers) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  });

  axiosInstance.interceptors.response.use(
    (response) => {
      return response;
    },
    async function (error) {
      if (error?.response?.status === 401) {
        localStorage.clear();

        window.location.href = '/';
      }

      return Promise.reject(error);
    }
  );
};
