import React from 'react';
import { FranchiseSelector } from '../../../components';
import { Container, PageHeader, Wrapper } from './../../styles';
import { FormContainer } from './style';

const FranchiseSelectPage: React.FC = () => {
  return (
    <Container>
      <Wrapper>
        <PageHeader>
          <span>Selecionar a Franquia</span>
        </PageHeader>
        <FormContainer>
          <FranchiseSelector />
        </FormContainer>
      </Wrapper>
    </Container>
  );
};

export default FranchiseSelectPage;
