import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import {
  Breadcrumb,
  Button,
  InfoIcon,
  StepProgress,
} from '../../../components';
import Loading from '../../../components/loading';
import { InputText } from '../../../components/search/styles';
import productsApi from '../../../services/products';
import InputFile from '../../../components/input-file';

import {
  ButtonGroup,
  Container,
  Divider,
  ImportContainer,
  ImportInputGroup,
  ImportInputs,
  PageHeader,
} from './../../styles';
import { Header } from './styles';

const links = [
  {
    id: 1,
    title: 'Produtos',
    link: '/produtos',
    active: false,
  },
  {
    id: 2,
    title: 'Importação de Produtos',
    link: '/produtos/importacao',
    active: false,
  },
  {
    id: 3,
    title: 'Envio de dados',
    link: '/produtos/importacao/kit-pronto',
    active: true,
  },
];

const ProductsImportSettedKitsPage: React.FC = () => {
  const history = useHistory();

  const [file, setFile] = useState<File[]>([]);
  const [discount, setDiscount] = useState<string>('0');
  const [rebate, setRebate] = useState<string>('0');
  const [loading, setLoading] = useState(false);

  const steps = [
    { key: 1, title: 'Enviar dados', active: true, complete: false },
    { key: 2, title: 'Conferência de dados', active: false, complete: false },
    { key: 3, title: 'Processamento', active: false, complete: false },
  ];

  const handleChangeDiscount = useCallback(
    (value: number | string) => {
      setDiscount(String(value));
    },
    [discount]
  );

  const handleChangeRebate = useCallback(
    (value: number | string) => {
      setRebate(String(value));
    },
    [rebate]
  );

  const handleSubmit = async () => {
    setLoading(true);

    try {
      const data = new FormData();

      data.append('type', 'default');
      data.append('discount', discount);
      data.append('rebate', rebate);
      data.append('file', file[0]);

      const { data: response } = await productsApi({
        method: 'POST',
        url: '/products/kit-uploads',
        data,
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      toast.success('Importação iniciada.');

      history.replace(
        `/produtos/importacao/kit-pronto/${response.id}/processamento`,
        response.draftProducts
      );
    } catch (error) {
      toast.error('Erro ao iniciar importação.');

      setLoading(false);

      throw error;
    }
  };

  if (loading)
    return (
      <Loading
        style={{
          height: '100%',
          alignItems: 'center',
        }}
        label="Processando"
      />
    );

  return (
    <Container>
      <Breadcrumb links={links} />

      <PageHeader>
        <span>{'Importação de Kits Prontos'}</span>
      </PageHeader>
      <StepProgress steps={steps} />

      <Header>
        <InputFile
          label="Anexe o arquivo XLS para importação"
          inputLabel="Anexar arquivo"
          onChange={(files) => setFile(files)}
          files={file}
          maxFiles={1}
          accept={{
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
              ['.xlsx'],
          }}
          onDelete={(fileName) =>
            setFile((state) => state.filter((file) => file.name !== fileName))
          }
        />
      </Header>

      <Divider />

      <ImportContainer>
        <h3>
          Defina os valores de desconto e rebate para os produtos desse arquivo.
        </h3>
        <ImportInputs>
          <ImportInputGroup>
            <label>
              Desconto
              <InputText
                type="text"
                error={false}
                placeholder="% 10"
                onChange={(e) => handleChangeDiscount(e.target.value)}
              />
            </label>
            <div>
              <InfoIcon />
              <p>Porcentagem de desconto a ser aplicada</p>
            </div>
          </ImportInputGroup>

          <ImportInputGroup>
            <label>
              Rebate
              <InputText
                type="text"
                error={false}
                placeholder="% 10"
                onChange={(e) => handleChangeRebate(e.target.value)}
              />
            </label>
            <div>
              <InfoIcon />
              <p>Acréscimo aplicado após o desconto</p>
            </div>
          </ImportInputGroup>
        </ImportInputs>
      </ImportContainer>

      <ButtonGroup>
        <Button
          type="button"
          text="Voltar"
          typeStyle="default"
          backgroundHoverColor="#C9CBCF"
          onClick={() => history.push('/produtos/importacao/')}
        />

        <Button
          type="submit"
          text="Avançar"
          typeStyle="confirm"
          onClick={handleSubmit}
        />
      </ButtonGroup>
    </Container>
  );
};

export default ProductsImportSettedKitsPage;
