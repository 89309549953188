import React, { CSSProperties, ReactNode, useCallback } from 'react';
import Dropzone, {
  DropzoneOptions,
  FileWithPath,
  DropzoneRef,
  Accept,
} from 'react-dropzone';

import { AddIcon, CloseIcon } from '..';

import { Container, Content, FileItem, FilePreviewContainer } from './styles';

interface InputFileProps extends DropzoneOptions {
  label?: string;
  inputLabel?: string;
  inputIcon?: ReactNode;
  files: File[];
  error?: string;
  required?: boolean;
  message?: ReactNode;
  style?: CSSProperties;
  className?: string;
  onChange: (files: FileWithPath[]) => void;
  onDelete?: (fileId: string) => void;
  accept?: Accept;
}

const InputFile = React.forwardRef<DropzoneRef, InputFileProps>(
  (props, ref) => {
    const {
      label = 'Selecionar foto',
      inputLabel = 'Selecionar imagem',
      inputIcon = <AddIcon />,
      onChange,
      error,
      files,
      required = false,
      message,
      style,
      className,
      onDelete,
      accept = {
        'application/pdf': ['.pdf'],
        'image/jpeg': ['.jpeg', '.jpg'],
        'image/png': ['.png'],
      },
      ...rest
    } = props;

    const onDrop = useCallback(
      (acceptedFiles: FileWithPath[]) => {
        if (acceptedFiles.length) {
          onChange(acceptedFiles);
        }
      },
      [files]
    );

    return (
      <Dropzone onDrop={onDrop} ref={ref} accept={accept} {...rest}>
        {({ getInputProps, getRootProps, fileRejections }) => (
          <Container className={className} style={style}>
            <label>{required ? label + '*' : label}</label>
            <Content isDisabled={rest.disabled}>
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                <p>
                  <>
                    {inputIcon}
                    {inputLabel}
                  </>
                </p>
              </div>
            </Content>

            {message && <span className="message">{message}</span>}

            {!!files?.length && (
              <FilePreviewContainer>
                {files.map(
                  (file: File & { id?: string; originalName?: string }) => {
                    return (
                      <FileItem key={file.name}>
                        <div>
                          <span>{file.originalName || file.name}</span>
                        </div>
                        <button
                          type="button"
                          onClick={() => {
                            onDelete?.(String(file.id || file.name));
                          }}
                        >
                          <CloseIcon />
                        </button>
                      </FileItem>
                    );
                  }
                )}
              </FilePreviewContainer>
            )}

            {error && <span>{error}</span>}

            {fileRejections.length > 0 &&
              fileRejections[0].errors.map((error) => {
                return <span key={error.code}>{error.message}</span>;
              })}
          </Container>
        )}
      </Dropzone>
    );
  }
);

InputFile.displayName = 'InputFile';

export default InputFile;
