import { format } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import React, { useState } from 'react';
import {
  FiCalendar,
  FiEdit,
  FiMinusCircle,
  FiPlusCircle,
  FiX,
} from 'react-icons/fi';
import { Props } from 'react-modal';
import { useHistory } from 'react-router-dom';
import { ShapeModal } from '../..';
import { Lead, STATES_UF } from '../../../../contexts/sales-funnel/types';
import { Button } from '../components/button';
import { InnerLostModal } from '../inner-modals/lost';
import { InnerScheduleModal } from '../inner-modals/schedule';
import {
  Aside,
  Buttons,
  Container,
  Content,
  Customer,
  Data,
  Header,
  Messages,
  Wrapper,
} from './styles';
import { Tags } from '../components/tags';
import { LeadModal } from '../lead-modal';
import { useSalesFunnel } from '../../../../contexts/sales-funnel';

type FinalProposalModalProps = {
  onCancel: () => void;
  content: Lead | null;
} & Props;

export const FinalProposalModal: React.FC<FinalProposalModalProps> = (
  props
) => {
  const { onCancel, content, ...rest } = props;

  const { leadModal, handleLeadModal } = useSalesFunnel();

  const history = useHistory();

  const [modals, setModals] = useState({
    schedule: {
      isOpen: false,
    },
    lost: {
      isOpen: false,
    },
  });

  const handleOpenModals = (modal: keyof typeof modals) => {
    setModals((state) => {
      if (modal in state) {
        return {
          ...state,
          [modal]: {
            isOpen: true,
          },
        };
      }

      return state;
    });
  };

  const handleCloseModals = (modal: keyof typeof modals) => {
    setModals((state) => {
      if (modal in state) {
        return {
          ...state,
          [modal]: {
            isOpen: false,
          },
        };
      }

      return state;
    });
  };

  return (
    <ShapeModal
      padding="0"
      maxWidth="60rem"
      onRequestClose={onCancel}
      {...rest}
    >
      <Container>
        <Header>
          <h1>Proposta final</h1>

          <button onClick={onCancel} type="button">
            <FiX />
          </button>
        </Header>

        <Content>
          <Tags lead={content} />
          <Wrapper>
            <Customer>
              <div>
                <header>
                  <h1>Dados do cliente</h1>

                  <Button
                    variant="white"
                    type="button"
                    maxWidth="7.313rem"
                    onClick={() => handleLeadModal(content)}
                  >
                    <FiEdit />
                    Editar
                  </Button>
                </header>

                <div>
                  <strong>{content?.customer.name}</strong>
                  <p>
                    Tipo de instalação:
                    <strong>{content?.customer.sector}</strong>
                  </p>
                  <p>
                    Canal de origem:<strong>{content?.customer.origin}</strong>
                  </p>
                  <p>
                    Vendedor:
                    <strong>{content?.franchiseUser?.user.name}</strong>
                  </p>
                </div>
              </div>
              <div>
                <header>
                  <h1>Endereço do cliente</h1>
                </header>

                {content?.customer.address && (
                  <div>
                    <p>
                      {content?.customer.address},{' '}
                      {content?.customer.addressNumber}
                    </p>

                    <p>
                      {content?.customer.district} - {content?.customer.city} -{' '}
                      {
                        STATES_UF[
                          content?.customer.state as keyof typeof STATES_UF
                        ]
                      }
                    </p>

                    <p>
                      CEP: <strong>{content?.customer.zipcode}</strong>
                    </p>
                  </div>
                )}

                {!content?.customer.address && (
                  <div>
                    <p>Nenhum endereço cadastrado.</p>
                  </div>
                )}
              </div>
            </Customer>

            <Aside>
              <Data>
                <header>
                  <h1>Dados do orçamento</h1>
                  <Button
                    variant="white"
                    type="button"
                    maxWidth="7.313rem"
                    onClick={() =>
                      history.push(
                        `/orcamentos/selecionar-produto/cadastro-cliente/${content?.budgetId}/selecao-de-kits/editar`
                      )
                    }
                  >
                    <FiEdit />
                    Editar
                  </Button>
                </header>

                {/* <main>
                <Kit>
                  <h1>2x Renovigi - 400W</h1>
                  <strong>R$ 13.000,00</strong>
                  <span>99,9 KWp | 380V</span>
                  <span>222 módulos</span>
                </Kit>
                <Kit>
                  <h1>2x Renovigi - 400W</h1>
                  <strong>R$ 13.000,00</strong>
                  <span>99,9 KWp | 380V</span>
                  <span>222 módulos</span>
                </Kit>
                <Kit>
                  <h1>2x Renovigi - 400W</h1>
                  <strong>R$ 13.000,00</strong>
                  <span>99,9 KWp | 380V</span>
                  <span>222 módulos</span>
                </Kit>
                <Kit>
                  <h1>2x Renovigi - 400W</h1>
                  <strong>R$ 13.000,00</strong>
                  <span>99,9 KWp | 380V</span>
                  <span>222 módulos</span>
                </Kit>
              </main> */}

                <article className="budget-details">
                  <p>
                    Valor:<strong>R$ 50.000,00</strong>
                  </p>
                  <p>
                    Fornecedor:<strong>Elgin</strong>
                  </p>
                  <p>
                    Categoria:<strong>400W</strong>
                  </p>
                  <p>
                    Data de criação:
                    <strong>
                      {' '}
                      {content?.createdAt &&
                        format(
                          new Date(content.createdAt),
                          "dd 'de' MMMM 'de' yyyy",
                          {
                            locale: ptBR,
                          }
                        )}
                    </strong>
                  </p>
                </article>

                <Messages>
                  {/* <p>
                  Última edição:
                  <strong>19 de maio de 2022</strong>
                </p> */}

                  <article className="discount">
                    <p>Desconto aprovado!</p>
                    <p>
                      Valor do desconto:
                      <strong>R$ 10.000,00</strong>
                    </p>
                    <p>
                      Valor final:
                      <strong>R$ 40.000,00</strong>
                    </p>
                  </article>
                </Messages>
              </Data>
              <Buttons>
                <Button variant="blue" type="button" maxWidth="100%">
                  <FiPlusCircle />
                  Gerar pedido
                </Button>
                <Button
                  variant="red"
                  type="button"
                  maxWidth="100%"
                  onClick={() => handleOpenModals('lost')}
                >
                  <FiMinusCircle />
                  Orçamento perdido
                </Button>
                <Button
                  variant="white"
                  type="button"
                  maxWidth="100%"
                  onClick={() => handleOpenModals('schedule')}
                >
                  <FiCalendar />
                  Agendar novo contato
                </Button>
              </Buttons>
            </Aside>
          </Wrapper>
        </Content>
      </Container>

      <InnerScheduleModal
        leadId={content?.id ?? ''}
        isOpen={modals.schedule.isOpen}
        onCancel={() => {
          handleCloseModals('schedule');
          onCancel();
        }}
      />

      <InnerLostModal
        leadId={content?.id ?? ''}
        isOpen={modals.lost.isOpen}
        onCancel={() => handleCloseModals('lost')}
        onConfirm={() => {
          handleCloseModals('lost');

          onCancel();
        }}
      />

      <LeadModal
        isOpen={leadModal.isOpen}
        lead={leadModal.data}
        onCancel={() => {
          handleLeadModal(null);
          onCancel();
        }}
      />
    </ShapeModal>
  );
};
