import { yupResolver } from '@hookform/resolvers/yup';
import { format } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import React, { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FiEdit, FiXCircle } from 'react-icons/fi';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { Breadcrumb } from '../../../../../../components/breadcrumb';
import { Button } from '../../../../../../components/button';
import { CloudIcon } from '../../../../../../components/icons';
import { CancelOrderModal } from '../../../../../../components/modal/cancel-order';
import Textarea from '../../../../../../components/textarea';
import { Order } from '../../../../../../contexts/orders/types';
import useMediaQuery from '../../../../../../hooks/mediaQuery';
import productsApi from '../../../../../../services/products';
import { downloadFile } from '../../../../../../utils/downloadFile';
import { ButtonGroup, Container, PageHeader } from '../../../../../styles';
import { ORDER_STATUS } from '../../../components/orders/innerTables/consultant';
import { SentDate } from '../../../styles';
import {
  CancelOrder,
  Content,
  Documents,
  Observation,
  ProblemDescription,
  Wrapper,
} from './styles';

type ConsultantIncompleteProps = {
  order: Order;
};

type OrderFile = {
  id: string;
  orderId: string;
  name: string;
  mimetype: string;
  path: string;
  originalName: string;
  size: string;
  type: string;
  createdAt: string;
  updatedAt: string;
};

type OrderFiles = {
  PersonType: OrderFile[];
  HouseDocumentProof: OrderFile[];
  BankDocument: OrderFile[];
  OtherDocuments: OrderFile[];
  AcceptanceTerm: OrderFile[];
};

type Form = {
  documentationMessage: string;
};

const schema = yup.object().shape({
  documentationMessage: yup.string().optional(),
});

export const ConsultantIncomplete: React.FC<ConsultantIncompleteProps> = (
  props
) => {
  const { order } = props;

  const isTablet = useMediaQuery('(max-width: 992px)');

  const { orderId, status } = useParams<{
    orderId: string;
    status: ORDER_STATUS;
  }>();

  const files: OrderFiles = useMemo(() => {
    return (
      order.files.reduce(
        (acc: OrderFiles, file: File) => {
          if (file.type in acc) {
            return {
              ...acc,
              [file.type]: [...acc[file.type as keyof OrderFiles], file],
            };
          }

          return acc;
        },
        {
          PersonType: [],
          BankDocument: [],
          HouseDocumentProof: [],
          OtherDocuments: [],
          AcceptanceTerm: [],
        }
      ) ?? {
        PersonType: [],
        BankDocument: [],
        HouseDocumentProof: [],
        OtherDocuments: [],
        AcceptanceTerm: [],
      }
    );
  }, [order]);

  const [editDocumentation, setEditDocumentation] = useState(false);

  const history = useHistory();

  const links = [
    {
      id: 1,
      title: 'Orçamentos',
      link: '/orcamentos',
      active: false,
    },
    {
      id: 2,
      title: 'Orçamentos e Pedidos',
      link: '/orcamentos/orcamentos-e-pedidos',
      active: false,
    },
    {
      id: 3,
      title: 'Pedidos enviados',
      link: `/orcamentos/orcamentos-e-pedidos/pedidos-enviados/${orderId}/${status}`,
      active: true,
    },
  ];

  const [documentationMessage, setDocumentationMessage] = useState(
    order.documentationMessage
  );

  const { handleSubmit, register } = useForm<Form>({
    resolver: yupResolver(schema),
    defaultValues: {
      documentationMessage: order.documentationMessage ?? '',
    },
    shouldUseNativeValidation: false,
  });

  const updateDocumentationMessage = (documentationMessage: string) => {
    productsApi
      .put(`/orders/${order.id}`, {
        documentationMessage,
        supplierMessage: null,
        status: order.status,
        franchiseStatus: order.franchiseStatus,
      })
      .then((response) => {
        const documentationMessage = response.data.documentationMessage;

        setDocumentationMessage(documentationMessage);

        setEditDocumentation(false);
      })
      .catch((error) => {
        const errorMessage = error.response.data.message;

        toast.error(errorMessage);
      });
  };

  const onSubmit = (data: Form) => {
    updateDocumentationMessage(data.documentationMessage);
  };

  const [cancelModal, setCancelModal] = useState(false);

  const cancelOrder = () => {
    productsApi
      .patch(`/orders/${order.id}/status`, {
        status: 'Pedido cancelado',
        franchiseStatus: 'Pedido cancelado',
      })
      .then(() => {
        toast.success('Status do pedido atualizado');

        history.replace('/orcamentos/orcamentos-e-pedidos/');
      })
      .catch((error) => {
        const errorMessage = error.response.data.message;

        toast.error(errorMessage);
      });
  };

  const handleCancelModal = () => {
    setCancelModal((state) => !state);
  };

  return (
    <Container>
      <Breadcrumb links={links} />

      <PageHeader>
        <span>{'Pedidos enviados'}</span>
      </PageHeader>

      <Content onSubmit={handleSubmit(onSubmit)}>
        <ProblemDescription>
          <div>
            <div>
              <h1>Descrição do problema</h1>

              <Button
                type="button"
                text="Editar descrição"
                icon={<FiEdit />}
                onClick={() => setEditDocumentation((state) => !state)}
              />
            </div>

            {!isTablet && (
              <CancelOrder>
                <Button
                  type="button"
                  backgroundColor="#E01919"
                  backgroundHoverColor="#e01919c1"
                  typeStyle="default"
                  text="Cancelar pedido"
                  onClick={handleCancelModal}
                  icon={<FiXCircle />}
                />
              </CancelOrder>
            )}
          </div>

          {!editDocumentation && <p>{documentationMessage}</p>}
          {editDocumentation && (
            <div>
              <Textarea
                label="Descreva o problema com a documentação"
                placeholder="Digite aqui"
                height="5.5rem"
                {...register('documentationMessage')}
              />

              <Button
                type="button"
                text="Salvar descrição"
                onClick={() => {
                  handleSubmit(onSubmit)();
                }}
              />
            </div>
          )}

          {isTablet && (
            <CancelOrder>
              <Button
                type="button"
                backgroundColor="#E01919"
                backgroundHoverColor="#e01919c1"
                typeStyle="default"
                text="Cancelar pedido"
                onClick={handleCancelModal}
                icon={<FiXCircle />}
              />
            </CancelOrder>
          )}
        </ProblemDescription>

        <Documents>
          <div>
            <h1>Documentos enviados pelo vendedor</h1>

            <Wrapper>
              <ul>
                <label>Pessoa física ou jurídica</label>

                {files?.PersonType.map((file) => {
                  return (
                    <li key={file.name}>
                      <Button
                        type="button"
                        backgroundColor="#FFFFFF"
                        typeStyle="default"
                        text="Fazer download"
                        onClick={() => downloadFile(file.path)}
                        icon={<CloudIcon />}
                      />
                      <SentDate>
                        Enviado em:{' '}
                        {format(new Date(file.updatedAt), 'dd/MM/yyyy', {
                          locale: ptBR,
                        })}
                      </SentDate>
                    </li>
                  );
                })}
              </ul>

              <ul>
                <label>Comprovante de residência</label>

                {files?.HouseDocumentProof.map((file) => {
                  return (
                    <li key={file.name}>
                      <Button
                        type="button"
                        backgroundColor="#FFFFFF"
                        typeStyle="default"
                        text="Fazer download"
                        onClick={() => downloadFile(file.path)}
                        icon={<CloudIcon />}
                      />
                      <SentDate>
                        Enviado em:{' '}
                        {format(new Date(file.updatedAt), 'dd/MM/yyyy', {
                          locale: ptBR,
                        })}
                      </SentDate>
                    </li>
                  );
                })}
              </ul>

              <ul>
                <label>Documento do Banco</label>

                {files?.BankDocument.map((file) => {
                  return (
                    <li key={file.name}>
                      <Button
                        type="button"
                        backgroundColor="#FFFFFF"
                        typeStyle="default"
                        text="Fazer download"
                        onClick={() => downloadFile(file.path)}
                        icon={<CloudIcon />}
                      />
                      <SentDate>
                        Enviado em:{' '}
                        {format(new Date(file.updatedAt), 'dd/MM/yyyy', {
                          locale: ptBR,
                        })}
                      </SentDate>
                    </li>
                  );
                })}
              </ul>

              <ul>
                <label>Termo de aceite assinado</label>

                {files?.AcceptanceTerm.map((file) => {
                  return (
                    <li key={file.name}>
                      <Button
                        type="button"
                        backgroundColor="#FFFFFF"
                        typeStyle="default"
                        text="Fazer download"
                        onClick={() => downloadFile(file.path)}
                        icon={<CloudIcon />}
                      />
                      <SentDate>
                        Enviado em:{' '}
                        {format(new Date(file.updatedAt), 'dd/MM/yyyy', {
                          locale: ptBR,
                        })}
                      </SentDate>
                    </li>
                  );
                })}
              </ul>

              <ul>
                <label>Outros documentos</label>

                {files?.OtherDocuments.map((file) => {
                  return (
                    <li key={file.name}>
                      <Button
                        type="button"
                        backgroundColor="#FFFFFF"
                        typeStyle="default"
                        text="Fazer download"
                        onClick={() => downloadFile(file.path)}
                        icon={<CloudIcon />}
                      />
                      <SentDate>
                        Enviado em:{' '}
                        {format(new Date(file.updatedAt), 'dd/MM/yyyy', {
                          locale: ptBR,
                        })}
                      </SentDate>
                    </li>
                  );
                })}
              </ul>
            </Wrapper>
          </div>
        </Documents>

        <Observation>
          <h1>Observações do pedido</h1>

          <p>{order.observations}</p>
        </Observation>

        <ButtonGroup>
          <Button
            type="button"
            text="Voltar"
            typeStyle="default"
            backgroundHoverColor="#C9CBCF"
            onClick={() =>
              history.replace('/orcamentos/orcamentos-e-pedidos', 'Pedidos')
            }
          />
        </ButtonGroup>
      </Content>

      <CancelOrderModal
        isOpen={cancelModal}
        onConfirm={cancelOrder}
        onCancel={handleCancelModal}
      />
    </Container>
  );
};
