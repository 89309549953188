export type PaginatedResponse<T> = {
  content: T[];
  pagination: {
    currentPage: number;
    lastPage: number;
    limit: number;
    total: number;
  };
  loading: boolean;
};

export const EMPTY_PAGE = {
  content: [],
  pagination: {
    currentPage: 1,
    lastPage: 1,
    limit: 10,
    total: 0,
  },
  loading: false,
};
