import styled from 'styled-components';

export const Container = styled.div``;

export const Header = styled.header`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 2rem;

  margin-bottom: 2rem;

  & > h1 {
    font-weight: 600;
    font-size: 1.25rem;

    color: #27282b;
  }

  & > div {
    display: flex;
    align-items: center;
    gap: 1.5rem;

    & > p {
      font-weight: 400;
      font-size: 1.125rem;

      color: #27282b;
    }
  }
`;
