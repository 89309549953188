import styled from 'styled-components';

export const Wrapper = styled.div`
  .item-list {
    padding-bottom: 5rem;

    background-color: #ebebeb;

    border-radius: 4px 4px 0px 0px;
    border-width: 1px 1px 0px 1px;
    border-style: solid;
    border-color: #c9cbcf;
  }
`;

