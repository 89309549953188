import styled from 'styled-components';

export const Content = styled.form`
  height: 100%;

  display: flex;
  flex-direction: column;

  padding: 1.5rem 0;
`;

export const ProblemDescription = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.125rem;

  margin-bottom: 2.5rem;

  & > div:first-child {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: flex-end;
    gap: 4.25rem;

    & > div {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 1.125rem;

      & > h1 {
        font-family: 'DunbarTextRegular';
        font-weight: 400;
        font-size: 1.25rem;

        letter-spacing: 0.02em;

        white-space: nowrap;

        color: #27282b;
      }

      & > button > svg {
        font-size: 1.25rem;
        color: #ffffff;

        & > path {
          stroke: unset;
        }
      }

      @media (max-width: 768px) {
        flex-direction: column;
        align-items: flex-start;

        & > button {
          width: 100%;
        }
      }
    }

    & > div:first-child {
      flex: 0 1 18rem;
    }
  }
  & > div:last-child,
  div:nth-child(2) {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;

    @media (max-width: 1453px) {
      align-items: flex-start;
    }

    @media (max-width: 768px) {
      & > button {
        width: 100%;
      }
    }
  }

  & > p {
    font-family: 'DunbarTextRegular';

    font-weight: 400;
    font-size: 1rem;

    letter-spacing: 0.02em;

    color: #000000;
  }
`;

export const Documents = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4.25rem;

  margin-bottom: 2.5rem;

  & > div {
    display: flex;
    flex-direction: column;
    gap: 1.125rem;

    & > h1 {
      font-family: 'DunbarTextRegular', sans-serif;
      font-weight: 400;
      font-size: 1.25rem;

      letter-spacing: 0.02em;

      color: #27282b;
    }
  }
`;

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(16.125rem, 1fr));
  gap: 1.5rem;

  & button {
    border: 1px solid #c9cbcf;
  }

  & > ul {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    & > label {
      font-family: 'Montserrat', sans-serif;
      font-weight: 500;
      font-size: 1rem;

      letter-spacing: 0.01em;

      color: #27282b;
    }
  }

  @media (max-width: 992px) {
    & button {
      width: 100%;
    }
  }
`;

export const Observation = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.875rem;

  height: 100%;

  & > h1 {
    font-family: 'DunbarTextRegular';
    font-weight: 400;
    font-size: 1.25rem;

    letter-spacing: 0.02em;

    white-space: nowrap;

    color: #27282b;
  }

  & > p {
    font-family: 'DunbarTextRegular';

    font-weight: 400;
    font-size: 1rem;

    letter-spacing: 0.02em;

    color: #000000;
  }
`;

export const CancelOrder = styled.div`
  & > button {
    color: #ffffff;

    & > svg {
      font-size: 1.25rem;
    }
  }

  @media (max-width: 768px) {
    width: 100%;

    & > button {
      width: 100%;
    }
  }
`;
