import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Controller, useForm } from 'react-hook-form';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { Breadcrumb, Button } from '../../../components';
import { Container, Wrapper, PageHeader } from '../styles';
import { FormContainer, InputGroup, GroupedInputs } from '../../styles';
import { ButtonContainer } from './style';

import Select from '../../../components/form-elements/select';
import { UsersType } from '../../franchises/types';
import { UserRoles } from '../../../types/users';
import franchisesApi from '../../../services/franchises';
import projectsApi from '../../../services/projects';
import { ProjectStatus } from '../types';
import { useAuth } from '../../../contexts/auth';
import { ProjectType } from '../../../types/project';

type CallCenterResponsiblePageProps = {
  id: string;
};

type ResponsibleType = {
  engineerUserId: string;
};

const CallCenterResponsiblePage: React.FC = () => {
  const history = useHistory();
  const formRef = useRef<FormHandles>(null);
  const { id } = useParams<CallCenterResponsiblePageProps>();
  const [users, setUsers] = useState<UsersType[]>([]);
  const [project, setProject] = useState<ProjectType>();
  const { user } = useAuth();

  useEffect(() => {
    franchisesApi.get<UsersType[]>('/api/franchises/users/list').then((res) => {
      setUsers(res.data.filter((user) => user.role === UserRoles.ENGENHEIRO));
    });
    projectsApi.get<ProjectType>(`/api/projects/${id}`).then((res) => {
      setProject(res.data);
      setValue('engineerUserId', res.data.engineerUserId);
    });
  }, []);

  const links = [
    {
      id: 1,
      title: 'Projetos',
      link: '/projetos',
      active: false,
    },
    {
      id: 2,
      title: 'Lista de Projetos',
      link: '/projetos',
      active: true,
    },
  ];

  const schema = yup.object().shape({
    engineerUserId: yup.string().required('Responsável é um campo obrigatório'),
  });

  const {
    handleSubmit,
    formState: { errors },
    setValue,
    control,
  } = useForm<ResponsibleType>({ resolver: yupResolver(schema) });

  const onSubmit = async (data: ResponsibleType) => {
    try {
      await projectsApi.post(`/api/projects/${id}/status-log`, {
        userId: user.id,
        observation: 'Engenheiro responsável selecionado',
        status: ProjectStatus.AGUARDANDO_PAGAMENTO,
      });

      await projectsApi.patch(`/api/projects/${id}/status`, {
        status: ProjectStatus.AGUARDANDO_PAGAMENTO,
      });

      await projectsApi.patch(`/api/projects/${id}/responsible`, {
        userId: data.engineerUserId,
      });

      toast.success('Projeto atualizado com sucesso');
      history.push('/projetos/central-atendimento');
    } catch (error: any) {
      toast.error(error.response.data.message);
    }
  };

  return (
    <Container>
      <Breadcrumb links={links} />
      <Wrapper>
        <PageHeader>
          <span>Selecionar Responsável</span>
        </PageHeader>
        <FormContainer>
          <Form
            ref={formRef}
            onSubmit={handleSubmit(onSubmit)}
            initialData={project}
          >
            <GroupedInputs>
              <InputGroup size="mid">
                <span>Responsável</span>
                <Controller
                  name="engineerUserId"
                  control={control}
                  render={({ field: { onChange, value } }) => {
                    return (
                      <Select
                        name="engineerUserId"
                        onChange={onChange}
                        value={value}
                        control={control}
                        placeholder="Selecione uma opção"
                        options={users.map((user) => ({
                          label: user.name,
                          value: user.id,
                        }))}
                        error={errors.engineerUserId?.message}
                      />
                    );
                  }}
                />
              </InputGroup>
            </GroupedInputs>

            <ButtonContainer>
              <div>
                <Button
                  type="submit"
                  text="Salvar"
                  backgroundColor="#005AF9"
                  backgroundHoverColor="#2772f8"
                />
              </div>
            </ButtonContainer>
          </Form>
        </FormContainer>
      </Wrapper>
    </Container>
  );
};

export default CallCenterResponsiblePage;
