import styled from 'styled-components';

export const Content = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  padding: 1.5rem 0;

  height: 100%;

  & > h1 {
    font-weight: 500;
    font-size: 1.25rem;

    color: #27282b;
  }
`;

export const DocumentsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 1.5rem;

  height: 100%;

  @media (max-width: 520px) {
    & textarea {
      height: 12.5rem;
    }
  }
`;

export const Documents = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(16.125rem, 16.125rem));
  gap: 1.5rem;

  flex: 1;

  @media (max-width: 992px) {
    grid-template-columns: 1fr;

    & > div {
      width: 100%;

      & > div {
        width: 100% !important;
      }
    }
  }
`;
