import React from 'react';

import {
  ChartContainer,
  Container,
  Percentage,
  Title,
  Value,
  Values,
} from './styles';
import { Description, Square } from '../styles';

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { ChartOptions } from 'chart.js/dist/types/index';

import { numberFormat } from '../../../../../../../utils/numberFormat';

ChartJS.register(ArcElement, Tooltip, Legend);

const chartOptions: ChartOptions<'doughnut'> = {
  circumference: 180,
  rotation: -90,
  responsive: true,
  aspectRatio: 16 / 9,
  cutout: 56,
  plugins: {
    datalabels: {
      display: false,
    },
    legend: {
      display: false,
    },
    tooltip: {
      enabled: false,
    },
  },
};

type Props = {
  active: number;
  productive: number;
};

export const UnitInfos: React.FC<Props> = (props) => {
  const { active, productive } = props;

  const chartData = {
    labels: ['Produtivas', 'Ativas'],
    datasets: [
      {
        label: 'Quantidade',
        data: [productive, productive - active],
        backgroundColor: ['#F95300', '#9FA1A8'],
        borderWidth: 0,
      },
    ],
  };

  return (
    <Container>
      <div>
        <Title>Unidades produtivas</Title>

        <Values>
          <Value>
            <p>Ativas</p>
            <span>{active.toLocaleString('pt-BR')}</span>
          </Value>
          <Value>
            <p>Produtivas</p>
            <span>{productive.toLocaleString('pt-BR')}</span>
          </Value>
        </Values>
      </div>

      <ChartContainer>
        <Doughnut options={chartOptions} data={chartData} />

        <Percentage>{numberFormat((productive * 100) / active)}%</Percentage>

        <Description>
          <p>
            <Square color="#F95300" />
            Produtivas
          </p>
          <p>
            <Square color="#9FA1A8" />
            Ativas
          </p>
        </Description>
      </ChartContainer>
    </Container>
  );
};
