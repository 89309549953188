import styled from 'styled-components';

export const Content = styled.main`
  display: flex;
  flex-direction: column;

  padding: 1.5rem 0;

  height: 100%;
`;

export const PromotionalKitsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  padding: 2rem 0 0;

  background-color: #ffffff;

  box-shadow: inset 0px 0px 5px rgba(0, 0, 0, 0.15);

  margin: 0 -2rem 2.5rem;

  & > h1 {
    font-weight: 600;
    font-size: 1.25rem;

    color: #00299b;

    margin-left: 3rem;
  }

  @media (max-width: 520px) {
    margin: 0 -5% 2.5rem;

    & > h1 {
      margin-left: 5%;
    }
  }
`;

export const PromotionalKitWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 2rem;

  padding: 0 0 2rem;

  overflow: auto;

  width: 100%;

  & > div:first-child {
    margin-left: 3rem;
  }
  & > div:last-child {
    margin-right: 3rem;
  }

  &::-webkit-scrollbar {
    width: 8rem;
    height: 0.5rem;

    border-radius: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #ffffff;

    border-radius: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background: #00299b;

    border-radius: 8px;
  }

  @media (max-width: 520px) {
    max-width: unset;

    gap: 1rem;

    & > div:first-child {
      margin-left: 5%;
    }
    & > div:last-child {
      margin-right: 5%;
    }
  }
`;

export const PromotionalKit = styled.div`
  display: flex;
  gap: 2rem;

  user-select: none;

  flex: 0 0 18rem;

  height: 8.25rem;

  & > div {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;

    & > span {
      white-space: nowrap;
    }

    & > span.errorMessage {
      color: #e01919;
      font-size: 0.75rem;
    }
  }
`;

export const PromotionalKitName = styled.div`
  display: flex;
  align-items: center;
  gap: 0.625rem;

  & > h1 {
    font-weight: 700;
    font-size: 1.5rem;

    white-space: nowrap;

    color: #00299b;
  }

  & > button {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 2rem;
    height: 2rem;

    font-size: 1.25rem;

    color: #00299b;

    border-radius: 4px;

    border: 1px solid #00299b;

    &:focus {
      border: 1px solid #00299b;
    }
  }
`;

export const PromotionalKitPrice = styled.span`
  font-weight: 900;
  font-size: 1.5rem;

  color: #00299b;
`;

export const CalculatedKitsContainer = styled.div`
  height: 100%;
`;

export const CalculatedKitsHeader = styled.header`
  display: flex;
  flex-direction: column;
  gap: 2rem;

  & > h1 {
    font-weight: 600;
    font-size: 1.25rem;

    color: #27282b;
  }

  & > div {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    gap: 1.5rem;

    & > p {
      font-weight: 400;
      font-size: 1.125rem;

      flex: 1 1 0;

      color: #27282b;
    }
  }
`;

export const CalculatedKitsTable = styled.div`
  margin: 2.5rem 0 4rem;
`;

export const CalculatedKitsTableHeader = styled.header`
  display: flex;
  justify-content: flex-end;
  gap: 1rem;

  background-color: #fafafa;

  @media (max-width: 992px) {
    flex-direction: column;

    & > div:has(input) {
      max-width: unset;
      order: 2;
    }

    & > div:has(button) {
      order: 1;
    }

    & > div:has(button) > button {
      width: 100%;
    }
  }
`;

export const Actions = styled.div`
  display: flex;
`;

export const NotFoundMessage = styled.div`
  display: flex;
  justify-content: center;

  margin: 0 auto;

  padding: 2.5rem 0;

  font-weight: 400;
  font-size: 1.125rem;

  color: #27282b;
`;
