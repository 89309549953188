import React, { InputHTMLAttributes, useEffect, useRef } from 'react';
import { useField } from '@unform/core';
import { InputTextarea, Container, Error } from './styles';

interface InputPros extends InputHTMLAttributes<HTMLTextAreaElement> {
  name: string;
  label?: string;
  error?: string;
}

const Textarea: React.FC<InputPros> = ({
  name,
  placeholder,
  error,
  label,
  ...rest
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { fieldName, defaultValue, registerField } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  return (
    <Container>
      {label && <label htmlFor={name}>{label}</label>}
      <InputTextarea
        type="text"
        id={name}
        name={name}
        defaultValue={defaultValue}
        placeholder={placeholder}
        error={error ? true : false}
        {...rest}
      />
      {error && <Error>{error}</Error>}
    </Container>
  );
};

export default Textarea;
