import styled, { css } from 'styled-components';

export const Content = styled.main`
  display: flex;
  flex-direction: column;
  gap: 2.5rem;

  padding: 1.5rem 0;
`;

export const TabsContainer = styled.div`
  margin: 0 -2rem;

  overflow: auto;

  &::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: 520px) {
    margin: 0 -5%;
  }
`;

export const Tabs = styled.div`
  display: flex;
  align-items: center;
`;

type TabProps = {
  isActive: boolean;
};

export const Tab = styled.button<TabProps>`
  display: flex;
  justify-content: center;
  align-items: center;

  min-width: 12.5rem;
  height: 4rem;

  padding: 0.75rem 1.25rem;

  flex: 1;

  font-family: 'DunbarTextRegular';
  font-weight: 600;
  font-size: 1.5rem;

  white-space: nowrap;

  color: #27282b;

  background: #ffffff;
  box-shadow: inset 0px -1px 0px #cccccc;

  ${({ isActive }) =>
    isActive &&
    css`
      color: #00299b;

      box-shadow: inset 0px -1px 0px #00299b;
    `}
`;

export const DownloadDocumentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0;

  & > button {
    border: 1px solid #c9cbcf;

    @media (max-width: 992px) {
      width: 100%;
    }
  }
`;

export const SentDate = styled.p`
  font-family: 'Montserrat', sans-serif !important;
  font-weight: 500 !important;
  font-size: 0.875rem !important;
  line-height: 1rem !important;
  color: #61646b !important;

  margin-top: 0.25rem;
`;
