import { format } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Breadcrumb } from '../../../../../../components/breadcrumb';
import { Button } from '../../../../../../components/button';
import { CloudIcon } from '../../../../../../components/icons';
import { Order } from '../../../../../../contexts/orders/types';
import { downloadFile } from '../../../../../../utils/downloadFile';
import { ButtonGroup, Container, PageHeader } from '../../../../../styles';
import { FRANCHISE_ORDER_STATUS } from '../../../components/orders/innerTables/franchise';
import { DownloadDocumentContainer, SentDate } from '../../../styles';
import { Content, NFE } from './styles';

type FranchiseNfeEmittedProps = {
  order: Order;
};

export const FranchiseNfeEmitted: React.FC<FranchiseNfeEmittedProps> = (
  props
) => {
  const { order } = props;

  const { orderId, status } = useParams<{
    orderId: string;
    status: FRANCHISE_ORDER_STATUS;
  }>();

  const history = useHistory();

  const links = [
    {
      id: 1,
      title: 'Orçamentos',
      link: '/orcamentos',
      active: false,
    },
    {
      id: 2,
      title: 'Orçamentos e Pedidos',
      link: '/orcamentos/orcamentos-e-pedidos',
      active: false,
    },
    {
      id: 3,
      title: 'Pedidos enviados',
      link: `/orcamentos/orcamentos-e-pedidos/pedidos-enviados/${orderId}/${status}`,
      active: true,
    },
  ];

  const nfe = order.files.filter((file) => file.type === 'NFE');

  return (
    <Container>
      <Breadcrumb links={links} />

      <PageHeader>
        <span>{'Pedidos enviados'}</span>
      </PageHeader>

      <Content>
        <NFE>
          <h1>NFE emitida</h1>

          <p>Faça o download da NFE e envie para o seu cliente.</p>

          <div>
            NFE
            {nfe.map((file) => {
              return (
                <DownloadDocumentContainer key={file.name}>
                  <Button
                    key={file.id}
                    type="button"
                    backgroundColor="#FFFFFF"
                    typeStyle="default"
                    text="Fazer download"
                    onClick={() => downloadFile(file.path)}
                    icon={<CloudIcon />}
                  />
                  <SentDate>
                    Enviado em:{' '}
                    {format(new Date(file.updatedAt as string), 'dd/MM/yyyy', {
                      locale: ptBR,
                    })}
                  </SentDate>
                </DownloadDocumentContainer>
              );
            })}
          </div>
        </NFE>

        <ButtonGroup>
          <Button
            type="button"
            text="Voltar"
            typeStyle="default"
            backgroundHoverColor="#C9CBCF"
            onClick={() =>
              history.replace('/orcamentos/orcamentos-e-pedidos', 'Pedidos')
            }
          />
        </ButtonGroup>
      </Content>
    </Container>
  );
};

