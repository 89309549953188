import styled from 'styled-components';

export const Content = styled.main`
  display: flex;
  flex-direction: column;

  padding: 1.5rem 0;

  height: 100%;
`;

export const CustomKitsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3.5rem;

  padding: 1.5rem 3rem;
  margin: 0 -2rem;

  min-height: 15.25rem;

  background-color: #ffffff;

  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.15) inset;

  & > header {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 2rem;

    & > h1 {
      color: #00299b;
      font-family: 'Montserrat', sans-serif;
      font-size: 1.25rem;
      font-style: normal;
      font-weight: 600;
      letter-spacing: 0.0125rem;
    }

    & > span {
      color: #00299b;

      font-family: 'Montserrat', sans-serif;

      font-size: 0.875rem;
      font-style: normal;
      font-weight: 400;
      letter-spacing: 0.0175rem;

      & > strong {
        color: #00299b;
        text-align: center;

        font-family: 'DunbarTextRegular', sans-serif;
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        letter-spacing: 0.02rem;
      }
    }
  }
`;

export const CustomKitsWrapper = styled.div`
  display: flex;
  align-items: center;

  height: 100%;

  overflow-x: auto;

  margin: 0 -3rem;

  padding: 0 3rem;

  background-color: #ffffff;

  &::-webkit-scrollbar {
    width: 8rem;
    height: 0.5rem;

    border-radius: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #ffffff;

    border-radius: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background: #00299b;

    border-radius: 8px;
  }

  & > table {
    width: 100%;
    border-spacing: 0;
    border-collapse: collapse;

    & > thead {
      & > tr {
        & > th {
          text-align: left;

          white-space: nowrap;

          padding: 1rem 1.5rem;

          height: 3.5rem;

          border-bottom: #e0e0e0 solid 1px;

          color: #00299b;

          font-family: 'Montserrat', sans-serif;
          font-size: 0.875rem;
          font-style: normal;
          font-weight: 600;
        }
      }
    }

    & > tbody {
      & > tr {
        & > td {
          padding: 1rem 1.5rem;

          height: 6.25rem;

          white-space: nowrap;

          color: #00299b;

          font-family: 'Montserrat', sans-serif;
          font-size: 0.875rem;
          font-style: normal;
          font-weight: 400;

          & > strong {
            display: flex;
            flex-direction: column;

            font-weight: 600;
            font-size: 0.875rem;

            color: #00299b;

            white-space: nowrap;

            & > span {
              font-weight: 500;
              font-size: 0.875rem;

              color: #4e6bb9;
            }
          }

          background-color: #fff;
        }
      }
    }
  }
`;

export const EmptyMessage = styled.span`
  display: flex;
  justify-content: center;

  width: 100%;

  color: #00299b;
  font-family: 'Montserrat', sans-serif;

  font-size: 1.25rem;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0.0125rem;
`;

export const Menu = styled.nav`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 1.5rem;

  margin-top: 1.5rem;

  padding: 0 1rem;
`;

export const Actions = styled.div`
  display: flex;

  align-items: center;
  gap: 0.75rem;

  & > button[data-tip='Visualizar'] {
    border: 1px solid #00299b;

    box-shadow: 0;

    & > svg {
      color: #00299b;
    }
  }
`;

export const TableFooter = styled.tfoot``;

export const MenuItem = styled.button.attrs((props) => ({
  ...props,
  type: 'button',
}))`
  display: flex;
  gap: 1.5rem;

  user-select: none;

  width: 33.75rem;
  min-height: 7rem;

  padding: 1.5rem;

  border-radius: 0.5rem;
  border: 0.5px solid #9fa1a8;
  background: #fff;

  & > svg {
    width: 4rem;
    height: 4rem;

    flex-shrink: 0;
  }

  & > div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;

    & > h1 {
      color: #27282b;
      font-family: 'Montserrat', sans-serif;
      font-size: 1.25rem;
      font-style: normal;
      font-weight: 600;
    }

    & > p {
      color: #27282b;
      font-family: 'Montserrat', sans-serif;
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      text-align: start;
    }
  }
`;
