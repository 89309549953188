import React, { CSSProperties, Fragment, ReactElement, ReactNode } from 'react';
import {
  CgChevronLeft,
  CgChevronRight,
  CgPushChevronLeft,
  CgPushChevronRight,
} from 'react-icons/cg';
import { FiArrowDown } from 'react-icons/fi';
import { useScrollContainer } from 'react-indiana-drag-scroll';
import 'react-indiana-drag-scroll/dist/style.css';
import Skeleton from 'react-loading-skeleton';
import InnerLimitDropdown from './innerLimitDropdown';
import {
  Body,
  BodyRow,
  Cell,
  Container,
  Head,
  HeadRow,
  NotFoundMessage,
  OrderBy,
  Pagination,
  TableElement,
  TableHeader,
  TableWrapper,
  Title,
} from './styles';

export type ColumnStructure = {
  id: string;
  label: string;
  checkbox?: ReactElement;
  hasEllipsis?: boolean;
  hasMultipleLines?: boolean;
  isCentered?: boolean;
  isEnded?: boolean;
  type?: 'string' | 'actionCell';
  size?: 'xl' | 'l' | 'm' | 's' | 'xs';
  orderBy?: string;
  onClick?: () => void;
};

export type Pagination = {
  currentPage: number;
  limit: number;
  total: number;
  lastPage: number;
};

export type TableData<T = { [key in string]: string | ReactElement }> = T & {
  error?: string;
  id: string;
};

type Limit = {
  value: number;
  label: string;
};

type TableProps = {
  styles?: CSSProperties;
  columns: ColumnStructure[];
  items: TableData[];
  isLoading: boolean;
  header?: ReactNode;
  pagination?: Pagination;
  placeholder?: string;
  limits?: Limit[];
  onLimitChange?: (value: number) => void;
  onPageChange?: (page: number) => void;
  hasPagination?: boolean;
};

const limitOptions = [
  { value: 10, label: '10' },
  { value: 25, label: '25' },
  { value: 50, label: '50' },
];

const Table: React.FC<TableProps> = (props) => {
  const {
    styles,
    columns,
    items,
    header,
    pagination,
    placeholder,
    isLoading,
    limits,
    hasPagination = true,
    onLimitChange,
    onPageChange,
  } = props;

  const tableWrapperRef = useScrollContainer<HTMLDivElement>({
    mouseScroll: {
      overscroll: true,
      ignoreElements: '.undraggable',
    },
  });

  const isEmpty = !items.length;

  const hasPrevious = pagination ? pagination.currentPage > 1 : false;

  const hasNext = pagination
    ? pagination.currentPage < pagination.lastPage
    : false;

  const handleFirstPage = () => {
    onPageChange?.(1);
  };

  const handlePreviousPage = () => {
    if (!pagination) {
      return;
    }

    const goPage = Number(pagination.currentPage) - 1;
    if (goPage > 0) {
      onPageChange?.(goPage);
    }
  };

  const handleNextPage = () => {
    if (!pagination) {
      return;
    }

    const goPage = Number(pagination.currentPage) + 1;
    if (goPage <= pagination.lastPage) {
      onPageChange?.(goPage);
    }
  };

  const handleLastPage = () => {
    if (!pagination) {
      return;
    }

    onPageChange?.(pagination.lastPage);
  };

  const handleLimitChange = (newLimit: number) => {
    onLimitChange?.(newLimit);
  };

  return (
    <Container style={styles}>
      {header && <TableHeader>{header}</TableHeader>}

      <TableWrapper ref={tableWrapperRef.ref}>
        <TableElement>
          <Head>
            <HeadRow>
              {columns.map((column) => {
                return (
                  <Title
                    key={column.id}
                    actionCell={column.type === 'actionCell'}
                    hasCheckbox={column.id === 'checkbox'}
                    isCentered={column.isCentered}
                  >
                    <div>
                      <span className="undraggable">{column.label}</span>
                      {column.type === 'actionCell' && column.checkbox}
                      {column.orderBy && (
                        <OrderBy
                          type="button"
                          onClick={() => column.onClick?.()}
                          orderBy={column.orderBy}
                        >
                          <FiArrowDown />
                        </OrderBy>
                      )}
                    </div>
                  </Title>
                );
              })}
            </HeadRow>
          </Head>

          <Body isLoading={isLoading}>
            {isLoading &&
              columns.map((tr) => {
                return (
                  <tr key={tr.id}>
                    {Array(columns.length)
                      .fill(<Skeleton />)
                      .map((element, index) => (
                        <td key={element + index}>{element}</td>
                      ))}
                  </tr>
                );
              })}

            {!isLoading &&
              items.map((item) => {
                return (
                  <BodyRow key={item.id}>
                    {columns.map((column, index) => {
                      return (
                        <Cell
                          key={item.id + column.id}
                          hasEllipsis={column.hasEllipsis}
                          hasCheckbox={column.id === 'checkbox'}
                          hasMultipleLines={column.hasMultipleLines}
                          isCentered={column.isCentered}
                          isEnded={column.isEnded}
                        >
                          <div>
                            <div className="undraggable">
                              {item[column.id] || <p>-</p>}
                            </div>
                            {(item?.error &&
                              column.id !== 'checkbox' &&
                              index === 0) ||
                              (index === 1 && (
                                <p className="error-message">{item.error}</p>
                              ))}
                          </div>
                        </Cell>
                      );
                    })}
                  </BodyRow>
                );
              })}
          </Body>
        </TableElement>

        {!isLoading && isEmpty && placeholder && (
          <NotFoundMessage>{placeholder}</NotFoundMessage>
        )}
      </TableWrapper>

      <Pagination hasPrevious={hasPrevious} hasNext={hasNext}>
        {hasPagination && (
          <Fragment>
            <span>
              Itens por página
              <InnerLimitDropdown
                defaultValue={pagination?.limit}
                options={limits || limitOptions}
                onSelectLimit={(option) => {
                  handleLimitChange && handleLimitChange(option.value);
                }}
              />
            </span>
            <span>
              {pagination?.currentPage} de {pagination?.lastPage}
            </span>
            <CgPushChevronLeft
              className="prev"
              onClick={() => {
                hasPrevious && handleFirstPage && handleFirstPage();
              }}
            />
            <CgChevronLeft
              className="prev"
              onClick={() => {
                hasPrevious && handlePreviousPage();
              }}
            />
            <CgChevronRight
              className="next"
              onClick={() => {
                hasNext && handleNextPage();
              }}
            />
            <CgPushChevronRight
              className="next"
              onClick={() => {
                hasNext && handleLastPage();
              }}
            />
          </Fragment>
        )}
      </Pagination>
    </Container>
  );
};

export default Table;
