import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.25rem;

  width: 100%;

  overflow-x: auto;
`;

type TagProps = {
  bgColor: string;
};

export const Tag = styled.button.attrs((props) => ({
  ...props,
  type: 'button',
}))<TagProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.625rem;

  flex: 0 0 7.625rem;
  height: 2.5rem;

  padding: 0.5rem 0.75rem;

  border-radius: 20px;
  background: ${(props) => props.bgColor};

  & > span {
    white-space: nowrap;
  }

  & > svg,
  span {
    color: #27282b;
    font-size: 1rem;
    font-family: 'DunbarTextRegular', sans-serif;
  }
`;

