import styled from 'styled-components';

export const Header = styled.header`
  margin-top: 2rem;

  & > div {
    width: 100%;

    @media (max-width: 992px) {
      & > div {
        width: 100%;

        & > div {
          width: 100%;

          & > div > span {
            max-width: unset;

            padding: 0 1.5rem 0 0;
          }
        }
      }
    }
  }
`;
