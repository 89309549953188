import React, { CSSProperties, ForwardedRef, ReactNode, useState } from 'react';

import { Container } from './styles';

import InputMask, { ReactInputMask, Props } from 'react-input-mask';

interface MaskInputProps extends Props {
  label?: string;
  iconLeft?: ReactNode;
  iconRight?: ReactNode;
  width?: string;
  height?: string;
  error?: string;
  maxLength?: number;
  placeholder?: string;
  style?: CSSProperties;
  disabled?: boolean;
  required?: boolean;
}

const MaskInput = React.forwardRef(
  (props: MaskInputProps, myRef: ForwardedRef<ReactInputMask>) => {
    const {
      label,
      iconLeft,
      iconRight,
      height = '3rem',
      error,
      style,
      mask,
      disabled = false,
      required = false,
      ...rest
    } = props;

    const [active, setActive] = useState(false);

    return (
      <Container
        height={height}
        active={active}
        error={!!error}
        disabled={disabled}
        style={style}
        onFocus={() => {
          setActive(true);
        }}
        onBlur={() => {
          setActive(false);
        }}
        htmlFor={rest.name}
      >
        {label && (required ? label + '*' : label)}
        <div>
          {iconLeft ?? iconLeft}
          <InputMask
            mask={mask || ''}
            ref={myRef}
            id={rest.name}
            maskPlaceholder={null}
            disabled={disabled}
            {...rest}
          />
          {iconRight ?? iconRight}
        </div>
        {error && <span>{error}</span>}
      </Container>
    );
  }
);

MaskInput.displayName = 'MaskInput';

export default MaskInput;
