import React from 'react';

import {
  Breadcrumb,
  CoinsIcon,
  DocListIcon,
  ImportIcon,
} from '../../components';
import { useAuth } from '../../contexts/auth';
import { UserRoles } from '../../types/users';
import {
  Container,
  LinkBox,
  LinkBoxGroup,
  PageHeader,
  Wrapper,
} from './../styles';

const ProductsMenuPage: React.FC = () => {
  const { user } = useAuth();

  const links = [
    {
      id: 1,
      title: 'Produtos',
      link: '/produtos',
      active: true,
    },
  ];

  return (
    <Container>
      <Breadcrumb links={links} />
      <Wrapper>
        <PageHeader>
          <span>{'Produtos'}</span>
        </PageHeader>

        <LinkBoxGroup>
          <LinkBox href="/produtos/lista">
            <DocListIcon />
            <span>Lista de produtos</span>
            <p>Veja a lista de todos os produtos e outras informações sobre </p>
          </LinkBox>

          {![
            UserRoles.CONSULTOR_SUCESSO,
            UserRoles.GERENCIA,
            UserRoles.SUPERVISAO,
          ].includes(user.role) && (
            <LinkBox href="/produtos/importacao">
              <ImportIcon />
              <span>Importação</span>
              <p>Importe um arquivo Excel com sua lista de produtos</p>
            </LinkBox>
          )}

          {![UserRoles.CONSULTOR_SUCESSO].includes(user.role) && (
            <LinkBox href="/produtos/descontos-rebates">
              <CoinsIcon />
              <span>Descontos e Rebates</span>
              <p>
                Altere os descontos e rebates conforme a categoria dos produtos
              </p>
            </LinkBox>
          )}
        </LinkBoxGroup>
      </Wrapper>
    </Container>
  );
};

export default ProductsMenuPage;
