import styled from 'styled-components';

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  & > h1 {
    font-family: 'DunbarTallBold';
    font-weight: 500;
    font-size: 1.5rem;

    letter-spacing: 0.03em;

    color: #000000;
  }
`;

export const SelectWrapper = styled.div`
  flex: 1;

  & > div {
    max-width: 22rem;

    @media (max-width: 992px) {
      max-width: unset;
    }
  }
`;

export const RankingWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;

  width: 100%;
`;

const BaseContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  flex: 1 1 33.75rem;

  width: 100%;

  background: #ffffff;

  border: 1px solid #c9cbcf;
  border-radius: 8px;

  padding: 1.5rem 1rem;

  & > h1 {
    font-family: 'DunbarTallBold';
    font-weight: 500;
    font-size: 1.125rem;

    letter-spacing: 0.03em;

    color: #000000;
  }

  & > ul {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;

    overflow: auto;

    &::-webkit-scrollbar {
      display: none;
    }
  }
`;

export const Li = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2.5rem;

  white-space: nowrap;

  & > div.details-wrapper {
    display: flex;
    align-items: center;
    gap: 2rem;

    flex: 0 0 15rem;

    & > div.title-wrapper {
      display: flex;
      flex-direction: column;
      gap: 0.25rem;
    }
  }

  & > div.units-wrapper {
    display: flex;
    align-items: flex-start;
    gap: 2rem;

    flex: 0 0 10rem;

    & > div.indicator-wrapper {
      display: flex;
      align-items: center;

      & > strong {
        font-family: 'DunbarTallBold';

        font-weight: 700;
        font-size: 1.5rem;

        letter-spacing: 0.03em;

        color: #000000;
      }
    }
  }
`;

export const Logo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  min-width: 3rem;
  min-height: 3rem;

  & > img {
    width: 100%;
    object-fit: contain;
  }
`;

export const Title = styled.strong`
  font-family: 'DunbarTextRegular';
  font-weight: 400;
  font-size: 1rem;

  letter-spacing: 0.02em;

  color: #27282b;
`;

export const SubTitle = styled.p`
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 0.75rem;

  letter-spacing: 0.02em;

  color: #61646b;
`;

export const Units = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 0.25rem;

  & > strong {
    font-family: 'DunbarTextRegular';
    font-weight: 400;
    font-size: 1rem;

    letter-spacing: 0.02em;

    color: #27282b;
  }
`;

export const BestSellingKits = styled(BaseContainer)``;

export const BestSellingInverters = styled(BaseContainer)``;
