import React from 'react';
import profileIcon from '../../assets/profile.png';
import { useAuth } from '../../contexts/auth';
import { FranchiseSelector } from '../franchise-selector';
import { MenuIcon } from '../icons';
import { Container, Profile, Wrapper } from './styles';

type Props = {
  onOpenSidebar: () => void;
};

export const Header: React.FC<Props> = ({ onOpenSidebar }) => {
  const { user, franchises } = useAuth();

  const hasFranchises = !!franchises.length;

  return (
    <Container>
      <Wrapper>
        {hasFranchises && <FranchiseSelector />}

        <Profile>
          <img src={profileIcon} alt="nome" />
          <p>
            Olá, <strong>{user.name ? user.name.split(' ')[0] : ''}</strong>
          </p>
        </Profile>

        <MenuIcon onClick={onOpenSidebar} />
      </Wrapper>
    </Container>
  );
};
