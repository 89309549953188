import { debounce } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button } from '../../../../../../../components/button';
import { SearchInput } from '../../../../../../../components/search';
import Table, {
  ColumnStructure,
  TableData,
} from '../../../../../../../components/table';
import InnerActions from '../../../../../../../components/table/innerActions';
import { useAuth } from '../../../../../../../contexts/auth';
import { Order } from '../../../../../../../contexts/orders/types';
import productsApi from '../../../../../../../services/products';
import {
  EMPTY_PAGE,
  PaginatedResponse,
} from '../../../../../../../types/pagination';
import { UserRoles } from '../../../../../../../types/users';
import { Tag } from '../tag';
import { TableHeader } from './styles';

type OrderTableItems = {
  customer: string;
  status: JSX.Element;
  actions: JSX.Element;
};

export enum ORDER_STATUS {
  'A processar' = 'a-processar',
  'Pedido incompleto' = 'pedido-incompleto',
  'Enviado para CP' = 'enviado-para-cp',
  'Enviado para Fornecedor' = 'enviado-para-fornecedor',
  'Link do cartão' = 'link-do-cartao',
  'NFE emitida' = 'nfe-emitida',
  'Pedido cancelado' = 'pedido-cancelado',
  'Aguardando NFE' = 'aguardando-nfe',
  'Boleto/Espelho' = 'boleto-nff',
  'NFF/Espelho' = 'boleto-nff',
  'Pagamento Efetuado' = 'pagamento-efetuado',
}

type Props = {
  setLoading: (isLoading: boolean) => void;
};

export const ConsultantTable: React.FC<Props> = (props) => {
  const { setLoading } = props;

  const history = useHistory();

  const { selectedFranchise, user } = useAuth();

  const isAdmin = UserRoles.ADMIN.includes(user.role);

  const isSuccessConsultant = UserRoles.CONSULTOR_SUCESSO.includes(user.role);

  const franchiseId = isAdmin
    ? selectedFranchise || null
    : isSuccessConsultant
    ? selectedFranchise || null
    : selectedFranchise || '';

  const [orders, setOrders] = useState<PaginatedResponse<Order>>(EMPTY_PAGE);

  const [search, setSearch] = useState('');

  const debouncedFunc = debounce(
    async (
      page,
      limit,
      search,
      fn: (page?: number, limit?: number, search?: string) => Promise<void>
    ) => await fn(page, limit, search),
    350
  );

  const getOrders = useCallback(
    async (page?: number, limit?: number, search?: string) => {
      setLoading(true);

      setOrders((state) => {
        return {
          ...state,
          loading: true,
        };
      });

      productsApi
        .get('/orders', {
          params: {
            'filters[franchiseId]': franchiseId,
            page: page || orders.pagination.currentPage,
            limit: limit || orders.pagination.limit,
            'filters[name]': search || null,
          },
        })
        .then((response) => {
          setOrders(response.data);
        })
        .catch((error) => {
          const errorMessage = error.response.data.message;

          if (!selectedFranchise && !isAdmin && !isSuccessConsultant) {
            return toast.error('Selecione uma franquia');
          }

          toast.error(errorMessage);
        })
        .finally(() => {
          setLoading(false);

          setOrders((state) => {
            return {
              ...state,
              loading: false,
            };
          });
        });
    },
    [orders, selectedFranchise]
  );

  const orderTableColumns: ColumnStructure[] = [
    {
      id: 'customer',
      label: 'Cliente',
      onClick: () => {},
    },
    {
      id: 'franchise',
      label: 'Franquia',
    },
    {
      id: 'status',
      label: 'Status',
    },
    {
      id: 'actions',
      label: '',
      type: 'actionCell',
      isCentered: true,
    },
  ];

  const orderTableItems: TableData<OrderTableItems>[] = useMemo(() => {
    return orders.content.map((order) => {
      return {
        id: order.id,
        customer: order.budget.customer.name,
        franchise: order.budget.franchise.name,
        status: <Tag status={order.status}>{order.status}</Tag>,
        actions: (
          <InnerActions>
            <Button
              type="button"
              text="Ver detalhes"
              typeStyle="default"
              backgroundHoverColor="#C9CBCF"
              style={{
                width: '10rem',
                height: '2rem',
              }}
              onClick={() =>
                history.push(
                  `/orcamentos/orcamentos-e-pedidos/pedidos-enviados/${
                    order.id
                  }/${ORDER_STATUS[order.status as keyof typeof ORDER_STATUS]}`
                )
              }
            />
          </InnerActions>
        ),
      };
    });
  }, [orders, selectedFranchise]);

  useEffect(() => {
    getOrders();
  }, [selectedFranchise]);

  return (
    <Table
      header={
        <TableHeader>
          <SearchInput
            placeholder="Digite aqui para pesquisar pelo nome do cliente"
            onTextChange={(search) => {
              debouncedFunc(1, orders.pagination.limit, search, getOrders);

              setSearch(search);
            }}
            name="search"
          />
        </TableHeader>
      }
      items={orderTableItems}
      columns={orderTableColumns}
      isLoading={orders.loading}
      placeholder="Nenhum pedido encontrado"
      pagination={orders.pagination}
      onPageChange={(page) => getOrders(page, orders.pagination.limit, search)}
      onLimitChange={(limit) =>
        getOrders(orders.pagination.currentPage, limit, search)
      }
    />
  );
};
