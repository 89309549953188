import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  width: 22rem;
  height: 25.75rem;

  background: #ffffff;

  border: 0.5px solid #9fa1a8;
  border-radius: 8px;

  padding: 1rem;

  & > strong {
    font-family: 'Montserrat', sans-serif;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 600;
    letter-spacing: 0.0125rem;

    color: #27282b;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
`;

export const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

