import { Option } from '../../components/select';

export interface FranchiseType {
  id: string;
  name: string;
  nickname: string;
  cnpj: string;
  status: string;
  modality: string;
  customerSuccessResponsible: string;
  email: string;
  emailSecondary: string;
  phone: string;
  cellphone: string;
  zipcode: string;
  city: string;
  state: string;
  district: string;
  buildingNumber: string;
  observations: string;
  franchiseUserId: string;
  address: string;
  complement: string;
  franchiseUser: {
    cpf: string;
    email: string;
    enabled: boolean;
    id: string;
    name: string;
    role: string;
  };
  enabled: boolean;
}

export interface FranchiseForm {
  id: string;
  name: string;
  nickname: string;
  cnpj: string;
  email: string;
  emailSecondary: string;
  phone: string;
  cellphone: string;
  zipcode: string;
  city: string;
  state: string;
  district: string;
  buildingNumber: string;
  observations: string;
  franchiseUserId: string;
  address: string;
  complement: string;
  franchiseUser: {
    cpf: string;
    email: string;
    enabled: boolean;
    id: string;
    name: string;
    role: string;
  };
  enabled: boolean;
  password: string;
  confirmPassword?: string;
  status: Option;
  modality: Option;
  customerSuccessResponsible: Option;
}

export type FranchiseListType = {
  content: FranchiseType[];
  pagination: {
    lastPage: number;
    currentPage: number;
    total: number;
    limit: number;
  };
};

export interface UsersType {
  id: string;
  name: string;
  email: string;
  cpf: string;
  role: string;
  enabled: boolean;
}

export interface FranchiseUserType {
  user: UsersType;
  franchise: FranchiseType;
  franchiseId: string;
  id: string;
  role: string;
  userId: string;
}

export type FranchiseUserListType = {
  content: FranchiseUserType[];
  pagination: {
    lastPage: number;
    currentPage: number;
    total: number;
    limit: number;
  };
};

export const UserRoles = [
  {
    label: 'Administrador',
    value: 'Administrador',
  },
  {
    label: 'Vendedor',
    value: 'Vendedor',
  },
];
