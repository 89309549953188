import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;

  height: 25.75rem;

  overflow-y: auto;
`;

export const Card = styled.article`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  gap: 1.25rem;

  width: 22rem;
  height: 12.5rem;

  background: #ffffff;

  border: 0.5px solid #9fa1a8;
  border-radius: 8px;

  & > header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    & > strong {
      color: #27282b;
      font-family: 'Montserrat', sans-serif;
      font-size: 1.25rem;
      font-style: normal;
      font-weight: 600;
      letter-spacing: 0.0125rem;
    }

    & > div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 0.5rem;
    }
  }

  & > div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;

    & > span {
      display: flex;
      align-items: center;
      gap: 0.25rem;

      color: #000;

      font-family: 'DunbarTextRegular', sans-serif;
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      letter-spacing: 0.02rem;

      & > strong {
        color: #000;

        font-family: 'DunbarTallBold', sans-serif;
        font-size: 1rem;
        font-style: normal;
        font-weight: 700;
        letter-spacing: 0.03rem;
      }
    }
  }
`;

