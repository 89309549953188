import styled, { css } from 'styled-components';

export const Overlay = styled.div`
  position: fixed;
  inset: 0;
  z-index: 99;

  background-color: rgba(19, 20, 21, 0.6);
`;

type ContainerProps = {
  open: boolean;
};

export const Container = styled.div<ContainerProps>`
  width: 25rem;
  height: 100vh;

  display: flex;
  flex-direction: column;
  flex: 1;

  background-color: #fff;
  border-left: 1px solid #c9cbcf;
  box-shadow: 1px 4px 6px rgba(0, 0, 0, 0.1), 1px 8px 15px rgba(0, 0, 0, 0.1);

  right: -6.25rem;
  position: fixed;
  bottom: 0;
  top: 0;
  transition: -webkit-transform 0.5s ease;
  transition: transform 0.5s ease;
  -webkit-transform: translateX(110%);
  -ms-transform: translateX(110%);
  transform: translateX(110%);
  z-index: 999;

  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;

  ${(props) =>
    props.open &&
    css`
      right: 0;
      -webkit-transform: translateX(0);
      -ms-transform: translateX(0);
      transform: translateX(0);
    `}

  @media (max-width: 520px) {
    width: 18rem;
  }
`;

type NotificationType = 'warning' | 'update';

type NotificationProps = {
  type: NotificationType;
};

export const Notification = styled.div<NotificationProps>`
  width: 100%;

  display: flex;
  align-items: center;
  gap: 1rem;

  padding: 1rem;
  border-bottom: 1px solid #c9cbcf;

  background-color: ${(props) =>
    props.type === 'warning' ? '#FFF5F5' : '#F5F9FF'};

  & > div {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    flex: 1;
  }

  & > div > span,
  & > div > p {
    font-family: 'Montserrat';
    font-weight: 400;
    line-height: 150%;
    letter-spacing: 0.02em;
  }

  & > div > span {
    font-size: 0.875rem;
    color: #61646b;
  }

  & > div > p {
    font-size: 1rem;
    color: #27282b;
  }

  & > div > p > strong.name {
    text-decoration: underline;
    text-underline-offset: 0.125rem;
    text-decoration-color: '#000';
  }

  &:first-child {
    border-top-left-radius: 8px;
  }
  &:last-child {
    border-bottom-left-radius: 8px;
  }
  &:not(:last-child) {
    padding-bottom: 1.5rem;
  }
`;
