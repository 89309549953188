import styled from 'styled-components';

export const Content = styled.form`
  height: 100%;

  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  padding: 1.5rem 0;

  & > h2 {
    font-family: 'DunbarTextRegular', sans-serif;
    font-weight: 400;
    font-size: 1.25rem;
    line-height: 150%;
    letter-spacing: 0.02em;
    color: #27282b;
  }

  & > div:nth-child(4) {
    height: 100%;
  }
`;

export const Row = styled.div`
  width: 100%;

  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 1.5rem;

  margin-bottom: 1rem;

  & > div,
  & > label {
    flex: 0 0 calc(25% - 1.125rem);
  }

  @media screen and (max-width: 980px) {
    & > div,
    & > label {
      flex: 0 0 calc(50% - 0.875rem);
    }
  }

  @media screen and (max-width: 720px) {
    & > div,
    & > label {
      flex: 0 0 100%;
    }
  }
`;

export const ValueContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;

  & > p {
    font-family: 'DunbarTextRegular', sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5rem;
    letter-spacing: 0.02em;
    color: #27282b;
  }

  & > span {
    font-family: 'DunbarTallBold', sans-serif;
    font-weight: 500;
    font-size: 1.5rem;
    line-height: 1.5rem;
    letter-spacing: 0.03em;
    color: #27282b;
  }
`;
