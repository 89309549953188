import React, { ReactNode } from 'react';
import { Container } from './styles';

import arrowRight from '../../assets/icon/plus-circle.svg';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  backgroundColor?: string;
  backgroundHoverColor?: string;
  fontColor?: string;
  text: string;
  disabled?: boolean;
  typeStyle?: string;
  icon?: 'add' | ReactNode;
  size?: 'large';
  message?: ReactNode;
  width?: string;
}

export const Button: React.FC<ButtonProps> = ({
  typeStyle,
  backgroundColor,
  backgroundHoverColor,
  fontColor,
  text,
  disabled,
  icon,
  size,
  message,
  width = '16.125rem',
  ...rest
}) => {
  let typeHvColor,
    typeBgColor,
    typefontColor = undefined;

  if (
    (!backgroundColor && !backgroundHoverColor && !typeStyle) ||
    typeStyle == 'confirm'
  ) {
    typeBgColor = '#00299b';
    typeHvColor = '#005AF9';
    typefontColor = '#FFF';
  } else if (typeStyle === 'delete') {
    typeBgColor = '#ED5A5A';
    typeHvColor = '#ED4A4D';
    typefontColor = '#FFF';
  } else if (typeStyle === 'default') {
    typeBgColor = '#DFE0E2';
    typeHvColor = '#DFE0E2';
    typefontColor = '#27282B';
  }

  return (
    <Container
      width={width}
      backgroundColor={backgroundColor || typeBgColor}
      backgroundHoverColor={backgroundHoverColor || typeHvColor}
      fontColor={fontColor || typefontColor}
      disabled={disabled}
      size={size}
      {...rest}
    >
      {icon === 'add' ? <img src={arrowRight} alt={text} /> : icon ?? icon}

      {text && text}
      {message && <span className="message">{message}</span>}
    </Container>
  );
};
