import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Breadcrumb, Button } from '../../../components';

import {
  ButtonContainer,
  Container,
  Content,
  PageHeader,
  TermsOfUserContainer,
} from './styles';

const NewProjectPage: React.FC = () => {
  const [termsAccepted, setTermsAccepted] = useState(false);
  const history = useHistory();

  const links = [
    {
      id: 1,
      title: 'Projetos',
      link: '/projetos',
      active: false,
    },
    {
      id: 2,
      title: 'Novo projeto',
      link: '/projeto/novo',
      active: true,
    },
  ];

  return (
    <Container>
      <Breadcrumb links={links} />
      <PageHeader>
        <span>Novo projeto</span>
      </PageHeader>
      <Content>
        <p>
          Para iniciar um projeto com Engenharia da Energy Brasil, toda a
          documentação do checklist deve ser enviada para a elaboração do
          projeto e envio para analise da concessionária
        </p>

        <ul>
          <li>
            Prazo para validação da documentação recebida - 24 horas úteis
          </li>
          <li>Prazo de elaboração do projeto - 5 dias úteis</li>
          <li>
            Prazo para análise do projeto na concessionária - 15 dias corridos
          </li>
          <li>Prazo para solicitação de vistoria - 24 horas úteis</li>
          <li>Prazo de execução da vistoria - 7 dias corridos</li>
          <li>Prazo de troca do medidor - 7 dias corridos</li>
        </ul>

        <TermsOfUserContainer>
          <input
            type="checkbox"
            defaultChecked={termsAccepted}
            onClick={() => setTermsAccepted(!termsAccepted)}
          />
          <p>
            Eu concordo com todas as informações enviadas e estou ciente do
            prazo normativo de aprovação do projeto.
          </p>
        </TermsOfUserContainer>
        <ButtonContainer>
          <Button
            typeStyle="confirm"
            text="Novo projeto"
            disabled={!termsAccepted}
            onClick={() => {
              if (termsAccepted) {
                history.push('/projeto/novo/formulario');
              }
            }}
          />
        </ButtonContainer>
      </Content>
    </Container>
  );
};

export default NewProjectPage;
