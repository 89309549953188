import React, { useMemo } from 'react';
import Table, {
  ColumnStructure,
  TableData,
} from '../../../../../../../../components/table';
import { TableHeader } from '../styles';
import { SearchInput } from '../../../../../../../../components/search';
import Count from '../../../../../../../../components/count';
import Checkbox from '../../../../../../../../components/checkbox';

type TableItems = {
  id: string;
  checkbox: JSX.Element;
  provider: string;
  description: string;
  quantity: JSX.Element;
  price: string;
};

export const ConnectorsTable: React.FC = () => {
  const tableColumns: ColumnStructure[] = [
    {
      id: 'checkbox',
      label: 'Checkbox',
      type: 'actionCell',
      checkbox: (
        <Checkbox
          styles={{ padding: '1.2rem 2rem' }}
          name={'allProducts'}
          onChange={() => null}
          isChecked={false}
        />
      ),
    },
    {
      id: 'provider',
      label: 'Fornecedor',
      onClick: () => {},
    },
    {
      id: 'description',
      label: 'Descrição',
      onClick: () => {},
    },
    {
      id: 'quantity',
      label: 'Quantidade',
    },
    {
      id: 'price',
      label: 'Valor',
      onClick: () => {},
    },
  ];

  const tableItems: TableData<TableItems>[] = useMemo(() => {
    return [
      {
        id: '1',
        checkbox: (
          <Checkbox
            styles={{ padding: '1.2rem 2rem' }}
            name=""
            onChange={() => null}
            isChecked={false}
          />
        ),
        provider: 'Elgin',
        description: 'Caixa com 4 pares de conectores MC4',
        quantity: (
          <Count
            variant="secondary"
            name=""
            value={0}
            onIncrement={() => null}
            onDecrement={() => null}
          />
        ),
        price: 'R$ 300,00',
      },
    ];
  }, []);

  return (
    <Table
      items={tableItems}
      columns={tableColumns}
      isLoading={false}
      header={
        <TableHeader>
          <SearchInput
            name="search"
            placeholder="Digite aqui para pesquisar"
            onTextChange={(value) => null}
          />
        </TableHeader>
      }
      placeholder="Nenhum produto encontrado"
    />
  );
};

