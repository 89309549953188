import React, { HTMLAttributes } from 'react';
import { Container, Spacing, Version } from './styles';

interface Props extends HTMLAttributes<HTMLButtonElement> {
  version: Version;
  isCircular?: boolean;
  size?: string;
  spacing?: Spacing;
  width?: string;
  children: React.ReactNode;
  isMobile?: boolean;
}

export const Tag: React.FC<Props> = (props) => {
  const {
    isCircular,
    size,
    version,
    children,
    spacing,
    width,
    isMobile,
    ...rest
  } = props;

  return (
    <Container
      version={version}
      spacing={spacing}
      isCircular={isCircular}
      size={size}
      width={width}
      isMobile={isMobile}
      {...rest}
    >
      {children}
    </Container>
  );
};
