import React, { PropsWithChildren } from 'react';
import Select, {
  GroupBase,
  OptionProps,
  StylesConfig,
  components,
  Props,
  MenuListProps,
} from 'react-select';
import { Container, NewTagButton, Placeholder, Tag } from './styles';
import Checkbox from '../../../../../checkbox';
import { TagsRefreshIcon } from '../../../../../icons';

export type Option = {
  id: string;
  franchiseTagId?: string;
  label: string | null;
  value: string;
};

type OptionComponentProps = PropsWithChildren<
  OptionProps<Option, boolean, GroupBase<Option>>
>;

type MenuListComponentProps = PropsWithChildren<
  MenuListProps<Option, boolean, GroupBase<Option>>
>;

type SelectStyles = StylesConfig<Option, boolean, GroupBase<Option>>;

type TagSelectProps = {
  onNewTag: () => void;
} & Props<Option>;

export const TagSelect = (props: TagSelectProps) => {
  const { onNewTag, ...rest } = props;

  const customStyles: SelectStyles = {
    container: (provided) => ({
      ...provided,
      width: '13.5rem',
      height: '2.5rem',
    }),
    control: (provided, state) => ({
      ...provided,
      height: '2.5rem',
      backgroundColor: state.isDisabled ? '#F4F5F6' : '#fff',
      fontSize: '1rem',
      color: state.isDisabled ? '#9AA3AC' : '#27282b',
      cursor: 'pointer',
      border: state.isFocused ? 'none' : provided.border,
      borderColor: '#C9CBCF',
      pointerEvents: state.isDisabled ? 'none' : 'auto',
      userSelect: state.isDisabled ? 'none' : 'auto',
      ':hover': {
        borderColor: '#C9CBCF',
      },
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      transition: 'all .2s ease',
      transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : '',
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: `#F9FAFB`,
    }),
    valueContainer: (provided) => ({
      ...provided,
      paddingLeft: '1rem',
      border: 0,
    }),
    menuList: (provided) => ({
      ...provided,
      border: '1px solid #005AF9',
      borderRadius: '4px',
      maxHeight: '13.75rem',
      '::-webkit-scrollbar': {
        width: '4px',
      },
      '::-webkit-scrollbar-track': {
        background: '#f5f5f6',
        borderRadius: '8px',
      },
      '::-webkit-scrollbar-thumb': {
        background: '#a8b0b5',
        borderRadius: '8px',
      },
    }),
    option: (provided, state) => ({
      ...provided,
      display: 'flex',
      alignItems: 'center',
      gap: '0.5rem',
      padding: '0.25rem 1.5rem',
      cursor: state.isDisabled ? 'not-allowed' : 'pointer',
      backgroundColor: 'unset',
      color: 'unset',
      ':selected': {
        backgroundColor: state.isFocused ? 'unset' : 'red',
      },
      ':active': {
        backgroundColor: state.isFocused ? 'unset' : 'red',
      },
    }),
    placeholder: (provided) => ({
      ...provided,
      display: 'flex',
      alignItems: 'center',
    }),
  };

  return (
    <Container>
      <Select
        isClearable={false}
        controlShouldRenderValue={false}
        styles={customStyles}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary: '#005AF9',
          },
        })}
        placeholder={
          <Placeholder>
            <TagsRefreshIcon /> Etiquetas
          </Placeholder>
        }
        isSearchable={false}
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        components={{
          Option: ({ children, ...rest }: OptionComponentProps) => {
            const { data } = rest;

            return (
              <components.Option {...rest}>
                <Checkbox
                  name={data.id}
                  onChange={() => null}
                  isChecked={rest.isSelected}
                />
                <Tag bgColor={data.value}>{children}</Tag>
              </components.Option>
            );
          },
          MenuList: ({ children, ...rest }: MenuListComponentProps) => {
            return (
              <components.MenuList {...rest}>
                {children}
                <NewTagButton onClick={() => onNewTag()}>
                  Adicionar etiqueta
                </NewTagButton>
              </components.MenuList>
            );
          },
        }}
        noOptionsMessage={() => 'Nenhuma etiqueta encontrada'}
        {...rest}
      />
    </Container>
  );
};
