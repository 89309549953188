import { debounce } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  ActionButton,
  Breadcrumb,
  Button,
  SearchInput,
} from '../../../components';
import Checkbox from '../../../components/checkbox';
import DynamicFilter, {
  FilterOption,
  Option,
} from '../../../components/dynamic-filter';
import productsApi from '../../../services/products';
import { EMPTY_PAGE, PaginatedResponse } from '../../../types/pagination';
import {
  Product,
  productTypes,
  sanitizedProductTypes,
} from '../../../types/product';
import { Container, PageHeader } from '../../styles';

import { FiEdit, FiEye } from 'react-icons/fi';
import { KitDescriptionModal } from '../../../components/modal/kit-description';
import Table, { ColumnStructure, TableData } from '../../../components/table';
import InnerActions from '../../../components/table/innerActions';
import { useAuth } from '../../../contexts/auth';
import { UserRoles } from '../../../types/users';
import { booleanToYesNo } from '../../../utils/booleanToYesNo';
import { toMoneyFormat } from '../../../utils/toMoneyFormat';
import { TableHeader } from './styles';

type Filters = {
  category: string[];
  fabricMaterial: string[];
  phase: string[];
  supplier: string[];
  type: string[];
  voltage: string[];
};

enum FiltersLabels {
  category = 'Categoria',
  fabricMaterial = 'Estrutura',
  phase = 'Fase',
  supplier = 'Fornecedores',
  type = 'Tipo',
  voltage = 'Tensão do inversor',
}

enum RestoredFilterLabels {
  Categoria = 'category',
  Estrutura = 'fabricMaterial',
  Fornecedores = 'supplier',
  Tipo = 'type',
  Fase = 'phase',
  Tensão = 'voltage',
}

type TableItems = {
  id: string;
  checkbox: JSX.Element;
  supplier: string;
  promotional: string;
  power: string;
  category: string;
  fabricMaterial: string;
  originalPrice: string;
  price: string;
  description: string;
  supplierCode: string;
  actions: JSX.Element;
};

const limitOptions = [
  { value: 10, label: '10' },
  { value: 25, label: '25' },
  { value: 50, label: '50' },
  { value: 150, label: '150' },
];

const KitListPage: React.FC = () => {
  const { selectedFranchise: franchiseId, user } = useAuth();

  const { isAdmin, isSupervision } = {
    isSupervision: [UserRoles.SUPERVISAO].includes(user.role),
    isAdmin: [UserRoles.ADMIN].includes(user.role),
  };

  const { type } = useParams<{ type: string }>();

  if (!['kit-pronto', 'kit-personalizado'].includes(type)) {
    return <Redirect to="/produtos/lista" />;
  }

  const history = useHistory();

  const links = [
    {
      id: 1,
      title: 'Produtos',
      link: '/produtos',
      active: false,
    },
    {
      id: 2,
      title: 'Lista de produtos',
      link: `/produtos/lista`,
      active: false,
    },
    {
      id: 3,
      title: `Lista de ${
        sanitizedProductTypes[type as keyof typeof productTypes].plural
      }`,
      link: `/produtos/lista/${type}`,
      active: true,
    },
  ];

  const [products, setProducts] =
    useState<PaginatedResponse<Product>>(EMPTY_PAGE);

  const [loading, setLoading] = useState(false);

  const [selectedProducts, setSelectedProducts] = useState<Product[]>([]);

  const allProductsIsChecked =
    products.content.every((_kit) =>
      selectedProducts.some((kit) => kit.id === _kit.id)
    ) &&
    !loading &&
    selectedProducts.length > 0;

  const [search, setSearch] = useState('');

  const [filterOptions, setFilterOptions] = useState([] as FilterOption[]);

  const [filters, setFilters] = useState({} as Filters);

  const [productDescription, setProductDescription] = useState('');

  const hasProductDescription = !!productDescription.length;

  const hasProductSelected = !!selectedProducts.length;

  const handleFilters = (options: Option[]) => {
    const normalizeSelectedFilters = options.reduce(
      (acc, option) => {
        return {
          ...acc,
          [RestoredFilterLabels[
            option.label as keyof typeof RestoredFilterLabels
          ]]: [
            ...acc[
              RestoredFilterLabels[
                option.label as keyof typeof RestoredFilterLabels
              ] as keyof typeof filters
            ],
            option.value,
          ],
        };
      },
      {
        category: [],
        fabricMaterial: [],
        phase: [],
        supplier: [],
        type: [],
        voltage: [],
      } as Filters
    );

    setFilters(normalizeSelectedFilters);
  };

  const handleSelectProduct = (product: Product) => {
    setSelectedProducts((state) => {
      const hasExistingValue = !!state.find((kit) => kit.id === product.id);

      if (hasExistingValue) {
        return state.filter((kit) => kit.id !== product.id);
      }

      return [...state, product];
    });
  };

  const [deleteAllProducts, setDeleteAllProducts] = useState(false);

  const handleSelectAllProducts = () => {
    setSelectedProducts((state) => {
      const hasValues = state.length === products.content.length;

      if (hasValues) {
        return [];
      }

      return products.content;
    });
  };

  const handleDescriptionModal = (description: string) =>
    setProductDescription(description);

  const handleDeleteProducts = async (productsToDelete: Product[]) => {
    const promises = productsToDelete.map((product) =>
      productsApi.delete(`/products/${product.id}`)
    );

    try {
      await Promise.all(promises);

      getProducts(1, '', products.pagination.limit).then((products) => {
        setProducts(products);
      });

      setSelectedProducts([]);

      toast.success('Todos os produtos selecionados foram removidos.', {
        autoClose: 1200,
      });
    } catch (error) {
      toast.error('Não foi possível remover os produtos selecionados.');
      throw error;
    }
  };

  const handleDeleteAllProducts = async () => {
    try {
      await productsApi.delete('/products/delete/filter', {
        params: {
          search,
          category: filters.category,
          fabricMaterial: filters.fabricMaterial,
          phase: filters.phase,
          supplier: filters.supplier,
          voltage: filters.voltage,
        },
      });

      await getFilters().then((filters) => {
        setFilterOptions(normalizeFilters(filters));

        setFilters({
          category: [],
          fabricMaterial: [],
          phase: [],
          supplier: [],
          type: [],
          voltage: [],
        });
      });

      if (hasProductSelected) {
        setSelectedProducts([]);
      }

      setDeleteAllProducts((state) => !state);
    } catch (error) {
      toast.error('Desculpe, não foi possível remover os produtos.');
    }
  };

  const getFilters = async () => {
    try {
      const { data } = await productsApi.get('/products/filters/', {
        params: {
          fields: 'category,supplier,fabricMaterial,phase,voltage',
          franchiseId: franchiseId || undefined,
        },
      });

      return data;
    } catch (error) {
      toast.error('Não foi possível buscar os filtros.');
    }
  };

  const getProducts = async (
    page: number,
    search: string,
    limit?: number
  ): Promise<PaginatedResponse<Product>> => {
    setLoading(true);

    try {
      const { data } = await productsApi.get<PaginatedResponse<Product>>(
        `/products`,
        {
          params: {
            page,
            limit: limit ? limit : products.pagination.limit,
            type: productTypes[type as keyof typeof productTypes],
            search,
            category: filters.category,
            fabricMaterial: filters.fabricMaterial,
            phase: filters.phase,
            supplier: filters.supplier,
            voltage: filters.voltage,
            sort: orderBy || 'createdAt,DESC',
          },
        }
      );

      return data;
    } catch (error) {
      toast.error('Não foi possível buscar os produtos.', {
        toastId: '1',
      });

      throw error;
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = (page: number) => {
    getProducts(page, search, products.pagination.limit).then((products) => {
      setProducts(products);
    });
  };

  const handleLimitChange = (limit: number) => {
    getProducts(products.pagination.currentPage, search, limit).then(
      (products) => {
        setProducts(products);
      }
    );
  };

  const handleSearchChange = debounce((textValue) => setSearch(textValue), 500);

  const normalizeFilters = (options: Filters) => {
    const formatLabel = {
      category(value: string | number) {
        return `${value}W`;
      },
      type(value: string) {
        const translation: { [x: string]: string } = {
          default: 'Padrão',
        };

        return translation[value] || value;
      },
      voltage(value: string | number) {
        return `${value}V`;
      },
    };

    return Object.entries(options)
      .map(([field, value]) => {
        const label = FiltersLabels[field as keyof typeof FiltersLabels];

        const options = value
          .map((item) => ({
            label: formatLabel[field as keyof typeof formatLabel]
              ? formatLabel[field as keyof typeof formatLabel](item)
              : item,
            value: item,
          }))
          .sort((a, b) => a.label.localeCompare(b.label));

        return {
          label,
          options,
        };
      })
      .sort((a, b) => a.label.localeCompare(b.label));
  };

  const [orderBy, setOrderBy] = useState('');

  const handleOrderBy = (columnId: string) => {
    const currentColumn = tableColumns.find((column) => column.id === columnId);
    setOrderBy(
      String(
        currentColumn?.id +
          ',' +
          (currentColumn?.orderBy === 'DESC' ? 'ASC' : 'DESC')
      )
    );
  };

  const tableColumns: ColumnStructure[] = useMemo(() => {
    const [column, order] = orderBy.split(',');

    return [
      {
        id: 'checkbox',
        label: 'Checkbox',
        type: 'actionCell',
        checkbox: (
          <Checkbox
            name="allChecked"
            styles={{ padding: '1.2rem 2rem' }}
            isChecked={allProductsIsChecked}
            onChange={handleSelectAllProducts}
            value={JSON.stringify(selectedProducts)}
            disabled={!isAdmin}
          />
        ),
      },
      {
        id: 'supplier',
        label: 'Fornecedor',
        orderBy: column === 'supplier' ? order : 'DESC',
        onClick: () => handleOrderBy('supplier'),
      },
      {
        id: 'promotional',
        label: 'Promocional',
        orderBy: column === 'promotional' ? order : 'DESC',
        onClick: () => handleOrderBy('promotional'),
      },
      {
        id: 'power',
        label: 'Potência',
        orderBy: column === 'power' ? order : 'DESC',
        onClick: () => handleOrderBy('power'),
      },
      {
        id: 'category',
        label: 'Categoria',
        orderBy: column === 'category' ? order : 'DESC',
        onClick: () => handleOrderBy('category'),
      },
      {
        id: 'fabricMaterial',
        label: 'Estrutura',
        orderBy: column === 'fabricMaterial' ? order : 'DESC',
        onClick: () => handleOrderBy('fabricMaterial'),
      },
      {
        id: 'originalPrice',
        label: 'Valor inicial',
        onClick: () => {},
      },
      {
        id: 'price',
        label: 'Valor final',
        orderBy: column === 'price' ? order : 'DESC',
        onClick: () => handleOrderBy('price'),
      },
      {
        id: 'description',
        label: 'Descrição',
        hasEllipsis: true,
        orderBy: column === 'description' ? order : 'DESC',
        onClick: () => handleOrderBy('description'),
      },
      {
        id: 'supplierCode',
        label: 'SKU',
        orderBy: column === 'supplierCode' ? order : 'DESC',
        onClick: () => handleOrderBy('supplierCode'),
      },
      {
        id: 'actions',
        label: 'Ações',
        type: 'actionCell',
      },
    ];
  }, [products, allProductsIsChecked, orderBy]);

  const tableItems: TableData<TableItems>[] = useMemo(() => {
    return products.content.map((product) => {
      const isChecked = !!selectedProducts.find(
        (selectedProduct) => selectedProduct.id === product.id
      );

      return {
        id: product.id,
        checkbox: (
          <Checkbox
            name={product.id}
            styles={{ padding: '1.2rem 2rem' }}
            isChecked={isChecked}
            onChange={handleSelectProduct}
            option={product}
            value={product.id}
            disabled={!isAdmin}
          />
        ),
        supplier: product.supplier,
        promotional: booleanToYesNo(product.promotional),
        power: product.power + 'KWp',
        category: product.category + 'W',
        fabricMaterial: product.fabricMaterial,
        originalPrice: toMoneyFormat(product.originalPrice),
        price: toMoneyFormat(product.price),
        description: product.description,
        supplierCode: product.supplierCode,
        actions: (
          <InnerActions>
            {isAdmin ||
              (isSupervision && (
                <ActionButton
                  tooltip="Editar"
                  onClick={() =>
                    history.push(`/produtos/lista/${type}/${product.id}/editar`)
                  }
                >
                  <FiEdit />
                </ActionButton>
              ))}

            <ActionButton
              tooltip="Visualizar"
              onClick={() => handleDescriptionModal(product.description)}
            >
              <FiEye />
            </ActionButton>
          </InnerActions>
        ),
      };
    });
  }, [products, selectedProducts]);

  useEffect(() => {
    getFilters().then((filters) => setFilterOptions(normalizeFilters(filters)));
  }, []);

  useEffect(() => {
    getProducts(1, search).then((products) => {
      setProducts(products);
    });
  }, [search, filters, orderBy]);

  return (
    <Container style={{ paddingBottom: '6.5rem' }}>
      <Breadcrumb links={links} />

      <PageHeader>
        <span>{`Lista de ${
          sanitizedProductTypes[type as keyof typeof productTypes].plural
        }`}</span>
      </PageHeader>

      <Table
        limits={limitOptions}
        items={tableItems}
        columns={tableColumns}
        isLoading={loading}
        header={
          <TableHeader hasProductSelected={hasProductSelected}>
            {isAdmin && (
              <div>
                <Checkbox
                  name="deleteAllProducts"
                  isChecked={deleteAllProducts}
                  onChange={() => setDeleteAllProducts((state) => !state)}
                  label={`Selecionar todos (${products.pagination.total})`}
                />
                <Button
                  type="button"
                  text="Excluir os itens selecionados"
                  typeStyle="default"
                  backgroundHoverColor="#C9CBCF"
                  style={{
                    width: '14.5rem',
                    fontWeight: 600,
                    padding: '0.75rem',
                  }}
                  disabled={!hasProductSelected && !deleteAllProducts}
                  onClick={async () => {
                    if (deleteAllProducts) {
                      await handleDeleteAllProducts();

                      return;
                    }

                    handleDeleteProducts(selectedProducts);
                  }}
                />
              </div>
            )}
            <div>
              <SearchInput
                placeholder="Digite aqui para pesquisar"
                onTextChange={handleSearchChange}
                name="search"
              />
              <DynamicFilter filters={filterOptions} onChange={handleFilters} />
            </div>
          </TableHeader>
        }
        pagination={products.pagination}
        onPageChange={handlePageChange}
        onLimitChange={handleLimitChange}
      />

      <KitDescriptionModal
        isOpen={hasProductDescription}
        description={productDescription as string}
        onCancel={() => handleDescriptionModal('')}
      />
    </Container>
  );
};

export default KitListPage;
