import styled from 'styled-components';

export const Content = styled.main`
  display: flex;
  flex-direction: column;

  padding: 1.5rem 0;

  height: 100%;
`;

export const Header = styled.header`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  & > h1 {
    font-family: 'Montserrat', sans-serif;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 600;
    letter-spacing: 0.0125rem;

    font-weight: 600;

    color: #27282b;
  }
`;

export const Products = styled.div`
  display: flex;
  align-items: center;
  gap: 1.5rem;

  overflow-x: auto;
`;

type Product = {
  isActive: boolean;
};

export const Product = styled.button.attrs((props) => ({
  ...props,
  type: 'button',
}))<Product>`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 10.25rem;
  height: 3rem;

  flex-shrink: 0;

  border-radius: 0.5rem;
  border: 0.5px solid ${(props) => (props.isActive ? '#00299B' : '#C9CBCF')};
  background: ${(props) => (props.isActive ? '#C8D2EF' : '#f5f5f5')};

  color: ${(props) => (props.isActive ? '#00299B' : '#60636b')};
  font-family: 'Montserrat', sans-serif;
  font-weight: ${(props) => (props.isActive ? '600' : '500')};
`;
