import React from 'react';
import { Table } from './components/Table';
import { Map } from './components/Map';
import {
  Container,
  RankingContainer,
  RankingWrapper,
  SectionContainer,
  SoldItems,
  Title,
  YourSales,
  YourSalesCard,
  YourSalesWrapper,
} from './styles';
import { UserRoles } from '../../../../types/users';
import { useAuth } from '../../../../contexts/auth';
import { Card } from './components/Card';
import { useScrollContainer } from 'react-indiana-drag-scroll';
import { GeneralData } from './components/GeneralData';
import { Ranking } from './components/Ranking';
import { RxTriangleUp } from 'react-icons/rx';
import { MonthlyHistory } from './components/MonthlyHistory';
import { useDashboard } from '../../../../contexts/dashboard';
import { FranchiseData } from '../../../../contexts/dashboard/types';

type DashboardPropertiesType = {
  franchiseData: FranchiseData;
};

export const Sales: React.FC = () => {
  const { franchiseData } = useDashboard() as DashboardPropertiesType;

  const { user } = useAuth();

  const soldItemsRef = useScrollContainer<HTMLElement>({
    mouseScroll: {
      overscroll: true,
      ignoreElements: '.undraggable',
    },
  });

  if (UserRoles.FRANQUEADO.includes(user.role)) {
    return (
      <Container>
        <GeneralData role={user.role} />

        <YourSales>
          <h1>Suas vendas em relação às unidades no mesmo nível</h1>

          <YourSalesWrapper>
            <YourSalesCard
              type={
                franchiseData.salesComparison?.sales > 0
                  ? 'increased'
                  : 'decreased'
              }
            >
              <h2>Vendas</h2>

              <div>
                <RxTriangleUp />
                <strong>
                  {Math.abs(franchiseData.salesComparison?.sales)}%
                </strong>
              </div>

              <strong>
                {franchiseData.salesComparison?.sales > 0
                  ? 'Acima da média'
                  : 'Abaixo da média'}
              </strong>
            </YourSalesCard>
            <YourSalesCard
              type={
                franchiseData.salesComparison?.item > 0
                  ? 'increased'
                  : 'decreased'
              }
            >
              <h2>Itens</h2>

              <div>
                <RxTriangleUp />
                <strong>
                  {Math.abs(franchiseData.salesComparison?.item)}%
                </strong>
              </div>

              <strong>
                {franchiseData.salesComparison?.item > 0
                  ? 'Acima da média'
                  : 'Abaixo da média'}
              </strong>
            </YourSalesCard>
            <YourSalesCard
              type={
                franchiseData.salesComparison?.labor > 0
                  ? 'increased'
                  : 'decreased'
              }
            >
              <h2>Mão de obra</h2>

              <div>
                <RxTriangleUp />
                <strong>
                  {Math.abs(franchiseData.salesComparison?.labor)}%
                </strong>
              </div>

              <strong>
                {franchiseData.salesComparison?.labor > 0
                  ? 'Acima da média'
                  : 'Abaixo da média'}
              </strong>
            </YourSalesCard>
          </YourSalesWrapper>
        </YourSales>

        {/* <Leads /> */}

        <RankingContainer>
          <RankingWrapper>
            <Card
              title="Produção acumulada do ano"
              currentValue={franchiseData.sales.total_year}
              style={{ width: '100%' }}
            />
            <Card
              title="Vendas em negociação"
              lastValue={franchiseData.sales.generation?.lastMonth}
              currentValue={franchiseData.sales.generation?.currentMonth}
              percentage={
                (franchiseData.sales.generation?.currentMonth /
                  franchiseData.sales.generation?.lastMonth -
                  1) *
                100
              }
              isPositive={
                franchiseData.sales.generation?.currentMonth >
                franchiseData.sales.generation?.lastMonth
              }
              style={{ width: '100%' }}
              money={true}
            />
            <Card
              title="Valor médio dos kits"
              lastValue={franchiseData.sales.kits_middle?.lastMonth}
              currentValue={franchiseData.sales.kits_middle?.currentMonth}
              percentage={
                (franchiseData.sales.kits_middle?.currentMonth /
                  franchiseData.sales.kits_middle?.lastMonth -
                  1) *
                100
              }
              isPositive={
                franchiseData.sales.kits_middle?.currentMonth >
                franchiseData.sales.kits_middle?.lastMonth
              }
              style={{ width: '100%' }}
            />
            <Card
              title="Royalties"
              lastValue={2182.67}
              currentValue={86495.69}
              percentage={(86495.69 / 2182.67 - 1) * 100}
              isPositive={false}
              style={{ width: '100%' }}
            />
            <Card
              title="Ticket médio"
              lastValue={franchiseData.sales.ticket_products_labor?.lastMonth}
              currentValue={
                franchiseData.sales.ticket_products_labor?.currentMonth
              }
              percentage={
                (franchiseData.sales.ticket_products_labor?.currentMonth /
                  franchiseData.sales.ticket_products_labor?.lastMonth -
                  1) *
                100
              }
              isPositive={
                franchiseData.sales.ticket_products_labor?.currentMonth >
                franchiseData.sales.ticket_products_labor?.lastMonth
              }
              style={{ width: '100%' }}
            />
            <Card
              title="Média de mão de obra"
              lastValue={franchiseData.sales.labor_middle?.lastMonth}
              currentValue={franchiseData.sales.labor_middle?.currentMonth}
              percentage={
                (franchiseData.sales.labor_middle?.currentMonth /
                  franchiseData.sales.labor_middle?.lastMonth -
                  1) *
                100
              }
              isPositive={
                franchiseData.sales.labor_middle?.currentMonth >
                franchiseData.sales.labor_middle?.lastMonth
              }
              style={{ width: '100%' }}
            />
          </RankingWrapper>
        </RankingContainer>

        {/* <Ranking role={user.role} /> */}

        <MonthlyHistory />

        <SectionContainer>
          <Title>Itens vendidos</Title>

          <SoldItems ref={soldItemsRef.ref}>
            <Card
              title="Itens vendidos"
              lastValue={franchiseData.sales.item.lastMonth}
              currentValue={franchiseData.sales.item.currentMonth}
              percentage={
                (franchiseData.sales.item.currentMonth /
                  franchiseData.sales.item.lastMonth -
                  1) *
                100
              }
              isPositive={
                franchiseData.sales.item.currentMonth >
                franchiseData.sales.item.lastMonth
              }
              money={false}
            />
            <Card
              title="Geração em negociação"
              lastValue={franchiseData.sales.sales_negotiation?.lastMonth * 2}
              currentValue={
                franchiseData.sales.sales_negotiation?.currentMonth * 2
              }
              percentage={
                (franchiseData.sales.sales_negotiation?.currentMonth /
                  franchiseData.sales.sales_negotiation?.lastMonth -
                  1) *
                100
              }
              isPositive={
                franchiseData.sales.sales_negotiation?.currentMonth >
                franchiseData.sales.sales_negotiation?.lastMonth
              }
              money={false}
              sufix=" kWp"
            />
            <Card
              title="Módulos vendidos"
              lastValue={franchiseData.sales.modules?.lastMonth}
              currentValue={franchiseData.sales.modules?.currentMonth}
              percentage={
                (franchiseData.sales.modules?.currentMonth /
                  franchiseData.sales.modules?.lastMonth -
                  1) *
                100
              }
              isPositive={
                franchiseData.sales.modules?.currentMonth >
                franchiseData.sales.modules?.lastMonth
              }
              money={false}
            />

            <Card
              title="Inversores vendidos"
              lastValue={43}
              currentValue={87}
              percentage={(87 / 43 - 1) * 100}
              isPositive={true}
              money={false}
            />
            <Card
              title="Bombas d`água"
              currentValue={0}
              isPositive={true}
              money={false}
            />
            <Card
              title="Carregadores"
              currentValue={0}
              isPositive={true}
              money={false}
            />
          </SoldItems>
        </SectionContainer>

        {UserRoles.FRANQUEADO.includes(user.role) && <Table />}
      </Container>
    );
  }

  return (
    <Container>
      {/* <SectionContainer>
        <Title>Selecione um Fornecedor</Title>

        <SelectAsync
          className="select-input"
          label="Fornecedor"
          placeholder="Selecionar opção"
          getOptions={() => new Promise((resolve) => resolve([]))}
        />
      </SectionContainer> */}

      <GeneralData role={user.role} />

      {/* <Leads /> */}

      <RankingContainer>
        <RankingWrapper>
          <Card
            title="Vendas em negociação"
            lastValue={franchiseData.sales.generation?.lastMonth * 2}
            currentValue={franchiseData.sales.generation?.currentMonth * 2}
            percentage={
              (franchiseData.sales.generation?.currentMonth /
                franchiseData.sales.generation?.lastMonth -
                1) *
              100
            }
            isPositive={
              franchiseData.sales.generation?.currentMonth >
              franchiseData.sales.generation?.lastMonth
            }
            money={true}
          />
          <Card
            title="Geração média"
            lastValue={franchiseData.sales.generation_middle?.lastMonth}
            currentValue={franchiseData.sales.generation_middle?.currentMonth}
            percentage={
              (franchiseData.sales.generation_middle?.currentMonth /
                franchiseData.sales.generation_middle?.lastMonth -
                1) *
              100
            }
            isPositive={
              franchiseData.sales.generation_middle?.currentMonth >
              franchiseData.sales.generation_middle?.lastMonth
            }
            sufix="kWp"
          />
          <Card
            title="Média de mão de obra"
            lastValue={franchiseData.sales.labor_middle?.lastMonth}
            currentValue={franchiseData.sales.labor_middle?.currentMonth}
            percentage={
              (franchiseData.sales.labor_middle?.currentMonth /
                franchiseData.sales.labor_middle?.lastMonth -
                1) *
              100
            }
            isPositive={
              franchiseData.sales.labor_middle?.currentMonth >
              franchiseData.sales.labor_middle?.lastMonth
            }
          />
          <Card
            title="Royalties"
            lastValue={franchiseData.sales.royalties?.lastMonth}
            currentValue={franchiseData.sales.royalties?.currentMonth}
            percentage={
              (franchiseData.sales.royalties?.currentMonth /
                franchiseData.sales.royalties?.lastMonth -
                1) *
              100
            }
            isPositive={
              franchiseData.sales.royalties?.currentMonth >
              franchiseData.sales.royalties?.lastMonth
            }
          />
          <Card
            title="Ticket médio de produtos"
            lastValue={franchiseData.sales.ticket_products?.lastMonth}
            currentValue={franchiseData.sales.ticket_products?.currentMonth}
            percentage={
              (franchiseData.sales.ticket_products?.currentMonth /
                franchiseData.sales.ticket_products?.lastMonth -
                1) *
              100
            }
            isPositive={
              franchiseData.sales.ticket_products?.currentMonth >
              franchiseData.sales.ticket_products?.lastMonth
            }
          />
          <Card
            title="Produtos + mão de obra"
            lastValue={franchiseData.sales.ticket_products_labor?.lastMonth}
            currentValue={
              franchiseData.sales.ticket_products_labor?.currentMonth
            }
            percentage={
              (franchiseData.sales.ticket_products_labor?.currentMonth /
                franchiseData.sales.ticket_products_labor?.lastMonth -
                1) *
              100
            }
            isPositive={
              franchiseData.sales.ticket_products_labor?.currentMonth >
              franchiseData.sales.ticket_products_labor?.lastMonth
            }
          />
        </RankingWrapper>

        <Ranking role={user.role} />
      </RankingContainer>

      <MonthlyHistory />

      <SectionContainer>
        <Title>Itens vendidos</Title>

        <SoldItems ref={soldItemsRef.ref}>
          <Card
            title="Itens vendidos"
            lastValue={franchiseData.sales.item.lastMonth}
            currentValue={franchiseData.sales.item.currentMonth}
            percentage={
              (franchiseData.sales.item.currentMonth /
                franchiseData.sales.item.lastMonth -
                1) *
              100
            }
            isPositive={
              franchiseData.sales.item.currentMonth >
              franchiseData.sales.item.lastMonth
            }
            money={false}
          />
          <Card
            title="Geração em negociação"
            lastValue={franchiseData.sales.sales_negotiation?.lastMonth * 2}
            currentValue={
              franchiseData.sales.sales_negotiation?.currentMonth * 2
            }
            percentage={
              (franchiseData.sales.sales_negotiation?.currentMonth /
                franchiseData.sales.sales_negotiation?.lastMonth -
                1) *
              100
            }
            isPositive={
              franchiseData.sales.sales_negotiation?.currentMonth >
              franchiseData.sales.sales_negotiation?.lastMonth
            }
            money={false}
            sufix=" kWp"
          />
          <Card
            title="Módulos vendidos"
            lastValue={franchiseData.sales.modules?.lastMonth}
            currentValue={franchiseData.sales.modules?.currentMonth}
            percentage={
              (franchiseData.sales.modules?.currentMonth /
                franchiseData.sales.modules?.lastMonth -
                1) *
              100
            }
            isPositive={
              franchiseData.sales.modules?.currentMonth >
              franchiseData.sales.modules?.lastMonth
            }
            money={false}
          />
          <Card
            title="Inversores vendidos"
            lastValue={43}
            currentValue={87}
            percentage={(87 / 43 - 1) * 100}
            isPositive={true}
            money={false}
          />
          <Card
            title="Bombas d`água"
            currentValue={0}
            isPositive={true}
            money={false}
          />
          <Card
            title="Carregadores"
            currentValue={0}
            isPositive={true}
            money={false}
          />
        </SoldItems>
      </SectionContainer>

      <Map />
    </Container>
  );
};

