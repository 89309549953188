import React from 'react';
import { DraftProduct } from '../../types/product';
import { LogContainerStyle, LogStyle } from './styles';

interface LogContainerProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  logs: Array<DraftProduct>;
}

export const LogContainer: React.FC<LogContainerProps> = ({ logs }) => {
  return (
    <LogContainerStyle>
      <h3>Produtos processados</h3>
      <ul>
        {logs.map((log, i) => {
          return (
            <LogStyle key={log.name + log.description + i}>
              {!log.hasError ? (
                <span>Produto {i + 1} processado com sucesso.</span>
              ) : (
                <span style={{ color: 'red' }}>
                  {`Não foi possível processar o Produto ${i + 1} ${
                    log.supplierCode || ''
                  }, verifique o arquivo.`}
                </span>
              )}
            </LogStyle>
          );
        })}
      </ul>
    </LogContainerStyle>
  );
};
