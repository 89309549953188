import styled from 'styled-components';

export const Container = styled.div`
  label {
    color: #292929;
  }
`;

export const Error = styled.div`
  color: red;
  font-weight: 500;
  margin-top: 6px;
`;
