import React from 'react';
import { RxDotFilled, RxTriangleUp } from 'react-icons/rx';
import { Container, IndicatorStatus } from './styles';

type IndicatorProps = {
  status: IndicatorStatus;
};

export const Indicator: React.FC<IndicatorProps> = (props) => {
  const { status } = props;

  return (
    <Container status={status}>
      {status === 'neutral' ? <RxDotFilled /> : <RxTriangleUp />}
    </Container>
  );
};
