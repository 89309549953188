import { format } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import React, { useEffect, useState } from 'react';
import { FiEdit, FiPlusCircle, FiX } from 'react-icons/fi';
import { useScrollContainer } from 'react-indiana-drag-scroll';
import { Props } from 'react-modal';
import { useHistory } from 'react-router-dom';
import { ShapeModal } from '../..';
import { useSalesFunnel } from '../../../../contexts/sales-funnel';
import { Lead, STATES_UF } from '../../../../contexts/sales-funnel/types';
import crmApi from '../../../../services/crm';
import Loading from '../../../loading';
import { Button } from '../components/button';
import {
  Aside,
  Buttons,
  Container,
  Content,
  Customer,
  Data,
  Header,
  Kit,
  Messages,
  Reason,
  Wrapper,
} from './styles';
import { Tags } from '../components/tags';
import { LeadModal } from '../lead-modal';

type LostModalProps = {
  onCancel: () => void;
  content: Lead | null;
  isOpen: boolean;
} & Props;

type LeadDetails = {
  reason: string;
  description: string;
};

export const LostModal: React.FC<LostModalProps> = (props) => {
  const { onCancel, content, isOpen, ...rest } = props;

  const history = useHistory();

  const scroll = useScrollContainer<HTMLElement>();

  const {
    loading,
    leadBudgetItems,
    leadModal,
    getLeadBudgetItems,
    clearLeadBudgetItems,
    handleCRMData,
    handleLeadModal,
  } = useSalesFunnel();

  const [lead, setLead] = useState<LeadDetails | null>(null);

  const getLostLeadDetails = async () => {
    try {
      const { data } = await crmApi.get<LeadDetails>(
        `/leads/lost-details/${content?.id}`
      );

      setLead(data);
    } catch (error) {
      console.error(error);

      throw error;
    }
  };

  const createBudget = () => {
    handleCRMData(content);

    history.push('/orcamentos/selecionar-produto/cadastro-cliente');
  };

  useEffect(() => {
    if (isOpen) {
      if (content?.budgetId) {
        getLeadBudgetItems(content.budgetId);
      }

      getLostLeadDetails();
    } else {
      clearLeadBudgetItems();
      setLead(null);
    }
  }, [isOpen]);

  if (loading.leadBudgetItems) {
    return (
      <ShapeModal
        isOpen={isOpen}
        padding="0"
        maxWidth="60rem"
        onRequestClose={onCancel}
        {...rest}
      >
        <Loading />
      </ShapeModal>
    );
  }

  return (
    <ShapeModal
      isOpen={isOpen}
      padding="0"
      maxWidth="60rem"
      onRequestClose={onCancel}
      {...rest}
    >
      <Container>
        <Header>
          <h1>Perdido</h1>

          <button onClick={onCancel} type="button">
            <FiX />
          </button>
        </Header>

        <Content>
          <Tags lead={content} />
          <Wrapper>
            <Customer>
              <div>
                <header>
                  <h1>Dados do cliente</h1>

                  <Button
                    variant="white"
                    type="button"
                    maxWidth="7.313rem"
                    onClick={() => handleLeadModal(content)}
                  >
                    <FiEdit />
                    Editar
                  </Button>
                </header>

                <div>
                  <strong>{content?.customer.name}</strong>
                  <p>
                    Tipo de instalação:
                    <strong>{content?.customer.sector}</strong>
                  </p>
                  <p>
                    Canal de origem:<strong>{content?.customer.origin}</strong>
                  </p>
                  <p>
                    Vendedor:
                    <strong>{content?.franchiseUser?.user.name}</strong>
                  </p>
                </div>
              </div>
              <div>
                <header>
                  <h1>Endereço do cliente</h1>
                </header>

                {content?.customer.address && (
                  <div>
                    <p>
                      {content?.customer.address},{' '}
                      {content?.customer.addressNumber}
                    </p>

                    <p>
                      {content?.customer.district} - {content?.customer.city} -{' '}
                      {
                        STATES_UF[
                          content?.customer.state as keyof typeof STATES_UF
                        ]
                      }
                    </p>

                    <p>
                      CEP: <strong>{content?.customer.zipcode}</strong>
                    </p>
                  </div>
                )}

                {!content?.customer.address && (
                  <div>
                    <p>Nenhum endereço cadastrado.</p>
                  </div>
                )}
              </div>
            </Customer>

            <Aside>
              <Data>
                <header>
                  <h1>Dados do orçamento</h1>
                </header>
                {!!leadBudgetItems?.length && (
                  <main ref={scroll.ref}>
                    {leadBudgetItems.map((item) => {
                      return (
                        <Kit key={item.id}>
                          <h1>
                            {item.quantity}x {item.product?.supplier} -{' '}
                            {item.product?.category}W
                          </h1>
                          <strong>{item.formatted.total}</strong>
                          <span>
                            {item.product?.power} KWp | {item.product?.voltage}V
                          </span>
                          <span>{item.product?.solarPlatesNumber} módulos</span>
                        </Kit>
                      );
                    })}
                  </main>
                )}

                <Messages>
                  <p>
                    Data de criação:
                    <strong>
                      {' '}
                      {content?.createdAt &&
                        format(
                          new Date(content.createdAt),
                          "dd 'de' MMMM 'de' yyyy",
                          {
                            locale: ptBR,
                          }
                        )}
                    </strong>
                  </p>

                  <Reason>
                    <h1>{lead?.reason}</h1>

                    <p>{lead?.description}</p>
                  </Reason>
                </Messages>
              </Data>
              <Buttons>
                <Button
                  variant="blue"
                  type="button"
                  maxWidth="100%"
                  onClick={createBudget}
                >
                  <FiPlusCircle />
                  Novo orçamento
                </Button>
              </Buttons>
            </Aside>
          </Wrapper>
        </Content>
      </Container>

      <LeadModal
        isOpen={leadModal.isOpen}
        lead={leadModal.data}
        onCancel={() => {
          handleLeadModal(null);
          onCancel();
        }}
      />
    </ShapeModal>
  );
};
