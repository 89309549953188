import styled from 'styled-components';

export const Content = styled.main`
  height: 100%;

  display: flex;
  flex-direction: column;

  padding: 1.5rem 0;
`;

export const Documents = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4.25rem;

  margin-bottom: 2.5rem;

  & > div {
    display: flex;
    flex-direction: column;
    gap: 1.125rem;

    & > h1 {
      font-family: 'DunbarTextRegular', sans-serif;
      font-weight: 400;
      font-size: 1.25rem;

      letter-spacing: 0.02em;

      color: #27282b;
    }
  }
`;

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(16.125rem, 24.125rem));
  gap: 1.5rem;

  & button {
    border: 1px solid #c9cbcf;
  }

  & > ul {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    & > label {
      font-family: 'Montserrat', sans-serif;
      font-weight: 500;
      font-size: 1rem;

      letter-spacing: 0.01em;

      color: #27282b;
    }
  }

  @media (max-width: 992px) {
    grid-template-columns: 1fr;

    & button {
      width: 100%;
    }
  }
`;

export const Voucher = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2.625rem;

  height: 100%;

  & > h1 {
    font-family: 'DunbarTextRegular', sans-serif;
    font-weight: 400;
    font-size: 1.25rem;

    letter-spacing: 0.02em;

    color: #27282b;
  }

  & > div {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    gap: 1.125rem;

    & > p {
      font-family: 'DunbarTextRegular', sans-serif;

      font-weight: 400;
      font-size: 1rem;

      letter-spacing: 0.02em;

      color: #000000;

      flex: 0 1 48rem;
    }

    & > button {
      color: #ffffff;

      & > svg {
        font-size: 1.25rem;
      }

      @media (max-width: 992px) {
        width: 100%;
      }
    }
  }
`;
