export const productTypes = {
  'kit-pronto': 'default',
  'kit-personalizado': 'custom',
};

export const sanitizedProductTypes = {
  'kit-pronto': { singular: 'Kit Pronto', plural: 'Kits Prontos' },
  'kit-personalizado': {
    singular: 'Kit Personalizado',
    plural: 'Kits Personalizados',
  },
};

export type Product = {
  id: string;
  supplier: string;
  power: string;
  category: string;
  fabricMaterial: string;
  name: string;
  supplierCode: string;
  promotional: boolean;
  description: string;
  solarPlatesNumber: number;
  voltage: number;
  phase: string;
  inverterType: string;
  inverterLv: boolean;
  weight: number;
  metreage: number;
  originalPrice: number;
  price: number;
  discount: number;
  rebate: number;
  type: string;
  deliveryTime: string;
  productType: string;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date;
};

export type DraftProduct = Product & { hasError: boolean };
