export const statusOptions = [
  {
    label: 'Implantação',
    value: 'Implantação',
  },
  {
    label: 'Ativo',
    value: 'Ativo',
  },
  {
    label: 'Cobrança Extrajudicial',
    value: 'Cobrança Extrajudicial',
  },
  {
    label: 'Processo Judicial',
    value: 'Processo Judicial',
  },
  {
    label: 'Negociação Rescisão',
    value: 'Negociação Rescisão',
  },
  {
    label: 'Rescisão Infração',
    value: 'Recisão Infração',
  },
  {
    label: 'Encerrado/Inativo',
    value: 'Encerrado/Inativo',
  },
  {
    label: 'Distrato',
    value: 'Distrato',
  },
];

export const modalityOptions = [
  {
    label: 'Home',
    value: 'Home',
  },
  {
    label: 'Store',
    value: 'Store',
  },
  {
    label: 'Store in Store',
    value: 'Store in Store',
  },
];
