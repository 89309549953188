import styled from 'styled-components';

export const ButtonContainer = styled.div`
  margin-top: 16px;
  display: flex;
  justify-content: flex-end;

  div {
    margin-left: 16px;
  }
`;

export const PaymentContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 16px;

  span {
    display: inline-block;
    margin-left: 30px;
    min-width: 200px;
  }
`;

export const PaymentButtonContainer = styled.div`
  max-width: 200px;
  margin-top: 16px;
`;

export const PaymentIcon = styled.div`
  display: flex;
  align-items: center;

  span.icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    background-color: #9fa1a8;
    border-radius: 50%;
  }

  span.title {
    margin-left: 8px;
  }
`;
