import styled from 'styled-components';

type ContainerProps = {
  menuOpen: boolean;
  isDisabled: boolean;
};

export const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  font-weight: 500;
  font-size: 1rem;

  color: #27282b;

  & > span.message {
    display: flex;
    align-items: center;
    gap: 0.375rem;

    font-weight: 500;
    font-size: 0.875rem;

    color: #61646b;
  }
`;
