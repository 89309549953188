import { yupResolver } from '@hookform/resolvers/yup';
import { AxiosError } from 'axios';
import cep from 'cep-promise';
import { cnpj, cpf } from 'cpf-cnpj-validator';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { withHookFormMask } from 'use-mask-input';
import * as yup from 'yup';
import {
  BRFlag,
  Breadcrumb,
  Button,
  StepProgress,
} from '../../../../components';
import Input from '../../../../components/input';
import InputPhone from '../../../../components/input-phone';
import InputPrice from '../../../../components/input-price';
import MaskInput from '../../../../components/mask-input';
import { EditBudgetConfirmationModal } from '../../../../components/modal/edit-budget-confirmation';
import { InfoProfitabilityModal } from '../../../../components/modal/info-profitability';
import RadioGroup from '../../../../components/radio-group';
import SelectInput, { Option } from '../../../../components/select';
import SelectAsync from '../../../../components/select-async';
import { useAuth } from '../../../../contexts/auth';
import { useBudgets } from '../../../../contexts/budgets';
import { Budget, Customer } from '../../../../contexts/budgets/types';
import { useSalesFunnel } from '../../../../contexts/sales-funnel';
import { CRMData } from '../../../../contexts/sales-funnel/types';
import { Profitability } from '../../../../layouts/default/default';
import productsApi from '../../../../services/products';
import projectsApi from '../../../../services/projects';
import { ButtonGroup, Container, PageHeader } from '../../../styles';
import { Content, Divisor, FormContainer } from './styles';

type Dealership = {
  id: string;
  name: string;
};

export type BudgetForm = {
  name: string;
  email: string;
  documentType: string;
  cnpj: string;
  cpf: string;
  phone: string;
  zipCode: string;
  street: string;
  number: string;
  complement: string;
  neighborhood: string;
  city: string;
  state: string;
  voltage: Option;
  phase: Option;
  dealership: Option;
  fabricMaterial: Option;
  orientation: Option;
  customerConsumption: number;
  kwhCost: string;
};

const radioOptions = [
  {
    label: 'CPF',
    value: 'cpf',
  },
  {
    label: 'CNPJ',
    value: 'cnpj',
  },
];

const voltageOptions = [
  {
    label: '127/220V',
    value: 220,
  },
  {
    label: '220/380V',
    value: 380,
  },
];

const phaseOptions = [
  {
    label: 'Monofásico',
    value: 'Monofásico',
  },
  {
    label: 'Bifásico',
    value: 'Bifásico',
  },
  {
    label: 'Trifásico',
    value: 'Trifásico',
  },
];

const orientationOptions = [
  {
    label: 'Norte',
    value: 'NORTE',
  },
  {
    label: 'Sul',
    value: 'SUL',
  },
  {
    label: 'Leste',
    value: 'LESTE',
  },
  {
    label: 'Oeste',
    value: 'OESTE',
  },
];

const fabricMaterialOptions = [
  {
    label: 'Cerâmico',
    value: 'Cerâmico',
  },
  {
    label: 'Laje',
    value: 'Laje',
  },
  {
    label: 'Solo',
    value: 'Solo',
  },
  {
    label: 'Metálico',
    value: 'Metálico',
  },
  {
    label: 'Mini Metálico',
    value: 'Mini Metálico',
  },
  {
    label: 'Fibrocimento',
    value: 'Fibrocimento',
  },
  {
    label: 'Fibrocimento Metálico',
    value: 'Fibrocimento Metal',
  },
  {
    label: 'Carregador Veicular',
    value: 'Carregador Veicular',
  },
];

const steps = [
  { key: 1, title: 'Selecionar produto', active: false, complete: true },
  { key: 2, title: 'Cadastro do cliente', active: true, complete: false },
  { key: 3, title: 'Selecionar Kits', active: false, complete: false },
  { key: 4, title: 'Serviços', active: false, complete: false },
  { key: 5, title: 'Proposta', active: false, complete: false },
];

const schema = yup.object().shape(
  {
    name: yup.string().required('Campo obrigatório'),
    email: yup
      .string()
      .email('Deve ser um email válido')
      .required('Campo obrigatório'),
    cnpj: yup.string().when('cpf', {
      is: (cpf: string) => !cpf || cpf.length === 0,
      then: yup
        .string()
        .required('Campo obrigatório')
        .test('cnpj', 'CNPJ inválido', (value) => cnpj.isValid(String(value)))
        .min(18, 'Campo obrigatório'),
      otherwise: yup.string(),
    }),
    cpf: yup.string().when('cnpj', {
      is: (cnpj: string) => !cnpj || cnpj.length === 0,
      then: yup
        .string()
        .required('Campo obrigatório')
        .test('cpf', 'CPF inválido', (value) => cpf.isValid(String(value)))
        .min(14, 'Campo obrigatório'),
      otherwise: yup.string(),
    }),
    phone: yup
      .string()
      .required('Campo obrigatório')
      .min(14, 'Campo obrigatório'),
    zipCode: yup
      .string()
      .required('Campo obrigatório')
      .min(8, 'Campo obrigatório'),
    street: yup.string().required('Campo obrigatório'),
    number: yup.string().required('Campo obrigatório'),
    complement: yup.string().optional(),
    neighborhood: yup.string().required('Campo obrigatório'),
    city: yup.string().required('Campo obrigatório'),
    state: yup.string().required('Campo obrigatório'),
    voltage: yup.object().required('Campo obrigatório'),
    phase: yup.object().required('Campo obrigatório'),
    dealership: yup.object().required('Campo obrigatório'),
    fabricMaterial: yup.object().required('Campo obrigatório'),
    orientation: yup.object().required('Campo obrigatório'),
    customerConsumption: yup
      .number()
      .min(1, 'Consumo médio mensal precisa ser maior que 0')
      .required('Campo obrigatório')
      .typeError('Campo obrigatório'),
    kwhCost: yup
      .string()
      .required('Campo obrigatório')
      .typeError('Campo obrigatório'),
  },
  [['cnpj', 'cpf']]
);

enum STATES_UF {
  AC = 'Acre',
  AL = 'Alagoas',
  AP = 'Amapá',
  AM = 'Amazonas',
  BA = 'Bahia',
  CE = 'Ceará',
  DF = 'Distrito Federal',
  ES = 'Espírito Santo',
  GO = 'Goiás',
  MA = 'Maranhão',
  MT = 'Mato Grosso',
  MS = 'Mato Grosso do Sul',
  MG = 'Minas Gerais',
  PA = 'Pará',
  PB = 'Paraíba',
  PR = 'Paraná',
  PE = 'Pernambuco',
  PI = 'Piauí',
  RJ = 'Rio de Janeiro',
  RN = 'Rio Grande do Norte',
  RS = 'Rio Grande do Sul',
  RO = 'Rondônia',
  RR = 'Roraima',
  SC = 'Santa Catarina',
  SP = 'São Paulo',
  SE = 'Sergipe',
  TO = 'Tocantins',
}

const BudgetsCustomerRegistrationPage: React.FC = () => {
  const history = useHistory();

  const { pathname } = history.location;

  const { id } = useParams<{ id?: string }>();

  const hasBudgetId = id !== 'undefined' && id;

  const hasEditPath = pathname.includes('editar');

  const links = [
    {
      id: 1,
      title: 'Orçamentos',
      link: '/orcamentos',
      active: false,
    },
    {
      id: 2,
      title: 'Selecionar produto',
      link: '/orcamentos/selecionar-produto',
      active: false,
    },
    {
      id: 3,
      title: 'Cadastro do cliente',
      link: hasEditPath
        ? `/orcamentos/selecionar-produto/cadastro-cliente/${id}/editar`
        : `/orcamentos/selecionar-produto/cadastro-cliente`,
      active: true,
    },
  ];

  const { crmData } = useSalesFunnel();

  const {
    budget,
    handleBudget,
    getBudget,
    cleanSelectedKits,
    cleanServices,
    cleanBudgetFlow,
  } = useBudgets();

  const { selectedFranchise, franchises } = useAuth();

  const {
    control,
    watch,
    register,
    setValue,
    getValues,
    formState: { errors, isValid },
    handleSubmit,
    setError,
    clearErrors,
    reset,
    trigger,
  } = useForm<BudgetForm>({
    resolver: yupResolver(schema),
    defaultValues: { documentType: 'cpf' },
    mode: 'onChange',
  });

  const [lastDocument, setLastDocument] = useState('');

  const documentType = watch('documentType');

  const customerConsumption = watch('customerConsumption');

  const [hasChanges, setHasChanges] = useState(false);

  const handleCep = async (cepValue: string) => {
    if (cepValue) {
      await cep(cepValue)
        .then((response) => {
          const normalizedState =
            STATES_UF[response.state as keyof typeof STATES_UF];

          setValue('street', response.street, { shouldValidate: true });
          setValue('neighborhood', response.neighborhood, {
            shouldValidate: true,
          });
          setValue('city', response.city, { shouldValidate: true });
          setValue('state', normalizedState, { shouldValidate: true });

          clearErrors('zipCode');
        })
        .catch(() => {
          setValue('street', '');
          setValue('neighborhood', '');
          setValue('city', '');
          setValue('state', '');

          setError('zipCode', { message: 'CEP não encontrado' });
        });
    }
  };

  const checkHasChanges = (currentData: BudgetForm, oldData: Budget) => {
    const currentChanges = {
      orientation: currentData.orientation.value,
      consumption: currentData.customerConsumption,
      kwhCost: currentData.kwhCost.replace(',', '.'),
      phase: currentData.phase.value,
      fabricMaterial: currentData.fabricMaterial.value,
    };

    const oldChanges = {
      orientation: oldData.orientation,
      consumption: oldData.consumption,
      kwhCost: currentData.kwhCost.replace(',', '.'),
      phase: oldData.phase,
      fabricMaterial: oldData.fabricMaterial,
    };

    const hasChanged =
      JSON.stringify(currentChanges) !== JSON.stringify(oldChanges);

    return hasChanged;
  };

  const updateBudget = async (data: BudgetForm) => {
    if (!budget) {
      return;
    }

    try {
      const normalizedBudget = {
        customer: {
          id: budget?.customer.id,
          name: data.name,
          document: data.cnpj || data.cpf,
          documentType: data.documentType,
          email: data.email,
          phone: data.phone,
        },
        address: {
          zipCode: data.zipCode,
          street: data.street,
          number: data.number,
          neighborhood: data.neighborhood,
          city: data.city,
          state: data.state,
          complement: data.complement || undefined,
        },
        phase: data.phase.value,
        voltage: String(data.voltage.value),
        orientation: data.orientation.value,
        consumption: data.customerConsumption,
        fabricMaterial: data.fabricMaterial.value,
        kwhCost: data.kwhCost.replace(',', '.'),
        dealership: {
          id: data.dealership.value,
          name: data.dealership.label,
        },
        hideInstalationKit: budget?.hideInstalationKit || false,
        observations: budget?.observations || undefined,
      };

      const response = await productsApi.put<Budget>(
        `/budgets/${id}`,
        normalizedBudget
      );

      handleBudget(response.data);

      cleanSelectedKits();

      cleanServices();

      history.push(
        `/orcamentos/selecionar-produto/cadastro-cliente/${response.data.id}/selecao-de-kits/editar`
      );
    } catch (error) {
      const validationError = (error as AxiosError).response?.data.validation;

      if (validationError) {
        toast.error(validationError.body.message);
      } else {
        toast.error('Desculpe, não foi possível editar as informações');
      }

      throw error;
    } finally {
      setHasChanges(false);
    }
  };

  const createBudget = async (data: BudgetForm) => {
    if (!selectedFranchise) {
      toast.info('Selecione uma franquia');

      return;
    }

    try {
      const normalizedBudget = {
        name: data.name,
        document: data.cnpj || data.cpf,
        documentType: data.documentType,
        email: data.email,
        phone: data.phone,
        zipCode: data.zipCode,
        street: data.street,
        number: String(data.number),
        neighborhood: data.neighborhood,
        complement: data.complement || undefined,
        city: data.city,
        state: data.state,
        orientation: String(data.orientation.value),
        consumption: data.customerConsumption,
        voltage: Number(data.voltage.value),
        phase: String(data.phase.value),
        fabricMaterial: String(data.fabricMaterial.value),
        kwhCost: Number(data.kwhCost.replace(',', '.')),
        dealership: {
          id: String(data.dealership.value),
          name: data.dealership.label,
        },
      };

      const response = await productsApi.post<Budget>('/budgets', {
        ...normalizedBudget,
        status: 'Incompleto',
        franchiseId: selectedFranchise,
      });

      handleBudget(response.data);

      history.push(
        `/orcamentos/selecionar-produto/cadastro-cliente/${response.data.id}/selecao-de-kits`
      );
    } catch (error) {
      const validationError = (error as AxiosError).response?.data.validation;

      if (validationError) {
        toast.error(validationError.body.message);
      } else {
        toast.error('Desculpe, não foi possível efetuar o cadastro');
      }

      throw error;
    }
  };

  const onSubmit = async (data: BudgetForm) => {
    if (!budget) {
      await createBudget(data);

      return;
    }

    if (checkHasChanges(data, budget)) {
      setHasChanges(true);
    } else {
      await updateBudget(data);
    }
  };

  const getDealershipsOptions = async (inputValue: string) => {
    const { data } = await projectsApi.get('/api/projects/power-company/list');

    const formattedData: Option[] = data.map((dealership: Dealership) => {
      return {
        label: dealership.name,
        value: dealership.id,
      };
    });

    const filteredOptions: Option[] = formattedData.filter((dealership) =>
      dealership.label.toLowerCase().includes(inputValue.toLowerCase())
    );

    return filteredOptions;
  };

  const clearAddress = () => {
    reset({
      name: '',
      email: '',
      phone: '',
      zipCode: '',
      street: '',
      state: '',
      city: '',
      neighborhood: '',
      complement: '',
      number: '',

      documentType: getValues('documentType'),
      cnpj: getValues('cnpj'),
      cpf: getValues('cpf'),

      orientation: getValues('orientation'),
      customerConsumption: getValues('customerConsumption'),
      dealership: getValues('dealership'),
      kwhCost: getValues('kwhCost'),
      phase: getValues('phase'),
      fabricMaterial: getValues('fabricMaterial'),
      voltage: getValues('voltage'),
    });
  };

  const getCustomerByDocument = async (document: string) => {
    const normalizedDocument = document?.replace(/-|\.|\//gm, '');

    productsApi
      .get<Customer>('/customers/' + normalizedDocument)
      .then(({ data }) => {
        const lastAddress = data.addresses.slice(-1)[0];

        reset({
          name: data.name,
          email: data.email,
          phone: data.phone,
          zipCode: lastAddress.zipCode,
          street: lastAddress.street,
          state: lastAddress.state,
          city: lastAddress.city,
          neighborhood: lastAddress.neighborhood,
          complement: lastAddress.complement ?? '',
          number: String(lastAddress.number),

          documentType: getValues('documentType'),
          cnpj: getValues('cnpj'),
          cpf: getValues('cpf'),

          orientation: getValues('orientation'),
          customerConsumption: getValues('customerConsumption'),
          dealership: getValues('dealership'),
          kwhCost: getValues('kwhCost'),
          phase: getValues('phase'),
          fabricMaterial: getValues('fabricMaterial'),
          voltage: getValues('voltage'),
        });
      })
      .catch(() => {
        clearAddress();
      });
  };

  const resetToBudgetData = (data: Budget) => {
    reset({
      name: data.customer.name,
      email: data.customer.email,
      phone: data.customer.phone,
      documentType: data.customer.documentType,
      cpf: data.customer.documentType === 'cpf' ? data.customer.document : '',
      cnpj: data.customer.documentType === 'cnpj' ? data.customer.document : '',
      zipCode: data.address.zipCode,
      street: data.address.street,
      state: data.address.state,
      city: data.address.city,
      neighborhood: data.address.neighborhood,
      complement: data.address.complement ?? '',
      number: String(data.address.number),
      customerConsumption: data.consumption,
      kwhCost: String(data.kwhCost),
      dealership: {
        label: data.dealership.name,
        value: data.dealership.id,
      },
      fabricMaterial: fabricMaterialOptions.find(
        (option) => option.value === data.fabricMaterial
      ),
      orientation: orientationOptions.find(
        (option) => option.value === data.orientation
      ),
      voltage: voltageOptions.find((option) => option.value === data.voltage),
      phase: phaseOptions.find((option) => option.value === data.phase),
    });
  };

  const resetToCRMData = (data: CRMData) => {
    reset({
      name: data.name,
      email: data.email,
      phone: data.phone,
      documentType: data.documentType,
      cpf: data.documentType === 'cpf' ? data.cpf : '',
      cnpj: data.documentType === 'cnpj' ? data.cnpj : '',
      zipCode: data.zipCode,
      street: data.street,
      state: data.state,
      city: data.city,
      neighborhood: data.neighborhood,
      complement: data.complement ?? '',
      number: String(data.number),
    });
  };

  const [profitabilityModal, setProfitabilityModal] = useState({
    isOpen: false,
    isAdmin: false,
  });

  const getProfitability = async (
    franchiseId: string
  ): Promise<Profitability> => {
    try {
      const { data } = await productsApi.get<Profitability>(
        '/budgets/profitability',
        {
          params: {
            franchiseId,
          },
        }
      );

      return data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const handleProfitabilityConfigMessage = async () => {
    try {
      if (!selectedFranchise) {
        toast.info('Selecione uma franquia');
        return;
      }

      const franchise = franchises.find(
        (franchise) =>
          franchise.id === selectedFranchise && franchise.franchise.enabled
      );

      if (!franchise) {
        return;
      }

      const profitability = await getProfitability(franchise.id);

      const notConfiguredFranchise =
        !profitability.invoiceValue || profitability.invoiceValue === 0;

      if (notConfiguredFranchise) {
        setProfitabilityModal({
          isOpen: true,
          isAdmin: franchise.role.includes('Administrador'),
        });
      }
    } catch (error) {
      console.error('Error handling profitability config message: ', error);
    }
  };

  useEffect(() => {
    handleProfitabilityConfigMessage();
  }, [selectedFranchise]);

  useEffect(() => {
    if (budget && id) {
      resetToBudgetData(budget);
    } else if (budget && !id) {
      cleanBudgetFlow();
    } else if (!budget && hasBudgetId) {
      getBudget(id);
    } else if (crmData) {
      resetToCRMData(crmData);
    }
  }, [budget, crmData, hasBudgetId, id]);

  return (
    <Container>
      <Breadcrumb links={links} />

      <PageHeader>
        <span>{'Cadastro do Cliente'}</span>
        <div>
          <StepProgress steps={steps} />
        </div>
      </PageHeader>

      <Content>
        <FormContainer onSubmit={handleSubmit(onSubmit)} noValidate>
          <h1>Dados do cliente</h1>

          <div className="inputs-wrapper">
            <div className="radio-wrapper">
              <Controller
                control={control}
                name="documentType"
                render={({
                  field: { onChange, value, ref },
                  fieldState: { error },
                }) => {
                  return (
                    <RadioGroup
                      options={radioOptions}
                      onChange={(event) => {
                        onChange(event.target.value);

                        setValue('cnpj', '');
                        setValue('cpf', '');
                        setLastDocument('');

                        clearAddress();
                      }}
                      value={value}
                      ref={ref}
                      error={error?.message}
                    />
                  );
                }}
              />

              {documentType === 'cpf' && (
                <MaskInput
                  label="CPF"
                  placeholder="999.999.999-99"
                  mask="999.999.999-99"
                  style={{ width: '16.125rem' }}
                  required
                  {...register('cpf', {
                    onChange: (event: ChangeEvent<HTMLInputElement>) => {
                      const value = event.target.value;

                      if (value.length === 14 && value !== lastDocument) {
                        setLastDocument(value);

                        getCustomerByDocument(value);

                        return;
                      }
                    },
                    onBlur: async () => await trigger('cpf'),
                  })}
                  error={errors.cpf?.message}
                />
              )}
              {documentType === 'cnpj' && (
                <MaskInput
                  label="CNPJ"
                  mask="99.999.999/9999-99"
                  placeholder="99.999.999/9999-99"
                  style={{ width: '16.125rem' }}
                  required
                  {...register('cnpj', {
                    onChange: (event: ChangeEvent<HTMLInputElement>) => {
                      const value = event.target.value;

                      if (value.length === 18 && value !== lastDocument) {
                        setLastDocument(value);

                        getCustomerByDocument(value);

                        return;
                      }
                    },
                    onBlur: async () => await trigger('cnpj'),
                  })}
                  error={errors.cnpj?.message}
                />
              )}
            </div>

            <Input
              label="Nome"
              type="text"
              placeholder="Informe o nome"
              style={{ width: '24rem' }}
              required
              {...register('name')}
              error={errors.name?.message}
            />

            <Input
              label="Email"
              type="email"
              placeholder="Informe o e-mail"
              style={{ width: '22rem' }}
              required
              {...register('email')}
              error={errors.email?.message}
            />
          </div>

          <div className="inputs-wrapper">
            <InputPhone
              mask=""
              label="Telefone"
              placeholder="Digite seu número"
              iconLeft={<BRFlag />}
              style={{ width: '16.125rem' }}
              required
              {...withHookFormMask(
                register('phone'),
                ['(99) 9999-9999', '(99) 99999-9999'],
                {
                  showMaskOnFocus: false,
                  showMaskOnHover: false,
                  placeholder: '',
                  jitMasking: true,
                }
              )}
              error={errors.phone?.message}
            />

            <MaskInput
              label="CEP"
              mask="99999-999"
              placeholder="Informe o CEP"
              style={{ width: '10.25rem' }}
              required
              {...register('zipCode', {
                onBlur: async (e) => await handleCep(e.target.value),
              })}
              error={errors.zipCode?.message}
            />

            <Input
              label="Localidade"
              type="text"
              style={{ width: '27.875rem' }}
              placeholder="Informe a rua ou logradouro"
              required
              {...register('street')}
              error={errors.street?.message}
            />

            <MaskInput
              label="Número"
              placeholder="0000"
              style={{ width: '10.25rem' }}
              required
              mask="99999"
              {...register('number')}
              error={errors.number?.message}
            />
          </div>

          <div className="inputs-wrapper">
            <Input
              label="Complemento"
              type="text"
              placeholder="Informe o complemento"
              style={{ width: '16.125rem' }}
              {...register('complement')}
              error={errors.complement?.message}
            />

            <Input
              label="Bairro"
              type="text"
              placeholder="Informe o bairro"
              style={{ width: '16.125rem' }}
              required
              {...register('neighborhood')}
              error={errors.neighborhood?.message}
            />

            <Input
              label="Cidade"
              type="text"
              placeholder="Informe a cidade"
              style={{ width: '16.125rem' }}
              required
              {...register('city')}
              error={errors.city?.message}
              disabled
            />

            <Input
              label="Estado"
              type="text"
              placeholder="Informe o estado"
              style={{ width: '16.125rem' }}
              required
              {...register('state')}
              error={errors.state?.message}
              disabled
            />
          </div>

          <Divisor />
          <h1>Dados da unidade</h1>

          <div className="inputs-wrapper">
            <Controller
              control={control}
              name="voltage"
              render={({
                field: { onChange, value, ref },
                fieldState: { error },
              }) => {
                return (
                  <SelectInput
                    ref={ref}
                    label="Tensão"
                    isSearchable={false}
                    placeholder="Selecione uma opção"
                    options={voltageOptions}
                    style={{ width: '22rem' }}
                    required
                    error={error?.message}
                    onChange={onChange}
                    value={value}
                  />
                );
              }}
            />

            <Controller
              control={control}
              name="phase"
              render={({
                field: { onChange, value, ref },
                fieldState: { error },
              }) => {
                return (
                  <SelectInput
                    ref={ref}
                    label="Fase"
                    isSearchable={false}
                    placeholder="Selecione uma opção"
                    options={phaseOptions}
                    style={{ width: '22rem' }}
                    required
                    error={error?.message}
                    onChange={onChange}
                    value={value}
                  />
                );
              }}
            />

            <Controller
              control={control}
              name="dealership"
              render={({
                field: { onChange, value, ref },
                fieldState: { error },
              }) => {
                return (
                  <SelectAsync
                    ref={ref}
                    label="Concessionária"
                    placeholder="Selecione uma opção"
                    getOptions={(inputValue) =>
                      getDealershipsOptions(inputValue)
                    }
                    style={{ width: '22rem' }}
                    required
                    error={error?.message}
                    onChange={onChange}
                    value={value}
                  />
                );
              }}
            />
          </div>

          <div className="inputs-wrapper">
            <Controller
              control={control}
              name="fabricMaterial"
              render={({
                field: { onChange, value, ref },
                fieldState: { error },
              }) => {
                return (
                  <SelectInput
                    ref={ref}
                    label="Tipo de telhado"
                    isSearchable={false}
                    placeholder="Selecione uma opção"
                    options={fabricMaterialOptions}
                    style={{ width: '16.125rem' }}
                    required
                    error={error?.message}
                    onChange={onChange}
                    value={value}
                  />
                );
              }}
            />

            <Controller
              control={control}
              name="orientation"
              render={({
                field: { onChange, value, ref },
                fieldState: { error },
              }) => {
                return (
                  <SelectInput
                    ref={ref}
                    label="Orientação"
                    isSearchable={false}
                    placeholder="Selecione uma opção"
                    options={orientationOptions}
                    style={{ width: '16.125rem' }}
                    required
                    error={error?.message}
                    onChange={onChange}
                    value={value}
                  />
                );
              }}
            />

            <Input
              label="Consumo médio mensal(KWh)"
              type="number"
              placeholder="1000"
              style={{ width: '16.125rem' }}
              iconRight={customerConsumption >= 1 && 'KWh'}
              required
              error={errors.customerConsumption?.message}
              {...register('customerConsumption')}
            />

            <Controller
              control={control}
              name="kwhCost"
              render={({
                field: { onChange, value, ref },
                fieldState: { error },
              }) => {
                return (
                  <InputPrice
                    ref={ref}
                    label="Custo do KWh"
                    placeholder="R$ 9.999,99"
                    style={{ width: '16.125rem' }}
                    required
                    error={error?.message}
                    onValueChange={onChange}
                    value={value}
                  />
                );
              }}
            />
          </div>
          <ButtonGroup>
            <Button
              type="button"
              text="Voltar"
              typeStyle="default"
              backgroundHoverColor="#C9CBCF"
              onClick={() => history.push('/orcamentos/selecionar-produto')}
            />
            <Button type="submit" text="Avançar" disabled={!isValid} />
          </ButtonGroup>
        </FormContainer>
      </Content>

      <EditBudgetConfirmationModal
        isOpen={hasChanges}
        onConfirm={async () => await updateBudget(getValues())}
        onCancel={() => setHasChanges(false)}
      />

      <InfoProfitabilityModal
        isOpen={profitabilityModal.isOpen}
        isAdmin={profitabilityModal.isAdmin}
        onConfirm={() => {
          history.push('/orcamentos/configurar-lucratividade');

          setProfitabilityModal((state) => ({ ...state, isOpen: false }));
        }}
        onCancel={() => {
          history.push('/');

          setProfitabilityModal((state) => ({ ...state, isOpen: false }));
        }}
      />
    </Container>
  );
};

export default BudgetsCustomerRegistrationPage;
