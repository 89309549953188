import styled, { css } from 'styled-components';

type InputTypes = {
  error: boolean;
};

export const Container = styled.div`
  width: 100%;

  label {
    display: inline-block;

    margin-bottom: 0.5rem;

    font-size: 1rem;
    font-weight: 500;
    color: #27282b;
  }
`;
export const Error = styled.div`
  color: #e01919;
  font-weight: 500;
  margin-top: 0.5rem;
`;

export const InputText = styled.input<InputTypes>`
  width: 100%;
  height: 3rem;
  background: #ffffff;
  border: 1px solid #c9cbcf;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 0 1rem;

  ${(props) =>
    props.error &&
    css`
      border: 1px solid #e01919;
    `}
`;
