import React, { useState } from 'react';
import { FiBell } from 'react-icons/fi';
import { RiAddCircleLine, RiInboxArchiveLine } from 'react-icons/ri';
import { Tag } from '../Tag';
import { NotificationsSidebar } from './NotificationSidebar';

import { LeadModal } from '../../../../components/modal/sales-funnel/lead-modal';
import useMediaQuery from '../../../../hooks/mediaQuery';
import { Container, Notification } from './styles';
import { useHistory } from 'react-router-dom';
import { useSalesFunnel } from '../../../../contexts/sales-funnel';

export const FloatMenu: React.FC = () => {
  const { leadModal, handleLeadModal } = useSalesFunnel();

  const history = useHistory();

  const [notificationModalOpen, setNotificationModalOpen] = useState(false);

  const [notifications, setNotifications] = useState(1);

  const isTablet = useMediaQuery('(max-width: 992px)');

  const handleOpenSidebar = () => {
    setNotificationModalOpen(!notificationModalOpen);
    setNotifications(0);
  };

  return (
    <Container>
      <Tag
        version="gray"
        spacing="m"
        width="12.5rem"
        isMobile={isTablet}
        onClick={() => history.push('/orcamentos/orcamentos-e-pedidos')}
      >
        <RiInboxArchiveLine fontSize={26} />
        <span>Meus pedidos</span>
      </Tag>
      <Tag
        version="blue"
        spacing="m"
        width="12.5rem"
        isMobile={isTablet}
        onClick={() => handleLeadModal()}
      >
        <RiAddCircleLine fontSize={26} />
        <span>Novo lead</span>
      </Tag>
      {/* <Tag version="blue" size="3rem" isCircular onClick={handleOpenSidebar}>
        <FiBell fontSize={26} />
        {notifications ? <Notification>{notifications}</Notification> : null}
      </Tag> */}

      <NotificationsSidebar
        isOpen={notificationModalOpen}
        onClose={() => setNotificationModalOpen(false)}
        data={[
          {
            id: '1',
            date: '20 de março de 2020 - 09:10',
            movedTo: 'finalProposal',
            name: 'Vanessa Alves',
          },
          {
            id: '2',
            date: '14 de março de 2020 - 09:10',
            movedTo: 'lost',
            name: 'Vanessa Alves',
          },
          {
            id: '3',
            date: '05 de fevereiro de 2020 - 09:10',
            movedTo: 'recovery',
            name: 'Vanessa Alves',
          },
        ]}
      />

      <LeadModal isOpen={leadModal.isOpen} onCancel={handleLeadModal} />
    </Container>
  );
};
