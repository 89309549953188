import styled, { css } from 'styled-components';

type StepProps = {
  isActive?: boolean;
  isComplete?: boolean;
};

type StepLine = {
  isComplete?: boolean;
  isLast?: boolean;
};

export const StepProgressContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Step = styled.span<StepProps>`
  display: flex;
  justify-content: center;
  align-items: center;

  min-width: 2rem;
  height: 2rem;

  padding: 0;

  border: 2px solid #000000;
  border-radius: 20px;

  font-weight: 600;
  font-size: 1rem;
  text-align: center;

  color: #000000;

  position: relative;

  & > svg {
    width: 1.25rem;
    height: 1.25rem;
  }

  .step-title-mobile {
    display: none;
  }

  ${({ isActive }) =>
    isActive &&
    css`
      height: fit-content;
      background-color: #000000;
      color: #ffffff;

      padding: 0.25rem 1rem;
    `}

  ${({ isComplete }) =>
    isComplete &&
    css`
      background-color: #1474ff;

      border: 0;
    `} 
    @media (max-width: 992px) {
    .step-title-mobile {
      display: inline-block;
    }

    .step-title {
      display: none;
    }
  }
`;

export const StepLine = styled.span<StepLine>`
  width: 2.5rem;
  height: 0.125rem;

  background-color: ${({ isComplete }) => (isComplete ? '#1474ff' : '#000000')};

  margin: 0 0.5rem;

  ${({ isLast }) =>
    isLast &&
    css`
      display: none;
    `}
`;
