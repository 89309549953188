import { yupResolver } from '@hookform/resolvers/yup';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { FiTrash2 } from 'react-icons/fi';
import Modal from 'react-modal';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import {
  ActionButton,
  Breadcrumb,
  Button,
  CloseIcon,
  SearchInput,
  Status,
} from '../../../components';
import Input from '../../../components/input';
import SelectInput, { Option } from '../../../components/select';
import Table, { ColumnStructure, TableData } from '../../../components/table';
import InnerActions from '../../../components/table/innerActions';
import { useAuth } from '../../../contexts/auth';
import franchisesApi from '../../../services/franchises';
import usersApi from '../../../services/users';
import { UserType } from '../../users/types';
import { FranchiseUserListType, UserRoles } from '../types';
import { Container, PageHeader, TableHeader } from './../../styles';
import {
  InputContainer,
  ModalContainer,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from './styles';
import { UserRoles as UserRolesSystem } from '../../../types/users';

type FranchiseUsersPageProps = {
  id: string;
};

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '16px',
  },
};

interface FormTypes {
  email: string;
  role: Option;
}

type TableItems = {
  id: string;
  name: string;
  email: string;
  role: string;
  enabled: JSX.Element;
  actions: JSX.Element;
};

const FranchiseUsersPage: React.FC = () => {
  const { id } = useParams<FranchiseUsersPageProps>();

  const formRef = useRef<FormHandles>(null);

  const [modalIsOpen, setIsOpen] = useState(false);

  const [users, setUsers] = useState<FranchiseUserListType>({
    content: [],
    pagination: {
      currentPage: 0,
      lastPage: 0,
      limit: 0,
      total: 0,
    },
  });

  const [page, setPage] = useState(1);

  const [limit, setLimit] = useState(10);

  const [name, setName] = useState<string>('');

  const [loading, setLoading] = useState(false);

  const { updateUserFranchises } = useAuth();

  const handlePageChange = (page: number): void => {
    setPage(page);
  };

  const handleLimitChange = (limit: number): void => {
    setLimit(limit);
  };

  const handleSearchChange = (search: string): void => {
    setName(search);
  };

  const links = [
    {
      id: 1,
      title: 'Franquias',
      link: '/franquias',
      active: false,
    },
    {
      id: 2,
      title: 'Lista de franquias',
      link: '/franquias',
      active: false,
    },
    {
      id: 3,
      title: 'Usuários',
      link: `/franquia/${id}/membros`,
      active: true,
    },
  ];

  const schema = yup.object().shape({
    role: yup.object().nullable().required('Cargo é um campo obrigatório'),
    email: yup.string().email().required('E-mail é um campo obrigatório'),
  });

  const {
    handleSubmit,
    formState: { errors },
    control,
    register,
    reset,
  } = useForm<FormTypes>({ resolver: yupResolver(schema) });

  const loadUsers = () => {
    setLoading(true);

    franchisesApi
      .get<FranchiseUserListType>(
        `/api/franchises/${id}/member?page=${page}&limit=${limit}&name=${name}`
      )
      .then((res) => {
        setUsers(res.data);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const deleteFranchiseMember = (franchiseId: string, memberId: string) => {
    franchisesApi
      .delete(`/api/franchises/${franchiseId}/member/${memberId}`)
      .then(() => {
        loadUsers();

        updateUserFranchises(memberId, id);
      });
  };

  const getUserIdByEmail = async (email: string) => {
    return usersApi
      .get<UserType>(`/api/users/by-email/${email}`)
      .then((response) => {
        const userId = response.data.id;

        return userId;
      })
      .catch((error) => {
        toast.error(error.response.data.message);

        return false;
      });
  };

  const onSubmit = async (data: FormTypes) => {
    const userId = await getUserIdByEmail(data.email);

    if (userId) {
      franchisesApi
        .post(`/api/franchises/${id}/member`, {
          userId,
          role: data.role.value,
        })
        .then(() => {
          toast.success('Usuário adicionado com sucesso');

          loadUsers();

          setIsOpen(false);

          updateUserFranchises(userId as string);

          reset();
        })
        .catch((error) => {
          toast.error(error.response.data.message);
        });
    }
  };

  const handleCloseModal = () => {
    reset();

    setIsOpen(false);
  };

  const tableColumns: ColumnStructure[] = [
    {
      id: 'name',
      label: 'Nome',
      onClick: () => {},
    },
    {
      id: 'email',
      label: 'E-mail',
      onClick: () => {},
    },
    {
      id: 'role',
      label: 'Tipo',
      onClick: () => {},
    },
    {
      id: 'enabled',
      label: 'Status',
      onClick: () => {},
    },
    {
      id: 'actions',
      label: 'Ações',
      type: 'actionCell',
    },
  ];

  const { user } = useAuth();

  const isManage = [UserRolesSystem.GERENCIA].includes(user.role);

  const tableItems: TableData<TableItems>[] = useMemo(() => {
    return users.content.map((user) => {
      return {
        id: user.id,
        name: user.user.name,
        email: user.user.email,
        role: user.role,
        enabled: <Status text={user.user.enabled ? 'Ativo' : 'Desativado'} />,
        actions: (
          <InnerActions>
            {!isManage && (
              <ActionButton
                onClick={() => deleteFranchiseMember(id, user.userId)}
                tooltip="Deletar"
              >
                <FiTrash2 className="delete-icon" />
              </ActionButton>
            )}
          </InnerActions>
        ),
      };
    });
  }, [users]);

  useEffect(() => {
    loadUsers();
  }, [page, limit, name]);

  return (
    <Container>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={handleCloseModal}
        contentLabel="Example Modal"
        style={customStyles}
      >
        <ModalContainer>
          <Form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
            <ModalHeader>
              <ActionButton onClick={handleCloseModal}>
                <CloseIcon />
              </ActionButton>
            </ModalHeader>
            <ModalContent>
              <h1>Adicionar usuário em uma franquia</h1>
              <InputContainer>
                <Input
                  placeholder="E-mail"
                  label="E-mail"
                  error={errors.email?.message}
                  {...register('email')}
                />
              </InputContainer>
              <InputContainer>
                <Controller
                  name="role"
                  control={control}
                  render={({
                    field: { onChange, value, ref },
                    fieldState: { error },
                  }) => {
                    return (
                      <SelectInput
                        ref={ref}
                        name="role"
                        onChange={onChange}
                        value={value}
                        placeholder="Selecione um cargo"
                        options={UserRoles}
                        error={error?.message}
                        label="Cargo"
                      />
                    );
                  }}
                />
              </InputContainer>
            </ModalContent>
            <ModalFooter>
              <Button type="submit" text="Adicionar" typeStyle="confirm" />
            </ModalFooter>
          </Form>
        </ModalContainer>
      </Modal>
      <Breadcrumb links={links} />
      <PageHeader>
        <span>Lista de usuários</span>
      </PageHeader>

      <Table
        items={tableItems}
        columns={tableColumns}
        isLoading={loading}
        header={
          <TableHeader>
            <SearchInput
              name="search"
              placeholder="Digite aqui para pesquisar"
              onTextChange={handleSearchChange}
            />
            <Button
              text="Novo usuário"
              icon="add"
              onClick={() => setIsOpen(true)}
            />
          </TableHeader>
        }
        placeholder="Nenhum usuário encontrado"
        pagination={users.pagination}
        onPageChange={handlePageChange}
        onLimitChange={handleLimitChange}
      />
    </Container>
  );
};

export default FranchiseUsersPage;
