import React, { useEffect, useRef, useState } from 'react';
import {
  Button,
  CloseButton,
  Container,
  DropdownContainer,
  DropdownContent,
  DropdownHeader,
} from './styles';
import { useOnClickOutside } from '../../../../../hooks/outsideClick';
import { CloseIcon, FilterIcon } from '../../../../../components/icons';
import { DateRange } from './DateRange';
import { Range } from 'react-date-range';
import { Tags } from './Tags';
import { Sellers } from './Sellers';
import { UserRoles } from '../../../../../types/users';
import { useAuth } from '../../../../../contexts/auth';

export type Option = {
  label: string;
  value: string;
};

export type FilterOption = {
  label: string;
  options: Option[];
};

type DateType = {
  startDate: string;
  endDate: string;
};

type FilterProps = {
  onChange: (tags: string[], userId: string, date: DateType) => void;
};

export const Filters: React.FC<FilterProps> = (props: FilterProps) => {
  const { onChange } = props;

  const { user } = useAuth();

  const isAdminOrFranchised = [UserRoles.FRANQUEADO, UserRoles.ADMIN].includes(
    user.role
  );

  const [isOpen, setIsOpen] = useState(false);

  const ref = useRef<HTMLDivElement>(null);

  useOnClickOutside(ref, () => setIsOpen(false));

  const [dates, setDates] = useState<Range[]>([
    {
      startDate: new Date(new Date().setDate(new Date().getDate() - 30)),
      endDate: new Date(),
      key: 'selection',
    },
  ]);

  const formatDate = (date?: Date) => {
    if (!date) {
      return '';
    }

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');

    return `${year}/${month}/${day}`;
  };

  const normalizedDate = {
    startDate: formatDate(dates[0]?.startDate),
    endDate: formatDate(dates[0]?.endDate),
  };

  const handleDateRange = (range: Range[]) => setDates(range);

  const [selectedTags, setSelectedTags] = useState<string[]>([]);

  const handleSelectTag = (tagId: string) =>
    setSelectedTags((state) => {
      const existsCurrentTag = state.includes(tagId);

      if (existsCurrentTag) {
        return state.filter((currentTagId) => currentTagId !== tagId);
      }

      return [...state, tagId];
    });

  const [selectedSeller, setSelectedSeller] = useState('');

  const handleSelectSeller = (sellerId: string) => {
    setSelectedSeller((state) => {
      const existsCurrentSeller = state.includes(sellerId);

      if (existsCurrentSeller) {
        return '';
      }

      return sellerId;
    });
  };

  useEffect(() => {
    onChange(selectedTags, selectedSeller, normalizedDate);
  }, [selectedTags, selectedSeller, dates]);

  return (
    <Container ref={ref}>
      <Button onClick={() => setIsOpen(!isOpen)}>
        <FilterIcon />
        Filtros
      </Button>
      <DropdownContainer isOpen={isOpen}>
        <DropdownHeader>
          <span>Filtros</span>

          <CloseButton onClick={() => setIsOpen(false)}>
            <CloseIcon />
          </CloseButton>
        </DropdownHeader>
        <DropdownContent>
          <h1>Etiquetas</h1>

          <Tags
            selectedTags={selectedTags}
            onChange={handleSelectTag}
            onRefresh={setSelectedTags}
          />

          <h1>Data de criação</h1>

          <DateRange
            dates={dates}
            onChange={(item) => {
              handleDateRange([item.selection]);
            }}
          />

          {isAdminOrFranchised && (
            <>
              <h1>Vendedor</h1>

              <Sellers
                selectedSeller={selectedSeller}
                onChange={handleSelectSeller}
              />
            </>
          )}
        </DropdownContent>
      </DropdownContainer>
    </Container>
  );
};
