import React from 'react';

import { AuthProvider } from './auth';
import { BudgetsProvider } from './budgets';
import { DashboardProvider } from './dashboard';
import { OrdersProvider } from './orders';
import { SalesFunnelProvider } from './sales-funnel';

const AppProvider: React.FC = ({ children }) => {
  return (
    <AuthProvider>
      <DashboardProvider>
        <SalesFunnelProvider>
          <BudgetsProvider>
            <OrdersProvider>{children}</OrdersProvider>
          </BudgetsProvider>
        </SalesFunnelProvider>
      </DashboardProvider>
    </AuthProvider>
  );
};

export default AppProvider;
