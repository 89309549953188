import React, { InputHTMLAttributes } from 'react';
import {
  Container,
  ButtonBackground,
  InputContainer,
  InputText,
  Error,
} from './styles';

import searchIcon from '../../assets/icon/search.svg';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  onTextChange: (text: string) => void;
  name: string;
  label?: string;
  error?: string;
}

export const SearchInput: React.FC<InputProps> = ({
  onTextChange,
  name,
  label,
  error,
  ...rest
}) => {
  return (
    <Container>
      <InputContainer>
        {label && <label htmlFor={name}>{label}</label>}
        <InputText
          id={name}
          name={name}
          type="text"
          error={error ? true : false}
          onChange={(e) => onTextChange(e.target.value)}
          {...rest}
        />
        {error && <Error>{error}</Error>}
      </InputContainer>
      <ButtonBackground>
        <img src={searchIcon} />
      </ButtonBackground>
    </Container>
  );
};
