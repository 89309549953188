import React, { useRef } from 'react';

import { useScrollContainer } from 'react-indiana-drag-scroll';

import { ChartContainer, Container, Indicator } from './styles';

import {
  Chart as ChartJS,
  Title as ChartTitle,
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
  Legend,
  ChartData,
  ChartOptions,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  ChartTitle,
  Tooltip,
  Legend,
  ChartDataLabels
);

type Props = {
  current: number[];
  last: number[];
  percentage: string[];
};

export const BarChart: React.FC<Props> = (props) => {
  const { current, last, percentage } = props;

  const indicatorsContainerRef = useRef<HTMLDivElement>(null);

  const chartContainerRef = useScrollContainer<HTMLDivElement>({
    mouseScroll: {
      overscroll: true,
      ignoreElements: '.undraggable',
    },
  });

  const chartData: ChartData<'bar'> = {
    labels: [
      'Jan',
      'Fev',
      'Mar',
      'Abr',
      'Mai',
      'Jun',
      'Jul',
      'Ago',
      'Set',
      'Out',
      'Nov',
      'Dez',
    ],
    datasets: [
      {
        grouped: true,
        data: last.map((item, index) => [current[index] ?? 0, item]),
        barThickness: 0,
      },
      {
        grouped: true,
        data: last.map((item, index) => current[index] ?? 0.01),
        backgroundColor: '#00299B',
        borderRadius: 4,
        barThickness: 24,
      },
      {
        grouped: true,
        data: last,
        backgroundColor: '#F95300',
        borderRadius: 4,
        barThickness: 24,
      },
      {
        grouped: true,
        data: last.map((item, index) => [current[index] ?? 0, item]),
        barThickness: 0,
      },
    ],
  };

  const options: ChartOptions<'bar'> = {
    responsive: true,
    maintainAspectRatio: false,

    plugins: {
      subtitle: {
        display: true,
      },
      tooltip: {
        enabled: false,
      },
      legend: {
        display: false,
      },
      datalabels: {
        offset: -50,
        align: 'start',
        anchor: 'end',

        display(context) {
          if (context.datasetIndex === 0 || context.datasetIndex === 3) {
            return true;
          }
          return false;
        },
        formatter: (value, context) => {
          if (context.datasetIndex === 3) {
            return (value[0] / 1000000).toFixed(1) + 'M';
          }
          return '\n' + (value[1] / 1000000).toFixed(1) + 'M';
        },
        color(context) {
          if (context.datasetIndex === 3) {
            return '#00299B';
          }
          return '#F95300';
        },
        font(context) {
          if (context.datasetIndex === 3)
            return {
              family: 'DunbarTallBold',
              weight: 500,
              size: 24,
            };
          return {
            family: 'DunbarTextRegular',
            weight: 400,
            size: 16,
            lineHeight: '24px',
          };
        },
      },
    },
    scales: {
      y: {
        display: false,
      },
      x: {
        grid: {
          display: false,
        },

        ticks: {
          font: {
            family: 'DunbarTextRegular',
            weight: '400',
            size: 16,
          },
          maxRotation: 0,
          color: '#27282B',
          autoSkip: false,

          callback(tickValue, tickIndex, ticks) {
            if (current[tickIndex - 1]) {
              const indicatorContainer = indicatorsContainerRef.current;

              if (indicatorContainer?.hasChildNodes) {
                Array.from(indicatorContainer.children).map(
                  (indicator, index) => {
                    const indicatorPosition =
                      (this.getPixelForValue(ticks[index + 1].value) -
                        this.getPixelForValue(ticks[index].value)) /
                        2 +
                      this.getPixelForValue(ticks[index].value) -
                      24;

                    indicator.setAttribute(
                      'style',
                      `left: ${indicatorPosition}px;`
                    );
                  }
                );
              }
            }

            return this.getLabelForValue(tickValue as number);
          },
        },
      },
    },
    layout: {
      padding: {
        top: 70,
      },
    },
  };

  return (
    <Container ref={chartContainerRef.ref}>
      <ChartContainer>
        <Bar options={options} data={chartData} />
        <div ref={indicatorsContainerRef}>
          {percentage.map((item) => (
            <Indicator key={item} isPositive={!item.includes('-')}>
              {!item.includes('-') ? `+${item}` : item}%
            </Indicator>
          ))}
        </div>
      </ChartContainer>
    </Container>
  );
};
