import React, { useEffect, useRef, useState } from 'react';
import { CheckboxIcon, CloseIcon, FilterIcon } from '../';
import { useOnClickOutside } from '../../hooks/outsideClick';
import {
  Button,
  Checkbox,
  CloseButton,
  Container,
  DropdownContainer,
  DropdownContent,
  DropdownHeader,
} from './styles';

export type Option = {
  label: string;
  value: string | boolean;
};

export type FilterOption = {
  label: string;
  options: Option[];
};

type FilterProps = {
  filters: FilterOption[];
  onChange: (option: Option[]) => void;
};

const DynamicFilter: React.FC<FilterProps> = (props) => {
  const { filters, onChange } = props;

  const [isOpen, setIsOpen] = useState(false);

  const isMountedRef = useRef(false);

  const [checked, setChecked] = useState<Option[]>([]);

  const currentFilters = filters.map((option) => option.options).flat();

  const filterRef = useRef<HTMLDivElement>(null);

  const handleCheckboxChange = (selectedOption: Option) => {
    setChecked((state) => {
      const hasExistingOption = state.some(
        (option) => option.value === selectedOption.value
      );

      if (hasExistingOption) {
        return state.filter((option) => option.value !== selectedOption.value);
      }

      return [...state, selectedOption];
    });
  };

  useOnClickOutside(filterRef, () => setIsOpen(false));

  const someFilterNotExist = checked.some(
    (option) => !currentFilters.some((filter) => filter.value === option.value)
  );

  useEffect(() => {
    if (isMountedRef.current) {
      onChange(checked);
    } else {
      isMountedRef.current = true;
    }
  }, [checked]);

  useEffect(() => {
    if (someFilterNotExist) {
      setChecked([]);
    }
  }, [filters]);

  return (
    <Container ref={filterRef}>
      <Button onClick={() => setIsOpen(!isOpen)}>
        <FilterIcon />
        Filtros
      </Button>
      <DropdownContainer isOpen={isOpen}>
        <DropdownHeader>
          <span>Filtros</span>
          <CloseButton onClick={() => setIsOpen(false)}>
            <CloseIcon />
          </CloseButton>
        </DropdownHeader>
        <DropdownContent>
          {filters.map((filter) => {
            return (
              <div key={filter.label}>
                <span>{filter.label}</span>
                {filter.options.map((option) => {
                  const isChecked = !!checked.find(
                    (optionSelected) => optionSelected.value === option.value
                  );

                  return (
                    <Checkbox
                      key={String(option.value)}
                      htmlFor={String(option.value)}
                      isChecked={isChecked}
                    >
                      <span>{isChecked ? <CheckboxIcon /> : null}</span>
                      <input
                        type="checkbox"
                        name={String(option.value)}
                        id={String(option.value)}
                        onChange={() => {
                          handleCheckboxChange({
                            label: filter.label,
                            value: option.value,
                          });
                        }}
                        value={String(option.value)}
                      />
                      {option.label}
                    </Checkbox>
                  );
                })}
              </div>
            );
          })}
        </DropdownContent>
      </DropdownContainer>
    </Container>
  );
};

export default DynamicFilter;
