import React, { useState } from 'react';
import { Container } from './styles';

const PercentageInput: React.FC<
  React.InputHTMLAttributes<HTMLInputElement>
> = ({ ...rest }) => {
  const [isActive, setIsActive] = useState(false);

  return (
    <Container
      isActive={isActive}
      onFocus={() => setIsActive(true)}
      onBlur={() => setIsActive(false)}
    >
      <input type="number" {...rest} />
      <span>%</span>
    </Container>
  );
};

export default PercentageInput;
