import styled, { css } from 'styled-components';
import { Status } from '.';

type ContainerProps = {
  status: Status;
};

const statusColor = {
  'A processar': css`
    background: #f5f5f6;
    color: #61646b;

    border: 1px solid #c9cbcf;
  `,
  'Pedido incompleto': css`
    background: #e01919;

    & > svg {
      fill: #ffffff;
      color: #e01919;
    }
  `,
  'Enviado para CP': css`
    background: #00e1ff;
    color: #27282b;
  `,
  'Enviado para Fornecedor': css`
    background: #cca300;
  `,
  'Link do cartão': css`
    background: #cca300;
  `,
  'Aguardando pagamento': css`
    background: #cca300;
  `,
  'NFE emitida': css`
    background: #1a7605;

    & > svg {
      fill: #ffffff;
      color: #005af9;
    }
  `,
  'Pedido cancelado': css`
    background: #e01919;

    & > svg {
      fill: #ffffff;
      color: #e01919;
    }
  `,
  'Aguardando NFE': css`
    background: #005af9;

    & > svg {
      fill: #ffffff;
      color: #005af9;
    }
  `,
  'Boleto/Espelho': css`
    background: #cca300;
  `,
  'NFF/Espelho': css`
    background: #cca300;
  `,
  'Pedido processado': css`
    background: #00e1ff;
    color: #27282b;
  `,
  'Pagamento Efetuado': css`
    background: #005af9;

    & > svg {
      fill: #ffffff;
      color: #005af9;
    }
  `,
};

export const Container = styled.span<ContainerProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;

  height: 2rem;

  padding: 8px;

  border-radius: 16px;

  font-weight: 500;
  font-size: 0.875rem;

  color: #ffffff;

  ${({ status }) => statusColor[status]};
`;
