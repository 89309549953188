import React from 'react';
import 'react-loading-skeleton/dist/skeleton.css';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ThemeProvider } from 'styled-components';
import AppProvider from './contexts/index';
import Routes from './routes';
import { GlobalStyles } from './styles/global';
import { energyLightTheme } from './styles/themes';

const App: React.FC = () => {
  return (
    <ThemeProvider theme={energyLightTheme}>
      <BrowserRouter>
        <AppProvider>
          <Routes />
        </AppProvider>

        <GlobalStyles />
        <ToastContainer
          style={{
            position: 'fixed',
            zIndex: 9999999,
          }}
        />
      </BrowserRouter>
    </ThemeProvider>
  );
};

export default App;
