import * as yup from 'yup';

export const projectSchema = yup.object().shape({
  powerCompanyId: yup
    .string()
    .required('Concessionária é um campo obrigatório'),
  personType: yup.string().required('Tipo de pessoa é um campo obrigatório'),
  accessType: yup.string().required('Tipo de acesso é um campo obrigatório'),
  requesterName: yup.string().required('Nome é um campo obrigatório'),
  requesterPhone: yup.string().required('Telefone é um campo obrigatório'),
  requesterEmail: yup.string().required('E-mail é um campo obrigatório'),
  ucCode: yup
    .string()
    .required('Código de unidade consumidora é um campo obrigatório'),
  ucClass: yup.string().required('Classe é um campo obrigatório'),
  ucOwner: yup.string().required('Nome é um campo obrigatório'),
  ucDocument: yup.string().required('CPF/CNPJ é um campo obrigatório'),
  ucZipcode: yup.string().required('CEP é um campo obrigatório'),
  ucState: yup.string().required('Estado é um campo obrigatório'),
  ucCity: yup.string().required('Cidade é um campo obrigatório'),
  ucAddress: yup.string().required('Endereço é um campo obrigatório'),
  ucNumber: yup.string().required('Número é um campo obrigatório'),
  ucPhone: yup.string().required('Telefone é um campo obrigatório'),
  ucEmail: yup
    .string()
    .email('E-mail fornecido é inválido')
    .required('E-mail é um campo obrigatório'),
  ucNeutralPhaseVoltage: yup
    .string()
    .required('Tensão de atendimento é um campo obrigatório'),
  ucConnectionType: yup
    .string()
    .required('Tipo de conexão é um campo obrigatório'),
  ucBranch: yup.string().required('Ramo é um campo obrigatório'),
  ucInstalledGenerationPower: yup
    .string()
    .required('Potência instalada de geração é um campo obrigatório'),
  ucGenerationSource: yup
    .string()
    .required('Tipo da fonte de geração é um campo obrigatório'),
  ucLatitude: yup.string().required('Latitude é um campo obrigatório'),
  ucLongitude: yup.string().required('Longitude é um campo obrigatório'),
});
