import { AxiosError } from 'axios';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FiEye } from 'react-icons/fi';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  ActionButton,
  Breadcrumb,
  Button,
  StepProgress,
} from '../../../../components';
import Checkbox from '../../../../components/checkbox';
import DynamicFilter, {
  FilterOption,
  Option,
} from '../../../../components/dynamic-filter';
import Loading from '../../../../components/loading';
import { KitDescriptionModal } from '../../../../components/modal/kit-description';
import Table, {
  ColumnStructure,
  TableData,
} from '../../../../components/table';
import InnerActions from '../../../../components/table/innerActions';
import { useAuth } from '../../../../contexts/auth';
import { useBudgets } from '../../../../contexts/budgets';
import { BudgetItem, Kit } from '../../../../contexts/budgets/types';
import productsApi from '../../../../services/products';
import { EMPTY_PAGE, PaginatedResponse } from '../../../../types/pagination';
import { toMoneyFormat } from '../../../../utils/toMoneyFormat';
import { ButtonGroup, Container, PageHeader } from '../../../styles';
import {
  CalculatedKitsContainer,
  CalculatedKitsHeader,
  CalculatedKitsTable,
  CalculatedKitsTableHeader,
  Content,
  PromotionalKit,
  PromotionalKitName,
  PromotionalKitPrice,
  PromotionalKitWrapper,
  PromotionalKitsContainer,
} from './styles';

const steps = [
  { key: 1, title: 'Selecionar produto', active: false, complete: true },
  { key: 2, title: 'Cadastro do cliente', active: false, complete: true },
  { key: 3, title: 'Selecionar Kits', active: true, complete: false },
  { key: 4, title: 'Serviços', active: false, complete: false },
  { key: 5, title: 'Proposta', active: false, complete: false },
];

type Filter = {
  selecteds: Filters | null;
  options: FilterOption[];
};

type Filters = {
  category: string[];
  phase: string[];
  supplier: string[];
  type: string[];
  voltage: string[];
  inverterLv: string[];
  inverterType: string[];
};

enum FiltersLabels {
  category = 'Categoria',
  phase = 'Fase',
  supplier = 'Fornecedor',
  type = 'Tipo',
  voltage = 'Tensão do inversor',
  inverter_lv = 'Inversor LV',
  inverter_type = 'Tipo de Inversor',
}

type CellErrorMessage = {
  id: string;
  label: string[];
};

type TableItems = {
  id: string;
  checkbox: JSX.Element;
  generation: string;
  supplier: string;
  inverterType: string;
  power: string;
  category: string;
  fabricMaterial: string;
  price: string;
  actions: JSX.Element;
  solarPlatesNumber: string;
};

const BudgetsSelectionKitPage: React.FC = () => {
  const { selectedFranchise: franchiseId } = useAuth();

  const history = useHistory();

  const { pathname } = history.location;

  const hasEditPath = pathname.includes('editar');

  const [loadings, setLoadings] = useState({
    default: false,
    table: false,
  });

  const [errorMessage, setErrorMessage] = useState<CellErrorMessage[]>([]);

  const { id } = useParams<{ id: string }>();

  const links = [
    {
      id: 1,
      title: 'Orçamentos',
      link: '/orcamentos',
      active: false,
    },
    {
      id: 2,
      title: 'Selecionar produto',
      link: '/orcamentos/selecionar-produto',
      active: false,
    },
    {
      id: 3,
      title: 'Cadastro do cliente',
      link: hasEditPath
        ? `/orcamentos/selecionar-produto/cadastro-cliente/${id}/editar`
        : `/orcamentos/selecionar-produto/cadastro-cliente`,
      active: false,
    },
    {
      id: 4,
      title: 'Seleção de kits',
      link: hasEditPath
        ? `/orcamentos/selecionar-produto/cadastro-cliente/${id}/selecao-de-kits/editar`
        : `/orcamentos/selecionar-produto/cadastro-cliente/${id}/selecao-de-kits/`,
      active: true,
    },
  ];

  const {
    budget,
    services,
    selectedKits,
    handleSelectKit,
    handleSelectAllKits,
    handleServices,
    handleProducts,
    getBudget,
  } = useBudgets();

  const [kits, setKits] = useState<PaginatedResponse<Kit>>(EMPTY_PAGE);

  const [promotionalKits, setPromotionalKits] =
    useState<PaginatedResponse<Kit>>(EMPTY_PAGE);

  const [kitDescription, setKitDescription] = useState<string | null>(null);

  const [filter, setFilter] = useState({
    options: [],
    selecteds: null,
  } as Filter);

  const hasKitDescription = !!kitDescription;

  const handleDescriptionModal = (description: string | null) => {
    setKitDescription(description);
  };

  const hasSelectedKits = !![
    ...selectedKits.kits,
    ...selectedKits.promotionalKits,
  ].length;

  const hasPromotionalKits = !!promotionalKits.content.length;

  const mapOptionToFilter = (
    option: Option
  ): [keyof Filters, string | boolean] => {
    switch (option.label) {
      case 'Categoria':
        return ['category', option.value];
      case 'Fornecedor':
        return ['supplier', option.value];
      case 'Tipo':
        return ['type', option.value];
      case 'Fase':
        return ['phase', option.value];
      case 'Tensão':
        return ['voltage', option.value];
      case 'Inversor LV':
        return ['inverterLv', option.value];
      case 'Tipo de Inversor':
        return ['inverterType', option.value];
      default:
        throw new Error(`Label não encontrada: ${option.label}`);
    }
  };

  const handleFilters = (options: Option[]) => {
    const selectedFilters: Filters = options.reduce(
      (acc, option) => {
        const [filterLabel, filterValue] = mapOptionToFilter(option);

        return {
          ...acc,
          [filterLabel]: [...acc[filterLabel], filterValue],
        };
      },
      {
        category: [],
        phase: [],
        supplier: [],
        type: [],
        voltage: [],
        inverterLv: [],
        inverterType: [],
      }
    );

    setFilter((state) => ({
      ...state,
      selecteds: selectedFilters,
    }));
  };

  const normalizeFilters = (options: Filters) => {
    const formatLabel: {
      [key in keyof typeof FiltersLabels]: (value: string) => string;
    } = {
      category: (value: string) => `${value}W`,
      type: (value: string) => {
        const translation: { [key: string]: string } = {
          default: 'Padrão',
        };
        return translation[value] || value;
      },
      voltage: (value: string) => `${value}V`,
      phase: (value: string) => value,
      supplier: (value: string) => value,
      inverter_lv: (value: string) => (value ? 'Sim' : 'Não'),
      inverter_type: (value: string) => value,
    };

    const normalizeOption = (
      field: keyof typeof FiltersLabels,
      value: string[]
    ) => {
      const label = FiltersLabels[field];

      const options = value
        .map((item) => {
          return {
            label: formatLabel[field](item),
            value: item,
          };
        })
        .sort((a, b) => a.label.localeCompare(b.label));

      return { label, options };
    };

    const normalizedFilters = Object.entries(options)
      .map(([field, value]) =>
        normalizeOption(field as keyof typeof FiltersLabels, value)
      )
      .sort((a, b) => {
        if (a.label === FiltersLabels.inverter_lv) {
          return 1;
        }

        if (a.label === FiltersLabels.inverter_type) {
          return b.label === FiltersLabels.inverter_lv ? -1 : 1;
        }

        return a.label.localeCompare(b.label);
      });

    return normalizedFilters;
  };

  const allKitsSelected =
    kits.content.every((_kit) =>
      selectedKits.kits.some((kit) => kit.id === _kit.id)
    ) &&
    !loadings.table &&
    selectedKits.kits.length > 0;

  const [orderBy, setOrderBy] = useState('');

  const tableColumns: ColumnStructure[] = useMemo(() => {
    const [column, order] = orderBy.split(',');

    return [
      {
        id: 'checkbox',
        label: 'Checkbox',
        type: 'actionCell',
        checkbox: (
          <Checkbox
            styles={{ padding: '1.2rem 2rem' }}
            name={'allCalculatedKitsCheck'}
            onChange={() => handleSelectAllKits(kits.content)}
            isChecked={allKitsSelected}
          />
        ),
      },
      {
        id: 'generation',
        label: 'Produção',
        orderBy: column === 'generation' ? order : 'DESC',
        onClick: () => handleOrderBy('generation'),
      },
      {
        id: 'solarPlatesNumber',
        label: 'Número de placas',
        orderBy: column === 'solarPlatesNumber' ? order : 'DESC',
        onClick: () => handleOrderBy('solarPlatesNumber'),
      },
      {
        id: 'supplier',
        label: 'Fornecedor',
        orderBy: column === 'supplier' ? order : 'DESC',
        onClick: () => handleOrderBy('supplier'),
      },
      {
        id: 'inverterType',
        label: 'Inversor',
        orderBy: column === 'inverterType' ? order : 'DESC',
        onClick: () => handleOrderBy('inverterType'),
      },
      {
        id: 'power',
        label: 'Potência',
        orderBy: column === 'power' ? order : 'DESC',
        onClick: () => handleOrderBy('power'),
      },
      {
        id: 'category',
        label: 'Categoria',
        orderBy: column === 'category' ? order : 'DESC',
        onClick: () => handleOrderBy('category'),
      },
      {
        id: 'fabricMaterial',
        label: 'Estrutura',
        orderBy: column === 'fabricMaterial' ? order : 'DESC',
        onClick: () => handleOrderBy('fabricMaterial'),
      },
      {
        id: 'price',
        label: 'Valor (R$)',
        orderBy: column === 'price' ? order : 'DESC',
        onClick: () => handleOrderBy('price'),
      },
      {
        id: 'actions',
        label: 'Ações',
        type: 'actionCell',
      },
    ];
  }, [kits, orderBy, allKitsSelected]);

  const handleOrderBy = (columnId: string) => {
    const currentColumn = tableColumns.find((column) => column.id === columnId);

    setOrderBy(
      String(
        currentColumn?.id +
          ',' +
          (currentColumn?.orderBy === 'DESC' ? 'ASC' : 'DESC')
      )
    );
  };

  const tableItems: TableData<TableItems>[] = useMemo(() => {
    const items: TableItems[] = kits.content.map((kit) => {
      const isChecked = selectedKits.kits.some(
        (selectedKit) => selectedKit.id === kit.id
      );

      const error = errorMessage.find((item) => item.id === kit.id);

      return {
        id: kit.id,
        error: error?.label.join(' ') ?? '',
        checkbox: (
          <Checkbox
            styles={{ padding: '1.2rem 2rem' }}
            option={kit}
            name={kit.id}
            onChange={(kit: Kit) => handleSelectKit(kit, kit.promotional)}
            isChecked={isChecked}
            value={JSON.stringify(selectedKits.kits)}
          />
        ),
        generation: kit.generation + 'KWh',
        solarPlatesNumber: kit.solarPlatesNumber + ' placas',
        supplier: kit.supplier,
        inverterType: kit.inverterType,
        power: kit.power + 'KWp',
        category: kit.category + 'W',
        fabricMaterial: kit.fabricMaterial,
        price: toMoneyFormat(kit.price),
        actions: (
          <InnerActions>
            <ActionButton
              tooltip="Visualizar"
              onClick={() => handleDescriptionModal(kit.description)}
            >
              <FiEye />
            </ActionButton>
          </InnerActions>
        ),
      };
    });

    const [column, order] = orderBy.split(',');

    const columnTable = column as keyof TableItems;

    const sortedItems = items.sort((a, b) => {
      const columnA = String(a[columnTable]);
      const columnB = String(b[columnTable]);

      return (
        columnA.localeCompare(columnB, 'pt-BR', {
          numeric: true,
          sensitivity: 'base',
        }) * (order === 'ASC' ? -1 : 1)
      );
    });

    return sortedItems;
  }, [kits, selectedKits, errorMessage, orderBy]);

  const validateKits = (kit: PaginatedResponse<Kit>) => {
    const normalizeData = (warnings: CellErrorMessage[]) => {
      const result: { [key: string]: CellErrorMessage } = {};

      warnings.forEach((item) => {
        if (result[item.id]) {
          result[item.id].label = [...result[item.id].label, ...item.label];
        } else {
          result[item.id] = { id: item.id, label: item.label };
        }
      });

      const formattedError = Object.values(result);

      return setErrorMessage((state) => [...state, ...formattedError]);
    };

    switch (budget?.phase) {
      case 'Monofásico':
      case 'Bifásico': {
        const entryWarning = kit.content
          .filter((item) => item.phase === 'Trifásico')
          .map((item) => ({
            id: item.id,
            label: [
              `Troca do padrão de entrada ${
                budget?.phase === 'Monofásico' ? 'monofásico' : 'bifásico'
              } para trifásico.`,
            ],
          }));

        const transformWarning = kit.content
          .filter(
            (item) =>
              (budget?.voltage === 220 &&
                item.phase === 'Trifásico' &&
                !item.inverterLv) ||
              (budget?.voltage === 380 &&
                item.phase === 'Trifásico' &&
                item.inverterLv)
          )
          .map((item) => {
            return {
              id: item.id,
              label: ['Necessário o uso de transformador.'],
            };
          });

        const allWarnings = [...entryWarning, ...transformWarning];
        return normalizeData(allWarnings);
      }

      case 'Trifásico': {
        const transformWarning = kit.content
          .filter(
            (item) =>
              (budget?.voltage === 220 &&
                item.phase === 'Trifásico' &&
                !item.inverterLv) ||
              (budget?.voltage === 380 &&
                item.phase === 'Trifásico' &&
                item.inverterLv)
          )
          .map((item) => {
            return {
              id: item.id,
              label: ['Necessário o uso de transformador.'],
            };
          });

        return normalizeData(transformWarning);
      }
    }
  };

  const getFilters = useCallback(async () => {
    try {
      const response = await productsApi.get('/products/filters/', {
        params: {
          fields: 'category,supplier,phase,voltage,inverter_type,inverter_lv',
          franchiseId,
        },
      });

      setFilter((state) => {
        return {
          ...state,
          options: normalizeFilters(response.data),
        };
      });
    } catch (error) {
      toast.error('Não foi possível buscar os filtros.', {
        toastId: '2',
      });

      throw error;
    }
  }, []);

  const getPromotionalKits = useCallback(async (page?: number) => {
    try {
      const response = await productsApi.get<PaginatedResponse<Kit>>(
        `/budgets/${id}/products`,
        {
          params: {
            page: page || promotionalKits.pagination.currentPage,
            limit: 10,
            order: 'price,ASC',
            promotional: true,
            franchiseId,
          },
        }
      );

      validateKits(response.data);

      setPromotionalKits(response.data);
    } catch (error) {
      toast.error('Não foi possível buscar os kits promocionais.', {
        toastId: '1',
      });

      throw error;
    }
  }, []);

  const getKits = useCallback(
    async (page?: number, limit?: number) => {
      setLoadings((state) => ({ ...state, table: true }));

      try {
        const { selecteds } = filter;

        const response = await productsApi.get<PaginatedResponse<Kit>>(
          `/budgets/${id}/products`,
          {
            params: {
              page: page || kits.pagination.currentPage,
              limit: limit || kits.pagination.limit,
              category: selecteds?.category,
              phase: selecteds?.phase,
              supplier: selecteds?.supplier,
              voltage: selecteds?.voltage,
              order: orderBy,
              promotional: false,
              franchiseId,
              inverter_type: selecteds?.inverterType,
              inverter_lv: selecteds?.inverterLv,
            },
          }
        );

        setKits(response.data);

        validateKits(response.data);
      } catch (error) {
        const errorMessage = (error as AxiosError).response?.data.message;

        switch (errorMessage) {
          case 'HSP not found':
            toast.error('Localização não encontrada', {
              toastId: '4',
            });

            break;

          default:
            toast.error('Não foi possível buscar os kits.', {
              toastId: '1',
            });

            break;
        }

        throw error;
      } finally {
        setLoadings((state) => ({ ...state, table: false }));
      }
    },
    [filter.selecteds, orderBy, kits]
  );

  const allSelectedKits = [
    ...selectedKits.kits,
    ...selectedKits.promotionalKits,
  ];

  const createServices = async () => {
    try {
      await removeKitsFromProposal();

      const newProducts = allSelectedKits.map((kit) => {
        return {
          id: kit.id,
          quantity: kit?.quantity || 1,
        };
      });

      const { data } = await productsApi.post<BudgetItem[]>(`/budgets/budget`, {
        products: newProducts,
        budgetId: id,
        franchiseId,
      });

      handleServices(data);

      const normalizedData = data.map((item) => {
        return {
          budgetItemId: item.id,
          quantity: item.quantity,
          idealProfitability: item.idealProfitability,
        };
      });

      handleProducts(normalizedData);

      history.push(
        `/orcamentos/selecionar-produto/cadastro-cliente/${id}/selecao-de-kits/servicos/`
      );
    } catch (error) {
      const errorMessage = (error as AxiosError).response?.data.message;

      toast.error(errorMessage);

      throw error;
    }
  };

  const updateServices = async () => {
    try {
      await removeKitsFromProposal();

      const updatedProducts = allSelectedKits.map((kit) => {
        return {
          id: kit.id,
          quantity: kit?.quantity || 1,
        };
      });

      const { data } = await productsApi.post<BudgetItem[]>(`/budgets/budget`, {
        products: updatedProducts,
        budgetId: id,
        franchiseId,
      });

      handleServices(data);

      const normalizedData = data.map((item) => {
        return {
          budgetItemId: item.id,
          quantity: item.quantity,
          idealProfitability: item.idealProfitability,
        };
      });

      handleProducts(normalizedData);

      history.push(
        `/orcamentos/selecionar-produto/cadastro-cliente/${id}/selecao-de-kits/servicos/editar`
      );
    } catch (error) {
      const errorMessage = (error as AxiosError).response?.data.message;

      toast.error(errorMessage);

      throw error;
    }
  };

  const removeKitsFromProposal = async () => {
    try {
      const unSelectedKits = services.filter(
        (item) => !allSelectedKits.find((kit) => kit.id === item.product?.id)
      );

      const promises = unSelectedKits.map(
        async (item) =>
          await productsApi.delete(`/budgets/${id}/${item.id}/remove-item`)
      );

      await Promise.all(promises);
    } catch (error) {
      console.error(error);

      throw error;
    }
  };

  const handleSelectionKits = async () =>
    hasEditPath ? await updateServices() : await createServices();

  const fetchData = async () => {
    setLoadings((state) => ({ ...state, default: true }));

    try {
      if (!budget) {
        await getBudget(id);
      }

      await Promise.all([getFilters(), getPromotionalKits()]);
    } catch (error) {
      console.error(error);

      throw error;
    } finally {
      setLoadings((state) => ({ ...state, default: false }));
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    getKits();
  }, [filter]);

  if (loadings.default) {
    return <Loading style={{ alignItems: 'center', height: '100%' }} />;
  }

  return (
    <Container>
      <Breadcrumb links={links} />

      <PageHeader style={{ flexDirection: 'column', gap: '1.5rem' }}>
        <span>{'Seleção de Kit'}</span>
        <div>
          <StepProgress steps={steps} />
        </div>
      </PageHeader>

      <Content>
        {hasPromotionalKits && (
          <PromotionalKitsContainer>
            <h1>Kits em promoção</h1>
            <PromotionalKitWrapper>
              {promotionalKits.content.map((promotionalKit: Kit) => {
                const isChecked = !!selectedKits.promotionalKits.find(
                  (selectedKit) => selectedKit.id === promotionalKit.id
                );

                const hasError = errorMessage.find(
                  (item) => item.id === promotionalKit.id
                );

                return (
                  <PromotionalKit
                    key={promotionalKit.id}
                    onClick={() =>
                      handleSelectKit(
                        promotionalKit,
                        promotionalKit.promotional
                      )
                    }
                  >
                    <Checkbox
                      option={promotionalKit}
                      name={promotionalKit.id + promotionalKit.category}
                      onChange={(selectedKit) =>
                        handleSelectKit(selectedKit, promotionalKit.promotional)
                      }
                      isChecked={isChecked}
                    />
                    <div>
                      <PromotionalKitName>
                        <h1>
                          {promotionalKit.supplier +
                            ' - ' +
                            promotionalKit.category +
                            'W'}
                        </h1>
                        <button
                          type="button"
                          onClick={(event) => {
                            event.stopPropagation();

                            handleDescriptionModal(promotionalKit.description);
                          }}
                        >
                          <FiEye />
                        </button>
                      </PromotionalKitName>
                      <PromotionalKitPrice>
                        {toMoneyFormat(promotionalKit.price)}
                      </PromotionalKitPrice>
                      <span
                        style={{
                          fontSize: '1.25rem',
                          fontWeight: '400',
                          color: '#00299b',
                        }}
                      >
                        {promotionalKit.power +
                          'KWp' +
                          ' | ' +
                          promotionalKit.voltage +
                          'V'}
                      </span>
                      <span
                        style={{
                          fontSize: '1rem',
                          fontWeight: '400',
                          color: '#00299b',
                        }}
                      >
                        {promotionalKit.solarPlatesNumber +
                          ' módulos' +
                          ' | ' +
                          promotionalKit.generation +
                          'KWh'}
                      </span>
                      {hasError &&
                        hasError.label.map((item, index) => {
                          return (
                            <span
                              key={item + hasError.id[index]}
                              className="errorMessage"
                            >
                              {item}
                            </span>
                          );
                        })}
                    </div>
                  </PromotionalKit>
                );
              })}
            </PromotionalKitWrapper>
          </PromotionalKitsContainer>
        )}

        <CalculatedKitsContainer>
          <CalculatedKitsHeader>
            <h1>Kits calculados</h1>
            <div>
              <p>
                Os kits mostrados na tabela abaixo possuem a potência necessária
                para a demanda do seu cliente. Caso queria explorar outras
                opções, clique no botão ao lado
              </p>
              <Button
                type="button"
                text="Personalizar kit"
                icon="add"
                onClick={() =>
                  history.push(
                    `/orcamentos/selecionar-produto/cadastro-cliente/${id}/personalizar-kit`
                  )
                }
              />
            </div>
          </CalculatedKitsHeader>
          <CalculatedKitsTable>
            <Table
              hasPagination={false}
              items={tableItems}
              columns={tableColumns}
              isLoading={loadings.table}
              header={
                <CalculatedKitsTableHeader>
                  <DynamicFilter
                    filters={filter.options}
                    onChange={handleFilters}
                  />
                </CalculatedKitsTableHeader>
              }
              placeholder="Nenhum kit encontrado"
            />
          </CalculatedKitsTable>
        </CalculatedKitsContainer>
        <ButtonGroup>
          <Button
            type="button"
            text="Voltar"
            typeStyle="default"
            backgroundHoverColor="#C9CBCF"
            onClick={() => {
              history.push(
                `/orcamentos/selecionar-produto/cadastro-cliente/${id}/${
                  hasEditPath ? 'editar' : ''
                }`
              );
            }}
          />

          <Button
            type="button"
            text="Avançar"
            onClick={handleSelectionKits}
            disabled={!hasSelectedKits}
          />
        </ButtonGroup>
      </Content>

      <KitDescriptionModal
        isOpen={hasKitDescription}
        description={kitDescription as string}
        onCancel={() => handleDescriptionModal(null)}
      />
    </Container>
  );
};

export default BudgetsSelectionKitPage;
