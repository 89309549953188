import styled from 'styled-components';

export const Content = styled.main`
  height: 100%;

  display: flex;
  flex-direction: column;

  padding: 1.5rem 0;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  height: 100%;

  margin-bottom: 3rem;

  & > h1 {
    font-family: 'DunbarTextRegular', sans-serif;
    font-weight: 400;
    font-size: 1.25rem;

    letter-spacing: 0.02em;

    color: #27282b;
  }

  & > p {
    font-family: 'DunbarTextRegular';

    font-weight: 400;
    font-size: 1rem;

    letter-spacing: 0.02em;

    color: #000000;
  }
`;
