import styled from 'styled-components';

export const Content = styled.form`
  height: 100%;

  display: flex;
  flex-direction: column;

  padding: 1.5rem 0;
`;

export const ConsultantMessage = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 1.125rem;

  margin-bottom: 2.5rem;

  & > div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1.125rem;

    & > h1 {
      font-family: 'DunbarTextRegular', sans-serif;
      font-weight: 400;
      font-size: 1.25rem;

      letter-spacing: 0.02em;

      color: #27282b;
    }

    & > p {
      font-family: 'DunbarTextRegular', sans-serif;

      font-weight: 400;
      font-size: 1rem;

      letter-spacing: 0.02em;

      color: #000000;
    }
  }

  & > div:first-child {
    flex: 0 1 48rem;
  }
`;

export const Info = styled.div`
  margin-bottom: 2.5rem;

  & > h1 {
    font-family: 'DunbarTextRegular', sans-serif;
    font-weight: 400;
    font-size: 1.25rem;

    letter-spacing: 0.02em;

    color: #27282b;

    & > strong {
      font-family: 'DunbarTallBold', sans-serif;
    }
  }
`;

export const Documents = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4.25rem;

  margin-bottom: 2.5rem;

  & > div {
    display: flex;
    flex-direction: column;
    gap: 1.125rem;

    & > h1 {
      font-family: 'DunbarTextRegular', sans-serif;
      font-weight: 400;
      font-size: 1.25rem;

      letter-spacing: 0.02em;

      color: #27282b;
    }
  }
`;

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(16.125rem, 1fr));
  gap: 1.5rem;

  & button {
    border: 1px solid #c9cbcf;
  }

  & > ul {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    & > label {
      font-family: 'Montserrat', sans-serif;
      font-weight: 500;
      font-size: 1rem;

      letter-spacing: 0.01em;

      color: #27282b;
    }
  }

  @media (max-width: 992px) {
    & button {
      width: 100%;
    }
  }
`;

export const RadioWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.875rem;

  height: 100%;
`;

export const DocumentTypes = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.875rem;

  & > div {
    width: 100%;

    & > div {
      overflow-x: auto;

      width: 100%;

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
`;

export const Links = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  & > div {
    display: flex;
    gap: 1.5rem;

    & > button {
      width: 10.25rem;

      padding: 0 1rem;

      margin-top: 1.6rem;
    }
  }
`;

export const LinkWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  & > h1 {
    font-family: 'DunbarTextRegular', sans-serif;
    font-weight: 400;
    font-size: 1.25rem;

    letter-spacing: 0.02em;

    color: #27282b;
  }

  & > div {
    display: flex;
    align-items: center;
    gap: 3rem;

    width: 100%;

    overflow: auto;

    padding: 2rem 0;

    &::-webkit-scrollbar {
      height: 8px;
    }
  }
`;

export const Link = styled.article`
  display: flex;
  align-items: center;
  gap: 0.5rem;

  & > button {
    padding: 0.375rem;
    gap: 0.25rem;

    width: 2rem;
    height: 2rem;

    background: #ffffff;

    box-shadow: inset -1px 0px 0px #cccccc, inset 1px 0px 0px #cccccc,
      inset 0px -1px 0px #cccccc, inset 0px 1px 0px #cccccc;
    border-radius: 4px;

    & > svg {
      color: #ed5a5a;

      font-size: 1.25rem;
    }
  }

  & > div {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    white-space: nowrap;

    & > h1 {
      font-family: 'DunbarTextRegular', sans-serif;
      font-weight: 400;
      font-size: 1rem;

      letter-spacing: 0.02em;

      color: #27282b;
    }

    & > a {
      font-family: 'Montserrat', sans-serif;
      font-weight: 400;
      font-size: 1rem;

      letter-spacing: 0.02em;
      text-decoration-line: underline;

      color: #005af9;
    }
  }
`;

export const CancelOrder = styled.div`
  & > button {
    color: #ffffff;

    & > svg {
      font-size: 1.25rem;
    }
  }

  @media (max-width: 768px) {
    width: 100%;

    & > button {
      width: 100%;
    }
  }
`;
