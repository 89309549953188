import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2.25rem;

  width: min(16.125rem, 100%);

  height: fit-content;

  border: 1px solid #c9cbcf;
  border-radius: 8px;

  padding: 1.5rem 1rem 1rem;

  background: #ffffff;

  @media (max-width: 520px) {
    width: 100%;
  }
`;

export const Header = styled.header`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;

  & > h1 {
    font-family: 'DunbarTallBold';
    font-weight: 500;
    font-size: 1.125rem;

    letter-spacing: 0.03em;

    color: #000000;
  }

  & > p {
    font-family: 'Montserrat';
    font-weight: 400;
    font-size: 0.875rem;

    letter-spacing: 0.02em;

    & > strong {
      color: #000000;

      font-weight: 700;
    }
  }
`;

export const List = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  width: 100%;

  overflow-y: auto;

  cursor: grab;

  &::-webkit-scrollbar {
    width: 2px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 8px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 8px;
  }
`;

export const Item = styled.ul`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1.5rem;

  & > p {
    font-family: 'DunbarTextRegular';
    font-weight: 400;
    font-size: 1rem;

    letter-spacing: 0.02em;

    color: #000000;

    max-width: 8.875rem;
    width: 100%;

    display: block;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  & > div {
    display: flex;
    align-items: center;
    gap: 0.25rem;

    max-width: 4.25rem;
    width: 100%;

    & > strong {
      font-family: 'DunbarTallBold';

      font-weight: 700;
      font-size: 1.5rem;

      letter-spacing: 0.03em;

      color: #000000;
    }
  }
`;

export const SeeDetails = styled.button`
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 1rem;

  letter-spacing: 0.02em;
  text-decoration-line: underline;

  color: #005af9;

  text-align: center;

  cursor: pointer;
`;

export const TableHeader = styled.header`
  display: flex;
  justify-content: flex-end;
  gap: 1rem;

  background-color: #fafafa;

  @media (max-width: 992px) {
    flex-direction: column;

    & > div:has(input) {
      max-width: unset;
      order: 2;
    }

    & > div:has(button) {
      order: 1;
    }

    & > div:has(button) > button {
      width: 100%;
    }
  }
`;
