import styled from 'styled-components';

export const Container = styled.div``;

export const Content = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1.37rem;
`;

export const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 1.5rem 3rem;

  border-bottom: 1px solid #c9cbcf;

  & > h1 {
    font-family: 'Montserrat', sans-serif;

    font-weight: 700;
    font-size: 1.5rem;

    color: #000000;
  }

  & > button {
    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 1.25rem;

    width: 3rem;
    height: 3rem;

    background: #f5f5f6;
    border-radius: 200px;
  }
`;

export const Wrapper = styled.div`
  padding: 1.5rem 3rem;

  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  & > p {
    color: #000;

    font-family: 'DunbarTextRegular', sans-serif;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0.02rem;
  }
`;

export const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1.5rem;

  width: 100%;

  padding: 1.5rem 3rem;

  border-top: 1px solid #c9cbcf;
`;
