import { format } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import React, { useEffect, useState } from 'react';
import {
  FiCalendar,
  FiEdit,
  FiMinusCircle,
  FiPlusCircle,
  FiX,
} from 'react-icons/fi';
import { Props } from 'react-modal';
import { useHistory } from 'react-router-dom';
import { ShapeModal } from '../..';
import { Lead, STATES_UF } from '../../../../contexts/sales-funnel/types';
import { Button } from '../components/button';
import { InnerLostModal } from '../inner-modals/lost';
import { InnerScheduleModal } from '../inner-modals/schedule';
import {
  Aside,
  Buttons,
  Container,
  Content,
  Customer,
  Data,
  Header,
  Kit,
  Messages,
  Wrapper,
} from './styles';
import { useSalesFunnel } from '../../../../contexts/sales-funnel';
import Loading from '../../../loading';
import { useScrollContainer } from 'react-indiana-drag-scroll';
import { Tags } from '../components/tags';
import { LeadModal } from '../lead-modal';

type NegotiationModalProps = {
  onCancel: () => void;
  content: Lead | null;
} & Props;

export const NegotiationModal: React.FC<NegotiationModalProps> = (props) => {
  const { onCancel, content, isOpen, ...rest } = props;

  const {
    leadModal,
    leadBudgetItems,
    loading,
    clearLeadBudgetItems,
    getLeadBudgetItems,
    handleCRMData,
    handleLeadModal,
  } = useSalesFunnel();

  const scroll = useScrollContainer<HTMLElement>();

  const history = useHistory();

  const [modals, setModals] = useState({
    schedule: {
      isOpen: false,
    },
    lost: {
      isOpen: false,
    },
  });

  const handleOpenModals = (modal: keyof typeof modals) => {
    setModals((state) => {
      if (modal in state) {
        return {
          ...state,
          [modal]: {
            isOpen: true,
          },
        };
      }

      return state;
    });
  };

  const handleCloseModals = (modal: keyof typeof modals) => {
    setModals((state) => {
      if (modal in state) {
        return {
          ...state,
          [modal]: {
            isOpen: false,
          },
        };
      }

      return state;
    });
  };

  const createOrder = () => {
    if (content) {
      handleCRMData(content);

      history.push(
        '/orcamentos/orcamentos-e-pedidos/gerar-pedido/selecione/',
        content?.budgetId
      );
    }
  };

  const editBudget = () => {
    if (content) {
      handleCRMData(content);

      history.push(
        `/orcamentos/selecionar-produto/cadastro-cliente/${content?.budgetId}/selecao-de-kits/servicos/editar`
      );
    }
  };

  useEffect(() => {
    if (isOpen && content?.budgetId) {
      getLeadBudgetItems(content.budgetId);
    } else {
      clearLeadBudgetItems();
    }
  }, [isOpen]);

  if (loading.leadBudgetItems) {
    return (
      <ShapeModal
        padding="0"
        maxWidth="60rem"
        onRequestClose={onCancel}
        isOpen={isOpen}
        {...rest}
      >
        <Loading />
      </ShapeModal>
    );
  }

  return (
    <ShapeModal
      padding="0"
      maxWidth="60rem"
      onRequestClose={onCancel}
      isOpen={isOpen}
      {...rest}
    >
      <Container>
        <Header>
          <h1>Negociação</h1>

          <button onClick={onCancel}>
            <FiX />
          </button>
        </Header>

        <Content>
          <Tags lead={content} />

          <Wrapper>
            <Customer>
              <div>
                <header>
                  <h1>Dados do cliente</h1>

                  <Button
                    variant="white"
                    type="button"
                    maxWidth="7.313rem"
                    onClick={() => handleLeadModal(content)}
                  >
                    <FiEdit />
                    Editar
                  </Button>
                </header>

                <div>
                  <strong>{content?.customer.name}</strong>
                  <p>
                    Tipo de instalação:
                    <strong>{content?.customer.sector}</strong>
                  </p>
                  <p>
                    Canal de origem:<strong>{content?.customer.origin}</strong>
                  </p>
                  <p>
                    Vendedor:
                    <strong>{content?.franchiseUser?.user.name}</strong>
                  </p>
                </div>
              </div>
              <div>
                <header>
                  <h1>Endereço do cliente</h1>
                </header>

                {content?.customer.address && (
                  <div>
                    <p>
                      {content?.customer.address},{' '}
                      {content?.customer.addressNumber}
                    </p>

                    <p>
                      {content?.customer.district} - {content?.customer.city} -{' '}
                      {
                        STATES_UF[
                          content?.customer.state as keyof typeof STATES_UF
                        ]
                      }
                    </p>

                    <p>
                      CEP: <strong>{content?.customer.zipcode}</strong>
                    </p>
                  </div>
                )}

                {!content?.customer.address && (
                  <div>
                    <p>Nenhum endereço cadastrado.</p>
                  </div>
                )}
              </div>
            </Customer>

            <Aside>
              <Data>
                <header>
                  <h1>Dados do orçamento</h1>
                  <Button
                    variant="white"
                    type="button"
                    maxWidth="7.313rem"
                    onClick={editBudget}
                  >
                    <FiEdit />
                    Editar
                  </Button>
                </header>
                {!!leadBudgetItems?.length && (
                  <main ref={scroll.ref}>
                    {leadBudgetItems?.map((item) => {
                      return (
                        <Kit key={item.id}>
                          <h1>
                            {item.quantity}x {item.product?.supplier} -{' '}
                            {item.product?.category}W
                          </h1>
                          <strong>{item.formatted.total}</strong>
                          <span>
                            {item.product?.power} KWp | {item.product?.voltage}V
                          </span>
                          <span>{item.product?.solarPlatesNumber} módulos</span>
                        </Kit>
                      );
                    })}
                  </main>
                )}

                <Messages>
                  <p>
                    Última edição:
                    <strong>
                      {' '}
                      {content?.updatedAt &&
                        format(
                          new Date(content.updatedAt),
                          "dd 'de' MMMM 'de' yyyy",
                          {
                            locale: ptBR,
                          }
                        )}
                    </strong>
                  </p>

                  {/* <p className="pending-discount">
                  Solicitação de desconto pendente.
                </p> */}
                </Messages>
              </Data>
              <Buttons>
                <Button
                  variant="blue"
                  type="button"
                  maxWidth="100%"
                  onClick={createOrder}
                >
                  <FiPlusCircle />
                  Gerar pedido
                </Button>
                <Button
                  variant="red"
                  type="button"
                  maxWidth="100%"
                  onClick={() => handleOpenModals('lost')}
                >
                  <FiMinusCircle />
                  Orçamento perdido
                </Button>
                <Button
                  variant="white"
                  type="button"
                  maxWidth="100%"
                  onClick={() => handleOpenModals('schedule')}
                >
                  <FiCalendar />
                  Agendar novo contato
                </Button>
              </Buttons>
            </Aside>
          </Wrapper>
        </Content>
      </Container>

      <InnerScheduleModal
        leadId={content?.id ?? ''}
        isOpen={modals.schedule.isOpen}
        onCancel={() => {
          handleCloseModals('schedule');
          onCancel();
        }}
      />

      <InnerLostModal
        leadId={content?.id ?? ''}
        isOpen={modals.lost.isOpen}
        onCancel={() => handleCloseModals('lost')}
        onConfirm={() => {
          handleCloseModals('lost');

          onCancel();
        }}
      />

      <LeadModal
        isOpen={leadModal.isOpen}
        lead={leadModal.data}
        onCancel={() => {
          handleLeadModal(null);
          onCancel();
        }}
      />
    </ShapeModal>
  );
};
