import styled from 'styled-components';

export const InputContainer = styled.div`
  margin-top: 1rem;
`;

export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;

  padding: 1rem;
`;

export const ModalHeader = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const ModalFooter = styled.div`
  display: flex;
  justify-content: center;
`;

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 25rem;

  & > h1 {
    font-weight: 900;
    font-size: 1.125rem;

    color: #27282b;

    margin: 1.5rem 0;
  }
`;
