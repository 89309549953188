import styled from 'styled-components';

export const Buttons = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1rem;

  width: 100%;

  margin-top: 2rem;

  @media (max-width: 992px) {
    & > button {
      width: 100% !important;
    }
  }
`;

export const ModalContainer = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1.25rem;

  height: fit-content;

  & > h1 {
    font-weight: 900;
    font-size: 1.125rem;

    color: #27282b;
  }

  & > p {
    font-weight: 400;
    font-size: 1.125rem;

    color: #27282b;
  }
`;
