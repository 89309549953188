import styled from 'styled-components';

export const Content = styled.main`
  display: flex;
  flex-direction: column;

  padding: 1.5rem 0;

  @media (max-width: 520px) {
    justify-content: space-between;
  }
`;

export const ContentHeader = styled.header`
  display: flex;
  justify-content: space-between;
  gap: 1rem;

  width: 100%;

  & > div {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;

    flex: 1;

    gap: 1rem;
  }

  & > div:last-child {
    justify-content: flex-end;
  }

  & div:last-child > button:last-child > svg > path {
    stroke: #ffffff;
  }

  @media (max-width: 992px) {
    flex-direction: column;

    & > div {
      & > button {
        width: 100% !important;
      }
    }
  }
`;

export const EmbedContainer = styled.iframe`
  width: min(69rem, 100%);
  height: 40rem;

  margin: 2rem auto 0;

  background: #9fa1a8;

  border: 0;

  @media (max-width: 520px) {
    display: none;
  }
`;
