import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Breadcrumb } from '../../../../../../components/breadcrumb';
import { Button } from '../../../../../../components/button';
import { ButtonGroup, Container, PageHeader } from '../../../../../styles';
import { FRANCHISE_ORDER_STATUS } from '../../../components/orders/innerTables/franchise';
import { Content, Wrapper } from './styles';

type FranchiseProcessedProps = {};

export const FranchiseProcessed: React.FC<FranchiseProcessedProps> = () => {
  const { orderId, status } = useParams<{
    orderId: string;
    status: FRANCHISE_ORDER_STATUS;
  }>();

  const history = useHistory();

  const links = [
    {
      id: 1,
      title: 'Orçamentos',
      link: '/orcamentos',
      active: false,
    },
    {
      id: 2,
      title: 'Orçamentos e Pedidos',
      link: '/orcamentos/orcamentos-e-pedidos',
      active: false,
    },
    {
      id: 3,
      title: 'Pedidos enviados',
      link: `/orcamentos/orcamentos-e-pedidos/pedidos-enviados/${orderId}/${status}`,
      active: true,
    },
  ];

  return (
    <Container>
      <Breadcrumb links={links} />

      <PageHeader>
        <span>{'Pedidos enviados'}</span>
      </PageHeader>

      <Content>
        <Wrapper>
          <h1>Pedido processado</h1>

          <p>
            O fornecedor está analisando a documentaçao e em breve atualizará
            seu status.
          </p>
        </Wrapper>

        <ButtonGroup>
          <Button
            type="button"
            text="Voltar"
            typeStyle="default"
            backgroundHoverColor="#C9CBCF"
            onClick={() =>
              history.replace('/orcamentos/orcamentos-e-pedidos', 'Pedidos')
            }
          />
        </ButtonGroup>
      </Content>
    </Container>
  );
};
