export const rankings = [
  {
    id: '1',
    consultant: 'Tiago',
    rank: '1',
    status: 'neutral',
  },
  {
    id: '2',
    consultant: 'Larissa',
    rank: '2',
    status: 'neutral',
  },
  {
    id: '3',
    consultant: 'Ana',
    rank: '3',
    status: 'neutral',
  },
  {
    id: '4',
    consultant: 'Juliana',
    rank: '4',
    status: 'neutral',
  },
  {
    id: '5',
    consultant: 'Junior',
    rank: '5',
    status: 'neutral',
  },
];

export const cards = [
  {
    id: '1',
    label: 'Cálculos realizados',
    currentValue: '90.000',
    lastValue: '100.000',
    percentage: '-10%',
    isPositive: false,
    type: 'default',
  },
  {
    id: '2',
    label: 'Geração em negociação',
    currentValue: '120.000 KWp',
    lastValue: '100.000 KWp',
    percentage: '10%',
    isPositive: true,
    type: 'default',
  },
  {
    id: '3',
    label: 'Geração média',
    currentValue: '5.000 KWp',
    lastValue: '4.999 KWp',
    percentage: '10%',
    isPositive: true,
    type: 'default',
  },
  {
    id: '4',
    label: 'Média de mão de obra',
    currentValue: 'R$ 3.000,00',
    lastValue: 'R$ 3.000,00',
    percentage: '10%',
    isPositive: true,
    type: 'default',
  },
  {
    id: '5',
    label: 'Rebate gerado',
    currentValue: 'R$ 3.000.000,00',
    lastValue: 'R$2.700.000,00',
    percentage: '10%',
    isPositive: true,
    type: 'default',
  },
  {
    id: '6',
    label: 'Royalties',
    currentValue: 'R$ 3.000,00',
    lastValue: 'R$2.700,00',
    percentage: '10%',
    isPositive: true,
  },
  {
    id: '7',
    label: 'Ticket médio',
    data: [
      {
        id: '8',
        label: 'Produtos',
        currentValue: 'R$ 200.000,00',
        lastValue: 'R$180.000,00',
        percentage: '-10%',
        isPositive: true,
        type: 'average-ticket',
      },
      {
        id: '9',
        label: 'Produtos + mão de obra',
        currentValue: 'R$ 300.000,00',
        lastValue: 'R$270.000,00',
        percentage: '10%',
        isPositive: true,
        type: 'average-ticket',
      },
    ],
  },
];

export const yourSales = [
  {
    id: '1',
    label: 'Vendas',
    percentage: '10%',
    type: 'increased',
  },
  {
    id: '2',
    label: 'Itens',
    percentage: '1,03%',
    type: 'decreased',
  },
  {
    id: '3',
    label: 'Mão de obra',
    percentage: '10%',
    type: 'increased',
  },
];

export const regions = [
  {
    id: '1',
    name: 'Sudeste',
    price: 'R$ 5.432.198,76',
    percentage: '29%',
    type: 'neutral',
    rank: '1°',
  },
  {
    id: '2',
    name: 'Nordeste',
    price: 'R$ 4.432.198,76',
    percentage: '23%',
    type: 'increased',
    rank: '2°',
  },
  {
    id: '3',
    name: 'Centro-oeste',
    price: 'R$ 3.432.198,76',
    percentage: '20%',
    type: 'decreased',
    rank: '3°',
  },
  {
    id: '4',
    name: 'Sul',
    price: 'R$ 2.432.198,76',
    percentage: '18%',
    type: 'neutral',
    rank: '4°',
  },
  {
    id: '5',
    name: 'Norte',
    price: 'R$ 1.432.198,76',
    percentage: '10%',
    type: 'neutral',
    rank: '5°',
  },
];

export const monthlyHistory = {
  currentYear: [1, 1.1, 1.05, 1.02, 1.2, 1.4],
  lastYear: [0.9, 1, 1, 0.92, 0.98, 1.2, 1.3, 1.5, 1.7, 1.6, 1.8, 2],
  percentage: [10, -4.5, -2.4, 17.1, 16.7],
};

export const itemsSold = [
  {
    id: '1',
    name: 'Itens vendidos',
    currentValue: 1.652,
    lastValue: 1.552,
    percentage: '+10%',
    isPositive: true,
  },
  {
    id: '2',
    name: 'Geração vendida',
    currentValue: 50000,
    lastValue: 48000,
    percentage: '-10%',
    isPositive: false,
  },
  {
    id: '3',
    name: 'Módulos vendidos',
    currentValue: '1.652.345',
    lastValue: '1.432.234',
    percentage: '-5%',
    isPositive: false,
  },
  {
    id: '4',
    name: 'Inversores vendidos',
    currentValue: '1.234',
    lastValue: '1.111',
    percentage: '+10%',
    isPositive: true,
  },
  {
    id: '5',
    name: 'Bombas d`água',
    currentValue: '1.234',
    lastValue: '1.345',
    percentage: '+10%',
    isPositive: true,
  },
  {
    id: '6',
    name: 'Carregadores',
    currentValue: '1.234',
    lastValue: '1.198',
    percentage: '-12%%',
    isPositive: false,
  },
];
