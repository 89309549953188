import React, { useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import franchisesApi from '../../../services/franchises';
import { FranchiseType } from '../../franchises/types';

import { toast } from 'react-toastify';

import Loading from '../../../components/loading';
import { Breadcrumb } from '../../../components';
import { Container, PageHeader } from '../../styles';
import { ColumnStructure, TableData } from '../../../components/table';
import Table from '../../../components/table';

type LinkedFranchisesPageParams = {
  id: string;
};

type Franchise = Omit<FranchiseType, 'franchiseUser'>;

type TableItems = {
  name: string;
  nickname: string;
};

const LinkedFranchisesPage: React.FC = () => {
  const { id } = useParams<LinkedFranchisesPageParams>();
  const router = useHistory();

  const [franchises, setFranchises] = useState<Franchise[]>([]);
  const [loading, setLoading] = useState(true);

  const links = [
    {
      id: 1,
      title: 'Usuários',
      link: '/usuarios',
      active: false,
    },
    {
      id: 2,
      title: 'Lista de usuários',
      link: '/usuarios',
      active: false,
    },
    {
      id: 3,
      title: 'Franquias vinculadas',
      link: '/usuarios/' + id + '/franquias',
      active: true,
    },
  ];

  const getLinkedFranchises = async (userId: string) => {
    setLoading(true);

    franchisesApi
      .get('/api/franchises/by-user-id/' + userId)
      .then((response) => {
        const normalizedResponse: Franchise[] = response.data.map(
          (data: { franchise: Franchise }) => data.franchise
        );

        return setFranchises(normalizedResponse);
      })
      .catch(() => {
        toast.error('Não foi possível buscar as franquias vinculadas!');
        return router.replace('/usuarios');
      })
      .finally(() => {
        return setLoading(false);
      });
  };

  const tableColumns: ColumnStructure[] = [
    {
      id: 'name',
      label: 'Nome',
    },
    {
      id: 'nickname',
      label: 'Apelido',
    },
  ];

  const tableItems: TableData<TableItems>[] = useMemo(() => {
    return franchises.map((franchise) => {
      return {
        id: franchise.id,
        name: franchise.name,
        nickname: franchise.nickname,
      };
    });
  }, [franchises]);

  useEffect(() => {
    getLinkedFranchises(id);
  }, []);

  if (loading) {
    return (
      <Loading
        label="Buscando franquias"
        style={{ alignItems: 'center', height: '100%' }}
      />
    );
  }

  return (
    <Container>
      <Breadcrumb links={links} />

      <PageHeader>
        <span>Franquias vinculadas</span>
      </PageHeader>

      <Table
        columns={tableColumns}
        items={tableItems}
        isLoading={loading}
        placeholder="Este usuário não possui franquias vinculadas"
        onLimitChange={() => null}
        onPageChange={() => null}
        pagination={{
          currentPage: 1,
          limit: 25,
          total: franchises.length,
          lastPage: 1,
        }}
      />
    </Container>
  );
};

export default LinkedFranchisesPage;

