import React from 'react';

import { ShapeModal } from '..';
import { Buttons } from '../styles';

import { Button } from '../../button';

type Props = {
  isOpen: boolean;
  onCancel: () => void;
  onConfirm: () => void;
};

export const UpdateProviderModal = (props: Props) => {
  const { isOpen, onCancel, onConfirm } = props;

  return (
    <ShapeModal isOpen={isOpen}>
      <h1>Editar fornecedor</h1>
      <p>Você tem certeza que deseja realizar essa ação?</p>

      <Buttons>
        <Button
          type="button"
          text="Cancelar"
          typeStyle="default"
          backgroundHoverColor="#C9CBCF"
          onClick={onCancel}
        />
        <Button
          type="button"
          text="Confirmar"
          typeStyle="confirm"
          onClick={onConfirm}
        />
      </Buttons>
    </ShapeModal>
  );
};

