import styled, { css } from 'styled-components';

interface ContainerProps {
  isDragging?: boolean;
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;

  border-radius: 4px;
  border: 1px solid #c9cbcf;
  padding: 1rem;

  background: ${(props) => (props.isDragging ? '#ffffff' : '#ffffff')};
  user-select: none;

  ${(props) =>
    props.isDragging &&
    css`
      box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
    `}
`;

export const Header = styled.header`
  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  & > div {
    width: 100%;
  }
`;

export const Tags = styled.div`
  display: flex;
  align-items: center;
  gap: 0.25rem;

  flex: 1;

  & .lead-tags-skeleton {
    flex: 1;
  }
`;

type TagProps = {
  bgColor: string;
};

export const Tag = styled.span<TagProps>`
  display: inline-flex;
  flex: 1;

  border-radius: 4px;

  max-width: 5rem;
  width: 100%;
  height: 0.5rem;

  background-color: ${(props) => props.bgColor};

  margin-bottom: 0.5rem;
`;

export const TitlePrimary = styled.h2`
  font-family: 'DunbarTallRegular', sans-serif;
  font-weight: 500;
  font-size: 1.125rem;
  letter-spacing: 0.03em;
  color: #27282b;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const TitleSecondary = styled.h3`
  font-family: 'DunbarTextRegular', sans-serif;
  font-weight: 400;
  font-size: 1rem;
  letter-spacing: 0.02em;
  color: #61646b;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Price = styled.h3`
  font-family: 'DunbarTallRegular', sans-serif;
  font-weight: 700;
  font-size: 1.5rem;
  letter-spacing: 0.03em;
  color: #27282b;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  margin-top: 0.25rem;
`;

export const Footer = styled.footer`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  margin-top: auto;

  & > p {
    display: flex;
    align-items: center;
    gap: 0.5rem;

    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    font-size: 0.875rem;
    letter-spacing: 0.02em;
    color: #61646b;

    & .lead-date-skeleton {
      flex: 1;
    }
  }
`;

export const Button = styled.button`
  width: 2rem;
  height: 2rem;

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 4px;
  border: 1px solid #ccc;

  background-color: #fff;
`;
