import React, { useRef } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as yup from 'yup';
import { Button } from '../../components';
import Input from '../../components/form-elements/input';
import { Container, ForgotPassword, Group } from './styles';
import { toast } from 'react-toastify';
import { Link, useHistory, useLocation } from 'react-router-dom';
import usersApi from '../../services/users';

type LoginType = {
  password: string;
  passwordConfirmation: string;
};

const ResetPasswordPage: React.FC = () => {
  const history = useHistory();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const formRef = useRef<FormHandles>(null);
  const schema = yup.object().shape({
    password: yup
      .string()
      .min(6, 'A senha deve possuir no minimo 6 digitos')
      .required('Senha é um campo obrigatório'),
    passwordConfirmation: yup
      .string()
      .min(6, 'A senha deve possuir no minimo 6 digitos')
      .oneOf(
        [yup.ref('password'), null],
        'Senha e repetir senha devem ser iguais'
      )
      .required('Repetir senha é um campo obrigatório'),
  });

  const {
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<LoginType>({ resolver: yupResolver(schema) });

  const onSubmit = async (data: LoginType) => {
    try {
      await usersApi.post('/api/users/forgot-password/reset', {
        token: query.get('token'),
        password: data.password,
        passwordConfirmation: data.passwordConfirmation,
      });

      toast.success('Senha alterada com sucesso');
      history.push('/');
    } catch (error) {
      toast.error('Dados inválidos');
    }
  };

  return (
    <Container>
      <ForgotPassword>
        <Link to="/">Voltar</Link>
      </ForgotPassword>
      <Form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
        <h1>Recuperação de acesso - Nova Senha</h1>
        <span>Digite a sua nova senha de acesso.</span>
        <Group>
          <Input
            name="password"
            label="Senha"
            placeholder="Digite a sua senha"
            onChange={(e) => setValue('password', e.target.value)}
            error={errors.password?.message}
          />
        </Group>
        <Group>
          <Input
            name="passwordConfirmation"
            label="Repetir senha"
            placeholder="Repita a sua senha"
            onChange={(e) => setValue('passwordConfirmation', e.target.value)}
            error={errors.passwordConfirmation?.message}
          />
        </Group>
        <Button
          text="Enviar"
          size="large"
          backgroundColor="#FFC129"
          backgroundHoverColor="#eda800"
          fontColor="#00299B"
          type="submit"
        />
      </Form>
    </Container>
  );
};

export default ResetPasswordPage;
