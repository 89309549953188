import styled from 'styled-components';

export const Content = styled.main`
  display: flex;
  flex-direction: column;
  gap: 2.5rem;

  padding: 1.5rem 0;

  height: 100%;
`;

export const FormContainer = styled.form`
  display: flex;
  flex-direction: column;

  height: 100%;

  & > .inputs-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    margin: 0 0 1.5rem;

    & > .radio-wrapper {
      display: flex;
      align-items: center;
      gap: 1.5rem;

      & > label {
        width: 100% !important;
      }
    }
  }

  & > h1 {
    font-weight: 600;
    font-size: 1.25rem;

    color: #27282b;

    margin-bottom: 2.5rem;
  }

  & > div {
    display: flex;
    align-items: flex-start;
    gap: 1.5rem;
  }

  @media (max-width: 1500px) {
    & > .button-group {
      margin: 0;
    }

    & > hr {
      display: none;
    }

    & > div {
      width: 100%;

      & > label {
        width: 100% !important;
      }

      & > div {
        width: 100% !important;
      }
    }
  }
`;

