import styled from 'styled-components';

export const Container = styled.fieldset`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;

  & .daterange-month {
    width: 100%;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.75rem;
`;

export const Input = styled.label`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;

  width: 9.5rem;

  color: #61646b;

  font-size: 1rem;
  font-family: 'DunbarTextRegular', sans-serif;

  & > button {
    display: flex;
    align-items: center;
    height: 4rem;
    padding: 1.25rem 1rem;
    justify-content: center;
    gap: 0.5rem;

    background: #dfe0e2;
    color: #2d3339;

    font-size: 1rem;
    font-family: 'Nunito Sans', sans-serif;
    font-weight: 500;
  }
`;
