import React, { createContext, useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import productsApi from '../../services/products';
import * as Types from './types';

export const OrdersContext = createContext({} as Types.OrdersContextData);

export const OrdersProvider: React.FC<Types.OrdersProvider> = (props) => {
  const { children } = props;

  const [order, setOrder] = useState<Types.Order | null>(null);

  const history = useHistory();

  const { pathname } = useLocation();

  const handleOrder = (order: Types.Order) => {
    setOrder(order);
  };

  const getOrder = async (orderId: string) => {
    try {
      const { data } = await productsApi.get<Types.Order>(`/orders/${orderId}`);

      handleOrder(data);

      return data;
    } catch (error) {
      toast.error('Desculpe, não foi possível buscar o pedido');

      history.replace('/orcamentos/orcamentos-e-pedidos/');

      throw error;
    }
  };

  const clearOrderFlow = () => {
    setOrder(null);
  };

  useEffect(() => {
    const isOutOrderFlow = !pathname.includes(
      '/orcamentos-e-pedidos/gerar-pedido'
    );

    if (isOutOrderFlow) {
      clearOrderFlow();
    }
  }, [pathname]);

  return (
    <OrdersContext.Provider
      value={{
        order,
        handleOrder,
        getOrder,
      }}
    >
      {children}
    </OrdersContext.Provider>
  );
};

export const useOrders = () => useContext(OrdersContext);
