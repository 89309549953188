import styled from 'styled-components';

export const Container = styled.div`
  height: 100%;
`;

export const PageHeader = styled.div`
  display: flex;
  justify-content: space-between;

  span {
    font-weight: bold;
    font-size: 2rem;
    line-height: 2rem;

    color: #292929;
  }
`;

export const Content = styled.div`
  align-items: center;
  padding: 1rem 0;
  color: #292929;
  max-width: 46.875rem;
  margin-bottom: 2.25rem;

  p {
    margin-top: 1rem;
  }

  span.filter-title {
    margin-right: 1rem;
  }

  select {
    height: 2rem;
    width: 4rem;
    margin-right: 2rem;
  }

  img {
    margin-right: 1.5rem;
  }

  ul {
    margin: 2rem 0;
    li {
      margin-top: 0.25rem;
    }
  }

  @media (max-width: 520px) {
    max-width: 100%;
  }
`;

export const TermsOfUserContainer = styled.div`
  margin-top: 1rem;
  background-color: #d8e4fa;
  padding: 1rem;
  border-radius: 16px;
  font-size: 0.875;
  display: flex;

  input {
    margin-top: 0.5rem;
    margin-right: 1rem;
  }

  p {
    margin: 0;
    padding: 0;
  }
`;

export const ButtonContainer = styled.div`
  margin-top: 3rem;
`;
