import React from 'react';
import { PaginationButton, PaginationContainer } from './styles';
import { PageDoubleLeft, PageDoubleRight, PageLeft, PageRight } from '../icons';

type PaginationType = {
  total: number;
  limit: number;
  page: number;
  lastPage: number;
  onPageChange: (page: number) => void;
  onLimitChange: (page: number) => void;
};

export const Pagination: React.FC<PaginationType> = ({
  page,
  lastPage,
  onPageChange,
  onLimitChange,
}) => {
  const handleFirstPage = () => {
    onPageChange(1);
  };

  const handlePreviousPage = () => {
    const goPage = Number(page) - 1;
    if (goPage > 0) {
      onPageChange(goPage);
    }
  };

  const handleNextPage = () => {
    const goPage = Number(page) + 1;
    if (goPage <= lastPage) {
      onPageChange(goPage);
    }
  };

  const handleLastPage = () => {
    onPageChange(lastPage);
  };

  const handleLimitChange = (newLimit: number) => {
    onLimitChange(newLimit);
  };

  return (
    <PaginationContainer>
      <span className="filter-title">Registros por página</span>
      <select
        onChange={(event) => handleLimitChange(parseInt(event.target.value))}
      >
        <option value="10">10</option>
        <option value="25">25</option>
        <option value="50">50</option>
      </select>
      <span className="data">
        {page} de {lastPage}
      </span>
      <div className="pagination-buttons">
        <PaginationButton onClick={handleFirstPage} isActive={page !== 1}>
          <PageDoubleLeft />
        </PaginationButton>
        <PaginationButton onClick={handlePreviousPage} isActive={page !== 1}>
          <PageLeft />
        </PaginationButton>
        <PaginationButton onClick={handleNextPage} isActive={page !== lastPage}>
          <PageRight />
        </PaginationButton>
        <PaginationButton onClick={handleLastPage} isActive={page !== lastPage}>
          <PageDoubleRight />
        </PaginationButton>
      </div>
    </PaginationContainer>
  );
};
