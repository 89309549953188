import React, { useEffect, useState } from 'react';
import { Breadcrumb, Button } from '../../../components';
import { Container, Wrapper, PageHeader } from '../styles';
import { useHistory, useParams } from 'react-router-dom';
import projectsApi from '../../../services/projects';
import { Divider, FormContainer } from '../../styles';
import { Group, ButtonContainer, File } from './style';
import { ProjectFilesType, UploadTypes } from '../types';
import { useAuth } from '../../../contexts/auth';
import { getProjectUrl } from '../../../helper/UserLinkRole';

type ProjectCallCenterPageProps = {
  id: string;
};

const ProjectCallCenterFilesPage: React.FC = () => {
  const history = useHistory();
  const { user } = useAuth();
  const { id } = useParams<ProjectCallCenterPageProps>();
  const [projectFiles, setProjectFiles] = useState<ProjectFilesType[]>([]);

  const links = [
    {
      id: 1,
      title: 'Projetos',
      link: '/projetos',
      active: false,
    },
    {
      id: 2,
      title: 'Lista de Projetos',
      link: '/projetos',
      active: true,
    },
  ];

  useEffect(() => {
    projectsApi
      .get<ProjectFilesType[]>(`/api/projects/${id}/file?type=`)
      .then((res) => {
        setProjectFiles(res.data);
      });
  }, [id]);

  return (
    <Container>
      <Breadcrumb links={links} />
      <Wrapper>
        <PageHeader>
          <span>Documentos enviados</span>
        </PageHeader>
        <FormContainer>
          <Group>
            <span>Conta de energia do cliente</span>
            <div>
              {projectFiles
                .filter((file) => file.type === UploadTypes.CONTA_ENEGIA)
                .map((file) => (
                  <File key={file.id} href={file.file_url} target="_blank">
                    {file.originalName}
                  </File>
                ))}
            </div>
          </Group>
          <Divider />

          <Group>
            <span>Termo de aceite com assinatura do cliente</span>
            <div>
              {projectFiles
                .filter(
                  (file) => file.type === UploadTypes.TERMO_ACEITA_CLIENTE
                )
                .map((file) => (
                  <File key={file.id} href={file.file_url} target="_blank">
                    {file.originalName}
                  </File>
                ))}
            </div>
          </Group>
          <Divider />

          <Group>
            <span>Documento do titular da conta de energia (RG / CNH)</span>
            <div>
              {projectFiles
                .filter((file) => file.type === UploadTypes.DOCUMENTO_TITULAR)
                .map((file) => (
                  <File key={file.id} href={file.file_url} target="_blank">
                    {file.originalName}
                  </File>
                ))}
            </div>
          </Group>
          <Divider />

          <Group>
            <span>Foto do padrão de entrada com quadro aberto e poste</span>
            <div>
              {projectFiles
                .filter(
                  (file) => file.type === UploadTypes.PADRAO_ENTRADA_QUADRO
                )
                .map((file) => (
                  <File key={file.id} href={file.file_url} target="_blank">
                    {file.originalName}
                  </File>
                ))}
            </div>
          </Group>
          <Divider />

          <Group>
            <span>Foto do interior do quadro de medição</span>
            <div>
              {projectFiles
                .filter(
                  (file) => file.type === UploadTypes.INTERIOR_QUADRO_MEDICAO
                )
                .map((file) => (
                  <File key={file.id} href={file.file_url} target="_blank">
                    {file.originalName}
                  </File>
                ))}
            </div>
          </Group>
          <Divider />

          <Group>
            <span>Foto do disjuntor geral do padrão de entrada</span>
            <div>
              {projectFiles
                .filter(
                  (file) =>
                    file.type === UploadTypes.DISJUNTOR_GERAL_PADRAO_ENTRADA
                )
                .map((file) => (
                  <File key={file.id} href={file.file_url} target="_blank">
                    {file.originalName}
                  </File>
                ))}
            </div>
          </Group>
          <Divider />

          <Group>
            <span>
              Especificações do kit vendido com marca (Modelo dos Equipamentos)
            </span>
            <div>
              {projectFiles
                .filter(
                  (file) =>
                    file.type === UploadTypes.ESPECIFICACAO_KIT_VENDIDO_MARCA
                )
                .map((file) => (
                  <File key={file.id} href={file.file_url} target="_blank">
                    {file.originalName}
                  </File>
                ))}
            </div>
          </Group>
          <Divider />

          <Group>
            <span>Procuração com reconhecimento de assinatura em cartório</span>
            <div>
              {projectFiles
                .filter(
                  (file) =>
                    file.type ===
                    UploadTypes.PROCURACAO_RECONHECIMENTO_ASSINATURA_CARTORIO
                )
                .map((file) => (
                  <File key={file.id} href={file.file_url} target="_blank">
                    {file.originalName}
                  </File>
                ))}
            </div>
          </Group>
          <Divider />

          <Group>
            <span>Foto do número de poste com transformador mais próximo</span>
            <div>
              {projectFiles
                .filter(
                  (file) =>
                    file.type === UploadTypes.NUMERO_POSTE_TRANSFORMADOR_PROXIMO
                )
                .map((file) => (
                  <File key={file.id} href={file.file_url} target="_blank">
                    {file.originalName}
                  </File>
                ))}
            </div>
          </Group>
          <Divider />

          <ButtonContainer>
            <div>
              <Button
                text="Voltar"
                backgroundColor="#DFE0E2"
                onClick={() => history.push(getProjectUrl(user.role))}
              />
            </div>
            <div>
              <Button
                text="Próximo"
                backgroundColor="#005AF9"
                backgroundHoverColor="#2772f8"
                onClick={() => history.push(`/projetos/${id}/extras`)}
              />
            </div>
          </ButtonContainer>
        </FormContainer>
      </Wrapper>
    </Container>
  );
};

export default ProjectCallCenterFilesPage;
