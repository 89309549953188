import axios from 'axios';
import { axiosConfig } from './config';

const productsApi = axios.create({
  baseURL: process.env.REACT_APP_PRODUCTS_MICROSERVICE_URL,
});

axiosConfig(productsApi);

export default productsApi;
