import React, { CSSProperties, ForwardedRef, InputHTMLAttributes } from 'react';
import { CheckboxIcon } from '../icons';
import { Container } from './styles';

interface CheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
  option?: { [key: string]: any };
  name: string;
  onChange: (option: any) => void;
  isChecked: boolean;
  label?: string;
  message?: string | null;
  styles?: CSSProperties;
  disabled?: boolean;
}

const Checkbox = React.forwardRef(
  (props: CheckboxProps, myRef: ForwardedRef<HTMLInputElement>) => {
    const {
      option,
      onChange,
      name,
      isChecked,
      label,
      message,
      styles,
      ...rest
    } = props;

    return (
      <Container
        htmlFor={name}
        isChecked={isChecked}
        style={styles}
        isDisabled={rest.disabled}
      >
        <div>{isChecked ? <CheckboxIcon /> : null}</div>
        <input
          ref={myRef}
          type="checkbox"
          name={name}
          id={name}
          onChange={() => onChange(option)}
          value={option && option.toString()}
          {...rest}
        />
        {label && (
          <span className="checkbox-label">
            {label}
            {message && <span>{message}</span>}
          </span>
        )}
      </Container>
    );
  }
);

Checkbox.displayName = 'Checkbox';

export default Checkbox;
