import styled from 'styled-components';

export const Container = styled.div``;

export const ModalContainer = styled.div`
  & > h1 {
    font-weight: 500;
    font-size: 1.125rem;

    color: #27282b;
  }
`;

export const Buttons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;

  width: 100%;

  margin-top: 3.5rem;

  & > button {
    width: 15rem;
  }

  @media (max-width: 992px) {
    flex-direction: column;

    & > button {
      width: 100%;
    }
  }
`;
