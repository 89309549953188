import styled from 'styled-components';

export const ModalContent = styled.div`
  & > p {
    font-weight: 400;

    font-size: 1.125rem;

    color: #27282b;

    margin: 3rem 0;
  }
`;

export const ModalSummary = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  margin-bottom: 2.5rem;

  & > div {
    display: flex;
    flex-direction: column;
  }
`;

export const TableHeader = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  @media (max-width: 992px) {
    & > div {
      width: 100%;

      & > button {
        width: 100%;
      }
    }
  }
`;
