import React, { useEffect, useState } from 'react';
import { AttachIcon, Breadcrumb, EyeIcon } from '../../../components';
import { Container, Wrapper, PageHeader } from './../styles';
import { useHistory } from 'react-router-dom';
import projectsApi from '../../../services/projects';
import { ProjectListType, ProjectStatus } from '../types';
import { Actions, TableHeader, TableContainer } from '../../styles';
import { format } from 'date-fns';
import {
  ActionButton,
  Pagination,
  SearchInput,
  Status,
} from '../../../components';

import { ProjectType } from '../../../types/project';
import SendToPowerComapanyModal from './components/SendToPowerComapanyModal';
import { StatusSelect } from '../../../components/status-select';

const ProjectEngineeringPage: React.FC = () => {
  const history = useHistory();
  const [projects, setProjects] = useState<ProjectListType>({
    content: [],
    pagination: {
      currentPage: 0,
      lastPage: 0,
      limit: 0,
      total: 0,
    },
  });
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [name, setName] = useState<string>('');
  const [status, setStatus] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(true);
  const links = [
    {
      id: 1,
      title: 'Projetos',
      link: '/projetos',
      active: false,
    },
    {
      id: 2,
      title: 'Lista de Projetos',
      link: '/projetos',
      active: true,
    },
  ];

  useEffect(() => {
    if (loading) {
      projectsApi
        .get<ProjectListType>(
          `/api/projects?name=${name}&status=${status}&page=${page}&limit=${limit}`
        )
        .then((res) => {
          setProjects(res.data);
          setLoading(false);
        });
    }
  }, [name, status, page, limit, loading]);

  const handlePageChange = (page: number): void => {
    setLoading(true);
    setPage(page);
  };

  const handleLimitChange = (limit: number): void => {
    setLoading(true);
    setLimit(limit);
  };

  const handleSearchChange = (search: string): void => {
    setLoading(true);
    setName(search);
  };

  const isAttachmentAllowed = (project: ProjectType) => {
    if (
      [
        ProjectStatus.DOCUMENTACAO_APROVADA,
        ProjectStatus.PROJETO_EM_ELABORACAO,
        ProjectStatus.PROJETO_ENVIADO,
        ProjectStatus.PROJETO_REPROVADO,
        ProjectStatus.AGUARDANDO_ANALISE_VISTORIA,
        ProjectStatus.VISTORIA_APROVADA,
        ProjectStatus.VISTORIA_REPROVADA,
      ].includes(project.status)
    ) {
      return true;
    }

    return false;
  };

  const isSendToPowerCompanyAllowed = (project: ProjectType) => {
    if (
      [ProjectStatus.PROJETO_ENVIADO, ProjectStatus.PROJETO_REPROVADO].includes(
        project.status
      )
    ) {
      return true;
    }

    return false;
  };

  const handleStatusChange = (search: string): void => {
    setLoading(true);
    setStatus(search);
  };

  return (
    <Container>
      <Breadcrumb links={links} />
      <Wrapper>
        <PageHeader>
          <span>Engenharia</span>
        </PageHeader>

        <TableContainer>
          <TableHeader>
            <div className="filter-container">
              <SearchInput
                name="search"
                placeholder="Digite aqui para pesquisar"
                onTextChange={handleSearchChange}
              />
              <div className="status-select">
                <StatusSelect onChange={handleStatusChange} />
              </div>
            </div>
            {loading && 'Carregando'}
          </TableHeader>
          <div className="table-wrapper">
            <table>
              <thead>
                <tr>
                  <th>Cliente</th>
                  <th>Localização</th>
                  <th>Criação</th>
                  <th>Pagamento</th>
                  <th>Entrega</th>
                  <th>Status</th>
                  <th>Ações</th>
                </tr>
              </thead>
              <tbody>
                {projects?.content.map((project) => (
                  <tr key={project.id}>
                    <td>{project.ucOwner}</td>
                    <td>
                      {project.ucCity}-{project.ucState}
                    </td>
                    <td>
                      {project.createdAt &&
                        format(new Date(project.createdAt), 'dd/MM/yyyy')}
                    </td>
                    <td></td>
                    <td></td>
                    <td>
                      <Status text={project.status} />
                    </td>
                    <td>
                      <Actions>
                        <ActionButton
                          tooltip="Visualizar"
                          onClick={() =>
                            history.push(`/projetos/${project.id}`)
                          }
                        >
                          <EyeIcon />
                        </ActionButton>
                        {isAttachmentAllowed(project) && (
                          <ActionButton
                            tooltip="Anexar"
                            onClick={() => {
                              history.push(
                                `/projetos/engenharia/projeto/${project.id}/arquivos`
                              );
                            }}
                          >
                            <AttachIcon />
                          </ActionButton>
                        )}

                        {isSendToPowerCompanyAllowed(project) && (
                          <SendToPowerComapanyModal
                            id={project.id}
                            handleOnClick={() => {
                              setLoading(true);
                            }}
                          />
                        )}

                        {/* <ActionButton
                        tooltip="Impedimento"
                        onClick={() =>
                          history.push(
                            `/projetos/engenharia/${project.id}/impedimento`
                          )
                        }
                      >
                        <img src={impedimentImg} alt="Impedimento" />
                      </ActionButton> */}
                      </Actions>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <Pagination
            lastPage={projects.pagination.lastPage}
            limit={projects.pagination.limit}
            page={projects.pagination.currentPage}
            total={projects.pagination.total}
            onPageChange={handlePageChange}
            onLimitChange={handleLimitChange}
          />
        </TableContainer>
      </Wrapper>
    </Container>
  );
};

export default ProjectEngineeringPage;
