import React from 'react';
import { ShapeModal } from '..';
import { Buttons } from '../styles';
import { Button } from '../../button';
import { Props } from 'react-modal';
import { Content } from './styles';

type KitDescriptionModalProps = {
  description: string;
  observation?: string;
  onCancel: () => void;
} & Props;

export const KitDescriptionModal: React.FC<KitDescriptionModalProps> = (
  props
) => {
  const { description, observation, onCancel, ...rest } = props;

  return (
    <ShapeModal onRequestClose={onCancel} {...rest}>
      <h1>Descrição do Kit</h1>

      <Content>
        <p>{description}</p>

        {observation && (
          <>
            <h1>Observações</h1>
            <p>{observation}</p>
          </>
        )}
      </Content>
      <Buttons>
        <Button
          type="button"
          text="Cancelar"
          typeStyle="default"
          backgroundHoverColor="#C9CBCF"
          onClick={onCancel}
        />
      </Buttons>
    </ShapeModal>
  );
};
