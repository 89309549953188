import React from 'react';
import { Card, Container } from './styles';
import { ActionButton } from '../../../../../../../components/action-button';
import { FiEdit, FiTrash2 } from 'react-icons/fi';

export const Columns: React.FC = () => {
  return (
    <Container>
      <Card>
        <header>
          <strong>Coluna 1</strong>

          <div>
            <ActionButton tooltip="Editar" onClick={() => null}>
              <FiEdit />
            </ActionButton>
            <ActionButton tooltip="Remover" onClick={() => null}>
              <FiTrash2 className="delete-icon" />
            </ActionButton>
          </div>
        </header>

        <div>
          <span>
            Tipo de estrutura:<strong>Fibrocimento</strong>
          </span>
          <span>
            Fileiras:<strong>3</strong>
          </span>
          <span>
            Placas:<strong>56</strong>
          </span>
          <span>
            Orientação:<strong>Norte</strong>
          </span>
        </div>
      </Card>
      <Card>
        <header>
          <strong>Coluna 1</strong>

          <div>
            <ActionButton tooltip="Editar" onClick={() => null}>
              <FiEdit />
            </ActionButton>
            <ActionButton tooltip="Remover" onClick={() => null}>
              <FiTrash2 className="delete-icon" />
            </ActionButton>
          </div>
        </header>
        <div>
          <span>
            Tipo de estrutura:<strong>Fibrocimento</strong>
          </span>
          <span>
            Fileiras:<strong>3</strong>
          </span>
          <span>
            Placas:<strong>56</strong>
          </span>
          <span>
            Orientação:<strong>Norte</strong>
          </span>
        </div>
      </Card>
      <Card>
        <header>
          <strong>Coluna 1</strong>

          <div>
            <ActionButton tooltip="Editar" onClick={() => null}>
              <FiEdit />
            </ActionButton>
            <ActionButton tooltip="Remover" onClick={() => null}>
              <FiTrash2 className="delete-icon" />
            </ActionButton>
          </div>
        </header>
        <div>
          <span>
            Tipo de estrutura:<strong>Fibrocimento</strong>
          </span>
          <span>
            Fileiras:<strong>3</strong>
          </span>
          <span>
            Placas:<strong>56</strong>
          </span>
          <span>
            Orientação:<strong>Norte</strong>
          </span>
        </div>
      </Card>
      <Card>
        <header>
          <strong>Coluna 1</strong>

          <div>
            <ActionButton tooltip="Editar" onClick={() => null}>
              <FiEdit />
            </ActionButton>
            <ActionButton tooltip="Remover" onClick={() => null}>
              <FiTrash2 className="delete-icon" />
            </ActionButton>
          </div>
        </header>
        <div>
          <span>
            Tipo de estrutura:<strong>Fibrocimento</strong>
          </span>
          <span>
            Fileiras:<strong>3</strong>
          </span>
          <span>
            Placas:<strong>56</strong>
          </span>
          <span>
            Orientação:<strong>Norte</strong>
          </span>
        </div>
      </Card>
    </Container>
  );
};

