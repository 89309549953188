import styled from 'styled-components';

export const Container = styled.div``;

export const Wrapper = styled.div``;

export const PageHeader = styled.div`
  display: flex;
  justify-content: space-between;

  margin-bottom: 2rem;

  span {
    font-weight: 700;
    font-size: 2rem;
    line-height: 2rem;

    color: #292929;
  }
`;
