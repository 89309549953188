import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { Container, Wrapper, PageHeader } from '../styles';
import { ProjectStatus, UploadTypes } from '../types';
import { FormContainer, InputGroup } from '../../styles';
import FileUploadService from '../../../services/FileUploadService';
import { Files } from '../../../types/files';
import { Button, Breadcrumb, FileUpload } from '../../../components/';
import projectsApi from '../../../services/projects';
import { ButtonContainer } from './style';

type InspectionRequestPageProps = {
  id: string;
};

type SendProjectType = {
  status: string;
};

const EngineeringProjectFilesPage: React.FC = () => {
  const history = useHistory();
  const formRef = useRef<FormHandles>(null);
  const { id } = useParams<InspectionRequestPageProps>();
  const [files, setFiles] = useState<Files[]>([]);

  useEffect(() => {
    FileUploadService.getFiles(id, '').then((res) => {
      const normalizied = res.data.map((file: any) => ({
        id: file.id,
        name: file.originalName,
        url: file.path,
        type: file.type,
      }));
      setFiles(normalizied);
    });

    setValue('status', ProjectStatus.PROJETO_ENVIADO);
  }, []);

  const links = [
    {
      id: 1,
      title: 'Projetos',
      link: '/projetos',
      active: false,
    },
    {
      id: 2,
      title: 'Lista de Projetos',
      link: '/projetos',
      active: true,
    },
  ];

  const schema = yup.object().shape({
    status: yup.string().required('Concessionária é um campo obrigatório'),
  });

  const {
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<SendProjectType>({ resolver: yupResolver(schema) });

  const getFiles = (type: string) => {
    return files.filter((file) => file.type === type);
  };

  const onSubmit = async (data: SendProjectType) => {
    try {
      await projectsApi.patch(`/api/projects/${id}/status`, {
        status: data.status,
      });
      toast.success('Status atualizado com sucesso');
      history.push('/projetos/engenharia/listar');
    } catch (error: any) {
      toast.error(error.response.data.message);
    }
  };

  return (
    <Container>
      <Breadcrumb links={links} />
      <Wrapper>
        <PageHeader>
          <span>Enviar arquivos do projeto</span>
        </PageHeader>
        <FormContainer>
          <Form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
            <InputGroup>
              <FileUpload
                title="1. arquivos do projeto"
                helpMessage="Arquivos do projeto a serem anviados para o cliente."
                loadedFiles={getFiles(UploadTypes.ARQUIVOS_PROJETO)}
                projectId={id}
                type={UploadTypes.ARQUIVOS_PROJETO}
                isMultiple={false}
              />
            </InputGroup>
            <ButtonContainer>
              <Button
                type="submit"
                text="Concluir"
                backgroundColor="#000000"
                backgroundHoverColor="#292929"
              />
            </ButtonContainer>
          </Form>
        </FormContainer>
      </Wrapper>
    </Container>
  );
};

export default EngineeringProjectFilesPage;
