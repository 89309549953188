import React, { useState } from 'react';
import DynamicFilter from '../../../../../../components/dynamic-filter';
import { SearchInput } from '../../../../../../components/search';
import FranchisesTable, {
  ColumnStructure,
} from '../../../../../../components/table';
import { SectionContainer, Title } from '../../styles';
import { TableHeader } from './styles';

export const Table: React.FC = () => {
  const [tableColumns, setTableColumns] = useState<ColumnStructure[]>([
    {
      id: 'city',
      label: 'Cidade',
      orderBy: 'DESC',
      onClick: () => handleOrderBy('city'),
    },
    {
      id: 'sales',
      label: 'Vendas',
      orderBy: 'DESC',
      onClick: () => handleOrderBy('sales'),
    },
    {
      id: 'kitsSold',
      label: 'Kits vendidos',
      orderBy: 'DESC',
      onClick: () => handleOrderBy('kitsSold'),
    },
    {
      id: 'itemsSold',
      label: 'Itens vendidos',
      orderBy: 'DESC',
      onClick: () => handleOrderBy('itemsSold'),
    },
    {
      id: 'ticket',
      label: 'Ticket Médio',
      orderBy: 'DESC',
      onClick: () => handleOrderBy('ticket'),
    },
    {
      id: 'share',
      orderBy: 'DESC',
      label: 'Share',
      onClick: () => handleOrderBy('share'),
    },
  ]);
  const [tableItemns, setTableItems] = useState([
    {
      id: '1',
      city: 'São José do Rio Preto',
      sales: 'R$ 5.000.000,00',
      kitsSold: '100',
      itemsSold: '100',
      ticket: 'R$ 50.000,00',
      share: '70%',
    },
    {
      id: '2',
      city: 'Mirassol',
      sales: 'R$ 5.000.000,00',
      kitsSold: '100',
      itemsSold: '100',
      ticket: 'R$ 50.000,00',
      share: '15%',
    },
    {
      id: '3',
      city: 'Jaci',
      sales: 'R$ 5.000.000,00',
      kitsSold: '100',
      itemsSold: '100',
      ticket: 'R$ 50.000,00',
      share: '7%',
    },
    {
      id: '4',
      city: 'Monte Aprazivel',
      sales: 'R$ 5.000.000,00',
      kitsSold: '100',
      itemsSold: '100',
      ticket: 'R$ 50.000,00',
      share: '8%',
    },
  ]);

  const [orderBy, setOrderBy] = useState('');

  const handleOrderBy = (columnId: string) => {
    setTableColumns((state) => {
      return state.map((column) => {
        const currentColumn = state.find((column) => column.id === columnId);

        setOrderBy(String(currentColumn?.id + ',' + currentColumn?.orderBy));

        return column.id === columnId
          ? column.orderBy === 'ASC'
            ? { ...column, orderBy: 'DESC' }
            : { ...column, orderBy: 'ASC' }
          : { ...column, orderBy: column.orderBy ? 'DESC' : '' };
      });
    });
  };

  return (
    <SectionContainer>
      <Title>Vendas por cidade</Title>

      <FranchisesTable
        header={
          <TableHeader>
            <SearchInput
              placeholder="Digite aqui para pesquisar"
              onTextChange={() => null}
              name="kitsSearch"
            />
            <DynamicFilter filters={[]} onChange={() => null} />
          </TableHeader>
        }
        columns={tableColumns}
        items={tableItemns}
        isLoading={false}
        pagination={{ currentPage: 1, lastPage: 1, limit: 10, total: 1 }}
        onLimitChange={() => null}
        onPageChange={() => null}
      />
    </SectionContainer>
  );
};
