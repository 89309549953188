import styled, { css } from 'styled-components';

export const Container = styled.div`
  @media (max-width: 520px) {
    margin: 2rem -5%;
  }
`;

export const TableHeader = styled.header`
  border-width: 1px 1px 0;
  border-color: #e0e0e0;
  border-style: solid;
  border-radius: 8px 8px 0 0;

  padding: 1.25rem 1rem;

  background-color: #fafafa;

  @media (max-width: 520px) {
    border-width: 1px 0 0;

    border-radius: unset;
  }
`;

export const TableWrapper = styled.div`
  overflow-x: auto;

  border-left: 1px solid #e0e0e0;
  border-right: 1px solid #e0e0e0;

  cursor: grab;

  & .undraggable {
    cursor: initial;

    width: fit-content;
  }

  & .undraggable:has(p) {
    width: 100%;
  }

  @media (max-width: 520px) {
    border-left: unset;
    border-right: unset;
  }
`;

export const TableElement = styled.table`
  width: 100%;

  border-collapse: collapse;

  border-top: 1px solid #e0e0e0;
`;

export const Head = styled.thead``;

export const HeadRow = styled.tr`
  width: 100%;

  background-color: #fafafa;
`;

export const Title = styled.th<{
  actionCell: boolean;
  hasCheckbox?: boolean;
  isCentered?: boolean;
}>`
  & > div {
    display: flex;

    ${(props) =>
      props.isCentered &&
      css`
        justify-content: center;
      `}

    align-items: center;
    gap: 0.5rem;

    padding: ${(props) => (props.hasCheckbox ? '0' : '0 2rem')};

    height: 3.5rem;

    border-bottom: 1px solid #e0e0e0;

    & > span {
      display: ${(props) => (props.actionCell ? 'none' : 'inline-block')};

      font-family: 'Montserrat';
      font-weight: 600;
      font-size: 0.875rem;

      white-space: nowrap;

      color: #27282b;
    }
  }
`;

export const OrderBy = styled.button<{ orderBy: string }>`
  font-size: 0;

  & > svg {
    color: #27282b;

    font-size: 1rem;

    cursor: pointer;

    transition: transform 200ms ease-in-out;

    transform: ${(props) =>
      props.orderBy === 'ASC' ? 'rotate(180deg)' : 'rotate(0)'};
  }
`;

export const Body = styled.tbody<{ isLoading?: boolean }>`
  ${(props) =>
    props.isLoading &&
    css`
      & > tr > td {
        padding: 0 2rem;

        font-size: 2rem;
      }
    `}
`;

export const BodyRow = styled.tr`
  background-color: white;

  transition: background-color 300ms;

  &:nth-child(odd) {
    background-color: rgba(221, 223, 228, 0.1);
  }
`;

export const Cell = styled.td<{
  hasEllipsis?: boolean;
  hasMultipleLines?: boolean;
  hasCheckbox?: boolean;
  isCentered?: boolean;
  isEnded?: boolean;
}>`
  & > div {
    display: flex;
    align-items: center;

    position: relative;

    padding: ${(props) => (props.hasCheckbox ? '0' : '0 2rem')};

    height: 3.5rem;

    border-bottom: 1px solid #e0e0e0;

    ${(props) =>
      props.isCentered &&
      css`
        justify-content: center;
      `}

    ${(props) =>
      props.isEnded &&
      css`
        justify-content: flex-end;
      `}

    & > div {
      &::first-line {
        font-family: 'Montserrat';
        font-weight: 500;
        font-size: 0.875rem;
        color: #27282b;
      }

      ${(props) =>
        props.hasMultipleLines &&
        css`
          font-family: 'Montserrat';
          font-weight: 500;
          font-size: 0.875rem;
          line-height: 1rem;
          color: #61646b;
        `}

      white-space: ${(props) =>
        props.hasMultipleLines ? 'break-spaces' : 'nowrap'};

      & > p {
        text-align: center;
      }

      ${(props) =>
        props.hasEllipsis &&
        css`
          overflow: hidden;
          text-overflow: ellipsis;

          max-width: 8rem;
        `}
    }

    & > p.error-message {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      position: absolute;
      bottom: 0.25rem;

      font-family: 'Montserrat';
      font-weight: 500;
      font-size: 0.75rem;
      line-height: 1rem;
      color: #e01919;
    }
  }
`;

export const Pagination = styled.footer<{
  hasPrevious: boolean;
  hasNext: boolean;
}>`
  padding: 1.5rem 3.375rem;

  height: 4rem;

  background-color: #fafafa;

  border-width: 0 1px 1px;
  border-color: #e0e0e0;
  border-style: solid;
  border-radius: 0px 0px 8px 8px;

  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 0.5rem;

  & > span {
    display: flex;
    align-items: center;
    gap: 0.75rem;

    font-family: 'Montserrat';
    font-weight: 500;
    font-size: 0.875rem;

    color: #27282b;

    @media (max-width: 520px) {
      &:first-child {
        display: none;
      }

      margin-right: auto;
    }
  }

  & > svg {
    width: 24px;
    height: 24px;

    color: #27282b;

    cursor: pointer;
  }

  ${(props) =>
    !props.hasNext &&
    css`
      & > .next {
        color: #c9cbcf;

        cursor: not-allowed;
      }
    `}
  ${(props) =>
    !props.hasPrevious &&
    css`
      & > .prev {
        color: #c9cbcf;
        cursor: not-allowed;
      }
    `}

    @media (max-width: 992px) {
    padding: 1.5rem 5%;
  }

  @media (max-width: 520px) {
    border-width: 0 0 1px;

    border-radius: unset;
  }
`;

export const NotFoundMessage = styled.p`
  display: flex;
  justify-content: center;

  width: 100%;

  padding: 3rem 0;

  font-family: 'Montserrat';
  font-weight: 500;
  font-size: 1.125rem;

  color: #27282b;
`;
