import styled from 'styled-components';

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.5rem;
`;

export const Description = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;

  & > p {
    display: flex;
    align-items: center;
    gap: 0.25rem;
  }
`;

type SquareProps = {
  color: string;
};

export const Square = styled.span<SquareProps>`
  width: 1rem;
  height: 1rem;

  display: inline-block;

  border-radius: 0.25rem;
  background-color: ${(props) => props.color};
`;

export const Wrapper = styled.div`
  display: flex;
  gap: 1.5rem;

  width: 100%;

  @media (max-width: 992px) {
    flex-direction: column;
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  width: 100%;

  flex: 1;

  overflow: auto;

  &::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: 1595px) {
    flex-direction: row;
  }
`;

export const CardsWrapper = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 2rem;

  background-color: #fff;
  border: 1px solid #c9cbcf;
  border-radius: 8px;

  padding: 1.5rem 2rem;

  & > h3 {
    font-weight: 500;
    font-size: 1rem;
    line-height: 100%;
    text-align: center;
    color: #61646b;
  }

  & > h3.special {
    font-size: 1.5rem;
    font-weight: 600;
    color: #00299b;
  }

  @media (max-width: 1595px) {
    max-width: 22rem;
  }
`;

export const Cards = styled.div`
  height: 100%;
  width: 100%;

  overflow-x: auto;

  display: flex;
  justify-content: space-between;
  gap: 1rem;

  cursor: grab;

  ::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: 1595px) {
    flex-direction: column;
  }

  @media (max-width: 992px) {
    flex-wrap: wrap;
  }
`;
