import { yupResolver } from '@hookform/resolvers/yup';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import React, { useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as yup from 'yup';

import axios from 'axios';
import { BRFlag, Breadcrumb, Button } from '../../../components';
import Input from '../../../components/input';
import InputPhone from '../../../components/input-phone';
import Loading from '../../../components/loading';
import MaskInput from '../../../components/mask-input';
import SelectInput, { Option } from '../../../components/select';
import Textarea from '../../../components/textarea';
import { useAuth } from '../../../contexts/auth';
import franchisesApi from '../../../services/franchises';
import { UserRoles } from '../../../types/users';
import { modalityOptions, statusOptions } from '../selectOptions';
import { FranchiseForm, FranchiseType, UsersType } from '../types';
import {
  ButtonGroup,
  Container,
  Divider,
  FormContainer,
  Group,
  GroupHeader,
  GroupedInputs,
  InputGroup,
  PageHeader,
  Wrapper,
} from './../../styles';

type NewFranchisePageProps = {
  id: string;
};

const NewFranchisePage: React.FC = () => {
  const history = useHistory();

  const formRef = useRef<FormHandles>(null);

  const [users, setUsers] = useState<UsersType[]>([]);

  const [franchise, setFranchises] = useState<FranchiseType>();

  const { id } = useParams<NewFranchisePageProps>();

  const links = [
    {
      id: 1,
      title: 'Franquias',
      link: '/franquias',
      active: false,
    },
    {
      id: 2,
      title: !id ? 'Nova' : 'Editar',
      link: !id ? '/franquia/nova' : `/franquia/${id}/editar`,
      active: true,
    },
  ];

  const [loading, setLoading] = useState(false);

  const customerSuccessOptions = users
    .filter((user) => user.role === UserRoles.CONSULTOR_SUCESSO)
    .map((user) => ({
      label: user.name,
      value: user.id,
    }));

  const schema = yup.object().shape({
    status: yup.object().nullable().required('Status é um campo obrigatório'),
    modality: yup
      .object()
      .nullable()
      .required('Modalidade é um campo obrigatório'),
    customerSuccessResponsible: yup
      .object()
      .nullable()
      .required('Consultor de sucesso é um campo obrigatório'),
    cnpj: yup.string().required('CNPJ é um campo obrigatório'),
    name: yup.string().required('Razão social é um campo obrigatório'),
    nickname: yup.string().required('Apelido é um campo obrigatório'),
    email: yup
      .string()
      .email('Digite um e-mail válido')
      .required('E-mail é um campo obrigatório'),
    phone: yup.string().optional().nullable(),
    cellphone: yup.string().optional().nullable(),
    zipcode: yup.string().required('CEP é um campo obrigatório'),
    state: yup.string().required('Estado é um campo obrigatório'),
    city: yup.string().required('Cidade é um campo obrigatório'),
    address: yup.string().required('Endereço é um campo obrigatório'),
    district: yup.string().required('Bairro é um campo obrigatório'),
    buildingNumber: yup.string().required('Número é um campo obrigatório'),
  });

  const {
    handleSubmit,
    formState: { errors },
    control,
    register,
    reset,
  } = useForm<FranchiseForm>({
    resolver: yupResolver(schema),
  });

  const getUsers = async () => {
    try {
      const { data } = await franchisesApi.get<UsersType[]>(
        '/api/franchises/users/list'
      );

      setUsers(data);

      return data;
    } catch (error) {
      return error;
    }
  };

  const getFranchise = async (customersSuccess: Option[]) => {
    try {
      const { data } = await franchisesApi.get<FranchiseType>(
        `/api/franchises/${id}`
      );

      reset({
        status: statusOptions.find((status) => status.value === data.status),
        modality: modalityOptions.find(
          (modality) => modality.value === data.modality
        ),
        customerSuccessResponsible: customersSuccess.find(
          (customerSuccess) =>
            customerSuccess.value === data.customerSuccessResponsible
        ),
        cnpj: data.cnpj,
        name: data.name,
        nickname: data.nickname,
        franchiseUserId: data.franchiseUserId,
        email: data.email,
        emailSecondary: data.emailSecondary,
        phone: data.phone,
        cellphone: data.cellphone,
        zipcode: data.zipcode,
        state: data.state,
        city: data.city,
        address: data.address,
        district: data.district,
        buildingNumber: data.buildingNumber,
        complement: data.complement,
        observations: data.observations,
      });

      setFranchises(data);
    } catch (error) {
      return error;
    }
  };

  const handleEditFranchise = async () => {
    setLoading(true);

    try {
      const users = (await getUsers()) as UsersType[];

      const customersSuccess = users
        .filter((user) => user.role === UserRoles.CONSULTOR_SUCESSO)
        .map((user) => ({
          label: user.name,
          value: user.id,
        }));

      await getFranchise(customersSuccess);
    } catch (error) {
      toast.error('Desculpe, não foi possível buscar os dados dessa franquia.');

      throw error;
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getUsers();

    if (id) {
      handleEditFranchise();
    }
  }, []);

  const { user, updateUserFranchises } = useAuth();

  const onSubmit = async (data: FranchiseForm) => {
    try {
      const requestData = {
        ...data,
        phone: data.phone || undefined,
        cellphone: data.cellphone || undefined,
        status: data.status.value,
        modality: data.modality.value,
        customerSuccessResponsible: data.customerSuccessResponsible.value,
      };

      if (id) {
        await franchisesApi.put(`/api/franchises/${id}`, requestData);
      } else {
        await franchisesApi.post('/api/franchises', requestData);
      }

      const successMessage = id
        ? 'Franquia editada com sucesso'
        : 'Franquia cadastrada com sucesso';

      toast.success(successMessage);

      history.push(`/franquias/`);

      if (id) {
        updateUserFranchises(user.id, id);
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        toast.error(error.response?.data.message);
      } else {
        toast.error('Erro desconhecido, tente novamente mais tarde.');
      }
    }
  };

  if (loading) {
    return <Loading style={{ alignItems: 'center', height: '100%' }} />;
  }

  return (
    <Container>
      <Breadcrumb links={links} />
      <Wrapper>
        <PageHeader>
          <span>{id ? 'Editar franquia' : 'Nova franquia'}</span>
        </PageHeader>
        <FormContainer>
          <Form
            ref={formRef}
            initialData={franchise}
            onSubmit={handleSubmit(onSubmit)}
          >
            <Group>
              <GroupHeader>
                <h2>Dados da franquia</h2>
              </GroupHeader>
              <GroupedInputs>
                <InputGroup sideSpace="right">
                  <Controller
                    name="status"
                    control={control}
                    render={({
                      field: { onChange, value, ref },
                      fieldState: { error },
                    }) => {
                      return (
                        <SelectInput
                          ref={ref}
                          label="Status"
                          required
                          name="status"
                          placeholder="Selecione um status"
                          options={statusOptions}
                          error={error?.message}
                          onChange={onChange}
                          value={value}
                        />
                      );
                    }}
                  />
                </InputGroup>
                <InputGroup sideSpace="right">
                  <Controller
                    name="modality"
                    control={control}
                    render={({
                      field: { onChange, value, ref },
                      fieldState: { error },
                    }) => {
                      return (
                        <SelectInput
                          label="Modalidade"
                          required
                          name="modality"
                          ref={ref}
                          placeholder="Selecione uma modalidade"
                          options={modalityOptions}
                          error={error?.message}
                          onChange={onChange}
                          value={value}
                        />
                      );
                    }}
                  />
                </InputGroup>
                <InputGroup>
                  <Controller
                    name="customerSuccessResponsible"
                    control={control}
                    render={({
                      field: { onChange, value, ref },
                      fieldState: { error },
                    }) => {
                      return (
                        <SelectInput
                          label="Consultor de sucesso"
                          required
                          name="customerSuccessResponsible"
                          ref={ref}
                          placeholder="Selecione um consultor"
                          options={customerSuccessOptions}
                          error={error?.message}
                          onChange={onChange}
                          value={value}
                        />
                      );
                    }}
                  />
                </InputGroup>
              </GroupedInputs>
            </Group>
            <Group>
              <GroupedInputs>
                <InputGroup sideSpace="right">
                  <MaskInput
                    label="CNPJ"
                    mask="99.999.999/9999-99"
                    placeholder="Digite o CNPJ"
                    required
                    {...register('cnpj')}
                    error={errors.cnpj?.message}
                  />
                </InputGroup>
                <InputGroup sideSpace="right">
                  <Input
                    required
                    label="Razão social"
                    placeholder="Digite a razão social"
                    {...register('name')}
                    error={errors.name?.message}
                  />
                </InputGroup>
                <InputGroup>
                  <Input
                    required
                    placeholder="Digite o apelido"
                    error={errors.nickname?.message}
                    label="Apelido"
                    {...register('nickname')}
                  />
                </InputGroup>
              </GroupedInputs>
            </Group>
            <Divider />
            <Group>
              <GroupHeader>
                <h2>Informações de contato</h2>
              </GroupHeader>
              <GroupedInputs>
                <InputGroup sideSpace="right">
                  <Input
                    required
                    placeholder="Digite o e-mail"
                    error={errors.email?.message}
                    label="E-mail"
                    {...register('email')}
                  />
                </InputGroup>
                <InputGroup sideSpace="right">
                  <Input
                    placeholder="Digite o e-mail secundário"
                    error={errors.emailSecondary?.message}
                    label="E-mail secundário"
                    {...register('emailSecondary')}
                  />
                </InputGroup>
              </GroupedInputs>
            </Group>
            <Group>
              <GroupedInputs>
                <InputGroup sideSpace="right">
                  <InputPhone
                    label="Celular"
                    mask="(99) 99999-9999"
                    placeholder="Digite o celular"
                    iconLeft={<BRFlag />}
                    error={errors.cellphone?.message}
                    {...register('cellphone')}
                  />
                </InputGroup>
                <InputGroup sideSpace="right">
                  <InputPhone
                    label="Celular 2"
                    mask="(99) 9999-9999"
                    placeholder="Digite o celular 2"
                    iconLeft={<BRFlag />}
                    error={errors.phone?.message}
                    {...register('phone')}
                  />
                </InputGroup>
              </GroupedInputs>
            </Group>
            <Divider />
            <Group>
              <GroupHeader>
                <h2>Localização</h2>
              </GroupHeader>
              <GroupedInputs>
                <InputGroup sideSpace="right">
                  <MaskInput
                    label="CEP"
                    mask="99999-999"
                    placeholder="Digite o CEP"
                    required
                    {...register('zipcode')}
                    error={errors.zipcode?.message}
                  />
                </InputGroup>
                <InputGroup sideSpace="right">
                  <Input
                    required
                    placeholder="Digite o estado"
                    error={errors.state?.message}
                    label="Estado"
                    {...register('state')}
                  />
                </InputGroup>
                <InputGroup>
                  <Input
                    required
                    placeholder="Digite a cidade"
                    error={errors.city?.message}
                    label="Cidade"
                    {...register('city')}
                  />
                </InputGroup>
              </GroupedInputs>
            </Group>
            <Group>
              <GroupedInputs>
                <InputGroup>
                  <Input
                    required
                    placeholder="Digite o endereço"
                    error={errors.address?.message}
                    label="Endereço"
                    {...register('address')}
                  />
                </InputGroup>
              </GroupedInputs>
            </Group>
            <Group>
              <GroupedInputs>
                <InputGroup sideSpace="right">
                  <Input
                    required
                    placeholder="Digite o bairro"
                    error={errors.district?.message}
                    label="Bairro"
                    {...register('district')}
                  />
                </InputGroup>
                <InputGroup sideSpace="right">
                  <MaskInput
                    label="Número"
                    placeholder="Digite o número"
                    mask="99999999999"
                    required
                    error={errors.buildingNumber?.message}
                    {...register('buildingNumber')}
                  />
                </InputGroup>
                <InputGroup>
                  <Input
                    placeholder="Digite o complemento"
                    error={errors.complement?.message}
                    label="Complemento"
                    {...register('complement')}
                  />
                </InputGroup>
              </GroupedInputs>
            </Group>
            <Group>
              <GroupedInputs>
                <InputGroup>
                  <Textarea
                    resize="vertical"
                    height="12.5rem"
                    label="Observações"
                    {...register('observations')}
                  />
                </InputGroup>
              </GroupedInputs>
            </Group>
            <Group>
              <GroupedInputs>
                <InputGroup>
                  <ButtonGroup>
                    <Button text="Salvar" backgroundColor="#005AF9" />
                  </ButtonGroup>
                </InputGroup>
              </GroupedInputs>
            </Group>
          </Form>
        </FormContainer>
      </Wrapper>
    </Container>
  );
};

export default NewFranchisePage;
