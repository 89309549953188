import styled, { css } from 'styled-components';

type ButtonStyleProps = {
  backgroundColor?: string;
  backgroundHoverColor?: string;
  fontColor?: string;
  disabled?: boolean;
  size?: 'large';
  width: string;
};

export const Container = styled.button<ButtonStyleProps>`
  width: ${(props) => props.width};
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  font-weight: 600;
  font-size: 0.875rem;
  background-color: ${(props) => props.backgroundColor};
  color: ${(props) => (props.fontColor ? props.fontColor : '#ffffff')};
  padding: 0 1.75rem;
  border-radius: 8px;
  position: relative;

  & > svg {
    margin-right: 0.2rem;

    & > path {
      stroke: #27282b;
    }
  }

  span.message {
    display: flex;
    align-items: center;
    gap: 0.375rem;

    & > svg {
      width: 0.875rem;
      height: 0.875rem;

      & > path {
        stroke: #323232;
      }
    }

    position: absolute;
    left: 0;
    bottom: -1.25rem;

    font-weight: 500;
    font-size: 0.875rem;

    color: #61646b;
  }

  &:not(:disabled):hover {
    background-color: ${(props) => props.backgroundHoverColor};
  }

  img {
    margin-right: 0.5rem;
  }

  ${(props) =>
    props.disabled &&
    css`
      cursor: default;

      opacity: 0.4;
    `}

  ${(props) =>
    props.size === 'large' &&
    css`
      width: 100%;
    `}
`;
