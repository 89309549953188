import React from 'react';

import { Breadcrumb, ImportIcon } from '../../../components';
import {
  Container,
  PageHeader,
  Wrapper,
  LinkBoxGroup,
  LinkBox
} from '../../styles';

const ProductsListPage: React.FC = () => {
  const links = [
    {
      id: 1,
      title: 'Produtos',
      link: '/produtos',
      active: false,
    },
    {
      id: 2,
      title: 'Lista de produtos',
      link: '/produtos/lista',
      active: true,
    },
  ];

  return (
    <Container>
      <Breadcrumb links={links} />
      <Wrapper>
        <PageHeader>
          <span>{"Lista de Produtos"}</span>
        </PageHeader>

        <LinkBoxGroup>
          <LinkBox href='/produtos/lista/kit-pronto'>
            <ImportIcon />
            <span>Kits prontos</span>
            <p>Veja a lista com os kits prontos cadastrados.</p>
          </LinkBox>
          <LinkBox href='/produtos/lista/kit-personalizado'>
            <ImportIcon />
            <span>Kits personalizados</span>
            <p>Veja a lista com os produtos cadastrados para kits personalizados.</p>
          </LinkBox>
        </LinkBoxGroup>

      </Wrapper>
    </Container>
  );
};

export default ProductsListPage;
