import React, { useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';

import { FiPlusCircle, FiTrash2 } from 'react-icons/fi';

import { ButtonGroup, Container, PageHeader } from '../../../styles';
import {
  Accessories,
  Content,
  InputContainer,
  TableHeader,
  TotalValue,
} from './styles';

import {
  ActionButton,
  Breadcrumb,
  Button,
  SearchInput,
  StepProgress,
} from '../../../../components';
import Input from '../../../../components/input';
import InputPrice from '../../../../components/input-price';
import MaskInput from '../../../../components/mask-input';
import SelectInput from '../../../../components/select';
import Table, {
  ColumnStructure,
  TableData,
} from '../../../../components/table';
import useMediaQuery from '../../../../hooks/mediaQuery';
import { normalize } from '../../../../utils/normalize';
import { toMoneyFormat } from '../../../../utils/toMoneyFormat';

import { debounce } from 'lodash';
import { toast } from 'react-toastify';
import Loading from '../../../../components/loading';
import InnerActions from '../../../../components/table/innerActions';
import { useOrders } from '../../../../contexts/orders';
import productsApi from '../../../../services/products';
import { EMPTY_PAGE, PaginatedResponse } from '../../../../types/pagination';
import { accessoriesType, units } from './selectOptions';

const steps = [
  { key: 1, title: 'Selecionar kit', active: false, complete: true },
  { key: 2, title: 'Resumo', active: false, complete: true },
  { key: 3, title: 'Acessórios', active: true, complete: false },
  { key: 4, title: 'Pagamento', active: false, complete: false },
  { key: 5, title: 'Documentos', active: false, complete: false },
  { key: 6, title: 'Termo de aceite', active: false, complete: false },
];

type Option = {
  label: string;
  value: string;
};

type AccessoriesTableItems = {
  product: string;
  value: string;
  actions: JSX.Element;
};

type Accessories = {
  budgetId: string;
  description: string;
  measureUnity: string;
  quantity: number;
  type: string;
  value: number;
  total: number;
  id: string;
  createdAt: string;
  updatedAt: string;
};

type Form = {
  type: Option;
  description: string;
  quantity: string;
  measureUnity: Option;
  value: string;
};

const Schema = yup.object().shape({
  type: yup.object().nullable().required('Selecione o tipo'),
  measureUnity: yup.object().nullable().required('Selecione a unidade'),
  description: yup.string().required('Descreva o acessório'),
  quantity: yup
    .string()
    .required('Digite a quantidade')
    .test((value, { createError }) => {
      if (!value) return false;

      if (normalize(value) <= 0)
        return createError({
          path: 'quantity',
          message: 'Digite a quantidade',
          type: 'field-error',
        });

      return true;
    }),
  value: yup
    .string()
    .required('Digite o valor')
    .test((value, { createError }) => {
      if (!value) return false;

      if (normalize(value) <= 0)
        return createError({
          path: 'value',
          message: 'Digite o valor',
          type: 'field-error',
        });

      return true;
    }),
});

const OrdersAccessoriesPage: React.FC = () => {
  const { order, getOrder } = useOrders();

  const { id: orderId } = useParams<{ id: string }>();

  const [loading, setLoading] = useState(true);

  const links = [
    {
      id: 1,
      title: 'Orçamentos',
      link: '/orcamentos',
      active: false,
    },
    {
      id: 2,
      title: 'Orçamentos e pedidos',
      link: '/orcamentos/orcamentos-e-pedidos',
      active: false,
    },
    {
      id: 3,
      title: 'Gerar pedido',
      link: '/orcamentos/orcamentos-e-pedidos/',
      active: false,
    },
    {
      id: 4,
      title: 'Selecionar kit',
      link: '/orcamentos/orcamentos-e-pedidos/gerar-pedido/selecione',
      active: false,
    },
    {
      id: 5,
      title: 'Resumo',
      link: `/orcamentos/orcamentos-e-pedidos/gerar-pedido/${orderId}/resumo`,
      active: false,
    },
    {
      id: 6,
      title: 'Acessórios',
      link: `/orcamentos/orcamentos-e-pedidos/gerar-pedido/${orderId}/acessorios`,
      active: true,
    },
  ];

  const budgetId = order?.budgetId;

  const history = useHistory();

  const isMobile = useMediaQuery('(max-width: 720px)');

  const {
    control,
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors, isValid },
  } = useForm<Form>({
    resolver: yupResolver(Schema),
    mode: 'onChange',
    defaultValues: {
      value: '',
      quantity: '',
      description: '',
      measureUnity: undefined,
      type: undefined,
    },
  });

  const [accessories, setAccessories] =
    useState<PaginatedResponse<Accessories>>(EMPTY_PAGE);

  const tableColumns: ColumnStructure[] = [
    {
      id: 'product',
      label: 'Produto',
      hasMultipleLines: true,
    },
    {
      id: 'value',
      label: 'Valor',
    },
    {
      id: 'actions',
      label: '',
      type: 'actionCell',
      isCentered: true,
    },
  ];

  const tableData: TableData<AccessoriesTableItems>[] = useMemo(() => {
    return accessories.content.map((accessory) => {
      const { id, measureUnity, type, description, quantity, total } =
        accessory;

      const normalizedMeasureUnity =
        quantity < 2
          ? measureUnity.replace(/s\b/g, '').toLowerCase()
          : measureUnity.toLowerCase();

      const productLabel = `${type}\n${description} - ${quantity} ${normalizedMeasureUnity}`;

      return {
        id: id,
        product: productLabel,
        value: toMoneyFormat(total),
        actions: (
          <InnerActions>
            <ActionButton
              tooltip="Remover acessório"
              onClick={async () => await removeAccessory(id)}
            >
              <FiTrash2 className="delete-icon" />
            </ActionButton>
          </InnerActions>
        ),
      };
    });
  }, [accessories]);

  const value = watch('value');

  const quantity = watch('quantity');

  const totalValue = toMoneyFormat(normalize(value) * Number(quantity));

  const getAccessories = async (
    page?: number,
    limit?: number,
    search?: string
  ) => {
    setAccessories((state) => ({ ...state, loading: true }));

    try {
      const { data } = await productsApi.get(`/orders/${orderId}/search`, {
        params: {
          page: page ?? accessories.pagination.currentPage,
          limit: limit ?? accessories.pagination.limit,
          search: search || undefined,
        },
      });

      setAccessories((state) => {
        return {
          ...state,
          content: data.content,
          pagination: data.pagination,
        };
      });
    } catch (error) {
      toast.error('Desculpe, não foi possível buscar os acessórios');

      throw error;
    } finally {
      setAccessories((state) => ({ ...state, loading: false }));
    }
  };

  const removeAccessory = async (acessoryId: string) => {
    try {
      await productsApi.delete(`/orders/order-accessory/${acessoryId}`);

      await getAccessories();
    } catch (error) {
      toast.error('Desculpe, não foi possível remover o acessório');

      throw error;
    }
  };

  const addAccessory = async (data: Form) => {
    if (!budgetId) {
      return;
    }

    setAccessories((state) => ({ ...state, loading: true }));

    const normalizedData = {
      type: data.type.label,
      description: data.description,
      value: normalize(data.value),
      measureUnity: data.measureUnity.label,
      quantity: Number(data.quantity),
      orderId: orderId,
    };

    try {
      await productsApi.post<Accessories>(
        '/orders/order-accessory',
        normalizedData
      );

      await getAccessories();

      reset();
    } catch (error) {
      toast.error('Desculpe, não foi possível adicionar o acessório');

      throw error;
    } finally {
      setAccessories((state) => ({ ...state, loading: false }));
    }
  };

  const [search, setSearch] = useState('');

  const isSearching = !!search.length;

  const hasAcessories = isSearching || accessories.pagination.total !== 0;

  const debounceGetAccessories = debounce(getAccessories, 300);

  const fetchData = async () => {
    if (!order) {
      await getOrder(orderId);
    }

    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    debounceGetAccessories(
      accessories.pagination.currentPage,
      accessories.pagination.limit,
      search
    );
  }, [search]);

  if (loading) {
    return <Loading />;
  }

  return (
    <Container>
      <Breadcrumb links={links} />
      <PageHeader>
        <span>Acessórios</span>
        <div>
          <StepProgress steps={steps} />
        </div>
      </PageHeader>
      <Content onSubmit={handleSubmit(addAccessory)}>
        <h2>Selecionar acessórios</h2>

        <Accessories>
          <div>
            <InputContainer size="small">
              <Controller
                name="type"
                control={control}
                render={({
                  field: { onChange, value, ref },
                  fieldState: { error },
                }) => {
                  return (
                    <SelectInput
                      ref={ref}
                      isSearchable={false}
                      isClearable
                      placeholder="Selecionar acessório"
                      label="Tipo de acessório"
                      options={accessoriesType}
                      onChange={onChange}
                      value={value ?? ''}
                      error={error?.message}
                    />
                  );
                }}
              />
            </InputContainer>

            <InputContainer>
              <Input
                placeholder="Descreva o acessório"
                label="Descrição"
                error={errors.description?.message}
                {...register('description')}
              />
            </InputContainer>
          </div>

          <div>
            <InputContainer size="small">
              <MaskInput
                mask="999999"
                placeholder="Quantidade"
                label="Quantidade"
                error={errors.quantity?.message}
                {...register('quantity')}
              />
              <Controller
                name="measureUnity"
                control={control}
                render={({
                  field: { onChange, value, ref },
                  fieldState: { error },
                }) => {
                  return (
                    <SelectInput
                      ref={ref}
                      isSearchable={false}
                      isClearable
                      placeholder="Unidades"
                      label="Unidade"
                      options={units}
                      onChange={onChange}
                      value={value ?? ''}
                      error={error?.message}
                    />
                  );
                }}
              />
            </InputContainer>

            <InputContainer>
              <Controller
                control={control}
                name="value"
                render={({
                  field: { onChange, value, ref },
                  fieldState: { error },
                }) => {
                  return (
                    <InputPrice
                      ref={ref}
                      label="Valor Unitário"
                      placeholder="R$ 0,00"
                      error={error?.message}
                      onValueChange={onChange}
                      value={value}
                    />
                  );
                }}
              />

              <TotalValue>
                <p>Valor Total</p>
                <span>{totalValue}</span>
              </TotalValue>

              {!isMobile && (
                <Button
                  type="submit"
                  style={{
                    margin: 'auto 0 0.5rem',
                    fontSize: '1rem',
                    gap: '0.25rem',
                    flex: 1,
                    maxWidth: 275,
                  }}
                  text="Adicionar"
                  disabled={!isValid}
                  icon={<FiPlusCircle fontSize="1.25rem" />}
                />
              )}
            </InputContainer>
          </div>

          {isMobile && (
            <div>
              <InputContainer>
                <Button
                  type="submit"
                  width="100%"
                  style={{ gap: '0.25rem' }}
                  text="Adicionar"
                  icon={<FiPlusCircle fontSize="1.25rem" />}
                  disabled={!isValid}
                />
              </InputContainer>
            </div>
          )}
        </Accessories>

        {hasAcessories && (
          <>
            <h2>Acessórios Selecionados</h2>

            <Table
              placeholder="Nenhum acessório encontrado"
              header={
                <TableHeader>
                  <SearchInput
                    placeholder="Digite aqui para pesquisar"
                    onTextChange={setSearch}
                    name="search"
                  />
                </TableHeader>
              }
              isLoading={accessories.loading}
              columns={tableColumns}
              items={tableData}
              pagination={accessories.pagination}
              onLimitChange={async (limit) => {
                const newLastPage = Math.ceil(
                  accessories.pagination.total / limit
                );

                const currentPage = accessories.pagination.currentPage;

                if (currentPage > newLastPage) {
                  await getAccessories(newLastPage, limit, search);
                } else {
                  await getAccessories(currentPage, limit, search);
                }
              }}
              onPageChange={async (page) =>
                await getAccessories(page, accessories.pagination.limit, search)
              }
            />
          </>
        )}

        <ButtonGroup style={{ margin: 'auto 0 0' }}>
          <Button
            type="button"
            text="Voltar"
            typeStyle="default"
            backgroundHoverColor="#C9CBCF"
            onClick={() =>
              history.push(
                `/orcamentos/orcamentos-e-pedidos/gerar-pedido/${orderId}/resumo/`
              )
            }
          />
          <Button
            type="button"
            text="Avançar"
            onClick={() =>
              history.push(
                `/orcamentos/orcamentos-e-pedidos/gerar-pedido/${orderId}/custos`
              )
            }
          />
        </ButtonGroup>
      </Content>
    </Container>
  );
};

export default OrdersAccessoriesPage;
