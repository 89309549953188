import styled from 'styled-components';

export const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  flex: 1;

  padding: 3rem 0;

  height: 100%;

  & > span {
    font-weight: 400;

    font-size: 1.125rem;

    color: #27282b;
  }

  & > span:after {
    display: inline-block;
    content: '';
    animation: dotty steps(1, end) 1.2s infinite;
  }

  @keyframes dotty {
    0% {
      content: '';
    }
    25% {
      content: '.';
    }
    50% {
      content: '..';
    }
    75% {
      content: '...';
    }
    100% {
      content: '';
    }
  }
`;

export const LoadingContent = styled.div`
  border: 6px solid;
  border-radius: 50%;

  border-color: #c8d2ef;
  border-top-color: #00299b;

  animation: Spin 1s linear infinite;

  @keyframes Spin {
    to {
      transform: rotate(360deg);
    }
  }
`;
