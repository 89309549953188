import React, { CSSProperties } from 'react';
import { LoadingContainer, LoadingContent } from './styles';

type LoadingProps = {
  size?: number;
  style?: CSSProperties;
  label?: string;
};

const Loading: React.FC<LoadingProps> = (props) => {
  const { size = 56, style, label } = props;

  return (
    <LoadingContainer style={style}>
      <LoadingContent style={{ height: size, width: size }} />
      {label && <span>{label}</span>}
    </LoadingContainer>
  );
};

export default Loading;
