import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Breadcrumb, ProductsIcon, StepProgress } from '../../../components';
import { useBudgets } from '../../../contexts/budgets';
import { Container, PageHeader, Wrapper } from '../../styles';
import { Content, ProductCard } from './styles';

const steps = [
  { key: 1, title: 'Selecionar produto', active: true, complete: false },
  { key: 2, title: 'Cadastro do cliente', active: false, complete: false },
  { key: 3, title: 'Selecionar Kits', active: false, complete: false },
  { key: 4, title: 'Serviços', active: false, complete: false },
  { key: 5, title: 'Proposta', active: false, complete: false },
];

const links = [
  {
    id: 1,
    title: 'Orçamentos',
    link: '/orcamentos',
    active: false,
  },
  {
    id: 2,
    title: 'Selecionar produto',
    link: '/orcamentos/selecionar-produto',
    active: true,
  },
];

const products = [
  {
    id: '1',
    title: 'Kit solar',
    description: 'Informações sobre o equipamento',
  },
  {
    id: '2',
    title: 'Bomba d’água',
    description: 'Informações sobre o equipamento',
  },
  {
    id: '3',
    title: 'Kit solar + carregador',
    description: 'Informações sobre o equipamento',
  },
];

const BudgetsNewPage: React.FC = () => {
  const history = useHistory();

  const { cleanBudgetFlow } = useBudgets();

  useEffect(() => {
    cleanBudgetFlow();
  }, []);

  return (
    <Container>
      <Breadcrumb links={links} />
      <Wrapper>
        <PageHeader>
          <span>{'Selecionar produto'}</span>
          <StepProgress steps={steps} />
        </PageHeader>

        <Content>
          {products.map((product) => {
            return (
              <ProductCard
                key={product.id}
                onClick={() => {
                  history.push(
                    '/orcamentos/selecionar-produto/cadastro-cliente'
                  );
                }}
              >
                <ProductsIcon />
                <h1>{product.title}</h1>
                <p>{product.description}</p>
              </ProductCard>
            );
          })}
        </Content>
      </Wrapper>
    </Container>
  );
};

export default BudgetsNewPage;
