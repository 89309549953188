import {
  Lead,
  LeadStatus,
} from '../../../../../../contexts/sales-funnel/types';

type DataCard = {
  date: string;
  hasPrice: boolean;
};

type DataCardByColumnType = {
  [key in LeadStatus]: (data: Lead) => DataCard;
};

export const DataCardByColumn = {
  agendado(data: Lead) {
    return {
      date: data.schedule?.scheduledTo,
      hasPrice: false,
    };
  },

  fechado(data: Lead) {
    return {
      date: data.updatedAt,
      hasPrice: true,
    };
  },

  negociacao(data: Lead) {
    return {
      date: data.updatedAt,
      hasPrice: true,
    };
  },

  orcamento(data: Lead) {
    return {
      date: data.updatedAt,
      hasPrice: true,
    };
  },

  perdido(data: Lead) {
    return {
      date: data.createdAt,
      hasPrice: true,
    };
  },

  propostaFinal(data: Lead) {
    return {
      date: data.createdAt,
      hasPrice: true,
    };
  },

  recuperacao(data: Lead) {
    return {
      date: data.updatedAt,
      hasPrice: true,
    };
  },

  captacao(data: Lead) {
    return {
      date: data.createdAt,
      hasPrice: true,
    };
  },
} as DataCardByColumnType;
