import React, { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  ActionButton,
  Breadcrumb,
  Button,
  SearchInput,
} from '../../components';
import { Container, PageHeader, TableHeader } from './../styles';

import { BiCoinStack } from 'react-icons/bi';
import { FiEdit, FiTruck } from 'react-icons/fi';
import { toast } from 'react-toastify';
import { DeleteProviderModal } from '../../components/modal/delete-provider';
import Table, { ColumnStructure, TableData } from '../../components/table';
import InnerActions from '../../components/table/innerActions';
import productsApi from '../../services/products';
import { PaginatedResponse } from '../../types/pagination';
import { ProviderType } from './types';
import { UserRoles } from '../../types/users';
import { useAuth } from '../../contexts/auth';

type TableItems = {
  id: string;
  name: string;
  actions: JSX.Element;
};

const ProvidersPage: React.FC = () => {
  const history = useHistory();
  const [providers, setProviders] = useState<PaginatedResponse<ProviderType>>({
    content: [],
    pagination: {
      currentPage: 0,
      lastPage: 0,
      limit: 0,
      total: 0,
    },
    loading: true,
  });
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [name, setName] = useState('');

  const [selectedId, setSelected] = useState('');
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const handlePageChange = (page: number): void => {
    setPage(page);
  };

  const handleLimitChange = (limit: number): void => {
    setLimit(limit);
  };

  const getProviders = async () => {
    setProviders((state) => ({ ...state, loading: true }));

    await productsApi
      .get(`/products/suppliers/search`, {
        params: {
          page,
          limit,
          name: name || null,
        },
      })
      .then(({ data }) => setProviders({ ...data, loading: false }))
      .catch(() => {
        setProviders((state) => ({ ...state, loading: false }));
        return toast.error('Não foi possível buscar os fornecedores!');
      });
  };

  const deleteProvider = async (id: string) => {
    await productsApi.delete('/products/suppliers/' + id);
  };

  const handleDeleteProvider = () => {
    deleteProvider(selectedId)
      .then(() => getProviders())
      .catch(() => toast.error('Não foi possível excluir esse fornecedor'));
    setModalIsOpen(false);
  };

  const links = [
    {
      id: 1,
      title: 'Fornecedores',
      link: '/fornecedores',
      active: true,
    },
  ];

  const tableColumns: ColumnStructure[] = [
    {
      id: 'name',
      label: 'Nome',
      onClick: () => {},
      orderBy: 'name',
    },
    {
      id: 'actions',
      label: 'Ações',
      type: 'actionCell',
      isEnded: true,
    },
  ];

  const { user } = useAuth();

  const isSupervision = UserRoles.SUPERVISAO.includes(user.role);

  const tableItems: TableData<TableItems>[] = useMemo(() => {
    return providers.content.map((provider) => {
      return {
        id: provider.id,
        name: provider.name,
        actions: (
          <InnerActions>
            {!isSupervision && (
              <ActionButton
                tooltip="Editar"
                onClick={() => {
                  history.push(`/fornecedores/${provider.id}/editar`);
                }}
              >
                <FiEdit />
              </ActionButton>
            )}
            <ActionButton
              tooltip="Formas de pagamento"
              onClick={() => {
                history.push(
                  `/fornecedores/${provider.id}/formas-de-pagamento`
                );
              }}
            >
              <BiCoinStack />
            </ActionButton>
            <ActionButton
              tooltip="Fretes"
              onClick={() => {
                history.push(`/fornecedores/${provider.id}/frete`);
              }}
            >
              <FiTruck />
            </ActionButton>
            {/* <ActionButton
              tooltip="Remover"
              onClick={() => {
                setSelected(provider.id);
                setModalIsOpen(true);
              }}
            >
              <FiTrash2 className="delete-icon" />
            </ActionButton> */}
          </InnerActions>
        ),
      };
    });
  }, [providers, isSupervision]);

  useEffect(() => {
    getProviders();
  }, [page, name, limit]);

  return (
    <Container>
      <DeleteProviderModal
        onCancel={() => setModalIsOpen(false)}
        onConfirm={handleDeleteProvider}
        isOpen={modalIsOpen}
      />
      <Breadcrumb links={links} />
      <PageHeader>
        <span>Lista de fornecedores</span>
      </PageHeader>

      <Table
        items={tableItems}
        columns={tableColumns}
        isLoading={providers.loading}
        header={
          <TableHeader>
            <SearchInput
              name="search"
              placeholder="Digite aqui para pesquisar"
              onTextChange={(value) => {
                setName(value);
                setPage(1);
              }}
            />
            <Button
              text="Novo Fornecedor"
              icon="add"
              onClick={() => history.push('/fornecedor/novo')}
            />
          </TableHeader>
        }
        placeholder="Nenhum fornecedor encontrado"
        pagination={providers.pagination}
        onPageChange={handlePageChange}
        onLimitChange={handleLimitChange}
      />
    </Container>
  );
};

export default ProvidersPage;
