import React from 'react';
import SelectInput from '../../../../../../../components/select';
import { ButtonWrapper, Container, InputWrapper, Wrapper } from './styles';
import Input from '../../../../../../../components/input';
import { Button } from '../../../../../../../components/button';

export const AddColumn: React.FC = () => {
  return (
    <Container>
      <strong>Coluna 1</strong>

      <Wrapper>
        <SelectInput
          label="Tipo de estrutura"
          placeholder="Selecione uma opção"
          options={[]}
        />

        <InputWrapper>
          <Input label="Fileiras" placeholder="Placeholder" />
          <Input label="Placas" placeholder="Placeholder" />
        </InputWrapper>

        <SelectInput
          label="Orientação"
          placeholder="Selecione uma opção"
          options={[]}
        />
      </Wrapper>

      <ButtonWrapper>
        <Button
          text="Cancelar"
          backgroundColor="#ED5A5A"
          backgroundHoverColor="#ed5a5abc"
          onClick={() => null}
        />

        <Button text="Adicionar" onClick={() => null} />
      </ButtonWrapper>
    </Container>
  );
};

