import styled from 'styled-components';

type ContainerProps = {
  menuOpen: boolean;
  isDisabled: boolean;
  width: string;
};

export const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  font-weight: 500;
  font-size: 1rem;

  color: #27282b;

  width: ${(props) => props.width};
`;
