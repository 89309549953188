import styled from 'styled-components';

export const Container = styled.form``;

export const Content = styled.main`
  display: flex;
  flex-direction: column;

  padding: 4rem 3rem;

  overflow-y: auto;

  max-height: 70vh;

  &::-webkit-scrollbar {
    width: 4px;
  }

  & > hr {
    margin: 4rem 0;

    border-top: 1px solid #c9cbcf;
  }
`;

export const DocumentWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 1.5rem;

  & > div {
    margin-top: 2rem;
  }

  & > label {
    min-width: 16.8rem;

    @media (max-width: 992px) {
      min-width: unset;
    }
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  & > header {
    margin-bottom: 3rem;

    & > h1 {
      font-family: 'Montserrat', sans-serif;
      font-weight: 600;
      font-size: 1.25rem;

      letter-spacing: -0.01em;

      color: #000000;
    }
  }

  & > section {
    display: flex;
    align-items: flex-start;
    gap: 1.5rem;

    margin-bottom: 1.5rem;

    & > label {
      width: 100%;
    }

    @media (max-width: 992px) {
      flex-direction: column;

      & > div {
        width: 100%;

        & > label {
          width: 100%;
        }
      }
    }
  }

  & > div {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(16.125rem, 1fr));
    grid-template-rows: repeat(auto-fit, 6.25rem);
    gap: 1.5rem;

    @media (max-width: 992px) {
      grid-template-columns: 1fr;

      & > label {
        grid-column: unset !important;
      }
    }
  }
`;

export const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1.5rem;

  padding: 1.5rem 3rem;

  border-top: 1px solid #c9cbcf;
`;

export const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 1.5rem 3rem;

  border-bottom: 1px solid #c9cbcf;

  & > h1 {
    font-family: 'Montserrat';

    font-weight: 700;
    font-size: 1.5rem;

    color: #000000;
  }

  & > button {
    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 1.25rem;

    width: 3rem;
    height: 3rem;

    background: #f5f5f6;
    border-radius: 200px;
  }
`;
