import { format } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import React, { useEffect, useState } from 'react';
import { FiEdit, FiX } from 'react-icons/fi';
import { Props } from 'react-modal';
import { useHistory } from 'react-router-dom';
import { ShapeModal } from '../..';
import { Lead, STATES_UF } from '../../../../contexts/sales-funnel/types';
import { Button } from '../components/button';
import {
  Aside,
  BudgetInfo,
  Buttons,
  Container,
  Content,
  Customer,
  Data,
  Header,
  Kit,
  Messages,
  Wrapper,
} from './styles';
import productsApi from '../../../../services/products';
import { toast } from 'react-toastify';
import Loading from '../../../loading';
import { useScrollContainer } from 'react-indiana-drag-scroll';
import { BudgetItem } from '../../../../contexts/budgets/types';
import { Tags } from '../components/tags';
import { useSalesFunnel } from '../../../../contexts/sales-funnel';
import { LeadModal } from '../lead-modal';

type ClosedModalProps = {
  onCancel: () => void;
  content: Lead | null;
} & Props;

export const ClosedModal: React.FC<ClosedModalProps> = (props) => {
  const { onCancel, content, isOpen, ...rest } = props;

  const { leadModal, handleLeadModal } = useSalesFunnel();

  const scroll = useScrollContainer<HTMLElement>();

  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [orderItems, setOrderItems] = useState<BudgetItem[]>([]);

  const getOrderItems = async () => {
    setLoading(true);

    try {
      const response = await productsApi.get('/orders/' + content?.orderId);

      setOrderItems(response.data.items);
    } catch {
      toast.error('Desculpe, não foi possível buscar os itens do pedido');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      getOrderItems();
    } else {
      setOrderItems([]);
    }
  }, [isOpen]);

  if (loading) {
    return (
      <ShapeModal
        padding="0"
        maxWidth="60rem"
        onRequestClose={onCancel}
        isOpen={isOpen}
        {...rest}
      >
        <Loading />
      </ShapeModal>
    );
  }

  return (
    <ShapeModal
      padding="0"
      maxWidth="60rem"
      onRequestClose={onCancel}
      isOpen={isOpen}
      {...rest}
    >
      <Container>
        <Header>
          <h1>Fechado</h1>

          <button onClick={onCancel} type="button">
            <FiX />
          </button>
        </Header>

        <Content>
          <Tags lead={content} />
          <Wrapper>
            <Customer>
              <div>
                <header>
                  <h1>Dados do cliente</h1>

                  <Button
                    variant="white"
                    type="button"
                    maxWidth="7.313rem"
                    onClick={() => handleLeadModal(content)}
                  >
                    <FiEdit />
                    Editar
                  </Button>
                </header>

                <div>
                  <strong>{content?.customer.name}</strong>
                  <p>
                    Tipo de instalação:
                    <strong>{content?.customer.sector}</strong>
                  </p>
                  <p>
                    Canal de origem:<strong>{content?.customer.origin}</strong>
                  </p>
                  <p>
                    Vendedor:
                    <strong>{content?.franchiseUser?.user.name}</strong>
                  </p>
                </div>
              </div>
              <div>
                <header>
                  <h1>Endereço do cliente</h1>
                </header>

                {content?.customer.address && (
                  <div>
                    <p>
                      {content?.customer.address},{' '}
                      {content?.customer.addressNumber}
                    </p>

                    <p>
                      {content?.customer.district} - {content?.customer.city} -{' '}
                      {
                        STATES_UF[
                          content?.customer.state as keyof typeof STATES_UF
                        ]
                      }
                    </p>

                    <p>
                      CEP: <strong>{content?.customer.zipcode}</strong>
                    </p>
                  </div>
                )}

                {!content?.customer.address && (
                  <div>
                    <p>Nenhum endereço cadastrado.</p>
                  </div>
                )}
              </div>
            </Customer>

            <Aside>
              <Data>
                <header>
                  <h1>Dados do orçamento</h1>
                </header>
                {!!orderItems?.length && (
                  <main ref={scroll.ref}>
                    {orderItems?.map((item) => {
                      if (!item.product) {
                        return (
                          <Kit key={item.id}>
                            <strong>{item.formatted.total}</strong>
                          </Kit>
                        );
                      }

                      return (
                        <Kit key={item.id}>
                          <h1>
                            {item.quantity}x {item.product.supplier} -{' '}
                            {item.product.category}W
                          </h1>
                          <strong>{item.formatted.total}</strong>
                          <span>
                            {item.product.power} KWp | {item.product.voltage}V
                          </span>
                          <span>{item.product.solarPlatesNumber} módulos</span>
                        </Kit>
                      );
                    })}
                  </main>
                )}
                <Messages>
                  <p>
                    Data de criação:
                    <strong>
                      {' '}
                      {content?.createdAt &&
                        format(
                          new Date(content.createdAt),
                          "dd 'de' MMMM 'de' yyyy",
                          {
                            locale: ptBR,
                          }
                        )}
                    </strong>
                  </p>
                  <strong>
                    Pedido gerado em{' '}
                    {content?.updatedAt &&
                      format(
                        new Date(content.updatedAt),
                        "dd 'de' MMMM 'de' yyyy",
                        {
                          locale: ptBR,
                        }
                      )}
                  </strong>
                </Messages>
              </Data>
              <Buttons>
                <Button
                  variant="gray"
                  type="button"
                  maxWidth="100%"
                  onClick={() =>
                    history.push('/orcamentos/orcamentos-e-pedidos', {
                      activeTab: 'Pedidos',
                    })
                  }
                >
                  Acompanhar pedido
                </Button>
              </Buttons>
            </Aside>
          </Wrapper>
        </Content>
      </Container>

      <LeadModal
        isOpen={leadModal.isOpen}
        lead={leadModal.data}
        onCancel={() => {
          handleLeadModal(null);
          onCancel();
        }}
      />
    </ShapeModal>
  );
};
