import styled from 'styled-components';

export const SelectWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(16.125rem, 0.25fr));
  gap: 1.5rem;
  align-items: center;

  width: 100%;
`;

export const MapContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 3rem;
`;

export const MapWrapper = styled.div`
  flex: 1 1 45.5rem;
  height: 40.5rem;

  .google-map {
    width: 100%;
    height: 100%;
  }
`;

export const Region = styled.aside`
  flex: 0 2 22rem;

  & > h1 {
    font-family: 'DunbarTallBold';
    font-weight: 500;
    font-size: 1.5rem;

    letter-spacing: 0.03em;

    color: #000000;
  }

  @media (max-width: 520px) {
    flex: 1;
  }
`;

export const Regions = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 2.125rem;

  width: 100%;
  max-height: 40.5rem;

  overflow-y: auto;

  margin-top: 3rem;

  & > div {
    display: flex;
    align-items: center;
    gap: 0.5rem;

    width: 100%;
    height: 2.8rem;

    @media (max-width: 520px) {
      justify-content: space-between;
    }

    & > div:nth-child(1) {
      max-width: 12rem;
      width: 100%;
    }

    & > div:nth-child(2) {
      max-width: 5rem;
      width: 100%;
    }

    & > div:nth-child(3) {
      max-width: 3.75rem;
      width: 100%;
    }

    & > div {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;

      & strong,
      p,
      span {
        display: block;

        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &.region-ranking {
        flex-direction: row;
        align-items: center;

        & > strong {
          font-family: 'DunbarTallBold';
          font-weight: 700;
          font-size: 1.5rem;

          letter-spacing: 0.03em;

          color: #000000;
        }
      }

      & > strong {
        font-family: 'DunbarTallBold';
        font-weight: 500;
        font-size: 1.125rem;

        letter-spacing: 0.03em;

        color: #27282b;
      }

      & > p.region-price {
        font-family: 'Montserrat';
        font-weight: 400;
        font-size: 0.875rem;

        letter-spacing: 0.02em;

        color: #27282b;
      }

      & > p.region-percentage {
        font-family: 'DunbarTextRegular';
        font-weight: 400;
        font-size: 1rem;

        letter-spacing: 0.02em;

        color: #27282b;
      }

      & > p.region-share {
        font-family: 'Montserrat';
        font-weight: 400;
        font-size: 0.75rem;

        letter-spacing: 0.02em;

        color: #61646b;
      }
    }
  }
`;
