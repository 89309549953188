import styled, { css } from 'styled-components';

type CheckboxProps = {
  isChecked: boolean;
  isDisabled?: boolean;
};

export const Container = styled.label<CheckboxProps>`
  display: flex;
  align-items: center;
  gap: 0.5rem;

  cursor: ${(props) => (props.isDisabled ? 'not-allowed' : 'pointer')};

  font-size: 1rem;

  color: #61646b;

  & > span {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;

    font-weight: 500;
    font-size: 0.875rem;

    color: #27282b;

    & > span {
      font-weight: 400;
      font-size: 0.875rem;

      color: #27282b;
    }
  }

  & > div {
    display: flex;
    justify-content: center;
    align-items: center;

    border: 1px solid ${({ isChecked }) => (isChecked ? '#005AF9' : '#9fa1a8')};

    border-radius: 4px;

    width: 1rem;
    height: 1rem;

    ${(props) =>
      props.isChecked &&
      css`
        background-color: #005af9;
      `}
  }

  & > input[type='checkbox'] {
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
    overflow: hidden;
    clip: rect(0 0 0 0);
  }
`;
