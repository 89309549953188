/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import {
  Container,
  ContainerHeader,
  FileButtonContainer,
  FileButton,
  File,
  FileContainer,
  DeleteButton,
  HiddenFileButton,
} from './styles';
import UploadService from '../../services/FileUploadService';

import deleteIcon from '../../assets/icon/delete.svg';

type FileUploadProps = {
  title?: string;
  helpMessage?: string;
  loadedFiles?: Files[];
  projectId: string;
  type: string;
  isMultiple?: boolean;
  onLoadFiles?: () => void;
};

type Files = {
  id: string;
  name: string;
  url: string;
  file_url: string;
};

type UploadProgress = {
  percentage: number;
  fileName: string;
};

export const FileUpload: React.FC<FileUploadProps> = ({
  title,
  helpMessage,
  loadedFiles,
  projectId,
  type,
  onLoadFiles,
  isMultiple = true,
}) => {
  const hiddenFileInput = React.createRef<HTMLInputElement>();
  const [selectedFiles, setSelectedFiles] = useState(undefined);
  const [progressInfos, setProgressInfos] = useState<UploadProgress[]>([]);
  const [progressInfosRef, setProgressInfosRef] = useState<UploadProgress[]>(
    []
  );
  const [files, setFiles] = useState<Files[]>([]);

  useEffect(() => {
    if (selectedFiles) {
      const files = Array.from(selectedFiles);
      const _progressInfos = files.map((file: any) => ({
        percentage: 0,
        fileName: file.name,
      }));

      setProgressInfos(_progressInfos);
      setProgressInfosRef(_progressInfos);
    }
  }, [selectedFiles]);

  useEffect(() => {
    const hasUploadInProgress = progressInfos.filter(
      (progress) => progress.percentage !== 100
    );

    if (progressInfos.length > 0 && hasUploadInProgress.length === 0) {
      setProgressInfos([]);
      setProgressInfosRef([]);
      setSelectedFiles(undefined);
    }
  }, [progressInfos]);

  useEffect(() => {
    if (selectedFiles) {
      const files = Array.from(selectedFiles);
      const uploadPromises = files.map((file, i) => upload(i, file));

      Promise.all(uploadPromises);
    }
  }, [progressInfosRef]);

  useEffect(() => {
    if (loadedFiles) {
      setFiles(loadedFiles);
    }
  }, [loadedFiles]);

  const handleFileClick = () => {
    hiddenFileInput?.current?.click();
  };

  const selectFiles = (event: any) => {
    setSelectedFiles(event.target.files);
  };

  const updateFiles = () => {
    UploadService.getFiles(projectId, type).then((res) => {
      const normalizedFiles = res.data.map((file: any) => ({
        id: file.id,
        name: file.originalName,
        url: file.path,
        file_url: file.file_url,
      }));
      setFiles(normalizedFiles);
    });
    if (onLoadFiles) {
      onLoadFiles();
    }
  };

  const deleteFile = (id: string) => {
    UploadService.deleteFile(id).then(() => {
      updateFiles();
    });
  };

  const upload = (idx: any, file: any) => {
    return UploadService.upload(file, projectId, type, (event: any) => {
      progressInfos[idx].percentage = Math.round(
        (100 * event.loaded) / event.total
      );
      setProgressInfos([...progressInfos]);
    })
      .then(() => {
        toast.success(
          `O upload do arquivo ${file.name} foi realizado com sucesso`
        );
        updateFiles();
      })
      .catch(() => {
        progressInfos[idx].percentage = 0;
        setProgressInfos(progressInfos);

        toast.error(`Não foi possível fazer o upload do arquivo ${file.name}`);
      });
  };

  return (
    <Container>
      <ContainerHeader>
        {title && <h4>{title}</h4>}
        {helpMessage && <span>{helpMessage}</span>}
      </ContainerHeader>
      <FileButtonContainer>
        <FileButton type="button" onClick={handleFileClick}>
          Selecionar arquivo
        </FileButton>
      </FileButtonContainer>
      <FileContainer>
        {files.map((file) => (
          <File key={file.id}>
            <a href={file.file_url} target="_blank" rel="noreferrer">
              {file.name}
            </a>
            <DeleteButton type="button" onClick={() => deleteFile(file.id)}>
              <img src={deleteIcon} alt="delete" />
            </DeleteButton>
          </File>
        ))}
        {progressInfos &&
          progressInfos.length > 0 &&
          progressInfos.map((progressInfo: any, index) => (
            <div className="mb-2" key={index}>
              <div className="progress">
                <span>{progressInfo.fileName}</span>
                <span>{progressInfo.percentage}%</span>
                <div
                  className="progress-bar"
                  style={{ width: progressInfo.percentage + '%' }}
                ></div>
              </div>
            </div>
          ))}
        <HiddenFileButton>
          <input
            type="file"
            multiple={isMultiple}
            onChange={selectFiles}
            ref={hiddenFileInput}
          />
        </HiddenFileButton>
      </FileContainer>
    </Container>
  );
};
