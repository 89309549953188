import styled from 'styled-components';

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  & > h1 {
    font-family: 'DunbarTallBold';
    font-weight: 500;
    font-size: 1.125rem;

    letter-spacing: 0.03em;

    color: #000000;
  }

  & > button {
    width: 1.5rem;
    height: 1.5rem;

    font-style: 0;

    & > svg {
      font-size: 1.5rem;
    }
  }
`;
