import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  height: 100%;

  padding: 1.5rem 0 0;
`;

export const Content = styled.div`
  display: flex;
  align-content: flex-start;
  flex-wrap: wrap;
  gap: 1rem;

  padding: 2.5rem 0 0;
`;
