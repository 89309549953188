import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { FiCalendar, FiX } from 'react-icons/fi';
import { Props } from 'react-modal';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { ShapeModal } from '../../..';
import { useSalesFunnel } from '../../../../../contexts/sales-funnel';
import crmApi from '../../../../../services/crm';
import { DatePicker } from '../../../../date-picker';
import Textarea from '../../../../textarea';
import { Button } from '../../components/button';
import { Buttons, Container, Content, Header, Wrapper } from './styles';

type InnerScheduleModalProps = {
  onCancel: () => void;
  scheduleId?: string;
  leadId?: string;
  onConfirm?: () => void;
} & Props;

type Form = {
  scheduledTo: Date;
  description: string;
};

const schema = yup.object().shape({
  scheduledTo: yup.date().required('Campo obrigatório'),
  description: yup.string().required('Campo obrigatório'),
});

export const InnerScheduleModal: React.FC<InnerScheduleModalProps> = (
  props
) => {
  const { onCancel, leadId, scheduleId, onConfirm, ...rest } = props;

  const { getLeads, updateLeadStatus } = useSalesFunnel();

  const {
    control,
    formState: { errors },
    clearErrors,
    reset,
    handleSubmit,
    register,
  } = useForm<Form>({
    resolver: yupResolver(schema),
  });

  const closeModal = () => {
    onCancel();
    clearErrors();
    reset();
    onConfirm && onConfirm();
  };

  const onSubmit = async (data: Form) => {
    const normalizeData = {
      ...data,
      scheduledTo: data.scheduledTo.toISOString(),
    };

    try {
      if (!props?.scheduleId && !leadId) throw new Error();

      if (leadId) {
        await crmApi.post('/schedules', { leadId, ...normalizeData });
      }

      if (scheduleId) {
        await crmApi.put('/schedules', { id: scheduleId, ...normalizeData });
      }

      await getLeads();

      closeModal();

      toast.success('Agendamento feito com sucesso');
    } catch (error) {
      toast.error('Desculpe, não foi possível fazer o agendamento.');
    }
  };

  return (
    <ShapeModal
      padding="0"
      maxWidth="38.5rem"
      onRequestClose={closeModal}
      {...rest}
    >
      <Container>
        <Header>
          <h1>Agendamento</h1>

          <button onClick={closeModal}>
            <FiX />
          </button>
        </Header>

        <Content onSubmit={handleSubmit(onSubmit)}>
          <Wrapper>
            <Controller
              control={control}
              name="scheduledTo"
              render={({
                field: { value, ref, onChange },
                fieldState: { error },
              }) => {
                return (
                  <DatePicker
                    ref={ref}
                    label="Data"
                    placeholder="Selecionar uma data"
                    onChange={onChange}
                    date={value}
                    error={error?.message}
                  />
                );
              }}
            />

            <Textarea
              label="Descrição"
              placeholder="Informações adicionais sobre o agendamento"
              height="8rem"
              {...register('description')}
              error={errors.description?.message}
            />

            <Buttons>
              <Button variant="white" type="submit" maxWidth="16.125rem">
                <FiCalendar />
                Agendar novo contato
              </Button>
            </Buttons>
          </Wrapper>
        </Content>
      </Container>
    </ShapeModal>
  );
};

