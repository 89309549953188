import React, { CSSProperties, memo } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { Item } from '../Item';
import { areEqual } from 'react-window';
import { Lead } from '../../../../../../contexts/sales-funnel/types';

interface Props {
  data: Lead[];
  index: number;
  style: CSSProperties;
}

export const Row = memo(function Row(props: Props) {
  const { data: items, index, style } = props;

  const item = items[index];

  if (!item) {
    return null;
  }

  return (
    <Draggable draggableId={item.id} index={index} key={item.id}>
      {(provided) => <Item provided={provided} item={item} style={style} />}
    </Draggable>
  );
}, areEqual);

