import styled from 'styled-components';

export const Container = styled.div`
  height: 5rem;
  width: 100%;

  background-color: #f5f5f5;
  color: #000;

  display: flex;
  align-items: center;
  justify-content: flex-end;

  padding: 1.5rem 3rem;

  @media (max-width: 520px) {
    padding: 5%;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;

  & > div:first-child {
    margin-right: 1rem;
  }

  & > svg {
    display: none;
  }

  @media (max-width: 520px) {
    width: 100%;
    justify-content: space-between;

    & > svg {
      display: initial;
    }
  }
`;

export const Profile = styled.div`
  display: flex;
  align-items: center;

  & > img {
    width: 2rem;
    height: 2rem;
  }

  & > p {
    font-size: 0.875rem;
  }
`;
