import React, { Fragment, useEffect, useState } from 'react';
import { Container, Wrapper } from './styles';
import { useAuth } from '../../../../../../contexts/auth';
import franchisesApi from '../../../../../../services/franchises';
import RadioGroup, { Option } from '../../../../../../components/radio-group';
import { v4 as uuid } from 'uuid';

type SellerResponse = {
  content: {
    id: string;
    user: {
      id: string;
      name: string;
      enabled: boolean;
    };
  }[];
};

interface SellersProps {
  selectedSeller: string;
  onChange: (sellerId: string) => void;
}

export const Sellers: React.FC<SellersProps> = (props) => {
  const { selectedSeller, onChange } = props;

  const { selectedFranchise: franchiseId } = useAuth();

  const [sellers, setSellers] = useState<Option[]>([]);

  const getSellers = async () => {
    if (!franchiseId) {
      setSellers([
        {
          label: 'Todos',
          value: '',
        },
      ]);

      return;
    }

    const { data } = await franchisesApi.get<SellerResponse>(
      `/api/franchises/${franchiseId}/member`,
      {
        params: {
          limit: 1000,
        },
      }
    );

    const normalizeSellers = [
      ...data.content
        .filter((seller) => seller.user.enabled)
        .map((seller) => ({
          id: uuid(),
          label: seller.user.name,
          value: seller.user.id,
        })),
      {
        label: 'Todos',
        value: '',
      },
    ];

    setSellers(normalizeSellers);
  };

  useEffect(() => {
    if (selectedSeller.length > 0) {
      onChange('');
    }
  }, [sellers]);

  useEffect(() => {
    getSellers();
  }, [franchiseId]);

  return (
    <Fragment>
      <Container>
        <Wrapper>
          <RadioGroup
            options={sellers}
            onChange={(e) => {
              const value = e.target.value;

              onChange(value);
            }}
            value={selectedSeller}
          />
        </Wrapper>
      </Container>
    </Fragment>
  );
};
