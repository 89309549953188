/* eslint-disable @typescript-eslint/no-explicit-any */
import api from './projects';

const upload = (
  file: string | Blob,
  projectId: string,
  type: string,
  onUploadProgress: any
) => {
  const formData: FormData = new FormData();

  formData.append('file', file);
  formData.append('type', type);

  return api.post(`/api/projects/${projectId}/add-file`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    onUploadProgress,
  });
};

const getFiles = (projectId: string, type: string) => {
  return api.get(`/api/projects/${projectId}/file?type=${type}`);
};

const deleteFile = (id: string) => {
  return api.delete(`/api/projects/${id}/file`);
};

const FileUploadService = {
  upload,
  getFiles,
  deleteFile,
};

export default FileUploadService;
