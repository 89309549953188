import styled, { css } from 'styled-components';

export const Container = styled.div``;

export const Placeholder = styled.span`
  display: inline-flex;
  gap: 0.25rem;
  align-items: center;

  color: #61646b;
  font-size: 1rem;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
`;

type TagProps = {
  bgColor: string;
};

export const Tag = styled.span<TagProps>`
  display: flex;
  align-items: center;

  padding: 0.5rem 0.56rem;

  width: 8.5rem;
  height: 2rem;

  border-radius: 4px;

  color: '#131415';
  font-size: 0.75rem;
  font-family: 'DunbarTextRegular', sans-serif;

  ${(props) => css`
    background-color: ${props.bgColor};
  `}
`;

export const NewTagButton = styled.button.attrs((props) => {
  return {
    ...props,
    type: 'button',
  };
})`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.25rem;

  width: calc(100% - 3rem);
  height: 2rem;

  border-radius: 8px;
  background: #c9cbcf;

  margin: 0.5rem auto;

  color: #27282b;
  font-size: 0.75rem;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
`;
