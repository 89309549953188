import styled from 'styled-components';

type InputProps = {
  text?: string;
};

export const Content = styled.div<InputProps>`
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  /* align-items: center; */
  gap: 3rem;
  border-radius: 16px;

  & > h1 {
    font-family: 'DunbarTallRegular';
    font-weight: 500;
    font-size: 1.5rem;
    line-height: 100%;
    letter-spacing: 0.03em;
    color: #000000;
  }

  & > article {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 1.5rem;

    & > h1,
    p {
      font-family: 'DunbarTextRegular', sans-serif;
      font-weight: 400;
      font-size: 1.25rem;

      letter-spacing: 0.02em;

      color: #27282b;
    }

    & > div {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;

      & > p,
      ul > li {
        font-family: 'DunbarTextRegular', sans-serif;
        font-weight: 400;
        font-size: 1.25rem;

        letter-spacing: 0.02em;

        color: #27282b;
      }

      & > ul {
        padding: 0 1rem;
      }

      & > ul > li {
        list-style: disc inside none;
      }
    }
  }

  & > article.seller {
    min-height: 18rem;
  }
`;
