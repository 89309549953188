import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 0.75rem;

  position: relative;
`;

export const Button = styled.button.attrs((props) => {
  return {
    ...props,
    type: 'button',
  };
})`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.25rem;

  background-color: #00299b;
  color: #ffffff;

  font-weight: 500;
  font-size: 1rem;

  border-radius: 24px;

  width: 7.5rem;
  height: 3rem;

  box-shadow: 1px 8px 15px 0px rgba(0, 0, 0, 0.1),
    1px 4px 6px 0px rgba(0, 0, 0, 0.1);

  &:hover {
    filter: brightness(0.95);
  }
`;

type DropdownContainerProps = {
  isOpen: boolean;
};

export const DropdownContainer = styled.div<DropdownContainerProps>`
  width: 21.875rem;
  max-height: 34.125rem;

  overflow: auto;

  position: absolute;
  top: 3.5rem;
  right: 0;

  z-index: 99;

  padding: 1rem;

  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};

  border-radius: 8px;
  border: 1px solid #c9cbcf;
  background: #fff;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.18);

  &::-webkit-scrollbar {
    width: 4px;

    border-radius: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #f5f5f6;

    border-radius: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background: #a8b0b5;

    border-radius: 8px;
  }
`;

export const DropdownHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;

  border-bottom: 1px solid #c9cbcf;

  padding-bottom: 1rem;

  & > span {
    font-weight: 700;
    font-size: 1rem;

    color: #61646b;
  }
`;

export const CloseButton = styled.button.attrs((props) => {
  return {
    ...props,
    type: 'button',
  };
})``;

export const DropdownContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  padding-top: 1.5rem;

  & > h1 {
    color: #61646b;

    font-size: 1.125rem;
    font-family: 'DunbarTallRegular', sans-serif;
    font-weight: 500;
  }
`;
