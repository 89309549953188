import styled from 'styled-components';

export const Container = styled.div``;

export const Content = styled.form`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 3rem;

  padding: 2rem 3rem 1.5rem;

  overflow-y: auto;

  height: 30rem;

  &::-webkit-scrollbar {
    width: 4px;
  }

  & > hr {
    margin: 0 3rem;

    border: 1px solid #c9cbcf;
  }

  @media (max-width: 825px) {
    flex-direction: column;

    align-items: flex-start;
  }

  @media (max-width: 520px) {
    padding: 5%;
  }
`;

export const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 1.5rem 3rem;

  border-bottom: 1px solid #c9cbcf;

  & > h1 {
    font-family: 'Montserrat', sans-serif;

    font-weight: 700;
    font-size: 1.5rem;

    color: #000000;
  }

  & > button {
    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 1.25rem;

    width: 3rem;
    height: 3rem;

    background: #f5f5f6;
    border-radius: 200px;
  }
`;

export const Buttons = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  gap: 0.5rem;

  width: 100%;
  height: 100%;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.5rem;

  flex: 1;

  width: 100%;
  height: 100%;
`;
