import styled from 'styled-components';

export const Content = styled.main`
  display: flex;
  flex-direction: column;

  padding: 1.5rem 0;

  height: 100%;
`;

export const Header = styled.header`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  margin-bottom: 1.5rem;

  & > h1 {
    color: #27282b;
    font-family: 'Montserrat', sans-serif;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 600;
    letter-spacing: 0.0125rem;
  }

  & > p {
    color: #27282b;

    font-family: 'Montserrat';
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0.0225rem;
  }
`;

export const Wrapper = styled.div`
  height: 100%;
`;

export const TableHeader = styled.header`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;

  @media (max-width: 992px) {
    flex-direction: column;

    & > div {
      width: 100%;

      & > button {
        width: 100%;
      }
    }

    & > div {
      max-width: unset;
    }
  }
`;
