import React, { ForwardedRef, TextareaHTMLAttributes, useState } from 'react';
import { Container } from './styles';

interface TextareaTextProps
  extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  label: string;
  height?: string;
  resize?: 'none' | 'both' | 'horizontal' | 'vertical';
  error?: string;
}

const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaTextProps>(
  (props: TextareaTextProps, MyRef: ForwardedRef<HTMLTextAreaElement>) => {
    const { label, height = '5.5rem', resize = 'none', error, ...rest } = props;

    const [active, setActive] = useState(false);

    return (
      <Container
        resize={resize}
        height={height}
        active={active}
        error={!!error}
        disabled={rest.disabled}
        onFocus={() => setActive(true)}
        onBlur={() => setActive(false)}
      >
        {rest.required ? label + '*' : label}

        <textarea ref={MyRef} {...rest}></textarea>

        {error && <span>{error}</span>}
      </Container>
    );
  }
);

Textarea.displayName = 'Textarea';

export default Textarea;
