import React, { HTMLAttributes } from 'react';
import { RxTriangleUp } from 'react-icons/rx';
import { numberFormat } from '../../../../../../utils/numberFormat';
import { toMoneyFormat } from '../../../../../../utils/toMoneyFormat';
import {
  Container,
  CurrentValue,
  Indicator,
  LastValue,
  Title,
  ValueContainer,
} from './styles';

type Props = {
  title: string;
  lastValue?: number;
  currentValue: number;
  percentage?: number;
  isMultiple?: boolean;
  isPositive?: boolean;
  sufix?: string;
  money?: boolean;
} & HTMLAttributes<HTMLDivElement>;

export const Card: React.FC<Props> = (props) => {
  const {
    title,
    lastValue,
    currentValue,
    percentage,
    isMultiple = false,
    isPositive = true,
    sufix = '',
    money = true,
    ...rest
  } = props;

  if (isMultiple) {
    return (
      <Container isMultiple={isMultiple} {...rest}>
        <ValueContainer>
          <Title className="undraggable">{title}</Title>
          <CurrentValue className="undraggable">
            {sufix
              ? `${numberFormat(currentValue)} ${sufix}`
              : money
              ? toMoneyFormat(currentValue)
              : numberFormat(currentValue)}
          </CurrentValue>
          {lastValue !== undefined && (
            <LastValue className="undraggable">
              {sufix
                ? `${numberFormat(lastValue)} ${sufix}`
                : money
                ? toMoneyFormat(lastValue)
                : numberFormat(lastValue)}
            </LastValue>
          )}
        </ValueContainer>

        {percentage && isFinite(percentage) ? (
          <Indicator
            version={isPositive ? 'positive' : 'negative'}
            className="undraggable"
          >
            <RxTriangleUp />
            {isPositive
              ? `+${numberFormat(percentage)}%`
              : `${numberFormat(percentage)}%`}
          </Indicator>
        ) : null}
      </Container>
    );
  }

  return (
    <Container isMultiple={isMultiple} {...rest}>
      <Title className="undraggable">{title}</Title>

      <ValueContainer>
        <CurrentValue className="undraggable">
          {sufix
            ? `${numberFormat(currentValue)} ${sufix}`
            : money
            ? toMoneyFormat(currentValue)
            : numberFormat(currentValue)}
        </CurrentValue>
        {lastValue !== undefined && (
          <LastValue className="undraggable">
            {sufix
              ? `${numberFormat(lastValue)} ${sufix}`
              : money
              ? toMoneyFormat(lastValue)
              : numberFormat(lastValue)}
          </LastValue>
        )}

        {percentage && isFinite(percentage) ? (
          <Indicator
            version={isPositive ? 'positive' : 'negative'}
            className="undraggable"
          >
            <RxTriangleUp />
            {isPositive
              ? `+${numberFormat(percentage)}%`
              : `${numberFormat(percentage)}%`}
          </Indicator>
        ) : null}
      </ValueContainer>
    </Container>
  );
};
