import styled, { css } from 'styled-components';

export type IndicatorStatus = 'neutral' | 'decreased' | 'increased';

type IndicatorProps = {
  status: IndicatorStatus;
};

export const Container = styled.div<IndicatorProps>`
  display: flex;
  justify-content: center;
  align-items: center;

  & > svg {
    font-size: 1.5rem;
  }

  ${(props) => {
    switch (props.status) {
      case 'neutral':
        return css`
          & > svg {
            color: #000000;
            rotate: 180deg;
          }
        `;

      case 'decreased':
        return css`
          & > svg {
            color: #e01919;
            rotate: 180deg;
          }
        `;

      case 'increased':
        return css`
          & > svg {
            color: #1a7605;
          }
        `;
    }
  }}
`;
