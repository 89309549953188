import { AxiosInstance } from 'axios';
import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import Dropzone, { Accept, DropzoneOptions, DropzoneRef } from 'react-dropzone';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';
import { downloadFile } from '../../utils/downloadFile';
import { AddIcon, CloseIcon } from '../icons';
import {
  Container,
  Content,
  FileContainer,
  FilePreviewContainer,
  FileWrapper,
} from './styles';

export interface IFile extends File {
  name: string;
  progress: number;
  error?: string;
  orderId?: string;
  mimetype?: string;
  path?: string;
  originalName?: string;
  size: number;
  type: string;
  id?: string;
  updatedAt?: string;
}

interface MultiFileProps extends DropzoneOptions {
  label?: string;
  inputLabel?: string;
  inputIcon?: ReactNode;
  error?: string;
  required?: boolean;
  message?: ReactNode;
  files: IFile[];
  accept?: Accept;
  api: AxiosInstance;
  url: string;
  deleteUrl: string;
  onChange: (files: IFile[]) => void;
}

export const FileInput = React.forwardRef<DropzoneRef, MultiFileProps>(
  (props, ref) => {
    const {
      label = 'Selecionar foto',
      inputLabel = 'Selecionar imagem',
      inputIcon = <AddIcon />,
      error,
      required = false,
      message,
      files,
      accept = {
        'application/pdf': ['.pdf'],
        'image/jpeg': ['.jpeg', '.jpg'],
        'image/png': ['.png'],
      },
      onChange,
      api,
      url,
      deleteUrl,
      ...rest
    } = props;

    const [localFiles, setLocalFiles] = useState<IFile[]>(files);
    const [filesWithError, setFilesWithError] = useState<IFile[]>([]);

    useEffect(() => {
      onChange(
        localFiles.map((file) => {
          const fileWithError = filesWithError.find(
            (_file) => _file.id === file.id
          );

          if (fileWithError) {
            return fileWithError;
          }

          return file;
        })
      );
    }, [localFiles, filesWithError]);

    const onDrop = useCallback(
      async (acceptedFiles: File[]) => {
        const normalizedFiles = acceptedFiles.map((file) => {
          return {
            ...file,
            id: uuidv4(),
            type: file.type,
            name: file.name,
            size: file.size,
            progress: 0,
          };
        });

        setLocalFiles((state) => [...state, ...normalizedFiles]);

        const promises = acceptedFiles.map((acceptedFile) => {
          const formData = new FormData();
          formData.append('file', acceptedFile, acceptedFile.name);

          const promise = api
            .post(url, formData, {
              onUploadProgress: (progressEvent) => {
                const progress = Math.round(
                  (progressEvent.loaded * 100) / progressEvent.total
                );

                setLocalFiles((_files) => {
                  const updatedFileProgress = _files.map((file) => {
                    if (file.name === acceptedFile.name) {
                      return {
                        ...file,
                        progress,
                      };
                    }

                    return file;
                  });

                  return updatedFileProgress;
                });
              },
            })
            .then((response) => {
              const data = response.data;

              setLocalFiles((_files) => {
                const updatedFile = _files.map((file) => {
                  if (data.originalName === file.name) {
                    return {
                      ...data,
                      progress: 100,
                    };
                  }

                  return file;
                });

                return updatedFile;
              });
            })
            .catch(() => {
              const normalizeFilesWithError = normalizedFiles.map((file) => {
                return {
                  ...file,
                  progress: 100,
                  error: 'Não foi possível enviar esse arquivo',
                };
              });

              setFilesWithError((_files) => [
                ..._files,
                ...normalizeFilesWithError,
              ]);
            });

          return promise;
        });

        await Promise.all(promises);
      },
      [localFiles, filesWithError, files]
    );

    const handleRemoveFile = useCallback(
      (fileId: string) => {
        api
          .delete(`${deleteUrl + fileId}`)
          .then(() => {
            setLocalFiles((_files) => {
              const filteredFiles = _files.filter((file) => file.id !== fileId);

              return filteredFiles;
            });

            setFilesWithError((_files) => {
              const filteredFiles = _files.filter((file) => file.id !== fileId);

              return filteredFiles;
            });
          })
          .catch(() => {
            toast.error('Não foi possível remover o arquivo');
          });
      },
      [localFiles, filesWithError, files]
    );

    return (
      <Dropzone onDrop={onDrop} ref={ref} accept={accept} {...rest}>
        {({ getInputProps, getRootProps, fileRejections }) => (
          <Container>
            <label>{required ? label + '*' : label}</label>
            <Content type="button" disabled={rest.disabled}>
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                <p>
                  <>
                    {inputIcon}
                    {inputLabel}
                  </>
                </p>
              </div>
            </Content>

            {message && <span className="message">{message}</span>}

            {!!files?.length && (
              <FilePreviewContainer>
                {files.map((file) => {
                  return (
                    <FileContainer key={file.id}>
                      <FileWrapper
                        progress={file.progress}
                        error={!!file.error}
                        onClick={() => {
                          if (file.path) {
                            downloadFile(file.path);
                          }
                        }}
                      >
                        <div>
                          <span>{file.originalName || file.name}</span>
                        </div>
                        <button
                          type="button"
                          onClick={(event) => {
                            event.stopPropagation();

                            handleRemoveFile(String(file.id));
                          }}
                        >
                          <CloseIcon />
                        </button>
                      </FileWrapper>
                      {file.error && <span>{file.error}</span>}
                    </FileContainer>
                  );
                })}
              </FilePreviewContainer>
            )}

            {error && <span>{error}</span>}

            {fileRejections.length > 0 &&
              fileRejections[0].errors.map((error) => {
                return <span key={error.code}>{error.message}</span>;
              })}
          </Container>
        )}
      </Dropzone>
    );
  }
);

FileInput.displayName = 'FileInput';

