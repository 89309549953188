import React from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { ShapeModal } from '../..';
import { Buttons } from '../../styles';

import { Button } from '../../../button';
import Input from '../../../input';
import { Container, Header } from './styles';

type Props = {
  isOpen: boolean;
  onCancel: () => void;
  onConfirm: () => void;
};

interface RequestAnalysisForm {
  password: string;
}
const Schema = yup.object().shape({
  password: yup.string().required('Insira sua senha!'),
});

export const RequestAnalysisModal: React.FC<Props> = (props) => {
  const { isOpen, onCancel, onConfirm } = props;

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<RequestAnalysisForm>({
    resolver: yupResolver(Schema),
  });

  return (
    <ShapeModal maxWidth="fit-content" isOpen={isOpen}>
      <Container>
        <Header>
          <h1>Análise do Consultor</h1>
          <p>
            Para personalizar um kit com quantidades de peças inferiores às
            quantidades calculadas, a personalização precisa ser aprovada por
            seu consultor.
          </p>
          <p>Para solicitar a análise, insira abaixo sua senha.</p>
        </Header>

        <form onSubmit={handleSubmit(onConfirm)}>
          <Input
            type="password"
            label="Senha"
            placeholder="Insira a senha"
            required
            {...register('password')}
            error={errors.password?.message}
          />

          <Buttons>
            <Button
              width="15rem"
              type="button"
              text="Voltar"
              typeStyle="default"
              backgroundHoverColor="#C9CBCF"
              onClick={onCancel}
            />
            <Button
              type="submit"
              width="15rem"
              text="Avançar"
              backgroundColor="#ED5A5A"
              backgroundHoverColor="#ed5a5abc"
              onClick={onConfirm}
            />
          </Buttons>
        </form>
      </Container>
    </ShapeModal>
  );
};
