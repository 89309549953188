import styled from 'styled-components';

export const Content = styled.div`
  margin-top: 1.25rem;

  max-height: 30vh;

  overflow-y: auto;

  & > p {
    font-weight: 400;
    font-size: 1.125rem;

    color: #27282b;
  }
`;

