import styled from 'styled-components';

export const LogContainerStyle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 100%;

  background-color: #fff;
  border-radius: 12px;
  padding: 1.25rem;

  margin-top: 1.5rem;

  h3 {
    width: 100%;
    padding: 1.5rem 0px;
    text-align: center;
    border-bottom: 1px solid #d3d3d3;
    margin-bottom: 1rem;
  }
  ul {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;

    max-height: 35.5rem;
    overflow: auto;

    &::-webkit-scrollbar {
      width: 4px;

      border-radius: 8px;
    }

    &::-webkit-scrollbar-track {
      background: #f5f5f6;

      border-radius: 8px;
    }

    &::-webkit-scrollbar-thumb {
      background: #a8b0b5;

      border-radius: 8px;
    }
  }
`;

export const LogStyle = styled.li`
  display: inline-block;
  font-weight: 600;
  font-size: 1rem;
`;
