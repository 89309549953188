import React, { useEffect, useRef } from 'react';
import { useState } from 'react';
import {
  DateRange as InputDateRange,
  Range,
  DateRangeProps as InputDateRangeProps,
} from 'react-date-range';
import { Container, Input, Wrapper } from './styles';
import { ptBR } from 'date-fns/locale';
import { format } from 'date-fns';
import { removeTimeZone } from '../../../../../../utils/removeTimeZone';
import { BsChevronDown } from 'react-icons/bs';

interface DateRangeProps extends InputDateRangeProps {
  dates: Range[];
}

export const DateRange = React.forwardRef<HTMLDivElement, DateRangeProps>(
  (props, ref) => {
    const { dates, ...rest } = props;

    const [isOpen, setIsOpen] = useState(false);

    const containerRef = useRef<HTMLFieldSetElement>(null);

    const handleOpenCalender = () => setIsOpen((state) => !state);

    useEffect(() => {
      if (isOpen) {
        containerRef.current?.scrollIntoView();
      }
    }, [isOpen]);

    const startDate = dates[0].startDate;
    const endDate = dates[0].endDate;

    return (
      <Container ref={containerRef}>
        <Wrapper ref={ref}>
          <Input onClick={handleOpenCalender}>
            De:
            <button type="button">
              {startDate && format(removeTimeZone(startDate), 'dd/MM/yyyy')}
              {!startDate && '--/--/----'}
              <BsChevronDown />
            </button>
          </Input>

          <Input onClick={handleOpenCalender}>
            Até:
            <button type="button">
              {endDate && format(removeTimeZone(endDate), 'dd/MM/yyyy')}
              {!endDate && '--/--/----'}
              <BsChevronDown />
            </button>
          </Input>
        </Wrapper>
        {isOpen && (
          <InputDateRange
            locale={ptBR}
            color="#c9cbcf"
            weekdayDisplayFormat={'EEEEEE'}
            showDateDisplay={false}
            ranges={dates}
            classNames={{
              month: 'daterange-month',
              inRange: 'daterange-in-range',
            }}
            onRangeFocusChange={(newFocusedRange) => {
              const hasFinishedChange = newFocusedRange.every(
                (rangeFocus) => rangeFocus === 0
              );

              if (hasFinishedChange) {
                handleOpenCalender();
              }
            }}
            {...rest}
          />
        )}
      </Container>
    );
  }
);

DateRange.displayName = 'DateRange';
