import React, { MutableRefObject, useEffect, useState } from 'react';
import { KanbanData } from '../../../../../contexts/sales-funnel/types';
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';
import { Container, Wrapper } from './styles';
import { Column } from './Column';
import { reorderList } from './utils/reorderList';
import { ScrollContainer } from 'react-indiana-drag-scroll';
import crmApi from '../../../../../services/crm';

type BoardProps = {
  data: KanbanData[];
};

export type ColumnOrder = Array<keyof IKanban['columns']>;

interface IKanban {
  columns: Record<
    | 'captacao'
    | 'orcamento'
    | 'negociacao'
    | 'propostaFinal'
    | 'fechado'
    | 'perdido'
    | 'recuperacao'
    | 'agendado',
    KanbanData
  >;
  order: ColumnOrder;
}

export const Board: React.FC<BoardProps> = (props) => {
  const { data } = props;

  const [kanban, setKanban] = useState<IKanban | null>(null);

  const updateLeadOrder = async (leadId: string, order: number) => {
    await crmApi.put(`/leads/${leadId}`, {
      order,
    });
  };

  const onDragEnd = (result: DropResult) => {
    if (!result.destination || !kanban) {
      return;
    }

    if (
      result.source.droppableId === result.destination.droppableId &&
      kanban
    ) {
      const column =
        kanban.columns[
          result.source.droppableId as keyof typeof kanban.columns
        ];

      const leadId = result.draggableId;

      const leadOrder = result.destination.index + 1;

      const leads = reorderList(
        column.leads,
        result.source.index,
        result.destination.index
      );

      const newState = {
        ...kanban,
        columns: {
          ...kanban.columns,
          [column.id]: {
            ...column,
            leads,
          },
        },
      };

      setKanban(newState);

      updateLeadOrder(leadId, leadOrder);
    }
  };

  useEffect(() => {
    if (data.length > 0) {
      const normalizedColumns = data.reduce((columns, column) => {
        const { id, label, color, leads, totalItems } = column;

        const normalizedColumn = {
          id,
          label,
          color,
          leads,
          totalItems,
        };

        return {
          ...columns,
          [id]: normalizedColumn,
        };
      }, {} as Record<keyof IKanban['columns'], KanbanData>);

      setKanban({
        columns: normalizedColumns,
        order: [
          'captacao',
          'orcamento',
          'negociacao',
          'recuperacao',
          'agendado',
          'fechado',
          'perdido',
        ],
      });
    }
  }, [data]);

  return (
    <Container>
      <DragDropContext onDragEnd={onDragEnd}>
        <Wrapper>
          <Droppable
            droppableId="all-droppables"
            direction="horizontal"
            type="column"
          >
            {(provided) => {
              if (!kanban) return <span ref={provided.innerRef} />;

              const { columns, order } = kanban;

              return (
                <ScrollContainer
                  hideScrollbars={false}
                  mouseScroll={{
                    ignoreElements: '.item-card',
                    activationDistance: 200,
                  }}
                  className="columns"
                  {...provided.droppableProps}
                  ref={
                    provided.innerRef as unknown as MutableRefObject<HTMLDivElement>
                  }
                >
                  {order.map((columnId, index) => {
                    return (
                      <Column
                        key={columnId}
                        column={columns[columnId]}
                        index={index}
                      />
                    );
                  })}
                  {provided.placeholder}
                </ScrollContainer>
              );
            }}
          </Droppable>
        </Wrapper>
      </DragDropContext>
    </Container>
  );
};
