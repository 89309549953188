import React, { useRef, useState } from 'react';
import { FiChevronDown } from 'react-icons/fi';
import { useOnClickOutside } from '../../../hooks/outsideClick';
import { Container } from './styles';

type InnerLimitDropdownProps = {
  options: { label: string; value: number }[];
  onSelectLimit: (option: { label: string; value: number }) => void;
  defaultValue?: number;
};

const InnerLimitDropdown: React.FC<InnerLimitDropdownProps> = (props) => {
  const { options, onSelectLimit, defaultValue } = props;

  const [isOpen, setIsOpen] = useState(false);

  const [selected, setSelected] = useState(
    options.find((option) => option.value === defaultValue) || options[0]
  );

  const dropdownRef = useRef<HTMLDivElement>(null);

  useOnClickOutside(dropdownRef, () => setIsOpen(false));

  return (
    <Container ref={dropdownRef} isOpen={isOpen}>
      <button type="button" onClick={() => setIsOpen((state) => !state)}>
        {selected.label} <FiChevronDown />
      </button>
      {isOpen && (
        <section>
          {options.map((option) => (
            <span
              key={option.value}
              onClick={() => {
                const current = options.find(
                  (item) => option.value === item.value
                ) || { label: '', value: 0 };
                setSelected(current);
                onSelectLimit(current);
                setIsOpen(false);
              }}
            >
              {option.label}
            </span>
          ))}
        </section>
      )}
    </Container>
  );
};

export default InnerLimitDropdown;
