import styled from 'styled-components';

export const Container = styled.div``;

export const Header = styled.header`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  margin-bottom: 1.5rem;

  & > h1 {
    color: #27282b;

    font-family: 'DunbarTallBold', sans-serif;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 500;
    letter-spacing: 0.03375rem;

    margin-bottom: 1.5rem;
  }

  & > p {
    color: #27282b;

    font-family: 'Montserrat', sans-serif;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0.0225rem;

    width: 31.5rem;
  }
`;
