import axios from 'axios';

const usersApi = axios.create({
  baseURL: process.env.REACT_APP_USER_MICROSERVICE_URL,
});

export default usersApi;

export const getToken = () => {
  try {
    const token = localStorage.getItem(
      `@energy:${process.env.REACT_APP_SITE_VERSION}-token`
    );

    return token;
  } catch (error) {
    return null;
  }
};

usersApi.interceptors.request.use((config) => {
  const token = getToken();

  if (token && config.headers) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
});
