import React, { useEffect, useState } from 'react';
import SelectInput from '../../../../../../components/select';
import { useDashboard } from '../../../../../../contexts/dashboard';
import { Indicator } from '../../../Indicator';
import { providersLogo } from '../../providersLogo';
import {
  BestSellingKits,
  Container,
  Logo,
  RankingWrapper,
  SelectWrapper,
  Li,
  SubTitle,
  Title,
  Units,
} from './styles';
import { FranchiseData } from '../../../../../../contexts/dashboard/types';

type RankingProps = {};

type RankingData = {
  model: string;
  provider: string;
  sales: number;
};

const options = [
  {
    label: 'Todos',
    value: 'Todos',
  },
  {
    label: 'WEG',
    value: 'WEG',
  },
  {
    label: 'GE',
    value: 'GE',
  },
  {
    label: 'Renovigi',
    value: 'Renovigi',
  },
  {
    label: 'Elgin',
    value: 'Elgin',
  },
];

type DashboardPropertiesType = {
  franchiseData: FranchiseData;
};

export const Ranking: React.FC<RankingProps> = (props) => {
  const { franchiseData } = useDashboard() as DashboardPropertiesType;

  const [selectedProvider, setSelectedProvider] = useState('');
  const [rankingData, setRankingData] = useState<RankingData[]>([]);

  useEffect(() => {
    if (!selectedProvider || selectedProvider === 'Todos') {
      return setRankingData(
        Object.values(franchiseData.kitsByProviders).sort(
          (a, b) => b.sales - a.sales
        )
      );
    }

    return setRankingData(() =>
      Object.values(franchiseData.kitsByProviders)
        .filter((item) => item.provider === selectedProvider)
        .sort((a, b) => b.sales - a.sales)
    );
  }, [selectedProvider]);

  return (
    <Container>
      <h1>Selecione um fornecedor</h1>

      <SelectWrapper>
        <SelectInput
          label="Fornecedor"
          placeholder="Selecione uma opção"
          options={options}
          onChange={(e) => setSelectedProvider(e.value as string)}
        />
      </SelectWrapper>

      <RankingWrapper>
        <BestSellingKits>
          <h1>Kits mais vendidos</h1>

          <ul>
            {Object.values(rankingData).map((kit, index) => {
              if (index < 10)
                return (
                  <Li key={kit.model + kit.provider}>
                    <div className="details-wrapper">
                      <Logo>
                        <img
                          src={
                            providersLogo[
                              kit.provider.toLowerCase() as keyof typeof providersLogo
                            ]
                          }
                        />
                      </Logo>
                      <div className="title-wrapper">
                        <Title>{kit.model}</Title>
                        {/* <SubTitle>
                        {kit.solarPlatesNumber + ' | ' + kit.fabricMaterial}
                      </SubTitle> */}
                      </div>
                    </div>

                    <div className="units-wrapper">
                      <Units>
                        <strong>{kit.sales}</strong>
                        <SubTitle>unidades</SubTitle>
                      </Units>

                      <div className="indicator-wrapper">
                        <Indicator status="neutral" />
                        <strong>{index + 1}°</strong>
                      </div>
                    </div>
                  </Li>
                );
            })}
          </ul>
        </BestSellingKits>
      </RankingWrapper>
    </Container>
  );
};

