import styled from 'styled-components';

export const Container = styled.div``;
export const ModalContainer = styled.div`
  padding: 32px;
  p {
    font-weight: 700;
  }
`;
export const Buttons = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 32px;
`;
