import { CSSProperties } from 'react';
import { DraggingStyle } from 'react-beautiful-dnd';

interface Props {
  draggableStyle?: DraggingStyle;
  virtualStyle?: CSSProperties;
  isDragging?: boolean;
}

export function getStyle(props: Props) {
  const { isDragging, virtualStyle, draggableStyle } = props;

  const combined = {
    ...virtualStyle,
    ...draggableStyle,
  };

  const grid = 12;

  const result = {
    ...combined,
    ...(combined.height && {
      height: isDragging ? combined.height : Number(combined.height) - grid,
    }),
    ...(combined.left && {
      left: isDragging ? combined.left : Number(combined.left) + grid,
    }),
    width: isDragging
      ? draggableStyle?.width
      : `calc(${combined.width} - ${grid + 12}px)`,
    margin: grid,
  };

  return result;
}
