import React from 'react';

import { Container, Content } from './styles';

import { Kanban } from './components/Kanban';

const SalesFunnelPage: React.FC = () => {
  return (
    <Container>
      <Content>
        <Kanban />
      </Content>
    </Container>
  );
};

export default SalesFunnelPage;
