import styled from 'styled-components';

export const Content = styled.main`
  display: flex;
  flex-direction: column;

  padding: 1.5rem 0;

  height: 100%;
`;

export const Header = styled.header`
  display: flex;
  flex-direction: column;
  gap: 2.5rem;

  & > h1 {
    font-family: 'Montserrat', sans-serif;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 600;
    letter-spacing: 0.0125rem;

    font-weight: 600;

    color: #27282b;
  }

  & > div {
    display: flex;
    align-items: center;
    gap: 1.5rem;
  }
`;

export const Divisor = styled.hr`
  margin: 3rem 0 2rem;

  border: 1px solid #dfe0e2;
`;

export const Grid = styled.main`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(22rem, 1fr));
  gap: 1.5rem;

  height: 100%;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  & > h1 {
    font-family: 'Montserrat', sans-serif;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 600;
    letter-spacing: 0.0125rem;

    color: #27282b;
  }
`;
