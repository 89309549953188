import styled, { css } from 'styled-components';

type ContainerProps = {
  isActive: boolean;
  hasError?: boolean;
};

export const Container = styled.div<ContainerProps>`
  width: 10rem;
  height: 2.5rem;

  & > div {
    display: flex;
    justify-content: center;
    align-items: center;

    height: 100%;

    & > input {
      background-color: transparent;

      border: 0;

      border-top: 1px solid #27282b;
      border-left: 1px solid #27282b;
      border-bottom: 1px solid #27282b;

      border-radius: 4px 0px 0px 4px;

      padding: 0.5rem 1rem;

      width: 6.375rem;
      height: 100%;

      text-align: right;

      ${({ isActive }) => {
        return (
          isActive &&
          css`
            border-color: #005af9;

            color: #005af9;
          `
        );
      }}

      ${({ hasError }) => {
        return (
          hasError &&
          css`
            border-color: #e01919;

            color: #e01919;
          `
        );
      }}

      &::-webkit-outer-spin-button,
    ::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      -moz-appearance: textfield;
    }

    & > span {
      display: flex;
      justify-content: center;
      align-items: center;

      width: 3.5rem;
      height: 100%;

      padding: 0.5rem 1rem;

      background-color: #dfe0e2;

      border-radius: 0px 4px 4px 0px !important;

      font-weight: 600;
      font-size: 1.5rem;

      color: #61646b;

      ${({ isActive }) => {
        return (
          isActive &&
          css`
            background-color: #d8e4fa;
          `
        );
      }}

      ${({ hasError }) => {
        return (
          hasError &&
          css`
            background-color: #dfe0e2;
          `
        );
      }}
    }
  }

  & > span {
    color: #e01919;

    font-size: 0.825rem;

    white-space: nowrap;
  }
`;
