import { ProjectType } from '../types/project';
import api from './projects';

export const ProjectService = {
  get: async (projectId: string) => {
    return api.get<ProjectType>(`/api/projects/${projectId}`);
  },

  update: async (projectId: string, data: any) => {
    return api.put(`/api/projects/${projectId}`, data);
  },
};
