import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  & > span {
    color: #131415;
    font-size: 1rem;
    font-family: 'DunbarTextRegular', sans-serif;
  }
`;

type TagProps = {
  bgColor: string;
};

export const Tag = styled.div<TagProps>`
  display: flex;
  align-items: center;
  gap: 0.75rem;

  & > label {
    cursor: pointer;

    display: flex;
    align-items: center;
    gap: 0.5rem;
    width: 100%;

    & > span {
      display: block;
      padding: 0.5rem;
      border-radius: 4px;
      background: ${(props) => props.bgColor};

      width: 100%;
      height: 2rem;

      color: #131415;
      font-size: 0.75rem;
      font-family: 'DunbarTextRegular', sans-serif;
    }
  }

  & > button {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 2rem;
    height: 2rem;

    border-radius: 4px;

    background: #fff;
    box-shadow: 0px 1px 0px 0px #ccc inset, 0px -1px 0px 0px #ccc inset,
      1px 0px 0px 0px #ccc inset, -1px 0px 0px 0px #ccc inset;

    &:hover {
      filter: brightness(0.95);
    }
  }
`;

export const Button = styled.button.attrs((props) => {
  return {
    ...props,
    type: 'button',
  };
})`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.25rem;

  width: 19.75rem;
  height: 2.5rem;

  border-radius: 8px;
  background: #c9cbcf;

  color: #27282b;
  font-size: 1rem;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;

  &:hover {
    filter: brightness(0.95);
  }
`;
