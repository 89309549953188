import React, { Fragment } from 'react';
import { CheckIcon } from '../icons';
import { StepProgressContainer, Step, StepLine } from './styles';

type Step = {
  key: number;
  title: string;
  active: boolean;
  complete: boolean;
};
interface StepProgressProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  steps: Step[];
}

export const StepProgress: React.FC<StepProgressProps> = (props) => {
  const { steps } = props;

  return (
    <StepProgressContainer>
      {steps.map((step, index) => {
        const isLast = steps.length - 1 === index;

        return (
          <Fragment key={step.key}>
            <Step isComplete={step.complete} isActive={step.active}>
              {step.active && (
                <span className="step-title">
                  {step.key}. {step.title}
                </span>
              )}
              {step.active && (
                <span className="step-title-mobile">{step.key}</span>
              )}
              {step.complete && <CheckIcon />}
              {!step.active && !step.complete && step.key}
            </Step>
            <StepLine isComplete={step.complete} isLast={isLast} />
          </Fragment>
        );
      })}
    </StepProgressContainer>
  );
};
