import React, { createContext, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import productsApi from '../../services/products';
import * as Types from './types';

const BudgetsContext = createContext<Types.BudgetsContextData>(
  {} as Types.BudgetsContextData
);

export const BudgetsProvider: React.FC = (props) => {
  const { children } = props;

  const [budget, setBudget] = useState<Types.Budget | null>(null);

  const [selectedKits, setSelectedKits] = useState<Types.SelectedKits>({
    kits: [],
    promotionalKits: [],
  });

  const [services, setServices] = useState<Types.BudgetItem[]>([]);

  const [products, setProducts] = useState<Types.ProductService[]>([]);

  const [profitability, setProfitability] =
    useState<Types.Profitability | null>(null);

  const { pathname } = useLocation();

  const cleanSelectedKits = () => {
    setSelectedKits({
      kits: [],
      promotionalKits: [],
    });
  };

  const cleanServices = () => {
    setServices([]);
  };

  const getBudget = async (budgetId: string) => {
    try {
      const { data } = await productsApi.get<Types.Budget>(
        `/budgets/${budgetId}`
      );

      const { items } = data;

      handleBudgetItems(items);

      handleBudget(data);

      return data;
    } catch (error) {
      toast.error(
        'Desculpe, não foi possível buscar as informações do orçamento'
      );

      throw error;
    }
  };

  const handleBudgetItems = (budgetItems: Types.BudgetItem[]) => {
    const products: Types.ProductService[] = [];
    const kits: Types.Kit[] = [];

    budgetItems.forEach((item) => {
      const { customItem } = item;

      if (!customItem) {
        budgetItems.forEach((item) => {
          const product: Types.ProductService = {
            budgetItemId: item.id,
            quantity: item.quantity,
            idealProfitability: item.idealProfitability,
          };

          if (item.product) {
            const kit = {
              ...item.product,
              quantity: item.product.quantity,
            };

            kits.push(kit);
          }

          products.push(product);
        });

        handleServices(budgetItems);

        handleProducts(products);

        handleSelectKits(kits);
      }
    });
  };

  const getProfitability = async () => {
    try {
      const { data } = await productsApi.get<Types.Profitability>(
        '/budgets/profitability'
      );

      setProfitability(data);
    } catch (error) {
      toast.error('Não foi possível buscar a margem dos kits');

      throw error;
    }
  };

  const handleIncrementQuantity = async (budgetItemId: string) => {
    setProducts((state) => {
      const kitToUpdate = state.find(
        (kit) => kit.budgetItemId === budgetItemId
      );
      if (!kitToUpdate) {
        return state;
      }
      return state.map((kit) => {
        if (kit.budgetItemId === budgetItemId) {
          return { ...kit, quantity: Number(kit.quantity) + 1 };
        }
        return kit;
      });
    });
  };

  const handleDecrementQuantity = async (budgetItemId: string) => {
    setProducts((state) => {
      const kitToUpdate = state.find(
        (kit) => kit.budgetItemId === budgetItemId
      );

      if (!kitToUpdate) {
        return state;
      }
      return state.map((kit) => {
        if (kit.budgetItemId === budgetItemId) {
          return { ...kit, quantity: Number(kit.quantity) - 1 };
        }
        return kit;
      });
    });
  };

  const handleServices = (services: Types.BudgetItem[]) => {
    setServices(services);
  };

  const handleBudget = (budget: Types.Budget) => {
    setBudget(budget);
  };

  const handleSelectAllKits = (kits: Types.Kit[]) => {
    setSelectedKits((state) => {
      const hasValue = kits.every((_kit) =>
        state.kits.some((kit) => kit.id === _kit.id)
      );

      if (hasValue) {
        return {
          ...state,
          kits: state.kits.filter(
            (_kit) => !kits.find((kit) => kit.id === _kit.id)
          ),
        };
      }

      const normalizedKits = kits.map((kit) => ({
        ...kit,
        quantity: 1,
      }));

      return {
        ...state,
        kits: [...state.kits, ...normalizedKits],
      };
    });
  };

  const handleSelectKit = (kit: Types.Kit, promotional: boolean) => {
    setSelectedKits((state) => {
      const normalizedKit = {
        ...kit,
        quantity: kit.quantity || 1,
      };

      if (!promotional) {
        const existingValue = state.kits.find((k) => k.id === kit.id);

        if (existingValue) {
          return {
            ...state,
            kits: state.kits.filter((k) => k.id !== kit.id),
          };
        }

        return {
          ...state,
          kits: [...state.kits, normalizedKit],
        };
      } else {
        const existingValue = state.promotionalKits.find(
          (k) => k.id === kit.id
        );

        if (existingValue) {
          return {
            ...state,
            promotionalKits: state.promotionalKits.filter(
              (k) => k.id !== kit.id
            ),
          };
        }

        return {
          ...state,
          promotionalKits: [...state.promotionalKits, normalizedKit],
        };
      }
    });
  };

  const handleSelectKits = (kits: Types.Kit[]) => {
    const normalizedKits = {
      promotionalKits: kits.filter((kit) => kit.promotional),
      kits: kits.filter((kit) => !kit.promotional),
      customKits: [],
    };

    setSelectedKits(normalizedKits);
  };

  const handleProducts = (products: Types.ProductService[]) => {
    setProducts(products);
  };

  const cleanBudgetFlow = () => {
    cleanSelectedKits();

    cleanServices();

    setBudget(null);

    setProducts([]);
  };

  useEffect(() => {
    const isOutBudgetFlow = !pathname.includes(
      '/orcamentos/selecionar-produto/'
    );

    if (isOutBudgetFlow) {
      cleanBudgetFlow();
    }
  }, [pathname]);

  return (
    <BudgetsContext.Provider
      value={{
        budget,
        selectedKits,
        services,
        products,
        profitability,
        handleBudget,
        handleSelectKit,
        handleSelectAllKits,
        handleServices,
        handleIncrementQuantity,
        handleDecrementQuantity,
        cleanBudgetFlow,
        handleProducts,
        getProfitability,
        getBudget,
        cleanSelectedKits,
        cleanServices,
      }}
    >
      {children}
    </BudgetsContext.Provider>
  );
};

export const useBudgets = () => {
  const context = useContext(BudgetsContext);

  return context;
};
