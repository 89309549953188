export const toMoneyFormat = (
  value?: number | string | null,
  rest?: Intl.NumberFormatOptions
) => {
  const normalizedValue = Number(value);

  const { format } = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
    ...rest,
  });

  if (isNaN(normalizedValue)) {
    return format(0);
  }

  return format(normalizedValue);
};
