import styled from 'styled-components';

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  width: 100%;

  & > h1 {
    font-family: 'Montserrat';
    font-weight: 600;
    font-size: 1.25rem;

    letter-spacing: 0.01em;

    color: #27282b;
  }
`;

export const DownloadContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  flex: 1;

  @media (max-width: 992px) {
    & > button {
      width: 100%;
    }
  }
`;

export const Title = styled.h1`
  font-family: 'DunbarTallBold';
  font-weight: 500;
  font-size: 1.5rem;

  letter-spacing: 0.03em;

  color: #000000;
`;

export const TableHeader = styled.header`
  display: flex;
  justify-content: flex-end;
  gap: 1rem;

  background-color: #fafafa;

  @media (max-width: 992px) {
    flex-direction: column;

    & > div:has(input) {
      max-width: unset;
      order: 2;
    }

    & > div:has(button) {
      order: 1;
    }

    & > div:has(button) > button {
      width: 100%;
    }
  }
`;
