import axios from 'axios';
import { axiosConfig } from './config';

const projectsApi = axios.create({
  baseURL: process.env.REACT_APP_PROJECT_MICROSERVICE_URL,
});

axiosConfig(projectsApi);

export default projectsApi;
