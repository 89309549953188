import styled from 'styled-components';

export const Content = styled.main`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(22rem, 22rem));
  grid-template-rows: max-content;
  gap: 1.5rem;

  margin: 3rem 0;

  &::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: 992px) {
    grid-template-columns: 1fr;
  }
`;

export const ProductCard = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 22rem;
  height: 15rem;
  padding: 1rem;
  background: #ffffff;
  border: 1px solid #dfe0e2;
  border-radius: 1rem;

  border-radius: 8px;

  cursor: pointer;

  & > svg {
    width: 4rem;
    height: 4rem;

    margin-bottom: 2rem;

    path {
      stroke: #323232;
      stroke-width: 0.5px;
    }
  }

  & > h1 {
    font-weight: 600;
    font-size: 1.25rem;

    color: #27282b;

    margin-bottom: 1.5rem;
  }

  & > p {
    font-weight: 400;
    font-size: 1rem;

    color: #27282b;
  }
`;
