import styled, { css } from 'styled-components';

export const Container = styled.div``;

export const Wrapper = styled.div``;

export const PageHeader = styled.div`
  display: flex;
  justify-content: space-between;

  h1 {
    font-weight: bold;
    font-size: 2rem;

    color: #292929;
  }
`;

export const FormContainer = styled.div`
  margin: 2.25rem 0;
  border: 1px solid #dfe0e2;
  background-color: #fff;
  border-radius: 12px;
  padding: 5%;
`;

type InputGroup = {
  sideSpace?: 'left' | 'right';
  size?: 'small';
  marginTop?: boolean;
};

export const InputGroup = styled.div<InputGroup>`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 1.5rem;

  span {
    display: inline-block;
    margin-bottom: 0.5rem;
  }

  ${(props) =>
    props.marginTop === false &&
    css`
      margin-top: 0px;
    `}

  ${(props) =>
    props.size === 'small' &&
    css`
      width: 25%;
    `}
`;

type groupType = {
  size?: 'small' | 'medium';
};

export const Group = styled.div<groupType>`
  max-width: 46.875rem;

  ${(props) =>
    props.size === 'small' &&
    css`
      max-width: 21.875rem;
    `}
`;

export const Divider = styled.hr`
  border: 1px solid #e0e0e0;
  margin: 3rem 0;
`;

export const GroupHeader = styled.div`
  width: 100%;
  h2 {
    font-weight: 500;
    font-size: 1.125rem;
    font-weight: 700;

    color: #292929;
  }

  span {
    display: inline-block;
    margin-top: 1rem;
    font-weight: 500;
    font-size: 1rem;
    line-height: 100%;

    color: #666666;
  }
`;

export const GroupedInputs = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 1rem;
`;
