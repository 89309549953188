import { yupResolver } from '@hookform/resolvers/yup';
import { format } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import React, { useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { FiXCircle } from 'react-icons/fi';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { Breadcrumb } from '../../../../../../components/breadcrumb';
import { Button } from '../../../../../../components/button';
import { FileInput, IFile } from '../../../../../../components/file-input';
import { CloudIcon } from '../../../../../../components/icons';
import { CancelOrderModal } from '../../../../../../components/modal/cancel-order';
import { Order } from '../../../../../../contexts/orders/types';
import productsApi from '../../../../../../services/products';
import { downloadFile } from '../../../../../../utils/downloadFile';
import { ButtonGroup, Container, PageHeader } from '../../../../../styles';
import { ORDER_STATUS } from '../../../components/orders/innerTables/consultant';
import { DownloadDocumentContainer, SentDate } from '../../../styles';
import {
  CancelOrder,
  Content,
  Documents,
  NFE,
  Voucher,
  Wrapper,
} from './styles';

type ConsultantWaitingNfeProps = {
  order: Order;
};

type Form = {
  nfe: IFile[];
};

type Files = {
  PaymentConfirmation: IFile[];
  BOLETO: IFile[];
  NFE: IFile[];
  NFF: IFile[];
};

const schema = yup.object().shape({
  nfe: yup
    .mixed()
    .test('check if field is empty', (value: IFile[], { createError }) => {
      const hasErrors = value.some((file) => file.error);

      if (value.length > 0) {
        return hasErrors
          ? createError({
              path: 'nfe',
              message: 'Remova o arquivo com erro',
              type: 'field-error',
            })
          : true;
      } else {
        return createError({
          path: 'nfe',
          message: 'Campo obrigatório',
          type: 'field-empty',
        });
      }
    }),
});

export const ConsultantWaitingNfe: React.FC<ConsultantWaitingNfeProps> = (
  props
) => {
  const { order } = props;

  const files = useMemo(() => {
    return (
      order.files.reduce(
        (acc: Files, file: File) => {
          if (file.type in acc) {
            return {
              ...acc,
              [file.type]: [...acc[file.type as keyof Files], file],
            };
          }

          return acc;
        },
        {
          PaymentConfirmation: [],
          BOLETO: [],
          NFE: [],
          NFF: [],
        }
      ) ?? {
        PaymentConfirmation: [],
        BOLETO: [],
        NFE: [],
        NFF: [],
      }
    );
  }, [order]);

  const hasBoleto = !!files.BOLETO.length;

  const hasNFE = !!files.NFE.length;

  const hasNFF = !!files.NFF.length;

  const { orderId, status } = useParams<{
    orderId: string;
    status: ORDER_STATUS;
  }>();

  const history = useHistory();

  const links = [
    {
      id: 1,
      title: 'Orçamentos',
      link: '/orcamentos',
      active: false,
    },
    {
      id: 2,
      title: 'Orçamentos e Pedidos',
      link: '/orcamentos/orcamentos-e-pedidos',
      active: false,
    },
    {
      id: 3,
      title: 'Pedidos enviados',
      link: `/orcamentos/orcamentos-e-pedidos/pedidos-enviados/${orderId}/${status}`,
      active: true,
    },
  ];

  const { control, handleSubmit, watch } = useForm<Form>({
    resolver: yupResolver(schema),
    defaultValues: {
      nfe: files.NFE,
    },
  });

  const hasFiles = !!watch('nfe').length;

  const updateOrderStatus = () => {
    productsApi
      .patch(`/orders/${order.id}/status`, {
        status: 'NFE emitida',
        franchiseStatus: 'NFE emitida',
      })
      .then(() => {
        toast.success('Status do pedido atualizado');

        history.replace('/orcamentos/orcamentos-e-pedidos/');
      })
      .catch((error) => {
        const errorMessage = error.response.data.message;

        toast.error(errorMessage);
      });
  };

  const onSubmit = () => {
    updateOrderStatus();
  };

  const [cancelModal, setCancelModal] = useState(false);

  const cancelOrder = () => {
    productsApi
      .patch(`/orders/${order.id}/status`, {
        status: 'Pedido cancelado',
        franchiseStatus: 'Pedido cancelado',
      })
      .then(() => {
        toast.success('Status do pedido atualizado');

        history.replace('/orcamentos/orcamentos-e-pedidos/');
      })
      .catch((error) => {
        const errorMessage = error.response.data.message;

        toast.error(errorMessage);
      });
  };

  const handleCancelModal = () => {
    setCancelModal((state) => !state);
  };

  return (
    <Container>
      <Breadcrumb links={links} />

      <PageHeader>
        <span>{'Pedidos enviados'}</span>
      </PageHeader>

      <Content onSubmit={handleSubmit(onSubmit)}>
        <Documents>
          <div>
            <h1>Documentos enviados pelo vendedor</h1>

            <Wrapper>
              {hasBoleto && (
                <ul>
                  <label>Boleto/espelho</label>

                  {files.BOLETO.map((file) => {
                    return (
                      <li key={file.id}>
                        <Button
                          type="button"
                          backgroundColor="#FFFFFF"
                          typeStyle="default"
                          text="Fazer download"
                          onClick={() => {
                            if (file.path) {
                              downloadFile(file.path);
                            }
                          }}
                          icon={<CloudIcon />}
                        />
                        <SentDate>
                          Enviado em:{' '}
                          {format(
                            new Date(file.updatedAt as string),
                            'dd/MM/yyyy',
                            {
                              locale: ptBR,
                            }
                          )}
                        </SentDate>
                      </li>
                    );
                  })}
                </ul>
              )}

              {hasNFE && (
                <ul>
                  <label>NFE</label>

                  {files.NFE.map((file) => {
                    return (
                      <li key={file.id}>
                        <Button
                          type="button"
                          backgroundColor="#FFFFFF"
                          typeStyle="default"
                          text="Fazer download"
                          onClick={() => {
                            if (file.path) {
                              downloadFile(file.path);
                            }
                          }}
                          icon={<CloudIcon />}
                        />
                        <SentDate>
                          Enviado em:{' '}
                          {format(
                            new Date(file.updatedAt as string),
                            'dd/MM/yyyy',
                            {
                              locale: ptBR,
                            }
                          )}
                        </SentDate>
                      </li>
                    );
                  })}
                </ul>
              )}

              {hasNFF && (
                <ul>
                  <label>NFF/espelho</label>

                  {files.NFF.map((file) => {
                    return (
                      <li key={file.id}>
                        <Button
                          type="button"
                          backgroundColor="#FFFFFF"
                          typeStyle="default"
                          text="Fazer download"
                          onClick={() => {
                            if (file.path) {
                              downloadFile(file.path);
                            }
                          }}
                          icon={<CloudIcon />}
                        />
                        <SentDate>
                          Enviado em:{' '}
                          {format(
                            new Date(file.updatedAt as string),
                            'dd/MM/yyyy',
                            {
                              locale: ptBR,
                            }
                          )}
                        </SentDate>
                      </li>
                    );
                  })}
                </ul>
              )}
            </Wrapper>
          </div>

          <CancelOrder>
            <Button
              type="button"
              backgroundColor="#E01919"
              backgroundHoverColor="#e01919c1"
              typeStyle="default"
              text="Cancelar pedido"
              onClick={handleCancelModal}
              icon={<FiXCircle />}
            />
          </CancelOrder>
        </Documents>

        <Voucher>
          <h1>Comprovante de pagamento</h1>

          <div>
            <label>Comprovante de pagamento</label>

            {files.PaymentConfirmation.map((file) => {
              return (
                <DownloadDocumentContainer key={file.name}>
                  <Button
                    key={file.id}
                    type="button"
                    backgroundColor="#FFFFFF"
                    typeStyle="default"
                    text="Fazer download"
                    onClick={() => {
                      if (file.path) {
                        downloadFile(file.path);
                      }
                    }}
                    icon={<CloudIcon />}
                  />
                  <SentDate>
                    Enviado em:{' '}
                    {format(new Date(file.updatedAt as string), 'dd/MM/yyyy', {
                      locale: ptBR,
                    })}
                  </SentDate>
                </DownloadDocumentContainer>
              );
            })}
          </div>
        </Voucher>

        <NFE>
          <h1>NFE</h1>

          <Controller
            name="nfe"
            control={control}
            render={({
              field: { ref, value, onChange },
              fieldState: { error },
            }) => {
              return (
                <FileInput
                  ref={ref}
                  label="NFE"
                  inputLabel="Anexar arquivo"
                  error={error?.message}
                  api={productsApi}
                  url={`/orders/upload-file/${order.id}/type/NFE`}
                  deleteUrl={'/orders/upload-file/'}
                  onChange={(files) => onChange(files)}
                  files={value}
                />
              );
            }}
          />
        </NFE>

        <ButtonGroup>
          <Button
            type="button"
            text="Voltar"
            typeStyle="default"
            backgroundHoverColor="#C9CBCF"
            onClick={() =>
              history.replace('/orcamentos/orcamentos-e-pedidos', 'Pedidos')
            }
          />
          <Button
            type="submit"
            text={'Enviar para franqueado'}
            disabled={!hasFiles}
          />
        </ButtonGroup>
      </Content>

      <CancelOrderModal
        isOpen={cancelModal}
        onConfirm={cancelOrder}
        onCancel={handleCancelModal}
      />
    </Container>
  );
};
