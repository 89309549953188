import React, { CSSProperties, ForwardedRef, ReactNode, useState } from 'react';
import Select, {
  MenuPosition,
  SelectInstance,
  StylesConfig,
} from 'react-select';
import { Container } from './styles';

export type Option = {
  value: string | boolean | number;
  label: string;
};

type SelectInputProps = {
  label?: string;
  placeholder?: string;
  options?: Option[];
  isSearchable?: boolean;
  isClearable?: boolean;
  isDisabled?: boolean;
  onChange?: (e: Option) => void;
  required?: boolean;
  error?: string;
  defaultValue?: Option;
  value?: Option | string;
  style?: CSSProperties;
  message?: ReactNode;
  name?: string;
  isOptionDisabled?: (option: unknown) => boolean;
  menuPosition?: MenuPosition;
};

const SelectInput = React.forwardRef(
  (props: SelectInputProps, myRef: ForwardedRef<SelectInstance>) => {
    const {
      label,
      placeholder,
      options,
      isDisabled = false,
      isSearchable = true,
      isClearable = false,
      onChange,
      required = false,
      error,
      defaultValue,
      value,
      style,
      message,
      name,
      isOptionDisabled = () => false,
      menuPosition,
    } = props;

    const customStyles: StylesConfig = {
      placeholder: (provided, state) => ({
        ...provided,
        color: state.isDisabled ? '#9AA3AC' : '#61646b',
        fontWeight: 400,
        fontSize: style?.fontSize || '1rem',
      }),
      container: (provided) => ({
        ...provided,
        height: style?.height || '3rem',
      }),
      control: (provided, state) => ({
        ...provided,
        height: style?.height || '3rem',
        flexWrap: 'nowrap',
        minHeight: 'unset',
        backgroundColor: state.isDisabled ? '#F4F5F6' : '#fff',
        fontSize: style?.fontSize || '1rem',
        color: state.isDisabled ? '#9AA3AC' : '#27282b',
        cursor: 'pointer',
        border: state.isFocused ? 'none' : provided.border,
        borderColor: error ? '#e01919' : '#C9CBCF',
        pointerEvents: state.isDisabled ? 'none' : 'auto',
        userSelect: state.isDisabled ? 'none' : 'auto',
        ':hover': {
          borderColor: error ? '#e01919' : '#C9CBCF',
        },
      }),
      indicatorSeparator: () => ({
        display: 'none',
      }),
      menu: (provided) => ({
        ...provided,
        backgroundColor: `#F9FAFB`,
      }),
      option: (provided, state) => ({
        ...provided,
        fontSize: style?.fontSize || '1rem',
        cursor: state.isDisabled ? 'not-allowed' : 'pointer',
        color: state.isDisabled ? 'gray' : '#292929',
        backgroundColor: state.isFocused ? '#EDF3FD' : '',
        ':hover': {
          backgroundColor: state.isDisabled ? '' : '#005AF9',
          color: state.isDisabled ? '' : '#fff',
        },
        ':selected': {
          backgroundColor: state.isFocused ? '#EDF3FD' : '',
        },
        ':active': {
          backgroundColor: state.isFocused ? '#EDF3FD' : '',
        },
      }),
      valueContainer: (provided) => ({
        ...provided,
        paddingLeft: '1rem',
        border: 0,
      }),
      menuList: (provided) => ({
        ...provided,
        maxHeight: '148px',
        '::-webkit-scrollbar': {
          width: '4px',
        },
        '::-webkit-scrollbar-track': {
          background: '#f5f5f6',
          borderRadius: '8px',
        },
        '::-webkit-scrollbar-thumb': {
          background: '#a8b0b5',
          borderRadius: '8px',
        },
      }),
    };

    const [menuOpen, setMenuOpen] = useState(false);

    return (
      <Container menuOpen={menuOpen} isDisabled={isDisabled} style={style}>
        {required ? label + '*' : label}

        <Select
          name={name}
          ref={myRef}
          menuShouldScrollIntoView={true}
          onMenuOpen={() => setMenuOpen(true)}
          onMenuClose={() => setMenuOpen(false)}
          options={options}
          defaultValue={defaultValue}
          placeholder={placeholder}
          styles={customStyles}
          theme={(theme) => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary: '#005AF9',
            },
          })}
          isSearchable={isSearchable}
          onChange={(option) => onChange?.(option as Option)}
          noOptionsMessage={() => 'Nenhuma opção encontrada'}
          value={value}
          isDisabled={isDisabled}
          isClearable={isClearable}
          isOptionDisabled={(option) => isOptionDisabled?.(option)}
          menuPosition={menuPosition}
        />

        {message && <span className="message">{message}</span>}

        {error && (
          <span style={{ color: '#E01919', fontSize: '0.875rem' }}>
            {error}
          </span>
        )}
      </Container>
    );
  }
);

SelectInput.displayName = 'SelectInput';

export default SelectInput;
