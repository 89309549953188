import styled from 'styled-components';

export const Container = styled.div`
  max-width: 22rem;
  width: 100%;
  height: 29.25rem;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  border-radius: 8px;
  border: 1px solid #c9cbcf;
  padding: 1.25rem 1rem 2.5rem;
  background-color: #fff;

  @media (max-width: 520px) {
    height: fit-content;
    max-width: unset;
  }
`;

export const Title = styled.h3`
  font-weight: 500;
  font-size: 1rem;
  line-height: 100%;
  text-align: center;
  color: #61646b;
`;

export const Values = styled.div`
  display: flex;
  justify-content: space-between;

  padding: 4rem 3rem 0;
`;

export const Value = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.25rem;

  & > p {
    font-weight: 500;
    font-size: 1rem;
    line-height: 100%;
    text-align: center;
    color: #61646b;
  }

  & > span {
    font-weight: 700;
    font-size: 2rem;
    line-height: 100%;
    text-align: center;
    letter-spacing: 0.03em;
    color: #27282b;
  }
`;

export const ChartContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;

  position: relative;
`;

export const Percentage = styled.p`
  font-family: 'DunbarTallBold';
  font-weight: 700;
  font-size: 2rem;
  line-height: 100%;
  letter-spacing: 0.03em;
  color: #27282b;

  position: absolute;
  top: 55%;
`;
