import React from 'react';
import { Props } from 'react-modal';

import { toast } from 'react-toastify';
import { ShapeModal } from '..';
import { useBudgets } from '../../../contexts/budgets';
import { toMoneyFormat } from '../../../utils/toMoneyFormat';
import { Button } from '../../button';
import PercentageInput from '../../percentage-input';
import { Buttons } from '../styles';
import {
  Container,
  Content,
  Header,
  TableContainer,
  TableFooter,
  TableHeader,
  ValuesData,
} from './styles';

type EditProfitabilityModalProps = {
  onCancel: () => void;
  isOpen: boolean;
} & Props;

export const EditProfitabilityModal: React.FC<EditProfitabilityModalProps> = (
  props
) => {
  const { onCancel, isOpen, ...rest } = props;

  const { services, products, profitability, handleProducts } = useBudgets();

  const handleProfitability = (id: string, value: number) => {
    const updatedProducts = products.map((kit) =>
      kit.budgetItemId === id
        ? {
            ...kit,
            idealProfitability: value,
            error:
              value < (profitability?.minimun ?? 0)
                ? `Deve ser maior que lucro mínimo(${profitability?.minimun}%)`
                : '',
          }
        : kit
    );

    handleProducts(updatedProducts);
  };

  const handleCloseModal = () => {
    const hasErrors = products.some((product) => product.error);

    if (hasErrors) {
      toast.info('Corrija os erros antes de prosseguir');

      return;
    }

    onCancel();
  };

  return (
    <ShapeModal
      isOpen={isOpen}
      onRequestClose={handleCloseModal}
      onAfterClose={() => {
        document.documentElement.style.overflow = 'auto';
      }}
      onAfterOpen={() => {
        document.documentElement.style.overflow = 'hidden';
      }}
      maxWidth="85.5rem"
      {...rest}
    >
      <Container>
        <Header>
          <h1>Edição de valores</h1>
          <p>Edite as margens percentuais dos valores de instalação, por kit</p>
        </Header>

        <Content>
          <TableContainer>
            <TableHeader>
              <h1>Kits</h1>
            </TableHeader>
            <div>
              <table>
                <thead>
                  <tr>
                    <th>Fornecedor</th>
                    <th>Categoria</th>
                    <th>Kits</th>
                    <th>Margem</th>
                    <th>Valores</th>
                  </tr>
                </thead>
                <tbody>
                  {services.map((item) => {
                    const itemProfitability = products.find(
                      (product) => product.budgetItemId === item.id
                    );

                    return (
                      <tr key={item.product?.id}>
                        <td>{item.product?.supplier}</td>
                        <td>{item.product?.category}W</td>
                        <td>
                          <strong>
                            {item.product?.power} KWp | {item.product?.voltage}V
                            <span>
                              {item.product?.solarPlatesNumber} módulos |{' '}
                              {item.totalGeneration}KWh
                            </span>
                          </strong>
                        </td>
                        <td>
                          <PercentageInput
                            step="0.01"
                            name={itemProfitability?.budgetItemId}
                            defaultValue={itemProfitability?.idealProfitability}
                            onChange={(e) => {
                              const value = Number(e.target.value);

                              handleProfitability(item.id, value);
                            }}
                            error={itemProfitability?.error}
                          />
                        </td>
                        <td>
                          <ValuesData>
                            <span>Kit: {toMoneyFormat(item.price)}</span>
                            <span>
                              Instalação: {toMoneyFormat(item.income)}
                            </span>
                            <span className="hr" />
                            <strong>
                              Total:{' '}
                              {toMoneyFormat(item.total - item.maintenance)}
                            </strong>
                          </ValuesData>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
                <TableFooter />
              </table>
            </div>
          </TableContainer>
        </Content>

        <Buttons>
          <Button
            type="button"
            text="Concluir"
            typeStyle="default"
            backgroundHoverColor="#C9CBCF"
            onClick={handleCloseModal}
          />
        </Buttons>
      </Container>
    </ShapeModal>
  );
};
