import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';

import { FiInfo } from 'react-icons/fi';

import { Breadcrumb, Button, StepProgress } from '../../../../components';
import Input from '../../../../components/input';
import InputPrice from '../../../../components/input-price';
import MaskInput from '../../../../components/mask-input';
import RadioGroup from '../../../../components/radio-group';

import { normalize } from '../../../../utils/normalize';

import { toast } from 'react-toastify';
import Loading from '../../../../components/loading';
import { useOrders } from '../../../../contexts/orders';
import productsApi from '../../../../services/products';
import { toMoneyFormat } from '../../../../utils/toMoneyFormat';
import { ButtonGroup, Container, PageHeader } from '../../../styles';
import { Content, Row, ValueContainer } from './styles';

const steps = [
  { key: 1, title: 'Selecionar kit', active: false, complete: true },
  { key: 2, title: 'Resumo', active: false, complete: true },
  { key: 3, title: 'Acessórios', active: false, complete: true },
  { key: 4, title: 'Custos', active: true, complete: false },
  { key: 5, title: 'Pagamento', active: false, complete: false },
  { key: 6, title: 'Documentos', active: false, complete: false },
  { key: 7, title: 'Termo de aceite', active: false, complete: false },
];

type Costs = {
  total: string;
  invoiceValue: string;
  plateNumber: number;
};

type CostsForm = {
  installerArt: string;
  salesCommission: boolean;
  referralCommission: boolean;
  energyProject: boolean;
  projectValue: string;
  invoiceValue: string;
};

const Schema = yup.object().shape({
  salesCommission: yup.boolean(),
  referralCommission: yup.boolean(),
  energyProject: yup.boolean(),
  installerArt: yup
    .string()
    .required('Digite o valor')
    .test((value, { createError }) => {
      if (!value) return false;

      if (normalize(value) <= 0)
        return createError({
          path: 'installerArt',
          message: 'Digite o valor',
          type: 'field-error',
        });

      return true;
    }),
  projectValue: yup
    .string()
    .required('Digite o valor')
    .test((value, { createError }) => {
      if (!value) return false;

      if (normalize(value) <= 0)
        return createError({
          path: 'projectValue',
          message: 'Digite o valor',
          type: 'field-error',
        });

      return true;
    }),
  invoiceValue: yup
    .string()
    .required('Digite o valor')
    .test((value, { createError }) => {
      if (!value) return false;

      if (normalize(value) <= 0)
        return createError({
          path: 'invoiceValue',
          message: 'Digite o valor',
          type: 'field-error',
        });

      return true;
    }),
});

const OrdersCostsPage: React.FC = () => {
  const history = useHistory();

  const { getOrder } = useOrders();

  const { id: orderId } = useParams<{ id: string }>();

  const links = [
    {
      id: 1,
      title: 'Orçamentos',
      link: '/orcamentos',
      active: false,
    },
    {
      id: 2,
      title: 'Orçamentos e pedidos',
      link: '/orcamentos/orcamentos-e-pedidos',
      active: false,
    },
    {
      id: 3,
      title: 'Gerar pedido',
      link: '/orcamentos/orcamentos-e-pedidos/',
      active: false,
    },
    {
      id: 4,
      title: 'Selecionar kit',
      link: '/orcamentos/orcamentos-e-pedidos/gerar-pedido/selecione',
      active: false,
    },
    {
      id: 5,
      title: 'Resumo',
      link: `/orcamentos/orcamentos-e-pedidos/gerar-pedido/${orderId}/resumo`,
      active: false,
    },
    {
      id: 6,
      title: 'Acessórios',
      link: `/orcamentos/orcamentos-e-pedidos/gerar-pedido/${orderId}/acessorios`,
      active: false,
    },
    {
      id: 7,
      title: 'Custos',
      link: `/orcamentos/orcamentos-e-pedidos/gerar-pedido/${orderId}/custos`,
      active: true,
    },
  ];

  const {
    control,
    register,
    handleSubmit,
    reset,
    formState: { errors, isValid },
  } = useForm<CostsForm>({
    resolver: yupResolver(Schema),
    mode: 'onChange',
    defaultValues: {
      installerArt: '',
      salesCommission: false,
      referralCommission: false,
      energyProject: false,
      projectValue: '',
      invoiceValue: '',
    },
  });

  const [loading, setLoading] = useState(true);

  const [costs, setCosts] = useState<Costs | null>(null);

  const totalAccessories = toMoneyFormat(Number(costs?.total ?? 0));

  const onSubmit = async (data: CostsForm) => {
    const normalizedData = {
      invoiceValue: Number(data.invoiceValue),
      installerArt: normalize(data.installerArt),
      projectValue: normalize(data.projectValue),
      salesCommission: data.salesCommission,
      referralCommission: data.referralCommission,
      energyProject: data.energyProject,
    };

    try {
      await productsApi.put(`/orders/${orderId}/update-costs`, normalizedData);

      history.push(
        `/orcamentos/orcamentos-e-pedidos/gerar-pedido/${orderId}/formas-de-pagamento`
      );
    } catch (error) {
      toast.error('Desculpe, não foi possível salvar as informações');

      throw error;
    }
  };

  const getCosts = async () => {
    try {
      const { data } = await productsApi.get(`/orders/${orderId}/costs-values`);

      reset(
        {
          invoiceValue: data.invoiceValue,
        },
        {
          keepDefaultValues: true,
        }
      );

      setCosts(data);
    } catch (error) {
      toast.error('Desculpe, não foi possível buscar os dados de custos');

      history.push(
        `/orcamentos/orcamentos-e-pedidos/gerar-pedido/${orderId}/acessorios`
      );

      throw error;
    } finally {
      setLoading(false);
    }
  };

  const fetchData = async () => {
    await getOrder(orderId);

    await getCosts();
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (loading) {
    return <Loading />;
  }

  return (
    <Container>
      <Breadcrumb links={links} />
      <PageHeader>
        <span>Custos</span>
        <div>
          <StepProgress steps={steps} />
        </div>
      </PageHeader>
      <Content onSubmit={handleSubmit(onSubmit)}>
        <h2>Custos</h2>

        <Row>
          <ValueContainer>
            <p>Acessórios</p>
            <span>{totalAccessories}</span>
          </ValueContainer>
          <InputPrice
            label="Instalador (ART incluso)"
            placeholder="R$ 0,00"
            message={
              <>
                <FiInfo /> Custo total do instalador
              </>
            }
            error={errors.installerArt?.message}
            {...register('installerArt')}
          />
          <Input
            label="Número de módulos"
            disabled
            defaultValue={costs?.plateNumber}
          />
        </Row>

        <Row>
          <Controller
            name="salesCommission"
            control={control}
            render={({ field: { ref, onChange, value } }) => {
              return (
                <RadioGroup
                  label="Comissão para vendedor?"
                  rowMode
                  options={[
                    { label: 'Sim', value: 'true' },
                    { label: 'Não', value: 'false' },
                  ]}
                  ref={ref}
                  error={errors.salesCommission?.message}
                  onChange={(event) => {
                    const value = JSON.parse(event.target.value);

                    onChange(value);
                  }}
                  value={JSON.stringify(value)}
                />
              );
            }}
          />

          <Controller
            name="referralCommission"
            control={control}
            render={({ field: { ref, onChange, value } }) => {
              return (
                <RadioGroup
                  label="Comissão por indicação?"
                  rowMode
                  options={[
                    { label: 'Sim', value: 'true' },
                    { label: 'Não', value: 'false' },
                  ]}
                  ref={ref}
                  error={errors.referralCommission?.message}
                  onChange={(event) => {
                    const value = JSON.parse(event.target.value);

                    onChange(value);
                  }}
                  value={JSON.stringify(value)}
                />
              );
            }}
          />

          <MaskInput
            label="Nota fiscal"
            mask="999"
            iconRight="%"
            error={errors.invoiceValue?.message}
            {...register('invoiceValue')}
          />
        </Row>

        <Row>
          <Controller
            name="energyProject"
            control={control}
            render={({ field: { ref, onChange, value } }) => {
              return (
                <RadioGroup
                  label="Projeto feito pela Energy?"
                  rowMode
                  options={[
                    { label: 'Sim', value: 'true' },
                    { label: 'Não', value: 'false' },
                  ]}
                  ref={ref}
                  error={errors.energyProject?.message}
                  onChange={(event) => {
                    const value = JSON.parse(event.target.value);

                    onChange(value);
                  }}
                  value={JSON.stringify(value)}
                />
              );
            }}
          />
          <InputPrice
            label="Valor do projeto"
            placeholder="R$ 0,00"
            error={errors.projectValue?.message}
            {...register('projectValue')}
          />
        </Row>

        <ButtonGroup>
          <Button
            type="button"
            text="Voltar"
            typeStyle="default"
            backgroundHoverColor="#C9CBCF"
            onClick={() =>
              history.push(
                `/orcamentos/orcamentos-e-pedidos/gerar-pedido/${orderId}/acessorios`
              )
            }
          />

          <Button type="submit" text="Avançar" disabled={!isValid} />
        </ButtonGroup>
      </Content>
    </Container>
  );
};

export default OrdersCostsPage;
