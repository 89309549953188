import React, { useEffect, useState } from 'react';
import { Props } from 'react-modal';

import { toast } from 'react-toastify';
import { ShapeModal } from '..';
import { useBudgets } from '../../../contexts/budgets';
import { Button } from '../../button';
import PercentageInput from '../../percentage-input';
import { Buttons } from '../styles';
import {
  Container,
  Content,
  Header,
  TableContainer,
  TableFooter,
  TableHeader,
  ValuesData,
} from './styles';
import { BudgetItem } from '../../../contexts/budgets/types';
import productsApi from '../../../services/products';
import { useAuth } from '../../../contexts/auth';
import { useParams } from 'react-router-dom';
import { AxiosError } from 'axios';
import { formatQuantity } from '../../../utils/formatQuantity';

type EditProfitabilityModalProps = {
  onCancel: () => void;
  isOpen: boolean;
} & Props;

type Composition = BudgetItem & {
  error: string;
};

export const EditCustomProfitabilityModal: React.FC<
  EditProfitabilityModalProps
> = (props) => {
  const { onCancel, isOpen, ...rest } = props;

  const { budget, profitability } = useBudgets();

  const { selectedFranchise: franchiseId } = useAuth();

  const { id } = useParams<{ id: string }>();

  const [compositions, setCompositions] = useState<Composition[]>([]);

  const handleCloseModal = () => {
    const hasErrors = compositions.some((composition) => composition.error);

    if (hasErrors) {
      toast.info('Corrija os erros antes de prosseguir');

      return;
    }

    onCancel();
  };

  const updateCompositionProfitability = async (
    compositionId: string,
    value: number
  ) => {
    const updatedCompositions = compositions.map((composition) =>
      composition.id === compositionId
        ? {
            ...composition,
            idealProfitability: value,
            error:
              value < (profitability?.minimun ?? 0)
                ? `Deve ser maior que lucro mínimo (${profitability?.minimun}%)`
                : '',
          }
        : composition
    );

    setCompositions(updatedCompositions);

    try {
      const { data } = await productsApi.put<BudgetItem[]>(
        `/budgets/budget-item-value/multiple`,
        {
          items: [
            {
              budgetItemId: compositionId,
              quantity: 1,
              idealProfitability: value,
            },
          ],
          budgetId: id,
          franchiseId,
        }
      );

      const updatedComposition = data.find((item) => item.id === compositionId);

      if (updatedComposition) {
        setCompositions((state) =>
          state.map((composition) =>
            composition.id === updatedComposition.id
              ? { ...composition, ...updatedComposition }
              : composition
          )
        );
      }
    } catch (error) {
      const errorMessage = (error as AxiosError)?.response?.data?.message;

      if (errorMessage.includes('Ideal profitability needs to be higher')) {
        return;
      }

      toast.error(errorMessage);
    }
  };

  const handleCompositions = () => {
    const compositions =
      budget?.items.map((item) => ({ ...item, error: '' })) ?? [];

    setCompositions(compositions);
  };

  return (
    <ShapeModal
      isOpen={isOpen}
      onRequestClose={handleCloseModal}
      onAfterClose={() => {
        document.documentElement.style.overflow = 'auto';
      }}
      onAfterOpen={() => {
        document.documentElement.style.overflow = 'hidden';

        handleCompositions();
      }}
      maxWidth="85.5rem"
      {...rest}
    >
      <Container>
        <Header>
          <h1>Edição de valores</h1>
          <p>Edite as margens percentuais dos valores de instalação, por kit</p>
        </Header>

        <Content>
          <TableContainer>
            <TableHeader>
              <h1>Kits Personalizados</h1>
            </TableHeader>
            <div>
              <table>
                <thead>
                  <tr>
                    <th>Composição</th>
                    <th>Tipo</th>
                    <th>Marca</th>
                    <th>Kits</th>
                    <th>Margem</th>
                    <th>Valores</th>
                  </tr>
                </thead>
                <tbody>
                  {compositions.map((composition, i) => {
                    const { customItem } = composition;

                    const itemProfitability = compositions.find(
                      (item) => item.id === composition.id
                    );

                    const quantity =
                      customItem?.kits.reduce(
                        (acc, kit) => acc + kit.quantity,
                        0
                      ) ?? 0;

                    return (
                      <tr key={customItem?.id}>
                        <td>
                          <strong>{i + 1}</strong>
                        </td>
                        <td>
                          <strong>{customItem?.type}</strong>
                        </td>
                        <td>
                          <strong>{customItem?.supplier}</strong>
                        </td>
                        <td>
                          <strong>
                            {formatQuantity(quantity, 'Kit', 'Kits')}
                          </strong>
                        </td>
                        <td>
                          <PercentageInput
                            step="0.01"
                            name={itemProfitability?.id}
                            defaultValue={itemProfitability?.idealProfitability}
                            onChange={(e) => {
                              const value = Number(e.target.value);

                              updateCompositionProfitability(
                                composition.id,
                                value
                              );
                            }}
                            error={itemProfitability?.error}
                          />
                        </td>
                        <td>
                          <ValuesData>
                            <span>Kit: {composition.formatted.price}</span>
                            <span>
                              Instalação: {composition.formatted.income}
                            </span>
                            <span className="hr" />
                            <strong>
                              Total: {composition.formatted.total}
                            </strong>
                          </ValuesData>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
                <TableFooter />
              </table>
            </div>
          </TableContainer>
        </Content>

        <Buttons>
          <Button
            type="button"
            text="Concluir"
            typeStyle="default"
            backgroundHoverColor="#C9CBCF"
            onClick={handleCloseModal}
          />
        </Buttons>
      </Container>
    </ShapeModal>
  );
};
