import { debounce } from 'lodash';
import React, { createContext, useContext, useState } from 'react';
import { toast } from 'react-toastify';
import { v4 as uuid } from 'uuid';
import { Option } from '../../components/modal/sales-funnel/components/tags/tag-select';
import { Steps } from '../../pages/sales-funnel/components/Kanban/TagsModal';
import crmApi from '../../services/crm';
import productsApi from '../../services/products';
import { UserRoles } from '../../types/users';
import { useAuth } from '../auth';
import { Budget, BudgetItem } from '../budgets/types';
import * as Types from './types';

export const SalesFunnelContext = createContext(
  {} as Types.SalesFunnelContextData
);

export const SalesFunnelProvider: React.FC<Types.SalesFunnelProvider> = (
  props
) => {
  const { children } = props;

  const { user, selectedFranchise: franchiseId } = useAuth();

  const [loading, setLoading] = useState<Types.Loading>({
    leadBudgetItems: false,
    leads: false,
  });

  const generateLeadsPlaceholder = (status: Types.LeadStatus) => {
    return Array(10).fill({
      id: uuid(),
      createdAt: new Date(),
      updatedAt: new Date(),
      schedule: {
        scheduledTo: new Date(),
      },
      status: {
        id: uuid(),
        status,
      },
    });
  };

  const [kanban, setKanban] = useState<Types.KanbanData[]>([
    {
      id: 'captacao',
      label: 'Captação',
      color: '#F95300',
      leads: generateLeadsPlaceholder('captacao'),
      totalItems: '0 leads',
    },
    {
      id: 'orcamento',
      label: 'Orçamento',
      color: '#0090A3',
      totalItems: '0 leads',
      leads: generateLeadsPlaceholder('orcamento'),
    },
    {
      id: 'negociacao',
      label: 'Negociação',
      color: '#005AF9',
      totalItems: '0 leads',
      leads: generateLeadsPlaceholder('negociacao'),
    },
    {
      id: 'propostaFinal',
      label: 'Proposta final',
      color: '#005AF9',
      totalItems: '0 leads',
      leads: generateLeadsPlaceholder('propostaFinal'),
    },
    {
      id: 'fechado',
      label: 'Fechado',
      color: '#2EDB09',
      totalItems: '0 leads',
      leads: generateLeadsPlaceholder('fechado'),
    },
    {
      id: 'perdido',
      label: 'Perdido',
      color: '#E01919',
      totalItems: '0 leads',
      leads: generateLeadsPlaceholder('perdido'),
    },
    {
      id: 'recuperacao',
      label: 'Recuperação',
      color: '#0090A3',
      totalItems: '0 leads',
      leads: generateLeadsPlaceholder('recuperacao'),
    },
    {
      id: 'agendado',
      label: 'Agendado',
      color: '#FF9763',
      leads: generateLeadsPlaceholder('agendado'),
      totalItems: '0 leads',
    },
  ]);

  const [crmData, setCrmData] = useState<Types.CRMData | null>(null);

  const [leadBudgetItems, setLeadBudgetItems] = useState<BudgetItem[] | null>(
    null
  );

  const [tags, setTags] = useState<Types.Tag[]>([]);

  const [tagsSelected, setTagsSelected] = useState<Option[]>([]);

  const [leadModal, setLeadModal] = useState<Types.LeadModal>({
    isOpen: false,
    data: null,
  });

  const [filters, setFilter] = useState<Types.Filter>({
    name: '',
    tags: [],
    userId: '',
    date: {
      startDate: '',
      endDate: '',
    },
  });

  const [tagModal, setTagModal] = useState<Types.TagModal>({
    isOpen: false,
    data: null,
    step: 'DEFAULT_STEP',
  });

  const handleTagModal = (
    step: Steps,
    data: Types.Tag | null,
    isOpen = true
  ) => {
    setTagModal(() => {
      return {
        step,
        isOpen,
        data,
      };
    });
  };

  const handleFilters = debounce(
    (name: string, tags: string[], userId: string, date: Types.Date) => {
      setFilter({
        name,
        tags,
        userId,
        date,
      });
    },
    350
  );

  const handleLeadModal = (data: Types.Lead | null = null) =>
    setLeadModal((state) => ({ isOpen: !state.isOpen, data }));

  const handleSelectedTag = async (selectedTag: Option, leadId: string) => {
    const existsCurrentTag = tagsSelected.find(
      (tag) => tag.franchiseTagId === selectedTag.id
    );

    if (existsCurrentTag) {
      handleUnselectTag(existsCurrentTag.id);
      return;
    }

    try {
      const { data } = await crmApi.post(`/leads/${leadId}/tag`, {
        franchiseTagId: selectedTag.id,
      });

      const newTag = {
        ...selectedTag,
        id: data.id,
        franchiseTagId: data.franchiseTagId,
      };

      await getLeads();

      setTagsSelected((state) => [...state, newTag]);
    } catch (error) {
      toast.error('Desculpe, não foi possível selecionar a etiqueta');
    }
  };

  const handleUnselectTag = async (tagId: string) => {
    try {
      await crmApi.delete(`/leads/${tagId}/tag`);

      await getLeads();

      setTagsSelected((state) => state.filter((tag) => tag.id !== tagId));
    } catch (error) {
      toast.error('Desculpe, não foi possível remover a etiqueta');
    }
  };

  const handleDefaultSelectedTags = (lead: Types.Lead) => {
    const normalizedTags = lead.tags.map((tag) => ({
      id: tag.id,
      franchiseTagId: tag.franchiseTagId,
      label: tag.franchiseTag.name,
      value: tag.franchiseTag.color,
    }));

    setTagsSelected(normalizedTags);
  };

  const getTags = async () => {
    const { data } = await crmApi.get<Types.Tag[]>(
      `/franchise-tag/${franchiseId}`
    );

    setTags(data);
  };

  const addTag = async (tag: Partial<Types.Tag>) => {
    try {
      const { data } = await crmApi.post<Types.Tag>('/franchise-tag', {
        name: tag.name || null,
        color: tag.color,
        franchiseId,
      });

      await getLeads();

      setTags((state) => [...state, data]);
    } catch (error) {
      toast.error('Desculpe, não foi possível criar a etiqueta');

      throw error;
    }
  };

  const deleteTag = async (tagId: string) => {
    try {
      await crmApi.delete(`/franchise-tag/${tagId}`);

      await getLeads();

      setTags((state) => state.filter((tag) => tag.id !== tagId));
    } catch (error) {
      toast.error('Desculpe, não foi possível remover a etiqueta');

      throw error;
    }
  };

  const editTag = async (tag: Omit<Types.Tag, 'franchiseId'>) => {
    try {
      const { data } = await crmApi.put<Types.Tag>(`/franchise-tag/${tag.id}`, {
        name: tag.name || null,
        color: tag.color,
        franchiseId,
      });

      await getLeads();

      setTags((state) =>
        state.map((_tag) => {
          return _tag.id === tag.id ? data : _tag;
        })
      );
    } catch (error) {
      toast.error('Desculpe, não foi possível editar a etiqueta');

      throw error;
    }
  };

  const getLeads = async () => {
    setLoading((state) => ({ ...state, leads: true }));

    const isAdminOrFranchised = [
      UserRoles.FRANQUEADO,
      UserRoles.ADMIN,
    ].includes(user.role);

    try {
      const { data } = await crmApi.get<Types.Leads>('/leads/categorized', {
        params: {
          franchiseId: franchiseId || undefined,
          userId: isAdminOrFranchised ? filters.userId || undefined : user.id,
          name: filters.name || undefined,
          tags: filters.tags.length > 0 ? filters.tags.join(',') : undefined,
          startDate: filters.date.startDate || undefined,
          endDate:
            filters.date.endDate !== filters.date.startDate
              ? filters.date.endDate
              : undefined,
        },
      });

      setKanban((state) =>
        state.map((column) => {
          const columnId = column.id as Types.LeadStatus;

          if (data[columnId]) {
            const totalItems = data[columnId].length;

            return {
              ...column,
              leads: data[columnId].map((lead) => ({
                ...lead,
              })),
              totalItems:
                totalItems === 1 ? totalItems + ' lead' : totalItems + ' leads',
            };
          }

          return column;
        })
      );
    } catch (error) {
      toast.error('Desculpe, não foi possível buscar os leads', {
        toastId: '1',
      });

      setKanban([
        {
          id: 'captacao',
          label: 'Captação',
          color: '#F95300',
          leads: [],
          totalItems: '0 leads',
        },
        {
          id: 'orcamento',
          label: 'Orçamento',
          color: '#0090A3',
          totalItems: '0 leads',
          leads: [],
        },
        {
          id: 'negociacao',
          label: 'Negociação',
          color: '#005AF9',
          totalItems: '0 leads',
          leads: [],
        },
        {
          id: 'propostaFinal',
          label: 'Proposta final',
          color: '#005AF9',
          totalItems: '0 leads',
          leads: [],
        },
        {
          id: 'fechado',
          label: 'Fechado',
          color: '#2EDB09',
          totalItems: '0 leads',
          leads: [],
        },
        {
          id: 'perdido',
          label: 'Perdido',
          color: '#E01919',
          totalItems: '0 leads',
          leads: [],
        },
        {
          id: 'recuperacao',
          label: 'Recuperação',
          color: '#0090A3',
          totalItems: '0 leads',
          leads: [],
        },
        {
          id: 'agendado',
          label: 'Agendado',
          color: '#FF9763',
          leads: [],
          totalItems: '0 leads',
        },
      ]);

      throw error;
    } finally {
      setLoading((state) => ({ ...state, leads: false }));
    }
  };

  const getLeadBudgetItems = async (budgetId: string) => {
    setLoading((state) => ({ ...state, leadBudgetItems: true }));

    try {
      const { data } = await productsApi.get<Budget>(`/budgets/${budgetId}`);
      setLeadBudgetItems(data.items);
    } catch (error) {
      console.error(error);

      throw error;
    } finally {
      setLoading((state) => ({ ...state, leadBudgetItems: false }));
    }
  };

  const clearLeadBudgetItems = () => setLeadBudgetItems(null);

  const updateLeadStatus = async (leadId: string, status: Types.LeadStatus) => {
    try {
      await crmApi.put('/leads/' + leadId + '/update-status', {
        status,
      });
    } catch (error) {
      console.error(error);

      throw error;
    }
  };

  const updateLead = async (leadId: string, updatedLead: Types.UpdatedLead) => {
    try {
      await crmApi.put('/leads/' + leadId, updatedLead);
    } catch (error) {
      console.error(error);

      throw error;
    }
  };

  const updateLeadByBudgetId = async ({
    budgetId,
    ...data
  }: Types.UpdateLeadByBudgetIdInput) => {
    try {
      await crmApi.put('/leads/by-budget-id/' + budgetId, data);
    } catch (error) {
      console.error(error);

      throw error;
    }
  };

  const updateToLost = async (leadId: string, data: Types.LostData) => {
    try {
      await crmApi.post('/leads/update-lost/' + leadId, data);
    } catch (error) {
      console.error(error);

      throw error;
    }
  };

  const handleCRMData = (data: Types.Lead | null) => {
    if (!data) {
      setCrmData(null);

      return;
    }

    const documentType = data.customer.document?.length === 14 ? 'cpf' : 'cnpj';

    const crmData = {
      leadId: data.id,
      name: data.customer.name,
      email: data.customer.email ?? '',
      phone: data.customer.phone ?? '',
      documentType,
      cpf: documentType === 'cpf' ? data.customer.document ?? '' : '',
      cnpj: documentType === 'cnpj' ? data.customer.document ?? '' : '',
      zipCode: data.customer.zipcode ?? '',
      street: data.customer.address ?? '',
      state: data.customer.state ?? '',
      city: data.customer.city ?? '',
      neighborhood: data.customer.district ?? '',
      complement: data.customer.addressComplement ?? '',
      number: String(data.customer.addressNumber ?? ''),
      status: data.status.status,
    };

    setCrmData(crmData);
  };

  return (
    <SalesFunnelContext.Provider
      value={{
        kanban,
        crmData,
        leadBudgetItems,
        loading,
        tags,
        tagsSelected,
        leadModal,
        filters,
        tagModal,
        getLeads,
        getLeadBudgetItems,
        clearLeadBudgetItems,
        handleCRMData,
        updateLeadStatus,
        updateLead,
        updateToLost,
        updateLeadByBudgetId,
        addTag,
        getTags,
        deleteTag,
        editTag,
        handleDefaultSelectedTags,
        handleSelectedTag,
        handleUnselectTag,
        handleLeadModal,
        handleFilters,
        handleTagModal,
      }}
    >
      {children}
    </SalesFunnelContext.Provider>
  );
};

export const useSalesFunnel = () => useContext(SalesFunnelContext);
