import React, { CSSProperties, ForwardedRef, ReactNode, useState } from 'react';
import { Container, Wrapper } from './styles';

import CurrencyInput, { CurrencyInputProps } from 'react-currency-input-field';

interface InputPriceProps extends CurrencyInputProps {
  label: string;
  name?: string;
  type?: string;
  disabled?: boolean;
  required?: boolean;
  iconLeft?: ReactNode;
  iconRight?: ReactNode;
  style?: CSSProperties;
  defaultValue?: number | string;
  height?: string;
  error?: string;
  message?: ReactNode;
  onValueChange?: (value: string | undefined) => void;
}

const InputPrice = React.forwardRef(
  (props: InputPriceProps, myRef: ForwardedRef<HTMLInputElement>) => {
    const {
      label,
      height = '3rem',
      error,
      message,
      placeholder,
      defaultValue,
      maxLength,
      style,
      disabled = false,
      onValueChange,
      ...rest
    } = props;
    const [active, setActive] = useState(false);

    return (
      <Container
        height={height}
        active={active}
        error={!!error}
        style={style}
        disabled={disabled}
        onFocus={() => setActive(true)}
        onBlur={() => {
          setActive(false);
        }}
        htmlFor={rest.name}
        className={rest.className}
      >
        {label && (rest.required ? label + '*' : label)}
        <div>
          <CurrencyInput
            {...rest}
            type="tel"
            ref={myRef}
            name={rest.name}
            disabled={disabled}
            id={rest.name}
            intlConfig={{ locale: 'pt-BR', currency: 'BRL' }}
            decimalSeparator=","
            decimalScale={2}
            placeholder={placeholder}
            prefix="R$"
            defaultValue={defaultValue}
            maxLength={maxLength}
            spellCheck={false}
            onValueChange={(value) => onValueChange && onValueChange(value)}
          />
        </div>
        <Wrapper>
          {error && <span>{error}</span>}
          {message && <span className="message">{message}</span>}
        </Wrapper>
      </Container>
    );
  }
);

InputPrice.displayName = 'InputPrice';

export default InputPrice;
