import React, { useEffect, useState } from 'react';
import { FiLayout } from 'react-icons/fi';
import { Breadcrumb } from '../../components';
import Loading from '../../components/loading';
import { useDashboard } from '../../contexts/dashboard';
import { Container, PageHeader, Wrapper } from '../styles';
import { Rankings } from './components/Rankings';
import { Sales } from './components/Sales';
import { ReportContainer, Tab, Tabs, TabsContainer } from './styles';
import { useAuth } from '../../contexts/auth';

type Link = {
  id: number;
  title: string;
  link: string;
  active: boolean;
};

const DashboardPage: React.FC = () => {
  const [activeTab, setActiveTab] = useState('Vendas');
  const [links, setLinks] = useState<Link[]>([]);

  const { selectedFranchise } = useAuth();
  const { loading, franchiseData, getFranchiseData } = useDashboard();

  const handleActiveTab = (tab: string) => {
    setActiveTab(tab);
  };

  useEffect(() => {
    setLinks([
      {
        id: 1,
        title: 'Dashboard',
        link: '/dashboard',
        active: false,
      },
      { id: 2, title: activeTab, link: '/dashboard', active: true },
    ]);
  }, [activeTab]);

  useEffect(() => {
    getFranchiseData();
  }, [selectedFranchise]);

  if (loading) {
    return (
      <Loading
        style={{ alignItems: 'center', height: '100%' }}
        label="Buscando os dados"
      />
    );
  }
  return (
    <Container>
      <Breadcrumb links={links} />
      <Wrapper>
        <PageHeader>
          <span>Meu painel</span>

          <TabsContainer>
            <Tabs>
              <Tab
                onClick={() => handleActiveTab('Vendas')}
                isActive={activeTab === 'Vendas'}
              >
                <FiLayout />
                Vendas
              </Tab>
              <Tab
                onClick={() => handleActiveTab('Rankings')}
                isActive={activeTab === 'Rankings'}
              >
                <FiLayout />
                Rankings
              </Tab>
            </Tabs>
          </TabsContainer>
        </PageHeader>
        <ReportContainer>
          {franchiseData && (
            <>
              {[activeTab].includes('Vendas') && <Sales />}
              {[activeTab].includes('Rankings') && <Rankings />}
            </>
          )}
        </ReportContainer>
      </Wrapper>
    </Container>
  );
};

export default DashboardPage;
