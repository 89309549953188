import React, { ReactNode } from 'react';
import ReactModal, { Props } from 'react-modal';
import { ModalContainer } from './styles';

export interface ShapeModalProps extends Props {
  isOpen: boolean;
  children: ReactNode;
  maxWidth?: string;
  padding?: string;
}

export const ShapeModal: React.FC<ShapeModalProps> = (props) => {
  const { isOpen, children, maxWidth = '56.5rem', padding, ...rest } = props;

  const customStyle = {
    content: {
      borderRadius: '16px',
      maxWidth,
      width: '100%',
      height: 'fit-content',
      padding: padding || '1.5rem',
      inset: '50%',
      transform: 'translate(-50%, -50%)',
      overflow: 'auto',
    },
    overlay: {
      backgroundColor: 'rgba(19, 20, 21, 0.1)',
      zIndex: 9999,
    },
  };

  return (
    <ReactModal isOpen={isOpen} style={customStyle} shouldCloseOnEsc {...rest}>
      <ModalContainer>{children}</ModalContainer>
    </ReactModal>
  );
};
