import styled from 'styled-components';

export const FormContainer = styled.form`
  & > .button-wrapper {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;

    margin-bottom: 1.5rem;

    & > p {
      font-family: 'Montserrat';

      font-size: 1.125rem;

      letter-spacing: 0.02em;

      color: #27282b;
    }

    & > button > svg > path {
      stroke: #ffffff;
    }

    @media (max-width: 992px) {
      & > button {
        width: 100% !important;
      }
    }
  }
`;

export const Divisor = styled.hr`
  margin: 2rem 0;

  border: 1px solid #dfe0e2;
`;

export const FormContent = styled.main`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  & > h1 {
    font-family: 'DunbarTextRegular', sans-serif;
    font-weight: 400;
    font-size: 1.25rem;

    letter-spacing: 0.02em;

    color: #27282b;
  }

  & > .form-content__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    gap: 1.5rem;
    flex: 1;

    @media (max-width: 1460px) {
      flex-direction: column;
      align-items: flex-start;
    }

    & > .form-content__inputs {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      gap: 1.2rem;
      flex: 1;

      @media (max-width: 992px) {
        & > label {
          width: 100% !important;
        }
      }
    }

    & > .form-content__price {
      display: flex;
      flex-direction: column;
      align-items: center;

      & > span {
        font-family: 'DunbarTextRegular', sans-serif;
        font-weight: 400;
        font-size: 1rem;

        letter-spacing: 0.02em;

        color: #27282b;
      }

      & > strong {
        font-family: 'DunbarTallBold', sans-serif;
        font-size: 2rem;

        letter-spacing: 0.03em;

        color: #27282b;
      }

      @media (max-width: 992px) {
        width: 100%;
      }
    }
  }
`;
