import styled from 'styled-components';

export const Content = styled.form`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  gap: 3rem;

  margin-top: 1rem;
`;

export const Wrapper = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 1rem;

  & > h2 {
    font-weight: 600;
    font-size: 1.125rem;

    color: #292929;
  }

  & > span {
    font-family: 'DunbarTallRegular';
    font-weight: 700;
    font-size: 1.5rem;
    letter-spacing: 0.03em;
    color: #27282b;
  }

  & span.checkbox-label {
    font-family: 'DunbarTextRegular';
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5rem;
    letter-spacing: 0.02em;
    color: #27282b;
  }
`;

export const SelectContainer = styled.div`
  width: calc(25% - 1rem);

  @media (max-width: 992px) {
    width: calc(50% - 1rem);
  }

  @media (max-width: 520px) {
    width: 100%;
  }
`;

export const InputGroup = styled.div`
  display: flex;
  flex-wrap: wrap;

  gap: 1.5rem;

  & > div {
    width: calc(25% - 1.125rem);
  }

  @media (max-width: 992px) {
    & > div {
      width: calc(50% - 1.125rem);
    }
  }

  @media (max-width: 520px) {
    & > div {
      width: 100%;
    }
  }
`;

export const PaymentMethodsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 1.5rem;

  margin-top: 1rem;

  @media (max-width: 992px) {
    justify-content: center;
    gap: 2rem;
  }
`;

export const PaymentMethods = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(16.125rem, 1fr));
  column-gap: 1.5rem;
  row-gap: 2rem;

  flex: 1;
`;

