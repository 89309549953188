import { ProjectType } from '../../types/project';

export type ProjectListType = {
  content: ProjectType[];
  pagination: {
    lastPage: number;
    currentPage: number;
    total: number;
    limit: number;
  };
};

export type ProjectFilesType = {
  id: string;
  mimetype: string;
  name: string;
  originalName: string;
  path: string;
  projectId: string;
  size: number;
  type: string;
  file_url: string;
};

export type InspectionType = {
  status: string;
  description: string;
};

export type FeedbackType = {
  status: string;
  description: string;
};

export type InspectionRequestType = {
  inspectionRequestObs: string;
};

export type ImpedimentType = {
  reason: string;
  description: string;
};

export type ProjectStatusLogType = {
  id: string;
  observation: string;
  status: string;
  user: {
    id: string;
    name: string;
  };
};

export type PowerCompanyFeedbackType = {
  id: string;
  observation: string;
  status: string;
  user: {
    id: string;
    name: string;
  };
  createdAt: Date;
};

export type ProjectInspectionType = {
  id: string;
  observation: string;
  status: string;
  user: {
    id: string;
    name: string;
  };
};

export type ProjectDocumentationAnalysisType = {
  id: string;
  observation: string;
  status: string;
  user: {
    id: string;
    name: string;
  };
};

export const UploadTypes = {
  IMPORT_PRODUTOS: 'importacao-produtos',
  CONTA_ENEGIA: 'conta-energia',
  DOCUMENTOS_EXTRAS: 'documentos-extras',
  TERMO_ACEITA_CLIENTE: 'termo-aceite-cliente',
  DOCUMENTO_TITULAR: 'documento-titular',
  PADRAO_ENTRADA_QUADRO: 'padrao-entrada-quadro',
  INTERIOR_QUADRO_MEDICAO: 'interior-quadro-medicao',
  DISJUNTOR_GERAL_PADRAO_ENTRADA: 'disjuntor-geral-padrao-entrada',
  ESPECIFICACAO_KIT_VENDIDO_MARCA: 'especificacoes-kit-vendido-marca',
  PROCURACAO_RECONHECIMENTO_ASSINATURA_CARTORIO:
    'procuracao-reconhecimento-assinatura-cartorio',
  NUMERO_POSTE_TRANSFORMADOR_PROXIMO: 'numero-poste-transformador-proximo',
  ARQUIVOS_PROJETO: 'arquivos-projeto',
  VISTORIA_PADRAO_ENTRADA: 'vistoria-padrao-entrada',
  VISTORIA_FOTOS_DADOS_INVERSORES: 'vistoria-fotos-dados-inversores',
  VISTORIA_INSTALACAO_INVERSORES: 'vistoria-instalacao-inversores',
  VISTORIA_INSTALACAO_MODULOS: 'vistoria-instalacao-modulos',
  VISTORIA_ATERRAMENT: 'vistoria-aterramento',
  VISTORIA_DOCUMENT_RESPONSABILIDADE_TECNICA:
    'vistoria-Documento-responsabilidade-tecnica',
  VISTORIA_RELATORIO_COMISSIONAMENTO: 'vistoria-relatorio-comisionamento',
  PARECER_CONCESSIONARIA: 'parecer-concessionaria',
};

export const ProjectStatus = {
  PROJETO_RACUNHO: 'Rascunho',
  DOCUMENTACAO_EM_ANALISE: 'Documentação em análise',
  DOCUMENTACAO_APROVADA: 'Documentação aprovada',
  DOCUMENTACAO_INCOMPLETA: 'Documentação incompleta',
  AGUARDANDO_PAGAMENTO: 'Aguardando Pagamento',
  PROJETO_EM_ELABORACAO: 'Projeto em elaboração',
  PROJETO_ENVIADO: 'Projeto enviado',
  PROJETO_AGUARDANDO_CONCESSIONARIO: 'Aguardando validação da concessionária',
  PROJETO_APROVADO: 'Projeto aprovado pela concessionária',
  PROJETO_REPROVADO: 'Projeto reprovado pela concessionária',
  AGUARDANDO_ANALISE_VISTORIA: 'Aguardando análise de vistoria',
  VISTORIA_SOLICITADA: 'Vistoria solicitada',
  DOCUMENTACAO_REPROVADA: 'Documentacão reprovada',
  VISTORIA_APROVADA: 'Vistoria aprovada',
  VISTORIA_REPROVADA: 'Vistoria reprovada',
  PROJETO_CONCLUIDO: 'Projeto concluído',
};

export const ProjectPaymentType = {
  DEFAULT: 'default-payment',
  PIX: 'PIX',
  FREE_OF_CHARGE: 'Free of charge',
};

export type ZipCodeResponseType = {
  id: number;
  address: string;
  city: {
    id: number;
    name: string;
    stateId: number;
  };
  cityId: number;
  district: string;
  state: {
    id: number;
    name: string;
    uf: string;
  };
  stateId: number;
  zipcode: string;
};
