import React, { ReactNode } from 'react';
import { Container } from './styles';

type InnerActionsProps = {
  children: ReactNode;
};

const InnerActions: React.FC<InnerActionsProps> = (props) => {
  const { children } = props;
  return <Container>{children}</Container>;
};

export default InnerActions;
