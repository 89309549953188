import React from 'react';

import { Container, Header, Table, Wrapper } from './styles';

import { ShapeModal } from '../..';
import { Buttons } from '../../styles';

import { Button } from '../../../button';

type Props = {
  isOpen: boolean;
  onCancel: () => void;
  onConfirm: () => void;
};

export const ConfirmKitConfigurationModal: React.FC<Props> = (props) => {
  const { isOpen, onCancel, onConfirm } = props;

  return (
    <ShapeModal isOpen={isOpen}>
      <Container>
        <Header>
          <h1>Kit Personalizado</h1>
          <p>
            Seu Kit Personalizado possui os produtos abaixo, deseja confirmar
            essa configuração?
          </p>
        </Header>

        <Wrapper>
          <Table>
            <thead>
              <tr>
                <th>
                  <strong>Produto</strong>
                </th>
                <th>
                  <strong>Quantidade</strong>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <div>
                    <strong>Placa Solar</strong>
                    <p>
                      Fornecedor: Elgin - Potência 400W - Estrutura:
                      Fibrocimento
                    </p>
                  </div>
                </td>
                <td>
                  <strong>168</strong>
                </td>
              </tr>
              <tr>
                <td>
                  <div>
                    <strong>Inversor LV</strong>
                    <p>Fornecedor: Elgin - Potência: 50KW/380V</p>
                  </div>
                </td>
                <td>
                  <strong>2</strong>
                </td>
              </tr>
              <tr>
                <td>
                  <div>
                    <strong>Conectores</strong>
                    <p>Caixa com 4 pares de conectores MC4</p>
                  </div>
                </td>
                <td>
                  <strong>2</strong>
                </td>
              </tr>
              <tr>
                <td>
                  <div>
                    <strong>Cabos</strong>
                    <p>Rolo de 30 metros de Cabo Vermelho 4mm².</p>
                  </div>
                </td>
                <td>
                  <strong>2</strong>
                </td>
              </tr>
              <tr>
                <td>
                  <div>
                    <strong>Acessório</strong>
                    <p>Descrição do acessório</p>
                  </div>
                </td>
                <td>
                  <strong>1</strong>
                </td>
              </tr>
              <tr>
                <td>
                  <div>
                    <strong>Proteção</strong>
                    <p>String Box 2 Entradas / 2 Saídas 1000Vcc.</p>

                    <span>Quantidade inferior ao recomendado</span>
                  </div>
                </td>
                <td>
                  <strong>1</strong>
                </td>
              </tr>
              <tr>
                <td>
                  <div>
                    <strong>Suportes</strong>
                    <p>Emenda de Perfil - Cx 2 peças - NT.</p>
                  </div>
                </td>
                <td>
                  <strong>2</strong>
                </td>
              </tr>
            </tbody>
          </Table>
        </Wrapper>

        <Buttons>
          <Button
            type="button"
            text="Voltar"
            typeStyle="default"
            backgroundHoverColor="#C9CBCF"
            onClick={onCancel}
          />
          <Button
            text="Avançar"
            backgroundColor="#ED5A5A"
            backgroundHoverColor="#ed5a5abc"
            onClick={onConfirm}
          />
        </Buttons>
      </Container>
    </ShapeModal>
  );
};
