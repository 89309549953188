import React, { useEffect, useState } from 'react';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Loading from '../../../../components/loading';
import { useAuth } from '../../../../contexts/auth';
import { Order } from '../../../../contexts/orders/types';
import productsApi from '../../../../services/products';
import { UserRoles } from '../../../../types/users';
import { OrderCanceled } from './canceled';
import { ConsultantBoletoNff } from './consultant/boleto-nff';
import { ConsultantIncomplete } from './consultant/incomplete';
import { ConsultantLink } from './consultant/link';
import { ConsultantNfeEmitted } from './consultant/nfe-emitted';
import { ConsultantPaymentEffected } from './consultant/payment-effected';
import { ConsultantProcessing } from './consultant/processing';
import { ConsultantSentToCp } from './consultant/sent-to-cp';
import { ConsultantSentToSupplier } from './consultant/sent-to-supplier';
import { ConsultantWaitingNfe } from './consultant/waiting-nfe';
import { FranchiseIncomplete } from './franchise/incomplete';
import { FranchiseNfeEmitted } from './franchise/nfe-emitted';
import { FranchiseProcessed } from './franchise/processed';
import { FranchiseProcessing } from './franchise/processing';
import { FranchiseWaitingNfe } from './franchise/waiting-nfe';
import { FranchiseWaitingPayment } from './franchise/waiting-payment';

type OrdersSubmittedProps = {};

type Status = {
  'a-processar': JSX.Element;
  'pedido-incompleto': JSX.Element;
  'pedido-processado': JSX.Element;
  'enviado-para-cp': JSX.Element;
  'enviado-para-fornecedor': JSX.Element;
  'link-do-cartao': JSX.Element;
  'aguardando-pagamento': JSX.Element;
  'pedido-cancelado': JSX.Element;
  'aguardando-nfe': JSX.Element;
  'nfe-emitida': JSX.Element;
  'boleto-nff': JSX.Element;
  'pagamento-efetuado': JSX.Element;
};

export const OrdersSubmitted: React.FC<OrdersSubmittedProps> = () => {
  const { orderId, status } = useParams<{
    orderId: string;
    status: string;
  }>();

  const [order, setOrder] = useState({} as Order);

  const [loading, setLoading] = useState(true);

  const { user } = useAuth();

  const history = useHistory();

  const pages = {
    [UserRoles.FRANQUEADO]: {
      'a-processar': <FranchiseProcessing order={order} />,
      'pedido-incompleto': <FranchiseIncomplete order={order} />,
      'pedido-processado': <FranchiseProcessed />,
      'aguardando-pagamento': <FranchiseWaitingPayment order={order} />,
      'pedido-cancelado': <OrderCanceled />,
      'aguardando-nfe': <FranchiseWaitingNfe />,
      'nfe-emitida': <FranchiseNfeEmitted order={order} />,
    },
    [UserRoles.ADMIN]: {
      'a-processar': <ConsultantProcessing order={order} />,
      'pedido-incompleto': <ConsultantIncomplete order={order} />,
      'enviado-para-cp': <ConsultantSentToCp order={order} />,
      'enviado-para-fornecedor': <ConsultantSentToSupplier order={order} />,
      'link-do-cartao': <ConsultantLink order={order} />,
      'pedido-cancelado': <OrderCanceled />,
      'aguardando-nfe': <ConsultantWaitingNfe order={order} />,
      'nfe-emitida': <ConsultantNfeEmitted order={order} />,
      'boleto-nff': <ConsultantBoletoNff order={order} />,
      'pagamento-efetuado': <ConsultantPaymentEffected order={order} />,
    },
    [UserRoles.CONSULTOR_SUCESSO]: {
      'a-processar': <ConsultantProcessing order={order} />,
      'pedido-incompleto': <ConsultantIncomplete order={order} />,
      'enviado-para-cp': <ConsultantSentToCp order={order} />,
      'enviado-para-fornecedor': <ConsultantSentToSupplier order={order} />,
      'link-do-cartao': <ConsultantLink order={order} />,
      'pedido-cancelado': <OrderCanceled />,
      'aguardando-nfe': <ConsultantWaitingNfe order={order} />,
      'nfe-emitida': <ConsultantNfeEmitted order={order} />,
      'boleto-nff': <ConsultantBoletoNff order={order} />,
      'pagamento-efetuado': <ConsultantPaymentEffected order={order} />,
    },
  };

  const getOrder = () => {
    productsApi
      .get(`/orders/${orderId}`)
      .then((response) => {
        setOrder(response.data);
      })
      .catch((error) => {
        const errorMessage = error.response.data.message;

        switch (errorMessage) {
          case 'order not found':
            toast.error('Pedido não encontrado');

            break;

          default:
            toast.error(errorMessage);

            break;
        }

        history.replace('/orcamentos/orcamentos-e-pedidos/');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getOrder();
  }, []);

  if (loading) {
    return <Loading style={{ alignItems: 'center', height: '100%' }} />;
  }

  return (
    pages[user.role][status as keyof Status] ?? (
      <Redirect to="/orcamentos/orcamentos-e-pedidos/" />
    )
  );
};
