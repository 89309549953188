import React, { useState } from 'react';
import SelectAsync from '../../../../../../components/select-async';
import { SectionContainer, Title } from '../../styles';
import {
  MapContainer,
  MapWrapper,
  Region,
  Regions,
  SelectWrapper,
} from './styles';
import { regions } from '../../mock';
import { Indicator } from '../../../Indicator';
import { IndicatorStatus } from '../../../Indicator/styles';
import { GoogleMap, useJsApiLoader, InfoBox } from '@react-google-maps/api';
import { InnerMark } from './InnerMark';
import { useDashboard } from '../../../../../../contexts/dashboard';
import {
  MapData,
  Region as RegionType,
  RegionData,
} from '../../../../../../contexts/dashboard/types';
import { numberFormat } from '../../../../../../utils/numberFormat';
import { toMoneyFormat } from '../../../../../../utils/toMoneyFormat';
import SelectInput, { Option } from '../../../../../../components/select';

const center = {
  lat: -28.471814,
  lng: -49.014857,
};

const options = {
  center: center,
  fullscreenControl: false,
  mapTypeControl: false,
  panControl: false,
  clickableIcons: false,
  scaleControl: false,
  streetViewControl: false,
};

export const Map: React.FC = () => {
  const { isLoaded, loadError } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: String(process.env.REACT_APP_GOOGLE_MAPS),
  });

  const { mapData } = useDashboard();

  const [selectedData, setSelectedData] = useState<{
    name: string;
    states: RegionData;
  } | null>(null);

  const selectOptions = Object.keys(mapData.regions).map((item) => ({
    label: item,
    value: item,
  }));

  const handleSelect = (option: Option) => {
    const sortable = Object.fromEntries(
      Object.entries(mapData[option.label as RegionType]).sort(
        ([, a], [, b]) => b.production - a.production
      )
    );

    setSelectedData({
      name: option.label,
      states: sortable,
    });
  };

  return (
    <SectionContainer>
      <Title>Mapas</Title>

      {/* <SelectAsync
        label="Franqueado"
        placeholder="Selecionar opção"
        style={{ maxWidth: '38.25rem', width: '100%' }}
        getOptions={() => new Promise((resolve) => resolve([]))}
      /> */}
      <SelectWrapper>
        <SelectInput
          label="Região"
          placeholder="Selecionar opção"
          options={selectOptions}
          onChange={handleSelect}
        />
        {/* <SelectAsync
          label="Estado"
          placeholder="Selecionar opção"
          getOptions={() => new Promise((resolve) => resolve([]))}
        />
        <SelectAsync
          label="Cidade"
          placeholder="Selecionar opção"
          getOptions={() => new Promise((resolve) => resolve([]))}
        />
        <SelectAsync
          label="Bairro"
          placeholder="Selecionar opção"
          getOptions={() => new Promise((resolve) => resolve([]))}
        /> */}
      </SelectWrapper>

      <MapContainer>
        <MapWrapper>
          {loadError && <p>{loadError.message}</p>}

          {isLoaded && (
            <GoogleMap
              mapContainerClassName="google-map"
              zoom={10}
              options={options}
            ></GoogleMap>
          )}
        </MapWrapper>

        {selectedData ? (
          <Region>
            <h1>{selectedData.name}</h1>

            <Regions>
              {Object.keys(selectedData.states).map((state, index) => {
                return (
                  <div key={state}>
                    <div>
                      <strong>{state}</strong>
                      <p className="region-price">
                        {toMoneyFormat(selectedData.states[state].production)}
                      </p>
                    </div>

                    <div>
                      <p className="region-percentage">
                        {numberFormat(selectedData.states[state].share)}%
                      </p>
                      <span className="region-share">share</span>
                    </div>

                    <div className="region-ranking">
                      <Indicator status="neutral" />
                      <strong>{index + 1}°</strong>
                    </div>
                  </div>
                );
              })}
            </Regions>
          </Region>
        ) : (
          <Region>
            <h1>Regiões</h1>

            <Regions>
              {Object.keys(mapData.regions).map((region, index) => {
                return (
                  <div key={region}>
                    <div>
                      <strong>{region}</strong>
                      <p className="region-price">
                        {toMoneyFormat(
                          mapData.regions[region as RegionType].production
                        )}
                      </p>
                    </div>

                    <div>
                      <p className="region-percentage">
                        {numberFormat(
                          mapData.regions[region as RegionType].share
                        )}
                        %
                      </p>
                      <span className="region-share">share</span>
                    </div>

                    <div className="region-ranking">
                      <Indicator status="neutral" />
                      <strong>{index + 1}°</strong>
                    </div>
                  </div>
                );
              })}
            </Regions>
          </Region>
        )}
      </MapContainer>
    </SectionContainer>
  );
};
