import styled from 'styled-components';

export const Background = styled.div`
  display: flex;
  justify-content: center;
  background-color: #00299b;

  height: 100vh;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 6rem;

  padding: 0 5%;

  max-width: 90rem;
  width: 100%;

  & > div {
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 4rem;

    width: 100%;
  }

  & > strong {
    font: 700 2.5rem 'DunbarTallBold';

    color: #fff;

    letter-spacing: 0.03em;

    text-align: center;
  }
`;

export const LeftContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 800px) {
    flex-direction: column;
  }
`;

export const HiddenLogo = styled.div`
  display: none;

  @media (max-width: 800px) {
    display: block;

    margin: 2rem 0;

    & > img {
      width: 100%;

      object-fit: cover;
    }
  }
`;

export const RightContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 800px) {
    display: none;
  }
`;
