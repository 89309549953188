import React, {
  CSSProperties,
  ForwardedRef,
  InputHTMLAttributes,
  useState,
} from 'react';
import { Container } from './styles';

interface PercentageInputProps extends InputHTMLAttributes<HTMLInputElement> {
  style?: CSSProperties;
  error?: string;
}

const PercentageInput = React.forwardRef(
  (props: PercentageInputProps, ref: ForwardedRef<HTMLInputElement>) => {
    const { style, error, ...rest } = props;

    const [isActive, setIsActive] = useState(false);

    return (
      <Container
        isActive={isActive}
        hasError={!!error}
        onFocus={() => setIsActive(true)}
        onBlur={() => setIsActive(false)}
        style={style}
      >
        <div>
          <input type="number" ref={ref} {...rest} />
          <span>%</span>
        </div>
        {error && <span className="error">{error}</span>}
      </Container>
    );
  }
);

PercentageInput.displayName = 'PercentageInput';

export default PercentageInput;
