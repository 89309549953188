import styled, { css } from 'styled-components';

export const Container = styled.main`
  display: flex;
  flex-direction: column;
  gap: 4rem;

  width: 100%;
`;

export const SectionContainer = styled.section`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  width: 100%;

  & > div.select-input {
    max-width: 22rem;

    @media (max-width: 520px) {
      max-width: unset;
    }
  }
`;

export const Title = styled.h1`
  font-family: 'DunbarTallBold';
  font-weight: 500;
  font-size: 1.5rem;

  letter-spacing: 0.03em;

  color: #000000;
`;

export const YourSales = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 2rem;

  background: #ffffff;

  border: 1px solid #c9cbcf;
  border-radius: 8px;

  padding: 1.5rem;

  & > h1 {
    font-weight: 500;
    font-size: 1rem;
    line-height: 100%;
    text-align: center;
    color: #61646b;
  }
`;

export const YourSalesWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  flex: 1;

  overflow-x: auto;
`;

export type YourSalesType = 'increased' | 'decreased';

type YourSalesCardProps = {
  type: YourSalesType;
};

export const YourSalesCard = styled.div<YourSalesCardProps>`
  display: flex;
  flex-direction: column;
  align-items: center;

  flex: 1 0 16rem;

  & > h2 {
    font-family: 'Montserrat';
    font-weight: 500;
    font-size: 1rem;

    color: #61646b;

    margin-bottom: 1rem;
  }

  & > div {
    display: flex;
    align-items: center;
    gap: 0.025rem;

    & > svg {
      font-size: 1.5rem;
    }

    & > strong {
      font-family: 'DunbarTallBold';
      font-weight: 700;
      font-size: 3rem;

      letter-spacing: 0.03em;
    }

    ${(props) => {
      switch (props.type) {
        case 'increased':
          return css`
            & > svg,
            strong {
              color: #1a7605;
            }
          `;

        case 'decreased':
          return css`
            & > svg {
              transform: rotateX(180deg);
            }

            & > svg,
            strong {
              color: #e01919;
            }
          `;
      }
    }}
  }

  & > strong {
    font-family: 'DunbarTextRegular';
    font-weight: 400;
    font-size: 1rem;

    letter-spacing: 0.02em;

    color: #000000;

    margin-top: 0.5rem;
  }
`;

export const SoldItems = styled.section`
  display: flex;
  align-items: center;
  gap: 1.5rem;

  overflow-x: auto;

  cursor: grab;

  &::-webkit-scrollbar {
    display: none;
  }

  & > div {
    flex: 0 0 16.125rem;
  }
`;

export const RankingContainer = styled.section`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 1.5rem;

  width: 100%;
  min-height: 33rem;

  @media (max-width: 520px) {
    flex-direction: column;
  }
`;

export const RankingWrapper = styled.div`
  flex: 1;

  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.5rem;

  @media (max-width: 1200px) {
    grid-template-columns: repeat(auto-fill, minmax(16.125rem, 16.125rem));
  }

  @media (max-width: 520px) {
    grid-template-columns: 1fr;
  }
`;

export const AverageTicket = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 0.75rem;

  background-color: #fff;
  border: 1px solid #c9cbcf;
  border-radius: 8px;

  height: 10rem;

  padding: 0.875rem;

  grid-column: span 2;

  flex: 1;

  & > h1 {
    font-weight: 500;
    font-size: 1rem;
    line-height: 100%;
    text-align: center;
    color: #61646b;
  }

  & > div {
    display: flex;
    align-items: center;

    width: 100%;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  @media (max-width: 992px) {
    grid-column: unset;

    height: auto;

    & > div {
      flex-direction: column;
      gap: 0.5rem;
    }
  }
`;
