import styled from 'styled-components';

export const Container = styled.div``;

export const Content = styled.main`
  display: flex;
  flex-direction: column;
  gap: 1.37rem;

  padding: 1.5rem 3rem;

  overflow-y: auto;

  max-height: 70vh;

  &::-webkit-scrollbar {
    width: 4px;
  }

  @media (max-width: 520px) {
    padding: 5%;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 3rem;

  &::-webkit-scrollbar {
    width: 4px;
  }

  & > hr {
    margin: 0 3rem;

    border: 1px solid #c9cbcf;
  }

  @media (max-width: 825px) {
    flex-direction: column;

    align-items: flex-start;
  }
`;

export const Aside = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  max-width: 50%;
  width: 100%;
  flex: 1;

  padding-left: 3rem;

  border-left: 1px solid #c9cbcf;

  @media (max-width: 825px) {
    max-width: 100%;

    padding-left: 0;

    border-left: unset;
  }
`;

export const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 1.5rem 3rem;

  border-bottom: 1px solid #c9cbcf;

  & > h1 {
    font-family: 'Montserrat', sans-serif;

    font-weight: 700;
    font-size: 1.5rem;

    color: #000000;
  }

  & > button {
    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 1.25rem;

    width: 3rem;
    height: 3rem;

    background: #f5f5f6;
    border-radius: 200px;
  }
`;

export const Customer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2.5rem;

  flex: 1;

  & > div {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;

    & > header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 2rem;

      & > h1 {
        font-family: 'Montserrat', sans-serif;
        font-weight: 600;
        font-size: 1.25rem;

        letter-spacing: -0.01em;

        color: #000000;
      }
    }

    & > div {
      display: flex;
      flex-direction: column;
      gap: 1rem;

      & > strong {
        font-family: 'DunbarTallBold', sans-serif;
        font-weight: 700;
        font-size: 1rem;

        letter-spacing: 0.02em;

        color: #000000;
      }

      & > p {
        display: flex;
        align-items: center;
        gap: 0.25rem;

        font-family: 'Montserrat', sans-serif;
        font-weight: 500;
        font-size: 1rem;

        letter-spacing: 0.01em;

        color: #000000;

        & > strong {
          font-family: 'Montserrat', sans-serif;
          font-weight: 700;
          font-size: 1rem;

          letter-spacing: 0.01em;

          color: #000000;
        }
      }
    }
  }
`;

export const Data = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  width: 100%;

  margin: 0 0 4.375rem;

  & > header {
    display: flex;
    align-items: center;

    & > h1 {
      font-family: 'Montserrat', sans-serif;
      font-weight: 600;
      font-size: 1.25rem;

      letter-spacing: -0.01em;

      color: #000000;
    }
  }

  & > main {
    display: flex;
    gap: 2.5rem;

    cursor: grab;

    padding: 1rem;

    background: #f5f5f5;

    margin: 0 -3rem;

    overflow-x: auto;

    @media (max-width: 520px) {
      margin: 0 -5%;
    }
  }
`;

export const Kit = styled.article`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;

  flex: 0 0 10.125rem;

  & > h1 {
    font-family: 'DunbarTallBold', sans-serif;
    font-weight: 700;
    font-size: 1rem;

    letter-spacing: 0.03em;

    color: #27282b;
  }

  & > strong {
    font-family: 'DunbarTallBold', sans-serif;

    font-style: normal;
    font-weight: 500;
    font-size: 1.125rem;

    letter-spacing: 0.03em;

    color: #27282b;
  }

  & > span {
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    font-size: 0.875rem;

    letter-spacing: 0.02em;

    color: #27282b;
  }
`;

export const BudgetInfo = styled.article`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  margin-bottom: 4rem;

  & > p {
    display: flex;
    align-items: center;
    gap: 0.25rem;

    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    font-size: 1rem;

    letter-spacing: 0.01em;

    color: #000000;

    & > strong {
      font-family: 'Montserrat', sans-serif;
      font-weight: 700;
      font-size: 1rem;

      letter-spacing: 0.01em;

      color: #000000;
    }
  }
`;

export const Messages = styled.article`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  & > p {
    display: flex;
    align-items: center;
    gap: 0.25rem;

    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    font-size: 1rem;

    letter-spacing: 0.01em;

    color: #000000;
  }
`;

export const Buttons = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;

  width: 100%;
`;
