import { yupResolver } from '@hookform/resolvers/yup';
import React, { useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { FiEdit } from 'react-icons/fi';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { Breadcrumb } from '../../../../../../components/breadcrumb';
import { Button } from '../../../../../../components/button';
import { FileInput, IFile } from '../../../../../../components/file-input';
import { InfoIcon } from '../../../../../../components/icons';
import Textarea from '../../../../../../components/textarea';
import { Order } from '../../../../../../contexts/orders/types';
import productsApi from '../../../../../../services/products';
import { ButtonGroup, Container, PageHeader } from '../../../../../styles';
import { FRANCHISE_ORDER_STATUS } from '../../../components/orders/innerTables/franchise';
import { Content, Documents, Observations } from './styles';

type FranchiseProcessingProps = {
  order: Order;
};

type Form = {
  observations: string;
  personType: IFile[];
  houseDocumentProof: IFile[];
  bankDocument: IFile[];
  otherDocuments: IFile[];
  acceptanceTerm: IFile[];
};

type OrderFiles = {
  PersonType: IFile[];
  HouseDocumentProof: IFile[];
  BankDocument: IFile[];
  OtherDocuments: IFile[];
};

const schema = yup.object().shape({
  observations: yup.string().optional(),
  personType: yup.mixed().optional(),
  houseDocumentProof: yup.mixed().optional(),
  bankDocument: yup.mixed().optional(),
  acceptanceTerm: yup.mixed().optional(),
  otherDocuments: yup.mixed().optional(),
});

export const FranchiseProcessing: React.FC<FranchiseProcessingProps> = (
  props
) => {
  const { order } = props;

  const { orderId, status } = useParams<{
    orderId: string;
    status: FRANCHISE_ORDER_STATUS;
  }>();

  const history = useHistory();

  const [editObservations, setEditObservations] = useState(false);

  const links = [
    {
      id: 1,
      title: 'Orçamentos',
      link: '/orcamentos',
      active: false,
    },
    {
      id: 2,
      title: 'Orçamentos e Pedidos',
      link: '/orcamentos/orcamentos-e-pedidos',
      active: false,
    },
    {
      id: 3,
      title: 'Pedidos enviados',
      link: `/orcamentos/orcamentos-e-pedidos/pedidos-enviados/${orderId}/${status}`,
      active: true,
    },
  ];

  const orderFiles: OrderFiles = useMemo(() => {
    return (
      order.files?.reduce(
        (acc: OrderFiles, file: File) => {
          if (file.type in acc) {
            return {
              ...acc,
              [file.type]: [
                ...acc[file.type as keyof OrderFiles],
                {
                  ...file,
                  progress: 100,
                },
              ],
            };
          }

          return acc;
        },
        {
          PersonType: [],
          BankDocument: [],
          HouseDocumentProof: [],
          OtherDocuments: [],
        }
      ) ?? {
        PersonType: [],
        BankDocument: [],
        HouseDocumentProof: [],
        OtherDocuments: [],
      }
    );
  }, [order]);

  const { control, handleSubmit, register } = useForm<Form>({
    resolver: yupResolver(schema),
    defaultValues: {
      observations: order.observations ?? '',
      personType: orderFiles.PersonType,
      houseDocumentProof: orderFiles.HouseDocumentProof,
      bankDocument: orderFiles.BankDocument,
      acceptanceTerm: orderFiles.OtherDocuments,
      otherDocuments: orderFiles.OtherDocuments,
    },
  });

  const [observations, setObservations] = useState(order.observations);

  const updateOrderObservations = (observations: string) => {
    productsApi
      .put(`/orders/${order.id}`, {
        observations,
        status: order.status,
        franchiseStatus: order.franchiseStatus,
        documentationMessage: null,
        isDocumentationOk: true,
      })
      .then((response) => {
        const observations = response.data.observations;

        setObservations(observations);

        setEditObservations((state) => !state);
      })
      .catch((error) => {
        const errorMessage = error.response.data.message;

        toast.error(errorMessage);
      });
  };

  const onSubmit = (data: Form) => {
    updateOrderObservations(data.observations);
  };

  return (
    <Container>
      <Breadcrumb links={links} />

      <PageHeader>
        <span>{'Pedidos enviados'}</span>
      </PageHeader>

      <Content>
        <Documents>
          <h1>Documentos</h1>

          <div>
            <Controller
              name="personType"
              control={control}
              render={({
                field: { ref, value, onChange },
                fieldState: { error },
              }) => {
                return (
                  <FileInput
                    ref={ref}
                    label="Pessoa física ou jurídica"
                    inputLabel="Anexar arquivo"
                    message={
                      <>
                        <InfoIcon />
                        CPF/CNH/Cartão CNPJ
                      </>
                    }
                    error={error?.message}
                    api={productsApi}
                    url={`/orders/upload-file/${order.id}/type/PersonType`}
                    deleteUrl={'/orders/upload-file/'}
                    onChange={(files) => onChange(files)}
                    files={value}
                  />
                );
              }}
            />

            <Controller
              name="houseDocumentProof"
              control={control}
              render={({
                field: { ref, value, onChange },
                fieldState: { error },
              }) => {
                return (
                  <FileInput
                    ref={ref}
                    label="Comprovante de residência"
                    inputLabel="Anexar arquivo"
                    error={error?.message}
                    api={productsApi}
                    url={`/orders/upload-file/${order.id}/type/HouseDocumentProof`}
                    deleteUrl={'/orders/upload-file/'}
                    onChange={(files) => onChange(files)}
                    files={value}
                  />
                );
              }}
            />

            <Controller
              name="bankDocument"
              control={control}
              render={({
                field: { ref, value, onChange },
                fieldState: { error },
              }) => {
                return (
                  <FileInput
                    ref={ref}
                    label="Documento do Banco"
                    inputLabel="Anexar arquivo"
                    message={
                      <>
                        <InfoIcon />
                        Comprovante de pagamento
                      </>
                    }
                    error={error?.message}
                    api={productsApi}
                    url={`/orders/upload-file/${order.id}/type/BankDocument`}
                    deleteUrl={'/orders/upload-file/'}
                    onChange={(files) => onChange(files)}
                    files={value}
                  />
                );
              }}
            />

            <Controller
              name="acceptanceTerm"
              control={control}
              render={({
                field: { ref, value, onChange },
                fieldState: { error },
              }) => {
                return (
                  <FileInput
                    ref={ref}
                    label="Termo de aceite assinado"
                    inputLabel="Anexar arquivo"
                    error={error?.message}
                    api={productsApi}
                    url={`/orders/upload-file/${order.id}/type/AcceptanceTerm`}
                    deleteUrl={'/orders/upload-file/'}
                    onChange={(files) => onChange(files)}
                    files={value}
                  />
                );
              }}
            />

            <Controller
              name="otherDocuments"
              control={control}
              render={({
                field: { ref, value, onChange },
                fieldState: { error },
              }) => {
                return (
                  <FileInput
                    ref={ref}
                    label="Outros documentos"
                    inputLabel="Anexar arquivo"
                    error={error?.message}
                    api={productsApi}
                    url={`/orders/upload-file/${order.id}/type/OtherDocuments`}
                    deleteUrl={'/orders/upload-file/'}
                    onChange={(files) => onChange(files)}
                    files={value}
                  />
                );
              }}
            />
          </div>
        </Documents>

        <Observations>
          <div>
            <h1>Observações do pedido</h1>

            <Button
              type="button"
              text="Editar descrição"
              icon={<FiEdit />}
              onClick={() => setEditObservations((state) => !state)}
            />
          </div>

          {!editObservations && <p>{observations}</p>}
          {editObservations && (
            <div>
              <Textarea
                label="Descreva o problema com a documentação"
                placeholder="Digite aqui"
                height="5.5rem"
                {...register('observations')}
              />

              <Button
                type="button"
                text="Salvar observação"
                onClick={() => {
                  handleSubmit(onSubmit)();
                }}
              />
            </div>
          )}
        </Observations>

        <ButtonGroup>
          <Button
            type="button"
            text="Voltar"
            typeStyle="default"
            backgroundHoverColor="#C9CBCF"
            onClick={() =>
              history.replace('/orcamentos/orcamentos-e-pedidos', 'Pedidos')
            }
          />
        </ButtonGroup>
      </Content>
    </Container>
  );
};
