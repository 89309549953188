import React from 'react';
import { ShapeModal } from '..';
import { Buttons } from '../styles';
import { Button } from '../../button';
import { Props as ReactModalProps } from 'react-modal';

type Props = {
  isOpen: boolean;
  onCancel: () => void;
  onConfirm: () => void;
} & ReactModalProps;

export const DisableBudgetModal: React.FC<Props> = (props) => {
  const { isOpen, onCancel, onConfirm, ...rest } = props;

  return (
    <ShapeModal isOpen={isOpen} {...rest}>
      <h1>Desativar orçamento</h1>
      <p>Deseja realmente desativar esse orçamento?</p>
      <p>
        Essa ação não poderá ser desfeita e você perderá todos os dados deste
        orçamento.
      </p>

      <Buttons>
        <Button
          type="button"
          text="Cancelar"
          typeStyle="default"
          backgroundHoverColor="#C9CBCF"
          onClick={onCancel}
        />
        <Button
          type="button"
          text="Confirmar"
          backgroundColor="#ED5A5A"
          backgroundHoverColor="#ed5a5abc"
          onClick={onConfirm}
        />
      </Buttons>
    </ShapeModal>
  );
};
