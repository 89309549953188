import styled, { css } from 'styled-components';

type InfoContainerType = {
  rightSpace?: boolean;
};

export const InfoContainer = styled.div<InfoContainerType>`
  background: #f5f5f6;
  border-radius: 8px;
  padding: 16px;
  margin-top: 16px;
  width: 100%;

  ${(props) =>
    props.rightSpace &&
    css`
      margin-right: 16px;
    `}

  span.label {
    margin-right: 8px;
    color: #61646b;
  }

  span.info {
    color: #27282b;
  }
`;

type groupType = {
  size?: 'small' | 'medium';
};

export const Group = styled.div<groupType>`
  ${(props) =>
    props.size === 'small' &&
    css`
      max-width: 350px;
    `}
  ${(props) =>
    props.size === 'medium' &&
    css`
      max-width: 570px;
    `}
`;

export const Columns = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;

  div {
    margin-left: 16px;
  }
`;
