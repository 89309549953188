import styled, { css } from 'styled-components';

type ContainerProps = {
  error?: boolean;
  isOpen: boolean;
};

export const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  position: relative;

  width: 24rem;

  & > span {
    color: #e01919;

    font-size: 0.875rem;
    font-weight: 500;

    white-space: nowrap;
  }

  & > label {
    font-size: 1rem;
    font-weight: 500;
    color: #27282b;
  }

  & > button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 3rem;
    padding: 0 1rem;

    border-radius: 4px;

    background-color: #ffffff;

    border: 1px solid #c9cbcf;

    font-family: 'Montserrat';
    font-weight: 500;
    font-size: 1rem;

    letter-spacing: 0.01em;

    color: #61646b;

    & > span {
      display: flex;
      align-items: center;
      gap: 0.5rem;

      color: #27282b;
      font-size: 1rem;
      font-weight: 400;

      & > svg {
        color: #323232;
        font-size: 1.25rem;
      }
    }

    & > svg {
      color: #61646b;
      font-size: 1.25rem;

      transition: 200ms transform ease-in-out;

      transform: ${(props) => props.isOpen && 'rotate(180deg)'};
    }

    ${(props) =>
      !props.error &&
      css`
        :focus {
          border-color: #005af9;
        }
      `}

    ${(props) =>
      props.error &&
      css`
        border-color: #e01919;
      `}
  }

  & > section {
    position: absolute;
    top: calc(100% + 0.5rem);
    z-index: 10;
    display: flex;
    flex-direction: column;

    width: 100%;

    border: 1px solid #c9cbcf;
    border-radius: 4px;

    background-color: #ffffff;

    padding: 0 1rem;

    .date-picker-container {
      width: 100%;

      & > div {
        width: 100%;
      }
    }

    .date-picker-selected {
      background: #c9cbcf;
    }

    .date-picker-today {
      color: #c9cbcf;
    }

    .date-picker-day-number > span::after {
      background: #c9cbcf;
    }
  }
`;
