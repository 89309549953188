import React, { useCallback, useEffect, useState } from 'react';
import { FiEye } from 'react-icons/fi';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ActionButton, Breadcrumb, Button } from '../../../../components';
import Loading from '../../../../components/loading';
import { KitDescriptionModal } from '../../../../components/modal/kit-description';
import { Order } from '../../../../contexts/orders/types';
import useMediaQuery from '../../../../hooks/mediaQuery';
import productsApi from '../../../../services/products';
import { toMoneyFormat } from '../../../../utils/toMoneyFormat';
import { ButtonGroup, Container, PageHeader } from '../../../styles';
import { Content } from '../styles';
import {
  Actions,
  Details,
  Info,
  TableContainer,
  TableFooter,
  TableHeader,
  ValuesData,
  Wrapper,
} from './styles';

type Profitability = Order;

type Modals = {
  description: string | null;
};

const BudgetsProfitabilityDetailsPage: React.FC = () => {
  const history = useHistory();

  const isTablet = useMediaQuery('(max-width: 1580px)');

  const { id } = useParams<{ id?: string }>();

  const [profitability, setProfitability] = useState<Profitability | null>(
    null
  );

  const [loading, setLoading] = useState(true);

  const costsValue = () => {
    if (!profitability) {
      return 0;
    }

    const kitsValue = profitability.items.reduce(
      (acc, item) => acc + item.price,
      0
    );

    const {
      projectValue,
      accessoriesValue,
      installerArt,
      royaltiesCommissionValue,
      invoiceDiscount,
      salesCommissionValue,
      referralCommissionValue,
    } = profitability;

    const costs = [
      projectValue,
      accessoriesValue,
      installerArt,
      royaltiesCommissionValue,
      invoiceDiscount,
      salesCommissionValue,
      referralCommissionValue,
    ]
      .map(Number)
      .reduce((acc, value) => acc + value, 0);

    return costs + kitsValue;
  };

  const profitPercentage = new Intl.NumberFormat('pt-BR').format(
    Number(profitability?.profitPercentage)
  );

  const [modals, setModals] = useState({
    description: null,
  } as Modals);

  const links = [
    {
      id: 1,
      title: 'Orçamentos',
      link: '/orcamentos',
      active: false,
    },
    {
      id: 2,
      title: 'Lucratividade nas vendas',
      link: '/orcamentos/lucratividade',
      active: false,
    },
    {
      id: 3,
      title: 'Detalhes',
      link: `/orcamentos/lucratividade/${id}/detalhes`,
      active: true,
    },
  ];

  const getProfitabilities = useCallback(async () => {
    try {
      const { data } = await productsApi.get(`/orders/${id}`);

      setProfitability(data);
    } catch (error) {
      toast.error('Desculpe, não foi possível buscar a lucratividade');

      throw error;
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    getProfitabilities();
  }, []);

  if (loading) {
    return <Loading />;
  }

  return (
    <Container>
      <Breadcrumb links={links} />

      <PageHeader>
        <span>Lucratividade Nas Vendas</span>
      </PageHeader>

      <Content>
        <TableContainer>
          <TableHeader>
            <h1>Kits</h1>
          </TableHeader>
          <div>
            <table>
              <thead>
                <tr>
                  <th>Fornecedor</th>
                  <th>Categoria</th>
                  <th>Kits</th>
                  <th>Valores</th>
                  <th>Quantidade</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {profitability?.items.map((item) => {
                  return (
                    <tr key={item.id}>
                      <td>
                        <strong>{item.product?.supplier}</strong>
                      </td>
                      <td>
                        <strong>{item.product?.category}W</strong>
                      </td>
                      <td>
                        <strong>
                          {item.product?.power} KWp | {item.product?.voltage}V
                          <span>
                            {item.product?.solarPlatesNumber} módulos |{' '}
                            {item.totalGeneration}KWh
                          </span>
                        </strong>
                      </td>
                      <td>
                        <ValuesData>
                          <span>Kit: {toMoneyFormat(item.price)}</span>
                          <span>Instalação: {toMoneyFormat(item.income)}</span>
                          <span className="hr" />
                          <strong>
                            Total:{' '}
                            {toMoneyFormat(item.total - item.maintenance)}
                          </strong>
                          {/* <span>
                                Manutenção: {toMoneyFormat(item.maintenance)}
                              </span> */}
                        </ValuesData>
                      </td>
                      <td>{item.quantity}</td>
                      <td>
                        <Actions>
                          <ActionButton
                            tooltip="Visualizar"
                            onClick={() => {
                              setModals((state) => {
                                return {
                                  ...state,
                                  description: item.product?.description ?? '',
                                };
                              });
                            }}
                          >
                            <FiEye />
                          </ActionButton>
                        </Actions>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
              <TableFooter />
            </table>
          </div>
        </TableContainer>

        <Details>
          <Wrapper>
            <div>
              <Info>
                <h1>Manutenções</h1>

                <strong>
                  {toMoneyFormat(profitability?.manutentionValue)}
                </strong>
              </Info>
              <Info>
                <h1>Receita Bruta</h1>

                <strong> {toMoneyFormat(profitability?.grossRevenue)}</strong>
              </Info>
              <Info>
                <h1>Descontos ou Acréscimos</h1>

                <strong>
                  {toMoneyFormat(
                    Number(profitability?.addition) +
                      Number(profitability?.discount)
                  )}
                </strong>
              </Info>
              <Info>
                <h1>Valor Final</h1>

                <strong>{toMoneyFormat(profitability?.finalValue)}</strong>
              </Info>
            </div>
          </Wrapper>

          <Wrapper>
            <h1>Custos</h1>

            <div>
              <Info>
                <h1>Comissão do vendedor</h1>

                <strong>
                  {toMoneyFormat(profitability?.salesCommissionValue)}
                </strong>
              </Info>
              <Info>
                <h1>Comissão por indicação</h1>

                <strong>
                  {toMoneyFormat(profitability?.referralCommissionValue)}
                </strong>
              </Info>
              <Info>
                <h1>Nota Fiscal</h1>

                <strong>{toMoneyFormat(profitability?.invoiceDiscount)}</strong>
              </Info>
              <Info>
                <h1>Royalties</h1>

                <strong>
                  {toMoneyFormat(profitability?.royaltiesCommissionValue)}
                </strong>
              </Info>
              <Info>
                <h1>Acessórios</h1>

                <strong>
                  {toMoneyFormat(profitability?.accessoriesValue)}
                </strong>
              </Info>
              <Info>
                <h1>Instalador</h1>

                <strong>{toMoneyFormat(profitability?.installerArt)}</strong>
              </Info>
              <Info>
                <h1>Projeto</h1>

                <strong>{toMoneyFormat(profitability?.projectValue)}</strong>
              </Info>
            </div>
          </Wrapper>
          <Wrapper>
            <h1>Lucro Líquido</h1>

            <div>
              <Info>
                <h1>Valor Final</h1>

                <strong>{toMoneyFormat(profitability?.finalValue)}</strong>
              </Info>

              <Info>
                <h1>Custos</h1>

                <strong>{toMoneyFormat(costsValue())}</strong>
              </Info>

              <Info
                style={
                  !isTablet ? { gridColumn: 'span 2', justifySelf: 'end' } : {}
                }
                variant="positive"
              >
                <h1>Lucro Líquido</h1>

                <strong>
                  {toMoneyFormat(profitability?.profit) +
                    ' - ' +
                    profitPercentage +
                    '%'}
                </strong>
              </Info>
            </div>
          </Wrapper>
        </Details>

        <ButtonGroup>
          <Button
            type="button"
            text="Voltar"
            typeStyle="default"
            backgroundHoverColor="#C9CBCF"
            onClick={() => history.push('/orcamentos/lucratividade')}
          />
        </ButtonGroup>
      </Content>

      <KitDescriptionModal
        isOpen={!!modals.description}
        description={modals.description as string}
        onCancel={() => {
          setModals((state) => {
            return {
              ...state,
              description: null,
            };
          });
        }}
        onRequestClose={() => {
          setModals((state) => {
            return {
              ...state,
              description: null,
            };
          });
        }}
      />
    </Container>
  );
};

export default BudgetsProfitabilityDetailsPage;
