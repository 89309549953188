import React from 'react';
import { Container, Wrapper } from './styles';

export const Infos: React.FC = () => {
  return (
    <Container>
      <Wrapper>
        <span>
          Consumo médio do cliente:<strong>100 KWh</strong>
        </span>
        <span>
          Tarifa de disponibilidade:<strong>30 (monofásico)</strong>
        </span>
        <span>
          Consumo para suprir:<strong>70 KWh</strong>
        </span>
        <span>
          Rede:<strong>127/220</strong>
        </span>
        <span>
          Placas necessárias¹:<strong>0</strong>
        </span>
        <span className="platesMessage">
          ¹Total de placas indicadas para a posição norte, será ajustados
          conforme a orientação informada.
        </span>
      </Wrapper>

      <h1>Sua personalização</h1>

      <Wrapper>
        <span>
          Total de placas:<strong>0</strong>
        </span>
        <span>
          Produção:<strong>0 KWh - 0 KWp</strong>
        </span>
      </Wrapper>
    </Container>
  );
};

