import styled, { css } from 'styled-components';

type ContainerProps = {
  height: string;
  active: boolean;
  disabled?: boolean;
  error?: boolean;
  resize?: 'none' | 'both' | 'horizontal' | 'vertical';
};

export const Container = styled.label<ContainerProps>`
  display: flex;
  flex-direction: column;

  width: 100%;

  gap: 0.5rem;

  font-weight: 500;
  font-size: 1rem;

  color: #25292d;

  & > span {
    color: #e01919;

    font-size: 0.875rem;

    white-space: nowrap;
  }

  & > textarea {
    width: 100%;

    min-height: 5.5rem;

    height: ${(props) => props.height};

    padding: 1.25rem 1.5rem;
    background-color: ${({ disabled }) => (disabled ? '#F4F5F6;' : '#fff')};

    border: 1px solid #c9cbcf;
    border-radius: 0.25rem;

    font-size: 1rem;

    ${(props) =>
      props.resize &&
      css`
        resize: ${props.resize};
        overflow: auto;
      `}

    ${(props) =>
      props.active &&
      css`
        border-color: #005af9;
      `}

      ${(props) =>
      props.error &&
      css`
        border-color: #e01919;
      `}

      ${(props) =>
      !props.error &&
      css`
        :focus {
          border-color: #005af9;
        }
      `}

    ${({ disabled }) => {
      return (
        disabled &&
        css`
          pointer-events: none;

          color: #9aa3ac;
          border: 1px solid #c7ccd1;
        `
      );
    }}
  }
`;
