import styled from 'styled-components';

export const Content = styled.main`
  height: 100%;

  display: flex;
  flex-direction: column;

  padding: 1.5rem 0;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  margin-bottom: 3rem;

  & > h1 {
    font-family: 'DunbarTextRegular', sans-serif;
    font-weight: 400;
    font-size: 1.25rem;

    letter-spacing: 0.02em;

    color: #27282b;
  }
`;

export const Voucher = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2.625rem;

  height: 100%;

  & > h1 {
    font-family: 'DunbarTextRegular', sans-serif;
    font-weight: 400;
    font-size: 1.25rem;

    letter-spacing: 0.02em;

    color: #27282b;
  }

  & > div {
    display: flex;
    justify-content: space-between;
    align-items: center;

    & > p {
      font-family: 'DunbarTextRegular', sans-serif;

      font-weight: 400;
      font-size: 1rem;

      letter-spacing: 0.02em;

      color: #000000;
    }

    & > button {
      color: #ffffff;

      & > svg {
        font-size: 1.25rem;
      }
    }
  }
`;

export const Links = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(12.125rem, 1fr));
  gap: 3.625rem;

  & > div {
    display: flex;
    flex-direction: column;

    width: fit-content;

    & > h1 {
      font-family: 'DunbarTextRegular', sans-serif;

      font-weight: 400;
      font-size: 1rem;

      letter-spacing: 0.02em;

      color: #000000;
    }

    & > a {
      font-family: 'Montserrat';

      font-weight: 400;
      font-size: 1rem;

      letter-spacing: 0.02em;
      text-decoration-line: underline;

      color: #005af9;
    }
  }
`;
