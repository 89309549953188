import styled from 'styled-components';

export const Container = styled.div`
  & > h1 {
    color: #27282b;
    font-family: 'Montserrat', sans-serif;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 600;
    letter-spacing: 0.0125rem;

    margin: 2.5rem 0 1.5rem;
  }
`;

export const Wrapper = styled.article`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  & > span {
    display: flex;
    align-items: center;
    gap: 0.25rem;

    color: #000;

    font-family: 'DunbarTextRegular', sans-serif;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0.02rem;

    & > strong {
      color: #000;

      font-family: 'DunbarTallBold', sans-serif;
      font-size: 1rem;
      font-style: normal;
      font-weight: 700;
      letter-spacing: 0.03rem;
    }
  }

  & > span.platesMessage {
    color: #000;
    font-family: 'Montserrat', sans-serif;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0.015rem;
  }
`;

