import styled from 'styled-components';

export const Container = styled.main``;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  margin: 0 -2rem;

  & .columns {
    display: flex;

    overflow-y: hidden;
  }

  @media (max-width: 520px) {
    margin: 0 -5%;
  }
`;
