import styled from 'styled-components';

export const Content = styled.form`
  height: 100%;

  display: flex;
  flex-direction: column;

  padding: 1.5rem 0;
`;

export const Documents = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  margin-bottom: 3rem;

  & > h1 {
    font-family: 'DunbarTextRegular', sans-serif;
    font-weight: 400;
    font-size: 1.25rem;

    letter-spacing: 0.02em;

    color: #27282b;
  }

  & > div {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(16.125rem, 1fr));
    gap: 1.5rem;

    @media (max-width: 992px) {
      grid-template-columns: 1fr;

      & > div {
        width: 100%;

        & > div {
          width: 100% !important;
        }
      }
    }
  }
`;

export const ProblemDescription = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.125rem;

  margin-bottom: 3.5rem;

  & > h1 {
    font-family: 'DunbarTextRegular';
    font-weight: 400;
    font-size: 1.25rem;

    letter-spacing: 0.02em;

    white-space: nowrap;

    color: #27282b;
  }

  & > p {
    font-family: 'DunbarTextRegular';

    font-weight: 400;
    font-size: 1rem;

    letter-spacing: 0.02em;

    color: #000000;
  }
`;

export const Observations = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.125rem;

  height: 100%;

  & > div:first-child {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1.125rem;

    & > h1 {
      font-family: 'DunbarTextRegular';
      font-weight: 400;
      font-size: 1.25rem;

      letter-spacing: 0.02em;

      white-space: nowrap;

      color: #27282b;
    }

    & > button > svg {
      font-size: 1.25rem;
      color: #ffffff;

      & > path {
        stroke: unset;
      }
    }

    @media (max-width: 768px) {
      flex-direction: column;
      align-items: flex-start;

      & > button {
        width: 100%;
      }
    }
  }
  & > div:last-child {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;

    @media (max-width: 768px) {
      & > button {
        width: 100%;
      }
    }
  }

  & > p {
    font-family: 'DunbarTextRegular';

    font-weight: 400;
    font-size: 1rem;

    letter-spacing: 0.02em;

    color: #000000;
  }
`;
