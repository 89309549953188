import React, { useEffect, useState } from 'react';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import {
  Breadcrumb,
  Button,
  InfoIcon,
  MoneyIcon,
} from '../../../../components';
import Input from '../../../../components/input';
import InputPrice from '../../../../components/input-price';
import { ButtonGroup, Container, PageHeader, Wrapper } from '../../../styles';
import ConfirmUpdatesModal from '../components/ConfirmUpdatesModal';
import { Divisor, FormContainer, FormContent } from './styles';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Product } from '../../../../types/product';
import { toMoneyFormat } from '../../../../utils/toMoneyFormat';
import productsApi from '../../../../services/products';
import { toast } from 'react-toastify';
import Skeleton from 'react-loading-skeleton';

type Form = {
  price: string;
  discount: number;
  rebate: number;
};

const schema = yup.object().shape({
  price: yup.string().required('Campo obrigatório'),
  discount: yup.number().required('Campo obrigatório'),
  rebate: yup.number().required('Campo obrigatório'),
});

const EditKitSettedDiscountAbatementPage: React.FC = () => {
  const [finalPrice, setFinalPrice] = useState(0);
  const [loading, setLoading] = useState(false);
  const [kit, setKit] = useState<Product>({} as Product);

  const history = useHistory();

  const param = useParams<{ id: string }>();

  if (!param.id || !kit) {
    return <Redirect to={'/produtos/lista/kit-pronto'} />;
  }

  const links = [
    {
      id: 1,
      title: 'Produtos',
      link: '/produtos',
      active: false,
    },
    {
      id: 2,
      title: 'Lista de produtos',
      link: `/produtos/lista/`,
      active: false,
    },
    {
      id: 3,
      title: 'Kits Prontos',
      link: '/produtos/lista/kit-pronto',
      active: false,
    },
    {
      id: 4,
      title: 'Edição de kit',
      link: `/produtos/lista/kit-pronto/${param.id}/editar`,
      active: false,
    },
    {
      id: 5,
      title: 'Desconto e rebate',
      link: `/produtos/lista/kit-pronto/${param.id}/editar/desconto-e-rebate`,
      active: true,
    },
  ];

  const {
    register,
    handleSubmit,
    reset,
    control,
    watch,
    formState: { errors },
  } = useForm<Form>({
    resolver: yupResolver(schema),
  });

  const originalPrice = watch('price');
  const discount = watch('discount');
  const rebate = watch('rebate');

  const [openConfirmModal, setOpenConfirmModal] = useState(false);

  const handleModal = async () => {
    const { id, createdAt, updatedAt, deletedAt, price, ...data } = kit;

    await productsApi
      .put(`/products/${param.id}`, {
        ...data,
        originalPrice: Number(originalPrice.replace(',', '.')),
        discount,
        rebate,
      })
      .then((res) => {
        setKit(res.data);
        toast.success('Valores do kit editados com sucesso!');
      })
      .catch(() => toast.error('Não foi possível editar os valores do kit!'))
      .finally(() => setOpenConfirmModal(false));
  };

  const onSubmit = (data: Form) => {
    setOpenConfirmModal(true);
  };

  useEffect(() => {
    reset({
      price: String(kit.originalPrice),
      discount: kit.discount,
      rebate: kit.rebate,
    });

    setFinalPrice(kit.price);
  }, [kit]);

  useEffect(() => {
    setLoading(true);

    productsApi
      .get(`/products/${param.id}`)
      .then((res) => setKit(res.data))
      .finally(() => setLoading(false));
  }, []);

  return (
    <Container>
      <Breadcrumb links={links} />
      <Wrapper>
        <PageHeader>
          <span>{'Edição de Desconto e Rebate'}</span>
        </PageHeader>
        <FormContainer onSubmit={handleSubmit(onSubmit)}>
          <FormContent>
            <h1>Editar valores</h1>
            <div className="form-content__wrapper">
              <div className="form-content__inputs">
                {loading ? (
                  <Skeleton width={'16.125rem'} height={48} borderRadius={4} />
                ) : (
                  <Controller
                    control={control}
                    name="price"
                    render={({
                      field: { onChange, value, ref },
                      fieldState: { error },
                    }) => {
                      return (
                        <InputPrice
                          style={{ width: '16.125rem' }}
                          ref={ref}
                          label="Valor"
                          message={
                            <>
                              <InfoIcon />
                              Sem desconto e rebate
                            </>
                          }
                          placeholder="R$ 6.500,00"
                          error={error?.message}
                          onValueChange={onChange}
                          value={value}
                        />
                      );
                    }}
                  />
                )}
                {loading ? (
                  <>
                    <Skeleton
                      width={'16.125rem'}
                      height={48}
                      borderRadius={4}
                    />
                    <Skeleton
                      width={'16.125rem'}
                      height={48}
                      borderRadius={4}
                    />
                  </>
                ) : (
                  <>
                    <Input
                      style={{ width: '16.125rem' }}
                      label="Desconto"
                      iconLeft="%"
                      error={errors.discount?.message}
                      {...register('discount')}
                    />
                    <Input
                      style={{ width: '16.125rem' }}
                      label="Rebate"
                      iconLeft="%"
                      error={errors.rebate?.message}
                      {...register('rebate')}
                    />
                  </>
                )}
              </div>
              <div className="form-content__price">
                {loading ? (
                  <Skeleton height={48} width={350} />
                ) : (
                  <>
                    <span>Valor</span>
                    <strong>{toMoneyFormat(finalPrice)}</strong>
                  </>
                )}
              </div>
            </div>
          </FormContent>

          <ButtonGroup style={{ marginBlock: '2.5rem' }}>
            {loading ? (
              <>
                <Skeleton width={256} height={48} borderRadius={8} />
                <Skeleton width={256} height={48} borderRadius={8} />
              </>
            ) : (
              <>
                <Button
                  type="button"
                  text="Cancelar"
                  typeStyle="default"
                  backgroundHoverColor="#C9CBCF"
                  onClick={() => history.goBack()}
                />

                <Button type="submit" text="Salvar" />
              </>
            )}
          </ButtonGroup>

          <Divisor />

          <div className="button-wrapper">
            <p>
              Os valores alterados acima serão atribuídos somente à este kit.
              Caso queira alterar os valores de toda a categoria, clique em:
            </p>

            <Button
              type="button"
              style={{ width: '16rem', height: '3rem' }}
              text="Descontos e rebates"
              onClick={() => history.push('/produtos/descontos-rebates')}
              icon={<MoneyIcon />}
            />
          </div>
        </FormContainer>

        <ConfirmUpdatesModal
          isOpen={openConfirmModal}
          onConfirm={handleModal}
          onRequestClose={() => setOpenConfirmModal(false)}
        >
          <h1>Alteração de desconto e rebate</h1>
          <p>
            Tem certeza que esse kit possui os valores de desconto e rebate
            configurados?
          </p>
          <div>
            <p>
              Kit: <strong>{kit.name}</strong>
            </p>
            <p>
              Fornecedor: <strong>{kit.supplier}</strong>
            </p>
          </div>
          <div>
            <p>
              Desconto: <strong>{discount + '%'}</strong>
            </p>
            <p>
              Rebate: <strong>{rebate + '%'}</strong>
            </p>
          </div>
          <div>
            <p>
              Valor final: <strong>{toMoneyFormat(finalPrice)}</strong>
            </p>
          </div>
        </ConfirmUpdatesModal>
      </Wrapper>
    </Container>
  );
};

export default EditKitSettedDiscountAbatementPage;
