import { yupResolver } from '@hookform/resolvers/yup';
import { format } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import React, { useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { FiXCircle } from 'react-icons/fi';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { Breadcrumb } from '../../../../../../components/breadcrumb';
import { Button } from '../../../../../../components/button';
import { CloudIcon } from '../../../../../../components/icons';
import { CancelOrderModal } from '../../../../../../components/modal/cancel-order';
import RadioGroup from '../../../../../../components/radio-group';
import { Order } from '../../../../../../contexts/orders/types';
import productsApi from '../../../../../../services/products';
import { downloadFile } from '../../../../../../utils/downloadFile';
import { ButtonGroup, Container, PageHeader } from '../../../../../styles';
import { ORDER_STATUS } from '../../../components/orders/innerTables/consultant';
import { SentDate } from '../../../styles';
import {
  CancelOrder,
  ConsultantMessage,
  Content,
  Documents,
  Observation,
  Wrapper,
} from './styles';

type ConsultantSentToCpProps = {
  order: Order;
};

type OrderFile = {
  id: string;
  orderId: string;
  name: string;
  mimetype: string;
  path: string;
  originalName: string;
  size: string;
  type: string;
  createdAt: string;
  updatedAt: string;
};

type OrderFiles = {
  PersonType: OrderFile[];
  HouseDocumentProof: OrderFile[];
  BankDocument: OrderFile[];
  OtherDocuments: OrderFile[];
  AcceptanceTerm: OrderFile[];
};

type Form = {
  isOk: boolean;
};

const schema = yup.object().shape({
  isOk: yup.boolean(),
});

export const ConsultantSentToCp: React.FC<ConsultantSentToCpProps> = (
  props
) => {
  const { order } = props;

  const { orderId, status } = useParams<{
    orderId: string;
    status: ORDER_STATUS;
  }>();

  const files = useMemo(() => {
    return (
      order.files.reduce(
        (acc: OrderFiles, file: File) => {
          if (file.type in acc) {
            return {
              ...acc,
              [file.type]: [...acc[file.type as keyof OrderFiles], file],
            };
          }

          return acc;
        },
        {
          PersonType: [],
          BankDocument: [],
          HouseDocumentProof: [],
          OtherDocuments: [],
          AcceptanceTerm: [],
        }
      ) ?? {
        PersonType: [],
        BankDocument: [],
        HouseDocumentProof: [],
        OtherDocuments: [],
        AcceptanceTerm: [],
      }
    );
  }, [order]);

  const history = useHistory();

  const links = [
    {
      id: 1,
      title: 'Orçamentos',
      link: '/orcamentos',
      active: false,
    },
    {
      id: 2,
      title: 'Orçamentos e Pedidos',
      link: '/orcamentos/orcamentos-e-pedidos',
      active: false,
    },
    {
      id: 3,
      title: 'Pedidos enviados',
      link: `/orcamentos/orcamentos-e-pedidos/pedidos-enviados/${orderId}/${status}`,
      active: true,
    },
  ];

  const { control, handleSubmit, watch } = useForm<Form>({
    resolver: yupResolver(schema),
    defaultValues: {
      isOk: false,
    },
    shouldUseNativeValidation: false,
  });

  const isOk = watch('isOk');

  const updateOrderStatus = () => {
    productsApi
      .patch(`/orders/${order.id}/status`, {
        status: 'Enviado para Fornecedor',
        franchiseStatus: order.franchiseStatus,
      })
      .then(() => {
        toast.success('Status do pedido atualizado');

        history.replace('/orcamentos/orcamentos-e-pedidos/');
      })
      .catch((error) => {
        const errorMessage = error.response.data.message;

        toast.error(errorMessage);
      });
  };

  const [cancelModal, setCancelModal] = useState(false);

  const cancelOrder = () => {
    productsApi
      .patch(`/orders/${order.id}/status`, {
        status: 'Pedido cancelado',
        franchiseStatus: 'Pedido cancelado',
      })
      .then(() => {
        toast.success('Status do pedido atualizado');

        history.replace('/orcamentos/orcamentos-e-pedidos/');
      })
      .catch((error) => {
        const errorMessage = error.response.data.message;

        toast.error(errorMessage);
      });
  };

  const handleCancelModal = () => {
    setCancelModal((state) => !state);
  };

  return (
    <Container>
      <Breadcrumb links={links} />

      <PageHeader>
        <span>{'Pedidos enviados'}</span>
      </PageHeader>

      <Content onSubmit={handleSubmit(updateOrderStatus)}>
        <ConsultantMessage>
          <div>
            <h1>Mensagem do consultor</h1>

            <p>{order.orderCenterMessage}</p>
          </div>

          <CancelOrder>
            <Button
              type="button"
              backgroundColor="#E01919"
              backgroundHoverColor="#e01919c1"
              typeStyle="default"
              text="Cancelar pedido"
              onClick={handleCancelModal}
              icon={<FiXCircle />}
            />
          </CancelOrder>
        </ConsultantMessage>

        <Documents>
          <div>
            <h1>Documentos enviados pelo vendedor</h1>

            <Wrapper>
              <ul>
                <label>Pessoa física ou jurídica</label>

                {files.PersonType.map((file) => {
                  return (
                    <li key={file.name}>
                      <Button
                        type="button"
                        backgroundColor="#FFFFFF"
                        typeStyle="default"
                        text="Fazer download"
                        onClick={() => downloadFile(file.path)}
                        icon={<CloudIcon />}
                      />
                      <SentDate>
                        Enviado em:{' '}
                        {format(new Date(file.updatedAt), 'dd/MM/yyyy', {
                          locale: ptBR,
                        })}
                      </SentDate>
                    </li>
                  );
                })}
              </ul>

              <ul>
                <label>Comprovante de residência</label>

                {files.HouseDocumentProof.map((file) => {
                  return (
                    <li key={file.name}>
                      <Button
                        type="button"
                        backgroundColor="#FFFFFF"
                        typeStyle="default"
                        text="Fazer download"
                        onClick={() => downloadFile(file.path)}
                        icon={<CloudIcon />}
                      />
                      <SentDate>
                        Enviado em:{' '}
                        {format(new Date(file.updatedAt), 'dd/MM/yyyy', {
                          locale: ptBR,
                        })}
                      </SentDate>
                    </li>
                  );
                })}
              </ul>

              <ul>
                <label>Documento do Banco</label>

                {files.BankDocument.map((file) => {
                  return (
                    <li key={file.name}>
                      <Button
                        type="button"
                        backgroundColor="#FFFFFF"
                        typeStyle="default"
                        text="Fazer download"
                        onClick={() => downloadFile(file.path)}
                        icon={<CloudIcon />}
                      />
                      <SentDate>
                        Enviado em:{' '}
                        {format(new Date(file.updatedAt), 'dd/MM/yyyy', {
                          locale: ptBR,
                        })}
                      </SentDate>
                    </li>
                  );
                })}
              </ul>

              <ul>
                <label>Termo de aceite assinado</label>

                {files.AcceptanceTerm.map((file) => {
                  return (
                    <li key={file.name}>
                      <Button
                        type="button"
                        backgroundColor="#FFFFFF"
                        typeStyle="default"
                        text="Fazer download"
                        onClick={() => downloadFile(file.path)}
                        icon={<CloudIcon />}
                      />
                      <SentDate>
                        Enviado em:{' '}
                        {format(new Date(file.updatedAt), 'dd/MM/yyyy', {
                          locale: ptBR,
                        })}
                      </SentDate>
                    </li>
                  );
                })}
              </ul>

              <ul>
                <label>Outros documentos</label>

                {files.OtherDocuments.map((file) => {
                  return (
                    <li key={file.name}>
                      <Button
                        type="button"
                        backgroundColor="#FFFFFF"
                        typeStyle="default"
                        text="Fazer download"
                        onClick={() => downloadFile(file.path)}
                        icon={<CloudIcon />}
                      />
                      <SentDate>
                        Enviado em:{' '}
                        {format(new Date(file.updatedAt), 'dd/MM/yyyy', {
                          locale: ptBR,
                        })}
                      </SentDate>
                    </li>
                  );
                })}
              </ul>
            </Wrapper>
          </div>
        </Documents>

        <Controller
          control={control}
          name="isOk"
          render={({ field: { ref, onChange, value } }) => {
            return (
              <RadioGroup
                ref={ref}
                label="A documentação já foi enviada para o fornecedor?"
                options={[
                  {
                    label: 'Sim',
                    value: 'true',
                  },
                  {
                    label: 'Não',
                    value: 'false',
                  },
                ]}
                rowMode
                onChange={async (event) => {
                  const value = JSON.parse(event.target.value);

                  onChange(value);
                }}
                value={JSON.stringify(value)}
              />
            );
          }}
        />

        <Observation>
          <h1>Observações do pedido</h1>

          <p>{order.observations}</p>
        </Observation>

        <ButtonGroup>
          <Button
            type="button"
            text="Voltar"
            typeStyle="default"
            backgroundHoverColor="#C9CBCF"
            onClick={() =>
              history.replace('/orcamentos/orcamentos-e-pedidos', 'Pedidos')
            }
          />
          <Button type="submit" text={'Avançar'} disabled={!isOk} />
        </ButtonGroup>
      </Content>

      <CancelOrderModal
        isOpen={cancelModal}
        onConfirm={cancelOrder}
        onCancel={handleCancelModal}
      />
    </Container>
  );
};
