import React, { Fragment } from 'react';
import { LeadStatus } from '../../../../../../contexts/sales-funnel/types';
import { CaptureModal } from '../../../../../../components/modal/sales-funnel/capture';
import { BudgetModal } from '../../../../../../components/modal/sales-funnel/budget';
import { NegotiationModal } from '../../../../../../components/modal/sales-funnel/negotiation';
import { FinalProposalModal } from '../../../../../../components/modal/sales-funnel/final-proposal';
import { RecuperationModal } from '../../../../../../components/modal/sales-funnel/recuperation';
import { ClosedModal } from '../../../../../../components/modal/sales-funnel/closed';
import { LostModal } from '../../../../../../components/modal/sales-funnel/lost';
import { ScheduledModal } from '../../../../../../components/modal/sales-funnel/scheduled';
import { Modals } from '../Item';

interface Props {
  modals: Modals;
  onClose: (modal: LeadStatus) => void;
}

export const KanbanModals = (props: Props) => {
  const { modals, onClose } = props;

  return (
    <Fragment>
      <CaptureModal
        isOpen={modals.captacao.isOpen}
        onCancel={() => onClose('captacao')}
        content={modals.captacao.data}
      />

      <BudgetModal
        isOpen={modals.orcamento.isOpen}
        onCancel={() => onClose('orcamento')}
        content={modals.orcamento.data}
      />

      <NegotiationModal
        isOpen={modals.negociacao.isOpen}
        onCancel={() => onClose('negociacao')}
        content={modals.negociacao.data}
      />

      <FinalProposalModal
        isOpen={modals.propostaFinal.isOpen}
        onCancel={() => onClose('propostaFinal')}
        content={modals.propostaFinal.data}
      />

      <RecuperationModal
        isOpen={modals.recuperacao.isOpen}
        onCancel={() => onClose('recuperacao')}
        content={modals.recuperacao.data}
      />

      <ClosedModal
        isOpen={modals.fechado.isOpen}
        onCancel={() => onClose('fechado')}
        content={modals.fechado.data}
      />

      <LostModal
        isOpen={modals.perdido.isOpen}
        onCancel={() => onClose('perdido')}
        content={modals.perdido.data}
      />

      <ScheduledModal
        isOpen={modals.agendado.isOpen}
        onCancel={() => onClose('agendado')}
        content={modals.agendado.data}
      />
    </Fragment>
  );
};
