import React, { useState } from 'react';
import { Breadcrumb, Button, StepProgress } from '../../../../../../components';
import { Content, Products, Header, Product } from './styles';
import { useHistory, useParams } from 'react-router-dom';
import { ButtonGroup, Container, PageHeader } from '../../../../../styles';
import { Tables } from './tables';
import {
  ConfirmKitConfigurationModal,
  RequestAnalysisModal,
} from '../../../../../../components/modal/custom-kits/';

export type ProductsType =
  | 'Inversores'
  | 'Conectores'
  | 'Cabos'
  | 'Acessórios'
  | 'Proteção'
  | 'Suportes';

export type Product = {
  id: string;
  name: ProductsType;
};

type Modals = {
  summary: {
    isOpen: boolean;
  };
  analysis: {
    isOpen: boolean;
  };
};

const steps = [
  { key: 1, title: 'Selecionar produto', active: false, complete: true },
  { key: 2, title: 'Cadastro do cliente', active: false, complete: true },
  { key: 3, title: 'Selecionar Kits', active: false, complete: true },
  { key: 4, title: 'Personalizar kit', active: true, complete: false },
  { key: 5, title: 'Serviços', active: false, complete: false },
  { key: 6, title: 'Proposta', active: false, complete: false },
];

const products: Product[] = [
  { id: '1', name: 'Inversores' },
  { id: '2', name: 'Conectores' },
  { id: '3', name: 'Cabos' },
  { id: '4', name: 'Acessórios' },
  { id: '5', name: 'Proteção' },
  { id: '6', name: 'Suportes' },
];

const CustomKitProductSelectionPage: React.FC = () => {
  const history = useHistory();

  const { pathname } = history.location;

  const hasEditPath = pathname.includes('editar');

  const { id } = useParams<{ id: string }>();

  const links = [
    {
      id: 1,
      title: 'Orçamentos',
      link: '/orcamentos',
      active: false,
    },
    {
      id: 2,
      title: 'Selecionar produto',
      link: '/orcamentos/selecionar-produto',
      active: false,
    },
    {
      id: 3,
      title: 'Cadastro do cliente',
      link: hasEditPath
        ? `/orcamentos/selecionar-produto/cadastro-cliente/${id}/editar`
        : `/orcamentos/selecionar-produto/cadastro-cliente`,
      active: false,
    },
    {
      id: 4,
      title: 'Seleção de kits',
      link: hasEditPath
        ? `/orcamentos/selecionar-produto/cadastro-cliente/${id}/selecao-de-kits/editar`
        : `/orcamentos/selecionar-produto/cadastro-cliente/${id}/selecao-de-kits/`,
      active: false,
    },
    {
      id: 5,
      title: 'Personalizar kit',
      link: hasEditPath
        ? `/orcamentos/selecionar-produto/cadastro-cliente/${id}/selecao-de-kits/personalizar-kit/editar`
        : `/orcamentos/selecionar-produto/cadastro-cliente/${id}/selecao-de-kits/personalizar-kit/`,
      active: true,
    },
  ];

  const [activeProduct, setActiveProduct] = useState<Product>(products[0]);

  const [modals, setModals] = useState<Modals>({
    summary: {
      isOpen: false,
    },
    analysis: {
      isOpen: false,
    },
  });

  const { analysis, summary } = modals;

  const handleActiveProduct = (product: Product) => setActiveProduct(product);

  const handleModals = (modal: keyof Modals) => {
    setModals((state) => {
      return {
        ...state,
        [modal]: {
          ...[modal],
          isOpen: !state[modal].isOpen,
        },
      };
    });
  };

  return (
    <Container>
      <Breadcrumb links={links} />

      <PageHeader>
        <span>Seleção de Produtos do Kit</span>
        <div>
          <StepProgress steps={steps} />
        </div>
      </PageHeader>

      <Content>
        <Header>
          <h1>Seleção de Produtos do Kit</h1>

          <Products>
            {products.map((product) => {
              const isActive = product.name === activeProduct.name;

              return (
                <Product
                  key={product.id}
                  isActive={isActive}
                  onClick={() => handleActiveProduct(product)}
                >
                  {product.name}
                </Product>
              );
            })}
          </Products>
        </Header>

        <Tables activeProduct={activeProduct} />

        <ButtonGroup>
          <Button
            text="Voltar"
            typeStyle="default"
            backgroundHoverColor="#C9CBCF"
            onClick={() => {
              history.push(
                `/orcamentos/selecionar-produto/cadastro-cliente/${id}/selecao-de-kits/personalizar-kit/montar-kit/${
                  hasEditPath ? 'editar' : ''
                }`
              );
            }}
          />

          <Button text="Avançar" onClick={() => handleModals('summary')} />
        </ButtonGroup>
      </Content>

      <ConfirmKitConfigurationModal
        isOpen={summary.isOpen}
        onCancel={() => handleModals('summary')}
        onConfirm={() => {
          handleModals('analysis');

          // history.push(
          //   `/orcamentos/selecionar-produto/cadastro-cliente/${id}/selecao-de-kits/personalizar-kit/montar-kit/selecao-de-produtos/composicao/${
          //     hasEditPath ? 'editar' : ''
          //   }`
          // );
        }}
      />

      <RequestAnalysisModal
        isOpen={analysis.isOpen}
        onCancel={() => handleModals('analysis')}
        onConfirm={() =>
          history.push(
            `/orcamentos/selecionar-produto/cadastro-cliente/${id}/selecao-de-kits/personalizar-kit/montar-kit/selecao-de-produtos/solicitacao-de-analise`
          )
        }
      />
    </Container>
  );
};

export default CustomKitProductSelectionPage;
