import { ProjectType } from '../../types/project';
import { ProjectStatus, UploadTypes } from './types';

export const isDownloadAllowed = (project: ProjectType) => {
  if (
    [
      ProjectStatus.PROJETO_ENVIADO,
      ProjectStatus.PROJETO_APROVADO,
      ProjectStatus.PROJETO_REPROVADO,
      ProjectStatus.PROJETO_AGUARDANDO_CONCESSIONARIO,
      ProjectStatus.AGUARDANDO_ANALISE_VISTORIA,
      ProjectStatus.VISTORIA_APROVADA,
      ProjectStatus.VISTORIA_REPROVADA,
      ProjectStatus.PROJETO_CONCLUIDO,
      ProjectStatus.VISTORIA_SOLICITADA,
    ].includes(project.status)
  ) {
    const hasFile = project.files?.filter(
      (file) => file.type === UploadTypes.ARQUIVOS_PROJETO
    );
    if (hasFile && hasFile.length > 0) {
      return true;
    }
  }

  return false;
};

export const isEditAllowed = (project: ProjectType) => {
  if (
    [
      ProjectStatus.PROJETO_RACUNHO,
      ProjectStatus.DOCUMENTACAO_INCOMPLETA,
      ProjectStatus.VISTORIA_REPROVADA,
    ].includes(project.status)
  ) {
    return true;
  }

  return false;
};

export const isRequestInspectionAllowed = (project: ProjectType) => {
  if (
    [
      ProjectStatus.PROJETO_APROVADO,
      ProjectStatus.AGUARDANDO_ANALISE_VISTORIA,
      ProjectStatus.VISTORIA_SOLICITADA,
      ProjectStatus.VISTORIA_REPROVADA,
      ProjectStatus.DOCUMENTACAO_REPROVADA,
    ].includes(project.status)
  ) {
    return true;
  }

  return false;
};

export const isConcludeAllowed = (project: ProjectType) => {
  if (
    [
      ProjectStatus.VISTORIA_APROVADA,
      ProjectStatus.VISTORIA_SOLICITADA,
    ].includes(project.status)
  ) {
    return true;
  }

  return false;
};

export const isIspectionResultAllowed = (project: ProjectType) => {
  if ([ProjectStatus.VISTORIA_SOLICITADA].includes(project.status)) {
    return true;
  }

  return false;
};
