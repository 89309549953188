import styled from 'styled-components';

export const Container = styled.div<{ isOpen: boolean }>`
  display: flex;
  flex-direction: column;
  position: relative;
  & > button {
    display: flex;
    align-items: center;
    gap: 0.25rem;

    padding: 0.25rem 0.5rem;

    border: 1px solid #c9cbcf;
    border-radius: 4px;

    background-color: white;
    color: #27282b;

    font-size: 0.875rem;
    font-weight: 400;

    & > svg {
      color: #27282b;
      font-size: 0.75rem;
      stroke-width: 4px;

      transition: all 250ms;

      transform: ${(props) => props.isOpen && 'rotate(180deg)'};
    }
  }

  & > section {
    position: absolute;
    top: calc(100% + 4px);
    right: 0;
    left: 0;
    display: flex;
    flex-direction: column;

    border: 1px solid #c9cbcf;
    border-radius: 4px;

    background-color: white;

    z-index: 99;

    & > span {
      cursor: pointer;
      display: flex;
      align-items: center;
      padding: 0.25rem 0.5rem;

      color: #27282b;

      font-size: 0.875rem;
      font-weight: 400;

      &:hover {
        background-color: #c9cbcf;
      }
    }
  }
`;
