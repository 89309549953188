import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

type RadioItemProps = {
  checked: boolean;
};

export const RadioItem = styled.label<RadioItemProps>`
  color: #000000;
  border: 1px solid #dfe0e2;
  margin-right: 16px;
  background-color: #ffffff;
  height: 48px;
  display: flex;
  align-items: center;
  padding: 0 16px;
  border-radius: 5px;

  input {
    margin-right: 8px;
  }

  &:not(:last-child) {
    margin-bottom: 8px;
  }

  ${(props) =>
    props.checked &&
    css`
      background-color: #edf3fd;
      border: 1px solid #8fb1ec;
      color: #005af9;
    `}
`;
