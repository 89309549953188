import styled from 'styled-components';

export const ButtonContainer = styled.div`
  margin-top: 16px;
  display: flex;
  justify-content: flex-end;

  div {
    margin-left: 16px;
  }
`;

export const File = styled.div`
  background: #7ed56b;
  border-radius: 4px;
  height: 48px;
  min-width: 150px;
  padding: 16px;

  font-size: 14px;
  a {
    color: #121212;
  }

  &:not(:first-child) {
    margin-left: 16px;
  }
`;

export const FilesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const Observation = styled.div`
  margin: 32px 0;

  span {
    font-weight: bold;
  }
`;
