import React, { useEffect, useState } from 'react';
import { ProjectStatus } from '../../pages/projects/types';
import Select from '../form-elements/select';
import { Container } from './styles';

type OptionsType = {
  label: string;
  value: string;
};

type StatusSelectorProps = {
  onChange: (status: string) => void;
};

export const StatusSelect: React.FC<StatusSelectorProps> = ({ onChange }) => {
  const [selected, setSelected] = useState<string | undefined | null>();
  const [statusOptions, setStatusOptions] = useState<OptionsType[]>([]);

  useEffect(() => {
    const statuses = Object.values(ProjectStatus).map((status) => ({
      label: status,
      value: status,
    }));

    setStatusOptions(statuses);
  }, []);

  const handleSelectChange = (franchise: string) => {
    onChange(franchise);
    setSelected(franchise);
  };

  return (
    <Container>
      <Select
        name="franchise"
        onChange={(event) => {
          handleSelectChange(event);
        }}
        options={statusOptions}
        value={statusOptions.find((f) => f.value === selected)?.value}
        control={''}
        placeholder="Status"
      />
    </Container>
  );
};
