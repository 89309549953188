import styled from 'styled-components';

export const NotFoundMessage = styled.div`
  display: flex;
  justify-content: center;
  border-bottom: 1px solid #e0e0e0;

  margin: 0 auto;

  padding: 3rem 0;

  font-weight: 400;
  font-size: 1.125rem;

  color: #27282b;
`;

export const TableHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;

  background-color: #fafafa;

  @media (max-width: 992px) {
    flex-direction: column;
    align-items: flex-end;

    & > div:first-child {
      max-width: unset;

      order: 2;
    }

    & > div:last-child {
      width: 100%;

      & > button {
        width: 100%;
      }
    }
  }
`;
