import styled, { css } from 'styled-components';

export type Variant = 'blue' | 'red' | 'white' | 'gray';

type ButtonProps = {
  variant: Variant;
  maxWidth: string;
};

const variants = {
  blue: css`
    color: #ffffff;
    background: #0043b8;
  `,
  red: css`
    color: #131415;
    background: #ed5a5a;
  `,
  white: css`
    color: #27282b;
    background: white;
    border: 1px solid #61646b;
  `,
  gray: css`
    color: #27282b;
    background: #c9cbcf;
  `,
};

export const Container = styled.button<ButtonProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;

  padding: 1rem;

  max-width: ${(props) => props.maxWidth};
  width: 100%;

  max-height: 3rem;

  font-family: 'Montserrat';
  font-weight: 500;
  font-size: 1rem;

  border-radius: 8px;

  ${(props) => variants[props.variant]}

  & > svg {
    font-size: 1.25rem;
  }
`;
