import styled from 'styled-components';

export const Content = styled.form`
  height: calc(100% - 7rem);

  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  padding: 1.5rem 0;

  & > h2 {
    font-family: 'Montserrat';
    font-weight: 600;
    font-size: 1.25rem;
    line-height: 1.5rem;
    letter-spacing: 0.01em;
    color: #27282b;
  }
`;

export const Accessories = styled.section`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  & > div {
    flex: 1;
    display: flex;
    gap: 1.5rem;

    @media screen and (max-width: 1080px) {
      flex-direction: column;
    }
  }
`;

type InputContainerProps = {
  size?: 'small' | 'large';
};

export const InputContainer = styled.div<InputContainerProps>`
  flex: ${(props) => (props.size === 'small' ? 0.35 : 0.65)};

  display: flex;
  gap: 1.5rem;
  justify-content: space-between;

  & > div,
  & > label {
    flex: 1;
  }
`;

export const TotalValue = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;

  & > p {
    font-family: 'DunbarTextRegular';
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5rem;
    letter-spacing: 0.02em;
    color: #27282b;
  }

  & > span {
    font-family: 'DunbarTallBold';
    font-weight: 500;
    font-size: 1.5rem;
    line-height: 1.5rem;
    letter-spacing: 0.03em;
    color: #27282b;
  }
`;

export const TableHeader = styled.header`
  display: flex;
  justify-content: flex-start;

  background-color: #fafafa;
`;
