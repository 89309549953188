import styled, { css } from 'styled-components';

type ContainerProps = {
  active: boolean;
  disabled: boolean;
  variant: 'primary' | 'secondary';
};

export const Container = styled.label<ContainerProps>`
  display: flex;
  flex-direction: column;

  width: 100%;
  max-width: 96px;

  gap: 0.5rem;

  font-size: 16px;
  font-weight: 500;

  background-color: #ffffff;
  color: #27282b;

  & > div {
    display: flex;
    align-items: center;

    border: 1px solid #00299b;

    border-radius: 4px;

    ${(props) =>
      props.active &&
      css`
        border-color: #005af9;
      `}

    ${(props) =>
      props.disabled &&
      css`
        border-color: #dfe0e2;
      `}

      ${(props) =>
      props.variant === 'secondary' &&
      css`
        border-color: #dfe0e2;
      `}

    & > button {
      display: flex;
      justify-content: center;
      align-items: center;

      width: 32px;
      height: 32px;

      line-height: 0;

      border: 0;

      background-color: transparent;

      & > span {
        display: flex;
        justify-content: center;
        align-items: center;

        font-size: 22px;

        width: 100%;
        height: 100%;

        color: #00299b;

        ${(props) =>
          props.disabled &&
          css`
            color: #dfe0e2;
          `}

        ${(props) =>
          props.variant === 'secondary' &&
          css`
            color: #005af9;
          `}
      }
    }

    & > input {
      display: flex;
      justify-content: center;
      align-items: center;

      background-color: transparent;
      border: 0;
      width: 32px;
      font-size: 14px;
      color: #00299b;

      font-weight: 500;

      text-align: center;

      pointer-events: none;

      padding: 0.5rem 0;

      &:focus {
        outline: none;
      }

      &::placeholder {
        font-size: 14;
        color: #61646b;
      }

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      ${(props) =>
        props.variant === 'secondary' &&
        css`
          color: #27282b;
          font-family: 'Montserrat', sans-serif;
          font-size: 0.875rem;
          font-style: normal;
          font-weight: 500;
        `}
    }
  }
`;
