import styled from 'styled-components';

export const Container = styled.div`
  overflow-x: auto;
  overflow-y: hidden;

  cursor: grab;

  ::-webkit-scrollbar {
    display: none;
  }
`;

export const ChartContainer = styled.div`
  min-width: 980px;

  margin-bottom: 1.5rem;

  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  & > canvas {
    height: 17rem !important;
  }
  & > div {
    height: 1.25rem;
    position: relative;
  }
`;

type IndicatorProps = {
  isPositive: boolean;
};

export const Indicator = styled.span<IndicatorProps>`
  width: 3rem;
  height: 1.25rem;

  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;

  border-radius: 999px;

  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 100%;
  letter-spacing: 0.3px;

  background-color: ${(props) => (props.isPositive ? '#D4F1CE' : '#FDEDED')};
  color: ${(props) => (props.isPositive ? '#1A7605' : '#E01919')};
`;
