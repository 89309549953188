import React, { useCallback } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import {
  Breadcrumb,
  Button,
  LogContainer,
  StepProgress,
} from '../../../../components';
import { DraftProduct } from '../../../../types/product';
import { ButtonGroup, Container, PageHeader } from '../../../styles';

const steps = [
  { key: 1, title: 'Enviar dados', active: false, complete: true },
  { key: 2, title: 'Processamento', active: true, complete: false },
  { key: 3, title: 'Conferência de dados', active: false, complete: false },
];

const ProductsImportSettedKitsProcessPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();

  const history = useHistory();

  const location = useLocation();

  const links = [
    {
      id: 1,
      title: 'Produtos',
      link: '/produtos',
      active: false,
    },
    {
      id: 2,
      title: 'Importação de Produtos',
      link: '/produtos/importacao',
      active: false,
    },
    {
      id: 3,
      title: 'Envio de dados',
      link: '/produtos/importacao/kit-pronto',
      active: false,
    },
    {
      id: 4,
      title: 'Processamento',
      link: `/produtos/importacao/kit-pronto/${id}/processamento`,
      active: true,
    },
  ];

  const logs = location.state as DraftProduct[];

  const handleSubmit = useCallback(() => {
    history.replace(`/produtos/importacao/kit-pronto/${id}/conferencia`);
  }, []);

  return (
    <Container>
      <Breadcrumb links={links} />

      <PageHeader>
        <span>{'Processamento'}</span>
      </PageHeader>

      <StepProgress steps={steps} />

      <LogContainer logs={logs} />

      <ButtonGroup>
        <Button
          type="button"
          text="Voltar"
          typeStyle="default"
          backgroundHoverColor="#C9CBCF"
          onClick={() => history.push('/produtos/importacao/kit-pronto/')}
        />

        <Button
          type="submit"
          text="Concluir"
          onClick={handleSubmit}
          typeStyle="confirm"
        />
      </ButtonGroup>
    </Container>
  );
};

export default ProductsImportSettedKitsProcessPage;
