import React, { ReactNode } from 'react';
import { FiMinusCircle, FiXCircle } from 'react-icons/fi';
import { Container } from './styles';
import { BsCheckCircleFill } from 'react-icons/bs';

export type Status =
  | 'A processar'
  | 'Pedido incompleto'
  | 'Enviado para CP'
  | 'Enviado para Fornecedor'
  | 'Link do cartão'
  | 'Pedido processado'
  | 'Aguardando pagamento'
  | 'NFE emitida'
  | 'Pedido cancelado'
  | 'Aguardando NFE'
  | 'Boleto/Espelho'
  | 'NFF/Espelho'
  | 'Pagamento Efetuado';

type TagProps = {
  status: Status;
  children: ReactNode;
};

export const Tag: React.FC<TagProps> = ({ status, children }) => {
  return (
    <Container status={status}>
      {status.includes('incomplete') && <FiMinusCircle />}
      {status.includes('canceled') && <FiXCircle />}
      {['payment-effected', 'waiting-nfe', 'nfe-emitted'].some((_status) =>
        _status.includes(status)
      ) && <BsCheckCircleFill />}

      {children}
    </Container>
  );
};
