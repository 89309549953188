import styled from 'styled-components';

export const Content = styled.form`
  display: flex;
  flex-direction: column;
  gap: 2rem;

  height: 100%;

  & > h1 {
    font-weight: 500;
    font-size: 1.25rem;

    color: #27282b;
  }
`;

export const PaymentMethodsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 1.5rem;

  @media (max-width: 992px) {
    justify-content: center;
    gap: 2rem;
  }
`;

export const PaymentMethods = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(16.125rem, 1fr));
  gap: 1.5rem;

  flex: 1;

  & > label {
    width: fit-content;
  }
`;

type OrderTotalPriceProps = {
  hasErrors?: boolean;
};

export const OrderTotalPrice = styled.div<OrderTotalPriceProps>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  margin-bottom: 1.5rem;

  & > span {
    font-weight: 500;
    font-size: 1rem;

    color: #27282b;
  }

  & > strong {
    font-family: 'DunbarTallBold', sans-serif;
    font-weight: 500;
    font-size: 2rem;

    color: ${(props) => (props.hasErrors ? '#e01919' : '#27282b')};
  }

  @media (max-width: 992px) {
    align-items: center;

    width: 100%;
  }
`;

export const PaymentDetailsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 1.5rem;

  @media (max-width: 992px) {
    &.financing {
      flex-direction: column;
      align-items: flex-start;

      & > div {
        width: 100% !important;

        & > div {
          width: 100%;
        }
      }
    }

    &.financing-entry {
      & > div {
        width: 100%;
      }
    }

    &.entry-creditcard {
      & > div {
        & > div {
          width: 100%;
        }
        & label {
          width: 100%;
        }
      }
    }

    .input-file {
      width: 100%;

      & > div {
        width: 100%;
      }
    }

    .bank-input {
      max-width: unset !important;
    }

    .entry-value {
      width: 100% !important;
    }
  }
`;

export const Cards = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  width: 100%;

  & > div {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(16.125rem, 16.125rem));
    gap: 1.5rem;

    width: 100%;
  }

  @media (max-width: 992px) {
    align-items: flex-end;

    & > div {
      grid-template-columns: 1fr;
    }
  }
`;
