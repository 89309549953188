import React, { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Breadcrumb, Button, SearchInput, Status } from '../../components';
import franchisesApi from '../../services/franchises';
import { Container, PageHeader, TableHeader } from './../styles';
import { FranchiseListType } from './types';

import { FiEdit, FiUsers } from 'react-icons/fi';
import { ActionButton } from '../../components';
import Table, { ColumnStructure, TableData } from '../../components/table';
import InnerActions from '../../components/table/innerActions';
import { useAuth } from '../../contexts/auth';
import { UserRoles } from '../../types/users';

type TableItems = {
  id: string;
  name: string;
  nickname: string;
  city: string;
  enabled: JSX.Element;
  actions: JSX.Element;
};

const FranchisesPage: React.FC = () => {
  const history = useHistory();
  const [franchises, setFranchises] = useState<FranchiseListType>({
    content: [],
    pagination: {
      currentPage: 0,
      lastPage: 0,
      limit: 0,
      total: 0,
    },
  });

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [name, setName] = useState<string>('');
  const [loading, setLoading] = useState(true);

  const handlePageChange = (page: number): void => {
    setPage(page);
  };

  const handleLimitChange = (limit: number): void => {
    setLimit(limit);
  };

  const handleSearchChange = (search: string): void => {
    setName(search);
  };

  const links = [
    {
      id: 1,
      title: 'Franquias',
      link: '/franquias',
      active: false,
    },
    {
      id: 2,
      title: 'Lista de franquias',
      link: '/franquias',
      active: true,
    },
  ];

  const loadUsers = () => {
    setLoading(true);

    franchisesApi
      .get<FranchiseListType>(
        `/api/franchises?page=${page}&limit=${limit}&name=${name}`
      )
      .then((res) => {
        setFranchises(res.data);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    loadUsers();
  }, [page, limit, name]);

  const tableColumns: ColumnStructure[] = [
    {
      id: 'name',
      label: 'Nome',
      onClick: () => {},
    },
    {
      id: 'nickname',
      label: 'Apelido',
      onClick: () => {},
    },
    {
      id: 'city',
      label: 'Cidade',
      onClick: () => {},
    },
    {
      id: 'enabled',
      label: 'Status',
      onClick: () => {},
    },
    {
      id: 'actions',
      label: 'Ações',
      type: 'actionCell',
    },
  ];

  const { user } = useAuth();

  const isManage = [UserRoles.GERENCIA].includes(user.role);

  const tableItems: TableData<TableItems>[] = useMemo(() => {
    return franchises.content.map((franchise) => {
      return {
        ...franchise,
        enabled: <Status text={franchise.enabled ? 'Ativo' : 'Desativado'} />,
        actions: (
          <InnerActions>
            {!isManage && (
              <ActionButton
                tooltip="Editar"
                onClick={() => {
                  history.push(`/franquia/${franchise.id}/editar`);
                }}
              >
                <FiEdit />
              </ActionButton>
            )}

            <ActionButton
              tooltip="Membros"
              onClick={() => {
                history.push(`/franquia/${franchise.id}/membros`);
              }}
            >
              <FiUsers />
            </ActionButton>
          </InnerActions>
        ),
      };
    });
  }, [franchises]);

  return (
    <Container>
      <Breadcrumb links={links} />
      <PageHeader>
        <span>Lista de franquias</span>
      </PageHeader>

      <Table
        items={tableItems}
        columns={tableColumns}
        isLoading={loading}
        header={
          <TableHeader>
            <SearchInput
              name="search"
              placeholder="Digite aqui para pesquisar"
              onTextChange={handleSearchChange}
            />
            <Button
              text="Nova franquia"
              icon="add"
              onClick={() => history.push('/franquia/nova')}
            />
          </TableHeader>
        }
        placeholder="Nenhuma franquia encontrada"
        pagination={franchises.pagination}
        onPageChange={handlePageChange}
        onLimitChange={handleLimitChange}
      />
    </Container>
  );
};

export default FranchisesPage;
