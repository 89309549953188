import React, { useState } from 'react';
import { FiCalendar, FiEdit, FiPlusCircle, FiX } from 'react-icons/fi';
import { Props } from 'react-modal';
import { useHistory } from 'react-router-dom';
import { ShapeModal } from '../..';
import { useSalesFunnel } from '../../../../contexts/sales-funnel';
import { Lead, STATES_UF } from '../../../../contexts/sales-funnel/types';
import { Button } from '../components/button';
import { InnerScheduleModal } from '../inner-modals/schedule';
import {
  Aside,
  Buttons,
  Container,
  Content,
  Customer,
  Empty,
  Header,
  Wrapper,
} from './styles';
import { Tags } from '../components/tags';
import { LeadModal } from '../lead-modal';

type CaptureModalProps = {
  onCancel: () => void;
  content: Lead | null;
} & Props;

export const CaptureModal: React.FC<CaptureModalProps> = (props) => {
  const { onCancel, content, ...rest } = props;

  const history = useHistory();

  const { leadModal, handleLeadModal, handleCRMData } = useSalesFunnel();

  const [scheduleModal, setScheduleModal] = useState({
    isOpen: false,
  });

  const handleOpenModal = () => {
    setScheduleModal((state) => {
      return {
        ...state,
        isOpen: true,
      };
    });
  };

  const handleCloseModal = () => {
    setScheduleModal({
      isOpen: false,
    });
  };

  const createBudget = () => {
    handleCRMData(content);

    history.push('/orcamentos/selecionar-produto/cadastro-cliente');
  };

  return (
    <ShapeModal
      padding="0"
      maxWidth="60rem"
      onRequestClose={onCancel}
      {...rest}
    >
      <Container>
        <Header>
          <h1>Captação</h1>

          <button onClick={onCancel} type="button">
            <FiX />
          </button>
        </Header>

        <Content>
          <Tags lead={content} />

          <Wrapper>
            <Customer>
              <div>
                <header>
                  <h1>Dados do cliente</h1>

                  <Button
                    variant="white"
                    type="button"
                    maxWidth="7.313rem"
                    onClick={() => handleLeadModal(content)}
                  >
                    <FiEdit />
                    Editar
                  </Button>
                </header>

                <div>
                  <strong>{content?.customer.name}</strong>
                  <p>
                    Tipo de instalação:
                    <strong>{content?.customer.sector}</strong>
                  </p>
                  <p>
                    Canal de origem:<strong>{content?.customer.origin}</strong>
                  </p>
                  <p>
                    Vendedor:
                    <strong>{content?.franchiseUser?.user.name}</strong>
                  </p>
                </div>
              </div>
              <div>
                <header>
                  <h1>Endereço do cliente</h1>
                </header>
                {content?.customer.address && (
                  <div>
                    <p>
                      {content?.customer.address},{' '}
                      {content?.customer.addressNumber}
                    </p>

                    <p>
                      {content?.customer.district} - {content?.customer.city} -{' '}
                      {
                        STATES_UF[
                          content?.customer.state as keyof typeof STATES_UF
                        ]
                      }
                    </p>

                    <p>
                      CEP: <strong>{content?.customer.zipcode}</strong>
                    </p>
                  </div>
                )}

                {!content?.customer.address && (
                  <div>
                    <p>Nenhum endereço cadastrado.</p>
                  </div>
                )}
              </div>
            </Customer>
            <Aside>
              <Empty>
                <p>Este cliente ainda não possui orçamentos.</p>

                <p>Crie um orçamento através do botão abaixo</p>
              </Empty>
              <Buttons>
                <Button
                  variant="blue"
                  type="button"
                  maxWidth="100%"
                  onClick={createBudget}
                >
                  <FiPlusCircle />
                  Gerar orçamento
                </Button>
                <Button
                  variant="white"
                  type="button"
                  maxWidth="100%"
                  onClick={handleOpenModal}
                >
                  <FiCalendar />
                  Agendar novo contato
                </Button>
              </Buttons>
            </Aside>
          </Wrapper>
        </Content>
      </Container>

      <InnerScheduleModal
        leadId={content?.id ?? ''}
        isOpen={scheduleModal.isOpen}
        onCancel={() => {
          handleCloseModal();
          onCancel();
        }}
        onConfirm={() => onCancel()}
      />

      <LeadModal
        isOpen={leadModal.isOpen}
        lead={leadModal.data}
        onCancel={() => {
          handleLeadModal(null);
          onCancel();
        }}
      />
    </ShapeModal>
  );
};
