import React from 'react';
import { Container } from './styles';
import Select from '../select';

interface SelectProps {
  onChange: (selected: string) => void;
}

const states = [
  'Acre',
  'Alagoas',
  'Amazonas',
  'Amapá',
  'Bahia',
  'Ceara',
  'Distrito Federal',
  'Espirito Santo',
  'Goiás',
  'Maranhão',
  'Minas Gerais',
  'Mato Grosso do Sul',
  'Mato Grosso',
  'Pará',
  'Pernambuco',
  'Paraíba',
  'Piauí',
  'Paraná',
  'Rio de Janeiro',
  'Rio Grande do Norte',
  'Rondônia',
  'Roraima',
  'Rio Grande do Sul',
  'Santa Catarina',
  'São Paulo',
  'Sergipe',
  'Tocantins',
];

const options = states.map((state) => ({ label: state, value: state }));

const StateSelect: React.FC<SelectProps> = ({ onChange, ...rest }) => {
  return (
    <Container {...rest}>
      <Select
        options={options}
        name="state"
        control={null}
        onChange={onChange}
      />
    </Container>
  );
};

export default StateSelect;
