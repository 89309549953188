import styled from 'styled-components';

export const Content = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  height: 100%;

  margin-top: 0.625rem;

  & > h1 {
    font-weight: 400;
    font-size: 1.25rem;

    color: #27282b;
  }

  & > div:nth-child(3) {
    padding: 0;

    border-bottom: unset;
  }

  & > div:last-child {
    margin: auto 0 0;
  }
`;

export const Warning = styled.div`
  margin-top: 2rem;

  & > strong {
    font-weight: 500;
    font-size: 1rem;

    color: #27282b;
  }

  & > p {
    font-weight: 400;
    font-size: 0.875rem;

    color: #27282b;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  /* padding: 0 0 2.5rem; */

  /* border-bottom: 1px solid #dfe0e2; */

  & > h1 {
    font-family: 'DunbarTextRegular', sans-serif;
    font-weight: 400;
    font-size: 1.25rem;

    letter-spacing: 0.02em;

    color: #27282b;
  }
`;

export const InputWrapper = styled.fieldset`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 2rem;

  border: 0;

  @media (max-width: 992px) {
    & > label {
      width: 100%;
    }
  }
`;

export const Royalties = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  & > span {
    font-family: 'DunbarTextRegular', sans-serif;
    font-weight: 400;
    font-size: 1rem;

    letter-spacing: 0.02em;

    color: #27282b;
  }

  & > strong {
    font-family: 'DunbarTallBold', sans-serif;
    font-weight: 500;
    font-size: 2rem;

    letter-spacing: 0.03em;

    color: #27282b;
  }
`;
