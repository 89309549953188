import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  width: 16.125rem;

  font-size: 1rem;
  font-weight: 500;
  color: #27282b;

  & > span {
    color: #e01919;

    font-size: 0.875rem;

    white-space: nowrap;
  }

  & > span.message {
    display: flex;
    align-items: center;
    gap: 0.375rem;

    font-weight: 500;
    font-size: 0.875rem;

    color: #61646b;

    order: 3;
  }
`;

export const Content = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 16.125rem;
  height: 3rem;

  border-radius: 8px;

  background-color: #dfe0e2;

  border-radius: 8px;

  &:hover {
    background-color: #c9cbcf;
  }

  &:disabled {
    cursor: not-allowed;
  }

  cursor: pointer;

  & > div {
    display: flex;
    justify-content: center;
    align-items: center;

    flex: 1;
    height: 100%;

    & > p {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 0.5rem;

      font-weight: 600;
      font-size: 1rem;

      color: #27282b;

      & > svg {
        width: 1.25rem;
        height: 1.25rem;

        & > path {
          stroke: #27282b;
        }
      }
    }
  }
`;

export const FilePreviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  width: 100%;
`;

type FileWrapperProps = {
  progress: number;
  error: boolean;
};

export const FileWrapper = styled.div<FileWrapperProps>`
  display: flex;
  align-items: center;

  cursor: pointer;

  width: 16.125rem;
  height: 3rem;

  border-radius: 8px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  border: ${(props) => (props.error ? '1px solid #e01919' : 'none')};

  position: relative;

  & > div {
    display: flex;
    align-items: center;

    border-radius: 8px;

    font-weight: 600;
    font-size: 1rem;

    color: ${(props) => (props.error ? '#ffffff' : '#27282b')};

    padding: 0.75rem 1rem;

    width: 100%;
    height: 100%;

    background-color: #dfe0e2;

    position: relative;

    z-index: 4;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    & > span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      max-width: 12.5rem;
    }
  }

  & > div::before {
    position: absolute;

    content: '';
    top: 0;
    left: 0;

    /* transition: width 250ms ease-in-out; */

    width: ${(props) => props.progress + '%'};
    height: 100%;

    z-index: -1;

    background-color: ${(props) => (props.error ? '#e01919' : '#7ed56b')};

    border-radius: 4px;
  }

  & > button {
    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: 50%;
    background: #131415;

    position: absolute;
    right: 1rem;

    z-index: 5;

    width: 1.25rem;
    height: 1.25rem;

    & > svg {
      color: black;

      & > path {
        stroke: #ffffff;
      }
    }
  }
`;

export const FileContainer = styled.div`
  & > span {
    color: #e01919;

    font-size: 0.875rem;

    white-space: nowrap;
  }
`;
