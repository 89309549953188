import React, { InputHTMLAttributes } from 'react';
import {
  CheckboxContainer,
  HiddenCheckbox,
  StyledCheckbox,
  Text,
} from './styles';

import CheckIcon from '../../../assets/icon/tick.svg';
interface CheckboxTypes extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  handleOnclick: (checked: boolean) => void;
  checked: boolean;
}

export const Checkbox: React.FC<CheckboxTypes> = ({
  children,
  handleOnclick,
  checked,
}) => {
  function handleCheckboxChange() {
    handleOnclick(!checked);
  }

  return (
    <CheckboxContainer checked={checked} onClick={handleCheckboxChange}>
      <HiddenCheckbox onChange={handleCheckboxChange} checked={checked} />
      <StyledCheckbox checked={checked}>
        <img alt="tick icon" style={{ width: '15px' }} src={CheckIcon} />
      </StyledCheckbox>
      <Text checked={checked}>{children}</Text>
    </CheckboxContainer>
  );
};
