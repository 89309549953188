import React, { useEffect, useRef, useState } from 'react';
import { FiCpu, FiUsers } from 'react-icons/fi';
import { Link, useLocation } from 'react-router-dom';
import logo from '../../assets/logo-white.png';
import { useAuth } from '../../contexts/auth';
import {
  getBudgetOptions,
  getProductsOptions,
} from '../../helper/MenuOptionsByRole';
import { getPermissionsByRole } from '../../helper/PermissionsByRole';
import { useOnClickOutside } from '../../hooks/outsideClick';
import { UserRoles } from '../../types/users';
import { CollapsibleOptions } from '../collapsible-options';
import {
  ArrowRightIcon,
  ChatIcon,
  DashboardIcon,
  FranchisersIcon,
  HornIcon,
  JuridicalIcon,
  KanbanIcon,
  LogoutIcon,
  ManagementIcon,
  ProductsIcon,
  ProjectsIcon,
  ProvidersIcon,
  ThumbsupIcon,
} from '../icons';
import { CloseButton, Container, Content, Divider, Logo } from './styles';

type Props = {
  open: boolean;
  handleSidebar: () => void;
  handleOutSideClick: () => void;
};

export type Permissions = {
  users: boolean;
  franchises: boolean;
  providers: boolean;
  products: boolean;
  budgets: boolean;
  projects: boolean;
  dashboard: boolean;
  salesFunnel: boolean;
  projectsURL: string;
  management: boolean;
  announcements: boolean;
  campaigns: boolean;
  nps: boolean;
  juridical: boolean;
  customers: boolean;
};

enum PagesRoute {
  Dashboard = 'dashboard',
  SalesFunnel = 'funil-de-vendas',
  Franchises = 'franquia',
  Users = 'usuario',
  Supplier = 'fornecedor',
  Projects = 'projeto',
  Management = 'gestao',
  Announcements = 'comunicados',
  Campaigns = 'campanhas',
  NPS = 'nps',
  Juridical = 'juridico',
  Customers = 'clientes',
}

export const Menu: React.FC<Props> = (props) => {
  const { open, handleSidebar, handleOutSideClick } = props;

  const { signOut, user, selectedFranchise, franchises } = useAuth();
  const location = useLocation();

  const [permissions, setPermissions] = useState<Permissions | null>(null);
  const [collapsibleOpened, setCollapsibleOpened] = useState<string[]>([]);

  const [active, setActive] = useState('');

  const menuRef = useRef<HTMLElement>(null);
  useOnClickOutside(menuRef, handleOutSideClick);

  const isSeller =
    franchises.find((franchise) => franchise.id === selectedFranchise)?.role ===
      'Vendedor' && UserRoles.FRANQUEADO.includes(user.role);

  const budgetOptions = getBudgetOptions(isSeller ? 'Vendedor' : user.role);

  const productOptions = getProductsOptions(user.role);

  const toggCollapsibleOpened = (menu: string): void => {
    if (!collapsibleOpened.includes(menu)) {
      return setCollapsibleOpened((state) => [...state, menu]);
    }

    return setCollapsibleOpened(() =>
      collapsibleOpened.filter((item) => {
        return item !== menu;
      })
    );
  };

  useEffect(() => {
    setPermissions(getPermissionsByRole(user.role));
  }, [user.role]);

  useEffect(() => {
    const activeItem =
      location.pathname
        .split('/')
        .map((path) =>
          Object.values(PagesRoute).find((item) => path.includes(item))
        )
        .find(Boolean) || '';

    setActive(activeItem);
  }, [location]);

  return (
    <Container open={open} ref={menuRef}>
      <div>
        <div className="menu-wrapper">
          <Logo>
            <img src={logo} alt="Energy Brasil" />
          </Logo>
          <CloseButton onClick={handleSidebar}>
            <ArrowRightIcon />
          </CloseButton>
        </div>
        <Content onClick={handleSidebar}>
          <ul>
            {permissions?.dashboard && (
              <li>
                <Link
                  to="/dashboard"
                  className={PagesRoute.Dashboard === active ? 'active' : ''}
                >
                  <DashboardIcon />
                  <span>Dashboard</span>
                </Link>
              </li>
            )}

            {permissions?.salesFunnel && (
              <li>
                <Link
                  to="/funil-de-vendas"
                  className={PagesRoute.SalesFunnel === active ? 'active' : ''}
                >
                  <KanbanIcon />
                  <span>Volt</span>
                </Link>
              </li>
            )}

            {permissions?.franchises && (
              <li>
                <Link
                  to="/franquias"
                  className={PagesRoute.Franchises === active ? 'active' : ''}
                >
                  <FranchisersIcon />
                  <span>Franquias</span>
                </Link>
              </li>
            )}

            {permissions?.users && (
              <li>
                <Link
                  to="/usuarios"
                  className={PagesRoute.Users === active ? 'active' : ''}
                >
                  <FiUsers />
                  <span>Usuários sistema</span>
                </Link>
              </li>
            )}

            {permissions?.providers && (
              <li>
                <Link
                  to="/fornecedores"
                  className={PagesRoute.Supplier === active ? 'active' : ''}
                >
                  <ProvidersIcon />
                  <span>Fornecedores</span>
                </Link>
              </li>
            )}

            {permissions?.products && (
              <CollapsibleOptions
                className={
                  collapsibleOpened.includes('Produtos') ? 'active' : ''
                }
                icon={<FiCpu />}
                title="Produtos"
                titleLink="/produtos"
                options={productOptions}
                toggleOpen={(menu: string): void => toggCollapsibleOpened(menu)}
                opened={collapsibleOpened.includes('Produtos')}
              />
            )}

            {permissions?.budgets && (
              <CollapsibleOptions
                className={
                  collapsibleOpened.includes('Orçamentos') ? 'active' : ''
                }
                icon={<ProductsIcon />}
                title="Orçamentos"
                titleLink="/orcamentos"
                options={budgetOptions}
                toggleOpen={(menu: string): void => toggCollapsibleOpened(menu)}
                opened={collapsibleOpened.includes('Orçamentos')}
              />
            )}

            {/* {permissions?.customers && (
              <li>
                <Link
                  to="/clientes"
                  className={PagesRoute.Customers === active ? 'active' : ''}
                >
                  <FiUsers />
                  <span>Clientes</span>
                </Link>
              </li>
            )} */}

            {permissions?.projects && (
              <li>
                <Link
                  to={permissions?.projectsURL}
                  className={PagesRoute.Projects === active ? 'active' : ''}
                >
                  <ProjectsIcon />
                  <span>Engenharia</span>
                </Link>
              </li>
            )}

            {permissions?.management && (
              <li>
                <Link
                  to="/gestao"
                  className={PagesRoute.Management === active ? 'active' : ''}
                >
                  <ManagementIcon />
                  <span>Gestão</span>
                </Link>
              </li>
            )}

            {permissions?.announcements && (
              <li>
                <Link
                  to="/comunicados"
                  className={
                    PagesRoute.Announcements === active ? 'active' : ''
                  }
                >
                  <ChatIcon />
                  <span>Comunicados</span>
                </Link>
              </li>
            )}

            {permissions?.campaigns && (
              <li>
                <Link
                  to="/campanhas"
                  className={PagesRoute.Campaigns === active ? 'active' : ''}
                >
                  <HornIcon />
                  <span>Campanhas</span>
                </Link>
              </li>
            )}

            {permissions?.nps && (
              <li>
                <Link
                  to="/nps"
                  className={PagesRoute.NPS === active ? 'active' : ''}
                >
                  <ThumbsupIcon />
                  <span>NPS</span>
                </Link>
              </li>
            )}

            {permissions?.juridical && (
              <li>
                <Link
                  to="/juridico"
                  className={PagesRoute.Juridical === active ? 'active' : ''}
                >
                  <JuridicalIcon />
                  <span>Jurídico</span>
                </Link>
              </li>
            )}
          </ul>
          <Divider />
          <ul>
            {/* <li>
          <a href="a">
            <img src={settingsIcon} />
            <span>Configurações</span>
          </a>
        </li> */}
            <li>
              <button onClick={() => signOut()}>
                <LogoutIcon />
                <span>Desconectar</span>
              </button>
            </li>
          </ul>
        </Content>
      </div>
    </Container>
  );
};
