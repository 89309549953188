import { yupResolver } from '@hookform/resolvers/yup';
import React, { useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { Breadcrumb, Button, MoneyIcon } from '../../../components';
import Input from '../../../components/input';
import Loading from '../../../components/loading';
import SelectInput, { Option } from '../../../components/select';
import { useAuth } from '../../../contexts/auth';
import productsApi from '../../../services/products';
import { Product } from '../../../types/product';
import { toMoneyFormat } from '../../../utils/toMoneyFormat';
import { ButtonGroup, Container, PageHeader, Wrapper } from '../../styles';
import ConfirmUpdatesModal from './components/ConfirmUpdatesModal';
import { Divisor, FormContainer, FormContent, FormHeader } from './styles';

type Form = {
  name: string;
  supplier: Option;
  promotional: Option;
  description: string;
  power: number;
  category: Option;
  fabricMaterial: Option;
  solarPlatesNumber: number;
  voltage: Option;
  phase: Option;
  inverterType: Option;
  inverterLv: Option;
  weight: number;
  metreage: number;
  supplierCode: string;
  deliveryTime: string;
};

type Filters = {
  category: string[];
  fabricMaterial: string[];
  phase: string[];
  supplier: string[];
  voltage: string[];
};

enum FiltersLabels {
  category = 'Categoria',
  fabricMaterial = 'Estrutura',
  phase = 'Fase',
  supplier = 'Fornecedores',
  voltage = 'Tensão do inversor',
}

type FilterOption = {
  label: string;
  options: Option[];
};

const inverterLvOptions = [
  {
    label: 'Sim',
    value: true,
  },
  {
    label: 'Não',
    value: false,
  },
];

const inverterOptions = [
  {
    label: 'Micro inversor',
    value: 'Micro inversor',
  },
  {
    label: 'Inversor',
    value: 'Inversor',
  },
];

const promotionalOptions = [
  {
    label: 'Sim',
    value: true,
  },
  {
    label: 'Não',
    value: false,
  },
];

const voltageOptions = [
  {
    label: '127/220V',
    value: '220',
  },
  {
    label: '220/380V',
    value: '380',
  },
];

const schema = yup.object().shape({
  name: yup.string().required('Campo obrigatório'),
  supplier: yup.object().required('Campo obrigatório'),
  promotional: yup.object().required('Campo obrigatório'),
  description: yup.string().required('Campo obrigatório'),
  power: yup.number().required('Campo obrigatório'),
  category: yup.object().required('Campo obrigatório'),
  fabricMaterial: yup.object().required('Campo obrigatório'),
  solarPlatesNumber: yup.number().required('Campo obrigatório'),
  voltage: yup.object().required('Campo obrigatório'),
  phase: yup.object().required('Campo obrigatório'),
  inverterType: yup.object().required('Campo obrigatório'),
  inverterLv: yup.object().required('Campo obrigatório'),
  weight: yup.number().required('Campo obrigatório'),
  metreage: yup.number().required('Campo obrigatório'),
  supplierCode: yup.string().required('Campo obrigatório'),
  deliveryTime: yup.string().required('Campo obrigatório'),
});

const EditKitSettedPage: React.FC = () => {
  const { selectedFranchise: franchiseId } = useAuth();

  const history = useHistory();

  const { id } = useParams<{ id: string }>();

  const [loading, setLoading] = useState(false);

  const [filters, setFilters] = useState<FilterOption[]>([]);

  const [kit, setKit] = useState({} as Product);

  if (!id) {
    return <Redirect to={'/produtos/lista/kit-pronto'} />;
  }

  const links = [
    {
      id: 1,
      title: 'Produtos',
      link: '/produtos',
      active: false,
    },
    {
      id: 2,
      title: 'Lista de produtos',
      link: `/produtos/lista/`,
      active: false,
    },
    {
      id: 3,
      title: 'Kits Prontos',
      link: '/produtos/lista/kit-pronto',
      active: false,
    },
    {
      id: 4,
      title: 'Edição de kit',
      link: `/produtos/lista/kit-pronto/${id}/editar`,
      active: true,
    },
  ];

  const [openConfirmModal, setOpenConfirmModal] = useState(false);

  const {
    register,
    handleSubmit,
    control,
    reset,
    watch,
    setValue,
    formState: { errors },
  } = useForm<Form>({
    resolver: yupResolver(schema),
  });

  const weight = watch('weight');
  const metreage = watch('metreage');

  const [updatedKit, setUpdatedKit] = useState({} as any);

  const getFilters = useCallback(async () => {
    try {
      const { data } = await productsApi.get<Filters>('/products/filters/', {
        params: {
          fields: 'category,supplier,fabricMaterial,phase,voltage',
          franchiseId: franchiseId || undefined,
        },
      });

      return data;
    } catch (error) {
      toast.error('Não foi possível buscar os filtros.');
    }
  }, []);

  const handleGetKit = async () => {
    const { data } = await productsApi.get(`/products/${id}`);

    return data;
  };

  const handleModal = () => {
    setOpenConfirmModal((state) => !state);
  };

  const onSubmit = (data: Form) => {
    const normalizedData = {
      ...data,
      name: data.name,
      power: String(data.power),
      metreage: Number(data.metreage),
      category: String(data.category.value),
      fabricMaterial: String(data.fabricMaterial.value),
      inverterLv: Boolean(data.inverterLv.value),
      inverterType: String(data.inverterType.value),
      phase: String(data.phase.value),
      promotional: Boolean(data.promotional.value),
      supplier: String(data.supplier.value),
      voltage: Number(data.voltage.value),
      originalPrice: kit.originalPrice,
      discount: kit.discount,
      rebate: kit.rebate,
      type: kit.type,
      productType: kit.productType,
    };

    setUpdatedKit(normalizedData);

    handleModal();
  };

  const handleUpdateKit = async () => {
    try {
      await productsApi.put(`/products/${id}`, updatedKit);

      toast.success('Kit alterado com sucesso!');

      history.replace('/produtos/lista/kit-pronto');
    } catch (error) {
      toast.error('Não foi possível alterar os dados');
    } finally {
      handleModal();
    }
  };

  const normalizeFilters = (options: Filters) => {
    const formatLabel = {
      category(value: string | number) {
        return `${value}W`;
      },
    };

    return Object.entries(options)
      .map(([field, value]) => {
        const label = FiltersLabels[field as keyof typeof FiltersLabels];

        const options = value
          .map((item) => ({
            label: formatLabel[field as keyof typeof formatLabel]
              ? formatLabel[field as keyof typeof formatLabel](item)
              : item,
            value: item,
          }))
          .sort((a, b) => {
            const labelA = String(a.label);
            const labelB = String(b.label);

            return labelA.localeCompare(labelB);
          });

        return {
          label,
          options,
        };
      })
      .sort((a, b) => {
        const labelA = String(a.label);
        const labelB = String(b.label);

        return labelA.localeCompare(labelB);
      });
  };

  useEffect(() => {
    setLoading(true);

    Promise.all([getFilters(), handleGetKit()])
      .then(([filters, kit]) => {
        setFilters(normalizeFilters(filters as Filters));
        setKit(kit);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    const hasKit = Object.entries(kit).length > 0;
    const hasFilters = !!filters.length;

    if (hasKit && hasFilters) {
      reset({
        name: kit.name,
        promotional: promotionalOptions.find(
          (option) => option.value === kit.promotional
        ),
        supplier: filters
          .find((option) => option.label === 'Fornecedores')
          ?.options.find((option) => option.value === kit.supplier),
        description: kit.description,
        power: Number(kit.power),
        category: filters
          .find((option) => option.label === 'Categoria')
          ?.options.find((option) => option.value === kit.category),
        fabricMaterial: filters
          .find((option) => option.label === 'Estrutura')
          ?.options.find((option) => option.value === kit.fabricMaterial),
        solarPlatesNumber: kit.solarPlatesNumber,
        voltage: filters
          .find((option) => option.label === 'Tensão')
          ?.options.find((option) => option.value === kit.voltage),
        phase: filters
          .find((option) => option.label === 'Fase')
          ?.options.find((option) => option.value === kit.phase),
        inverterType: inverterOptions.find(
          (option) => option.value === kit.inverterType
        ),
        inverterLv: inverterLvOptions.find(
          (option) => option.value === kit.inverterLv
        ),
        weight: kit.weight,
        metreage: kit.metreage,
        supplierCode: kit.supplierCode,
        deliveryTime: kit.deliveryTime,
      });
    }
  }, [kit, filters]);

  if (loading)
    return (
      <Loading
        style={{
          height: '100%',
          alignItems: 'center',
        }}
        label="Carregando"
      />
    );

  return (
    <Container>
      <Breadcrumb links={links} />
      <Wrapper>
        <PageHeader>
          <span>{'Edição de Kit'}</span>
        </PageHeader>
        <FormContainer onSubmit={handleSubmit(onSubmit)} noValidate>
          <FormHeader>
            <div className="form-header__wrapper">
              <div className="form-header__inputs">
                <div>
                  <h1>Dados Gerais</h1>

                  <Button
                    className="edit-values-btn"
                    type="button"
                    style={{ width: '14rem', height: '3rem', fontWeight: 600 }}
                    text="Editar valores"
                    onClick={() =>
                      history.push(
                        `/produtos/lista/kit-pronto/${id}/editar/desconto-e-rebate`,
                        kit
                      )
                    }
                    icon={<MoneyIcon />}
                  />
                </div>
                <div>
                  <Controller
                    name="supplier"
                    control={control}
                    render={({
                      field: { ref, onChange, value },
                      fieldState: { error },
                    }) => {
                      return (
                        <SelectInput
                          ref={ref}
                          label="Fornecedor"
                          placeholder="Selecione uma opção"
                          style={{ width: '16.125rem' }}
                          options={
                            filters.find(
                              (filter) => filter.label === 'Fornecedores'
                            )?.options
                          }
                          onChange={onChange}
                          value={value}
                          error={error?.message}
                          isDisabled={true}
                        />
                      );
                    }}
                  />

                  <Input
                    label="Nome do kit"
                    placeholder="Descrição do kit"
                    error={errors.name?.message}
                    {...register('name')}
                  />

                  <Controller
                    name="promotional"
                    control={control}
                    render={({
                      field: { ref, onChange, value },
                      fieldState: { error },
                    }) => {
                      return (
                        <SelectInput
                          ref={ref}
                          label="Promocional"
                          placeholder="Selecione uma opção"
                          style={{ width: '16.125rem' }}
                          options={promotionalOptions}
                          onChange={onChange}
                          value={value}
                          error={error?.message}
                        />
                      );
                    }}
                  />
                </div>
              </div>
              <div className="form-header__button">
                <Button
                  type="button"
                  style={{ width: '16rem', height: '3rem', fontWeight: 600 }}
                  text="Editar valores"
                  onClick={() =>
                    history.push(
                      `/produtos/lista/kit-pronto/${id}/editar/desconto-e-rebate`,
                      kit
                    )
                  }
                  icon={<MoneyIcon />}
                />
                <div className="kit-price">
                  <span>Valor</span>
                  <strong>{toMoneyFormat(kit.price)}</strong>
                </div>
              </div>
            </div>
            <Input
              label="Descrição"
              placeholder="Descrição do kit"
              style={{ marginTop: '1rem' }}
              error={errors.description?.message}
              {...register('description')}
            />
            <div className="kit-price mobile">
              <span>Valor</span>
              <strong>{toMoneyFormat(kit.price)}</strong>
            </div>
          </FormHeader>

          <Divisor />

          <FormContent>
            <h1>Detalhes Técnicos</h1>
            <div className="form-content__grids">
              <Input
                type="number"
                label="Potência"
                placeholder="Insira a potência"
                style={{ width: '100%' }}
                error={errors.power?.message}
                {...register('power', { valueAsNumber: true })}
              />

              <Controller
                name="category"
                control={control}
                render={({
                  field: { ref, onChange, value },
                  fieldState: { error },
                }) => {
                  return (
                    <SelectInput
                      ref={ref}
                      label="Categoria"
                      placeholder="Selecione uma opção"
                      style={{ width: '100%' }}
                      options={
                        filters.find((filter) => filter.label === 'Categoria')
                          ?.options
                      }
                      onChange={onChange}
                      value={value}
                      error={error?.message}
                    />
                  );
                }}
              />

              <Controller
                name="fabricMaterial"
                control={control}
                render={({
                  field: { ref, onChange, value },
                  fieldState: { error },
                }) => {
                  return (
                    <SelectInput
                      ref={ref}
                      label="Estrutura"
                      placeholder="Selecione uma opção"
                      style={{ width: '100%' }}
                      options={
                        filters.find((filter) => filter.label === 'Estrutura')
                          ?.options
                      }
                      onChange={onChange}
                      value={value}
                      error={error?.message}
                    />
                  );
                }}
              />

              <Input
                type="number"
                label="Número de Painéis"
                placeholder="Insira o número de painéis"
                style={{ width: '100%' }}
                error={errors.solarPlatesNumber?.message}
                {...register('solarPlatesNumber', { valueAsNumber: true })}
              />

              <Controller
                name="voltage"
                control={control}
                render={({
                  field: { ref, onChange, value },
                  fieldState: { error },
                }) => {
                  return (
                    <SelectInput
                      ref={ref}
                      label="Tensão"
                      placeholder="Selecione uma opção"
                      style={{ width: '100%' }}
                      options={voltageOptions}
                      onChange={onChange}
                      value={value}
                      error={error?.message}
                    />
                  );
                }}
              />

              <Controller
                name="phase"
                control={control}
                render={({
                  field: { ref, onChange, value },
                  fieldState: { error },
                }) => {
                  return (
                    <SelectInput
                      ref={ref}
                      label="Fase"
                      placeholder="Selecione uma opção"
                      style={{ width: '100%' }}
                      options={
                        filters.find((filter) => filter.label === 'Fase')
                          ?.options
                      }
                      onChange={onChange}
                      value={value}
                      error={error?.message}
                    />
                  );
                }}
              />

              <Controller
                name="inverterType"
                control={control}
                render={({
                  field: { ref, onChange, value },
                  fieldState: { error },
                }) => {
                  return (
                    <SelectInput
                      ref={ref}
                      label="Tipo de Inversor"
                      placeholder="Selecione uma opção"
                      style={{ width: '100%' }}
                      options={inverterOptions}
                      onChange={onChange}
                      value={value}
                      error={error?.message}
                    />
                  );
                }}
              />

              <Controller
                name="inverterLv"
                control={control}
                render={({
                  field: { ref, onChange, value },
                  fieldState: { error },
                }) => {
                  return (
                    <SelectInput
                      ref={ref}
                      label="Inversor LV"
                      placeholder="Selecione uma opção"
                      style={{ width: '100%' }}
                      options={inverterLvOptions}
                      onChange={onChange}
                      value={value}
                      error={error?.message}
                    />
                  );
                }}
              />

              <Input
                type="number"
                label="Peso total(kg)"
                placeholder="Insira o peso total"
                style={{ width: '100%' }}
                error={errors.weight?.message}
                iconRight={weight >= 1 && 'kg'}
                {...register('weight', { valueAsNumber: true })}
              />

              <Input
                type="number"
                label="Metragem(m²)"
                placeholder="Insira a metragem"
                style={{ width: '100%' }}
                error={errors.metreage?.message}
                iconRight={metreage >= 1 && 'm²'}
                {...register('metreage', { valueAsNumber: true })}
              />

              <Input
                label="Código"
                placeholder="Insira o código"
                style={{ width: '100%' }}
                error={errors.supplierCode?.message}
                {...register('supplierCode', {
                  onChange: (e) =>
                    setValue('supplierCode', e.target.value.toUpperCase()),
                })}
              />

              <Input
                label="Prazo de entrega"
                placeholder="Insira o prazo de entrega"
                style={{ width: '100%' }}
                error={errors.deliveryTime?.message}
                {...register('deliveryTime')}
              />
            </div>
          </FormContent>

          <ButtonGroup style={{ marginBottom: '2rem' }}>
            <Button
              type="button"
              text="Cancelar"
              typeStyle="default"
              backgroundHoverColor="#C9CBCF"
              onClick={() => history.replace('/produtos/lista/kit-pronto')}
            />

            <Button type="submit" text="Salvar" />
          </ButtonGroup>
        </FormContainer>

        <ConfirmUpdatesModal
          isOpen={openConfirmModal}
          onConfirm={handleUpdateKit}
          onRequestClose={() => setOpenConfirmModal(false)}
        >
          <h1>Alteração de dados</h1>
          <p>Tem certeza que os dados inseridos estão corretos?</p>
          <p>
            Essa ação não poderá ser desfeita e os dados atualizados serão
            encaminhados para os fraqueados.
          </p>
        </ConfirmUpdatesModal>
      </Wrapper>
    </Container>
  );
};

export default EditKitSettedPage;
