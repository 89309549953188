import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  gap: 1.5rem;

  position: fixed;
  bottom: 1rem;
  left: 19.75rem;

  @media (max-width: 992px) {
    & > button:nth-child(1) {
      order: 3;
    }
    & > button:nth-child(2) {
      order: 1;
    }
  }

  @media (max-width: 520px) {
    left: 5%;
  }
`;

export const Notification = styled.span`
  width: 1.25rem;
  height: 1.25rem;

  position: absolute;
  top: -0.175rem;
  right: -0.125rem;

  display: flex;
  justify-content: center;
  align-items: center;

  font-family: 'DunbarTallRegular';
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 150%;
  letter-spacing: 0.02em;
  color: #ffffff;

  background-color: #e01919;
  border-radius: 50%;
`;
