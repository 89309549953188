export const normalize = (value: string) => {
  const regexPrice = /R|\$| |\./gm;

  const valueReplaced =
    Number(value?.replace(regexPrice, '').replace(',', '.')).toFixed(2) ?? 0;

  const normalizedValue = Number(valueReplaced);

  if (isNaN(normalizedValue)) {
    return 0;
  }

  return normalizedValue;
};
