import styled, { css } from 'styled-components';

export const Overlay = styled.div`
  position: fixed;
  inset: 0;
  z-index: 999999;

  background-color: rgba(19, 20, 21, 0.6);
`;

type ContainerProps = {
  open: boolean;
};

export const Container = styled.div<ContainerProps>`
  width: 25rem;
  height: 100vh;

  display: flex;
  flex-direction: column;
  flex: 1;

  background-color: #fff;
  border-left: 1px solid #c9cbcf;
  box-shadow: 1px 4px 6px rgba(0, 0, 0, 0.1), 1px 8px 15px rgba(0, 0, 0, 0.1);

  right: -6.25rem;
  position: fixed;
  bottom: 0;
  top: 0;
  transition: -webkit-transform 0.5s ease;
  transition: transform 0.5s ease;
  -webkit-transform: translateX(110%);
  -ms-transform: translateX(110%);
  transform: translateX(110%);
  z-index: 999999;

  user-select: none;
  pointer-events: none;

  ${(props) =>
    props.open &&
    css`
      right: 0;
      -webkit-transform: translateX(0);
      -ms-transform: translateX(0);
      transform: translateX(0);

      user-select: auto;
      pointer-events: auto;
    `}

  @media (max-width: 520px) {
    width: 18rem;
  }
`;

export const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;

  border-bottom: 1px solid #c9cbcf;

  height: 6rem;

  padding: 1.75rem 1rem;

  & > span {
    color: #000;

    font-size: 1.5rem;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
  }
`;

export const Content = styled.main`
  padding: 2rem 1.5rem;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  & > span {
    color: #131415;

    font-size: 1.125rem;
    font-family: 'DunbarTallBold', sans-serif;
    font-weight: 500;
  }

  & > button {
    width: 100%;
  }
`;

type ButtonProps = {
  isHidden?: boolean;
};

export const Button = styled.button.attrs((props) => ({
  ...props,
  type: 'button',
}))<ButtonProps>`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 2.5rem;
  height: 2.5rem;

  border-radius: 200px;

  font-size: 1rem;

  background: #f5f5f5;

  visibility: ${(props) => (props.isHidden ? 'hidden' : 'visible')};
  pointer-events: ${(props) => (props.isHidden ? 'none' : 'auto')};
`;

export const Tags = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

export const TagsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  & > span {
    color: #131415;
    font-size: 1rem;
    font-family: 'DunbarTextRegular', sans-serif;
  }
`;

type TagProps = {
  bgColor: string;
};

export const Tag = styled.div<TagProps>`
  display: flex;
  align-items: center;
  gap: 0.75rem;

  & > span {
    display: flex;
    align-items: center;
    padding: 0.5rem;
    border-radius: 4px;
    background: ${(props) => props.bgColor};

    color: #131415;
    font-size: 0.75rem;
    font-family: 'DunbarTextRegular', sans-serif;

    width: 100%;
    height: 2rem;

    cursor: pointer;
  }

  & > div {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.25rem;

    & > button {
      display: flex;
      justify-content: center;
      align-items: center;

      width: 2rem;
      height: 2rem;

      border-radius: 4px;

      background: #fff;
      box-shadow: 0px 1px 0px 0px #ccc inset, 0px -1px 0px 0px #ccc inset,
        1px 0px 0px 0px #ccc inset, -1px 0px 0px 0px #ccc inset;

      &:hover {
        filter: brightness(0.95);
      }
    }
  }
`;

export const AddTagButton = styled.button.attrs((props) => {
  return {
    ...props,
    type: 'button',
  };
})`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.25rem;

  width: 100%;
  height: 2.5rem;

  border-radius: 8px;
  background: #c9cbcf;

  color: #27282b;
  font-size: 1rem;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;

  &:hover {
    filter: brightness(0.95);
  }
`;

export const Colors = styled.div`
  display: flex;
  flex-direction: column;

  gap: 0.5rem;

  & > span {
    font-size: 1rem;
    font-weight: 500;
    color: #27282b;
  }

  & > span:last-child {
    color: #e01919;

    font-size: 0.875rem;
  }

  & > div {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(4rem, 1fr));
    row-gap: 0.5rem;
  }
`;

type ColorProps = {
  bgColor: string;
  isSelected: boolean;
  gridColumn?: number;
};

export const Color = styled.button.attrs((props) => {
  return {
    ...props,
    type: 'button',
  };
})<ColorProps>`
  width: 4rem;
  height: 2.375rem;

  border-radius: 4px;
  background: ${(props) => props.bgColor};

  grid-column: ${(props) => props.gridColumn};

  ${(props) =>
    props.isSelected &&
    css`
      border: 1px solid #fff;
      outline: 1px solid #00299b;
    `};
`;

export const ColorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;
