import { GroupBase, SelectInstance, StylesConfig } from 'react-select';
import { Container } from './styles';
import React, { CSSProperties, ForwardedRef, useState } from 'react';
import AsyncSelect, { AsyncProps } from 'react-select/async';

export type Option = {
  value: string | boolean | number;
  label: string;
};

interface SelectAsyncProps
  extends AsyncProps<Option, boolean, GroupBase<Option>> {
  label: string;
  placeholder: string;
  options?: Option[];
  isSearchable?: boolean;
  isDisabled?: boolean;
  required?: boolean;
  error?: string;
  defaultValue?: Option;
  style?: CSSProperties;
  getOptions: (inputValue: string) => Promise<Option[]>;
  className?: string;
  width?: string;
  maxHeight?: string;
}

const SelectAsync = React.forwardRef(
  (
    props: SelectAsyncProps,
    myRef: ForwardedRef<SelectInstance<Option, boolean, GroupBase<Option>>>
  ) => {
    const {
      label,
      placeholder,
      isDisabled = false,
      isSearchable = true,
      required = false,
      error,
      defaultValue,
      value,
      style,
      getOptions,
      className,
      width = '100%',
      maxHeight = '9.25rem',
      ...rest
    } = props;

    const customStyles: StylesConfig<Option, boolean, GroupBase<Option>> = {
      placeholder: (provided, state) => ({
        ...provided,
        color: state.isDisabled ? '#9AA3AC' : '#61646b',
        fontWeight: 400,
      }),
      container: (provided) => ({
        ...provided,
        height: '3rem',
      }),
      control: (provided, state) => ({
        ...provided,
        height: '3rem',
        backgroundColor: state.isDisabled ? '#F4F5F6' : '#fff',
        fontSize: '1rem',
        color: state.isDisabled ? '#9AA3AC' : '#27282b',
        cursor: 'pointer',
        border: state.isFocused ? 'none' : provided.border,
        borderColor: error ? '#e01919' : '#C9CBCF',
        pointerEvents: state.isDisabled ? 'none' : 'auto',
        userSelect: state.isDisabled ? 'none' : 'auto',
      }),
      indicatorSeparator: () => ({
        display: 'none',
      }),
      menu: (provided) => ({
        ...provided,
        backgroundColor: `#F9FAFB`,
      }),
      option: (provided, state) => ({
        ...provided,
        fontSize: '1rem',
        cursor: 'pointer',
        color: '#292929',
        backgroundColor: state.isFocused ? '#EDF3FD' : '',
        ':hover': {
          backgroundColor: '#005AF9',
          color: '#fff',
        },
        ':selected': {
          backgroundColor: '#EDF3FD',
        },
        ':active': {
          backgroundColor: '#EDF3FD',
        },
      }),
      valueContainer: (provided) => ({
        ...provided,
        paddingLeft: '1rem',
        border: 0,
        flexWrap: 'nowrap',
      }),
      menuList: (provided) => ({
        ...provided,
        maxHeight,
        '::-webkit-scrollbar': {
          width: '4px',
        },
        '::-webkit-scrollbar-track': {
          background: '#f5f5f6',
          borderRadius: '8px',
        },
        '::-webkit-scrollbar-thumb': {
          background: '#a8b0b5',
          borderRadius: '8px',
        },
      }),
    };

    const [menuOpen, setMenuOpen] = useState(false);

    const loadOptions = (inputValue: string) => {
      return new Promise<Option[]>((resolve) => {
        setTimeout(() => {
          resolve(getOptions(inputValue));
        }, 1000);
      });
    };

    return (
      <Container
        className={className}
        menuOpen={menuOpen}
        isDisabled={isDisabled}
        style={style}
        width={width}
      >
        {required ? label + '*' : label}

        <AsyncSelect
          cacheOptions
          defaultOptions
          ref={myRef}
          menuShouldScrollIntoView={true}
          onMenuOpen={() => setMenuOpen(true)}
          onMenuClose={() => setMenuOpen(false)}
          defaultValue={defaultValue}
          placeholder={placeholder}
          styles={customStyles}
          theme={(theme) => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary: '#005AF9',
            },
          })}
          isSearchable={isSearchable}
          isDisabled={isDisabled}
          value={value}
          loadOptions={loadOptions}
          noOptionsMessage={() => 'Nenhuma opção encontrada'}
          loadingMessage={() => 'Carregando...'}
          {...rest}
        />

        {error && (
          <span style={{ color: '#E01919', fontSize: '0.875rem' }}>
            {error}
          </span>
        )}
      </Container>
    );
  }
);

SelectAsync.displayName = 'SelectAsync';

export default SelectAsync;
