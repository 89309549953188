import React, { ReactNode } from 'react';
import { Container } from './styles';

type DefaultPageProps = {
  module: string;
  icon: ReactNode;
};

export const DefaultPage: React.FC<DefaultPageProps> = (props) => {
  const { module, icon } = props;

  return (
    <Container>
      <div>
        {icon}

        <p>
          O módulo <strong>{module}</strong> está em desenvolvimento e será
          lançado em breve, aguarde!
        </p>
      </div>
    </Container>
  );
};
