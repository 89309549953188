import React, { useEffect } from 'react';
import { FiXCircle } from 'react-icons/fi';
import { Option, TagSelect } from './tag-select';
import { Container, Tag, Wrapper } from './styles';
import { useSalesFunnel } from '../../../../../contexts/sales-funnel';
import { Lead } from '../../../../../contexts/sales-funnel/types';

interface TagsProps {
  lead: Lead | null;
}

export const Tags: React.FC<TagsProps> = (props) => {
  const { lead } = props;

  const {
    tags,
    tagsSelected,
    handleDefaultSelectedTags,
    handleSelectedTag,
    handleUnselectTag,
    handleTagModal,
  } = useSalesFunnel();

  const tagsOptions = tags.map((tag) => ({
    id: tag.id,
    label: tag.name,
    value: tag.color,
  }));

  useEffect(() => {
    if (lead) {
      handleDefaultSelectedTags(lead);
    }
  }, [lead]);

  return (
    <Container>
      <TagSelect
        options={tagsOptions}
        onChange={(option) => {
          if (option && lead) {
            handleSelectedTag(option as Option, lead.id);
          }
        }}
        value={tagsSelected}
        onNewTag={() => handleTagModal('DEFAULT_STEP', null)}
      />

      <Wrapper>
        {tagsSelected.map((tag) => (
          <Tag
            key={tag.id}
            bgColor={tag.value}
            onClick={async () => await handleUnselectTag(tag.id)}
          >
            <span>{tag.label}</span>
            <FiXCircle />
          </Tag>
        ))}
      </Wrapper>
    </Container>
  );
};
