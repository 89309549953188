import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { FiMinusCircle, FiX } from 'react-icons/fi';
import { Props } from 'react-modal';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { ShapeModal } from '../../..';
import { useSalesFunnel } from '../../../../../contexts/sales-funnel';
import SelectInput, { Option } from '../../../../select';
import Textarea from '../../../../textarea';
import { Button } from '../../components/button';
import { Buttons, Container, Content, Header, Wrapper } from './styles';

type InnerLostModalProps = {
  onCancel: () => void;
  onConfirm: () => void;
  leadId: string;
} & Props;

type Form = {
  reason: Option;
  description: string;
};

const schema = yup.object().shape({
  reason: yup.object().required('Campo obrigatório'),
  description: yup.string().required('Campo obrigatório'),
});

const options = [
  {
    label: 'Fechou com a concorrência',
    value: '1',
  },
  {
    label: 'Sem interesse na compra',
    value: '2',
  },
  {
    label: 'Crédito negado',
    value: '3',
  },
  {
    label: 'Prazo de entrega',
    value: '4',
  },
  {
    label: 'Produto não atende a necessidade do cliente',
    value: '5',
  },
  {
    label: 'Preço',
    value: '6',
  },
  {
    label: 'Cliente fora do perfil da empresa',
    value: '7',
  },
  {
    label: 'Dificuldade em contatar o cliente',
    value: '8',
  },
  {
    label: 'Despreparo dos vendedores',
    value: '9',
  },
  {
    label: 'Outros',
    value: '10',
  },
];

export const InnerLostModal: React.FC<InnerLostModalProps> = (props) => {
  const { leadId, onCancel, onConfirm, ...rest } = props;

  const { getLeads, updateToLost } = useSalesFunnel();

  const {
    control,
    formState: { errors },
    clearErrors,
    reset,
    handleSubmit,
    register,
  } = useForm<Form>({
    resolver: yupResolver(schema),
  });

  const closeModal = () => {
    onCancel();

    clearErrors();

    reset();
  };

  const onSubmit = async (data: Form) => {
    try {
      const normalizedData = {
        ...data,
        reason: data.reason.label,
      };

      await updateToLost(leadId, normalizedData);

      await getLeads();

      clearErrors();

      reset();

      onConfirm();

      toast.success('Lead atualizado com sucesso');
    } catch (error) {
      toast.success('Desculpe, não foi possível atualizar o lead.');
    }
  };

  return (
    <ShapeModal
      padding="0"
      maxWidth="38.5rem"
      onRequestClose={closeModal}
      {...rest}
    >
      <Container>
        <Header>
          <h1>Orçamento perdido</h1>

          <button onClick={closeModal} type="button">
            <FiX />
          </button>
        </Header>

        <Content>
          <Wrapper onSubmit={handleSubmit(onSubmit)} noValidate>
            <Controller
              control={control}
              name="reason"
              render={({
                field: { onChange, value, ref },
                fieldState: { error },
              }) => {
                return (
                  <SelectInput
                    ref={ref}
                    label="Motivo"
                    isSearchable={false}
                    placeholder="Selecione uma opção"
                    options={options}
                    required
                    error={error?.message}
                    onChange={onChange}
                    value={value}
                  />
                );
              }}
            />

            <Textarea
              required
              label="Descrição"
              placeholder="Informe o motivo da recusa deste orçamento"
              height="8rem"
              {...register('description')}
              error={errors.description?.message}
            />

            <Buttons>
              <Button variant="red" type="submit" maxWidth="16.125rem">
                <FiMinusCircle />
                Cancelar orçamento
              </Button>
            </Buttons>
          </Wrapper>
        </Content>
      </Container>
    </ShapeModal>
  );
};
