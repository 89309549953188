import React from 'react';
import { ShapeModal } from '../..';
import { Buttons, Container, Content, Header, Wrapper } from './styles';
import { FiX } from 'react-icons/fi';
import { Button } from '../../../button';
import SelectAsync, { Option } from '../../../select-async';
import productsApi from '../../../../services/products';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useAuth } from '../../../../contexts/auth';
import { Props } from 'react-modal';

type Form = {
  supplier: Option;
  fabricMaterial: Option[];
};

type Provider = {
  id: string;
  name: string;
  personName: string;
  website: string;
  email: string;
  phone: string;
  zipcode: string;
  state: string;
  city: string;
  address: string;
  district: string;
  addressNumber: string;
  complement: string;
  solarGenerationLost: string;
  deleted: boolean;
  createdAt: string;
  updatedAt: string;
};

type SelectKitsModalProps = {
  isOpen: boolean;
  onCancel: () => void;
  onConfirm: (query: string) => void;
} & Props;

const schema = yup.object().shape({
  supplier: yup.object().nullable().required('Campo obrigatório'),
  fabricMaterial: yup.array(yup.object()).required('Campo obrigatório'),
});

export const SelectKitsModal: React.FC<SelectKitsModalProps> = (props) => {
  const { onCancel, onConfirm, ...rest } = props;

  const { selectedFranchise: franchiseId } = useAuth();

  const { control, handleSubmit } = useForm<Form>({
    resolver: yupResolver(schema),
  });

  const getSuppliers = async (inputValue: string): Promise<Option[]> => {
    const { data } = await productsApi.get<{ content: Provider[] }>(
      '/products/suppliers/search'
    );

    const options = data.content
      .map((supplier) => ({ label: supplier.name, value: supplier.id }))
      .filter((option) =>
        option.label.toLowerCase().includes(inputValue.toLowerCase())
      );

    return options;
  };

  const getStructures = async (inputValue: string): Promise<Option[]> => {
    const { data } = await productsApi.get<{ fabricMaterial: string[] }>(
      '/products/filters/',
      {
        params: {
          fields: 'fabricMaterial',
          franchiseId,
        },
      }
    );

    const options = data.fabricMaterial
      .map((structure) => ({ label: structure, value: structure }))
      .filter((option) =>
        option.label.toLowerCase().includes(inputValue.toLowerCase())
      );

    return options;
  };

  const onSubmit = async (formData: Form) => {
    const filters = {
      fabricMaterial:
        formData.fabricMaterial.map((item) => item.value).join(',') ?? '',
      supplier: formData.supplier.label ?? '',
    };

    const queryParams = new URLSearchParams(filters).toString();

    onConfirm(queryParams);
  };

  return (
    <ShapeModal
      padding="0"
      maxWidth="26rem"
      onRequestClose={onCancel}
      {...rest}
    >
      <Container>
        <Header>
          <h1>Selecionar kits</h1>

          <button onClick={onCancel} type="button">
            <FiX />
          </button>
        </Header>

        <Content onSubmit={handleSubmit(onSubmit)}>
          <Wrapper>
            <p>
              Informe qual o fornecedor e para quais estruturas você deseja
              selecionar os kits
            </p>

            <Controller
              control={control}
              name="supplier"
              render={({ field: { ...rest }, fieldState: { error } }) => {
                return (
                  <SelectAsync
                    label="Fornecedor"
                    placeholder="Selecione uma opção"
                    getOptions={getSuppliers}
                    maxHeight="7.5rem"
                    error={error?.message}
                    {...rest}
                  />
                );
              }}
            />

            <Controller
              control={control}
              name="fabricMaterial"
              render={({ field: { ...rest }, fieldState: { error } }) => {
                return (
                  <SelectAsync
                    isMulti
                    label="Estruturas"
                    placeholder="Selecione uma opção"
                    getOptions={getStructures}
                    maxHeight="7.5rem"
                    error={error?.message}
                    {...rest}
                  />
                );
              }}
            />
          </Wrapper>

          <Buttons>
            <Button
              typeStyle="default"
              text="Cancelar"
              backgroundHoverColor="#C9CBCF"
              onClick={onCancel}
            />
            <Button type="submit" text="Avançar" />
          </Buttons>
        </Content>
      </Container>
    </ShapeModal>
  );
};
