/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import ReactSelect, { StylesConfig } from 'react-select';
import { Container, Error } from './styles';

type OptionsType = {
  label: string;
  value: string;
};

type SelectProps = {
  onChange: (e: any) => void;
  // handleChange?: (e: any) => void;
  name: string;
  placeholder?: string;
  options: OptionsType[];
  error?: string;
  value?: any;
  control: any;
  isClearable?: boolean;
};

const Select: React.FC<SelectProps> = ({
  name,
  placeholder,
  options,
  error,
  control,
  value,
  // handleChange,
  onChange,
  isClearable,
  ...rest
}) => {
  const customStyles: StylesConfig = {
    placeholder: (provided, state) => ({
      ...provided,
      color: state.isDisabled ? '#9AA3AC' : '#5E84A1',
      fontWeight: 400,
    }),
    container: (provided) => ({
      ...provided,
      height: '3rem',
    }),
    control: (provided, state) => ({
      ...provided,
      height: '3rem',
      backgroundColor: state.isDisabled ? '#F4F5F6' : '#fff',
      fontSize: '1rem',
      color: state.isDisabled ? '#9AA3AC' : '#4B6A81',
      cursor: 'pointer',
      border: state.isFocused ? 'none' : provided.border,
      borderColor: error ? '#e01919' : '#C9CBCF',
      pointerEvents: state.isDisabled ? 'none' : 'auto',
      userSelect: state.isDisabled ? 'none' : 'auto',
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: `#F9FAFB`,
    }),
    option: (provided, state) => ({
      ...provided,
      fontSize: '1rem',
      cursor: 'pointer',
      color: '#292929',
      backgroundColor: state.isFocused ? '#EDF3FD' : '',
      ':hover': {
        backgroundColor: '#005AF9',
        color: '#fff',
      },
      ':selected': {
        backgroundColor: '#EDF3FD',
      },
      ':active': {
        backgroundColor: '#EDF3FD',
      },
    }),
    valueContainer: (provided) => ({
      ...provided,
      paddingLeft: '1rem',
      border: 0,
    }),
    menuList: (provided) => ({
      ...provided,
      maxHeight: '148px',
      '::-webkit-scrollbar': {
        width: '4px',
      },
      '::-webkit-scrollbar-track': {
        background: '#f5f5f6',
        borderRadius: '8px',
      },
      '::-webkit-scrollbar-thumb': {
        background: '#a8b0b5',
        borderRadius: '8px',
      },
    }),
  };

  return (
    <Container>
      <ReactSelect
        {...rest}
        name={name}
        placeholder={placeholder}
        options={options}
        styles={customStyles}
        onChange={(e: any) => {
          if (!e) {
            return onChange('');
          }

          onChange(e.value);
        }}
        value={options.filter((option) => option?.value.includes(value))}
        defaultValue={options.filter((option) => option.value?.includes(value))}
        noOptionsMessage={() => 'Nenhuma franquia foi encontrada'}
        isClearable={isClearable}
      />

      {error && <Error>{error}</Error>}
    </Container>
  );
};

export default Select;
