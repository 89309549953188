import axios from 'axios';
import { axiosConfig } from './config';

const cepApi = axios.create({
  baseURL: process.env.REACT_APP_PROJECT_MICROSERVICE_URL,
});

axiosConfig(cepApi);

export default cepApi;
