import React, { useEffect, useMemo, useState } from 'react';
import { format } from 'date-fns';
import { useHistory } from 'react-router-dom';

import projectsApi from '../../services/projects';
import { ProjectListType } from './types';
import {
  ActionButton,
  Button,
  Breadcrumb,
  SearchInput,
} from '../../components';
import { useAuth } from '../../contexts/auth';

import { Container, Wrapper, PageHeader } from './styles';
import { TableHeader } from '../styles';

import {
  BREADCRUMB_PAGES,
  getBreadCrumbObject,
} from '../../helper/BreadcrumbRole';

import ConcludeModal from './engineering/components/ConcludeModal';
import InspectionResult from './engineering/components/InspectionResult';

import {
  isConcludeAllowed,
  isDownloadAllowed,
  isEditAllowed,
  isIspectionResultAllowed,
  isRequestInspectionAllowed,
} from './permission';

import { StatusSelect } from '../../components/status-select';
import Table, { ColumnStructure, TableData } from '../../components/table';
import InnerActions from '../../components/table/innerActions';
import { FiDownload, FiEdit, FiEye, FiList } from 'react-icons/fi';

type TableItems = {
  id: string;
  client: string;
  localization: string;
  creationDate: string;
  deliveryDate: string;
  status: string;
  actions: JSX.Element;
};

const ProjectPage: React.FC = () => {
  const { selectedFranchise, user } = useAuth();
  const history = useHistory();
  const [projects, setProjects] = useState<ProjectListType>({
    content: [],
    pagination: {
      currentPage: 0,
      lastPage: 0,
      limit: 0,
      total: 0,
    },
  });
  const [loading, setLoading] = useState<boolean>(true);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [name, setName] = useState<string>('');
  const [status, setStatus] = useState<string>('');
  const links = getBreadCrumbObject(user.role, BREADCRUMB_PAGES.PROJETOS);

  useEffect(() => {
    if (loading) {
      projectsApi
        .get<ProjectListType>(
          `/api/projects?name=${name}&status=${status}&page=${page}&limit=${limit}&franchise=${selectedFranchise}`
        )
        .then((res) => {
          setProjects(res.data);
          setLoading(false);
        });
    }
  }, [name, page, limit, selectedFranchise, loading]);

  const handlePageChange = (page: number): void => {
    setLoading(true);
    setPage(page);
  };

  const handleLimitChange = (limit: number): void => {
    setLoading(true);
    setLimit(limit);
  };

  const handleSearchChange = (search: string): void => {
    setLoading(true);
    setName(search);
  };

  const handleStatusChange = (search: string): void => {
    setLoading(true);
    setStatus(search);
  };

  const tableColumns: ColumnStructure[] = [
    {
      id: 'client',
      label: 'Cliente',
      onClick: () => {},
    },
    {
      id: 'localization',
      label: 'Localização',
      onClick: () => {},
    },
    {
      id: 'creationDate',
      label: 'Data de criação',
      onClick: () => {},
    },
    {
      id: 'deliveryDate',
      label: 'Data de entrega',
      onClick: () => {},
    },
    {
      id: 'status',
      label: 'Status',
      onClick: () => {},
    },
    {
      id: 'actions',
      label: 'Ações',
      type: 'actionCell',
    },
  ];

  const tableItems: TableData<TableItems>[] = useMemo(() => {
    return projects.content.map((project) => {
      return {
        id: project.id as string,
        client: project.ucOwner,
        localization: project.ucCity + ' - ' + project.ucState,
        creationDate: project.createdAt
          ? format(new Date(project.createdAt), 'dd/MM/yyyy')
          : '',
        deliveryDate: '',
        status: project.status,
        actions: (
          <InnerActions>
            {isEditAllowed(project) ? (
              <ActionButton
                tooltip="Editar projeto"
                onClick={() => history.push(`/projeto/${project.id}/editar`)}
              >
                <FiEdit />
              </ActionButton>
            ) : (
              <></>
            )}
            {isDownloadAllowed(project) ? (
              <ActionButton
                tooltip="Download do projeto"
                onClick={() => history.push(`/projetos/${project.id}/download`)}
              >
                <FiDownload />
              </ActionButton>
            ) : (
              <></>
            )}
            <ActionButton
              tooltip="Visualizar"
              onClick={() => history.push(`/projetos/${project.id}/extras`)}
            >
              <FiEye />
            </ActionButton>

            {isRequestInspectionAllowed(project) ? (
              <ActionButton
                tooltip="Solicitar vistoria"
                onClick={() =>
                  history.push(`/projetos/${project.id}/solicitar-vistoria`)
                }
              >
                <FiList />
              </ActionButton>
            ) : (
              <></>
            )}

            {isIspectionResultAllowed(project) ? (
              <InspectionResult
                id={project.id}
                handleOnClick={() => {
                  setLoading(true);
                }}
              />
            ) : (
              <></>
            )}

            {isConcludeAllowed(project) ? (
              <ConcludeModal
                id={project.id}
                handleOnClick={() => {
                  setLoading(true);
                }}
              />
            ) : (
              <></>
            )}
          </InnerActions>
        ),
      };
    });
  }, [projects]);

  return (
    <Container>
      <Breadcrumb links={links} />
      <Wrapper>
        <PageHeader>
          <span>Projetos</span>
        </PageHeader>

        <Table
          items={tableItems}
          columns={tableColumns}
          isLoading={loading}
          header={
            <TableHeader>
              <div className="filter-container">
                <SearchInput
                  name="search"
                  placeholder="Digite aqui para pesquisar"
                  onTextChange={handleSearchChange}
                />
                <div className="status-select">
                  <StatusSelect onChange={handleStatusChange} />
                </div>
              </div>
              <Button
                text="Novo projeto"
                icon="add"
                onClick={() => history.push('/projeto/novo')}
              />
            </TableHeader>
          }
          placeholder="Nenhum projeto encontrado"
          pagination={projects.pagination}
          onPageChange={handlePageChange}
          onLimitChange={handleLimitChange}
        />
      </Wrapper>
    </Container>
  );
};

export default ProjectPage;
