import styled from 'styled-components';

export const Content = styled.main`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  height: 100%;
`;

export const TableHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;

  width: 100%;

  padding: 0 1rem;

  @media (max-width: 992px) {
    flex-direction: column;

    padding: 0;

    & > div {
      max-width: unset;
      width: 100%;

      & > button {
        width: 100%;
      }
    }
  }
`;

export const Profit = styled.span`
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  font-size: 0.875rem;

  color: #1a7605;
`;
