import styled from 'styled-components';

export const Container = styled.button`
  padding: 0.375rem;

  background: #ffffff;

  border-radius: 4px;

  box-shadow: inset -1px 0px 0px #cccccc, inset 1px 0px 0px #cccccc,
    inset 0px -1px 0px #cccccc, inset 0px 1px 0px #cccccc;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 2rem;
  height: 2rem;

  & > svg {
    font-size: 1.25rem;

    color: #61646b;

    &.delete-icon {
      color: #ed5a5a;
    }
  }

  .proposal-loading {
    animation: spin 1s infinite linear;
  }

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;
