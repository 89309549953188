import React from 'react';
import { Container } from './styles';
import { Link } from 'react-router-dom';
import { ArrowIcon } from '../icons';

type BreadcrumbItem = {
  id: number;
  title: string;
  link: string;
  active: boolean;
};

type Props = {
  links: BreadcrumbItem[];
};

export const Breadcrumb: React.FC<Props> = ({ links }) => {
  return (
    <Container>
      {links.map((link, i) => {
        const lastItem = links.length - 2;

        return (
          <div key={link.id}>
            <Link to={link.link} className={link.active ? 'active' : undefined}>
              <span
                className={i !== links.length - 1 && i !== 0 ? 'ellipsed' : ''}
              >
                {link.title}
              </span>
              {links.length !== i + 1 && (
                <ArrowIcon className={lastItem === i ? 'last' : ''} />
              )}
            </Link>
          </div>
        );
      })}
    </Container>
  );
};
