import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';
import { Breadcrumb } from '../../../components';

import { Container, Wrapper, PageHeader } from './../styles';
import { useHistory } from 'react-router-dom';
import projectsApi from '../../../services/projects';
import { Pagination, SearchInput, Status } from '../../../components';
import { ProjectListType, ProjectStatus } from '../types';
import { Actions, TableHeader, TableContainer } from '../../styles';
import { ActionButton } from '../../../components';
import { ProjectType } from '../../../types/project';
import ConcludeModal from '../engineering/components/ConcludeModal';
import InspectionResult from '../engineering/components/InspectionResult';
import {
  DocIcon,
  EyeIcon,
  PayIcon,
  SendIcon,
  UserPositioningIcon,
} from '../../../components/icons';
import { StatusSelect } from '../../../components/status-select';

const ProjectCallCenterPage: React.FC = () => {
  const history = useHistory();
  const [projects, setProjects] = useState<ProjectListType>({
    content: [],
    pagination: {
      currentPage: 0,
      lastPage: 0,
      limit: 0,
      total: 0,
    },
  });
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [name, setName] = useState<string>('');
  const [status, setStatus] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(true);
  const links = [
    {
      id: 1,
      title: 'Projetos',
      link: '/projetos',
      active: false,
    },
    {
      id: 2,
      title: 'Lista de Projetos',
      link: '/projetos',
      active: true,
    },
  ];

  useEffect(() => {
    if (loading) {
      projectsApi
        .get<ProjectListType>(
          `/api/projects?name=${name}&status=${status}&page=${page}&limit=${limit}`
        )
        .then((res) => {
          setProjects(res.data);
          setLoading(false);
        });
    }
  }, [name, status, page, limit, loading]);

  const handlePageChange = (page: number): void => {
    setPage(page);
    setLoading(true);
  };

  const handleLimitChange = (limit: number): void => {
    setLimit(limit);
    setLoading(true);
  };

  const handleSearchChange = (search: string): void => {
    setName(search);
    setLoading(true);
  };

  const isPowerCompanyFeedbackAllowed = (project: ProjectType) => {
    if (
      [
        ProjectStatus.PROJETO_ENVIADO,
        ProjectStatus.PROJETO_APROVADO,
        ProjectStatus.PROJETO_REPROVADO,
        ProjectStatus.AGUARDANDO_ANALISE_VISTORIA,
        ProjectStatus.PROJETO_AGUARDANDO_CONCESSIONARIO,
        ProjectStatus.VISTORIA_APROVADA,
        ProjectStatus.VISTORIA_REPROVADA,
        ProjectStatus.PROJETO_CONCLUIDO,
      ].includes(project.status)
    ) {
      return true;
    }

    return false;
  };

  const isDoInspectionAllowed = (project: ProjectType) => {
    if (
      [
        ProjectStatus.AGUARDANDO_ANALISE_VISTORIA,
        ProjectStatus.VISTORIA_APROVADA,
        ProjectStatus.VISTORIA_REPROVADA,
        ProjectStatus.DOCUMENTACAO_REPROVADA,
        ProjectStatus.VISTORIA_SOLICITADA,
      ].includes(project.status)
    ) {
      return true;
    }

    return false;
  };

  const isIspectionResultAllowed = (project: ProjectType) => {
    if ([ProjectStatus.VISTORIA_SOLICITADA].includes(project.status)) {
      return true;
    }

    return false;
  };

  const isConcludeAllowed = (project: ProjectType) => {
    if ([ProjectStatus.VISTORIA_APROVADA].includes(project.status)) {
      return true;
    }

    return false;
  };

  const handleStatusChange = (search: string): void => {
    setLoading(true);
    setStatus(search);
  };

  return (
    <Container>
      <Breadcrumb links={links} />
      <Wrapper>
        <PageHeader>
          <span>Central de Atendimento</span>
        </PageHeader>

        <TableContainer>
          <TableHeader>
            <div className="filter-container">
              <SearchInput
                name="search"
                placeholder="Digite aqui para pesquisar"
                onTextChange={handleSearchChange}
              />
              <div className="status-select">
                <StatusSelect onChange={handleStatusChange} />
              </div>
            </div>
            {loading && 'Carregando'}
          </TableHeader>
          <div className="table-wrapper">
            <table>
              <thead>
                <tr>
                  <th>Cliente</th>
                  <th>Status</th>
                  <th>Data de criação</th>
                  <th>Localização</th>
                  <th>Ações</th>
                </tr>
              </thead>
              <tbody>
                {projects?.content.map((project) => (
                  <tr key={project.id}>
                    <td>{project.ucOwner}</td>
                    <td>
                      <Status text={project.status} />
                    </td>
                    <td>
                      {project.createdAt &&
                        format(new Date(project.createdAt), 'dd/MM/yyyy')}
                    </td>
                    <td>
                      {project.ucCity}-{project.ucState}
                    </td>
                    <td>
                      <Actions>
                        <ActionButton
                          tooltip="Visualizar"
                          onClick={() =>
                            history.push(`/projetos/${project.id}`)
                          }
                        >
                          <EyeIcon />
                        </ActionButton>
                        <ActionButton
                          tooltip="Análise Documentação"
                          onClick={() =>
                            history.push(
                              `/projetos/${project.id}/analise-documentacao`
                            )
                          }
                        >
                          <DocIcon />
                        </ActionButton>
                        <ActionButton
                          tooltip="Selecionar engenheiro"
                          onClick={() =>
                            history.push(`/projetos/${project.id}/responsavel`)
                          }
                        >
                          <UserPositioningIcon />
                        </ActionButton>
                        <ActionButton
                          tooltip="Pagamento"
                          onClick={() =>
                            history.push(`/projetos/${project.id}/pagamento`)
                          }
                        >
                          <PayIcon />
                        </ActionButton>

                        {isPowerCompanyFeedbackAllowed(project) && (
                          <ActionButton
                            tooltip="Parecer concessionária"
                            onClick={() =>
                              history.push(
                                `/projetos/engenharia/${project.id}/parecer-concessionaria`
                              )
                            }
                          >
                            <SendIcon />
                          </ActionButton>
                        )}

                        {isDoInspectionAllowed(project) && (
                          <ActionButton
                            tooltip="Realizar vistoria"
                            onClick={() => {
                              history.push(
                                `/projetos/engenharia/${project.id}/vistoria`
                              );
                            }}
                          >
                            <DocIcon />
                          </ActionButton>
                        )}

                        {isIspectionResultAllowed(project) && (
                          <InspectionResult
                            id={project.id}
                            handleOnClick={() => {
                              setLoading(true);
                            }}
                          />
                        )}

                        {isConcludeAllowed(project) && (
                          <ConcludeModal
                            id={project.id}
                            handleOnClick={() => {
                              setLoading(true);
                            }}
                          />
                        )}
                      </Actions>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <Pagination
            lastPage={projects.pagination.lastPage}
            limit={projects.pagination.limit}
            page={projects.pagination.currentPage}
            total={projects.pagination.total}
            onPageChange={handlePageChange}
            onLimitChange={handleLimitChange}
          />
        </TableContainer>
      </Wrapper>
    </Container>
  );
};

export default ProjectCallCenterPage;
