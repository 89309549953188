import styled from 'styled-components';

export const Content = styled.main`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  padding: 1.5rem 0;
`;

export const TableContainer = styled.div`
  background-color: #ffffff;

  margin: 0 -2rem;
  padding: 1.5rem 3rem;

  color: #00299b;

  box-shadow: inset 0px 0px 5px rgba(0, 0, 0, 0.15);

  & > div {
    overflow: auto;

    margin: 0 -3rem;

    padding: 0 3rem;

    background-color: #ffffff;

    &::-webkit-scrollbar {
      width: 20rem;
      height: 0.5rem;

      border-radius: 8px;
    }

    &::-webkit-scrollbar-track {
      background: #ffffff;

      border-radius: 8px;
    }

    &::-webkit-scrollbar-thumb {
      background: #00299b;

      border-radius: 8px;
    }

    & > table {
      width: 100%;
      border-spacing: 0;
      border-collapse: collapse;

      & > thead {
        & > tr {
          & > th {
            text-align: left;

            white-space: nowrap;

            padding: 1rem 1.5rem;

            height: 3.5rem;

            border-bottom: #e0e0e0 solid 1px;

            color: #00299b;
          }
        }
      }

      & > tbody {
        & > tr {
          & > td {
            padding: 1rem 1.5rem;

            height: 6.25rem;

            white-space: nowrap;

            color: #00299b;

            & > strong {
              display: flex;
              flex-direction: column;

              font-weight: 600;
              font-size: 0.875rem;

              color: #00299b;

              white-space: nowrap;

              & > span {
                font-weight: 500;
                font-size: 0.875rem;

                color: #4e6bb9;
              }
            }

            background-color: #fff;
          }
        }
      }
    }
  }
`;

export const TableHeader = styled.header`
  display: flex;
  justify-content: space-between;
  flex: 1;

  margin-bottom: 2rem;

  & > h1 {
    font-weight: 600;
    font-size: 1.25rem;

    color: #00299b;
  }

  & > div {
    display: flex;
    flex-direction: column;

    & > span {
      display: flex;
      justify-content: flex-end;
      flex: 1;
      gap: 0.25rem;

      font-weight: 400;
      font-size: 0.875rem;

      color: #00299b;

      & > strong {
        text-align: end;

        font-weight: 500;
        font-size: 1rem;

        width: 5.375rem;

        &.calculated {
          color: #00299b;
        }

        &.selected {
          color: #25b007;
        }
      }
    }
  }
`;

export const TableFooter = styled.footer``;

export const Actions = styled.div`
  display: flex;

  align-items: center;
  gap: 0.75rem;

  & > button[data-tip='Visualizar'] {
    border: 1px solid #00299b;

    box-shadow: 0;

    & > svg {
      color: #00299b;
    }
  }
`;

export const AdditionalsSummaryWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 1.5rem;
`;

export const AdditionalsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(15.125rem, 1fr));
  grid-template-rows: repeat(auto-fit, minmax(5.5rem, 1fr));
  gap: 1.5rem;

  flex: 0 1 45.5rem;

  & > div:last-child {
    grid-column: 1;
  }

  & > .mobile-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1.5rem;
  }

  @media (max-width: 992px) {
    display: flex;
    flex-direction: column;

    & > div:not(:last-child),
    label {
      width: 100%;
    }
  }
`;

export const SummaryContainer = styled.div`
  max-width: 28rem;
  width: 100%;
  height: fit-content;

  background: #ffffff;

  box-shadow: inset -1px 0px 0px #cccccc, inset 1px 0px 0px #cccccc,
    inset 0px -1px 0px #cccccc, inset 0px 1px 0px #cccccc;
  border-radius: 1rem;

  @media (max-width: 992px) {
    width: 100%;
  }
`;

export const SummaryHeader = styled.header`
  padding: 1.25rem 2rem;

  font-weight: 500;
  font-size: 1.5rem;
  font-family: 'DunbarTallRegular', sans-serif;

  color: #00299b;

  border-bottom: 1px solid #dadada;
`;

export const SummaryContent = styled.main`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  padding: 2rem;

  border-bottom: 1px solid #dadada;

  & > span {
    display: flex;
    justify-content: space-between;
    align-items: center;

    flex: 1;

    font-weight: 400;
    font-size: 1rem;

    color: #4e6bb9;

    & > strong {
      font-weight: 700;
      font-size: 1rem;

      color: #00299b;
    }
  }
`;

export const SummaryTotal = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  padding: 1.25rem 1.875rem;

  & > span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    & > sub {
      font-weight: 700;
      font-size: 2rem;

      vertical-align: -55%;

      margin-left: 0.375rem;

      color: #00299b;
    }
  }

  & > span.total {
    font-weight: 500;
    font-size: 1.5rem;

    color: #4e6bb9;
  }

  & > span.insurance {
    font-weight: 400;
    font-size: 1rem;

    color: #4e6bb9;
  }
`;

export const KitsMobile = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  background-color: #ffffff;

  margin: 0 -5%;
  padding: 2rem 5%;

  & > h1 {
    font-weight: 600;
    font-size: 20px;

    color: #00299b;
  }
`;

export const Kit = styled.div`
  display: flex;
  flex-direction: column;

  border-bottom: #e0e0e0 solid 1px;

  & > header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    & > div {
      display: flex;
      justify-content: center;
      align-items: center;

      flex: 1;

      max-width: 7rem;
      width: 100%;
      height: 3.5rem;

      padding: 0 1rem;

      & > span {
        font-weight: 500;
        font-size: 0.875rem;

        width: 100%;

        color: #00299b;
      }
    }
  }

  & > main {
    display: flex;
    flex-direction: column;

    & > .kit-details {
      display: flex;
      align-items: center;
      padding: 0.5rem;

      height: 4rem;

      & > strong {
        display: flex;
        flex-direction: column;

        font-weight: 600;
        font-size: 0.875rem;

        color: #00299b;

        & > span {
          font-weight: 500;
          font-size: 0.875rem;

          padding: 0;

          color: #4e6bb9;

          text-overflow: ellipsis;
          white-space: nowrap;
          max-width: 18.75rem;
          overflow: hidden;
        }
      }
    }

    & > .kit-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0.5rem;

      height: 7.5rem;

      & > .kit-values {
        flex: 1;

        & > strong {
          display: flex;
          flex-direction: column;

          font-weight: 600;
          font-size: 0.875rem;

          color: #00299b;
        }

        & > span {
          font-weight: 500;
          font-size: 0.875rem;

          padding: 0;

          color: #4e6bb9;
        }
      }

      & > .kit-actions {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1.5rem;

        & > div {
          gap: 0.5rem;
          & > button {
            margin: 0;
          }
        }
      }
    }
  }
`;

export const ValuesData = styled.span`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;

  max-width: 13.375rem;
  width: 100%;

  font-weight: 500;
  color: ${(props) => props.theme.mediumBlue};

  & > span {
    font-size: 0.875rem;
    padding: 0 !important;

    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  & > strong {
    color: ${(props) => props.theme.darkBlue};
  }

  & > span.hr {
    width: 100%;
    height: 1px;
    border-bottom: 1px solid ${(props) => props.theme.mediumBlue};
  }
`;
