import styled from 'styled-components';

export const Container = styled.section`
  display: flex;
  flex-direction: column;

  border: 1px solid #c9cbcf;
  border-radius: 8px;

  background: #ffffff;

  width: 100%;

  overflow-x: auto;

  padding: 1.5rem 1rem;
`;

export const Header = styled.header`
  width: 100%;

  display: flex;
  justify-content: space-between;

  margin-bottom: 3rem;

  & > h1 {
    font-family: 'DunbarTallRegular';
    font-weight: 500;
    font-size: 1.125rem;
    line-height: 1.5rem;
    letter-spacing: 0.03em;
    color: #000;
  }

  & > div {
    max-width: 100%;
    overflow-x: auto;

    display: flex;
    gap: 2rem;

    ::-webkit-scrollbar {
      display: none;
    }
  }

  @media (max-width: 1080px) {
    align-items: center;
    flex-direction: column;
    gap: 1.5rem;
  }
`;

export const Footer = styled.footer`
  & > div {
    display: flex;
    gap: 1rem;
  }

  & > div > p {
    display: flex;
    gap: 0.25rem;
    align-items: center;

    font-family: 'DunbarTextRegular';
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5rem;
    letter-spacing: 0.02em;
    color: #27282b;
  }
`;
