import React, { useEffect, useState } from 'react';
import { FranchiseListType } from '../../pages/franchises/types';
import franchisesApi from '../../services/franchises';

import Select from '../form-elements/select';
import { Container } from './styles';

type OptionsType = {
  label: string;
  value: string;
};

type FranchiseSelectorProps = {
  onChange: (franchise: string) => void;
};

export const FranchiseAll: React.FC<FranchiseSelectorProps> = ({
  onChange,
}) => {
  const [selected, setSelected] = useState<string | undefined | null>();
  const [franchiseOptions, setFranchiseOptions] = useState<OptionsType[]>([]);

  useEffect(() => {
    franchisesApi
      .get<FranchiseListType>('/api/franchises?enabled=true&limit=1000')
      .then((response) => {
        setFranchiseOptions(
          response.data.content.map((franchise) => ({
            label: franchise.name,
            value: franchise.id,
          }))
        );
      });
  }, []);

  const handleSelectChange = (franchise: string) => {
    onChange(franchise);
    setSelected(franchise);
  };

  return (
    <Container>
      <Select
        name="franchise"
        onChange={(event) => {
          handleSelectChange(event);
        }}
        options={franchiseOptions}
        value={franchiseOptions.find((f) => f.value === selected)?.value}
        control={''}
        placeholder="Selecione uma franquia"
      />
    </Container>
  );
};
