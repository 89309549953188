import React from 'react';
import { useDashboard } from '../../../../../../contexts/dashboard';
import { FranchiseData } from '../../../../../../contexts/dashboard/types';
import { numberFormat } from '../../../../../../utils/numberFormat';
import { Square } from '../GeneralData/styles';
import { BarChart } from './BarChart';

import { Container, Footer, Header } from './styles';

type DashboardPropertiesType = {
  franchiseData: FranchiseData;
};

export const MonthlyHistory = () => {
  const { franchiseData } = useDashboard() as DashboardPropertiesType;

  const calcPercentage = (current: number[]) => {
    const result = current
      .map((item, index) => {
        if (index == 0) return;
        return numberFormat((current[index] / current[index - 1] - 1) * 100);
      })
      .filter((value) => value);

    return result as string[];
  };

  return (
    <Container>
      <Header>
        <h1>Histórico mensal de vendas</h1>

        {/* <div>
          <DoughnutChart value={100} title="Meta mensal" />
          <DoughnutChart value={100} title="Meta trimestral" />
          <DoughnutChart value={62} title="Meta anual" />
        </div> */}
      </Header>
      <BarChart
        current={franchiseData.sales.lastYear}
        last={franchiseData.sales.lastYear}
        percentage={calcPercentage(franchiseData.sales.lastYear)}
      />
      <Footer>
        <div>
          <p>
            <Square color="#F95300" />
            Ano anterior
          </p>
          <p>
            <Square color="#00299B" />
            Ano atual
          </p>
        </div>
      </Footer>
    </Container>
  );
};

