import React from 'react';
import { useAuth } from '../../../../../contexts/auth';
import { UserRoles } from '../../../../../types/users';
import { FranchiseTable } from './innerTables/franchise';
import { ConsultantTable } from './innerTables/consultant';
import { Container, Header } from './styles';

type Props = {
  handleLoading: (isLoading: boolean) => void;
};

export const Orders: React.FC<Props> = (props) => {
  const { handleLoading } = props;

  const { user } = useAuth();

  return (
    <Container>
      <Header>
        <h1>Pedidos enviados</h1>
      </Header>
      {UserRoles.FRANQUEADO.includes(user.role) && (
        <FranchiseTable setLoading={(isLoading) => handleLoading(isLoading)} />
      )}

      {UserRoles.CONSULTOR_SUCESSO.includes(user.role) && (
        <ConsultantTable setLoading={(isLoading) => handleLoading(isLoading)} />
      )}

      {UserRoles.ADMIN.includes(user.role) && (
        <ConsultantTable setLoading={(isLoading) => handleLoading(isLoading)} />
      )}
    </Container>
  );
};

