import styled from 'styled-components';

export const Container = styled.div`
  color: #000;
  margin-top: 16px;
  background-color: #f8f8f8;
  padding: 16px;

  margin-right: 16px;
`;

export const ContainerHeader = styled.div`
  h4 {
    font-weight: bold;
    font-size: 16px;
    line-height: 100%;
    color: #292929;
  }

  span {
    font-size: 14px;
    line-height: 100%;
    color: #666666;
    display: inline-block;
    margin-top: 12px;
  }
`;

export const FileContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 16px;
`;

export const File = styled.div`
  border: 0;
  background: #7ed56b;
  border-radius: 4px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;

  font-size: 14px;
  color: #121212;

  &:not(:first-child) {
    margin-left: 16px;
  }
`;
