import styled, { css, FlattenSimpleInterpolation } from 'styled-components';

export type Version = 'blue' | 'gray';
export type Spacing = 'l' | 'm';

type ContainerProps = {
  version: Version;
  size?: string;
  isCircular?: boolean;
  spacing?: Spacing;
  width?: string;
  isMobile?: boolean;
};

type SetVersion = {
  [key in Version]: FlattenSimpleInterpolation;
};

const setVersion: SetVersion = {
  blue: css`
    color: #ffffff;
    background-color: #0043b8;
  `,
  gray: css`
    color: #27282b;
    background-color: #c9cbcf;
  `,
};

export const Container = styled.button<ContainerProps>`
  width: ${(props) => props.width || 'auto'};

  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.25rem;

  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 1rem;
  line-height: 100%;

  padding: ${(props) =>
    props.spacing === 'm' ? '0.75rem 1.75rem' : '0.5rem 1.75rem'};

  border-radius: 999px;
  filter: drop-shadow(1px 4px 6px rgba(0, 0, 0, 0.1))
    drop-shadow(1px 8px 15px rgba(0, 0, 0, 0.1));

  &:hover {
    filter: brightness(1.1);
    cursor: pointer;
  }

  ${(props) =>
    props.isCircular &&
    css`
      padding: 0.5rem;
      width: ${props.size};
      height: ${props.size};
      border-radius: 50%;
    `}

  ${(props) => setVersion[props.version]}

  ${(props) =>
    props.isMobile &&
    css`
      @media (max-width: 992px) {
        width: 3rem;
        height: 3rem;

        justify-content: center;

        padding: 0;

        & > span {
          display: none;
        }
      }
    `}
`;
