import React from 'react';

import { ShapeModal } from '..';
import { Buttons } from '../styles';

import { Button } from '../../button';
import { Props } from 'react-modal';

type UpdateBudgetPriceModal = {
  onCancel: () => void;
  maxWidth?: string;
} & Props;

export const UpdateBudgetPriceModal: React.FC<UpdateBudgetPriceModal> = (
  props
) => {
  const { onCancel, ...rest } = props;

  return (
    <ShapeModal {...rest}>
      <h1>Atualização de preço indisponível</h1>
      <p>
        Os produtos selecionados nesse orçamento não estão mais disponíveis.
      </p>

      <Buttons>
        <Button
          type="button"
          text="Fechar"
          typeStyle="default"
          backgroundHoverColor="#C9CBCF"
          onClick={onCancel}
        />
      </Buttons>
    </ShapeModal>
  );
};
