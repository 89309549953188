import styled from 'styled-components';

export const FormContainer = styled.form``;

export const FormHeader = styled.header`
  display: flex;
  flex-direction: column;

  width: 100%;

  & div.kit-price {
    display: flex;
    flex-direction: column;
    align-items: center;

    & > span {
      font-family: 'DunbarTextRegular', sans-serif;
      font-weight: 400;
      font-size: 1rem;

      letter-spacing: 0.02em;

      color: #27282b;
    }

    & > strong {
      font-family: 'DunbarTallBold', sans-serif;
      font-size: 2rem;

      letter-spacing: 0.03em;

      color: #27282b;
    }
  }

  & > .form-header__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;

    flex: 1;

    & > .form-header__inputs {
      display: flex;
      flex-direction: column;
      justify-content: center;
      flex-wrap: wrap;
      gap: 1.5rem;

      flex: 1;

      & > div {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        & > button > svg > path {
          stroke: #ffffff;
        }

        & > h1 {
          font-family: 'DunbarTextRegular', sans-serif;
          font-weight: 400;
          font-size: 1.25rem;

          letter-spacing: 0.02em;

          color: #27282b;
        }

        & > button.edit-values-btn {
          display: none;
        }

        @media (max-width: 1158px) {
          justify-content: space-between;

          & > button.edit-values-btn {
            display: flex;
          }
        }
      }

      & > div {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 1.5rem;
        flex: 1;

        @media (max-width: 1158px) {
          & > div {
            width: 100% !important;
          }

          & > label {
            width: 100% !important;
          }
        }
      }
    }

    & > .form-header__button {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 0.875rem;

      & > button > svg > path {
        stroke: #ffffff;
      }

      @media (max-width: 1158px) {
        display: none;
      }
    }
  }

  & div.kit-price.mobile {
    display: none;
  }

  @media (max-width: 1158px) {
    & div.kit-price.mobile {
      display: flex;

      margin-top: 1rem;
    }
  }
`;

export const Divisor = styled.hr`
  margin: 2rem 0 2.625rem;

  border: 1px solid #dfe0e2;
`;

export const FormContent = styled.main`
  display: flex;
  flex-direction: column;

  gap: 1.5rem;

  & > h1 {
    font-family: 'DunbarTextRegular', sans-serif;
    font-weight: 400;
    font-size: 1.25rem;

    letter-spacing: 0.02em;

    color: #27282b;
  }

  & > .form-content__grids {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(16.125rem, 1fr));
    gap: 1.5rem;
    flex: 1;
  }
`;
