import React from 'react';
import {
  Container,
  SellingSupplier,
  SellingSupplierDetails,
  SellingSupplierLogo,
} from './styles';
import { useScrollContainer } from 'react-indiana-drag-scroll';
import { Indicator } from '../../../Indicator';
import { useDashboard } from '../../../../../../contexts/dashboard';
import { providersLogo } from '../../providersLogo';
import { FranchiseData } from '../../../../../../contexts/dashboard/types';

type BestSellingsSuppliersProps = {};

type DashboardPropertiesType = {
  franchiseData: FranchiseData;
};

export const BestSellingsSuppliers: React.FC = (
  props: BestSellingsSuppliersProps
) => {
  const sellingSupplierRef = useScrollContainer<HTMLDivElement>({
    mouseScroll: {
      overscroll: true,
    },
  });

  const { franchiseData } = useDashboard() as DashboardPropertiesType;

  const sortable = Object.fromEntries(
    Object.entries(franchiseData.sales.providers).sort(([, a], [, b]) => b - a)
  );

  return (
    <Container>
      <h1>Fornecedores mais vendidos</h1>

      <div ref={sellingSupplierRef.ref}>
        {Object.keys(sortable).map((supplier, index) => {
          return (
            <SellingSupplier key={sortable[supplier]}>
              <Indicator status="neutral" />

              <SellingSupplierDetails>
                <strong>{index + 1}°</strong>
                <SellingSupplierLogo>
                  <img
                    src={
                      providersLogo[
                        supplier.toLowerCase() as keyof typeof providersLogo
                      ]
                    }
                  />
                </SellingSupplierLogo>
                <p>{supplier}</p>
              </SellingSupplierDetails>
            </SellingSupplier>
          );
        })}
      </div>
    </Container>
  );
};

