import React, { Fragment, useEffect, useRef } from 'react';
import {
  AddTagButton,
  Button,
  Color,
  ColorWrapper,
  Colors,
  Container,
  Content,
  Header,
  Overlay,
  Tag,
  Tags,
  TagsWrapper,
  Wrapper,
} from './styles';
import { FiPlusCircle, FiTrash2, FiX } from 'react-icons/fi';
import { BsChevronLeft } from 'react-icons/bs';
import { TagEditIcon } from '../../../../../components/icons';
import Input from '../../../../../components/input';
import { Button as SaveButton } from '../../../../../components/button';
import { useSalesFunnel } from '../../../../../contexts/sales-funnel';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

export type Steps = 'ADD_STEP' | 'EDIT_STEP' | 'DEFAULT_STEP';

enum MODAL_TITLE {
  DEFAULT_STEP = 'Etiquetas',
  ADD_STEP = 'Nova etiqueta',
  EDIT_STEP = 'Editar etiqueta',
}

type Form = {
  name: string | null;
  selectedColor: string;
};

const colors = {
  blue: [
    { bgColor: '#003692' },
    { bgColor: '#005AF9' },
    { bgColor: '#8FB1EC' },
    { bgColor: '#D8E4FA' },
  ],
  green: [
    { bgColor: '#1A7605' },
    { bgColor: '#2EDB09' },
    { bgColor: '#7ED56B' },
    { bgColor: '#D4F1CE' },
  ],
  yellow: [
    { bgColor: '#CCA300' },
    { bgColor: '#FFCE00' },
    { bgColor: '#FCE794' },
    { bgColor: '#FFF8DA' },
  ],
  orange: [
    { bgColor: '#A33600' },
    { bgColor: '#F95300' },
    { bgColor: '#FF9763' },
    { bgColor: '#FFDCCB' },
  ],
  cyan: [
    { bgColor: '#0090A3' },
    { bgColor: '#00E1FF' },
    { bgColor: '#6CE6F6' },
    { bgColor: '#CEF7FC' },
  ],
} as const;

const schema = yup.object().shape({
  name: yup.string().nullable(),
  selectedColor: yup.string().required('Selecione uma cor'),
});

export const TagsModal: React.FC = () => {
  const { tags, tagModal, addTag, deleteTag, editTag, handleTagModal } =
    useSalesFunnel();

  const { data, isOpen, step } = tagModal;

  const hasTags = tags.length > 0;

  const ref = useRef<HTMLDivElement>(null);

  const {
    register,
    reset,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm<Form>({
    resolver: yupResolver(schema),
  });

  const { selectedColor } = watch();

  const { DEFAULT_STEP, EDIT_STEP, ADD_STEP } = {
    DEFAULT_STEP: step === 'DEFAULT_STEP',
    EDIT_STEP: step === 'EDIT_STEP',
    ADD_STEP: step === 'ADD_STEP',
  };

  const onSubmit = async ({ name, selectedColor }: Form) => {
    const tag = {
      name,
      color: selectedColor,
    };

    const editedTag = {
      id: data?.id ?? '',
      name,
      color: selectedColor,
    };

    ADD_STEP ? await addTag(tag) : await editTag(editedTag);

    handleTagModal('DEFAULT_STEP', null);

    reset({
      name: '',
      selectedColor: '',
    });
  };

  const handleSelectColor = (color: string) => setValue('selectedColor', color);

  useEffect(() => {
    reset({
      name: data?.name ?? '',
      selectedColor: data?.color ?? '',
    });
  }, [data]);

  return (
    <Fragment>
      {isOpen && <Overlay />}
      <Container open={isOpen} ref={ref}>
        <Header>
          <Button
            isHidden={DEFAULT_STEP}
            onClick={() => handleTagModal('DEFAULT_STEP', null)}
          >
            <BsChevronLeft />
          </Button>

          <span>{MODAL_TITLE[step]}</span>

          <Button onClick={() => handleTagModal('DEFAULT_STEP', null, false)}>
            <FiX />
          </Button>
        </Header>

        <Content>
          {DEFAULT_STEP && (
            <Wrapper>
              <span>Minhas etiquetas</span>
              <Tags>
                <TagsWrapper>
                  {!hasTags && <span>Nenhuma etiqueta encontrada.</span>}

                  {tags.map((tag) => {
                    return (
                      <Tag key={tag.id} bgColor={tag.color}>
                        <span onClick={() => null}>{tag.name}</span>

                        <div>
                          <button
                            type="button"
                            onClick={() => {
                              handleTagModal('EDIT_STEP', tag);
                            }}
                          >
                            <TagEditIcon />
                          </button>
                          <button
                            type="button"
                            onClick={async () => await deleteTag(tag.id)}
                          >
                            <FiTrash2 size={20} color="#E01919" />
                          </button>
                        </div>
                      </Tag>
                    );
                  })}
                </TagsWrapper>

                <AddTagButton onClick={() => handleTagModal('ADD_STEP', null)}>
                  <FiPlusCircle />
                  Nova etiqueta
                </AddTagButton>
              </Tags>
            </Wrapper>
          )}

          {ADD_STEP && (
            <Wrapper as="form" onSubmit={handleSubmit(onSubmit)}>
              <span>Nova Etiqueta</span>

              <Input
                label="Nome"
                placeholder="Digite o nome completo"
                {...register('name')}
              />

              <Colors>
                <span>Selecione uma cor*</span>
                <div>
                  {Object.entries(colors).map(([colorKey, colors]) => (
                    <ColorWrapper key={colorKey}>
                      {colors.map((color) => {
                        const isSelected = selectedColor === color.bgColor;

                        return (
                          <Color
                            key={color.bgColor}
                            bgColor={color.bgColor}
                            isSelected={isSelected}
                            onClick={() => handleSelectColor(color.bgColor)}
                          />
                        );
                      })}
                    </ColorWrapper>
                  ))}
                </div>

                {errors.selectedColor?.message && (
                  <span>{errors.selectedColor?.message}</span>
                )}
              </Colors>

              <SaveButton text="Salvar" />
            </Wrapper>
          )}

          {EDIT_STEP && (
            <Wrapper as="form" onSubmit={handleSubmit(onSubmit)}>
              <span>Editar Etiqueta</span>

              <Input
                label="Nome"
                placeholder="Digite o nome completo"
                {...register('name')}
              />

              <Colors>
                <span>Selecione uma cor*</span>

                <div>
                  {Object.entries(colors).map(([colorKey, colors]) => (
                    <ColorWrapper key={colorKey}>
                      {colors.map((color) => {
                        const isSelected = selectedColor === color.bgColor;

                        return (
                          <Color
                            key={color.bgColor}
                            bgColor={color.bgColor}
                            isSelected={isSelected}
                            onClick={() => handleSelectColor(color.bgColor)}
                          />
                        );
                      })}
                    </ColorWrapper>
                  ))}
                </div>

                {errors.selectedColor?.message && (
                  <span>{errors.selectedColor?.message}</span>
                )}
              </Colors>

              <SaveButton text="Salvar" />
            </Wrapper>
          )}
        </Content>
      </Container>
    </Fragment>
  );
};
