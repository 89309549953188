import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Breadcrumb } from '../../../components';
import { Container, Wrapper, PageHeader } from '../styles';
import { FileList, FormContainer } from '../../styles';
import FileUploadService from '../../../services/FileUploadService';
import { Files } from '../../../types/files';
import { File, FilesContainer } from './style';
import { UploadTypes } from '../types';

type InspectionRequestPageProps = {
  id: string;
};

const DonwloadProjectFilesPage: React.FC = () => {
  const { id } = useParams<InspectionRequestPageProps>();
  const [files, setFiles] = useState<Files[]>([]);

  useEffect(() => {
    FileUploadService.getFiles(id, '').then((res) => {
      const normalizied = res.data.map((file: any) => ({
        id: file.id,
        name: file.originalName,
        url: file.path,
        type: file.type,
        file_url: file.file_url,
      }));
      setFiles(normalizied);
    });
  }, []);

  const getFiles = (type: string) => {
    return files.filter((file) => file.type === type);
  };

  const links = [
    {
      id: 1,
      title: 'Projetos',
      link: '/projetos',
      active: false,
    },
    {
      id: 2,
      title: 'Arquivos do projeto',
      link: '/projetos',
      active: true,
    },
  ];

  return (
    <Container>
      <Breadcrumb links={links} />
      <Wrapper>
        <PageHeader>
          <span>Arquivos do projeto</span>
        </PageHeader>
        <FormContainer>
          <FileList>
            <span>Projeto</span>
            <FilesContainer>
              {getFiles(UploadTypes.ARQUIVOS_PROJETO).map((file) => (
                <File key={file.id}>
                  <a href={file.file_url} target="_blank" rel="noreferrer">
                    {file.name}
                  </a>
                </File>
              ))}
            </FilesContainer>
          </FileList>
          <FileList>
            <span>Parecer da concessionária</span>
            <FilesContainer>
              {getFiles(UploadTypes.PARECER_CONCESSIONARIA).map((file) => (
                <File key={file.id}>
                  <a href={file.file_url} target="_blank" rel="noreferrer">
                    {file.name}
                  </a>
                </File>
              ))}
            </FilesContainer>
          </FileList>
        </FormContainer>
      </Wrapper>
    </Container>
  );
};

export default DonwloadProjectFilesPage;
