import { yupResolver } from '@hookform/resolvers/yup';
import cep from 'cep-promise';
import { cnpj, cpf } from 'cpf-cnpj-validator';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import * as yup from 'yup';
import { BRFlag, Breadcrumb, Button } from '../../../../components';
import Input from '../../../../components/input';
import InputPhone from '../../../../components/input-phone';
import MaskInput from '../../../../components/mask-input';
import RadioGroup from '../../../../components/radio-group';
import { ButtonGroup, Container, PageHeader } from '../../../styles';
import { Content, FormContainer } from './styles';

enum STATES_UF {
  AC = 'Acre',
  AL = 'Alagoas',
  AP = 'Amapá',
  AM = 'Amazonas',
  BA = 'Bahia',
  CE = 'Ceará',
  DF = 'Distrito Federal',
  ES = 'Espírito Santo',
  GO = 'Goiás',
  MA = 'Maranhão',
  MT = 'Mato Grosso',
  MS = 'Mato Grosso do Sul',
  MG = 'Minas Gerais',
  PA = 'Pará',
  PB = 'Paraíba',
  PR = 'Paraná',
  PE = 'Pernambuco',
  PI = 'Piauí',
  RJ = 'Rio de Janeiro',
  RN = 'Rio Grande do Norte',
  RS = 'Rio Grande do Sul',
  RO = 'Rondônia',
  RR = 'Roraima',
  SC = 'Santa Catarina',
  SP = 'São Paulo',
  SE = 'Sergipe',
  TO = 'Tocantins',
}

export type Form = {
  name: string;
  email: string;
  documentType: string;
  cnpj: string;
  cpf: string;
  phone: string;
  zipCode: string;
  street: string;
  number: string;
  complement: string;
  neighborhood: string;
  city: string;
  state: string;
};

const schema = yup.object().shape(
  {
    name: yup.string().required('Campo obrigatório'),
    email: yup
      .string()
      .email('Deve ser um email válido')
      .required('Campo obrigatório'),
    cnpj: yup.string().when('cpf', {
      is: (cpf: string) => !cpf || cpf.length === 0,
      then: yup
        .string()
        .required('Campo obrigatório')
        .test('cnpj', 'CNPJ inválido', (value) => cnpj.isValid(String(value)))
        .min(18, 'Campo obrigatório'),
      otherwise: yup.string(),
    }),
    cpf: yup.string().when('cnpj', {
      is: (cnpj: string) => !cnpj || cnpj.length === 0,
      then: yup
        .string()
        .required('Campo obrigatório')
        .test('cpf', 'CPF inválido', (value) => cpf.isValid(String(value)))
        .min(14, 'Campo obrigatório'),
      otherwise: yup.string(),
    }),
    phone: yup
      .string()
      .required('Campo obrigatório')
      .min(14, 'Campo obrigatório'),
    zipCode: yup
      .string()
      .required('Campo obrigatório')
      .min(8, 'Campo obrigatório'),
    street: yup.string().required('Campo obrigatório'),
    number: yup.string().required('Campo obrigatório'),
    complement: yup.string().optional(),
    neighborhood: yup.string().required('Campo obrigatório'),
    city: yup.string().required('Campo obrigatório'),
    state: yup.string().required('Campo obrigatório'),
  },
  [['cnpj', 'cpf']]
);

const radioOptions = [
  {
    label: 'CPF',
    value: 'cpf',
  },
  {
    label: 'CNPJ',
    value: 'cnpj',
  },
];

const CustomersEditPage: React.FC = () => {
  const history = useHistory();

  const { id } = useParams<{ id?: string }>();

  const links = [
    {
      id: 1,
      title: 'Clientes',
      link: '/clientes',
      active: false,
    },
    {
      id: 2,
      title: 'Editar cliente',
      link: `/clientes/${id}/editar`,
      active: true,
    },
  ];

  const {
    control,
    watch,
    register,
    setValue,
    formState: { errors, isValid },
    handleSubmit,
    clearErrors,
    setError,
  } = useForm<Form>({
    resolver: yupResolver(schema),
    defaultValues: { documentType: 'cpf' },
    mode: 'onChange',
  });

  const documentType = watch('documentType');

  const onSubmit = async (data: Form) => {
    console.log('data => ', data);
  };

  const handleCep = async (cepValue: string) => {
    if (cepValue) {
      await cep(cepValue)
        .then((response) => {
          const normalizedState =
            STATES_UF[response.state as keyof typeof STATES_UF];

          setValue('street', response.street, { shouldValidate: true });
          setValue('neighborhood', response.neighborhood, {
            shouldValidate: true,
          });
          setValue('city', response.city, { shouldValidate: true });
          setValue('state', normalizedState, { shouldValidate: true });

          clearErrors('zipCode');
        })
        .catch(() => {
          setValue('street', '');
          setValue('neighborhood', '');
          setValue('city', '');
          setValue('state', '');

          setError('zipCode', { message: 'CEP não encontrado' });
        });
    }
  };

  return (
    <Container>
      <Breadcrumb links={links} />

      <PageHeader>
        <span>{'Editar cliente'}</span>
      </PageHeader>

      <Content>
        <FormContainer onSubmit={handleSubmit(onSubmit)} noValidate>
          <h1>Dados do cliente</h1>

          <div className="inputs-wrapper">
            <div className="radio-wrapper">
              <Controller
                control={control}
                name="documentType"
                render={({
                  field: { onChange, value, ref },
                  fieldState: { error },
                }) => {
                  return (
                    <RadioGroup
                      options={radioOptions}
                      onChange={(event) => {
                        onChange(event.target.value);

                        setValue('cnpj', '');
                        setValue('cpf', '');
                      }}
                      value={value}
                      ref={ref}
                      error={error?.message}
                    />
                  );
                }}
              />

              {documentType === 'cpf' && (
                <MaskInput
                  label="CPF"
                  placeholder="999.999.999-99"
                  mask="999.999.999-99"
                  style={{ width: '16.125rem' }}
                  required
                  {...register('cpf')}
                  error={errors.cpf?.message}
                />
              )}
              {documentType === 'cnpj' && (
                <MaskInput
                  label="CNPJ"
                  mask="99.999.999/9999-99"
                  placeholder="99.999.999/9999-99"
                  style={{ width: '16.125rem' }}
                  required
                  {...register('cnpj')}
                  error={errors.cnpj?.message}
                />
              )}
            </div>

            <Input
              label="Nome"
              type="text"
              placeholder="Informe o nome"
              style={{ width: '24rem' }}
              required
              {...register('name')}
              error={errors.name?.message}
            />

            <Input
              label="Email"
              type="email"
              placeholder="Informe o e-mail"
              style={{ width: '22rem' }}
              required
              {...register('email')}
              error={errors.email?.message}
            />
          </div>

          <div className="inputs-wrapper">
            <InputPhone
              label="Telefone"
              mask="(99) 99999-9999"
              placeholder="Digite seu número"
              iconLeft={<BRFlag />}
              style={{ width: '16.125rem' }}
              required
              {...register('phone')}
              error={errors.phone?.message}
            />

            <MaskInput
              label="CEP"
              mask="99999-999"
              placeholder="Informe o CEP"
              style={{ width: '10.25rem' }}
              required
              {...register('zipCode', {
                onBlur: async (e) => await handleCep(e.target.value),
              })}
              error={errors.zipCode?.message}
            />

            <Input
              label="Localidade"
              type="text"
              style={{ width: '27.875rem' }}
              placeholder="Informe a rua ou logradouro"
              required
              {...register('street')}
              error={errors.street?.message}
            />

            <MaskInput
              label="Número"
              placeholder="0000"
              style={{ width: '10.25rem' }}
              required
              mask="99999"
              {...register('number')}
              error={errors.number?.message}
            />
          </div>

          <div className="inputs-wrapper">
            <Input
              label="Complemento"
              type="text"
              placeholder="Informe o complemento"
              style={{ width: '16.125rem' }}
              {...register('complement')}
              error={errors.complement?.message}
            />

            <Input
              label="Bairro"
              type="text"
              placeholder="Informe o bairro"
              style={{ width: '16.125rem' }}
              required
              {...register('neighborhood')}
              error={errors.neighborhood?.message}
            />

            <Input
              label="Cidade"
              type="text"
              placeholder="Informe a cidade"
              style={{ width: '16.125rem' }}
              required
              error={errors.city?.message}
              {...register('city')}
            />

            <Input
              label="Estado"
              type="text"
              placeholder="Informe o estado"
              style={{ width: '16.125rem' }}
              required
              error={errors.state?.message}
              {...register('state')}
            />
          </div>

          <ButtonGroup style={{ margin: 'auto 0 0' }}>
            <Button
              type="button"
              text="Voltar"
              typeStyle="default"
              backgroundHoverColor="#C9CBCF"
              onClick={() => history.push('/clientes')}
            />
            <Button type="submit" text="Salvar" disabled={!isValid} />
          </ButtonGroup>
        </FormContainer>
      </Content>
    </Container>
  );
};

export default CustomersEditPage;

