import React from 'react';
import { ShapeModal } from '..';
import { Buttons } from '../styles';

import { Props } from 'react-modal';
import { Button } from '../../button';
import { Container } from './styles';

type EditBudgetConfirmationModalProps = {
  isOpen: boolean;
  onCancel: () => void;
  onConfirm: () => void;
} & Props;

export const EditBudgetConfirmationModal: React.FC<
  EditBudgetConfirmationModalProps
> = (props) => {
  const { isOpen, onCancel, onConfirm, ...rest } = props;

  return (
    <ShapeModal
      isOpen={isOpen}
      {...rest}
      onAfterClose={() => {
        document.documentElement.style.overflow = 'auto';
      }}
      onAfterOpen={() => {
        document.documentElement.style.overflow = 'hidden';
      }}
    >
      <Container>
        <h1>Aviso</h1>

        <p>O orçamento será perdido caso não seja gerado uma nova proposta.</p>

        <Buttons>
          <Button
            type="button"
            text="Voltar"
            typeStyle="default"
            backgroundHoverColor="#C9CBCF"
            onClick={onCancel}
          />

          <Button
            type="button"
            text="Continuar mesmo assim"
            typeStyle="confirm"
            onClick={onConfirm}
          />
        </Buttons>
      </Container>
    </ShapeModal>
  );
};
