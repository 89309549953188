import React from 'react';
import { ShapeModal, ShapeModalProps } from '../..';
import { FiX } from 'react-icons/fi';
import { Header } from './styles';

type ConsultantModalProps = {
  onClose: () => void;
  title: string;
} & ShapeModalProps;

export const RankingModal: React.FC<ConsultantModalProps> = (
  props: ConsultantModalProps
) => {
  const { isOpen, onClose, title, ...rest } = props;

  return (
    <ShapeModal isOpen={isOpen} onRequestClose={onClose} {...rest}>
      <Header>
        <h1>{title}</h1>

        <button onClick={onClose}>
          <FiX />
        </button>
      </Header>

      {rest.children}
    </ShapeModal>
  );
};
