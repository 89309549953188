import React, { useState } from 'react';
import {
  Container,
  Header,
  Item,
  List,
  SeeDetails,
  TableHeader,
} from './styles';
import { useScrollContainer } from 'react-indiana-drag-scroll';
import { UserRoles } from '../../../../../../types/users';
import { RankingModal } from '../../../../../../components/modal/dashboard/ranking';
import Table, { ColumnStructure } from '../../../../../../components/table';
import { SearchInput } from '../../../../../../components/search';
import DynamicFilter from '../../../../../../components/dynamic-filter';
import { rankings } from '../../mock';
import { Indicator } from '../../../Indicator';
import { IndicatorStatus } from '../../../Indicator/styles';

type RankingProps = {
  role: string;
};

export const Ranking: React.FC<RankingProps> = (props) => {
  const { role } = props;

  const listRef = useScrollContainer<HTMLUListElement>({
    mouseScroll: {
      overscroll: true,
      ignoreElements: '.undraggable',
    },
  });

  const [rankingModal, setRankingModal] = useState(false);

  const handleRankingModal = () => {
    setRankingModal((state) => !state);
  };

  const [consultantColumns, setConsultantColumns] = useState<ColumnStructure[]>(
    [
      {
        id: 'rank',
        label: 'Ranking',
        orderBy: 'DESC',
        onClick: () => handleOrderBy('rank'),
      },
      {
        id: 'consultant',
        label: 'Consultor',
        orderBy: 'DESC',
        onClick: () => handleOrderBy('consultant'),
      },
      {
        id: 'sales',
        label: 'Vendas',
        orderBy: 'DESC',
        onClick: () => handleOrderBy('sales'),
      },
      {
        id: 'itemsSold',
        label: 'Itens vendidos',
        orderBy: 'DESC',
        onClick: () => handleOrderBy('itemsSold'),
      },
      {
        id: 'ticketProducts',
        label: 'Ticket Médio Produtos',
        orderBy: 'DESC',
        onClick: () => handleOrderBy('ticket'),
      },
      {
        id: 'labor',
        orderBy: 'DESC',
        label: 'Média de mão de obra',
        onClick: () => handleOrderBy('labor'),
      },
      {
        id: 'ticket',
        orderBy: 'DESC',
        label: 'Ticket Médio',
        onClick: () => handleOrderBy('ticket'),
      },
      {
        id: 'share',
        orderBy: 'DESC',
        label: 'Share',
        onClick: () => handleOrderBy('share'),
      },
    ]
  );

  const [consultantItems, setConsultantItems] = useState([
    {
      id: '1',
      rank: '1°',
      consultant: 'Tiago',
      sales: 'R$ 50.314.060,95',
      itemsSold: '1.013',
      ticketProducts: 'R$ 35.518,18',
      labor: 'R$ 14.150,22',
      ticket: 'R$ 49.668,37',
      share: '26,45%',
    },
    {
      id: '2',
      rank: '2°',
      consultant: 'Larissa',
      sales: 'R$ 42.288.555,02',
      itemsSold: '1.134',
      ticketProducts: 'R$ 25.339,17',
      labor: 'R$ 11.952,32',
      ticket: 'R$ 37.291,49',
      share: '22,24%',
    },
    {
      id: '3',
      rank: '3°',
      consultant: 'Ana',
      sales: 'R$ 37.586.819,11',
      itemsSold: '886',
      ticketProducts: 'R$ 31.054,77',
      labor: 'R$ 12.348,02',
      ticket: 'R$ 43.402,79',
      share: '19,76%',
    },
    {
      id: '4',
      rank: '4°',
      consultant: 'Juliana',
      sales: 'R$ 30.639.848,55',
      itemsSold: '737',
      ticketProducts: 'R$ 29.609,17',
      labor: 'R$ 11.964,57',
      ticket: 'R$ 41.573,74',
      share: '16,11%',
    },
    {
      id: '5',
      rank: '5°',
      consultant: 'Junior',
      sales: 'R$ 29.371.427,63',
      itemsSold: '908',
      ticketProducts: 'R$ 22.592,54',
      labor: 'R$ 9.754,75',
      ticket: 'R$ 32.347,39',
      share: '15,44%',
    },
  ]);

  const [franchiseColumns, setfranchiseColumns] = useState<ColumnStructure[]>([
    {
      id: 'sales',
      label: 'Vendas',
      orderBy: 'DESC',
      onClick: () => handleOrderBy('sales'),
    },
    {
      id: 'items',
      label: 'Itens',
      orderBy: 'DESC',
      onClick: () => handleOrderBy('items'),
    },
    {
      id: 'ticket',
      label: 'Ticket médio com mão de obra',
      orderBy: 'DESC',
      onClick: () => handleOrderBy('ticket'),
    },
    {
      id: 'share',
      label: 'Share',
      orderBy: 'DESC',
      onClick: () => handleOrderBy('share'),
    },
    {
      id: 'name',
      orderBy: 'DESC',
      label: 'Nome do vendedor',
      onClick: () => handleOrderBy('name'),
    },
  ]);

  const [franchiseItems, setFranchiseItems] = useState([
    {
      id: '1',
      sales: 'R$ 5.000.000,00',
      items: 'placeholder',
      ticket: 'R$ 50.000,00',
      share: '25%',
      name: 'Luan Souza',
    },
    {
      id: '2',
      sales: 'R$ 5.000.000,00',
      items: 'placeholder',
      ticket: 'R$ 50.000,00',
      share: '15%',
      name: 'Ítalo Braga',
    },
  ]);

  const [orderBy, setOrderBy] = useState('');

  const handleOrderBy = (columnId: string) => {
    setConsultantColumns((state) => {
      return state.map((column) => {
        const currentColumn = state.find((column) => column.id === columnId);

        setOrderBy(String(currentColumn?.id + ',' + currentColumn?.orderBy));

        return column.id === columnId
          ? column.orderBy === 'ASC'
            ? { ...column, orderBy: 'DESC' }
            : { ...column, orderBy: 'ASC' }
          : { ...column, orderBy: column.orderBy ? 'DESC' : '' };
      });
    });
  };

  if ([UserRoles.ADMIN].includes(role)) {
    return (
      <Container>
        <Header>
          <h1>Consultores</h1>
        </Header>

        <List ref={listRef.ref}>
          {rankings.map((item) => {
            return (
              <Item key={item.id}>
                <p>{item.consultant}</p>
                <div>
                  <Indicator status={item.status as IndicatorStatus} />
                  <strong>{item.rank}°</strong>
                </div>
              </Item>
            );
          })}
        </List>

        <SeeDetails type="button" onClick={handleRankingModal}>
          Ver detalhes
        </SeeDetails>

        <RankingModal
          isOpen={rankingModal}
          onClose={handleRankingModal}
          maxWidth={'69rem'}
          title="Ranking de consultores"
        >
          <Table
            header={
              <TableHeader>
                <SearchInput
                  placeholder="Digite aqui para pesquisar"
                  onTextChange={() => null}
                  name="kitsSearch"
                />
                <DynamicFilter filters={[]} onChange={() => null} />
              </TableHeader>
            }
            columns={consultantColumns}
            items={consultantItems}
            isLoading={false}
            pagination={{ currentPage: 1, lastPage: 1, limit: 10, total: 1 }}
            onLimitChange={() => null}
            onPageChange={() => null}
          />
        </RankingModal>
      </Container>
    );
  }

  return null;
};
