import styled from 'styled-components';

export const Content = styled.main`
  height: 100%;

  display: flex;
  flex-direction: column;

  padding: 1.5rem 0;
`;

export const NFE = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.875rem;

  height: 100%;

  & > h1 {
    font-family: 'DunbarTextRegular', sans-serif;
    font-weight: 400;
    font-size: 1.25rem;

    letter-spacing: 0.02em;

    color: #27282b;
  }

  & > p {
    font-family: 'DunbarTextRegular', sans-serif;

    font-weight: 400;
    font-size: 1rem;

    letter-spacing: 0.02em;

    color: #000000;
  }

  & > div {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    font-size: 1rem;

    letter-spacing: 0.01em;

    color: #27282b;

    & button {
      border: 1px solid #c9cbcf;
    }
  }
`;

