import React, {
  CSSProperties,
  ForwardedRef,
  InputHTMLAttributes,
  useState,
} from 'react';

import { Container } from './styles';

interface CountProps extends InputHTMLAttributes<HTMLInputElement> {
  style?: CSSProperties;
  disabled?: boolean;
  value: number;
  variant?: 'primary' | 'secondary';
  onIncrement: () => void;
  onDecrement: () => void;
}

const Count = React.forwardRef(
  (props: CountProps, myRef: ForwardedRef<HTMLInputElement>) => {
    const {
      name,
      style,
      disabled = false,
      value,
      variant = 'primary',
      onIncrement,
      onDecrement,
      ...rest
    } = props;

    const [active, setActive] = useState(false);

    return (
      <Container
        active={active}
        disabled={disabled}
        style={style}
        onFocus={() => setActive(true)}
        onBlur={() => setActive(false)}
        htmlFor={name}
        variant={variant}
      >
        <div>
          <button type="button" onClick={onDecrement} disabled={disabled}>
            <span>-</span>
          </button>
          <input
            ref={myRef}
            id={name}
            name={name}
            readOnly
            type="number"
            value={value}
            {...rest}
          />
          <button type="button" onClick={onIncrement} disabled={disabled}>
            <span>+</span>
          </button>
        </div>
      </Container>
    );
  }
);

Count.displayName = 'Count';

export default Count;
