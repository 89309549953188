import { AxiosError } from 'axios';
import React, { useEffect, useState } from 'react';
import { FiEye } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  ActionButton,
  Breadcrumb,
  Button,
  StepProgress,
} from '../../../../components';
import Checkbox from '../../../../components/checkbox';
import Count from '../../../../components/count';
import Loading from '../../../../components/loading';
import { KitDescriptionModal } from '../../../../components/modal/kit-description';
import { UpdateBudgetPriceModal } from '../../../../components/modal/update-budget-price';
import { Budget, BudgetItem } from '../../../../contexts/budgets/types';
import { useOrders } from '../../../../contexts/orders';
import { Order } from '../../../../contexts/orders/types';
import productsApi from '../../../../services/products';
import { ButtonGroup, Container, PageHeader } from '../../../styles';
import {
  Actions,
  Content,
  Deleted,
  TableContainer,
  TableFooter,
  TableHeader,
  ValuesData,
} from './styles';
import { formatQuantity } from '../../../../utils/formatQuantity';

type Modals = {
  deleted: string | null;
  description: string | null;
};

const steps = [
  { key: 1, title: 'Selecionar kit', active: true, complete: false },
  { key: 2, title: 'Resumo', active: false, complete: false },
  { key: 3, title: 'Pagamento', active: false, complete: false },
  { key: 4, title: 'Documentos', active: false, complete: false },
  { key: 5, title: 'Termo de aceite', active: false, complete: false },
];

const links = [
  {
    id: 1,
    title: 'Orçamentos',
    link: '/orcamentos',
    active: false,
  },
  {
    id: 2,
    title: 'Orçamentos e pedidos',
    link: '/orcamentos/orcamentos-e-pedidos',
    active: false,
  },
  {
    id: 3,
    title: 'Gerar pedido',
    link: '/orcamentos/orcamentos-e-pedidos',
    active: false,
  },
  {
    id: 4,
    title: 'Selecionar kit',
    link: '/orcamentos/orcamentos-e-pedidos/gerar-pedido/selecione',
    active: true,
  },
];

const OrdersSelectionKitPage: React.FC = () => {
  const [modals, setModals] = useState({
    deleted: null,
    description: null,
  } as Modals);

  const { handleOrder, order } = useOrders();

  const history = useHistory();

  const budgetId =
    order?.budgetId || (history.location.state as string | undefined);

  const [loading, setLoading] = useState(true);

  const [budget, setBudget] = useState<Budget | null>(null);

  const budgetItems = budget?.items ?? [];

  const isBudgetCustom = budgetItems.some((item) => item.type === 'custom');

  const [selectedKits, setSelectedKits] = useState<BudgetItem[]>(
    order?.items || []
  );

  const hasSelectedKits = !!selectedKits?.length;

  const handleSelectKit = (item: BudgetItem) => {
    setSelectedKits((state) => {
      const isDifferentSupplier = state.every(
        (kit) => kit.product?.supplier !== item.product?.supplier
      );

      const hasValue = !!state.length;

      if (isDifferentSupplier && hasValue) {
        toast.info('Selecione somente um fornecedor', {
          toastId: item.id,
        });

        return state;
      }

      const existingValue = state.find((kit) => kit.id === item.id);

      if (existingValue) {
        return state.filter((kit) => kit.id !== item.id);
      }

      return [...state, item];
    });
  };

  const handleDescriptionModal = (description: string | null) => {
    setModals((state) => {
      return {
        ...state,
        description,
      };
    });
  };

  const handleDeleteModal = (id: string | null) => {
    setModals((state) => {
      return {
        ...state,
        deleted: id,
      };
    });
  };

  const getBudgetItems = async () => {
    if (!budgetId && !order?.budgetId) {
      history.replace('/orcamentos/orcamentos-e-pedidos');

      return;
    }

    try {
      const { data } = await productsApi.get<Budget>(
        `/budgets/${budgetId}/items`
      );

      setBudget(data);
    } catch (error) {
      const errorMessage = (error as AxiosError).response?.data.error;

      switch (errorMessage) {
        case 'budget not found':
          toast.error('Orçamento não encontrado');
          break;

        case 'Not found':
          toast.error('Orçamento não encontrado');
          break;

        case 'budget items not found':
          toast.error('Orçamento sem produtos vinculados');
          break;

        default:
          toast.error(errorMessage);
          break;
      }

      history.replace('/orcamentos/orcamentos-e-pedidos');
    } finally {
      setLoading(false);
    }
  };

  const updateBudgetItemPrice = async (itemId: string) => {
    try {
      const { data } = await productsApi.patch(
        `/budgets/${itemId}/budget-price`
      );

      getBudgetItems();

      const status = data.status;

      const isDeleted = status.includes('Deleted');

      if (isDeleted) {
        handleDeleteModal(data.id);
      }
    } catch (error) {
      const errorMessage = (error as AxiosError).response?.data.message;

      toast.error(errorMessage);
    }
  };

  const updateDeletedBudgetItem = async (itemId: string) => {
    try {
      await productsApi.patch(`/budgets/${itemId}/budget-price`);

      getBudgetItems();
    } catch (error) {
      const errorMessage = (error as AxiosError).response?.data.message;

      toast.error(errorMessage);
    } finally {
      handleDeleteModal(null);
    }
  };

  const createOrder = async () => {
    const normalizedOrder = {
      budgetId: budget?.id,
      items: selectedKits.map((kit) => kit.id),
    };

    try {
      const { data } = await productsApi.post<Order>(
        '/orders/',
        normalizedOrder
      );

      history.push(
        `/orcamentos/orcamentos-e-pedidos/gerar-pedido/${data.id}/resumo`
      );

      handleOrder(data);
    } catch (error) {
      const errorMessage = (error as AxiosError).response?.data.message;

      switch (errorMessage) {
        case 'budget not found':
          toast.error('Orçamento não encontrado');
          break;

        case 'budget item not found':
          toast.error('Item do orçamento não encontrado');
          break;

        default:
          toast.error(errorMessage);
          break;
      }
    }
  };

  useEffect(() => {
    getBudgetItems();
  }, []);

  if (loading) {
    return <Loading />;
  }

  return (
    <Container>
      <Breadcrumb links={links} />

      <PageHeader>
        <span>{'Selecionar kit'}</span>
        <div>
          <StepProgress steps={steps} />
        </div>
      </PageHeader>

      <Content>
        <TableContainer>
          <TableHeader>
            <h1>{isBudgetCustom ? 'Kits Personalizados' : 'Kits'}</h1>
          </TableHeader>
          <div>
            {isBudgetCustom && (
              <table>
                <thead>
                  <tr>
                    <th></th>
                    <th>Composição</th>
                    <th>Tipo</th>
                    <th>Marca</th>
                    <th>Kits</th>
                    <th>Módulos</th>
                    <th>Valores</th>
                    <th>Quantidade</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {budgetItems.map((item, i) => {
                    const { customItem } = item;

                    const kitsDescription =
                      customItem?.kits
                        .map((kit) => kit.product.description)
                        .join(', ') ?? '';

                    const quantity =
                      customItem?.kits.reduce(
                        (acc, kit) => acc + kit.quantity,
                        0
                      ) ?? 0;

                    const solarPlatesNumber =
                      customItem?.solarPlatesNumber ?? 0;

                    const isChecked = !!selectedKits.find(
                      (kit) => kit.id === item.id
                    );

                    const isDisabled =
                      item?.status?.includes('Deleted') ||
                      item?.status?.includes('PriceUpdated');

                    return (
                      <tr key={item.id}>
                        <td>
                          <Checkbox
                            option={item}
                            name={item.id}
                            onChange={(item) => handleSelectKit(item)}
                            value={item.id}
                            isChecked={isChecked}
                            disabled={isDisabled}
                          />
                        </td>
                        <td>
                          <strong>{i + 1}</strong>
                        </td>
                        <td>
                          <strong>{customItem?.type}</strong>
                        </td>
                        <td>
                          <strong>{customItem?.supplier}</strong>
                        </td>
                        <td>
                          <strong>
                            {formatQuantity(quantity, 'Kit', 'Kits')}
                          </strong>
                        </td>
                        <td>
                          <strong>
                            {formatQuantity(
                              solarPlatesNumber,
                              'módulo',
                              'módulos'
                            )}
                          </strong>
                        </td>
                        <td>
                          {item.status?.includes('PriceUpdated') && (
                            <Button
                              type="button"
                              text="Atualizar"
                              onClick={() => updateBudgetItemPrice(item.id)}
                            />
                          )}
                          {item.status?.includes('Deleted') && (
                            <Deleted>Kit indisponível</Deleted>
                          )}
                          {(!item.status ||
                            item.status?.includes('Regular')) && (
                            <ValuesData>
                              <span>Kit: {item.formatted.price}</span>
                              <span>Instalação: {item.formatted.income}</span>
                              <span className="hr" />
                              <strong>Total: {item.formatted.total}</strong>
                            </ValuesData>
                          )}
                        </td>
                        <td>
                          <Count
                            name={item.id}
                            value={item.quantity}
                            disabled
                            onDecrement={() => null}
                            onIncrement={() => null}
                          />
                        </td>
                        <td>
                          <Actions>
                            <ActionButton
                              tooltip="Visualizar"
                              onClick={() => {
                                handleDescriptionModal(kitsDescription);
                              }}
                            >
                              <FiEye />
                            </ActionButton>
                          </Actions>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
                <TableFooter />
              </table>
            )}

            {!isBudgetCustom && (
              <table>
                <thead>
                  <tr>
                    <th></th>
                    <th>Fornecedor</th>
                    <th>Categoria</th>
                    <th>Kits</th>
                    <th>Valores</th>
                    <th>Quantidade</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {budgetItems.map((item) => {
                    const isChecked = !!selectedKits.find(
                      (kit) => kit.id === item.id
                    );

                    const isDisabled =
                      item?.status?.includes('Deleted') ||
                      item?.status?.includes('PriceUpdated');

                    return (
                      <tr key={item.id}>
                        <td>
                          <Checkbox
                            option={item}
                            name={item.id}
                            onChange={(item) => handleSelectKit(item)}
                            value={item.id}
                            isChecked={isChecked}
                            disabled={isDisabled}
                          />
                        </td>
                        <td>
                          <strong>{item.product?.supplier}</strong>
                        </td>
                        <td>
                          <strong>{item.product?.category}W</strong>
                        </td>
                        <td>
                          <strong>
                            {item.product?.power} KWp | {item.product?.voltage}V
                            <span>
                              {item.product?.solarPlatesNumber} módulos |{' '}
                              {item.totalGeneration}KWh
                            </span>
                          </strong>
                        </td>
                        <td>
                          {item.status?.includes('PriceUpdated') && (
                            <Button
                              type="button"
                              text="Atualizar"
                              onClick={() => updateBudgetItemPrice(item.id)}
                            />
                          )}
                          {item.status?.includes('Deleted') && (
                            <Deleted>Kit indisponível</Deleted>
                          )}
                          {(!item.status ||
                            item.status?.includes('Regular')) && (
                            <ValuesData>
                              <span>Kit: {item.formatted.price}</span>
                              <span>Instalação: {item.formatted.income}</span>
                              <span className="hr" />
                              <strong>Total: {item.formatted.total}</strong>
                            </ValuesData>
                          )}
                        </td>
                        <td>
                          <Count
                            name={item.id}
                            value={item.quantity}
                            disabled
                            onDecrement={() => null}
                            onIncrement={() => null}
                          />
                        </td>
                        <td>
                          <Actions>
                            <ActionButton
                              tooltip="Visualizar"
                              onClick={() => {
                                handleDescriptionModal(
                                  item.product?.description ?? ''
                                );
                              }}
                            >
                              <FiEye />
                            </ActionButton>
                          </Actions>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
                <TableFooter />
              </table>
            )}
          </div>
        </TableContainer>

        <ButtonGroup>
          <Button
            type="button"
            text="Voltar"
            typeStyle="default"
            backgroundHoverColor="#C9CBCF"
            onClick={() => history.push('/orcamentos/orcamentos-e-pedidos')}
          />

          <Button
            type="submit"
            text="Avançar"
            onClick={async () => await createOrder()}
            disabled={!hasSelectedKits}
          />
        </ButtonGroup>
      </Content>

      <KitDescriptionModal
        isOpen={!!modals.description}
        description={modals.description as string}
        onCancel={() => {
          handleDescriptionModal(null);
        }}
      />

      <UpdateBudgetPriceModal
        maxWidth="30rem"
        isOpen={!!modals.deleted}
        onCancel={() => updateDeletedBudgetItem(modals.deleted as string)}
        onRequestClose={() => {
          handleDeleteModal(null);
        }}
      />
    </Container>
  );
};

export default OrdersSelectionKitPage;
