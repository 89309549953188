import React from 'react';
import { Container, Wrapper } from './styles';
import { Product, ProductsType } from '..';
import { InvertersTable } from './inverters';
import { ConnectorsTable } from './connectors';
import { CablesTable } from './cables';
import { AccessoriesTable } from './accessories';
import { ProtectionTable } from './protection';
import { SupportsTable } from './supports';

type Props = {
  activeProduct: Product;
};

const tables: Record<ProductsType, JSX.Element> = {
  Inversores: <InvertersTable />,
  Conectores: <ConnectorsTable />,
  Cabos: <CablesTable />,
  Acessórios: <AccessoriesTable />,
  Proteção: <ProtectionTable />,
  Suportes: <SupportsTable />,
};

export const Tables: React.FC<Props> = (props) => {
  const { activeProduct } = props;

  const productName = activeProduct.name.toLowerCase();

  return (
    <Container>
      <h1>Selecionar {productName}</h1>

      <Wrapper>{tables[activeProduct.name]}</Wrapper>
    </Container>
  );
};

