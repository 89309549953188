import styled, { css } from 'styled-components';

export const Content = styled.form`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
`;

export const Header = styled.header`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  & > h1 {
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    font-size: 1.25rem;

    letter-spacing: 0.01em;

    color: #27282b;
  }

  & > strong {
    font-family: 'DunbarTallBold', sans-serif;
    font-weight: 700;
    font-size: 1.5rem;

    letter-spacing: 0.03em;

    color: #27282b;
  }
`;

type FieldWrapperProps = {
  variant: 'primary' | 'secondary';
};

const fieldWrapperVariants = {
  primary: css`
    display: grid;
    grid-template-columns: repeat(2, 10.25rem);
    grid-template-rows: 4.5rem;
    align-items: center;

    margin: 5rem 0 3.375rem;
  `,
  secondary: css`
    display: flex;
    flex-direction: column;
    gap: 1rem;
  `,
} as const;

export const FieldWrapper = styled.fieldset<FieldWrapperProps>`
  ${(props) => fieldWrapperVariants[props.variant]}

  & > label {
    width: fit-content;
  }
`;

export const RegionWrapper = styled.div`
  display: flex;
  flex-direction: column;

  & > h1 {
    font-family: 'DunbarTallRegular', sans-serif;
    font-weight: 500;
    font-size: 1.125rem;

    letter-spacing: 0.03em;

    color: #27282b;
  }
`;

export const Regions = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(10.25rem, 10.25rem));
  column-gap: 7.375rem;
  row-gap: 2rem;

  & > fieldset > label {
    width: fit-content;

    white-space: nowrap;
  }

  @media (max-width: 992px) {
    grid-template-columns: repeat(auto-fit, minmax(10.25rem, 1fr));

    & > fieldset > label {
      width: 100%;
    }
  }
`;
