import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  color: #000;
`;

export const ContainerHeader = styled.div`
  h4 {
    font-weight: bold;
    font-size: 16px;
    line-height: 100%;
    color: #292929;
  }

  span {
    font-size: 14px;
    line-height: 100%;
    color: #666666;
    display: inline-block;
    margin-top: 12px;
  }
`;

export const FileButtonContainer = styled.div`
  margin: 32px 0;
`;

export const FileButton = styled.button`
  border: 0;
  background: #dfe0e2;
  border-radius: 4px;
  width: 360px;
  height: 50px;

  font-weight: bold;
  font-size: 14px;
  line-height: 24px;

  color: #27282b;
`;

export const FileContainer = styled.div`
  display: flex;
  flex-wrap: wrap;

  .progress {
    height: 48px;
    display: flex;
    align-items: center;
    position: relative;
    background-color: #f5f5f6;
    margin-right: 16px;
    border-radius: 4px;
    min-width: 200px;

    span {
      z-index: 20;
      padding: 0 16px;
    }
  }

  .progress-bar {
    height: 48px;
    width: 30%;
    background-color: #7ed56b;
    position: absolute;
    border-radius: 4px;
  }
`;

export const File = styled.div`
  border: 0;
  background: #7ed56b;
  border-radius: 4px;
  min-height: 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;

  font-size: 14px;
  color: #121212;

  margin-right: 16px;
  margin-top: 16px;
  word-break: break-all;
`;

export const DeleteButton = styled.button`
  border: 0;
  background-color: transparent;
  width: 24px;
  height: 24px;
  border-radius: 5px;
  margin-left: 32px;
  display: flex;
  justify-content: center;
  align-self: center;

  :hover {
    background-color: red;
    opacity: 0.5;
    fill: #fff;
  }
`;

export const HiddenFileButton = styled.div`
  display: none;
`;
