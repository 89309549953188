import React from 'react';
import { Container } from './styles';

type InputProps = {
  text: string;
};

export const Status: React.FC<InputProps> = ({ text }) => {
  return (
    <Container text={text}>
      <span>{text}</span>
    </Container>
  );
};
