import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  Breadcrumb,
  Button,
  CloudIcon,
  EmailIcon,
  StepProgress,
  WhatsappIcon,
} from '../../../../components';
import { useOrders } from '../../../../contexts/orders';
import { ButtonGroup, Container, PageHeader } from '../../../styles';

import { Content, ContentHeader, EmbedContainer } from './styles';

const steps = [
  { key: 1, title: 'Selecionar kit', active: false, complete: true },
  { key: 2, title: 'Resumo', active: false, complete: true },
  { key: 3, title: 'Acessórios', active: false, complete: true },
  { key: 4, title: 'Custos', active: false, complete: true },
  { key: 5, title: 'Pagamento', active: false, complete: true },
  { key: 6, title: 'Documentos', active: false, complete: true },
  { key: 7, title: 'Termo de aceite', active: true, complete: false },
];

const OrdersTermsPage: React.FC = () => {
  const history = useHistory();

  const { order } = useOrders();

  const { id: orderId } = useParams<{ id: string }>();

  const links = [
    {
      id: 1,
      title: 'Orçamentos',
      link: '/orcamentos',
      active: false,
    },
    {
      id: 2,
      title: 'Orçamentos e pedidos',
      link: '/orcamentos/orcamentos-e-pedidos',
      active: false,
    },
    {
      id: 3,
      title: 'Gerar pedido',
      link: '/orcamentos/orcamentos-e-pedidos/',
      active: false,
    },
    {
      id: 4,
      title: 'Selecionar kit',
      link: '/orcamentos/orcamentos-e-pedidos/gerar-pedido/selecione',
      active: false,
    },
    {
      id: 5,
      title: 'Resumo',
      link: `/orcamentos/orcamentos-e-pedidos/gerar-pedido/${orderId}/resumo`,
      active: false,
    },
    {
      id: 6,
      title: 'Acessórios',
      link: `/orcamentos/orcamentos-e-pedidos/gerar-pedido/${orderId}/acessorios`,
      active: false,
    },
    {
      id: 7,
      title: 'Custos',
      link: `/orcamentos/orcamentos-e-pedidos/gerar-pedido/${orderId}/custos`,
      active: false,
    },
    {
      id: 8,
      title: 'Pagamento',
      link: `/orcamentos/orcamentos-e-pedidos/gerar-pedido/${orderId}/formas-de-pagamento`,
      active: false,
    },
    {
      id: 9,
      title: 'Documentos',
      link: `/orcamentos/orcamentos-e-pedidos/gerar-pedido/${orderId}/documentos`,
      active: false,
    },
    {
      id: 10,
      title: 'Termo de aceite',
      link: `/orcamentos/orcamentos-e-pedidos/gerar-pedido/${orderId}/termo-de-aceite`,
      active: true,
    },
  ];

  const normalizedPhone =
    '55' + order?.budget.customer.phone.replace(/\(|\)| |-/gm, '');

  const pdfUrl = history.location.state as string;

  const downloadTerm = (data: string, fileName: string) => {
    const link = document.createElement('a');
    link.href = data;
    link.download = fileName;
    link.click();
  };

  const sendByWhatsapp = (phone: string, pdfURL: string) => {
    downloadTerm(pdfURL, 'proposta.pdf');

    window.open('https://wa.me/' + phone, '_blank');
  };

  const handleTerm = () => {
    history.push(
      `/orcamentos/orcamentos-e-pedidos/gerar-pedido/${orderId}/termo-de-aceite/enviar`,
      pdfUrl
    );
  };

  return (
    <Container>
      <Breadcrumb links={links} />

      <PageHeader>
        <span>{'Termo de aceite'}</span>
        <div>
          <StepProgress steps={steps} />
        </div>
      </PageHeader>

      <Content>
        <ContentHeader>
          <div>
            <Button
              type="button"
              typeStyle="default"
              backgroundHoverColor="#C9CBCF"
              text="Enviar por e-mail"
              onClick={() => null}
              icon={<EmailIcon />}
              disabled
            />
            <Button
              type="button"
              typeStyle="default"
              backgroundHoverColor="#C9CBCF"
              text="Enviar por whatsapp"
              icon={<WhatsappIcon />}
              onClick={() => sendByWhatsapp(normalizedPhone, pdfUrl)}
            />
          </div>
          <div>
            <Button
              type="button"
              style={{ width: '16.125rem', border: '1px solid #C9CBCF' }}
              backgroundColor="#FFFFFF"
              typeStyle="default"
              text="Fazer download"
              onClick={() => downloadTerm(pdfUrl, 'termo.pdf')}
              icon={<CloudIcon />}
            />
          </div>
        </ContentHeader>

        <EmbedContainer src={pdfUrl} width="640" height="480" />

        <ButtonGroup>
          <Button
            type="button"
            text="Voltar"
            typeStyle="default"
            backgroundHoverColor="#C9CBCF"
            onClick={() =>
              history.push(
                `/orcamentos/orcamentos-e-pedidos/gerar-pedido/${orderId}/documentos`
              )
            }
          />
          <Button
            type="submit"
            text="Enviar termo assinado"
            onClick={handleTerm}
          />
        </ButtonGroup>
      </Content>
    </Container>
  );
};

export default OrdersTermsPage;
