import React from 'react';
import {
  Background,
  Container,
  HiddenLogo,
  LeftContainer,
  RightContainer,
} from './auth-styles';

import { Redirect } from 'react-router-dom';
import logo from '../../assets/energy-logo-vertical.png';
import logoBig from '../../assets/energy-logo-white-big.png';
import { useAuth } from '../../contexts/auth';
import { userURLByRole } from '../../helper/UserURLByRole';
import { UserRoles } from '../../types/users';

const AuthLayout: React.FC = ({ children }) => {
  const { user } = useAuth();

  if (user) {
    return (
      <Redirect
        to={userURLByRole(user.role as keyof typeof UserRoles).loggedIn}
      />
    );
  }

  return (
    <Background>
      <Container>
        <div>
          <LeftContainer>
            <HiddenLogo>
              <img src={logoBig} alt="Energy Brasil Solar" />
            </HiddenLogo>
            {children}
          </LeftContainer>
          <RightContainer>
            <img src={logo} alt="Energy Brasil Solar" />
          </RightContainer>
        </div>
        <strong>NOSSA UNIÃO É TRANSFORMADORA</strong>
      </Container>
    </Background>
  );
};

export default AuthLayout;
