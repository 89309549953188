import styled from 'styled-components';

export const Container = styled.div`
  h1 {
    font-style: normal;
    font-weight: 700;
    font-size: 3rem;
    color: #fff;
    font-family: 'DunbarTallBold';
    letter-spacing: 0.03em;
  }

  span {
    display: inline-block;
    margin-top: 0.8rem;
    color: #fff;
    font-style: normal;
    font-weight: 400;
    font-size: 1.375rem;
    font-family: 'DunbarTextRegular';
    letter-spacing: 0.02em;
  }

  @media (max-width: 800px) {
    form {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    h1 {
      display: none;
    }
    span {
      text-align: center;
    }
  }
`;

export const Group = styled.div`
  margin-top: 1.625rem;

  label {
    color: #fff;
    font-weight: 500;
  }

  input {
    color: #00299b;
  }
`;

export const ForgotPassword = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 0.5rem;
  margin-bottom: 1rem;

  & > a {
    color: #fff;
    font-size: 1rem;
  }

  @media (max-width: 800px) {
    width: 100%;
    justify-content: center;
    margin-bottom: 2.5rem;

    & > a {
      text-decoration: underline;
    }
  }
`;

export const FormWrapper = styled.div`
  max-width: 25rem;

  width: 100%;

  & > button {
    height: 3rem;
  }

  @media (max-width: 800px) {
    max-width: unset;
  }
`;
