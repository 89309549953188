import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { Breadcrumb, Button } from '../../../components';
import Checkbox from '../../../components/checkbox';
import Input from '../../../components/input';
import SelectInput from '../../../components/select';

import { normalize } from '../../../utils/normalize';

import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import * as yup from 'yup';

import { ButtonGroup, Container, PageHeader } from '../../styles';
import {
  Content,
  InputGroup,
  PaymentMethods,
  PaymentMethodsContainer,
  SelectContainer,
  Wrapper,
} from './styles';
import {
  NormalizedProviderPaymentMethods,
  ProviderPaymentMethodsResponse,
  ProviderType,
} from '../types';
import productsApi from '../../../services/products';
import { toast } from 'react-toastify';
import Loading from '../../../components/loading';

type Params = {
  id: string;
};

type Option = {
  label: string;
  value: number;
};

type SchemaType = {
  paymentMethod: string[];
  installmentsNumber: Option;
  fee: { value: string }[];
};

const paymentMethodOptions = [
  {
    key: 'bankSlip',
    title: 'Boleto',
  },
  {
    key: 'pix',
    title: 'Pix',
  },
  {
    key: 'creditCard',
    title: 'Cartão de crédito',
  },
  {
    key: 'financing',
    title: 'Financiamento',
  },
  {
    key: 'bankTransfer',
    title: 'Transferência',
  },
  {
    key: 'entryCreditCard',
    title: 'Entrada + Cartão de crédito',
  },
  {
    key: 'entryFinancing',
    title: 'Entrada + Financiamento',
  },
];

const installmentOptions = Array(12)
  .fill('')
  .map((item, index) => ({
    label: String(index + 1),
    value: index + 1,
  }));

const Schema = yup.object().shape(
  {
    paymentMethod: yup.array().of(yup.string()),
    installmentsNumber: yup
      .object()
      .when('paymentMethod', (method: string[]) =>
        method?.includes('creditCard') || method?.includes('entryCreditCard')
          ? yup.object().required('Selecione o número de parcelas')
          : yup.object().nullable()
      ),

    fee: yup.array().when('installmentsNumber', (installmentsNumber: Option) =>
      installmentsNumber?.value
        ? yup
            .array()
            .of(
              yup.object().shape({
                value: yup.string().required('Digite a taxa'),
              })
            )
            .required('Digite a taxa')
        : yup.array().nullable()
    ),
  },
  [
    ['paymentMethod', 'installmentsNumber'],
    ['installmentsNumber', 'fee'],
  ]
);

const ProvidersPaymentMethodsPage: React.FC = () => {
  const [provider, setProvider] = useState<ProviderType | null>(null);
  const [loading, setLoading] = useState(true);

  const history = useHistory();

  const { id } = useParams<Params>();

  const links = [
    {
      id: 1,
      title: 'Fornecedores',
      link: '/fornecedores',
      active: false,
    },
    {
      id: 2,
      title: 'Formas de pagamento',
      link: `/fornecedores/${id}/formas-de-pagamento`,
      active: true,
    },
  ];

  const {
    register,
    control,
    handleSubmit,
    setValue,
    reset,
    resetField,
    formState: { errors },
    watch,
  } = useForm<SchemaType>({
    resolver: yupResolver(Schema),
  });

  const { fields, append, remove } = useFieldArray<SchemaType>({
    control,
    name: 'fee',
  });

  const selectedPaymentMethods = watch('paymentMethod');
  const installmentsNumber = watch('installmentsNumber');

  const hasCreditCard =
    selectedPaymentMethods?.includes('creditCard') ||
    selectedPaymentMethods?.includes('entryCreditCard');

  const handleSelectPaymentMethod = (isSelected: boolean, value: string) => {
    if (isSelected) {
      return setValue(
        'paymentMethod',
        selectedPaymentMethods.filter((item) => item !== value)
      );
    }

    if (selectedPaymentMethods?.length) {
      return setValue('paymentMethod', [...selectedPaymentMethods, value]);
    }

    return setValue('paymentMethod', [value]);
  };

  useEffect(() => {
    if (!hasCreditCard) {
      resetField('installmentsNumber');
      resetField('fee');
    }
  }, [selectedPaymentMethods]);

  useEffect(() => {
    const currentLength = Number(installmentsNumber?.value) || 0;
    const prevLength = fields.length;

    if (currentLength > prevLength) {
      for (let count = prevLength; count < currentLength; count++) {
        append({ value: '' });
      }
    } else {
      for (let count = prevLength; count > currentLength; count--) {
        remove(count - 1);
      }
    }
  }, [installmentsNumber]);

  const onSubmit = (data: SchemaType) => {
    setLoading(true);

    const normalizedData: NormalizedProviderPaymentMethods = {
      supplierId: id,
      bankSlip: data.paymentMethod.includes('bankSlip'),
      creditCard: data.paymentMethod.includes('creditCard'),
      pix: data.paymentMethod.includes('pix'),
      financing: data.paymentMethod.includes('financing'),
      bankTransfer: data.paymentMethod.includes('bankTransfer'),
      entryCreditCard: data.paymentMethod.includes('entryCreditCard'),
      entryFinancing: data.paymentMethod.includes('entryFinancing'),
      installmentsNumber: data.installmentsNumber?.value || 0,
      instalmentOptions:
        data.fee?.map((item, index) => ({
          fee: normalize(item?.value),
          instalmentNumber: index + 1,
        })) || [],
    };

    if (provider?.paymentMethod?.id) {
      const installmentOptions = data.fee?.map((item, index) => {
        const option =
          provider.paymentMethod?.paymentMethodInstalmentOptions.find(
            (item) => item.instalmentNumber === index + 1
          );

        if (option) {
          return {
            id: option.id,
            fee: normalize(item.value),
            instalmentNumber: index + 1,
          };
        }

        return {
          fee: normalize(item.value),
          instalmentNumber: index + 1,
        };
      });

      const updatedNormalizedData = {
        ...normalizedData,
        supplierId: provider.id,
        instalmentOptions: installmentOptions || [],
      };

      return updateProviderPaymentMethods(
        provider.paymentMethod.id,
        updatedNormalizedData
      )
        .then(() => {
          toast.success('Formas de pagamento atualizadas com sucesso');
          return history.replace('/fornecedores');
        })
        .catch(() => {
          return toast.error(
            'Não foi possível atualizar as formas de pagamento'
          );
        })
        .finally(() => {
          return setLoading(false);
        });
    }

    postProviderPaymentMethods(normalizedData)
      .then(() => {
        toast.success('Formas de pagamento cadastradas com sucesso');
        return history.replace('/fornecedores');
      })
      .catch(() => {
        return toast.error('Não foi possível cadastrar as formas de pagamento');
      })
      .finally(() => {
        return setLoading(false);
      });
  };

  const getProvider = async () => {
    const provider = await productsApi
      .get('/products/suppliers/' + id)
      .then((response) => {
        return response.data;
      })
      .catch(() => {
        return toast.error('Não foi possível buscar os dados do fornecedor');
      });

    return provider;
  };

  const getProviderPaymentMethods = async (name: string) => {
    const paymentMethods = await productsApi
      .get(`/products/suppliers/supplier-payment-method/${name}/name`)
      .then((response) => {
        return response.data;
      })
      .catch(() => {
        return;
      });

    return paymentMethods;
  };

  const postProviderPaymentMethods = async (
    data: NormalizedProviderPaymentMethods
  ) => {
    productsApi.post('/products/suppliers/supplier-payment-method', data);
  };

  const updateProviderPaymentMethods = async (
    id: string,
    data: NormalizedProviderPaymentMethods
  ) => {
    productsApi.put(`/products/suppliers/supplier-payment-method/${id}`, data);
  };

  useEffect(() => {
    getProvider()
      .then((provider: ProviderType) => {
        setProvider(provider);

        return getProviderPaymentMethods(provider.name);
      })
      .then((paymentMethods: ProviderPaymentMethodsResponse) => {
        if (!paymentMethods) return;

        setProvider((state) => ({
          ...(state as ProviderType),
          paymentMethod: paymentMethods,
        }));

        const acceptedMethods = paymentMethodOptions
          .filter(
            (item) =>
              paymentMethods[item.key as keyof typeof paymentMethods] === true
          )
          .map((item) => item.key);

        const fee = paymentMethods.paymentMethodInstalmentOptions
          .sort((a, b) => a.instalmentNumber - b.instalmentNumber)
          .map((item) => ({ value: String(item.fee).replace('.', ',') }));

        reset({
          paymentMethod: acceptedMethods,
          installmentsNumber: {
            label: String(paymentMethods.installmentsNumber),
            value: paymentMethods.installmentsNumber,
          },
          fee,
        });
      })
      .finally(() => {
        return setLoading(false);
      });
  }, []);

  if (loading) {
    return (
      <Loading
        style={{ alignItems: 'center', height: '100%' }}
        label="Buscando os dados do fornecedor"
      />
    );
  }

  return (
    <Container>
      <Breadcrumb links={links} />
      <PageHeader>
        <span>Formas de pagamento</span>
      </PageHeader>

      <Content onSubmit={handleSubmit(onSubmit)}>
        <Wrapper>
          <h2>Nome do fornecedor</h2>
          <span>{provider?.name}</span>
        </Wrapper>
        <Wrapper>
          <h2>Formas de pagamento aceitas</h2>

          <PaymentMethodsContainer>
            <PaymentMethods>
              {paymentMethodOptions.map((method) => {
                const isChecked = selectedPaymentMethods?.includes(method.key);

                return (
                  <Checkbox
                    key={method.key}
                    label={method.title}
                    name={method.key}
                    option={method}
                    isChecked={isChecked}
                    onChange={() =>
                      handleSelectPaymentMethod(isChecked, method.key)
                    }
                  />
                );
              })}
            </PaymentMethods>
          </PaymentMethodsContainer>
        </Wrapper>

        {hasCreditCard && (
          <Wrapper>
            <SelectContainer>
              <Controller
                control={control}
                name="installmentsNumber"
                render={({
                  field: { onChange, value, ref },
                  fieldState: { error },
                }) => {
                  return (
                    <SelectInput
                      ref={ref}
                      label="Número de parcelas"
                      placeholder="Selecione uma opção"
                      options={installmentOptions}
                      required
                      error={error?.message}
                      onChange={onChange}
                      value={value}
                    />
                  );
                }}
              />
            </SelectContainer>

            <InputGroup>
              {fields.map((item, index) => {
                const position = index + 1;

                const label = `Em ${position} ${
                  position > 1 ? 'parcelas' : 'parcela'
                }`;

                return (
                  <div key={item.id}>
                    <Input
                      {...register(`fee.${index}.value`)}
                      error={errors.fee?.[index]?.value?.message}
                      defaultValue={item.value}
                      placeholder="Informe a taxa"
                      iconLeft="%"
                      label={label}
                    />
                  </div>
                );
              })}
            </InputGroup>
          </Wrapper>
        )}

        <ButtonGroup style={{ marginTop: 'auto', marginBottom: '1.5rem' }}>
          <Button
            text="Voltar"
            typeStyle="default"
            onClick={() => history.replace('/fornecedores')}
          />
          <Button
            text="Salvar alterações"
            typeStyle="confirm"
            disabled={!selectedPaymentMethods?.length}
          />
        </ButtonGroup>
      </Content>
    </Container>
  );
};

export default ProvidersPaymentMethodsPage;

