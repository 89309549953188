import styled, { css } from 'styled-components';

type ContainerProps = {
  height: string;
  active: boolean;
  error: boolean;
  disabled: boolean;
};

export const Container = styled.label<ContainerProps>`
  display: flex;
  flex-direction: column;

  gap: 0.5rem;

  font-size: 1rem;
  font-weight: 500;
  color: #27282b;

  span {
    color: #e01919;
    font-size: 0.875rem;
  }

  div {
    display: flex;
    align-items: center;
    gap: 0.5rem;

    border: 1px solid #c9cbcf;

    border-radius: 4px;

    background-color: #ffffff;

    ${(props) =>
      props.disabled &&
      css`
        background-color: #f5f5f6;
        color: #9aa3ac;
      `}

    ${(props) =>
      props.active &&
      css`
        border-color: #005af9;
      `}

      ${(props) =>
      props.error &&
      css`
        border-color: #e01919;
      `}

    max-height: ${(props) => props.height};
    min-height: ${(props) => props.height};

    & > div:first-child {
      display: flex;
      align-items: center;

      padding: 0 0.875rem;

      background-color: transparent;

      border-radius: 0;
      border: 0;
      border-right: 1px solid #c9cbcf;

      ${(props) =>
        props.active &&
        css`
          border-color: #005af9;
        `}

      ${(props) =>
        props.error &&
        css`
          border-color: #e01919;
        `}
    }
  }

  input {
    display: flex;
    align-items: center;

    background-color: transparent;
    border: 0;
    width: 100%;
    height: 100%;
    font-size: 1rem;
    color: #27282b;
    font-weight: 500;

    padding: 0.5rem 0;

    &:focus {
      outline: none;
    }

    &::placeholder {
      font-size: 1rem;
      font-weight: 400;
      color: #61646b;

      ${(props) =>
        props.disabled &&
        css`
          color: #9aa3ac;
        `}
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  svg {
    color: #61646b;
  }
`;
