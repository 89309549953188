import React from 'react';
import { SectionContainer, Title } from '../../styles';
import { UnitInfos } from './UnitInfos';
import {
  Cards,
  CardsWrapper,
  Column,
  Description,
  Square,
  TitleContainer,
  Wrapper,
} from './styles';
import { Card } from '../Card';
import { useScrollContainer } from 'react-indiana-drag-scroll';
import { Classification } from './Classification';
import { UserRoles } from '../../../../../../types/users';
import { useDashboard } from '../../../../../../contexts/dashboard';
import { FranchiseData } from '../../../../../../contexts/dashboard/types';

type Props = {
  role: string;
};

type DashboardPropertiesType = {
  franchiseData: FranchiseData;
};

export const GeneralData: React.FC<Props> = (props) => {
  const { role } = props;

  const { franchiseData } = useDashboard() as DashboardPropertiesType;

  const totalSales = useScrollContainer<HTMLDivElement>({
    mouseScroll: {
      overscroll: true,
      ignoreElements: '.undraggable',
    },
  });
  const totalPaid = useScrollContainer<HTMLDivElement>({
    mouseScroll: {
      overscroll: true,
      ignoreElements: '.undraggable',
    },
  });

  return (
    <SectionContainer>
      <TitleContainer>
        <Title>Dados Gerais</Title>
        <Description>
          <p>
            <Square color="#00299B" />
            Mês atual
          </p>
          <p>
            <Square color="#0090A3" />
            Mês anterior
          </p>
        </Description>
      </TitleContainer>

      <Wrapper>
        {UserRoles.ADMIN.includes(role) && (
          <UnitInfos active={243} productive={133} />
        )}
        {UserRoles.FRANQUEADO.includes(role) && (
          <Classification
            remaining={franchiseData.level.remaining}
            currentClassification={franchiseData.level.current}
            lastClassification={franchiseData.level.current}
          />
        )}

        <Column>
          <CardsWrapper>
            <h3 className="special">Total de Vendas</h3>

            <Cards ref={totalSales.ref}>
              <Card
                title="Kits"
                currentValue={franchiseData.sales.kit.currentMonth}
                lastValue={franchiseData.sales.kit.lastMonth}
                percentage={
                  (franchiseData.sales.kit.currentMonth /
                    franchiseData.sales.kit.lastMonth -
                    1) *
                  100
                }
                isPositive={
                  franchiseData.sales.kit.currentMonth >
                  franchiseData.sales.kit.lastMonth
                }
                isMultiple={true}
              />
              <Card
                title="Mão de obra"
                currentValue={franchiseData.sales.labor.currentMonth}
                lastValue={franchiseData.sales.labor.lastMonth}
                percentage={
                  (franchiseData.sales.labor.currentMonth /
                    franchiseData.sales.labor.lastMonth -
                    1) *
                  100
                }
                isPositive={
                  franchiseData.sales.labor.currentMonth >
                  franchiseData.sales.labor.lastMonth
                }
                isMultiple={true}
              />
              <Card
                title="Total"
                currentValue={franchiseData.sales.total.currentMonth}
                lastValue={franchiseData.sales.total.lastMonth}
                percentage={
                  (franchiseData.sales.total.currentMonth /
                    franchiseData.sales.total.lastMonth -
                    1) *
                  100
                }
                isPositive={
                  franchiseData.sales.total.currentMonth >
                  franchiseData.sales.total.lastMonth
                }
                isMultiple={true}
              />
            </Cards>
          </CardsWrapper>
          <CardsWrapper>
            <h3 className="special">Total Pago</h3>

            <Cards ref={totalPaid.ref}>
              <Card
                title="Kits"
                currentValue={franchiseData.sales.kit.currentMonth}
                lastValue={franchiseData.sales.kit.lastMonth}
                percentage={
                  (franchiseData.sales.kit.currentMonth /
                    franchiseData.sales.kit.lastMonth -
                    1) *
                  100
                }
                isPositive={
                  franchiseData.sales.kit.currentMonth >
                  franchiseData.sales.kit.lastMonth
                }
                isMultiple={true}
              />
              <Card
                title="Mão de obra"
                currentValue={franchiseData.sales.labor.currentMonth}
                lastValue={franchiseData.sales.labor.lastMonth}
                percentage={
                  (franchiseData.sales.labor.currentMonth /
                    franchiseData.sales.labor.lastMonth -
                    1) *
                  100
                }
                isPositive={
                  franchiseData.sales.labor.currentMonth >
                  franchiseData.sales.labor.lastMonth
                }
                isMultiple={true}
              />
              <Card
                title="Total"
                currentValue={franchiseData.sales.total.currentMonth}
                lastValue={franchiseData.sales.total.lastMonth}
                percentage={
                  (franchiseData.sales.total.currentMonth /
                    franchiseData.sales.total.lastMonth -
                    1) *
                  100
                }
                isPositive={
                  franchiseData.sales.total.currentMonth >
                  franchiseData.sales.total.lastMonth
                }
                isMultiple={true}
              />
            </Cards>
          </CardsWrapper>
        </Column>
      </Wrapper>
    </SectionContainer>
  );
};

