import { yupResolver } from '@hookform/resolvers/yup';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as yup from 'yup';

import Input from '../../../components/input';

import { Breadcrumb, BRFlag, Button } from '../../../components';
import InputPhone from '../../../components/input-phone';
import { UpdateProviderModal } from '../../../components/modal/update-provider';
import productsApi from '../../../services/products';
import { ProviderType } from '../types';
import {
  ButtonGroup,
  Container,
  Divider,
  FormContainer,
  Group,
  GroupedInputs,
  GroupHeader,
  InputGroup,
  PageHeader,
  Wrapper,
} from './../../styles';

interface FormTypes extends ProviderType {
  password: string;
  confirmPassword?: string;
}

type NewProviderPageProps = {
  id: string;
};
const urlRegex =
  /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi;

const NewProviderPage: React.FC = () => {
  const history = useHistory();
  const formRef = useRef<FormHandles>(null);
  const { id } = useParams<NewProviderPageProps>();
  const [toggDialog, setToggDialog] = useState<boolean>(false);
  const [confirmEditable, setEditable] = useState<boolean>(false);

  const action = id ? 'Editar' : 'Novo';

  const links = [
    {
      id: 1,
      title: 'Lista de Fornecedores',
      link: '/fornecedores',
      active: false,
    },
    {
      id: 2,
      title: id ? 'Editar Fornecedor' : 'Novo Fornecedor',
      link: id ? `/fornecedores/${id}/editar` : '/fornecedores/novo',
      active: true,
    },
  ];

  const schema = yup.object().shape({
    name: yup.string().required('Nome da empresa é um campo obrigatório'),
    personName: yup
      .string()
      .required('Nome do representante é um campo obrigatório'),
    website: yup
      .string()
      .matches(urlRegex, 'Insira um site válido')
      .required('Site da empresa é um campo obrigatório'),
    email: yup.string().email().required('E-mail é um campo obrigatório'),
    phone: yup.string().required('Telefone é um campo obrigatório'),
    solarGenerationLost: yup
      .number()
      .required('Taxa de perda é um campo obrigatório')
      .typeError('Taxa de perda é um campo obrigatório'),
    zipcode: yup.string().required('CEP é um campo obrigatório'),
    state: yup.string().required('Estado é um campo obrigatório'),
    city: yup.string().required('Cidade é um campo obrigatório'),
    address: yup.string().required('Endereço é um campo obrigatório'),
    district: yup.string().required('Bairro é um campo obrigatório'),
    addressNumber: yup.string().required('Número é um campo obrigatório'),
    complement: yup.string(),
  });

  const {
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormTypes>({ resolver: yupResolver(schema) });

  useEffect(() => {
    if (id) {
      productsApi.get<ProviderType>(`/products/suppliers/${id}`).then((res) => {
        reset({
          name: res.data.name,
          personName: res.data.personName,
          website: res.data.website,
          email: res.data.email,
          phone: res.data.phone,
          solarGenerationLost: res.data.solarGenerationLost,
          zipcode: res.data.zipcode,
          state: res.data.state,
          city: res.data.city,
          address: res.data.address,
          district: res.data.district,
          addressNumber: res.data.addressNumber,
          complement: res.data.complement,
        });
      });
    }
  }, []);

  useEffect(() => {
    if (Object.keys(errors).length) {
      toast.error('Alguns erros foram encontrados no seu formulário');
    }
  }, [errors]);

  const onSubmit = async (data: FormTypes) => {
    try {
      if (!id) {
        await productsApi.post('/products/suppliers/', {
          ...data,
          complement: data.complement || null,
        });
        toast.success('Fornecedor cadastrado com sucesso');
        history.push(`/fornecedores/`);
      } else {
        if (confirmEditable) {
          await productsApi.put(`/products/suppliers/${id}`, {
            ...data,
            complement: data.complement || null,
          });
          setEditable(false);
          toast.success('Fornecedor editado com sucesso');
          history.push(`/fornecedores/`);
        } else {
          setToggDialog(true);
        }
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      toast.error(error.response.data.message);
      setEditable(false);
    }
  };

  const confirmSubmit = () => {
    setToggDialog(false);
    setEditable(true);
  };

  useEffect(() => {
    if (confirmEditable) formRef.current?.submitForm();
  }, [confirmEditable]);

  return (
    <Container>
      <Breadcrumb links={links} />
      <Wrapper>
        <PageHeader>
          <span>{action + ' Fornecedor'}</span>
        </PageHeader>
        <FormContainer>
          <Form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
            <Group>
              <GroupHeader>
                <h2>Dados do fornecedor</h2>
              </GroupHeader>
              <GroupedInputs>
                <InputGroup sideSpace="right">
                  <Input
                    label="Nome da empresa"
                    placeholder="Digite o nome da empresa"
                    {...register('name')}
                    error={errors.name?.message}
                  />
                </InputGroup>
                <InputGroup sideSpace="right">
                  <Input
                    label="Nome do representante"
                    placeholder="Digite o nome do representante"
                    {...register('personName')}
                    error={errors.personName?.message}
                  />
                </InputGroup>
                <InputGroup sideSpace="right">
                  <Input
                    label="Site da empresa"
                    placeholder="Digite o site da empresa"
                    {...register('website')}
                    error={errors.website?.message}
                  />
                </InputGroup>
              </GroupedInputs>
            </Group>
            <Group>
              <GroupedInputs>
                <InputGroup sideSpace="right">
                  <Input
                    label="E-mail"
                    placeholder="Digite o e-mail"
                    {...register('email')}
                    error={errors.email?.message}
                  />
                </InputGroup>
                <InputGroup sideSpace="right">
                  <InputPhone
                    label="Telefone"
                    mask="(99) 99999-9999"
                    placeholder="Digite o telefone"
                    iconLeft={<BRFlag />}
                    {...register('phone')}
                    error={errors.phone?.message}
                  />
                </InputGroup>
                <InputGroup sideSpace="right">
                  <Input
                    step="0.01"
                    type="number"
                    label="Taxa de perda"
                    placeholder="Digite aqui a taxa"
                    {...register('solarGenerationLost')}
                    error={errors.solarGenerationLost?.message}
                  />
                </InputGroup>
              </GroupedInputs>
            </Group>
            <Divider />
            <Group>
              <GroupHeader>
                <h2>Endereço da empresa</h2>
              </GroupHeader>
              <GroupedInputs>
                <InputGroup sideSpace="right">
                  <Input
                    label="CEP"
                    placeholder="Digite o CEP"
                    {...register('zipcode')}
                    error={errors.zipcode?.message}
                  />
                </InputGroup>
                <InputGroup sideSpace="right">
                  <Input
                    label="Estado"
                    placeholder="Digite o estado"
                    {...register('state')}
                    error={errors.state?.message}
                  />
                </InputGroup>
                <InputGroup>
                  <Input
                    label="Cidade"
                    placeholder="Digite a cidade"
                    {...register('city')}
                    error={errors.city?.message}
                  />
                </InputGroup>
              </GroupedInputs>
            </Group>
            <Group>
              <GroupedInputs>
                <InputGroup>
                  <Input
                    label="Endereço"
                    placeholder="Digite o endereço"
                    {...register('address')}
                    error={errors.address?.message}
                  />
                </InputGroup>
              </GroupedInputs>
            </Group>
            <Group>
              <GroupedInputs>
                <InputGroup sideSpace="right">
                  <Input
                    label="Bairro"
                    placeholder="Digite o bairro"
                    {...register('district')}
                    error={errors.district?.message}
                  />
                </InputGroup>
                <InputGroup sideSpace="right">
                  <Input
                    label="Número"
                    placeholder="Digite o número"
                    {...register('addressNumber')}
                    error={errors.addressNumber?.message}
                  />
                </InputGroup>
                <InputGroup>
                  <Input
                    placeholder="Digite o complemento"
                    label="Complemento"
                    {...register('complement')}
                    error={errors.complement?.message}
                  />
                </InputGroup>
              </GroupedInputs>
            </Group>
            <ButtonGroup style={{ marginTop: '3rem' }}>
              <Button
                text="Cancelar"
                typeStyle="default"
                onClick={() => history.replace('/fornecedores')}
              />

              <Button text={action} />
            </ButtonGroup>
          </Form>
        </FormContainer>
      </Wrapper>
      <UpdateProviderModal
        isOpen={toggDialog}
        onCancel={() => setToggDialog(false)}
        onConfirm={confirmSubmit}
      />
    </Container>
  );
};

export default NewProviderPage;
