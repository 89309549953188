import styled from 'styled-components';

export const Content = styled.form`
  display: flex;
  flex-direction: column;

  padding: 1.5rem 0;

  height: 100%;

  & > div {
    margin-top: auto;
  }
`;

export const Header = styled.header`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  & > h1 {
    font-family: 'Montserrat', sans-serif;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 600;
    letter-spacing: 0.0125rem;

    font-weight: 600;

    color: #27282b;
  }

  & > p {
    color: #27282b;
    font-family: 'DunbarTextRegular', sans-serif;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0.02rem;
  }

  margin-bottom: 4rem;
`;
