import { Permissions } from '../components';
import { UserRoles } from '../types/users';

const initialState: Permissions = {
  users: false,
  franchises: false,
  providers: false,
  products: false,
  budgets: false,
  projects: true,
  dashboard: false,
  salesFunnel: false,
  projectsURL: '/projetos',
  management: false,
  announcements: false,
  campaigns: false,
  nps: false,
  juridical: false,
  customers: false,
};

export const getPermissionsByRole = (role: string) => {
  const permissionsByRole = {
    [UserRoles.ADMIN]: {
      ...initialState,
      users: true,
      franchises: true,
      providers: true,
      products: true,
      budgets: true,
      dashboard: true,
      salesFunnel: true,
      management: true,
      announcements: true,
      campaigns: true,
      nps: true,
      juridical: true,
      customers: true,
    },
    [UserRoles.CENTRAL_ATENDIMENTO]: {
      ...initialState,
      projectsURL: '/projetos/central-atendimento',
    },
    [UserRoles.ENGENHEIRO]: {
      ...initialState,
      projectsURL: '/projetos/engenharia/listar',
    },
    [UserRoles.FRANQUEADO]: {
      ...initialState,
      dashboard: true,
      salesFunnel: true,
      budgets: true,
      management: true,
      announcements: true,
      campaigns: true,
      nps: true,
      juridical: true,
      customers: true,
    },
    [UserRoles.CONSULTOR_SUCESSO]: {
      ...initialState,
      budgets: true,
      franchises: true,
      users: true,
      providers: true,
      salesFunnel: true,
      products: true,
    },
    [UserRoles.GERENCIA]: {
      ...initialState,
      users: true,
      franchises: true,
      providers: true,
      products: true,
      budgets: true,
      dashboard: true,
      salesFunnel: true,
      management: true,
      announcements: true,
      campaigns: true,
      nps: true,
      juridical: true,
      customers: true,
    },
    [UserRoles.SUPERVISAO]: {
      ...initialState,
      users: true,
      franchises: true,
      providers: true,
      products: true,
      budgets: true,
      dashboard: true,
      salesFunnel: true,
      management: true,
      announcements: true,
      campaigns: true,
      nps: true,
      juridical: true,
      customers: true,
    },
  };

  const permissions = permissionsByRole[role];

  if (permissions) return permissions;

  return initialState;
};
