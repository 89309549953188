import { yupResolver } from '@hookform/resolvers/yup';
import { AxiosError } from 'axios';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { Breadcrumb, Button, StepProgress } from '../../../../components';
import { FileInput, IFile } from '../../../../components/file-input';
import Loading from '../../../../components/loading';
import Textarea from '../../../../components/textarea';
import { useOrders } from '../../../../contexts/orders';
import { useSalesFunnel } from '../../../../contexts/sales-funnel';
import productsApi from '../../../../services/products';
import { ButtonGroup, Container, PageHeader } from '../../../styles';
import { Content, Documents, DocumentsContainer } from './styles';

const steps = [
  { key: 1, title: 'Selecionar kit', active: false, complete: true },
  { key: 2, title: 'Resumo', active: false, complete: true },
  { key: 3, title: 'Acessórios', active: false, complete: true },
  { key: 4, title: 'Custos', active: false, complete: true },
  { key: 5, title: 'Pagamento', active: false, complete: true },
  { key: 6, title: 'Documentos', active: false, complete: true },
  { key: 8, title: 'Termo de aceite', active: true, complete: false },
];

type TermSignedForm = {
  acceptanceTerm: IFile[];
  observations: string;
};

const schema = yup.object().shape({
  acceptanceTerm: yup
    .mixed()
    .test('check if field is empty', (value: IFile[], { createError }) => {
      const hasErrors = value.some((file) => file.error);

      if (value.length > 0) {
        return hasErrors
          ? createError({
              path: 'acceptanceTerm',
              message: 'Remova o arquivo com erro',
              type: 'field-error',
            })
          : true;
      } else {
        return createError({
          path: 'acceptanceTerm',
          message: 'Campo obrigatório',
          type: 'field-empty',
        });
      }
    }),
  observations: yup.string().optional(),
});

const OrdersSendTermsPage: React.FC = () => {
  const { order } = useOrders();

  const { id: orderId } = useParams<{ id: string }>();

  const links = [
    {
      id: 1,
      title: 'Orçamentos',
      link: '/orcamentos',
      active: false,
    },
    {
      id: 2,
      title: 'Orçamentos e pedidos',
      link: '/orcamentos/orcamentos-e-pedidos',
      active: false,
    },
    {
      id: 3,
      title: 'Gerar pedido',
      link: '/orcamentos/orcamentos-e-pedidos/',
      active: false,
    },
    {
      id: 4,
      title: 'Selecionar kit',
      link: '/orcamentos/orcamentos-e-pedidos/gerar-pedido/selecione',
      active: false,
    },
    {
      id: 5,
      title: 'Resumo',
      link: `/orcamentos/orcamentos-e-pedidos/gerar-pedido/${orderId}/resumo`,
      active: false,
    },
    {
      id: 6,
      title: 'Acessórios',
      link: `/orcamentos/orcamentos-e-pedidos/gerar-pedido/${orderId}/acessorios`,
      active: false,
    },
    {
      id: 7,
      title: 'Custos',
      link: `/orcamentos/orcamentos-e-pedidos/gerar-pedido/${orderId}/custos`,
      active: false,
    },
    {
      id: 8,
      title: 'Pagamento',
      link: `/orcamentos/orcamentos-e-pedidos/gerar-pedido/${orderId}/formas-de-pagamento`,
      active: false,
    },
    {
      id: 9,
      title: 'Documentos',
      link: `/orcamentos/orcamentos-e-pedidos/gerar-pedido/${orderId}/documentos`,
      active: false,
    },
    {
      id: 10,
      title: 'Termo de aceite',
      link: `/orcamentos/orcamentos-e-pedidos/gerar-pedido/${orderId}/termo-de-aceite`,
      active: true,
    },
  ];

  const { crmData, updateLeadByBudgetId, handleCRMData } = useSalesFunnel();

  const history = useHistory();

  const [loading, setLoading] = useState(false);

  const pdfUrl = history.location.state as string;

  const { control, reset, handleSubmit, register } = useForm<TermSignedForm>({
    resolver: yupResolver(schema),
    defaultValues: {
      acceptanceTerm: [],
    },
  });

  const onSubmit = async (data: TermSignedForm) => {
    try {
      await productsApi.put(`/orders/${orderId}`, {
        observations: data.observations.length ? data.observations : null,
        status: 'A processar',
        franchiseStatus: 'A processar',
        documentationMessage: order?.documentationMessage,
        isDocumentationOk: true,
      });

      URL.revokeObjectURL(pdfUrl);

      if (order && !crmData) {
        await updateLeadByBudgetId({
          budgetId: order.budgetId,
          status: 'fechado',
          orderId: order.id,
        });
      }

      if (crmData) {
        const normalizedCustomerData = {
          id: crmData.leadId,
          name: crmData.name,
          document:
            crmData.documentType === 'cnpj' ? crmData.cnpj : crmData.cpf,
          email: crmData.email,
          phone: crmData.phone,
          zipcode: crmData.zipCode,
          state: crmData.state,
          city: crmData.city,
          district: crmData.neighborhood,
          address: crmData.street,
          addressNumber: crmData.number,
          addressComplement: crmData?.complement,
        };

        if (order) {
          await updateLeadByBudgetId({
            budgetId: order.budgetId,
            status: 'fechado',
            customerData: normalizedCustomerData,
            orderId: order.id,
          });
        }

        handleCRMData(null);
      }

      history.replace('/orcamentos/orcamentos-e-pedidos');
    } catch (error) {
      const errorMessage = (error as AxiosError)?.response?.data.message;

      toast.error(errorMessage);
    }
  };

  const getFiles = () => {
    setLoading(true);

    productsApi
      .get(`/orders/upload-file/${orderId}/`)
      .then((response) => {
        const acceptanceTermFiles = response.data.filter(
          (file: File) => file.type === 'AcceptanceTerm'
        );

        const normalizedFiles = acceptanceTermFiles.map((file: File) => {
          return {
            ...file,
            progress: 100,
          };
        });

        reset({
          acceptanceTerm: normalizedFiles,
        });
      })
      .catch((error) => {
        const errorMessage = error.response.data.message;

        toast.error(errorMessage);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getFiles();
  }, []);

  if (loading) {
    return <Loading style={{ alignItems: 'center', height: '100%' }} />;
  }

  return (
    <Container>
      <Breadcrumb links={links} />

      <PageHeader>
        <span>{'Enviar termo'}</span>
        <div>
          <StepProgress steps={steps} />
        </div>
      </PageHeader>

      <Content onSubmit={handleSubmit(onSubmit)}>
        <h1>Termo de aceite assinado</h1>
        <DocumentsContainer>
          <Documents>
            <Controller
              name="acceptanceTerm"
              control={control}
              render={({
                field: { ref, value, onChange },
                fieldState: { error },
              }) => {
                return (
                  <FileInput
                    ref={ref}
                    required
                    label="Termo de aceite assinado"
                    inputLabel="Anexar arquivo"
                    error={error?.message}
                    api={productsApi}
                    url={`/orders/upload-file/${orderId}/type/AcceptanceTerm`}
                    deleteUrl={'/orders/upload-file/'}
                    onChange={(files) => {
                      onChange(files);
                    }}
                    files={value}
                  />
                );
              }}
            />

            <Textarea
              label="Observações"
              placeholder="Digite aqui"
              height="15rem"
              {...register('observations')}
            />
          </Documents>
        </DocumentsContainer>
        <ButtonGroup>
          <Button
            type="button"
            text="Voltar"
            typeStyle="default"
            backgroundHoverColor="#C9CBCF"
            onClick={() =>
              history.push(
                `/orcamentos/orcamentos-e-pedidos/gerar-pedido/${orderId}/termo-de-aceite/`,
                pdfUrl
              )
            }
          />
          <Button type="submit" text="Enviar" />
        </ButtonGroup>
      </Content>
    </Container>
  );
};

export default OrdersSendTermsPage;
