import styled, { css } from 'styled-components';

export const TabsContainer = styled.div`
  overflow: auto;

  box-shadow: inset 0px -1px 0px #cccccc;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const Tabs = styled.div`
  display: flex;
  align-items: center;

  flex: 1;
`;

type TabProps = {
  isActive: boolean;
};

export const Tab = styled.div<TabProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.25rem;

  padding: 1rem 1.5rem;

  font-weight: 500;
  font-size: 1rem;

  color: #27282b;

  cursor: pointer;

  ${({ isActive }) =>
    isActive &&
    css`
      color: #005af9;

      box-shadow: inset 0px -2px 0px #478aff;
    `}
`;

export const ReportContainer = styled.div`
  margin: 1.5rem 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;
